import React, { Component } from "react";
import {connect} from "react-redux";
import Product from "../redux/dto/product";
import {
    addProduct,
    displayShortProductDescription,
    saveQuickPosProducts,
    setEditQuickProducts,
    setQuickPosProductsOrder
} from "../redux/actions";
import ProductSelectListDraggable from "./lists/ProductSelectListDraggable";
import {getAttributeValue, getObjectValues} from "../util/helperFunctions";

const mapStateToProps = state => {
    return {
        quickPosProducts: state.rootReducer.quickPosProducts,
        editQuickPosProducts: state.rootReducer.editQuickPosProducts,
        uiCustomization: state.rootReducer.uiCustomization,
        user: state.rootReducer.user,
        productsOnLine: 6,
        language: state.localize.activeLocale,
        pointOfSaleID: state.rootReducer.pos.pointOfSaleID
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setProduct: (product) => {
            let productDTO = new Product(product.name, product.priceListPrice, product.priceListPriceWithVat, 1, getObjectValues(product));
            dispatch(addProduct(productDTO));
        },
        setQuickPosProductsOrder: (productIDs) => {
            dispatch(setQuickPosProductsOrder(productIDs));
        },
        saveQuickProducts: (productIDs, posID, saved) => {
            dispatch(saveQuickPosProducts(productIDs, posID, saved));
        },
        setEditQuickProducts: (edit) => {
            dispatch(setEditQuickProducts(edit));
        },
        displayShortProductDescription: (product, language) => {
            dispatch(displayShortProductDescription(product, language));
        }
    }
};

class QuickSelectProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayAll: false,
            quickPosProductsOrder: props.quickPosProducts.order,
            oldOrder: props.quickPosProducts.order
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.editQuickPosProducts === true){
            if(this.props.editQuickPosProducts === false){
                this.props.setQuickPosProductsOrder(this.state.quickPosProductsOrder);
                this.props.saveQuickProducts(this.state.quickPosProductsOrder, this.props.pointOfSaleID, this.props.quickPosProducts.saved);
            }else if(this.props.editQuickPosProducts === 'cancel'){
                this.props.setQuickPosProductsOrder(this.state.oldOrder);
                this.props.setEditQuickProducts(false);
            }
        }
    }

    render() {
        let orderedProducts = this.props.quickPosProducts.order.reduce((acc, el) => {
            if(typeof this.props.quickPosProducts.data[el] !== "undefined"){
                let product = structuredClone(this.props.quickPosProducts.data[el]);
                product.showInfoButton = !this.props.editQuickPosProducts && typeof getAttributeValue(product, 'shortDescriptionSCO') !== "undefined";
                product.onInfoButtonClick = () => {
                    this.props.displayShortProductDescription(product, this.props.language);
                };
                acc.push(product);
            }
            return acc;
        }, []);
        return (
            <ProductSelectListDraggable
                products={orderedProducts}
                setProduct={this.props.setProduct}
                productsOnLine={this.props.productsOnLine}
                language={this.props.language}
                editable={this.props.editQuickPosProducts}
                onLayoutChange={(productIDs) => {
                    this.setState({
                        quickPosProductsOrder: productIDs
                    });
                }}
                removeProduct={(product) => {
                    let ids = this.state.quickPosProductsOrder.filter(el => el !== product.productID);
                    this.props.setQuickPosProductsOrder(ids);
                }}
                fontSize={this.props.uiCustomization.fonts.quickPosProducts.size}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickSelectProducts);
