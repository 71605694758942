export const LMS = {
    port: false,
    url: 'https://localhost.erply.com',
    available: false,
    log: function(){
        let newArgs = Array.prototype.slice.call(arguments);
        newArgs.unshift('LMS: ');
        console.log.apply(this, newArgs);
    },
    init: (config) => {
        LMS.port = config.Port;
        LMS.processQueue();
    },
    queue: [],
    processQueue: () => {
        if(LMS.queue.length === 0 || LMS.port === false){
            return;
        }
        let {request, headers, resolve, reject} = LMS.queue.shift();
        LMS.sendRequest(request, headers).then(resolve, reject);
    },
    sendRequest: (request, headers) => {
        return new Promise((resolve, reject) => {
            if(LMS.port === false){
                LMS.queue.push({request, headers, resolve, reject});
                return;
            }
            let url = `${LMS.url}:${LMS.port}/`;
            fetch(url + request, {
                headers
            }).then((response) => {
                resolve(response.json());
            }, reject);
        });
    },
    registerDevice: (clientCode, warehouseID, posID, sessionKey) => {
        LMS.log("registerDevice", {clientCode, warehouseID, posID, sessionKey});
        return LMS.sendRequest('registerDevice', {
            Accept: "application/json",
            Clientcode: clientCode,
            WarehouseId: warehouseID,
            Posid: posID,
            Sessionkey: sessionKey
        });
    }
};
