import React from 'react';
import RFIDService from "../../redux/rfid/RFIDService";
import Translate from "localization/Translate";
import {useSelector} from "react-redux";

export default () => {
    //Triggers an update on redux action
    let RFIDStatus = useSelector(state => state.rootReducer.rfidStatus);
    return (
        <div id="RFIDStatus" onClick={RFIDService.changeStatus} style={{
            color: RFIDService.status === 'reading' ? "green" : "red",
            fontWeight: "bold"
        }}>
            <Translate id={'RFID ' + RFIDStatus.toUpperCase() }/>
        </div>
    );
}
