import {
    addBeforeApplyPromotionsEvent, setOnePromotionPromoCode, updateProduct
} from "../../redux/actions";
import {APPLY_PROMOTIONS} from "../../redux/actionTypes";
import {getProductsInBasket, getReasonCodeByNameAndPurpose} from "../../redux/selectors";
import moment from "moment/moment";
import {fetchWithTimeout} from "../../util/helperFunctions";

/**
 * One Promotion
 * Integrates Decathlon promotion API
 */
const OP = {
    conf: false,
    setConf: (erplyConfig) => {
        let excludedCategories = [];
        if(typeof window?.AppConf.onePromotionExcludedCategories !== "undefined"){
            excludedCategories = window?.AppConf.onePromotionExcludedCategories.split(",");
        }

        let quoteTimeout = window.AppConf?.onePromotionQuoteTimeout ?? 4;
        if(quoteTimeout === ""){
            quoteTimeout = 4;
        }

        OP.conf = {
            getQuoteTimeout: quoteTimeout * 1000,
            couponEnabled: window.AppConf?.onePromotionCouponEnabled ?? false,
            auth: erplyConfig.DKT_PROXY_AUTH,
            clientName: erplyConfig.DKT_ONEPAY_CLIENT_NAME,
            currency: erplyConfig.default_currency,
            timezone: erplyConfig.timezone,
            reasonCodeMap: {
                'SET': 'DKT:Set',
                'BUNDLE': 'DKT:Bundle'
            },
            excludedCategories
        }
    },
    Item: function (code, quantity, price, total, rowNumber, priceType){
        this.code = code;
        this.quantity = parseFloat(quantity);
        this.price = price;
        this.total = total;
        this.rowNumber = rowNumber;
        this.priceType = priceType;
    },
    addPromotion: (productsInBasket, reasonCodes, referenceNumber, dispatch, promoCode, promoCodeSet = false) => {
        return new Promise((resolve, reject) => {
            let includeAll = promoCode !== false;
            let {items, products} = OP.getPromotionableProducts(productsInBasket, includeAll);
            let itemsTotalQuantity = items.reduce((acc, el) => {
                acc += el.quantity;
                return acc;
            },0);

            if( items.length === 0 ||
                ( !includeAll && items.length === 1 && items[0].quantity === 1 && OP.previousMixMatchProductsQuantity === 0 ) ||
                ( OP.previousMixMatchProductsQuantity === itemsTotalQuantity && !promoCodeSet )
            ){
                resolve();
                return;
            }
            OP.previousMixMatchProductsQuantity = itemsTotalQuantity;

            OP.getQuote(items, referenceNumber, promoCode).then(quote => {
                products.forEach((product, index) => {
                    const hasPromotion = (promotionProduct) => {
                        return typeof promotionProduct?.total_promotion?.applied_promotions !== "undefined" && promotionProduct.total_promotion.applied_promotions.length > 0
                    }

                    let promotionProduct = quote.items.filter(item => parseInt(item.original_basket_line_id) === product.lineNumber).reduce((acc, el) => {
                        if(Object.keys(acc).length === 0){
                            acc = el;
                        }else{
                            acc.quantity += el.quantity;
                            acc.total_price.value += el.total_price.value
                            if(hasPromotion(el)){
                                acc.total_promotion.applied_promotions = el.total_promotion.applied_promotions;
                            }
                        }
                        return acc;
                    }, {});
                    promotionProduct.unit_price.value = promotionProduct.total_price.value / promotionProduct.quantity;

                    if(hasPromotion(promotionProduct)){
                        product.setManualDiscountUnitPrice(promotionProduct.unit_price.value, false);
                        let reasonCode = OP.conf.reasonCodeMap[promotionProduct.total_promotion.applied_promotions[0]?.promotion_type] ?? false;
                        if(reasonCode !== false){
                            let reason = getReasonCodeByNameAndPurpose(reasonCodes, reasonCode, "DISCOUNT");
                            if(typeof reason !== "undefined"){
                                product.returnReasonID = reason.reasonID;
                                product.returnReason = reason;
                            }
                        }
                        product.priceDerivationRuleID = promotionProduct.total_promotion.applied_promotions[0].promotion_id;
                        product.onePromotion = true;
                        dispatch(updateProduct(product, true));
                    }else{
                        if(product.onePromotion){
                            product.resetManualDiscount();
                            delete product.returnReasonID;
                            delete product.returnReason;
                            delete product.priceDerivationRuleID;
                            delete product.onePromotion;
                            dispatch(updateProduct(product, true));
                        }
                    }
                });
                if(promoCodeSet && promoCode !== false){
                    let appliedPromoCode = quote?.promotions.find(el => el.promotion_name.toUpperCase() === promoCode.toUpperCase());
                    if(typeof appliedPromoCode === "undefined"){
                        let notAppliedPromoCode = quote?.not_applied_promotions.find(el => {
                            if(typeof el.coupon_code !== "undefined"){
                                return el.coupon_code.toUpperCase() === promoCode.toUpperCase();
                            }
                            return false;
                        });
                        if(typeof notAppliedPromoCode !== "undefined"){
                            dispatch(setOnePromotionPromoCode(promoCode, "error", notAppliedPromoCode.error_code));
                        }else{
                            dispatch(setOnePromotionPromoCode(promoCode, "error", "Unknown error"));
                        }
                    }
                }
                resolve();
            }, reject);
        });
    },
    validatePromoCode: (code, referenceNumber) => {
        return new Promise((resolve, reject) => {
            let dateTime = moment();
            dateTime.tz(OP.conf.timezone);
            let data = {
                clientName: OP.conf.clientName,
                referenceNumber,
                dateTime: dateTime.format("YYYY-MM-DD\THH:mm:ss"),
                code
            };
            fetchWithTimeout(process.env.REACT_APP_ONEPAY_URL + "/validateCoupon", {
                method: 'POST',
                headers: {
                    'Authorization': OP.conf.auth,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                timeout: OP.conf.getQuoteTimeout
            }).then(response => {
                return response.json();
            }).then((response) => {
                console.log('OnePromotion api response', response);
                if(response.status.responseStatus === "ok"){
                    resolve(response.data);
                }else{
                    reject(response);
                }
            }).catch((response) => {
                console.log('OnePromotion api request failed', response);
                reject(response);
            });
        });
    },
    getQuote: (items, referenceNumber, promoCode) => {
        return new Promise((resolve, reject) => {
            let dateTime = moment();
            dateTime.tz(OP.conf.timezone);
            let data = {
                clientName: OP.conf.clientName,
                items,
                currency: OP.conf.currency,
                referenceNumber,
                dateTime: dateTime.format("YYYY-MM-DD\THH:mm:ss")
            };
            if(promoCode !== false){
                data.vouchers = [{
                    code: promoCode
                }];
            }
            fetchWithTimeout(process.env.REACT_APP_ONEPAY_URL + "/getQuote", {
                method: 'POST',
                headers: {
                    'Authorization': OP.conf.auth,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                timeout: OP.conf.getQuoteTimeout
            }).then(response => {
                return response.json();
            }).then((response) => {
                console.log('OnePromotion api response', response);
                if(response.status.responseStatus === "ok"){
                    resolve(response.data);
                }else{
                    reject(response);
                }
            }).catch((response) => {
                console.log('OnePromotion api request failed', response);
                reject(response);
            });
        });
    },
    previousMixMatchProductsQuantity: 0,
    getPromotionableProducts: (productsInBasket, includeAll) => {
        return productsInBasket.reduce((acc, product) => {
            if(OP.productIsInExcludedCategory(product)){
                return acc;
            }
            if(includeAll || (product.hasMixMatchPromotion() && product.amount > 0 && product.hardDiscountable)){
                if(product.manualDiscountPercentage > 0 && product?.onePromotion !== true){
                    return acc;
                }

                let price = product.manualDiscountPercentage > 0 ? product.originalPriceWithVAT : product.vatPrice;
                acc.items.push(new OP.Item(product.data.code, product.amount, price, product.rowTotal, product.lineNumber, "STANDARD"));
                acc.products.push(product);
            }
            return acc;
        }, {
            items: [],
            products: []
        });
    },
    onDocumentInit: () => {
        OP.previousMixMatchProductsQuantity = 0;
    },
    onPromotionCodeSet: (state, store) => {
        if(state.rootReducer.onePromotionPromoCode !== false && state.rootReducer.onePromotionPromoCode?.status !== "pending"){
            return;
        }

        let promoCode = state.rootReducer.onePromotionPromoCode.code;
        let addPromotion = () => {
            OP.addPromotion(
                getProductsInBasket(state),
                state.rootReducer.reasonCodes,
                state.rootReducer.warehouse.code,
                store.dispatch,
                state.rootReducer.onePromotionPromoCode === false ? false : promoCode,
                true
            ).then(() => {
                store.dispatch({
                    type: APPLY_PROMOTIONS
                });
            }, (error) => {
                console.log("Failed to add a promotion", error);
            });
        }

        if(state.rootReducer.onePromotionPromoCode === false){
            addPromotion();
            return;
        }

        OP.validatePromoCode(promoCode, state.rootReducer.warehouse.code).then((data) => {
            console.log("validatePromoCode success", data);
            store.dispatch(setOnePromotionPromoCode(promoCode, "applied", "applied"));
            addPromotion();
        }, (response) => {
            console.log("validatePromoCode failed", response);
            let errorMessage = "Unknown error";
            if(response.status.statusCode === 400){
                errorMessage = "The coupon code is invalid";
            }else if(response.status.statusCode === 404){
                errorMessage = "Coupon does not exist";
            }
            store.dispatch(setOnePromotionPromoCode(promoCode, "error", errorMessage));
        });
    },
    onProductAdded: (state, store) => {
        const id = "onePromotion";
        if(state.rootReducer.productsInBasket.length === 0){
            OP.previousMixMatchProductsQuantity = 0;
        }
        if(typeof state.rootReducer.beforeApplyPromotionsEvents[id] === "undefined"){
            if(OP.conf === false){
                OP.setConf(state.rootReducer.erplyConf);
            }
            store.dispatch(addBeforeApplyPromotionsEvent((state) => {
                return new Promise((resolve, reject) => {
                    OP.addPromotion(
                        getProductsInBasket(state),
                        state.rootReducer.reasonCodes,
                        state.rootReducer.warehouse.code,
                        store.dispatch,
                        state.rootReducer.onePromotionPromoCode === false ? false : state.rootReducer.onePromotionPromoCode.code
                    ).then(resolve, (error) => {
                        console.log("Failed to add a promotion", error);
                        resolve();
                    });
                });
            }, id));
            setTimeout(() => {
                store.dispatch({
                    type: APPLY_PROMOTIONS
                });
            }, 1);
        }
    },
    productIsInExcludedCategory: (product) => {
        return OP.conf.excludedCategories.indexOf(product.data.seriesName) !== -1;
    }
}

export default OP;
