import React, {Component} from 'react';
import "../main.css"
import Translate from "localization/Translate";
import {connect} from "react-redux";
import {apiError, setLoading, switchUser, verifyUserSSOToken} from "../../redux/actions";
import {getEmployees} from "../../redux/actions";
import '../scrollbar.css';
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../uiComponents/WrappedPerfectScrollBar';
import Keyboard from "../../keyboard/Keyboard";
import Button from "../../buttons/Button";
import {Loader} from "../Loading";
import {getTranslate} from "../../localization/localizeSlice";


const mapStateToProps = state => {
    return {
        employees: state.rootReducer.employees,
        pointOfSaleID: state.rootReducer.posID,
        clientCode: state.rootReducer.user.clientCode,
        user: state.rootReducer.user,
        cashierMode: state.rootReducer.cashierMode,
        decathlonQRCodeSupervisor: window.AppConf?.decathlonQRCodeSupervisor || false,
        decathlonSSOQRCodeURL: window.AppConf?.decathlonSSOQRCodeURL || '',
        connectionHealth: state.rootReducer.connectionHealth,
        warehouse: state.rootReducer.warehouse,
        translate: getTranslate(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getEmployees: (pointOfSaleID) => {
            dispatch(getEmployees(pointOfSaleID));
        },
        verifyOfflinePin(pin, warehouseCode, onSuccess, onFailure, employee){
            const verifies = pin === warehouseCode.padStart(6,"9");
            console.log({verifyOfflinePin: verifies, pin, warehouseCode});
            if(verifies){
                dispatch(onSuccess([employee]));
            }else {
                dispatch(onFailure("Could not verify user!"));
            }
        },
        verifyUser: (clientCode, employeeID, password, onSuccess, onFailure) => {
            dispatch(setLoading(true));
            dispatch(switchUser(clientCode, password, (data) => {
                dispatch(setLoading(false));
                console.log('verify user', {clientCode, employeeID, password, data});
                if(employeeID == data[0].employeeID){
                    return onSuccess(data);
                }else{
                    return onFailure("Could not verify user!");
                }
            }, (error) => {
                dispatch(setLoading(false));
                return onFailure(error);
            }));
        },
        verifyUserToken: (accessToken, onSuccess, onFailure) => {
            dispatch(setLoading(true));
            dispatch(verifyUserSSOToken(accessToken, undefined, undefined, (data) => {
                    dispatch(setLoading(false));
                return onSuccess([{
                    employeeID: data.session.user.employeeId.toString(),
                    userID: data.session.user.id.toString(),
                    sessionKey: data.session.sessionKey
                }])
            }, (error) => {
                dispatch(setLoading(false));
                return onFailure(error);
            }));
        },
        skipLogon: (user, afterLogonAction) => {
            dispatch(afterLogonAction([user]));
        }
    }
};

class Logon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterInput: "",
            keyboardLayout: "letters",
            selectedEmployee: false,
            errorMessage: false,
            qrCodeLoading: true,
            qrCodeAvailable: true
        };

        this.keyboardClick = this.keyboardClick.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.setUser = this.setUser.bind(this);
        this.login = this.login.bind(this);
        this.qrMessageEvent = this.qrMessageEvent.bind(this);
    }

    componentDidMount() {
        if(!this.props.connectionHealth){
            let offlineUserName = "OfflineHost" + this.props.warehouse.code;
            console.log("setting offline user", {offlineUserName});
            this.setUser({
                id: 0,
                employeeID: "0",
                fullName: offlineUserName,
                employeeName: offlineUserName,
                firstName: "",
                lastName: offlineUserName,
                userID: "0",
                username: offlineUserName,
                userGroupID: "0"
            });
        }
        if (this.props.employees === false) {
            this.props.getEmployees(this.props.pointOfSaleID);
        }
        if(this.props.decathlonQRCodeSupervisor){
            setTimeout(() => {
                if(this._unmounted !== true){
                    if(this.props.cashierMode && !this.props.supervisor){
                        this.props.skipLogon(this.props.user, this.props.afterLogonAction);
                        return;
                    }
                    this.initQRCodeLogin();
                }
            },1);
        }else{
            if(this.props.cashierMode && !this.props.supervisor){
                setTimeout(() => {
                    if(this._unmounted !== true){
                        this.props.skipLogon(this.props.user, this.props.afterLogonAction);
                    }
                },1);
                return;
            }
            if(window.AppConf.devMode0192412412949){
                let that = this;
                this.props.verifyUser(this.props.clientCode, window.AppConf.devModeCredentials.user, window.AppConf.devModeCredentials.pin, this.props.afterLogonAction, function (error) {
                    that.setState({
                        errorMessage: this.props.translate("Could not verify user!")
                    });
                    return apiError(error);
                });
            }
        }
    }
    componentWillUnmount() {
        this._unmounted = true;
        if(this.state.qrCodeAvailable){
            window.removeEventListener("message", this.qrMessageEvent);
        }
    }

    getEmployeeDisplayName(employee) {
        return employee.firstName + ' ' + employee.lastName.substr(0, 1).toUpperCase();
    }

    setUser(employee) {
        this.setState({
            selectedEmployee: employee,
            keyboardLayout: "numbers",
            filterInput: "",
            passwordInput: ""
        });
    }

    keyboardClick(key) {
        let inputFieldValue = document.getElementsByName("logonFilter")[0].value;

        this.setState({
            filterInput: inputFieldValue
        });
    }

    selectUser(message) {
        return (
            <div>
                <h1 className={"bigUpperCaseHeading"}><Translate id={"logon"}/></h1>
                <div className={"logonContent"}>
                    <div style={{
                        "fontSize": "18px"
                    }}>
                        {message}
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Translate id={"Enter the first letters of your name to filter the list"}/>
                    <br/>
                    <br/>
                    <input type={"text"} name={"logonFilter"}
                           className={"searchInput underlineInput"}
                           value={this.state.filterInput}
                           readOnly={"readonly"}/>
                    <div style={{
                        "position": "absolute",
                        "bottom": "0",
                        "left": "0",
                        "right": "0"
                    }}>
                        <Keyboard options={{
                            layout: this.state.keyboardLayout,
                            inputFieldName: "logonFilter",
                            onChangeToLetters: () => {this.setState({keyboardLayout: "letters"})},
                            onChangeToNumbers: () => {this.setState({keyboardLayout: "numbers"})},
                            onClick: this.keyboardClick
                        }}/>
                    </div>
                </div>
            </div>
        );
    }

    login() {
        const employeeID = this.state.selectedEmployee.employeeID;
        console.log('verify user', {selectedEmployee: this.state.selectedEmployee});
        const password = document.getElementsByName('password')[0].value;
        const that = this;
        if(!this.props.connectionHealth){
            this.props.verifyOfflinePin(password, this.props.warehouse.code, this.props.afterLogonAction, (error) => {
                that.setState({
                    errorMessage: that.props.translate("Could not verify user!")
                });
                return apiError(error);
            }, this.state.selectedEmployee);
        }else{
            this.props.verifyUser(this.props.clientCode, employeeID, password, this.props.afterLogonAction, function (error) {
                that.setState({
                    errorMessage: that.props.translate("Could not verify user!")
                });
                return apiError(error);
            });
        }
    }

    tokenLogin(accessToken) {
        const that = this;
        this.props.verifyUserToken(accessToken, this.props.afterLogonAction, function (error) {
            that.setState({
                errorMessage: error
            });
            return apiError(error);
        });
    }

    enterPassword() {
        let errorMessage;
        if(this.state.errorMessage !== false){
            errorMessage = (
                <div className={"errorMessage"}>
                    {this.state.errorMessage}
                </div>
            );
        }
        return (
            <div>
                <h1 className={"bigUpperCaseHeading"}><Translate id={"Logon"}/></h1>
                <div className={"logonContent"}>
                    <div style={{
                        "fontSize": "18px"
                    }}>
                        <Translate id={"Enter your password"}/>
                    </div>
                    <input type={"password"}
                           name={"password"}
                           className={"underlineInput"}
                    />
                    {errorMessage}
                    <div style={{
                        "position": "absolute",
                        "bottom": "0",
                        "left": "0",
                        "right": "0"
                    }}>
                        <Keyboard options={{
                            layout: this.state.keyboardLayout,
                            inputFieldName: "password",
                            onChangeToLetters: () => {this.setState({keyboardLayout: "letters"})},
                            onChangeToNumbers: () => {this.setState({keyboardLayout: "numbers"})},
                            onDone: this.login
                        }}/>
                    </div>
                </div>
            </div>
        );
    }

    displayQRLogin(logonMessage){
        let errorMessage;
        if(this.state.errorMessage !== false){
            errorMessage = (
                <div className={"errorMessage"}>
                    {this.state.errorMessage}
                </div>
            );
        }
        if(this.state.qrCodeLoading){
            return <Loader/>;
        }

        return <div style={{
            position: 'relative',
            height: '100%'
        }}>
            <h2 style={{
                textAlign: 'center'
            }}>
                {logonMessage}
            </h2>
            {errorMessage}
            <iframe
                title={'QRCode'}
                src={this.props.decathlonSSOQRCodeURL}
                style={{
                    border: 'none',
                    margin: '0 auto',
                    display: 'block',
                    position: 'absolute',
                    inset: 0,
                    width: '100%',
                    height: '640px',
                    WebkitTransform: 'scale(0.8)'
                }}
            />
        </div>
    }

    initQRCodeLogin(){
        //Mock QR code login
        /*setTimeout(() => {
            this.tokenLogin("access_token");
        }, 2000);*/
        fetch(this.props.decathlonSSOQRCodeURL, {mode: "no-cors"}).then(() => {
            this.setState({
                qrCodeLoading: false,
                qrCodeAvailable: true
            });
            window.addEventListener("message", this.qrMessageEvent);
        }).catch(() => {
            this.setState({
                qrCodeLoading: false,
                qrCodeAvailable: false
            });
        })
    }

    qrMessageEvent(e) {
        if(e.origin !== new URL(this.props.decathlonSSOQRCodeURL).origin) return;
        console.log({accessToken: e.data});
        var accessToken = e.data;
        this.tokenLogin(accessToken);
    }

    render() {
        const logonMessage = typeof this.props.message !== 'undefined' ? this.props.message : <Translate id={"Logon to access the administration menu"}/>;
        if(this.props.decathlonQRCodeSupervisor && this.state.qrCodeAvailable){
            if(this.props.cashierMode && !this.props.supervisor){
                return <div/>;
            }
            return this.displayQRLogin(logonMessage);
        }
        const content = this.state.selectedEmployee === false ? this.selectUser(logonMessage) : this.enterPassword();
        let cancelButton;
        if(this.state.selectedEmployee !== false){
            cancelButton = (
                <div onClick={e => this.setState({
                    selectedEmployee: false,
                    errorMessage: false,
                    keyboardLayout: 'letters'
                })} style={{
                    position: 'absolute',
                    bottom: "10px",
                    width: "100%",
                    textAlign: "center"
                }}>
                    <Button type="button" className={"mediumButton grayButton"} name={<Translate id="cancel"/>}
                            arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
                </div>
            );
        }

        let employees;
        let filteredEmployees = this.props.employees;
        const that = this;
        if (this.props.employees !== false) {
            if (this.state.filterInput !== "") {
                const filterLength = this.state.filterInput.length;
                filteredEmployees = filteredEmployees.filter(function (employee) {
                    return that.getEmployeeDisplayName(employee).substr(0, filterLength).toLowerCase() === that.state.filterInput;
                });
            }else{
                if(that.state.selectedEmployee !== false){
                    filteredEmployees = [that.state.selectedEmployee];
                }
            }
            if(filteredEmployees.length === 1 && that.state.selectedEmployee === false){
                setTimeout(function (){
                    that.setUser(filteredEmployees[0]);
                }, 100);
            }
            employees = filteredEmployees.map(function (employee) {
                return (
                    <li key={employee.employeeID} onClick={e => {that.setUser(employee)}}>
                        {that.getEmployeeDisplayName(employee)}
                    </li>
                );
            });
        }

        return (
            <div>
                <PerfectScrollbar className={"adminLeft"} style={{position: 'absolute'}}>
                    <ul className={"boxList"}>
                        {employees}
                    </ul>
                    {cancelButton}
                </PerfectScrollbar>
                <div className={"adminRight"}>
                    {content}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logon);
