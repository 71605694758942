import React from 'react';
import './button.css';
import Image from "../theme/Image";

export default function ({className = '', image, title}) {
    return (
        <div className={"buttonContainer " + className} style={{
            overflow: 'hidden',
            boxShadow: '1px 3px 4px -1px #919191'
        }}>
            <div className={"blueBackground leftBox"}>
                <Image image={image} style={{
                    width: "45px",
                    verticalAlign: 'middle',
                    margin: 'auto'
                }} alt={"bank card"}/>
            </div>
            <div className={"flencheBackgroundLeft"}/>
            <span className={"uppercaseText"} style={{
                marginLeft: '20px',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <span style={{margin: 'auto'}}>{ title }</span>
            </span>
        </div>
    );
}
