import { Component } from 'react';
import {connect} from "react-redux";
import {
    addPeripheral,
    findProduct,
    removePeripheral,
    removeProduct,
    setLoading,
    showDialogue,
    updatePeripheral
} from "../../redux/actions";
import RFIDService from "../../redux/rfid/RFIDService";
import Peripheral from "../../redux/dto/peripheral";
import {getPeripheral, getUnknownItemCode} from "../../redux/selectors";
import {UPDATE_RFID_STATUS} from "../../redux/actionTypes";

const mapStateToProps = state => {
    const tillNumber = state.rootReducer.pos !== false ? state.rootReducer.pos.name.substr(-2, 2) : "";
    const productsInBasket = state.rootReducer.productsInBasket.reduce(function (acc, product) {
        acc.push(product.data);
        return acc;
    }, []);
    return {
        warehouseCode: state.rootReducer.warehouse.code,
        tillNumber: tillNumber,
        nextInvoiceNo: state.rootReducer.nextInvoiceNo,
        clientCode: state.rootReducer.user.clientCode,
        pos: state.rootReducer.pos,
        DKTUnknownItemCode: getUnknownItemCode(),
        scanning: state.rootReducer.mode === 'scan' || state.rootReducer.mode === 'screenSaver',
        scanned_code_regex_parser: state.rootReducer.erplyConf.touchpos_scanned_code_regex_parser || false,
        productsInBasket: productsInBasket,
        peripherals: state.rootReducer.peripherals,
        warehouseID: state.rootReducer.pos.warehouseID,
        pointOfSaleID: state.rootReducer.pos.pointOfSaleID,
        securityTagCodes: state.rootReducer.erplyConf.DKTRfidSecurityTagCodes || "",
        hangerTagCodes: RFIDService.parameters().hangerTagCodes,
        handleSecurityTagsAfterScanning: window.AppConf.handleSecurityTagsAfterScanning || false
    }
};

const mapDispatchToProps = function (dispatch) {
    return {
        findProduct: (code, warehouseID, pointOfSaleID) => {
            dispatch(setLoading(true));
            dispatch(findProduct(code, warehouseID, pointOfSaleID, false, undefined, undefined, undefined, () => {
                dispatch(setLoading(false));
            }));
        },
        removeProduct: (product) => dispatch(removeProduct(product)),
        addPeripheral: () => {
            const peripheral = new Peripheral('rfid');
            peripheral.setConnecting();
            dispatch(addPeripheral(peripheral));
        },
        removePeripheral: () => dispatch(removePeripheral('rfid')),
        updatePeripheral: (peripheral) => {
            dispatch(updatePeripheral(peripheral));
        },
        onDeviceTimeout: (onConfirm) => {
            dispatch(showDialogue('Continue reading', '', 'Ok', onConfirm, onConfirm));
        },
        setLoading: (loading) => {
            dispatch(setLoading(loading));
        },
        displaySecurityTagDialogue: (tag, onDone) => {
            const showMessage = () => {
                dispatch(showDialogue('Attention!', '<span style="font-size: 24px;">Hardtag detected, please call the teammate</span>', 'Ok', () => {
                    dispatch(showDialogue('', 'Can you confirm all Hardtags are removed?', 'Yes', () => {
                        onDone();
                    }, () => {
                        showMessage();
                    },"No",false,undefined,undefined,true));
                }, () => {},undefined,false,undefined,undefined,true));
            }

            showMessage();
        },
        updateRFIDStatus: (status) => {
            dispatch({
                type: UPDATE_RFID_STATUS,
                payload: status
            })
        }
    }
};

class RFID extends Component{
    constructor(props){
        super(props);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.afterConnectionSuccess = this.afterConnectionSuccess.bind(this);
        this.afterConnectionLost = this.afterConnectionLost.bind(this);
    }

    afterConnectionSuccess() {
        console.log('RFID: afterConnectionSuccess');
        const peripheral = getPeripheral(this.props.peripherals, 'rfid');
        peripheral.setConnected();
        this.props.updatePeripheral(peripheral);
    }

    afterConnectionLost() {
        console.log('RFID: afterConnectionLost');
        const peripheral = getPeripheral(this.props.peripherals, 'rfid');
        peripheral.setConnectionFailed();
        this.props.updatePeripheral(peripheral);
    }

    componentDidMount() {
        const component = this;
        RFIDService.updatePOSInformation(this.props.warehouseCode, this.props.tillNumber, this.props.nextInvoiceNo, this.props.clientCode, this.props.pos, this.props.DKTUnknownItemCode);
        RFIDService.updateState(this.props.scanning, this.props.productsInBasket);
        RFIDService.addToScannerQueue = function (code) {
            component.props.findProduct(code, component.props.warehouseID, component.props.pointOfSaleID);
        };
        RFIDService.setStatus = (status) => {
            RFIDService.status = status;
            component.props.updateRFIDStatus(status);
        }
        RFIDService.handleSecurityTagsAfterScanning = this.props.handleSecurityTagsAfterScanning;
        RFIDService.securityTagCodes = this.props.securityTagCodes.split(',');
        RFIDService.securityTagFound = function (tag) {
            if(!RFIDService.handleSecurityTagsAfterScanning){
                component.props.displaySecurityTagDialogue(tag, () => {
                    RFIDService.cancelRFIDLine(tag, () => {
                        console.log('RFID: security tag line cancelled');
                        RFIDService.lastCancelledTag = false;
                    });
                });
            }
        }
        RFIDService.hangerTagCodes = this.props.hangerTagCodes.split(',');
        RFIDService.removeProduct = function (product) {
            RFIDService.log('removeProduct', product);
            component.props.removeProduct(product);
        };
        this.props.addPeripheral();
        RFIDService.onDeviceTimeout = () => {
            RFIDService.startTransaction(true);
        };
        RFIDService.setLoading = (loading) => {
            component.props.setLoading(loading);
        };
        RFIDService.initService(this.afterConnectionSuccess, this.afterConnectionLost);
        window.rfidService = RFIDService;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // return true;
        RFIDService.updatePOSInformation(this.props.warehouseCode, this.props.tillNumber, this.props.nextInvoiceNo, this.props.clientCode, this.props.pos, this.props.DKTUnknownItemCode);
        RFIDService.updateState(this.props.scanning, this.props.productsInBasket);
        RFIDService.initService(this.afterConnectionSuccess, this.afterConnectionLost);

        // TODO: add once!!!
        /*const peripheral = getPeripheral(this.props.peripherals, 'rfid');
        if(!RFIDService.isConnected()){
            console.log('peripheral', peripheral);
            const that = this;
            console.log('RFID: setting interval for wait connect');
            const waitConnect = setInterval(function () {
                console.log('RFID: waitConnect', peripheral);
                if(RFIDService.isConnected()){
                    clearInterval(waitConnect);
                    if(!peripheral.isConnected()){
                        peripheral.setConnected();
                        that.props.updatePeripheral(peripheral);
                    }
                }else{
                    if(!RFIDService.isAvailable() && !peripheral.hasNoConnection()){
                        clearInterval(waitConnect);
                        peripheral.setConnectionFailed();
                        that.props.updatePeripheral(peripheral);
                    }
                }
            }, 2000);
        }else{
            if(!RFIDService.isAvailable() && !peripheral.hasNoConnection()){
                peripheral.setConnectionFailed();
                this.props.updatePeripheral(peripheral);
            }
        }*/
    }

    componentWillUnmount() {
        window.rfidService = null;
        this.props.removePeripheral();
    }

    render() {
        return ''
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RFID)
