import React from 'react';
import "../../main/main.css";
import Translate from "localization/Translate";
import Button from "../../buttons/Button";
import { useActionListener } from 'redux-action-listener-hook';
import {isCouponCode} from "./util";
import paybackCouponImg from "./img/coupon.png";
import {addErrorAlert} from "../../redux/actions";

const ScanCoupon = (props) => {
    useActionListener('SCANNER_DETECTED', (dispatch, action) => {
        console.log({useActionListener: action});
        const scannedCode = action.payload;
        if(isCouponCode(scannedCode)){
            if(props.coupons.indexOf(scannedCode) !== -1){
                dispatch(addErrorAlert('Coupon is already in the transaction!'));
            }else{
                props.addPaybackCoupon(scannedCode);
            }
        }else{
            dispatch(addErrorAlert('Not a proper Payback coupon number!'));
        }
    });

    const hasCoupons = props.coupons.length > 0;

    const paymentButtonName = hasCoupons ? "Continue to payment" : "Continue without enter";
    let paymentButton = (
        <Button
            onClick={e => {
                props.paybackInputDone();
            }}
            type="button"
            className={"largeButton yellowButton"}
            name={<Translate id={paymentButtonName}/>}
            arrow="right"
            flencheClass={"flencheSmallRightBottom yellowFlenche"}
            style={{
                marginTop: '20px'
            }}
        />
    );


    const enterCouponCodeButtonName = hasCoupons ? "Enter another Payback coupon code" : "Enter the Payback print coupon code";
    let enterCouponCodeButton = (
        <div style={{marginBottom: "20px"}} onClick={e => {
            props.enterCouponCode(props.coupons);
        }}>
            <Button type="button" className={"largeButton blueButton"} name={<Translate id={enterCouponCodeButtonName}/>}
                    arrow="right" flencheClass={"flencheSmallRightBottom blueFlenche"}/>
        </div>
    );

    let orLine = (
        <div style={{
            width: "300px",
            margin: "auto"
        }}>
            <div className={"diagonalLine"} style={{
                float: "left",
                marginTop: "40px"
            }}/>
            <span style={{
                textTransform: "uppercase",
                fontStyle: "italic",
                color: "#0082C3",
                fontSize: "20px"
            }}>
                            <Translate id="or"/>
                        </span>
            <div className={"diagonalLine"} style={{
                float: "right",
                marginTop: "10px"
            }}/>
        </div>
    );
    return (
        <div>
            <span className={"boldUppercaseText"}>
                <Translate id={"Activate your"}/>
            </span>
            <br/>
            <span className={"boldUppercaseText"}>
                <Translate id={"Payback coupons"}/>
            </span>
            <br/>
            <div style={{
                "marginBottom": "20px"
            }}/>
            <img src={paybackCouponImg} style={{
                maxWidth: "80%",
                maxHeight: "100px",
                display: "block",
                margin: "0 auto"
            }} alt="scanPaybackCard"/>
            <span className={"boldUppercaseText"}>
                <Translate id={"by scan or"}/>
            </span>
            <br/>
            <span className={"boldUppercaseText"}>
                <Translate id={"in the Payback app"}/>
            </span>
            <br/>
            <span style={{
                fontStyle: "italic",
                fontSize: "12px"
            }}><Translate id={"Activate eCoupons within the PAYBACK app or on PAYBACK.de."}/></span>
            <br/>
            <br/>
            <div style={{}}>
                {orLine}
                <div style={{
                    position: "absolute",
                    width: "100%",
                    bottom: "18px"
                }}>
                    {enterCouponCodeButton}
                    {paymentButton}
                </div>
            </div>
        </div>
    );

}

export default ScanCoupon;