import React, {Component} from 'react';
import {connect} from "react-redux";
import {TailSpin} from 'react-loader-spinner';

export const Loader = ({position = 'fixed'}) => <TailSpin
    color="var(--loader)"
    height={100}
    width={100}
    wrapperStyle={{
        position,
        left: "50%",
        top: "50%",
        margin: "-50px 0 0 -50px"
    }}
/>
const mapStateToProps = function (state) {
    return {
        loading: state.rootReducer.loading
    }
};

const mapDispatchToProps = function (dispatch) {
    return {

    }
};

class Loading extends Component {
    render() {
        if(this.props.loading){
            return (
                <div style={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    background: 'rgba(0,0,0,0.2)',
                    zIndex: 10
                }}>
                    <Loader/>
                </div>
            );
        }else{
            return null;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
