import React, { Component } from 'react';
import './screenSaver.css';
import scanItemImg from "./scanItem.png";
import Translate from "localization/Translate";
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {
        erplyConf: state.rootReducer.erplyConf,
        uiCustomization: state.rootReducer.uiCustomization,
        activeLanguage: state.localize.activeLocale
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

class ScreenSaver extends Component{
    render() {
        return (
            <div style={{
                backgroundColor: "#f2f2f2"
            }} className={"touchToStartWrapper"}>
                <div className={"touchToStartContainer"}>
                    <span>
                        {
                            this.props.uiCustomization.screenSaver.text[this.props.activeLanguage] ||
                            <div>
                                <div><Translate id={"Welcome"}/>!</div>
                                <div><Translate id={"Scan an item to start"}/></div>
                            </div>
                        }
                    </span>
                    {
                        this.props.uiCustomization.screenSaver.image === 'default' ?
                            <img src={scanItemImg} alt={"screenSaver"}/> :
                            <img src={this.props.erplyConf.invoiceLogoURL} alt={"screenSaver"}/>
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSaver);