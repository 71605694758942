import {getProductsTotalSum, getReasonCodeByID, getVatRateByID} from "../../selectors";
import CTR from "../../../util/receiptTemplate";
import moment from "moment-timezone";
import crypto from 'crypto-browserify';
import {APP_URL} from "../../appConstants";
import Decimal from "decimal.js";

const yocuda = {
    generateSignature: (key, expires, secret) => {
        let _trim = (str) => {
            return str.replace(/^\s+|\s+$/g, "");
        };
        const secretDecoded = Buffer.from(secret, "base64");
        const hmac = crypto.createHmac('sha256', secretDecoded);
        const message = key + "\n" + expires;
        hmac.update(message);
        console.log({key, expires, secret, secretDecoded: atob(secret)}, message);
        return _trim(hmac.digest('base64'));
    },
    sendReceipt: (state, receiptDelivery) => {
        return new Promise(async (resolve, reject) => {
            console.log("Sending Yocuda receipt!");
            if (typeof window.AppConf?.yocudaKey === "undefined" ||
                typeof window.AppConf?.yocudaSecret === "undefined"
            ) {
                return;
            }
            let expires = Math.floor(Date.now() / 1000) + 300;
            let receipt = await yocuda.createDocument(state, receiptDelivery)
            yocuda.request(expires, window.AppConf.yocudaKey, window.AppConf.yocudaSecret, receipt).then(resolve, reject);
        });
    },
    sendDuplicateReceipt: (document, email, state) => {
        return new Promise((resolve, reject) => {
            if( typeof window.AppConf?.yocudaKey === "undefined" ||
                typeof window.AppConf?.yocudaSecret === "undefined"
            ){
                reject("Yocuda receipt credentials not configured!");
                return;
            }
            let expires = Math.floor(Date.now() / 1000) + 300;
            yocuda.request(expires, window.AppConf.yocudaKey, window.AppConf.yocudaSecret, yocuda.createDuplicateDocument(document, email, state)).then(resolve, (error) => {
                console.log("Yocuda send duplicate receipt failed", error);
                reject(error);
            });
        });
    },
    request: (expires, key, secret, document) => {
        return new Promise((resolve, reject) => {
            const signature = yocuda.generateSignature(key, expires, secret);

            let url = "https://api.ereceipts.co.uk/receipts?key=" + key + "&signature=" + encodeURIComponent(signature) + "&expires=" + expires;
            fetch(url, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: document
            }).then(response => {
                response.json().then((result) => {
                    if(!response.ok){
                        console.log("Yocuda failed", {result});
                        reject(result.errors?.[0]?.description ?? "Yocuda error");
                    }else{
                        resolve(result);
                    }
                }, reject);
            }, (reason) => {
                console.log("Yocuda error", reason);
            }).catch(() => {
                console.log("Yocuda receipt API call failed");
                reject();
            });
        });
    },
    requestGoErp: (expires, key, secret, document, state) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            const signature = yocuda.generateSignature(key, expires, secret);
            const params = {
                expires,
                key,
                signature,
                document
            };
            for ( let key in params) {
                formData.append(key, params[key]);
            }

            let url = state.rootReducer.erplyServiceEndpoints.goerp.url.split("/api")[0] + `/${state.rootReducer.user.clientCode}/en/yocuda-main-page.json`;
            let sessionKey = state.rootReducer.user.sessionKey;

            if(window.location.origin.indexOf('localhost') !== -1){
                url = APP_URL + "postProxy?u=" + btoa(url);
            }

            fetch(url, {
                method: 'POST',
                headers: {
                    sessionKey
                },
                body: formData
            }).then(response => response.json()).then(response => {
                resolve(response);
            }).catch(() => {
                console.log("Yocuda receipt API call failed");
                reject();
            });
        });
    },
    getPaymentType: (type) => {
        let types = ["cash", "card", "giftcard", "voucher", "other"];
        let formattedType = type.toLowerCase();
        return types.indexOf(formattedType) !== -1 ? formattedType : "other";
    },
    getProductsTaxTotals: (state) => {
        let vatrateIDToRate = {};
        let total = state.rootReducer.productsInBasket.reduce((acc, product) => {
            if(typeof acc[product.data.vatrateID] === "undefined"){
                let vatrate = getVatRateByID(state.rootReducer.vatRates, product.data.vatrateID);
                if(typeof vatrate === "undefined"){
                    vatrate = {
                        name: "vat",
                        rate: product.data.vatrate
                    }
                }
                vatrateIDToRate[product.data.vatrateID] = vatrate;
                acc[product.data.vatrateID] = new Decimal(0);
            }
            let rowVAT = new Decimal(product.rowVAT);
            acc[product.data.vatrateID] = acc[product.data.vatrateID].plus(rowVAT);
            return acc;
        }, {});

        return Object.keys(total).reduce((acc, el) => {
            let isTaxOnMargin = typeof vatrateIDToRate[el].attributes !== "undefined" ? typeof vatrateIDToRate[el].attributes.find(el => {
                return el.attributeName === "TaxOnMargin" && el.attributeValue === "1";
            }) !== "undefined" : false;
            acc.push({
                name: vatrateIDToRate[el].name,
                amount: isTaxOnMargin ? "0" : total[el].toDecimalPlaces(4).toString(),
                rate: vatrateIDToRate[el].rate
            })
            return acc;
        }, []);
    },
    getVatRate: (state, vatrateID) => {
        let productVatRate = getVatRateByID(state.rootReducer.vatRates, vatrateID);
        if(typeof productVatRate !== "undefined"){
            return productVatRate;
        }
        return {
            rate: "0",
            name: "vat"
        };
    },
    createDocument: async (state, receiptDelivery) => {
        let hasUsedProducts = false;
        let items = state.rootReducer.productsInBasket.reduce((acc, product) => {
            let vatrate = yocuda.getVatRate(state, product.data.vatrateID);
            let isUsedProduct = product.data?.isUsedProduct === 1;
            if(isUsedProduct){
                hasUsedProducts = true;
            }
            let item = {
                name: product.name,
                quantity: product.amount,
                total: product.rowTotal,
                taxes: [{
                    name: vatrate.name,
                    amount: isUsedProduct ? "0" : product.rowVAT.toFixed(2),
                    rate: product.data.vatrate
                }],
                properties: {
                    product_code: product.data.code
                }
            };

            if (typeof product.data.RFIDTag !== "undefined" && product.data.RFIDTag.SERIAL_NUMBER !== "NULL_VALUE") {
                item.properties.RFID = product.data.RFIDTag.SERIAL_NUMBER;
            }

            if (product.discount !== 0) {
                item.discounts = [{
                    name: typeof product.manualDiscountReason !== "undefined" ? product.manualDiscountReason.name : product.returnReason.name,
                    amount: product.manualDiscountTotal * -1
                }];
            }
            acc.push(item);
            return acc;
        }, []);
        let payments = state.payment.previousPayments.reduce((acc, payment) => {
            acc.push({
                method: yocuda.getPaymentType(payment.type),
                amount: payment.sumPaid
            });
            return acc;
        }, []);
        let data = {
            items,
            localtime: moment().format(),
            store_reference: window.AppConf.preprod ? "1" : state.rootReducer.warehouse.code,
            total: getProductsTotalSum(state.rootReducer.productsInBasket),
            transaction_id: CTR.lastReceiptNumber !== false ? CTR.lastReceiptNumber : state.rootReducer.nextInvoiceNo,
            taxes: yocuda.getProductsTaxTotals(state),
            payments,
            properties: {
                email_settings: {
                    send: receiptDelivery.type === 'email'
                }
            }
        }
        if(hasUsedProducts){
            /*let receiptPng = await CTR.createPng(CTR.lastReceipt, 800);
            data.attachments = {
                pos_receipt: {
                    content_type: "image/png",
                        data: receiptPng
                }
            }*/
        }
        if (receiptDelivery.type === "email") {
            data.identifier = receiptDelivery.email;
        }

        return JSON.stringify(data);
    },
    createDuplicateDocument: (document, email, state) => {
        let c = 0;
        let items = document.rows.reduce((acc, product) => {
            let vatrate = yocuda.getVatRate(state, product.vatrateID);
            console.log({vatrate});
            let item = {
                name: product.itemName,
                quantity: product.amount,
                total: product.rowTotal,
                taxes: [{
                    name: vatrate.name,
                    amount: product.rowVAT.toFixed(2),
                    rate: vatrate.rate
                }],
                properties: {
                    product_code: product.code
                }
            };
            let RFIDAttribute = document.attributes.find(el => el.attributeName === 'RFIDTag' + c);
            if(typeof RFIDAttribute !== "undefined"){
                let RFIDTag = JSON.parse(RFIDAttribute.attributeValue);
                if(RFIDTag.SERIAL_NUMBER !== "NULL_VALUE"){
                    item.properties.RFID = RFIDTag.SERIAL_NUMBER;
                }
            }

            if(product.discount !== "0"){
                let reason = "Discount";
                if(product.returnReasonID !== "0"){
                    let reasonCode = getReasonCodeByID(state.rootReducer.reasonCodes, product.returnReasonID);
                    if(typeof reasonCode !== "undefined"){
                        reason = reasonCode.name;
                    }
                }

                item.discounts = [{
                    name: reason,
                    amount: (product.finalPriceWithVAT / (100 - parseInt(product.discount)) - product.finalPriceWithVAT) * -1
                }];
            }
            acc.push(item);
            c++;
            return acc;
        }, []);
        console.log("Yocuda create duplicate document", {items});
        let payments = document.payments.reduce((acc, payment) => {
            acc.push({
                method: yocuda.getPaymentType(payment.type),
                amount: payment.sum
            });
            return acc;
        }, []);

        let receiptNumberAttribute = document.attributes.find(el => el.attributeName === 'receiptNumber');
        let receiptNumber = typeof receiptNumberAttribute !== "undefined" ? receiptNumberAttribute.attributeValue : document.number;

        let taxes = document.vatTotalsByTaxRate.reduce((acc, el) => {
            let vatrate = yocuda.getVatRate(state, el.vatrateID.toString());
            acc.push({
                name: vatrate.name,
                amount: el.total.toFixed(4),
                rate: vatrate.rate
            })
            return acc;
        }, []);
        console.log("Yocuda create duplicate document", {taxes});

        let data = {
            transaction_id: receiptNumber,
            duplicate: true,
            items,
            localtime: moment().format(),
            store_reference: window.AppConf.preprod ? "1" : state.rootReducer.warehouse.code,
            total: document.total,
            taxes,
            payments,
            properties: {
                email_settings: {
                    send: true
                }
            },
            identifier: email
        }

        console.log("Yocuda duplicate receipt data", data);

        return JSON.stringify(data);
    }
}

export default yocuda;