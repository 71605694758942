import React, {Component} from 'react';
import './login.css'
import Button from "../buttons/Button";
import {Redirect} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import {
    addErrorAlert,
    changeOperationMode,
    changeView,
    checkPOSDayOpened,
    closeQCO,
    getAllowedWarehouses,
    getCompanyInfo,
    getCurrencies,
    getEmployees,
    getErplyConfiguration,
    getErplyServiceEndpoints,
    getPointsOfSale,
    getProductCategories,
    getProductGroups,
    getReasonCodes,
    getUserRights,
    getVatRates,
    login,
    oAuthLogin,
    openLogonModal,
    openModal,
    POSOpenDay,
    refreshUserSession,
    setLoading,
    setPosDayOpened,
    setPOSID,
    setTransactionUser,
    setWarehouse,
    showDialogue,
    showSelectDialogue,
    verifyUserSSOToken
} from "../redux/actions";
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import {getPaymentTypes} from "../redux/payment/actions";
import * as uuid from "uuid";
import {
    OPEN_POS_OPEN_DAY_VIEW,
    POS_DAY_OPEN_SUCCESS,
    SET_POS_DATA,
    SET_USER,
    UPDATE_USER_SESSION
} from "../redux/actionTypes";
import OpenDay from "../main/OpenDay";
import Keyboard from "../keyboard/Keyboard";
import ChooseOperationMode from "./ChooseOperationMode";
import {DKT_SSO_URL, ERPLY_AUTH_API_REGIONS} from "../redux/appConstants";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from 'uiComponents/WrappedPerfectScrollBar.js';
import configurationLoader, {checkLocalAppVersion} from "./configurationLoader";
import {addOrUpdate, get as getFromDB, getDB} from "../util/indexedDBConnector";
import {useTrackConnectionStatus} from "../hooks/useTrackConnectionStatus";
import {EI} from "../redux/erplyInstaller/main";
import {timers} from "../util/timers";
import {checkAppMainPreCache, isCashierModeForced} from "../util/helperFunctions";

const mapStateToProps = function (state) {
    return {
        view: state.rootReducer.view,
        translate: getTranslate(state),
        theme: state.rootReducer.theme,
        decathlonSSO: window.AppConf?.decathlonSSO || false,
        decathlonQRCodeLogin: process.env.REACT_APP_ERPLY_MODE !== "1" && (window.AppConf?.decathlonQRCodeLogin || false),
        decathlonSSOQRCodeURL: window.AppConf?.decathlonSSOQRCodeURL || false,
        clientCode: window.AppConf?.clientCode || false,
        preprod: window.AppConf.preprod || false,
        user: state.rootReducer.user,
        pos: state.rootReducer.pos,
        connectionHealth: state.rootReducer.connectionHealth,
        operationMode: state.rootReducer.operationMode,
        cashierMode: state.rootReducer.cashierMode,
        cashierModeForced : isCashierModeForced()
    }
};

const mapDispatchToProps = function (dispatch) {
    let setPos = (user, translate, autoLoginPOSID, autoLoginPOSName) => {
        return new Promise((resolve, reject) => {
            dispatch(getPointsOfSale((pointsOfSale) => {
                return getAllowedWarehouses(user.userID, (allowedWarehouses) => {
                    const setPosByID = (posID, setLoadingOnSuccess) => {
                        console.log("setPosByID", {posID});
                        let pos = pointsOfSale.find(el => el.pointOfSaleID === posID);
                        if(typeof pos !== "undefined"){
                            dispatch({
                                type: SET_POS_DATA,
                                payload: pos
                            });
                            if(setLoadingOnSuccess){
                                dispatch(setLoading(true));
                            }
                            resolve(pos);
                        }else{
                            reject();
                        }
                    }

                    const getPosByName = (posName) => {
                        console.log("setPosByName", {posName});
                        let pos = pointsOfSale.find(el => el.name === posName);
                        if(typeof pos !== "undefined"){
                            return pos;
                        }else{
                            return false;
                        }
                    }

                    let displayPOSSelect = () => {
                        dispatch(setLoading(false));
                        dispatch(changeView('app'));

                        let allowedPointsOfSale = pointsOfSale.reduce((acc, el) => {
                            if(typeof allowedWarehouses.find(warehouse => parseInt(warehouse.warehouseID) === parseInt(el.warehouseID)) !== "undefined"){
                                acc.push({
                                    name: el.name,
                                    value: el.pointOfSaleID
                                });
                            }
                            return acc;
                        }, []);
                        return showSelectDialogue(
                            'Select POS',
                            allowedPointsOfSale,
                            (posID, posName) => {
                                dispatch(showDialogue(
                                    translate("Are you sure you want to select till", {tillName: posName}),
                                    ' ',
                                    'Yes',
                                    () => {
                                        dispatch(changeView('login'));
                                        setPosByID(posID, true);
                                    },
                                    () => {
                                        dispatch(displayPOSSelect());
                                    },
                                    'No',
                                    false,
                                    undefined,
                                    undefined,
                                    true
                                ));
                            },
                            e => {
                                reject();
                            },
                            false
                        );
                    }
                    if(autoLoginPOSID !== "0"){
                        setPosByID(Number(autoLoginPOSID), false);
                        return {
                            type: "SET_POS_FROM_AUTOLOAD"
                        }
                    }else if(autoLoginPOSName !== false){
                        let pos = getPosByName(autoLoginPOSName);
                        if(pos !== false){
                            dispatch({
                                type: SET_POS_DATA,
                                payload: pos
                            });
                            resolve(pos);
                            return {
                                type: "SET_POS_FROM_AUTOLOAD"
                            }
                        }else{
                            return displayPOSSelect();
                        }
                    }else{
                        return displayPOSSelect();
                    }
                }, (message) => {
                    reject();
                    return addErrorAlert(message);
                });
            }, (message) => {
                reject();
                return addErrorAlert(message);
            }));
        });
    };

    return {
        login: (values, translate, operationMode, cashierMode, user=false, autoLoginPOSID='0', autoLoginPOSName=false) => {
            let onLoginSuccess = (user) => {
                setTimeout(() => {
                    onLoginSuccessDelayed(user);
                },1);
            }
            let onLoginSuccessDelayed = (user) => {
                setPos(user, translate, autoLoginPOSID, autoLoginPOSName).then((pos) => {
                    dispatch(setPOSID(pos.pointOfSaleID));
                    dispatch(getCompanyInfo());
                    dispatch(getReasonCodes());
                    dispatch(getVatRates());
                    dispatch(getCurrencies());
                    dispatch(getPaymentTypes());
                    dispatch(getEmployees(pos.pointOfSaleID));
                    dispatch(getProductGroups());
                    dispatch(getProductCategories());
                    dispatch(getUserRights(user.userID, pos.pointOfSaleID, (records) => {
                        return { type: 'USER_RIGHTS_SUCCESS' }
                    }, () => {
                        return { type: 'USER_RIGHTS_FAIL' }
                    }));
                    dispatch(setWarehouse(pos.warehouseID, [warehouseData => {
                        return getErplyConfiguration((erplyConf) => {
                            dispatch(getErplyServiceEndpoints(() => {
                                configurationLoader(dispatch, pos, cashierMode ? "cashier" : operationMode, erplyConf);
                            }));
                            dispatch(setLoading(false));
                            dispatch(changeView('app'));

                            let openDay = (openedSum, employeeID) => {
                                return POSOpenDay(pos.pointOfSaleID, openedSum, function (data) {
                                    dispatch(setTransactionUser(false));
                                    let dayID = data[0].dayID;
                                    setTimeout(() => {
                                        dispatch({
                                            type: POS_DAY_OPEN_SUCCESS,
                                            openedSum,
                                            dayID,
                                            openedUnixTime: data[0].openedUnixTime
                                        });
                                    },1);
                                    return setPosDayOpened(dayID);
                                }, function () {
                                    setTimeout(() => {
                                        dispatch(addErrorAlert('opening the day failed'));
                                    }, 100);
                                    return closeQCO();
                                }, employeeID);
                            };
                            let checkPOSDay = (autoOpen) => {
                                dispatch(setLoading(true));
                                dispatch(checkPOSDayOpened(pos.pointOfSaleID, function (dayOpened, dayID) {
                                    dispatch(setLoading(false));
                                    if (dayOpened) {
                                        return setPosDayOpened(dayID);
                                    } else {
                                        if(typeof autoOpen === "function"){
                                            autoOpen((shouldAutoOpen) => {
                                                if(shouldAutoOpen){
                                                    dispatch(openDay(0));
                                                }else{
                                                    displayOpenDayView();
                                                }
                                            })
                                        }else{
                                            if(autoOpen){
                                                return openDay(0);
                                            }else{
                                                displayOpenDayView();
                                            }
                                        }
                                    }
                                    return {
                                        type: "POS_DAY_NOT_OPENED"
                                    }
                                }, function () {
                                    dispatch(setLoading(false));
                                    setTimeout(() => {
                                        dispatch(addErrorAlert('opening the day failed'));
                                    }, 2000);
                                    return closeQCO();
                                }));
                            };

                            let displayOpenDayView = () => {
                                let openDayModal = (employeeID=false) => {
                                    let openDayModalID = uuid.v4();
                                    dispatch({
                                        type: OPEN_POS_OPEN_DAY_VIEW
                                    });
                                    dispatch(openModal({
                                        content:
                                            <OpenDay
                                                modalID={openDayModalID}
                                                pos={pos}
                                                onConfirm={(openedSum) => {
                                                    dispatch(openDay(openedSum, employeeID));
                                                }}
                                                onCancel={() => {
                                                    dispatch(closeQCO());
                                                }}
                                            />,
                                        id: openDayModalID,
                                        className: "Administration",
                                        onClose: function () {
                                            dispatch(closeQCO());
                                        },
                                        canClose: true
                                    }));
                                }

                                if(!cashierMode){
                                    dispatch(openLogonModal("Log in to declare cash", (data) => {
                                        dispatch(setTransactionUser(data[0]));
                                        openDayModal(data[0].employeeID);
                                    }))
                                }else{
                                    openDayModal();
                                }
                            }

                            if(process.env.REACT_APP_ERPLY_MODE !== "1"){
                                if(operationMode === 'lite'){
                                    checkPOSDay(true);
                                }else if(!cashierMode){
                                    checkPOSDay((onSelect) => {
                                        dispatch(showDialogue("Do you want to declare cash?", "", "Yes", () => {
                                            onSelect(false);
                                        }, () => {
                                            onSelect(true);
                                        }, "No", false, undefined, undefined, true));
                                    });
                                }else{
                                    checkPOSDay(false);
                                }
                            }else{
                                checkPOSDay(false);
                            }
                        }, () => {
                            dispatch(setLoading(false));
                        });
                    }]));
                }, () => {
                    dispatch(setLoading(false));
                });
            };

            let onLoginFailure = () => {
                dispatch(setLoading(false));
            };

            let onSessionTimeout = (user) => {
                let handleRefreshUserSession = () => {
                    dispatch(refreshUserSession(user.clientCode, values.username, values.password, () => {
                        return closeQCO();
                    }, () => {
                        handleRefreshUserSession();
                    }));
                }
                handleRefreshUserSession();
            };

            dispatch(setLoading(true));
            if(user !== false){
                dispatch({
                    type: SET_USER,
                    payload: user
                });
                let autoLoginOperationMode = localStorage.getItem("autoLoginOperationMode") || '0';
                if(autoLoginOperationMode !== '0'){
                    dispatch(changeOperationMode(autoLoginOperationMode));
                }
                onLoginSuccess(user);
            }else if (typeof values.authCode !== "undefined"){
                dispatch(oAuthLogin(values.authCode, onLoginSuccess, onLoginFailure));
            }else if(typeof values.accessToken !== "undefined"){
                let tryVerifyUserSSOToken = (regions=false) => {
                    let region = regions === false ? values.region : regions.shift();
                    dispatch(verifyUserSSOToken(values.accessToken, values.clientCode, region, (data) => {
                        let user = data.session.user;
                        user.userID = data.session.user.id.toString();
                        user.sessionKey = data.session.sessionKey;
                        user.clientCode = values.clientCode.toString();
                        user.employeeID = user.employeeId.toString();
                        user.loginUrl = "https://" + region + ".erply.com/" + user.clientCode + "/";
                        onLoginSuccess(user);
                        localStorage.setItem('lastLoginRegion', region);

                        //Add the user info to the offline database
                        getDB(values.clientCode).then(() => {
                            addOrUpdate('verifyUser', user).then(() => {}, (e) => {});
                        }, () => {});

                        return {
                            type: SET_USER,
                            payload: user
                        }
                    }, (message, errorCode) => {
                        if(errorCode === 'DB_DISCOVERY_FAILED'){
                            if(regions === false){
                                regions = ERPLY_AUTH_API_REGIONS.filter(el => el !== values.region);
                            }
                            if(regions.length > 0){
                                tryVerifyUserSSOToken(regions);
                                return {
                                    type: "TRY_ANOTHER_REGION"
                                }
                            }
                        }
                        dispatch(addErrorAlert(message));
                        setTimeout(() => {
                            //window.location.reload();
                        }, 4000);
                        return setLoading(false);
                    }));
                }

                tryVerifyUserSSOToken();
            }else{
                dispatch(login(values.clientCode, values.username, values.password, onLoginSuccess, onLoginFailure, onSessionTimeout));
            }
        },
        changeOperationMode: (mode) => {
            dispatch(changeOperationMode(mode));
        },
        setLoading: (loading) => {
            dispatch(setLoading(loading));
        },
        updateUserSession: (sessionKey) => {
            dispatch({
                type: UPDATE_USER_SESSION,
                sessionKey
            });
        },
        closeQCO: () => {
            dispatch(closeQCO());
        }
    }
};

const getUserFromDB = (clientCode) => {
    return new Promise((resolve, reject) => {
        getDB(clientCode).then( () => {
            let key = false;
            getFromDB("verifyUser", key).then((records) => {
                if(records.length > 0){
                    resolve(records[0]);
                }else{
                    reject("No saved user");
                }
            }, (error) => {
                reject(error);
            });
        }, () => {
            reject("Could not open the local DB");
        });
    });
}

class Login extends Component {
    constructor(props) {
        super(props);

        let clientCode = props.clientCode || localStorage.getItem('lastLoginClientCode') || '';
        let username = localStorage.getItem('lastLoginUsername') || '';
        let region = localStorage.getItem('lastLoginRegion') || 'eu';
        let password = localStorage.getItem('lastLoginPassword') || '';
        let autoLoginPOSID = localStorage.getItem("autoLoginPOSID") || '0';
        let autoLogin = autoLoginPOSID !== '0';

        this.state = {
            inputValues: {
                clientCode,
                username,
                password,
                region
            },
            clientCodeReadOnly: false,
            inputErrors: {},
            activeInput: "password",
            keyboardLayout: "numbers",
            isSubmitting: false,
            autoLogin,
            autoLoginPOSID,
            liteUser: false,
            register: false
        };

        this.selectInput = this.selectInput.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.qrMessageEvent = this.qrMessageEvent.bind(this);
        this.createSession = this.createSession.bind(this);
        this.updateSession = this.updateSession.bind(this);
        this.setSessionUpdate = this.setSessionUpdate.bind(this);
    }

    componentDidMount() {
        if(this.state.autoLogin){
            getUserFromDB(this.state.inputValues.clientCode).then((user) => {
                user.clientCode = this.state.inputValues.clientCode;
                this.props.login(
                    {clientCode: this.state.inputValues.clientCode},
                    this.props.translate,
                    this.props.operationMode,
                    this.props.cashierMode,
                    user,
                    this.state.autoLoginPOSID
                );
                localStorage.setItem('autoLoginPOSID', '0');
                localStorage.setItem('autoLoginOperationMode', '0');
            }, (error) => {
                console.log("Auto login error", error);
            })
        }else{
            checkLocalAppVersion();
            checkAppMainPreCache();
        }

        if(this.props.pos === false && this.props.operationMode !== false){
            this.createSession().then(() => {
                if(!this.props.cashierMode){
                    localStorage.setItem('lastLoginClientCode', this.state.liteUser.clientCode);
                    localStorage.setItem('lastLoginUsername', this.state.liteUser.userName);
                    this.props.login(
                        {clientCode: this.state.liteUser.clientCode},
                        this.props.translate,
                        this.props.operationMode,
                        this.props.cashierMode,
                        this.state.liteUser,
                        undefined,
                        this.state.register
                    );
                    this.setSessionUpdate();
                }
            });
        }

        if(this.props.decathlonQRCodeLogin) {
            this.initQRCodeLogin();
        }
        if(this.props.decathlonSSO){
            let authCode = new URLSearchParams(window.location.search).get('code');
            if(authCode !== null){
                this.props.login({authCode}, this.props.translate, this.props.operationMode, this.props.cashierMode, undefined, undefined, this.state.register);
            }else{
                if(this.props.user === false){
                    window.location.href = DKT_SSO_URL + '?redirectUri=' + encodeURIComponent(window.location.origin);
                }
            }
        }

    }

    componentWillUnmount() {
        if(this.props.decathlonQRCodeLogin){
            window.removeEventListener("message", this.qrMessageEvent);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let el = document.getElementsByName(this.state.activeInput);
        if(el.length > 0){
            el[0].focus();
        }

        if(!this.props.connectionHealth && this.props.pos === false && this.props.operationMode !== false &&
            (prevProps.connectionHealth || !prevProps.operationMode)
        ){
            let registerName = localStorage.getItem("registerName") || false;
            this.props.login({
                ...this.state.inputValues,
                password: "xxxxxx"
            }, this.props.translate, this.props.operationMode, this.props.cashierMode, undefined, undefined, registerName);
        }
    }

    selectInput(name) {
        this.setState({
            activeInput: name
        });
    }

    updateValue(value) {
        let inputValues = {...this.state.inputValues};
        inputValues[this.state.activeInput] = value;
        this.setState({
            inputValues
        });
    }

    validate(values) {
        let errors = {};
        if (!values.clientCode) {
            errors.clientCode = 'Required';
        }
        if (!values.username) {
            errors.username = 'Required';
        }
        if (!values.password) {
            errors.password = 'Required';
        }
        this.setState({
            inputErrors: errors
        });
        return Object.keys(errors).length === 0;
    }

    onSubmit(values) {
        let validates = this.validate(this.state.inputValues);
        if(!validates){
            return false;
        }
        this.setState({
            isSubmitting: true
        });
        this.props.login(values, this.props.translate, this.props.operationMode, this.props.cashierMode, undefined, undefined, this.state.register);
        localStorage.setItem('lastLoginClientCode', values.clientCode);
        localStorage.setItem('lastLoginUsername', values.username);
    }

    displayQRLogin(){
        let TrackConnectionWrapper = () => {
            useTrackConnectionStatus();
            let connectionStatus = useSelector(state => state.rootReducer.connectionStatus);
            console.log({connectionStatus});
            return <div/>
        }

        let backButton;

        if(!this.props.cashierModeForced){
            backButton = <Button
                type="button"
                className={"mediumEqualButton roundButton"}
                style={{
                    margin: "20px"
                }}
                name={this.props.translate("Back")}
                arrow="left"
                onClick={e => {
                    this.props.changeOperationMode(false);
                }}
            />;
        }

        return <div style={{
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <TrackConnectionWrapper/>
            <div>
                { backButton }
                <input
                    type="text"
                    name="clientCode"
                    className={"loginInput"}
                    readOnly={this.state.clientCodeReadOnly}
                    style={{
                        display: "none"
                    }}
                    onClick={e => {this.selectInput("clientCode")}}
                    onFocus={e => {this.selectInput("clientCode")}}
                    placeholder={this.props.translate("Client Code")}
                    onChange={e => {
                        this.updateValue(e.target.value);
                    }}
                    value={this.state.inputValues.clientCode}
                />
                <select
                    name={"region"}
                    value={this.state.inputValues.region}
                    onFocus={e => {this.selectInput("region")}}
                    onClick={e => {this.selectInput("region")}}
                    onChange={e => {
                        let region = e.target.value;
                        this.updateValue(region);
                    }}
                    className={'underlineInput selectInput'}
                    style={{
                        display: "none",
                        width: "auto",
                        height: "45px"
                    }}
                >
                    {
                        ERPLY_AUTH_API_REGIONS.map((region, index) => {
                            return <option key={index} value={ region }>{ region}</option>;
                        })
                    }
                </select>
                <Button
                    type="button"
                    className={"blueButton mediumEqualButton roundButton"}
                    style={{
                        margin: "20px",
                        display: localStorage.getItem('showCred') === '1' ? "inline-table" : "none"
                    }}
                    name={this.props.translate("Login with credentials")}
                    arrow="rightClose"
                    onClick={e => {
                        this.setState({
                            credentialsLogin: true
                        })
                    }}
                />
            </div>
            <iframe
                title={'QRCode'}
                src={this.props.decathlonSSOQRCodeURL}
                style={{
                    border: 'none',
                    margin: '0 auto',
                    display: 'block',
                    inset: 0,
                    width: '100%',
                    height: '640px',
                    WebkitTransform: 'scale(0.8)'
                }}
            />
            <Button
                type="button"
                className={"mediumEqualButton roundButton"}
                style={{
                    margin: "0 auto"
                }}
                name={this.props.translate("Reload")}
                onClick={e => {
                    document.getElementsByTagName("iframe")[0].src = document.getElementsByTagName("iframe")[0].src;
                }}
            />
        </div>
    }

    initQRCodeLogin(){
        window.addEventListener("message", this.qrMessageEvent);
    }

    qrMessageEvent(e) {
        console.log({qrmessageEvent: e, url: this.props?.decathlonSSOQRCodeURL});
        if(this.props.decathlonSSOQRCodeURL === false || e.origin !== new URL(this.props.decathlonSSOQRCodeURL).origin) return;
        console.log({accessToken: e.data});
        var accessToken = e.data;
        localStorage.setItem('lastLoginClientCode', this.state.inputValues.clientCode);
        this.props.login(
            {accessToken, clientCode: this.state.inputValues.clientCode, region: this.state.inputValues.region},
            this.props.translate,
            this.props.operationMode,
            this.props.cashierMode,
            undefined,
            undefined,
            this.state.register
        );
    }

    createSession(){
        return new Promise((resolve, reject) => {
            this.props.setLoading(true);
            EI.createSession((response) => {
                this.props.setLoading(false);
                let user = response.userInfo;
                user.clientCode = response.clientCode;
                let registerName = (response?.register || "") !== "" ? response.register : response.hostName;
                localStorage.setItem("registerName", registerName);

                let inputValues = {...this.state.inputValues};
                inputValues["clientCode"] = user.clientCode;
                this.setState({
                    inputValues,
                    clientCodeReadOnly: true,
                    liteUser: user,
                    register: registerName
                });
                resolve();
            }, (error) => {
                this.props.setLoading(false);
                console.log("Failed to create session", {error});
                resolve();
            });
        });
    }

    updateSession(){
        return new Promise((resolve, reject) => {
            EI.createSession((response) => {
                let user = response.userInfo;
                this.props.updateUserSession(user.sessionKey);
                resolve();
            }, (error) => {
                console.log("Failed to update session", {error});
                reject();
            });
        });
    }
    setSessionUpdate(){
        timers.setTimeout('userSessionUpdate',() => {
            this.updateSession().then(() => {
                this.setSessionUpdate(this.state.liteUser);
            }, () => {
                this.props.closeQCO();
            })
        }, (this.state.liteUser.sessionLength - 400));
    }
    render() {
        if(this.props.operationMode === false){
            let component = this;
            let autoLogin = (mode) => {
                this.createSession().then(() => {
                    component.props.changeOperationMode(mode);
                    if(this.state.liteUser !== false){
                        //Add the user info to the offline database
                        getDB(this.state.liteUser.clientCode).then(() => {
                            addOrUpdate('verifyUser', this.state.liteUser).then(() => {}, (e) => {});
                        }, () => {});
                        localStorage.setItem('lastLoginClientCode', this.state.liteUser.clientCode);
                        localStorage.setItem('lastLoginUsername', this.state.liteUser.userName);
                        component.props.login(
                            {clientCode: this.state.liteUser.clientCode},
                            this.props.translate,
                            mode,
                            this.props.cashierMode,
                            this.state.liteUser,
                            undefined,
                            this.state.register);
                        component.setSessionUpdate();
                    }
                });
            };

            return <ChooseOperationMode
                onFullMode={() => {
                    autoLogin('full')
                }}
                onLiteMode={() => {
                    autoLogin('lite')
                }}
                onCashierMode={() => {
                    this.createSession().then(() => {
                        this.props.changeOperationMode('cashier');
                    });
                }}
            />
        }
        if (this.props.view !== 'login') {
            return <Redirect to={this.props.view}/>
        }

        if (this.props.decathlonQRCodeLogin && this.state.credentialsLogin !== true){
            if(this.props.user !== false){
                return '';
            }
            return this.displayQRLogin();
        }

        let ErrorMessage = (props) => {
            return <div className={"errorMessage smallPaddingLeft"}>{props.message ?? ''}</div>;
        };

        let loginButton;
        if(this.props.theme === 'Erply'){
            loginButton =
                <Button
                    type="button"
                    disabled={this.state.isSubmitting}
                    className={"largerButton lightBlueButton roundButton uppercaseText fullWidth"}
                    style={{
                        width: "365px",
                        marginTop: "10px"
                    }}
                    name={this.props.translate("Sign in")}
                    arrow="rightClose"
                    onClick={e => {
                        this.onSubmit(this.state.inputValues);
                    }}
                />
        }else{
            loginButton =
                <Button
                    type="button"
                    disabled={this.state.isSubmitting}
                    className={"largeButton blueButton"}
                    name={this.props.translate("Sign in")}
                    flencheClass={"flencheRight transparentFlenche"}
                    arrow="right"
                    onClick={e => {
                        this.onSubmit(this.state.inputValues);
                    }}
                />
        }

        let keyboardHeight = 0;
        let keyboardEl = document.getElementsByClassName('keyboardWrapper');
        if(keyboardEl.length > 0){
            keyboardHeight = keyboardEl[0].offsetHeight;
        }
        return (
            <div onKeyPress={event => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    event.stopPropagation();
                    this.onSubmit(this.state.inputValues);
                }
            }}>
                <PerfectScrollbar
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: window.innerHeight - 65 - keyboardHeight
                    }}
                    containerRef={(ref) => {
                        this._scrollRef = ref;
                    }}
                >
                    {
                        this.props.decathlonQRCodeLogin ?
                            <Button
                                type="button"
                                className={"blueButton mediumEqualButton roundButton"}
                                style={{
                                    margin: "20px",
                                    position: "absolute",
                                    left: 0
                                }}
                                name={this.props.translate("Login with QR code")}
                                arrow="rightClose"
                                onClick={e => {
                                    this.setState({
                                        credentialsLogin: false
                                    })
                                }}
                            />
                            :
                            ""
                    }
                    <div style={window.innerHeight < 620 ? {top: 0} : {}} className={"centerContent"}>
                        <table className={'loginTable'}>
                            <tbody>
                            <tr>
                                <td>
                                    <label><Translate id={"Client Code"}/>:</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="clientCode"
                                        className={"fullWidth loginInput"}
                                        readOnly={this.state.clientCodeReadOnly}
                                        onClick={e => {this.selectInput("clientCode")}}
                                        onFocus={e => {this.selectInput("clientCode")}}
                                        onChange={e => {
                                            this.updateValue(e.target.value);
                                        }}
                                        value={this.state.inputValues.clientCode}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>
                                    <ErrorMessage name="clientCode" message={this.state.inputErrors.clientCode ?? ''}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label><Translate id={"Username"}/>:</label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="username"
                                        className={"fullWidth loginInput"}
                                        onClick={e => {this.selectInput("username")}}
                                        onFocus={e => {this.selectInput("username")}}
                                        onChange={e => {
                                            this.updateValue(e.target.value);
                                        }}
                                        value={this.state.inputValues.username}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>
                                    <ErrorMessage name="username" message={this.state.inputErrors.username ?? ''}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label><Translate id={"Password"}/>:</label>
                                </td>
                                <td>
                                    <input
                                        type="password"
                                        name="password"
                                        className={"fullWidth loginInput"}
                                        onClick={e => {this.selectInput("password")}}
                                        onFocus={e => {this.selectInput("password")}}
                                        onChange={e => {
                                            this.updateValue(e.target.value);
                                        }}
                                        value={this.state.inputValues.password}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td>
                                    <ErrorMessage name="password" message={this.state.inputErrors.password ?? ''}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {loginButton}
                    </div>
                </PerfectScrollbar>
                <Keyboard options={{
                    currentValue: this.state.inputValues[this.state.activeInput],
                    updateValue: (value) => {
                        this.updateValue(value);
                    },
                    layout: this.state.keyboardLayout,
                    onChangeToLetters: () => {this.setState({keyboardLayout: "letters"})},
                    onChangeToNumbers: () => {this.setState({keyboardLayout: "numbers"})},
                    onDone: function () {},
                    onClick: function () {}
                }} style={{
                    position: 'absolute',
                    bottom: 0
                }}/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
