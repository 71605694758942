import React from 'react';
import '../statusBar.css';
import LanguageSelect from "./LanguageSelect";
import Steps from "./Steps";
import {UserIcon} from "./User";
import {useSelector} from "react-redux";
import Translate from "localization/Translate";

export default function () {
    let cashierMode = useSelector(state => state.rootReducer.cashierMode);
    let liteMode = useSelector(state => state.rootReducer.operationMode) === 'lite';

    let textStyle = {
        textTransform: "uppercase",
        paddingTop: "5px",
        fontWeight: "bold"
    }

    let ModeText = () => (
        <div className={"coloredText"} style={{
            ...textStyle,
            float: "right",
            paddingRight: "15px"
        }}>
            <Translate id={cashierMode ? "Cashier mode" : "Lite mode"}/>
        </div>
    );
    let pos = useSelector(state => state.rootReducer.pos);
    let PosText = () => (
        <div className={"coloredText"} style={{
            ...textStyle,
            float: "left",
            paddingLeft: "85px"
        }}>
            { pos.name }
        </div>
    );

    return (
        <div>
            <LanguageSelect />
            <div className={"statusBar"} style={{
                padding: "20px"
            }}>
                { cashierMode ? <PosText/> : ""}
                <Steps/>
                <UserIcon/>
                { cashierMode || liteMode ? <ModeText/> : ""}
            </div>
        </div>
    );
}
