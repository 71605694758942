import React, {Component} from 'react';
import "../main.css"
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import {connect} from "react-redux";
import {
    addPluginRequestSaleNotes,
    changeAdministrationView,
    removePluginRequestSaleNote
} from "../../redux/actions";
import InputWithKeyboard from "../../formElements/InputWithKeyboard";
import deleteIconErply from "../images/Erply/trash.png";
import Header from "./components/Header";
import Trash from "../../icons/Trash";


const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        warehouseID: state.rootReducer.warehouse.warehouseID,
        pointOfSaleID: state.rootReducer.posID,
        translate: getTranslate(state),
        notes: state.rootReducer.pluginRequestParameters?.saveSalesDocument?.notes || [],
        theme: state.rootReducer.theme
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addNotesToSale: (notes) => {
            if(notes.trim() !== ""){
                dispatch(addPluginRequestSaleNotes([notes]));
            }
            dispatch(changeAdministrationView('main'));
        },
        removeNote: (noteIndex) => {
            dispatch(removePluginRequestSaleNote(noteIndex));
            dispatch(changeAdministrationView('addNotes'));
        }
    }
};

class AddNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyboardLayout: "letters"
        };
    }

    render() {
        if(this.props.theme === 'Erply'){
            return (
                <div>
                    <Header title={"Adding notes"}/>
                    <div style={{
                        marginTop: '100px'
                    }}>
                        <label style={{
                            color: '#808080',
                            marginLeft: '15px'
                        }}>
                            <Translate id={"Enter notes"}/>
                        </label>
                        <br/>
                        <InputWithKeyboard
                            onInputChange={() => {}}
                            onDone={(notes) => {
                                this.props.addNotesToSale(notes);
                            }}
                            inputClass={'underlineInput'}
                        />
                        <br/>
                        <br/>
                        {
                            this.props.notes.length > 0 ? (
                                <div>
                                    <label className={"dialogueTitle"}><Translate id={"Added notes"}/></label>
                                    <br/>
                                    <br/>
                                    <table>
                                        <tbody>
                                        {
                                            this.props.notes.map((note, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td
                                                            onClick={e => this.props.removeNote(index)}
                                                            style={{
                                                                width: "20px",
                                                                paddingRight: "10px"
                                                            }}
                                                        >
                                                            <img src={deleteIconErply} alt={'trash'} height={22}/>
                                                        </td>
                                                        <td>{note}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : ""
                        }
                    </div>
                </div>
            );
        }

        return (
            <div>
                <h1 className={"bigUpperCaseHeading"}><Translate id={"Adding notes"}/></h1>
                <label><Translate id={"Enter notes"}/></label><br/>
                <InputWithKeyboard
                    onInputChange={() => {}}
                    onDone={(notes) => {
                        this.props.addNotesToSale(notes);
                    }}
                />
                <br/>
                <br/>
                {
                    this.props.notes.length > 0 ? (
                        <div>
                            <label className={"dialogueTitle"}><Translate id={"Added notes"}/></label>
                            <br/>
                            <br/>
                            <table>
                                <tbody>
                                {
                                    this.props.notes.map((note, index) => {
                                        return (
                                            <tr key={index}>
                                                <td onClick={e => this.props.removeNote(index)} style={{"width": "20px"}}>
                                                    <Trash/>
                                                </td>
                                                <td>{note}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : ""
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNotes);