import {ED} from "./integrations/externalDevices/main";
import {PMS} from "./integrations/printingms/main";
import {APP_URL, ERPLY_API_PARTNER_KEY} from "../appConstants";
import {LMS} from "./integrations/loggerms/main";
import {MCMS} from "./integrations/metricsCollector/main";
import {PFMS} from "./integrations/posnetFiscal/main";
import {SET_POS_DATA} from "../actionTypes";

export const EI = {
    url: 'https://localhost.erply.com:7777/api/v1/',
    integrations: [
        {
            name: 'extdev-microservice',
            init: ED.init
        },
        {
            name: 'printing-microservice',
            init: PMS.init
        },
        {
            name: 'logger-ms',
            init: LMS.init
        },
        {
            name: 'metrics-collector-ms',
            init: MCMS.init
        },
        {
            name: 'posnet-fiscal-printing-ms',
            init: PFMS.init,
            onStateChange: PFMS.onStateChange
        }
    ],
    session: false,
    log: function(){
        let newArgs = Array.prototype.slice.call(arguments);
        newArgs.unshift('EI: ');
        console.log.apply(this, newArgs);
    },
    init: () => {
        let url = EI.url + 'service/list';
        if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
            url = APP_URL + "proxy?u=" + btoa(url);
        }
        fetch(url).then(response => {
            return response.json();
        }).then((response) => {
            EI.log('api response', response);

            response.data.integrations.map(integration => {
                if(integration.status === 'StatusRunning'){
                    let currentIntegration = EI.integrations.find(el => el.name === integration.name);
                    console.log({currentIntegration, integration});
                    if(typeof currentIntegration !== "undefined"){
                        currentIntegration.status = integration.status;
                        EI.getIntegration(integration, (config) => {
                            currentIntegration.init(config);
                        }, () => {

                        });
                    }
                }
            });

        }).catch((response) => {
            EI.log('api request failed', response);
        });
    },
    getIntegration: (integration, onSuccess, onFail) => {
        let url = EI.url + 'config/get';
        if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
            url = APP_URL + "postProxy?u=" + btoa(url);
        }
        let body = {
            entity: integration.name
        }
        if(typeof integration.version !== "undefined"){
            body.version = integration.version
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then((response) => response.json()).then((response) => {
            let config = JSON.parse(response.data.config);
            onSuccess(config);
        }).catch(response => {onFail(response)});
    },
    createSession: (onSuccess, onFail) => {
        let url = EI.url + 'createSession';
        if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
            url = APP_URL + "postProxy?u=" + btoa(url);
        }
        fetch(url, {
            method: 'POST'
        }).then((response) => response.text()).then((response) => {
            try {
                let session = JSON.parse(response);
                if(typeof session.userInfo !== "undefined"){
                    EI.session = session;
                    onSuccess(session);
                }else{
                    const formData = new FormData();
                    const params = {
                        request: "getSessionKeyUser",
                        partnerKey: ERPLY_API_PARTNER_KEY,
                        sessionKey: session.sessionKey,
                        clientCode: session.clientCode
                    };
                    for ( let key in params) {
                        formData.append(key, params[key]);
                    }
                    fetch("https://" + session.clientCode + ".erply.com/api/", {
                        method: "POST",
                        body: formData
                    }).then((response) => response.json()).then((response) => {
                        if(response.status.responseStatus === "ok" && response.records.length > 0){
                            session.userInfo = response.records[0];
                            onSuccess(session);
                        }else{
                            onFail(response);
                        }
                    });
                }
            }catch (e){
                onFail(e);
            }
        }).catch(response => {onFail(response)});
    },
    registerDevice: (clientCode, warehouseID, posID, sessionKey) => {
        let url = EI.url + 'registerDevice';
        if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
            url = APP_URL + "postProxy?u=" + btoa(url);
        }
        console.log("EI: registerDevice", {clientCode, warehouseID, posID, sessionKey});
        fetch(url, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                Clientcode: clientCode,
                WarehouseId: warehouseID,
                Posid: EI.session !== false ? EI.session.register : posID,
                Sessionkey: sessionKey
            }
        }).then((response) => response.text()).then((response) => {}).catch(response => {});
    }
};

export const middleware = (store) => next => action => {
    next(action);
    const state = store.getState();
    EI.integrations.forEach(integration => {
        if(integration.status === 'StatusRunning'){
            if(typeof integration.onStateChange !== "undefined"){
                integration.onStateChange(store, state, action);
            }
        }
    });
    if(action.type === SET_POS_DATA){
        const pos = action.payload;
        EI.registerDevice(state.rootReducer.user.clientCode, pos.warehouseID, pos.pointOfSaleID, state.rootReducer.user.sessionKey);
    }
};
