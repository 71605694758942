import CountDown from "../../uiComponents/CountDown";
import React from "react";

export default (props) => {
    let timeout = window.AppConf.scanCustomerCardTimeout;
    if(timeout === 0){
        return <div/>;
    }
    return <CountDown
        style={{
            display: 'inline-block',
            color: '#7f7f7f',
            backgroundColor: '#f7f7f7',
            borderRadius: '50%',
            width: '200px',
            height: '50px',
            fontSize: '32px',
            fontWeight: 'bold',
            lineHeight: 1.5
        }}
        onDone={e => {
            props.setDefaultCustomer();
        }}
        timeInSeconds={timeout}
    />
}