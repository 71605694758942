import React, {Component, Fragment} from 'react';
import "../App.css"
import "./main.css"
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import {connect} from "react-redux";
import ProductLine from "./ProductLine";
import bagIconErply from "./images/Erply/addBag.png";
import {
    addDocumentDiscount,
    addDocumentDiscountApproval,
    addErrorAlert,
    changeMode,
    closeModal,
    closeRightPanelDialogue,
    displayShortProductDescription,
    findProduct,
    getUserRights,
    openGetUserRightsByPinModal,
    openLogonModal,
    openModal,
    removeCustomer,
    removeDocumentDiscount,
    removePaybackCoupon,
    removePaybackCustomer,
    removeProduct,
    setLoading,
    setTransactionUser,
    showInputDialogue,
    showRightPanelDialogue,
    showSelectDialogue,
    updateProduct
} from "../redux/actions"
import {
    getProductsTotalSum,
    getReasonCodeByID,
    getReasonCodesByPurpose,
    nonDiscountableProductsInList,
    displayNetPrices,
    getNumberOfArticles,
    getTransactionMOPLimit,
    getProductsTotalSumBeforeManualDiscount,
    getNumberOfDecathlonGiftCardsInBasket,
    getProductsTotalTax,
    getBagItemCode,
    getProductsInBasket,
    getHasRightToMakeDiscount,
    getProductsNetTotalSum,
    isVoucherDetailProduct
} from "../redux/selectors";
import {
    getCheckedGiftCardPayments,
    getGiftCardsSum,
    giftCardPaymentIsPresent
} from "../redux/payment/selectors";
import GiftCardLine from "./GiftCardLine";
import {removePayment} from "../redux/payment/actions";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../uiComponents/WrappedPerfectScrollBar';
import RemoveItems from "./RemoveItems";
import {APP_MODE_SCAN} from "../redux/appConstants";
import MembershipLine from "./MembershipLine";
import PaymentLine from "./payment/PaymentLine";
import {createModalID} from "../modal/Modal";
import MultipleInputContent from "../modal/MultipleInputContent";
import operatorBypassApproval from "../redux/dto/operatorBypassApproval";
import Decimal from "decimal.js";
import GiftCardActivation from "./payment/GiftCardActivation";
import {displayPrice} from "../util/helperFunctions";
import BasketLine from "../integrations/payback/BasketLine";
import paybackLogoImg from "../integrations/payback/img/paybackLogo.png";
import paybackCouponImg from "../integrations/payback/img/coupon.png";
import {formatPaybackCustomerCode} from "../integrations/payback/util";
import PaymentButton from "./PaymentButton";
import ShoppingBag from "../icons/ShoppingBag";
import GiftCardButton from "./components/GiftCardButton";


const paymentTypeToImage = (type, theme) => {
    if(type.toLowerCase().indexOf('voucher') !== -1){
        return "iconVoucher";
    }
    switch (type.toLowerCase()) {
        case "card":{
            return "iconCard";
        }
        case "cash":{
            return "iconCash";
        }
        case "giftcard":{
            return "iconGiftCardPayment";
        }
        default: {
            return "iconDefaultPayment";
        }
    }
};



const discountNeedsSupervisor = (discountPercentage, price, discountSupervisorLimit) => {
    console.log("discount needs supervisor", {discountPercentage, price, discountSupervisorLimit})
    if(typeof discountSupervisorLimit === "number"){
        return parseFloat(discountPercentage) > discountSupervisorLimit;
    }
    let limit = discountSupervisorLimit.discountSupervisorThreshold.find(el => el.min < price && price <= el.max);
    if(typeof limit !== "undefined"){
        return parseFloat(discountPercentage) > limit.discount;
    }else{
        return true;
    }
}

const editProductPrice = (
    dispatch,
    clientCode,
    posID,
    originalProduct,
    currency,
    reasonCodes,
    discountSupervisorLimit,
    discount,
    useNetPrice,
    user,
    connectionHealth,
    translate,
    cashierMode,
    userHasRightToMakeDiscount
) => {
    console.log('Edit product price', originalProduct);

    let product = Object.assign( Object.create( Object.getPrototypeOf(originalProduct)), originalProduct);

    function calculateFields(inputValues, activeInput){
        if(activeInput === 'sum' && !isNaN(inputValues[activeInput])){
            product.setManualDiscountSum(inputValues[activeInput], useNetPrice);
        }else if (activeInput === 'percentage' && !isNaN(inputValues[activeInput])){
            product.setManualDiscountPercentage(inputValues[activeInput]);
        }else if (activeInput === 'unitPrice'){
            product.setManualDiscountUnitPrice(inputValues[activeInput], useNetPrice);
        }
    }

    let getInputValues = product => {
        console.log('getInputValues', product, product.getNetPrice(product.manualDiscountUnitPrice, product.data.vatrate).toString(), product.manualDiscountUnitPrice.toString());
        let inputValues = product.hasManualDiscount() ? {
            unitPrice: useNetPrice ? product.getNetPrice(product.manualDiscountUnitPrice, product.data.vatrate).toString() : product.manualDiscountUnitPrice.toString(),
            percentage: product.manualDiscountPercentage.toString(),
            sum: useNetPrice ? product.getNetPrice(product.manualDiscountTotal, product.data.vatrate).toString() : product.manualDiscountTotal.toString()
        } : {};
        console.log('inputValues', inputValues);
        return inputValues;
    };

    let initialValues = getInputValues(product);

    let inputFields = [
        { id: 'unitPrice', name: 'Unit price', isNumber: true},
        { id: 'percentage', name: 'Discount %', isNumber: true},
        { id: 'sum', name: 'Discount sum', isNumber: true}
    ];
    let editModalID = createModalID();
    dispatch(openModal({
        content:
            <MultipleInputContent
                title={'Edit product price'}
                inputFields={ inputFields }
                onDone={(inputValues) => {
                    console.log('inputValues', inputValues);
                    if(Object.keys(inputValues).length === 0){
                        dispatch(closeModal(editModalID));
                        return;
                    }

                    if(product.netPrice === 0 || product.originalPriceZero){
                        if(useNetPrice){
                            product.setNetPrice(inputValues.unitPrice);
                        }else{
                            product.setVatPrice(inputValues.unitPrice);
                        }
                    }
                    if(!product.hasManualDiscount() && product.manualPrice === false){
                        dispatch(closeModal(editModalID));
                        return;
                    }

                    let askReasonCodesIfApplicable = () => {
                        return new Promise((resolve) => {
                            if(parseFloat(inputValues['percentage']) === 0 && !product.originalPriceZero){
                                if(product.amount < 0){
                                    delete product.manualDiscountReasonCodeID;
                                    delete product.manualDiscountReason;
                                }else{
                                    delete product.returnReason;
                                    delete product.returnReasonID;
                                }
                                product.resetManualDiscount();
                                resolve();
                                return;
                            }

                            let reasonCodeOptions = getReasonCodesByPurpose(reasonCodes, 'DISCOUNT', true).reduce((acc, reasonCode) => {
                                acc.push({
                                    name: reasonCode.name,
                                    value: reasonCode.reasonID
                                });
                                return acc;
                            }, []);
                            if(reasonCodeOptions.length === 0){
                                resolve();
                                return;
                            }

                            dispatch(showSelectDialogue('Discount reason', reasonCodeOptions, (reasonID, reason) => {
                                let reasonCode = getReasonCodeByID(reasonCodes, reasonID);
                                if(reasonCode === undefined){
                                    reasonCode = {reasonID, name: reason};
                                }
                                if(product.amount < 0){
                                    product.manualDiscountReasonCodeID = reasonID;
                                    product.manualDiscountReason = reasonCode;
                                }else{
                                    product.returnReasonID = reasonID;
                                    product.returnReason = reasonCode;
                                }

                                resolve();
                            }, e => {}, false));
                        });
                    };

                    askReasonCodesIfApplicable().then(() => {
                        let discountPercentage = product.amount < 0 ? inputValues['percentage'] * -1 : inputValues['percentage'];
                        if(typeof discountSupervisorLimit === "object"){
                            if(!(product.amount < 0 && discountSupervisorLimit.discountSupervisorNoReturnLimit) && discountSupervisorLimit.discountSupervisorMax < discountPercentage){
                                dispatch(addErrorAlert(translate('Maximum discount percentage is ${max}%', {max: discountSupervisorLimit.discountSupervisorMax})));
                                return;
                            }
                            if(product.amount > 0){
                                let unitDiscount = product.originalPrice * discountPercentage / 100;
                                if(discountSupervisorLimit.discountSupervisorSaleMaxUnitAmount !== false && unitDiscount > discountSupervisorLimit.discountSupervisorSaleMaxUnitAmount){
                                    dispatch(addErrorAlert(translate('Maximum unit discount amount is ${max} (net)', {max: discountSupervisorLimit.discountSupervisorSaleMaxUnitAmount})));
                                    return;
                                }
                            }
                        }
                        if(discountNeedsSupervisor(discountPercentage, product.manualDiscountPercentage !== 0 ? product.originalPrice : product.netPrice, discountSupervisorLimit)){
                            if(!connectionHealth){
                                dispatch(addErrorAlert('This discount can not be approved in the offline mode'));
                                return;
                            }

                            if(window.AppConf.selfValidateSupervisor && userHasRightToMakeDiscount){
                                product.discountApproval = new operatorBypassApproval('ModLineDisc', user.employeeID);
                                dispatch(updateProduct(product));
                                dispatch(setTransactionUser(user));
                            }else{
                                dispatch(openGetUserRightsByPinModal(dispatch, clientCode, posID, ((userRights, supervisor) => {
                                    if(!window.AppConf.selfValidateSupervisor && user.userID === supervisor.userID){
                                        dispatch(addErrorAlert('User has no right to perform this action!'));
                                        return;
                                    }

                                    if(getHasRightToMakeDiscount(userRights)){
                                        console.log("adding product discount operatorBypassApproval", {supervisor});
                                        product.discountApproval = new operatorBypassApproval('ModLineDisc', supervisor.employeeID);
                                        dispatch(updateProduct(product));
                                        dispatch(setTransactionUser(user));
                                    }else{
                                        dispatch(addErrorAlert('User has no right to perform this action!'));
                                    }
                                })));
                            }
                        }else{
                            if(!cashierMode){
                                product.discountApproval = new operatorBypassApproval('ModLineDisc', user.employeeID);
                            }
                            dispatch(updateProduct(product));
                            dispatch(setTransactionUser(user));
                        }
                        if(typeof discount.documentDiscountPercentage !== "undefined" && parseFloat(inputValues['percentage']) !== parseFloat(discount.documentDiscountPercentage)){
                            dispatch(removeDocumentDiscount());
                        }
                    });

                    dispatch(closeModal(editModalID));
                }}
                onChange={(inputValues, activeInput, changeInput) => {
                    if(inputValues[activeInput] === ''){
                        changeInput({});
                    }else{
                        if(inputValues[activeInput].slice(-1) !== '.'){
                            calculateFields(inputValues, activeInput);
                            changeInput(getInputValues(product));
                        }
                    }
                }}
                initialValues={ initialValues }
                beforeInputFields={
                    <table>
                        <tbody>
                        <tr>
                            <td>{translate('Product')}:</td>
                            <td>{product.name}</td>
                        </tr>
                        <tr>
                            <td>{translate('Price')}:</td>
                            <td>{product.getDisplayUnitPrice(useNetPrice)} {currency}</td>
                        </tr>
                        </tbody>
                    </table>
                }
                initialKeyboardLayout={'numbers'}
                inputValuesDisplayRounding={window?.AppConf?.priceDecimals ?? 2}
            />,
        id: editModalID,
        className: "Administration",
        onClose: function () {

        },
        canClose: true
    }));
};

const showDocumentDiscountDialogue = (dispatch, discount, onDone) => {
    let inputFields = [
        { id: 'percentage', name: 'Discount %', isNumber: true},
        { id: 'sum', name: 'Discount sum', isNumber: true}
    ];

    const initialValues = {
        sum: discount.documentDiscountPrice || '',
        percentage: discount.documentDiscountPercentage || ''
    };

    let editModalID = createModalID();

    return (openModal({
        content:
            <MultipleInputContent
                title={'Receipt discount'}
                inputFields={ inputFields }
                onDone={(inputValues) => {
                    console.log('inputValues', inputValues);
                    onDone(inputValues);
                    dispatch(closeModal(editModalID));
                }}
                onChange={(inputValues, activeInput, changeInput) => {
                    let newValues = {};
                    newValues[activeInput] = inputValues[activeInput];
                    changeInput(newValues);
                }}
                initialValues={ initialValues }
                initialKeyboardLayout={'numbers'}
                inputValuesDisplayRounding={window?.AppConf?.priceDecimals ?? 2}
            />,
        id: editModalID,
        className: "Administration",
        onClose: function () {

        },
        canClose: true
    }));
}

const mapStateToProps = (state) => {
    let discountSupervisorLimit = state.rootReducer.theme === "Erply" ? 101 : parseFloat(state.rootReducer.erplyConf.DKTDiscountSupervisorLimit) || 10;
    let discountSupervisorMax = parseInt(window.AppConf?.discountSupervisorMax ?? 100);
    if(isNaN(discountSupervisorMax)) {
        discountSupervisorMax = 100;
    }

    let discountSupervisorSaleMaxUnitAmount = parseInt(window.AppConf?.discountSupervisorSaleMaxUnitAmount ?? "");
    if(isNaN(discountSupervisorSaleMaxUnitAmount)) {
        discountSupervisorSaleMaxUnitAmount = false;
    }

    let discountSupervisorNoReturnLimit = window.AppConf?.discountSupervisorNoReturnLimit ?? false;

    let discountSupervisorThreshold = window.AppConf?.discountSupervisorThreshold || [];
    if(discountSupervisorThreshold.length !== 0){
        discountSupervisorLimit = {
            discountSupervisorThreshold,
            discountSupervisorMax,
            discountSupervisorSaleMaxUnitAmount,
            discountSupervisorNoReturnLimit
        };
    }
    return {
        products: getProductsInBasket(state),
        productCategories: state.rootReducer.productCategories,
        customer: state.rootReducer.customer,
        payback: state.rootReducer.payback,
        currency: state.rootReducer.currency,
        decathlonBagCode: getBagItemCode(),
        mode: state.rootReducer.mode,
        cashierMode: state.rootReducer.cashierMode,
        transactionMode: state.rootReducer.transactionMode,
        warehouseID: state.rootReducer.pos.warehouseID,
        pointOfSaleID: state.rootReducer.pos.pointOfSaleID,
        clientCode: state.rootReducer.user.clientCode,
        giftCardPayments: getCheckedGiftCardPayments(state.payment.payments),
        payment: state.payment,
        reasonCodes: state.rootReducer.reasonCodes,
        discountSupervisorLimit,
        translate: getTranslate(state),
        language: state.localize.activeLocale,
        uiCustomizationPOS: state.rootReducer.uiCustomizationPOS,
        discount: state.rootReducer.discount,
        displayNetPrices: displayNetPrices(state.rootReducer.erplyConf),
        theme: state.rootReducer.theme,
        decathlonTheme: state.rootReducer.decathlonTheme,
        leftPanelFullScreen: state.rootReducer.leftPanelFullScreen,
        payments: state.payment.payments,
        connectionHealth: state.rootReducer.connectionHealth,
        rightPanelDialogueOpen: state.rootReducer.rightPanelDialogue !== false,
        invoice: state.rootReducer.invoice,
        displayNetTotal: window.AppConf?.displayNetTotal ?? false
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addBag: (decathlonBagCode, warehouseID, pointOfSaleID) => {
            dispatch(setLoading(true));
            dispatch(findProduct(decathlonBagCode, warehouseID, pointOfSaleID, false, undefined, undefined, undefined, () => {
                dispatch(setLoading(false));
            }));
        },
        removeProduct: product => {
            let title = <Translate id={"removeItemFromBin"}/>;
            if(isVoucherDetailProduct(product)){
                title = <><Translate id={"Remove all voucher products from the bin"}/><br/><span style={{fontSize: "16px"}}><Translate id={"Voucher code"}/>: {product.data.RFIDTag.SERIAL_NUMBER}</span></>;
            }
            dispatch(showRightPanelDialogue(
                <RemoveItems
                    title={title}
                    confirmText={<Translate id={"yes"}/>}
                    onConfirm={() => {
                        dispatch(removeProduct(product));
                        dispatch(closeRightPanelDialogue());
                    }}
                    cancelText={<Translate id={"no"}/>}
                    onCancel={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                />
            ));
        },
        openGiftCardView: (productsInBasket, productCategories, payments) => {
            let giftCardsInBasket = getNumberOfDecathlonGiftCardsInBasket(productsInBasket, productCategories);
            let allowedGiftCardsInBasket = window.AppConf?.giftCardActivationLimit ?? 10;
            let hasGiftCardMOP = giftCardPaymentIsPresent(payments);
            if(giftCardsInBasket >= allowedGiftCardsInBasket || hasGiftCardMOP){
                dispatch(addErrorAlert("No more gift cards can be added to the basket"));
            }else{
                dispatch(changeMode('addGiftCard'));
                dispatch(showRightPanelDialogue(
                    <GiftCardActivation/>
                ));
            }
        },
        removePayment: payment => dispatch(removePayment(payment)),
        editProductAmount: (originalProduct, newAmount, transactionMode, productsInBasket, user=false) => {
            if(originalProduct._isContainer || originalProduct.barcodePrice || originalProduct.barcodeWeight){
                return;
            }
            let product = Object.assign( Object.create( Object.getPrototypeOf(originalProduct)), originalProduct);
            console.log('product:', product, originalProduct);

            let updateProductAmount = (product, newAmount) => {
                product.setAmount(newAmount);
                dispatch(updateProduct(product));
                if(product.hasContainer){
                    let containerProduct = productsInBasket.find(el => el.containerForProductLine === product.lineNumber);
                    if(typeof containerProduct !== "undefined"){
                        let productContainer = Object.assign( Object.create( Object.getPrototypeOf(containerProduct)), containerProduct);
                        productContainer.setAmount(newAmount);
                        dispatch(updateProduct(productContainer));
                    }
                }
                if(user !== false){
                    dispatch(setTransactionUser(user));
                }
            }
            if(newAmount === false){
                let displayEnterAmountDialogue = () => {
                    dispatch(showInputDialogue('Enter amount', '', '', (inputAmount) => {
                        let parsedInput = parseFloat(inputAmount.replace(',', '.'));
                        if(isNaN(parsedInput)){
                            dispatch(addErrorAlert('Please enter a number'));
                        }else{
                            if(parsedInput === 0){
                                return;
                            }
                            if(parsedInput > 999999){
                                dispatch(addErrorAlert('Please enter a number smaller than 1000000'));
                                displayEnterAmountDialogue();
                                return;
                            }
                            if(originalProduct.amount < 0){
                                parsedInput *= -1;
                            }
                            updateProductAmount(product, parsedInput);
                        }
                    }, () => {}, true, true));
                }
                displayEnterAmountDialogue();
            }else{
                if(typeof product.baseDocumentRow !== "undefined"){
                    if(newAmount === 0){
                        return;
                    }else if(product.baseDocumentRow.amount < Math.abs(newAmount)){
                        dispatch(addErrorAlert('Quantity can\'t be higher than on the base document!'));
                        return;
                    }
                }

                if(newAmount === 0){
                    return;
                }
                if(transactionMode === 'SALE' && originalProduct.amount < 0){
                    return;
                }
                console.log('product:', product);
                updateProductAmount(product, newAmount);
            }
        },
        editProductPriceIfAllowed: (
            clientCode,
            posID,
            originalProduct,
            currency,
            reasonCodes,
            discountSupervisorLimit,
            discount,
            useNetPrice,
            connectionHealth,
            translate,
            cashierMode
        ) => {
            let productIsDiscountable = originalProduct.isDiscountable();
            if(originalProduct._isContainer){
                return;
            }
            if(typeof originalProduct.giftCardCode !== "undefined" || !productIsDiscountable){
                dispatch(addErrorAlert("Can't edit this product price!"));
                return;
            }
            if(typeof discount?.documentDiscountPercentage !== "undefined" || typeof discount?.documentDiscountPrice !== "undefined"){
                dispatch(addErrorAlert("Can't edit product price! Transaction discount already added!"));
                return;
            }
            dispatch(openLogonModal('Logon to edit product price!', (data) => {
                dispatch(setLoading(true));
                let user = data[0];
                dispatch(getUserRights(user.userID, posID, (records) => {
                    dispatch(setLoading(false));
                    let userHasRightToMakeDiscount = getHasRightToMakeDiscount(records[0]);
                    editProductPrice(dispatch, clientCode, posID, originalProduct, currency, reasonCodes,
                        discountSupervisorLimit, discount, useNetPrice, data[0], connectionHealth, translate,
                        cashierMode, userHasRightToMakeDiscount);
                    return {
                        type: 'USER_RIGHTS_SUCCESS'
                    }
                }, () => {
                    return setLoading(false);
                }));
            }));
        },
        addDocumentDiscount: (
            posID,
            clientCode,
            reasonCodes,
            products,
            discount,
            useNetPrices,
            discountSupervisorLimit,
            connectionHealth,
            cashierMode,
            translate
        ) => {
            let nonDiscountableProductsInBasket = nonDiscountableProductsInList(products);
            if(nonDiscountableProductsInBasket){
                dispatch(addErrorAlert('At least one of the products is not discountable!'));
                return;
            }

            dispatch(openLogonModal('Logon to edit product price!', (data) => {
                let user = data[0];
                dispatch(getUserRights(user.userID, posID, (records) => {
                    let userHasRightToMakeDiscount = getHasRightToMakeDiscount(records[0]);
                    return showDocumentDiscountDialogue(dispatch, discount, ({percentage, sum}) => {
                        if(sum === '' && percentage === '') return;

                        if(sum !== ''){
                            let documentTotal = getProductsTotalSumBeforeManualDiscount(products, useNetPrices);
                            percentage = new Decimal(sum).div(new Decimal(documentTotal)).mul(100).toDecimalPlaces(4).toString();
                        }


                        let askReasonCodesIfApplicable = () => {
                            return new Promise((resolve) => {
                                let reasonCodeOptions = getReasonCodesByPurpose(reasonCodes, 'DISCOUNT', true).reduce((acc, reasonCode) => {
                                    acc.push({
                                        name: reasonCode.name,
                                        value: reasonCode.reasonID
                                    });
                                    return acc;
                                }, []);
                                if(reasonCodeOptions.length === 0){
                                    resolve();
                                    return;
                                }
                                dispatch(showSelectDialogue('Discount reason', reasonCodeOptions, (reasonID, reason) => {
                                    let reasonCode = getReasonCodeByID(reasonCodes, reasonID);
                                    if(reasonCode === undefined){
                                        reasonCode = {reasonID, name: reason};
                                    }
                                    resolve(reasonCode);
                                }, e => {}, false));
                            });
                        };

                        askReasonCodesIfApplicable().then((reasonCode) => {
                            let totalPrice = getProductsTotalSumBeforeManualDiscount(products, true);

                            if(typeof discountSupervisorLimit === "object"){
                                let discountPercentage = totalPrice < 0 ? percentage * -1 : percentage;

                                if(!(totalPrice < 0 && discountSupervisorLimit.discountSupervisorNoReturnLimit) && discountSupervisorLimit.discountSupervisorMax < discountPercentage){
                                    dispatch(addErrorAlert(translate('Maximum discount percentage is ${max}%', {max: discountSupervisorLimit.discountSupervisorMax})));
                                    return;
                                }
                                if(totalPrice > 0){
                                    if(discountSupervisorLimit.discountSupervisorSaleMaxUnitAmount !== false){
                                        let productWithHigherDiscount = products.find(product => {
                                            let netUnitDiscountAmount = (product?.originalPrice ?? product.netPrice) * discountPercentage / 100;
                                            return netUnitDiscountAmount > discountSupervisorLimit.discountSupervisorSaleMaxUnitAmount;
                                        });
                                        if(typeof productWithHigherDiscount !== "undefined"){
                                            dispatch(addErrorAlert(translate('Maximum unit discount amount is ${max} (net)', {max: discountSupervisorLimit.discountSupervisorSaleMaxUnitAmount})));
                                            return;
                                        }
                                    }
                                }
                            }

                            if(!discountNeedsSupervisor(percentage, totalPrice, discountSupervisorLimit) ||
                                (window.AppConf.selfValidateSupervisor && userHasRightToMakeDiscount)){
                                dispatch(addDocumentDiscount(percentage, reasonCode, sum));
                                dispatch(addDocumentDiscountApproval(user.employeeID));
                            }else{
                                if(!connectionHealth){
                                    dispatch(addErrorAlert('This discount can not be approved in the offline mode'));
                                    return;
                                }
                                dispatch(openGetUserRightsByPinModal(dispatch, clientCode, posID, ((userRights, supervisor) => {
                                    if(!window.AppConf.selfValidateSupervisor && user.userID === supervisor.userID){
                                        dispatch(addErrorAlert('User has no right to perform this action!'));
                                        return;
                                    }

                                    if(getHasRightToMakeDiscount(userRights)){
                                        dispatch(addDocumentDiscount(percentage, reasonCode, sum));
                                        dispatch(addDocumentDiscountApproval(supervisor.employeeID));
                                    }else{
                                        dispatch(addErrorAlert('User has no right to perform this action!'));
                                    }
                                })))
                            }
                            dispatch(setTransactionUser(user));
                        });
                    }, undefined, true, true);
                }));
            }));
        },
        displayShortProductDescription: (product, language) => {
            dispatch(displayShortProductDescription(product.data, language));
        },
        openLogonModal: (title, afterLogonAction) => {
            dispatch(openLogonModal(title, afterLogonAction));
        },
        removePaybackCustomer: () => {
            dispatch(removePaybackCustomer());
        },
        removePaybackCoupon: (coupon) => {
            dispatch(removePaybackCoupon(coupon));
        },
        removeCustomer: () => {
            dispatch(removeCustomer());
        },
        addErrorAlert: (message) => {
            dispatch(addErrorAlert(message));
        }
    }
};

class ProductList extends Component{
    getTotalPrice(products, payment, invoice=false){
        let total = invoice !== false ? invoice.total : getProductsTotalSum(products);
        const giftCardsSum = new Decimal(getGiftCardsSum(payment.payments));

        return displayPrice(new Decimal(total).minus(new Decimal(payment.totalPaid)).minus(giftCardsSum));
    };

    getTotalTaxes(products){
        return displayPrice(getProductsTotalTax(products));
    };
    getNetTotal(products){
        return displayPrice(getProductsNetTotalSum(products));
    };
    getTotalDiscount(products, displayNetPrices){
        let total = displayNetPrices ? getProductsNetTotalSum(products) : getProductsTotalSum(products);
        let totalBefore = getProductsTotalSumBeforeManualDiscount(products, displayNetPrices);
        return totalBefore - total;
    }

    render() {
        let containerStyle = {
            width: "100%",
            overflow: "hidden",
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        };

        let addProducts;
        if(this.props.mode === 'scan') {
            let buyGiftCardButton;
            if(process.env.REACT_APP_ERPLY_MODE !== "1" && this.props.transactionMode === 'SALE' && this.props.connectionHealth){
                buyGiftCardButton = (
                    <Fragment>
                        <GiftCardButton
                            title={"buy a gift card"}
                            onClick={e => {
                                if(!this.props.rightPanelDialogueOpen) {
                                    this.props.openGiftCardView(this.props.products, this.props.productCategories, this.props.payments);
                                }
                            }}
                            style={{
                                borderLeft: '2px solid #ebebeb',
                                width: '50%'
                            }}
                        />
                    </Fragment>
                )
            }

            let addBagButton;
            let bagCode = this.props.uiCustomizationPOS.shoppingBag.code !== '' ? this.props.uiCustomizationPOS.shoppingBag.code : this.props.decathlonBagCode;

            if(!this.props.cashierMode && this.props.uiCustomizationPOS.shoppingBag.display === 1 && bagCode !== false){
                if(this.props.theme === 'Erply'){
                    addBagButton = (
                        <div style={{
                            padding: '10px'
                        }} onClick={e => {
                            this.props.addBag(bagCode, this.props.warehouseID, this.props.pointOfSaleID);
                        }}>
                            <img style={{
                                margin: '10px',
                                float: 'left'
                            }} src={bagIconErply} alt="bagIcon"/>
                            <span className={"boldUppercaseText"} style={{
                                fontSize: "14px",
                                float: 'left',
                                lineHeight: 3.2
                            }}>
                            <Translate id={"addBag"}/>
                            </span>
                        </div>
                    );
                }else{
                    addBagButton = (
                        <div
                            style={{
                                width: '50%'
                            }}
                            className={"addProductContainer"}
                            onClick={e => {
                                this.props.addBag(bagCode, this.props.warehouseID, this.props.pointOfSaleID);
                            }}
                        >
                            <ShoppingBag/>
                            <span style={{
                                fontSize: "20px",
                                textTransform: "none",
                                fontStyle: "italic",
                                paddingTop: "5px",
                                display: "block",
                                marginLeft: "10px",
                                flexGrow: 1
                            }}>
                                <Translate id={"addBag"}/>
                                </span>
                            <i className={"icoFont blueCircleBackgroundSmall"}>&#xefc2;</i>
                        </div>
                    );
                }
            }

            addProducts = (
                <div style={{
                    borderTop: '2px solid #ebebeb',
                    borderBottom: '2px solid #ebebeb',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    { typeof addBagButton !== "undefined"?
                        addBagButton :
                        typeof buyGiftCardButton !== "undefined" ?
                        <div
                            className={"addProductContainer"}
                            style={{
                                width: '50%'
                            }}
                        /> : ""
                    }
                    { buyGiftCardButton }
                    <div style={{clear: 'both'}}/>
                </div>
            );
        }

        if( this.props.products.length === 0 &&
            this.props.customer === false &&
            this.props.payback.customer === false &&
            this.props.payback.coupons.length === 0 &&
            this.props.invoice === false
        ) {
            let noArticlesContent;
            if(process.env.REACT_APP_ERPLY_MODE === "1"){
                noArticlesContent = (
                    <div className={'boldText'} style={{
                        fontSize: '24px',
                        color: '#f0f0f0'
                    }}>
                        <Translate id={"No items have been added yet"}/>
                    </div>
                );
            }else{
                noArticlesContent = (
                    <Translate id={"noArticlesInBasket"}/>
                );
            }

            return (
                <div style={containerStyle}>
                    <div style={{
                        flexGrow: 1,
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <span style={{
                            width: "100%",
                            textAlign: "center"
                        }}>
                            {noArticlesContent}
                        </span>
                    </div>
                    {addProducts}
                </div>
            );
        }

        setTimeout(function () {
            let productListDiv = document.getElementById("productList");
            if(productListDiv === null) return false;
            let out = productListDiv.getElementsByClassName("scrollbar-container ps")[0];
            if(typeof out !== "undefined" && out !== null){
                let isScrolledToBottom = out.scrollHeight - out.clientHeight <= out.scrollTop + 1;
                if(!isScrolledToBottom){
                    out.scrollTop = out.scrollHeight - out.clientHeight;
                }
            }
        }, 200);

        let membershipLine;
        if(this.props.customer !== false && this.props.customer.default !== true){
            membershipLine = <MembershipLine customer={this.props.customer} remove={() => {
                this.props.removeCustomer();
            }}/>;
        }

        let paybackLines = [];
        if(this.props.payback.customer !== false){
            paybackLines.push(
                <BasketLine
                    key="0"
                    title="Added Payback card"
                    value={formatPaybackCustomerCode(this.props.payback.customer)}
                    image={paybackLogoImg}
                    remove={this.props.mode === APP_MODE_SCAN && typeof this.props.payback.referenceReceipt === "undefined" ? this.props.removePaybackCustomer : false}
                />);
        }
        this.props.payback.coupons.forEach((coupon, index) => {
            paybackLines.push(
                <BasketLine
                    key={index + 1}
                    title="Added Payback coupon"
                    value={coupon}
                    image={paybackCouponImg}
                    remove={this.props.mode === APP_MODE_SCAN ? () => {
                        this.props.removePaybackCoupon(coupon);
                    } : false}
                />
            );
        });

        let invoiceLine;
        if(this.props.invoice !== false){
            invoiceLine = <tr className={"productLine"}>
                <td className={"productLineNameColumn"}>
                    <span style={{textTransform: "uppercase"}}><Translate id={"Invoice"}/></span><br/>
                    <span style={{
                        fontWeight: "bold"
                    }}>{this.props.invoice.number}</span>
                </td>
                <td className={"productLinePriceColumn"}>
                    {this.props.invoice.total}
                </td>
            </tr>;
        }

        let paymentLines = this.props.payment.payments.filter(el => el.status === 'success').map((payment, index) => {
            if(payment.status !== 'success'){
                return '';
            }
            let displayDelete = false;
            if(this.props.payment.payments.length >= getTransactionMOPLimit()){
                if(payment.type.toUpperCase() !== 'CARD'){
                    displayDelete = true;
                }
            }
            return (
                <PaymentLine
                    key={index}
                    payment={payment}
                    currency={this.props.currency}
                    image={paymentTypeToImage(payment.type, this.props.theme)}
                    removePayment={this.props.removePayment}
                    displayDelete={displayDelete}
                />
            );
        });

        let documentDiscountTotal = this.getTotalDiscount(this.props.products, this.props.displayNetPrices);
        let productListTotals;

        if(this.props.theme === 'Erply'){
            productListTotals = (
                <div className={"productListTotalsErply"} style={{
                    display: 'flex'
                }}>
                    <div style={{
                        flexGrow:1
                    }}/>
                    <div>
                        <div style={{
                            color: '#FFFFFF',
                            opacity: 0.7,
                            display: 'inline-block',
                            marginRight: '10px'
                        }}>
                            <Translate id={"toBePaid"} style={{"text-transform": "capitalize:first !important"}}/>
                            <br/>
                            {this.props.displayNetPrices ? <span style={{
                                fontSize: "16px",
                                fontWeight: "normal"
                            }}>
                                        <Translate id={"with taxes"}/>
                                    </span> : ""}
                        </div>
                        <div className={"totalPriceErply"} style={{
                            display: "inline-block",
                        }} onClick={e => {
                            if(this.props.mode === APP_MODE_SCAN){
                                this.props.addDocumentDiscount(
                                    this.props.pointOfSaleID,
                                    this.props.clientCode,
                                    this.props.reasonCodes,
                                    this.props.products,
                                    this.props.discount,
                                    this.props.displayNetPrices,
                                    this.props.discountSupervisorLimit,
                                    this.props.connectionHealth,
                                    this.props.cashierMode,
                                    this.props.translate
                                );
                            }
                        }}>
                            {this.getTotalPrice(this.props.products, this.props.payment, this.props.invoice)} {this.props.currency}
                        </div>
                    </div>
                    {this.props.mode === APP_MODE_SCAN && this.props.leftPanelFullScreen ? (
                        <PaymentButton/>
                    ) : null}
                </div>
            );
        }else{
            productListTotals = (
                <div className={"productListTotals"}>
                    <div style={{
                        display: "grid",
                        gridAutoFlow: "column",
                        gridAutoColumns: "1fr",
                    }}>
                        <table className={"responsiveTable bold"}>
                            <tbody>
                            <tr>
                                <td style={{"fontWeight": "normal", "paddingBottom": "0"}}>
                                    <Translate id={"articles"} />
                                </td>
                                <td style={{"paddingBottom": "0"}}>
                                    <div id={"numberOfArticles"} style={{"display": "inline-block"}}>
                                        {getNumberOfArticles(this.props.products)}
                                    </div>
                                </td>
                            </tr>
                            {
                                this.props.cashierMode ? (
                                    <Fragment>
                                        { parseFloat(documentDiscountTotal) !== 0 ? (
                                            <tr  style={{
                                                fontSize: "18px"
                                            }}>
                                                <td><Translate id={"Discount sum"}/></td>
                                                <td>{displayPrice(documentDiscountTotal)} {this.props.currency}</td>
                                            </tr>
                                        ) : null}
                                        <tr style={{
                                            fontSize: "18px"
                                        }}>
                                            <td><Translate id={"Taxes"}/></td>
                                            <td>
                                                {this.getTotalTaxes(this.props.products)} {this.props.currency}
                                            </td>
                                        </tr>
                                    </Fragment>
                                ) : null
                            }
                            {
                                this.props.displayNetTotal ? (
                                    <Fragment>
                                        <tr style={{
                                            fontSize: "18px"
                                        }}>
                                            <td><Translate id={"NET"}/></td>
                                            <td>
                                                {this.getNetTotal(this.props.products)} {this.props.currency}
                                            </td>
                                        </tr>
                                    </Fragment>
                                ) : null
                            }
                            <tr>
                                <td>
                                    <Translate id={"toBePaid"} style={{"text-transform": "capitalize:first !important"}}/>
                                    <br/>
                                    {this.props.displayNetPrices ? <span style={{
                                        fontSize: "16px",
                                        fontStyle: "italic",
                                        fontWeight: "normal",
                                        fontFamily: "Roboto Condensed"
                                    }}>
                                            <Translate id={"with taxes"}/>
                                        </span> : ""}
                                </td>
                                <td style={{
                                    textAlign: "right"
                                }}>
                                    <div className={"totalPrice"} style={{"display": "inline-block"}} onClick={e => {
                                        if(this.props.mode === APP_MODE_SCAN){
                                            this.props.addDocumentDiscount(
                                                this.props.pointOfSaleID,
                                                this.props.clientCode,
                                                this.props.reasonCodes,
                                                this.props.products,
                                                this.props.discount,
                                                this.props.displayNetPrices,
                                                this.props.discountSupervisorLimit,
                                                this.props.connectionHealth,
                                                this.props.cashierMode,
                                                this.props.translate
                                            );
                                        }
                                    }}>
                                        {this.getTotalPrice(this.props.products, this.props.payment)} {this.props.currency}
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        {this.props.mode === APP_MODE_SCAN && this.props.leftPanelFullScreen ? (
                            <div style={{
                                borderLeft: "2px solid #e6e6e6",
                                display: "flex"
                            }}>
                                <div style={{
                                    margin: "auto"
                                }}>
                                    <PaymentButton/>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            );
        }

        let editProductAmountFunction;

        if(process.env.REACT_APP_ERPLY_MODE === "1"){
            editProductAmountFunction = (product, newAmount=false) => {
                this.props.editProductAmount(product, newAmount, this.props.transactionMode, this.props.products);
            }
        }else{
            editProductAmountFunction = (product, newAmount=false) => {
                if(!this.props.cashierMode){
                    let productHasSerialNumber = (product.data?.RFIDTag?.['SERIAL_NUMBER'] ?? 'NULL_VALUE') !== 'NULL_VALUE';
                    if(productHasSerialNumber){
                        this.props.addErrorAlert("Can't edit the quantity of a serial numbered product");
                        return;
                    }
                    this.props.openLogonModal('Logon to edit product amount!', (data) => {
                        let user = data[0];
                        this.props.editProductAmount(product, newAmount, this.props.transactionMode, this.props.products, user);
                    });
                }
            }
        }

        return (
            <div style={containerStyle}>
                <div id={"productList"}>
                    <PerfectScrollbar style={{
                        position: "absolute",
                        left: 0,
                        right:0,
                        top: 0,
                        bottom: 0,
                    }}>
                    <table className={`${this.props.theme === 'Erply' ? "responsiveTableErply" : "responsiveTable"} bold borderBottomTd`}>
                        <tbody>
                        {
                            this.props.products.map( (product) => {
                                return <ProductLine
                                    key={product.lineNumber}
                                    theme={this.props.theme}
                                    product={product}
                                    removeProduct={this.props.mode === APP_MODE_SCAN ? this.props.removeProduct : false}
                                    currency={this.props.currency}
                                    editProductAmount={ this.props.mode === APP_MODE_SCAN ? editProductAmountFunction : () => {}}
                                    editProductPrice={
                                        this.props.mode === APP_MODE_SCAN ?
                                        (product, currency) => {
                                            this.props.editProductPriceIfAllowed(
                                                this.props.clientCode,
                                                this.props.pointOfSaleID,
                                                product,
                                                currency,
                                                this.props.reasonCodes,
                                                this.props.discountSupervisorLimit,
                                                this.props.discount,
                                                this.props.displayNetPrices,
                                                this.props.connectionHealth,
                                                this.props.translate,
                                                this.props.cashierMode
                                            );
                                        }
                                        : () => {}
                                    }
                                    onInfoButtonClick={(product) => {
                                        this.props.displayShortProductDescription(product, this.props.language);
                                    }}
                                    displayNetPrices={this.props.displayNetPrices}
                                    displayTax={this.props.cashierMode && !(product.isUsedProduct())}
                                />
                            })
                        }
                        { membershipLine }
                        { paybackLines }
                        { invoiceLine }
                        {
                            this.props.giftCardPayments.map(giftCardPayment => {
                               return <GiftCardLine key={giftCardPayment.index} payment={giftCardPayment} removeGiftCardPayment={this.props.removePayment} currency={this.props.currency}/>
                            })
                        }
                        {paymentLines}
                        </tbody>
                    </table>
                    </PerfectScrollbar>
                </div>
                <div>
                    { addProducts }
                    { productListTotals }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
