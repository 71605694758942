import {
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    API_START,
    API_END,
    ACCESS_DENIED,
    API_ERROR,
    API,
    SET_USER,
    SET_TRANSACTION_USER,
    SET_POS_ID,
    SET_POS_DATA,
    SET_WAREHOUSE,
    SET_ERPLY_CONF,
    INIT_TRANSACTION,
    CHANGE_VIEW,
    CHANGE_MODE,
    SET_DEFAULT_CUSTOMER,
    SET_PAYMENT_MODE,
    TRANSACTION_FAILED,
    PRODUCT_NOT_FOUND,
    CANCEL_TRANSACTION,
    UPDATE_PRODUCTS,
    SET_PRODUCTS_IN_BASKET,
    TRANSACTION_SUCCESS,
    SET_CUSTOMER,
    UPDATE_CUSTOMER,
    CUSTOMER_API,
    SET_ERROR,
    CLOSE_DIALOGUE,
    SHOW_DIALOGUE,
    CLOSE_ADMINISTRATION_VIEW,
    OPEN_ADMINISTRATION_VIEW,
    SET_EMPLOYEES,
    SET_ADMIN_USER,
    UPDATE_PERIPHERAL,
    ADD_PERIPHERAL,
    SCANNER_DETECTED,
    CLOSE_QCO,
    CHANGE_ADMINISTRATION_VIEW,
    SHOW_CALL_ASSISTANCE,
    CLOSE_CALL_ASSISTANCE,
    CANCEL_ADD_UNKNOWN_ITEM,
    SECURITY_ITEM_ADDED,
    SET_CUSTOMER_AGE_VERIFIED,
    SHOW_RIGHT_PANEL_DIALOGUE,
    CLOSE_RIGHT_PANEL_DIALOGUE,
    ADD_UNKNOWN_RFID_PRODUCT,
    REMOVE_UNKNOWN_RFID_PRODUCT,
    ADD_ALERT,
    REMOVE_ALERT,
    UPDATE_PRODUCT,
    SET_REASON_CODES,
    CHANGE_TRANSACTION_MODE,
    ADD_PRODUCT_RETURN_REASON_ID,
    OPEN_MODAL,
    CLOSE_MODAL,
    SET_POS_DAY_OPENED,
    SET_POS_DATA_FAILED,
    PRINT_RECEIPT,
    SET_VAT_RATES,
    ADD_DOCUMENT_DISCOUNT,
    ADD_PRODUCT_DISCOUNT_APPROVAL,
    ADD_DOCUMENT_DISCOUNT_APPROVAL,
    REMOVE_PERIPHERAL,
    TRANSACTION_TIMEOUT_REACHED,
    CLOSE_ALL_MODALS,
    OPEN_CASH_DRAWER,
    PRINT_HTML,
    EPSI_REQUEST,
    HANDLE_FISCAL_REQUEST,
    SET_COMPANY_INFO,
    SET_LOADING,
    SET_CUSTOMER_SELECT_LIST,
    SET_PRODUCT_SELECT_LIST,
    SET_PRODUCT_GROUPS,
    SET_PRODUCT_CATEGORIES,
    SET_QUICK_POS_PRODUCTS,
    UPDATE_PRODUCT_GROUP_PRODUCTS,
    SET_OPERATION_MODE,
    SET_DELAY_RECEIPT,
    CLOSE_ALL_DIALOGUES,
    CAFA_API,
    SET_EDIT_QUICK_SELECT_PRODUCTS,
    ADD_QUICK_POS_PRODUCT,
    SET_QUICK_POS_PRODUCTS_ORDER,
    SET_ERPLY_SERVICE_ENDPOINTS,
    SET_LAST_SCANNED_GIFT_CARD,
    ADD_BEFORE_COMPLETE_TRANSACTION_ACTION,
    REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTION,
    ADD_PLUGIN_REQUEST_SALE_ATTRIBUTES,
    ADD_RECEIPT_GIFT_CARD,
    UPDATE_PRODUCT_GIFT_CARD_NUMBER,
    ADD_PLUGIN_REQUEST_SALE_NOTES,
    REMOVE_PLUGIN_REQUEST_SALE_NOTE,
    SET_RETURN_BASE_DOCUMENT,
    SET_ADD_RECEIPT_TITLE,
    REMOVE_DOCUMENT_DISCOUNT,
    CREATE_RECEIPT,
    UPDATE_USER_SESSION,
    UPDATE_RETURN_BASE_DOCUMENT_PAYMENT,
    AUTH_API,
    SET_PAYBACK_CUSTOMER,
    SET_PAYBACK_INPUT_DONE,
    ADD_PAYBACK_COUPON,
    REMOVE_PAYBACK_COUPON,
    ADD_RECEIPT_EXTRA_LINE,
    REMOVE_RECEIPT_EXTRA_LINE,
    REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTIONS_BY_TYPE,
    CREATE_RECEIPT_NUMBER,
    REMOVE_PAYBACK_CUSTOMER,
    SET_PAYBACK_REFERENCE_RECEIPT,
    ADD_MANUAL_PROMOTION_ID,
    REMOVE_MANUAL_PROMOTION_ID,
    ADD_CAMPAIGN_BY_NAME,
    SET_NEXT_SEQUENCE_NUMBER,
    REVERT_NEXT_SEQUENCE_NUMBER,
    REMOVE_CUSTOMER,
    SET_RIGHT_PANEL_FULLSCREEN,
    ADD_BEFORE_APPLY_PROMOTIONS_EVENT,
    REMOVE_BEFORE_APPLY_PROMOTIONS_EVENT,
    SET_PENDING_DOCUMENT,
    SET_USER_RIGHTS,
    SET_USED_PRODUCT_CUSTOMER,
    ADD_INTERVAL,
    REMOVE_INTERVAL,
    SET_CONNECTION_HEALTH,
    SET_CONNECTION_STATUS,
    INIT_POS,
    SET_CUSTOMER_CAN_REMOVE,
    ADD_PRODUCT_CODE_INFO,
    ADD_PRODUCT_RFID_SERIAL,
    ADD_PRODUCT_RFID_TAG,
    UPDATE_PRODUCT_NAME,
    CLOSE_RFID_READER,
    SET_ONE_PROMOTION_PROMO_CODE,
    SET_CUSTOMER_ZIP_CODE,
    SET_PAYBACK_PAY_CODE,
    REMOVE_PAYBACK_PAY_CODE,
    SET_INVOICE,
    SET_CURRENCIES,
    SET_SCREEN_SAVER,
    SET_SCREEN_SAVER_NEXT_IMAGE
} from "./actionTypes";

import Product from "./dto/product";
import Customer from "./dto/customer";
import {getCurrentTimestamp} from "../util/calendar";
import {createModalID} from "../modal/Modal";
import VerifyPin from "../main/administration/VerifyPin";
import React from "react";
import * as uuid from "uuid";
import Logon from "../main/administration/Logon";
import Translate from "localization/Translate";
import operatorBypassApproval from "./dto/operatorBypassApproval";
import {getProductName, getProductShortDescription, getTillNumber} from "./selectors";
import {timers} from "../util/timers";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from 'uiComponents/WrappedPerfectScrollBar.js';
import {healthCheck} from "../services/ErplyAPI/connection";
import {sleep} from "../util/helperFunctions";
import moment from "moment-timezone";
import {SET_CUSTOM_PAYMENT_VIEW} from "./payment/actionTypes";

export const setLoading = loading => {
    return {
        type: SET_LOADING,
        payload: loading
    }
};

export const addProduct = (product, pendingDocument=false) => {
    if(product.data.priceListPriceWithVat === 0){
        product.originalPriceZero = true;
        product.manualPrice = true;
    }
    return {
        type: ADD_PRODUCT,
        payload: product,
        pendingDocument
    }
};

export const applyPromotions = (productsInBasket, pos, customer, defaultCustomerID, onDone = ()=>{}, manualPromotionIDs = []) => {
    let data = {
        request: "applyPromotions",
        warehouseID: pos.warehouseID,
        pointOfSaleID: pos.pointOfSaleID
    };

    if(manualPromotionIDs.length > 0){
        data.manualPromotionIDs = manualPromotionIDs.join(',');
    }

    if(customer !== false && typeof customer.customerID !== "undefined"){
        data.customerID = customer.customerID;
    }else{
        data.customerID = defaultCustomerID;
    }

    let c = 0;
    for(let product of productsInBasket) {
        data["productID" + c ] = product.data.productID;
        if(product.fixedVatRate){
            data["vatrateID" + c ] = product.data.vatrateID;
        }
        data["amount" + c ] = product.amount;
        if(product.manualPrice){
            if(product.originalPriceZero){
                data["price" + c] = product.originalPrice || product.netPrice;
                data["discount" + c] = product.manualDiscountPercentage;
            }else if(product.hasManualDiscount()){
                data["discount" + c] = product.manualDiscountPercentage;
            }else{
                data["price" + c] = product.netPrice;
            }
        }
        c++;
    }

    return {
        type: API,
        payload: {
            data: data,
            onSuccess: function (data) {
                onDone();
                return {
                    type: UPDATE_PRODUCTS,
                    payload: data[0]
                }
            },
            onFailure: function (error) {
                onDone();
                return apiError(error, false);
            },
            label: "Apply promotions"
        }
    };
};

export const setProductsInBasket = products => ({
    type: SET_PRODUCTS_IN_BASKET,
    payload: products
});
export const updateProduct = (product, preventApplyPromotions = false) => ({
    type: UPDATE_PRODUCT,
    payload: product,
    preventApplyPromotions
});

export const removeProduct = product => ({
    type: REMOVE_PRODUCT,
    payload: product
});

export const addProductReturnReasonID = (lineNumber, returnReason) => ({
    type: ADD_PRODUCT_RETURN_REASON_ID,
    payload: {
        lineNumber: lineNumber,
        returnReason: returnReason
    }
});
export const addProductCodeInfo = (lineNumber, codeInfo) => ({
    type: ADD_PRODUCT_CODE_INFO,
    payload: {
        lineNumber,
        codeInfo
    }
});
export const addProductRFIDSerialNumber = (lineNumber, serialNumber) => ({
    type: ADD_PRODUCT_RFID_SERIAL,
    payload: {
        lineNumber,
        serialNumber
    }
});

export const addProductRFIDTag = (lineNumber, RFIDTag) => ({
    type: ADD_PRODUCT_RFID_TAG,
    payload: {
        lineNumber,
        RFIDTag
    }
});

export const closeRFIDReader = (callback) => ({
    type: CLOSE_RFID_READER,
    payload: {
        callback
    }
});

export const updateProductName = (lineNumber, name) => ({
    type: UPDATE_PRODUCT_NAME,
    payload: {
        lineNumber,
        name
    }
});
export const updateProductGiftCardNumber = (lineNumber, number) => ({
    type: UPDATE_PRODUCT_GIFT_CARD_NUMBER,
    payload: {
        lineNumber,
        number
    }
});

export const addProductDiscountApproval = (lineNumber, employeeID) => {
    let discountApproval = new operatorBypassApproval('ModLineDisc', employeeID);
    return {
        type: ADD_PRODUCT_DISCOUNT_APPROVAL,
        payload: {
            lineNumber,
            discountApproval
        }
    };
};

export const addDocumentDiscountApproval = (employeeID) => {
    let discountApproval = new operatorBypassApproval('ModTotalDisc', employeeID);
    return {
        type: ADD_DOCUMENT_DISCOUNT_APPROVAL,
        payload: {
            discountApproval
        }
    };
};

export const addUnknownRFIDProduct = product => {
    return {
        type: ADD_UNKNOWN_RFID_PRODUCT,
        payload: product
    }
};

export const removeUnknownRFIDProduct = product => ({
    type: REMOVE_UNKNOWN_RFID_PRODUCT,
    payload: product
});


export function findProduct(
    code,
    warehouseID,
    pointOfSaleID,
    vatPrice = false,
    onSuccess,
    onFailure,
    originalCode,
    onDone=()=>{},
    searchCodeType='searchNameIncrementally',
    onlyActive=true
) {
    console.log("findProduct function", code, warehouseID, pointOfSaleID);
    let parameters = {
        request: "getProducts",
        recordsOnPage: "1",
        getAllLanguages: "1",
        warehouseID: warehouseID,
        pointOfSaleID: pointOfSaleID,
        getReplacementProducts: "1",
        getRelatedProducts: "1",
        orderBy: "name",
        orderByDir: "asc",
        getPriceListPrices: "1",
        getContainerInfo: "1",
        getRecipes: "1",
        type: "PRODUCT,MATRIX,BUNDLE,ASSEMBLY",
        getPriceCalculationSteps: "1"
    };
    if(onlyActive){
        parameters.active = 1;
    }
    parameters[searchCodeType] = code;

    return {
        type: API,
        payload: {
            data: parameters,
            onSuccess: function (data) {
                onDone();
                if (data.length === 0) {
                    let error = 'Product missing from database!';
                    if(onFailure !== undefined){
                        return onFailure(code, error);
                    }else{
                        return productNotFound(code, error);
                    }
                }
                const firstProduct = data[0];
                let product = new Product(firstProduct.name, firstProduct.priceListPrice, firstProduct.priceListPriceWithVat, 1, firstProduct);
                product.originalCode = originalCode;
                if(vatPrice !== false){
                    product.setVatPrice(vatPrice);
                }
                console.log('product vat price', product.vatPrice);
                if(onSuccess !== undefined){
                    return onSuccess(product);
                }else{
                    return addProduct(product);
                }
            },
            onFailure: (error) => {
                onDone();
                console.log("Error occured finding the product!", error);
                if(onFailure !== undefined){
                    return onFailure(code, error);
                }else{
                    return productNotFound(code, error);
                }
            },
            label: "Find product"
        }
    }
}

export function findProducts(params, warehouseID, pointOfSaleID, onSuccess, onFailure) {
    let data = {
        request: "getProducts",
        active: "1",
        getAllLanguages: "1",
        warehouseID: warehouseID,
        pointOfSaleID: pointOfSaleID,
        orderBy: "name",
        orderByDir: "asc",
        getPriceListPrices: "1",
        getContainerInfo: "1",
        getRecipes: "1",
        type: "PRODUCT,MATRIX,BUNDLE,ASSEMBLY",
        getPriceCalculationSteps: "1",
        ...params
    };
    if(typeof params.productID !== "undefined" || typeof params.productIDs !== "undefined"){
        delete data.active;
    }
    return {
        type: API,
        payload: {
            data,
            onSuccess: function (data) {
                if(data.length === 0){
                    return onFailure("No records");
                }
                return onSuccess(data);
            },
            onFailure: (error) => {
                return onFailure(error);
            },
            label: "Find products"
        }
    }
}

export function setProductSelectList(products) {
    return {
        type: SET_PRODUCT_SELECT_LIST,
        payload: products
    }
}

export function productNotFound(searchParam, error) {
    return {
        type: PRODUCT_NOT_FOUND,
        payload: {
            searchParam: searchParam,
            error: error
        }
    }
}

export function initPOS(){
    return {
        type: INIT_POS
    }
}

export function login(clientCode, username, password, onSuccess, onFailure, onSessionTimeout) {
    return {
        type: API,
        payload: {
            data: {
                request: "verifyUser",
                clientCode: clientCode,
                username: username,
                password: password,
                sessionLength: 86400
            },
            onSuccess: function (data) {
                const user = data[0];
                user.clientCode = clientCode;
                timers.setTimeout('userSessionUpdate', () => {
                    onSessionTimeout(user);
                }, 86000);
                onSuccess(user);
                return {
                    type: SET_USER,
                    payload: user
                }
            },
            onFailure: (error) => {
                console.log("Could not verify user!", error);
                onFailure();
                return apiError(error);
            },
            label: "Verify user"
        }
    }
}

export function oAuthLogin(authCode, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "oAuthLogin",
                authCode,
                sessionLength: 86400
            },
            onSuccess: function (user) {
                onSuccess(user);
                return {
                    type: SET_USER,
                    payload: user
                }
            },
            onFailure: (error) => {
                console.log("Could not verify user!", error);
                onFailure();
                return apiError(error);
            },
            label: "oAuth login"
        }
    }
}

export function refreshUserSession(clientCode, username, password, onFailure, onSessionTimeout) {
    let sessionLength = 86400;
    return {
        type: API,
        payload: {
            data: {
                request: "verifyUser",
                clientCode: clientCode,
                username: username,
                password: password,
                sessionLength

            },
            onSuccess: function (data) {
                const user = data[0];
                timers.setTimeout('userSessionUpdate',() => {
                    onSessionTimeout(user);
                }, (sessionLength - 400));

                return {
                    type: UPDATE_USER_SESSION,
                    sessionKey: user.sessionKey
                }
            },
            onFailure: onFailure,
            label: "Verify user"
        }
    }
}

export function verifyUser(clientCode, username, password, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "verifyUser",
                clientCode: clientCode,
                username: username,
                password: password,
                sessionLength: 86400

            },
            onSuccess: onSuccess,
            onFailure: onFailure,
            label: "Verify user"
        }
    }
}

export function verifyUserSSOToken(accessToken, clientCode, region, onSuccess, onFailure) {
    let headers = {
        accessToken
    }
    if(typeof clientCode !== "undefined"){
        headers.clientCode = clientCode;
    }
    if(typeof region !== "undefined"){
        headers.region = region;
    }
    return {
        type: AUTH_API,
        payload: {
            method: 'POST',
            headers,
            parameters: {
                sessionLength:86400
            },
            onSuccess,
            onFailure
        }
    }
}

export function setTransactionUser(user){
    return {
        type: SET_TRANSACTION_USER,
        payload: user
    }
}
export function getUserRights(userID, posID, onSuccess, onFailure) {
    if(userID === "0"){
        return onSuccess([{
            rightMakeDiscountInPOS: 0,
            rightMakePOSReturnsWithoutReceipt: 1,
            rightMakePOSRefunds: 1
        }]);
    }
    return {
        type: API,
        payload: {
            data: {
                request: "getUserRights",
                userID: userID,
                posID: posID
            },
            onSuccess: (records) => {
                const result = records.find(el => el.userID === parseInt(userID));
                return onSuccess([result]);
            },
            onFailure: onFailure,
            label: "Get user rights"
        }
    }
}

export function getHasSupervisorUserRights(userID, posID, onSuccess, onFailure) {
    if(userID === "0"){
        return onSuccess(false);
    }
    return {
        type: API,
        payload: {
            data: {
                request: "getUserRights",
                userID: userID,
                posID: posID
            },
            onSuccess: (records) => {
                const result = records.find(el => el.userID === parseInt(userID));
                return onSuccess(result.rightMakeDiscountInPOS);
            },
            onFailure: onFailure,
            label: "Get has supervisor user rights"
        }
    }
}

export function setUserRights(records) {
    return {
        type: SET_USER_RIGHTS,
        payload: records
    }
}

export function switchUser(clientCode, cardCode, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "switchUser",
                clientCode: clientCode,
                cardCode: cardCode,
                sessionLength: 86400

            },
            onSuccess: onSuccess,
            onFailure: onFailure,
            label: "Switch user"
        }
    }
}

export function setAdministrationUser(user) {
    console.log(SET_ADMIN_USER, user);
    return {
        type: SET_ADMIN_USER,
        payload: user
    }
}

export function setPOSID(posID) {
    console.log('setting POS ID');
    return {
        type: SET_POS_ID,
        payload: posID
    }
}

export function getPointsOfSale(onSuccess, onFailure=()=>{}) {
    return {
        type: API,
        payload: {
            data: {
                request: "getPointsOfSale",
                getAllPages: true
            },
            onSuccess,
            onFailure,
            label: 'Get points of sale'
        }
    }
}

export function getAllowedWarehouses(userID, onSuccess, onFailure=()=>{}) {
    return {
        type: API,
        payload: {
            data: {
                request: "getAllowedWarehouses",
                userID,
                getAllPages: true
            },
            onSuccess,
            onFailure,
            label: 'Get points of sale'
        }
    }
}

export function setPOSData(posID, onSuccess = () => {}, onFailure = () => {}) {
    console.log('setting POS Data');
    let convertIDsToString = function (data) {
        data.warehouseID = data.warehouseID.toString();
        data.pointOfSaleID = data.pointOfSaleID.toString();
        return data;
    };

    let erplyMode = process.env.REACT_APP_ERPLY_MODE === "1";

    let params = {
        request: "getPointsOfSale"
    };

    if(erplyMode){
        params['pointOfSaleID'] = posID;
    }else {
        params['getAllPages'] = true;
    }

    return {
        type: API,
        payload: {
            data: params,
            onSuccess: function (data) {
                let pos;
                if(erplyMode){
                    pos = convertIDsToString(data[0]);
                    if(pos.pointOfSaleID !== posID){
                        return {
                            type: SET_POS_DATA_FAILED,
                            payload: 'POS ID not found'
                        }
                    }
                }else {
                    pos = data.find(pos => posID === getTillNumber(pos));
                    if(typeof pos === "undefined"){
                        return {
                            type: SET_POS_DATA_FAILED,
                            payload: 'POS ID not found'
                        }
                    }
                }
                onSuccess(pos);
                return {
                    type: SET_POS_DATA,
                    payload: pos
                }
            },
            onFailure: function (error) {
                onFailure();
                return {
                    type: SET_POS_DATA_FAILED,
                    payload: error
                }
            },
            successActions: [],
            label: 'Get Erply POS Data'
        }
    };
}

export function checkPOSDayOpened(posID, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "POSOpenDay",
                pointOfSaleID: posID,
                queryOpenDay: 1
            },
            onSuccess: function (data) {
                let dayOpened = data.length !== 0;
                let dayID = dayOpened ? data[0].dayID : false;
                return onSuccess(dayOpened, dayID);
            },
            onFailure: onFailure,
            successActions: [],
            label: 'POS Open Day'
        }
    };
}

export function POSOpenDay(pointOfSaleID, openedSum, onSuccess, onFailure, employeeID=false) {
    let params = {
        request: "POSOpenDay",
        pointOfSaleID,
        openedSum,
        openedUnixTime: getCurrentTimestamp()
    };
    if(employeeID !== false){
        params.employeeID = employeeID;
    }
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            successActions: [],
            label: 'POS Open Day'
        }
    };
}

export function getPointOfSaleDayTotals(posID, onSuccess, onFailure) {
    let params = {
        request: "getPointOfSaleDayTotals",
        pointOfSaleID: posID
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: function (data) {
                if(data.length > 0){
                    return onSuccess(data);
                }else{
                    return onFailure();
                }
            },
            onFailure: onFailure,
            successActions: [],
            label: 'getPointOfSaleDayTotals'
        }
    };
}

export function POSCloseDay(posID, dayID, closedSum, onSuccess, onFailure, employeeID=false) {
    let params = {
        request: "POSCloseDay",
        pointOfSaleID: posID,
        dayID: dayID,
        closedUnixTime: getCurrentTimestamp(),
        closedSum: 0,
        bankedSum: closedSum
    };
    if(employeeID !== false){
        params.employeeID = employeeID;
    }
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            successActions: [],
            label: 'POS Close Day'
        }
    };
}

export function getCloseDayHtmlReport(posID, warehouseID, onSuccess, onFailure) {
    let params = {
        request:"getReports",
        type:"ZReport",
        format:"HTML",
        getShortReport: (window?.AppConf?.shortXReport ?? true) ? 1 : 0,
        pointOfSaleID:posID,
        warehouseID:warehouseID
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            successActions: [],
            label: 'Get close day report'
        }
    }

}

export function getRecentSales(posID, warehouseID, onSuccess, onFailure, numberOrCustomer) {
    let params = {
        request: 'getSalesDocuments',
        warehouseID: warehouseID,
        posID: posID,
        recordsOnPage: 100,
        confirmed: 1,
        getRowsForAllInvoices: 0,
        getCustomerInformation: 0
    };
    if(numberOrCustomer !== undefined){
        params['numberOrCustomer'] = numberOrCustomer;
    }
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            successActions: [],
            label: 'Get recent sales'
        }
    }
}

export function getPendingSales(posID, warehouseID, onSuccess, onFailure) {
    let params = {
        request: 'getSalesDocuments',
        warehouseID: warehouseID,
        posID: posID,
        recordsOnPage: 100,
        confirmed: 0,
        getRowsForAllInvoices: 0,
        getCustomerInformation: 0
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            successActions: [],
            label: 'Get recent sales'
        }
    }
}

export function getInvoices(posID, onSuccess, onFailure) {
    let params = {
        request: 'getSalesDocuments',
        recordsOnPage: 100,
        confirmed: 1,
        getRowsForAllInvoices: 1,
        getCustomerInformation: 1,
        posID,
        types: "INVOICE,INVWAYBILL",
        unpaidItemsOnly: 1,
        getReturnedPayments: 1
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            successActions: [],
            label: 'Get invoices'
        }
    }
}

export function getSalesDocument(id, onSuccess, onFailure, parameters={}) {
    let params = {
        request: 'getSalesDocuments',
        id,
        ...parameters
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: (documents) => {
                if(documents.length > 0){
                    return onSuccess(documents[0]);
                }else{
                    return onFailure('Failed to get sales document');
                }
            },
            onFailure,
            successActions: [],
            label: 'Get sales document'
        }
    }
}

export function deleteSalesDocument(id, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: 'deleteSalesDocument',
                documentID: id
            },
            onSuccess,
            onFailure,
            successActions: [],
            label: 'Delete sales document'
        }
    }
}
export function deleteSalesDocuments(ids, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: ids.reduce((acc, el) => {
                acc.push({
                    requestName: 'deleteSalesDocument',
                    documentID: el
                });
                return acc;
            }, []),
            onSuccess,
            onFailure,
            successActions: [],
            label: 'Delete sales documents'
        }
    }
}

export function getAppliedPromotionRecords(documentID, onSuccess, onFailure) {
    let params = {
        request: 'getAppliedPromotionRecords',
        invoiceIDs: documentID
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess: (records) => {
                return onSuccess(records);
            },
            onFailure,
            successActions: [],
            label: 'Get sales document applied promotion records'
        }
    }
}

export function getPayments(documentID, onSuccess, onFailure) {
    let params = {
        request: 'getPayments',
        documentID,
        recordsOnPage: 100
    };
    return {
        type: API,
        payload: {
            data: params,
            onSuccess,
            onFailure,
            successActions: [],
            label: 'Get payments'
        }
    }
}

export function setPosDayOpened(dayID) {
    return {
        type: SET_POS_DAY_OPENED,
        payload: dayID
    }
}

export function setWarehouse(warehouseID, successActions = []) {
    console.log('getting warehouse data', warehouseID);
    return {
        type: API,
        payload: {
            data: {
                request: "getWarehouses",
                warehouseID: warehouseID
            },
            onSuccess: function (data) {
                console.log('got warehouse data');
                return {
                    type: SET_WAREHOUSE,
                    payload: data[0]
                }
            },
            onFailure: function (error) {

            },
            label: 'Get Erply Warehouse',
            successActions: successActions
        }
    };
}

export function getErplyConfiguration(onSuccess, onFailure) {
    console.log('getting Erply configuration');
    return {
        type: API,
        payload: {
            data: {
                request: "getConfParameters"
            },
            onSuccess: function (data) {
                setTimeout(() => {
                    onSuccess(data[0]);
                }, 100);
                return {
                    type: SET_ERPLY_CONF,
                    payload: data[0]
                }
            },
            onFailure: (error) => {
                onFailure();
                console.log('Could not get Erply configuration');
            },
            label: 'Get Erply Configuration'
        }
    }
}

export function getErplyServiceEndpoints(onSuccess = ()=>{}, onFailure = ()=>{}) {
    return {
        type: API,
        payload: {
            data: {
                request: "getServiceEndpoints"
            },
            onSuccess: function (data) {
                setTimeout(() => {
                    onSuccess(data[0]);
                }, 100);
                return {
                    type: SET_ERPLY_SERVICE_ENDPOINTS,
                    payload: data[0]
                }
            },
            onFailure: (error) => {
                onFailure();
            },
            label: 'Get Erply service endpoints'
        }
    }
}

export function getCompanyInfo() {
    return {
        type: API,
        payload: {
            data: {
                request: "getCompanyInfo"
            },
            onSuccess: function (data) {
                return {
                    type: SET_COMPANY_INFO,
                    payload: data[0]
                }
            },
            onFailure: (error) => {
                console.log('Could not get company info');
            },
            label: 'Get company info'
        }
    }
}

export function getReasonCodes() {
    console.log('getting Erply reason codes');
    return {
        type: API,
        payload: {
            data: {
                request: "getReasonCodes",
                getAllPages: true
            },
            onSuccess: function (data) {
                return {
                    type: SET_REASON_CODES,
                    payload: data
                }
            },
            onFailure: (error) => {
                console.log('Could not get Erply reason codes');
            },
            label: 'Get Erply reason codes'
        }
    }
}

export function getVatRates() {
    console.log('getting Erply vat rates');
    return {
        type: API,
        payload: {
            data: {
                request: "getVatRates",
                getAllPages: true
            },
            onSuccess: function (data) {
                return {
                    type: SET_VAT_RATES,
                    payload: data
                }
            },
            onFailure: (error) => {
                console.log('Could not get Erply vat rates');
            },
            label: 'Get Erply Vat rates'
        }
    }
}

export function getCurrencies() {
    return {
        type: API,
        payload: {
            data: {
                request: "getCurrencies",
                getAllPages: true
            },
            onSuccess: function (data) {
                return {
                    type: SET_CURRENCIES,
                    payload: data
                }
            },
            onFailure: (error) => {
                console.log('Could not get Erply currencies');
            },
            label: 'Get currencies'
        }
    }
}

export function getProductGroups() {
    return {
        type: API,
        payload: {
            data: {
                request: "getProductGroups",
                getAllLanguages: 1
            },
            onSuccess: function (data) {
                return {
                    type: SET_PRODUCT_GROUPS,
                    payload: data
                }
            },
            onFailure: (error) => {
                console.log('Could not get product groups');
            },
            label: 'Get product groups'
        }
    }
}

export function getProductCategories() {
    return {
        type: API,
        payload: {
            data: {
                request: "getProductCategories",
                lang: "eng",
                getAllPages: true
            },
            onSuccess: function (data) {
                return {
                    type: SET_PRODUCT_CATEGORIES,
                    payload: data
                }
            },
            onFailure: (error) => {
                console.log('Could not get product groups');
            },
            label: 'Get product groups'
        }
    }
}

export function getActiveManualCampaigns(warehouseID, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "getCampaigns",
                activeToday: 1,
                recordsOnPage: 100,
                type: "manual",
                warehouseID
            },
            onSuccess,
            onFailure,
            label: 'Get campaigns'
        }
    }
}

export function getCampaignsByName(name, onSuccess, onFailure) {
    return {
        type: API,
        payload: {
            data: {
                request: "getCampaigns",
                name
            },
            onSuccess,
            onFailure,
            label: 'Get campaign by name'
        }
    }
}

export function addCampaignByName(campaign) {
    return {
        type: ADD_CAMPAIGN_BY_NAME,
        payload: {
            campaign
        }
    }
}

export function updateProductGroupProducts(productGroupID, products, productsPageNo) {
    return {
        type: UPDATE_PRODUCT_GROUP_PRODUCTS,
        productGroupID,
        products,
        productsPageNo
    }
}

export function setEditQuickProducts(edit){
    return {
        type: SET_EDIT_QUICK_SELECT_PRODUCTS,
        edit
    }
}

export function addQuickPosProduct(product) {
    return {
        type: ADD_QUICK_POS_PRODUCT,
        payload: product
    }
}

export function saveQuickPosProducts(productIDs, pointOfSaleID, saved) {
    if(window.AppConf.posSpecificQuickSelectProducts === true){
        console.log('saveQuickPosProducts', productIDs, saved);
        return {
            type: CAFA_API,
            payload: {
                method: 'PUT',
                endpoint: 'v3/configuration',
                parameters: {
                    level: 'Pos',
                    level_id: pointOfSaleID.toString(),
                    name: 'quickPosProductIDs',
                    value: productIDs.join(',')
                },
                onSuccess: function (result) {
                    return addInfoAlert('Saved');
                },
                onFailure: (error) => {
                    console.log('Could not save quick selection products');
                },
            }
        }
    }
}

export function getQuickPosProducts(warehouseID, pointOfSaleID) {
    if(window.AppConf.posSpecificQuickSelectProducts === true){
        return {
            type: CAFA_API,
            payload: {
                method: 'GET',
                parameters: {
                    level: 'Pos',
                    level_id: pointOfSaleID,
                    name: 'quickPosProductIDs'
                },
                onSuccess: function (productIDsString) {
                    let productIDs = typeof productIDsString === 'string' ? productIDsString.split(',') : productIDsString;
                    console.log('productIDs', productIDs);
                    if(productIDsString === false){
                        return {
                            type: 'NO_QUICK_POS_PRODUCTS_SET'
                        }
                    }
                    if(productIDs.length === 0){
                        return {
                            type: SET_QUICK_POS_PRODUCTS,
                            payload: {
                                data: {},
                                order: [],
                                saved: true
                            }
                        }
                    }
                    return {
                        type: API,
                        payload: {
                            data: {
                                request: "getProducts",
                                productIDs: productIDsString,
                                getAllLanguages: "1",
                                warehouseID,
                                pointOfSaleID,
                                getPriceListPrices: "1",
                                getContainerInfo: "1",
                                getRecipes: "1",
                                type: "PRODUCT,MATRIX,BUNDLE,ASSEMBLY",
                                getPriceCalculationSteps: "1",
                                getAllPages: true
                            },
                            onSuccess: function (data) {
                                console.log('quickPosProducts', data);
                                let productsByID = data.reduce((acc, el) => {
                                    acc[el.productID] = el;
                                    return acc;
                                }, {});
                                return {
                                    type: SET_QUICK_POS_PRODUCTS,
                                    payload: {
                                        data: productsByID,
                                        order: productIDs,
                                        saved: true
                                    }
                                }
                            },
                            onFailure: (error) => {
                                console.log('Could not get quick selection products');
                            },
                            label: 'Get quick selection products'
                        }
                    }
                },
                onFailure: (error) => {
                    console.log('Could not get quick selection products');
                },
            }
        }
    }else{
        return {
            type: API,
            payload: {
                data: {
                    request: "getProducts",
                    active: "1",
                    getAllLanguages: "1",
                    warehouseID,
                    pointOfSaleID,
                    orderBy: "name",
                    orderByDir: "asc",
                    getPriceListPrices: "1",
                    getContainerInfo: "1",
                    getRecipes: "1",
                    type: "PRODUCT,MATRIX,BUNDLE,ASSEMBLY",
                    getPriceCalculationSteps: "1",
                    getAllPages: true,
                    quickPosProducts: 1
                },
                onSuccess: function (data) {
                    let productsByID = data.reduce((acc, el) => {
                        acc[el.productID] = el;
                        return acc;
                    }, {});
                    let productIDs = data.reduce((acc, el) => {
                        acc.push(el.productID);
                        return acc;
                    }, []);
                    return {
                        type: SET_QUICK_POS_PRODUCTS,
                        payload: {
                            data: productsByID,
                            order: productIDs,
                            saved: true
                        }
                    }
                },
                onFailure: (error) => {
                    console.log('Could not get quick selection products');
                },
                label: 'Get quick selection products'
            }
        }
    }
}

export function setQuickPosProductsOrder(order) {
    return {
        type: SET_QUICK_POS_PRODUCTS_ORDER,
        payload: order
    }
}

export function getUICustomization(name, level, actionType) {
    return {
        type: CAFA_API,
        payload: {
            method: 'GET',
            parameters: {
                level,
                name
            },
            onSuccess: function (data) {
                if(data === false){
                    return {
                        type: 'NO_UI_CUSTOMIZATION_SET'
                    }
                }
                data.saved = true;
                return {
                    type: actionType,
                    payload: data
                }
            },
            onFailure: function (error) {
                console.log('Could not get UI customization data');
            }
        }
    }
}

export function saveUICustomization(data, name, level) {
    return {
        type: CAFA_API,
        payload: {
            method: 'PUT',
            endpoint: 'v3/configuration',
            parameters: {
                level,
                name,
                value: JSON.stringify(data)
            },
            onSuccess: function (result) {
                return addInfoAlert('Saved');
            },
            onFailure: (error) => {
                console.log('Could not save UI customization');
            },
        }
    }
}

export function savePrinterTimeoutPrintConfiguration(data) {
    return {
        type: CAFA_API,
        payload: {
            method: 'PUT',
            endpoint: 'v3/configuration',
            parameters: {
                level: 'Pos',
                name: 'printerTimeoutPrintDays',
                value: JSON.stringify(data)
            },
            onSuccess: function (result) {
                console.log('Saved printer timeout configuration');
                return {
                    type: "PRINTER_TIMEOUT_PRINT_SAVED"
                };
            },
            onFailure: (error) => {
                console.log('Could not save printer timeout configuration');
            },
        }
    }
}

export function getCafaConf(onSuccess, onFailure) {
    return {
        type: CAFA_API,
        payload: {
            method: 'GET',
            parameters: {
                getAll: true
            },
            onSuccess,
            onFailure
        }
    }
}

export function POSCashInOut(request, pointOfSaleID, employeeID, sum, reason, reasonID, onSuccess, onFailure, erplyConf) {
    let saveTime = moment();
    saveTime.tz(erplyConf.timezone);

    let params = {
        request: request,
        pointOfSaleID: pointOfSaleID,
        employeeID: employeeID,
        sum: sum,
        comment: reason,
        added: saveTime.unix(),
        attributeName1: 'reasonID',
        attributeType1: 'int',
        attributeValue1: reasonID
    };

    if(process.env.REACT_APP_ERPLY_MODE !== "1"){
        params['attributeName2'] = 'POSLogSent';
        params['attributeType2'] = 'text';
        params['attributeValue2'] = 0;
    }

    return {
        type: API,
        payload: {
            data: params,
            onSuccess: onSuccess,
            onFailure: onFailure,
            label: 'POSCashIn'
        }
    }
}

export function sendEmailReceipt(documentID, email, onSuccess, onFailure, document=false) {
    return {
        type: API,
        payload: {
            data: {
                id: documentID,
                type: 'SALESDOCUMENT',
                email: email,
                request: 'sendByEmail'
            },
            onSuccess: onSuccess,
            onFailure: onFailure,
            label: 'Send email receipt',
            document
        }
    }
}

export function changeTransactionMode(mode, referenced=false) {
    return {
        type: CHANGE_TRANSACTION_MODE,
        payload: mode,
        referenced
    }
}

export function setNextSequenceNumber(number) {
    return {
        type: SET_NEXT_SEQUENCE_NUMBER,
        payload: number
    }
}

export function revertNextSequenceNumber(number) {
    return {
        type: REVERT_NEXT_SEQUENCE_NUMBER,
        payload: number
    }
}

export function completeTransaction(receipt, onSuccess, onFail) {
    return {
        type: API,
        payload: {
            data: {
                request: "completeTransaction",
                receipt
            },
            onSuccess: function (requests) {
                let allSuccessful = true;
                let invoiceID = 0;
                let invoiceNo = 0;
                let receiptLink = false;
                for(let request of requests){
                    if(request.status.responseStatus !== 'ok'){
                        allSuccessful = false;
                    }else{
                        if( typeof request.records !== "undefined" &&
                            typeof request.records[0] !== "undefined" &&
                            typeof request.records[0].invoiceID !== "undefined"
                        ){
                            invoiceID = request.records[0].invoiceID;
                            invoiceNo = request.records[0].invoiceNo;
                            receiptLink = request.records[0].receiptLink;
                        }
                    }
                }
                if(allSuccessful){
                    onSuccess(invoiceID, invoiceNo, receiptLink);
                    return transactionSuccess(invoiceID, invoiceNo, receiptLink);
                }else{
                    onFail(requests);
                    return {
                        type: TRANSACTION_FAILED,
                        payload: ""
                    }
                }
            },
            onFailure: function (error) {
                onFail([]);
                return {
                    type: TRANSACTION_FAILED,
                    payload: error
                }
            },
            successActions: [],
            label: 'completeTransaction'
        }
    };
}

export function saveSale(onSuccess, onFail) {
    return {
        type: API,
        payload: {
            data: {
                request: "saveSale"
            },
            onSuccess: function (requests) {
                let allSuccessful = true;
                for(let request of requests){
                    if(request.status.responseStatus !== 'ok'){
                        allSuccessful = false;
                    }
                }
                if(allSuccessful){
                    onSuccess();
                    return {
                        type: "SALE_SAVED"
                    }
                }else{
                    onFail();
                    return {
                        type: TRANSACTION_FAILED,
                        payload: ""
                    }
                }
            },
            onFailure: function (error) {
                onFail();
                return {
                    type: TRANSACTION_FAILED,
                    payload: error
                }
            },
            successActions: [],
            label: 'saveSale'
        }
    };
}

export const saveSalesDocument = (parameters, onSuccess, onFailure) => ({
    type: API,
    payload: {
        data: {
            request: "saveSalesDocument",
            ...parameters
        },
        onSuccess,
        onFailure,
        label: 'Save sales document'
    }
});

export const apiStart = label => ({
    type: API_START,
    payload: label
});

export const apiEnd = label => ({
    type: API_END,
    payload: label
});

export const accessDenied = url => ({
    type: ACCESS_DENIED,
    payload: {
        url
    }
});

export const apiError = (error, displayError = true) => ({
    type: API_ERROR,
    payload: error,
    displayError: displayError
});

export const addErrorAlert = (message, autoClose = true) => ({
    type: ADD_ALERT,
    payload: {
        type: 'error',
        message: message,
        autoClose: autoClose
    }
});

export const addInfoAlert = (message, autoClose = true) => ({
    type: ADD_ALERT,
    payload: {
        type: 'info',
        message: message,
        autoClose: autoClose
    }
});

export const removeAlert = () => ({
    type: REMOVE_ALERT
});

export const initTransaction = () => ({
    type: INIT_TRANSACTION,
    payload: {}
});

export const cancelTransaction = () => ({
    type: CANCEL_TRANSACTION,
    payload: {}
});

export const transactionSuccess = (invoiceID, invoiceNo, receiptLink) => ({
    type: TRANSACTION_SUCCESS,
    payload: {
        invoiceID: invoiceID,
        invoiceNo: invoiceNo,
        receiptLink: receiptLink
    }
});

export const changeView = view => ({
    type: CHANGE_VIEW,
    payload: {
        view: view
    }
});

export const changeMode = mode => ({
    type: CHANGE_MODE,
    payload: {
        mode: mode
    }
});

export const setDefaultCustomer = () => ({
    type: SET_DEFAULT_CUSTOMER,
    payload: {}
});

export const setPaybackInputDone = (inputDone) => ({
    type: SET_PAYBACK_INPUT_DONE,
    inputDone
});

export const setPaybackCustomer = (customer) => ({
    type: SET_PAYBACK_CUSTOMER,
    customer
});
export const setPaybackPayCode = (code) => ({
    type: SET_PAYBACK_PAY_CODE,
    payload: code
});

export const removePaybackPayCode = () => ({
    type: REMOVE_PAYBACK_PAY_CODE
});

export const removePaybackCustomer = () => ({
    type: REMOVE_PAYBACK_CUSTOMER
});

export const addPaybackCoupon = (coupon) => ({
    type: ADD_PAYBACK_COUPON,
    coupon
});

export const removePaybackCoupon = (coupon) => ({
    type: REMOVE_PAYBACK_COUPON,
    coupon
});

export const setPaybackReferenceReceipt = (number, products = [], transactionDateTime = false) => ({
    type: SET_PAYBACK_REFERENCE_RECEIPT,
    payload: {
        number,
        transactionDateTime,
        products
    }
});

export const addReceiptExtraLine = (line) => ({
    type: ADD_RECEIPT_EXTRA_LINE,
    line
});

export const removeReceiptExtraLine = (line) => ({
    type: REMOVE_RECEIPT_EXTRA_LINE,
    line
});

export const addManualPromotionID = (id) => ({
    type: ADD_MANUAL_PROMOTION_ID,
    id
});

export const removeManualPromotionID = (id) => ({
    type: REMOVE_MANUAL_PROMOTION_ID,
    id
});

export const addBeforeApplyPromotionsEvent = (event, id) => ({
    type: ADD_BEFORE_APPLY_PROMOTIONS_EVENT,
    payload: {
        id,
        event
    }
});

export const removeBeforeApplyPromotionEvent = (id) => ({
    type: REMOVE_BEFORE_APPLY_PROMOTIONS_EVENT,
    payload: {
        id
    }
});

export const changePaymentMode = mode => ({
    type: SET_PAYMENT_MODE,
    payload: {
        mode: mode
    }
});

export const setCustomPaymentView = view => ({
    type: SET_CUSTOM_PAYMENT_VIEW,
    payload: view
});
export const changeOperationMode = mode => ({
    type: SET_OPERATION_MODE,
    mode
});

export const getCustomers = (query, params, onSuccess, onFailure) => ({
    type: CUSTOMER_API,
    payload: {
        request: "getCustomers",
        data: {
            searchNameIncrementally: query,
            getAllPages: true,
            searchFromMiddle: "1"
        },
        onSuccess,
        onFailure,
        label: "find customers"
    }
});

export const setCustomerSelectList = customers => ({
    type: SET_CUSTOMER_SELECT_LIST,
    payload: customers
});

export const findCustomerByRegistryCode = (code) => {
    return {
        type: 'CUSTOMER_API',
        payload: {
            request: "getCustomers",
            data: {
                searchRegistryCode: code
            },
            onSuccess: function (result) {
                let customer = new Customer();
                Object.assign(customer, result[0]);
                return setCustomer(customer);
            },
            onFailure: function (result) {
                return showDialogue('couldNotFindCustomer', '', 'ok', undefined, undefined, undefined, undefined, undefined, undefined, true);
            },
            label: "find customers"
        }
    }
};

export const findCustomerByCardNumber = (cardNumber, onSuccess=()=>{}, onFailure=()=>{}) => {
    let requestName;
    let params;
    if(process.env.REACT_APP_ERPLY_MODE === "1"){
        requestName = "getCustomers";
        params = {
            searchCode: cardNumber
        };
    }else{
        requestName = "getCustomer";
        params = {
            card_number: cardNumber
        };
    }
    return {
        type: CUSTOMER_API,
        payload: {
            request: requestName,
            data: params,
            onSuccess: function (result) {
                let customer = new Customer();
                Object.assign(customer, result[0]);
                onSuccess(customer);
                return setCustomer(customer);
            },
            onFailure: function (result, getState) {
                onFailure(result);
                let state = getState();
                let connectionHealth = state.rootReducer.connectionHealth;

                if(typeof result.status !== "undefined"){
                    return showDialogue('couldNotFindCustomer', '', 'ok', undefined, undefined, undefined, undefined, undefined, undefined, true);
                }else{
                    return showDialogue(
                        'connectionFailed',
                        connectionHealth ?
                            'Confirm that the customer card number is correct and add without validation' :
                            'The customer loyalty card cannot be validated offline'
                        ,
                        'ok',
                        (dispatch) => {
                            let customer = new Customer(cardNumber);
                            dispatch(setCustomer(customer));
                        },
                        () => {},
                        "cancel",
                        false,
                        undefined,
                        undefined,
                        true
                    );
                }
            },
            label: "find customer by code"
        }
    }
};



export const findCustomerByEmail = (email, onSuccess=()=>{}, onFailure=()=>{}, canRemove=true) => ({
    type: CUSTOMER_API,
    payload: {
        request: "getCustomer",
        data: {
            email: email
        },
        onSuccess: function (result) {
            let customer = new Customer();
            Object.assign(customer, result[0]);
            customer.canRemove = canRemove;
            onSuccess(customer);
            return setCustomer(customer);
        },
        onFailure: function (result) {
            onFailure(result);
            return showDialogue('couldNotFindCustomer', '', 'ok', undefined, undefined, undefined, undefined, undefined, undefined, true);
        },
        label: "find customer by email"
    }
});

export const findCustomerByPhone = (phone, country, onSuccess=()=>{}, onFailure=()=>{}, canRemove=true) => ({
    type: CUSTOMER_API,
    payload: {
        request: "getCustomer",
        data: {
            mobile_country_code: country,
            mobile: phone
        },
        onSuccess: function (result) {
            let customer = new Customer();
            Object.assign(customer, result[0]);
            customer.canRemove = canRemove;
            onSuccess(customer);
            return setCustomer(customer);
        },
        onFailure: function (result) {
            onFailure(result);
            return showDialogue('couldNotFindCustomer', '', 'ok', undefined, undefined, undefined, undefined, undefined, undefined, true);
        },
        label: "find customer by phone"
    }
});

export const setCustomer = customer => ({
    type: SET_CUSTOMER,
    payload: customer
});

export const updateCustomer = customerInfo => ({
    type: UPDATE_CUSTOMER,
    payload: customerInfo
});

export const setCustomerCanRemove = canRemove => ({
    type: SET_CUSTOMER_CAN_REMOVE,
    payload: canRemove
});

export const setUsedProductCustomer = customer => ({
    type: SET_USED_PRODUCT_CUSTOMER,
    payload: customer
});

export const removeCustomer = () => ({
    type: REMOVE_CUSTOMER,
    payload: false
});

export const setCustomerAgeVerified = (oldEnough) => ({
    type: SET_CUSTOMER_AGE_VERIFIED,
    payload: oldEnough ? 1 : 0
});

export const setError = message => ({
    type: SET_ERROR,
    payload: {
        message: message
    }
});

export const showDialogue = (
    title,
    message,
    confirmText,
    onConfirm,
    onCancel,
    cancelText,
    canClose=true,
    id=uuid.v4(),
    initialInputValue='',
    smallDialogue=false,
    messageClass,
    confirmButtonClass,
    cancelButtonClass
) => ({
    type: SHOW_DIALOGUE,
    payload: {
        id,
        title,
        message,
        confirmText,
        onConfirm,
        onCancel,
        cancelText,
        canClose,
        initialInputValue,
        smallDialogue,
        messageClass,
        confirmButtonClass,
        cancelButtonClass
    }
});

export const showInputDialogue = (
    title,
    message,
    inputLabel,
    onConfirm,
    onCancel,
    canClose=true,
    inputNumber=false,
    className,
    id=uuid.v4(),
    initialInputValue='',
    closeOnDone=true,
    smallDialogue=false
) => ({
    type: SHOW_DIALOGUE,
    payload: {
        id,
        title,
        message,
        inputLabel,
        onConfirm,
        onCancel,
        canClose,
        inputDialogue: true,
        inputNumber,
        className,
        initialInputValue,
        closeOnDone,
        smallDialogue
    }
});

export const showSelectDialogue = (title, options, onSelect, onCancel, canClose=true, id=uuid.v4(), initialInputValue='', message, className) => ({
    type: SHOW_DIALOGUE,
    payload: {
        id,
        title,
        message,
        options,
        onSelect,
        onCancel,
        canClose,
        selectDialogue: true,
        initialInputValue,
        className
    }
});

export const closeDialogue = (id) => ({
    type: CLOSE_DIALOGUE,
    payload: id
});

export const closeAllDialogues = () => ({
    type: CLOSE_ALL_DIALOGUES
});

export const showRightPanelDialogue = (dialogue, id=uuid.v4()) => ({
    type: SHOW_RIGHT_PANEL_DIALOGUE,
    payload: {
        id,
        dialogue
    }
});

export const closeRightPanelDialogue = (id=false) => ({
    type: CLOSE_RIGHT_PANEL_DIALOGUE,
    payload: {
        id
    }
});

export const setRightPanelFullscreen = (fullscreen) => ({
    type: SET_RIGHT_PANEL_FULLSCREEN,
    payload: fullscreen
});

export const openModal = (data) => ({
    type: OPEN_MODAL,
    payload: data
});

export const closeModal = (id) => ({
    type: CLOSE_MODAL,
    payload: id
});

export const closeAllModals = () => ({
    type: CLOSE_ALL_MODALS
});

export const openAdministrationView = (view = 'logon', canClose = true, onClose = ()=>{}, data) => {
    console.log('openAdministrationView action', view, canClose, onClose);
    return {
        type: OPEN_ADMINISTRATION_VIEW,
        payload: {
            view,
            canClose,
            onClose,
            data
        }
    };
};

export const closeAdministration = () => ({
    type: CLOSE_ADMINISTRATION_VIEW
});

export const changeAdministrationView = (view) => ({
    type: CHANGE_ADMINISTRATION_VIEW,
    payload: view
});

window.getEmployeesTry = 1;

export const getEmployees = (pointOfSaleID) => ({
    type: API,
    payload: {
        data: {
            request: "getEmployees",
            pointsOfSale: pointOfSaleID,
            getAllPages: true
        },
        onSuccess: function (data) {
            return {
                type: SET_EMPLOYEES,
                payload: data
            }
        },
        onFailure: (error) => {
            console.log('Could not get employees');
            return apiError(error);
        },
        label: 'Get employees'
    }
});

export const deletePayment = (paymentID) => {
    return {
        type: API,
        payload: {
            data: {
                request: "deletePayment",
                paymentID
            },
            onSuccess: function (data) {
                return {
                    type: "PAYMENT_DELETED",
                    payload: data
                }
            },
            onFailure: (error) => {
                return apiError(error);
            },
            label: 'Remove payment'
        }
    };
};

export const removeSalesDocument = (documentID) => {
    return {
        type: API,
        payload: {
            data: {
                request: "deleteSalesDocument",
                documentID
            },
            onSuccess: function (data) {
                return {
                    type: "DOCUMENT_DELETED",
                    payload: data
                }
            },
            onFailure: (error) => {
                return apiError(error);
            },
            label: 'Remove sales document'
        }
    };
};

export const addPeripheral = (peripheral) => ({
    type: ADD_PERIPHERAL,
    payload: peripheral
});

export const updatePeripheral = (peripheral) => ({
    type: UPDATE_PERIPHERAL,
    payload: peripheral
});

export const removePeripheral = (name) => ({
    type: REMOVE_PERIPHERAL,
    name
});

export const addInterval = (interval) => ({
    type: ADD_INTERVAL,
    payload: interval
});

export const removeInterval = (interval) => ({
    type: REMOVE_INTERVAL,
    payload: interval
});

export const scannerDetected = code => ({
    type: SCANNER_DETECTED,
    payload: code
});

export const closeQCO = () => ({
    type: CLOSE_QCO,
    payload: ''
});

export const closeApp = () => {
    return {
        type: API,
        payload: {
            data: {
                request: 'closeApp'
            },
            onSuccess: function (data) {
                return {
                    type: 'APP_CLOSED'
                }
            },
            onFailure: function (error) {
                return apiError(error, false);
            },
            label: "Close app"
        }
    };
};

export const showCallAssistance = (administrationView, titleMessage = '', canCancel = false, onCancel = ()=>{}, canClose = false, content=undefined, confirmText='Logon', onConfirm=()=>{}, data={}) => ({
    type: SHOW_CALL_ASSISTANCE,
    payload: {
        administrationView,
        titleMessage,
        canCancel,
        onCancel,
        canClose,
        content,
        confirmText,
        onConfirm,
        data
    }
});

export const showCallAssistanceConfirm = (administrationView, titleMessage = '') => ({
    type: SHOW_CALL_ASSISTANCE,
    payload: {
        administrationView: 'confirm',
        titleMessage: titleMessage
    }
});

export const closeCallAssistance = (id) => ({
    type: CLOSE_CALL_ASSISTANCE,
    payload: id
});

export const cancelAddUnknownItem = (productCode) => ({
    type: CANCEL_ADD_UNKNOWN_ITEM,
    productCode: productCode
});

export const securityItemAdded = () => ({
    type: SECURITY_ITEM_ADDED
});

export const printReceipt = (receiptLink, giftReceipt = false, duplicate = false, document, onSuccess = () => {}, onFail = () => {}) => ({
    type: PRINT_RECEIPT,
    payload: {
        receiptLink,
        giftReceipt,
        duplicate,
        document,
        onSuccess,
        onFail
    }
});

export const printHtml = (html, onSuccess = () => {}, onFail = () => {}) => ({
    type: PRINT_HTML,
    payload: {
        data: html,
        onSuccess,
        onFail
    }
});

export const epsiRequest = (params, onSuccess = () => {}, onFail = () => {}) => ({
    type: EPSI_REQUEST,
    payload: {
        params,
        onSuccess,
        onFail
    }
});

export const handleFiscalRequest = (onSuccess, onFail) => ({
    type: HANDLE_FISCAL_REQUEST,
    payload: {
        onSuccess,
        onFail
    }
});

export const createReceipt = (onSuccess) => ({
    type: CREATE_RECEIPT,
    payload: {
        onSuccess
    }
});

export const createReceiptNumber = (onSuccess) => ({
    type: CREATE_RECEIPT_NUMBER,
    payload: {
        onSuccess
    }
});

export const openVerifyPinModal = (dispatch, clientCode, onSuccess, onFailure, onClose=()=>{}, canClose=true, title='This action needs supervisor confirmation!') => {
    if(window?.AppConf?.decathlonQRCodeSupervisor){
        return openLogonModal(title, (data) => {
            onSuccess(data[0]);
        }, canClose, true, onClose);
    }
    let modalID = createModalID();
    return openModal({
        content: <VerifyPin title={title} onDone={(pin) => {
            dispatch(setLoading(true));
            dispatch(switchUser(clientCode, pin, (records) => {
                dispatch(setLoading(false));
                onSuccess(records[0]);
                return closeModal(modalID);
            }, () => {
                dispatch(setLoading(false));
                onFailure();
                return {
                    type: 'SWITCH_USER_FAILED'
                }
            }))
        }
        }/>,
        id: modalID,
        className: "Administration",
        onClose: onClose,
        canClose: canClose
    });
};

export const openGetUserRightsByPinModal = (dispatch, clientCode, posID, onSuccess, onClose=()=>{}, canClose=true, title) => {
    return openVerifyPinModal(dispatch, clientCode, (user) => {
        dispatch(setLoading(true));
        dispatch(getUserRights(user.userID, posID, (records) => {
            dispatch(setLoading(false));
            onSuccess(records[0], user);
            return {
                type: 'USER_RIGHTS_SUCCESS'
            }
        }, () => {
            dispatch(setLoading(false));
            return addErrorAlert('Could not get user right records, please try again!');
        }));
    }, () => {
        dispatch(addErrorAlert('This pin is not correct!'));
    }, onClose, canClose, title);
};

export const openLogonModal = (title, afterLogonAction, canClose = true, supervisor=false, onClose=()=>{}) => {
    let logonModalID = uuid.v4();
    return openModal({
        content: <Logon
            message={<Translate id={title}/>}
            afterLogonAction={function (data) {
                afterLogonAction(data);
                return closeModal(logonModalID);
            }}
            supervisor={supervisor}
        />,
        id: logonModalID,
        afterLogonAction,
        type: 'logon',
        className: "Administration",
        onClose,
        canClose
    });
};

export const addDocumentDiscount = (percentage, returnReason, sum) => {
    return {
        type: ADD_DOCUMENT_DISCOUNT,
        percentage,
        returnReason,
        sum
    }
};

export const removeDocumentDiscount = () => {
    return {
        type: REMOVE_DOCUMENT_DISCOUNT
    }
};

export const openCashDrawer = () => {
    return {
        type: OPEN_CASH_DRAWER
    }
};

export const transactionTimeoutReached = () => {
    return {
        type: TRANSACTION_TIMEOUT_REACHED
    }
};

export const setDelayReceipt = (delayReceipt) => {
    return {
        type: SET_DELAY_RECEIPT,
        delayReceipt
    }
};

export const displayShortProductDescription = (product, language) => {
    let modalID = uuid.v4();
    let picture = typeof product.images !== "undefined" ? product.images[0].smallURL : false;
    let name = getProductName(product, language);

    return openModal({
        content: (
            <div style={{
                height: '100%'
            }}>
                <div className={"dialogueTitle"}>{name}</div>
                <div  style={{
                    height: 'calc(100% - 20px)',
                    display: 'flex'
                }}>
                    <div style={{
                        width: '30%'
                    }}>
                        {
                            picture !== false ?
                                <img
                                    src={picture} alt={name}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%'
                                    }}
                                /> : ""
                        }
                    </div>
                    <PerfectScrollbar
                        className={'smallDialogueMessage'}
                        style={{
                            width: '70%',
                            height: 'calc(100% - 20px)',
                            paddingLeft: '10px',
                            boxSizing: 'border-box'
                        }}>
                        {getProductShortDescription(product, language)}
                    </PerfectScrollbar>
                </div>
            </div>
        ),
        id: modalID,
        className: "smallDialogue",
        onClose: () => {},
        canClose: true
    });
};

export const setLastScannedGiftCard = (code) => {
    return {
        type: SET_LAST_SCANNED_GIFT_CARD,
        code
    }
};

export const addReceiptGiftCard = (giftCard) => ({
    type: ADD_RECEIPT_GIFT_CARD,
    giftCard
});

export const addBeforeCompleteTransactionAction = (action) => ({
    type: ADD_BEFORE_COMPLETE_TRANSACTION_ACTION,
    action
});

export const removeBeforeCompleteTransactionAction = (id) => ({
    type: REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTION,
    id
});

export const removeBeforeCompleteTransactionActionsByType = (actionType) => ({
    type: REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTIONS_BY_TYPE,
    actionType
});

export const addPluginRequestSaleAttributes = (attributes) => ({
    type: ADD_PLUGIN_REQUEST_SALE_ATTRIBUTES,
    attributes
});

export const addPluginRequestSaleNotes = (notes) => ({
    type: ADD_PLUGIN_REQUEST_SALE_NOTES,
    notes
});

export const removePluginRequestSaleNote = (noteIndex) => ({
    type: REMOVE_PLUGIN_REQUEST_SALE_NOTE,
    noteIndex
});

export const setReturnBaseDocument = (document, payments) => ({
    type: SET_RETURN_BASE_DOCUMENT,
    document,
    payments
});

export const setPendingDocument = (document) => ({
    type: SET_PENDING_DOCUMENT,
    payload: document
});
export const setInvoice = (document) => ({
    type: SET_INVOICE,
    payload: document
});

export const updateReturnBaseDocumentPayment = (payment) => ({
    type: UPDATE_RETURN_BASE_DOCUMENT_PAYMENT,
    payment
});

export const setAddReceiptTitle = (addReceiptTitle) => ({
    type: SET_ADD_RECEIPT_TITLE,
    addReceiptTitle
});

export const setConnectionHealth = health => ({
    type: SET_CONNECTION_HEALTH,
    payload: health
});

export const setConnectionStatus = status => ({
    type: SET_CONNECTION_STATUS,
    payload: status
});

export const setOnePromotionPromoCode = (code, status, message) => ({
    type: SET_ONE_PROMOTION_PROMO_CODE,
    payload: {
        code,
        status,
        message
    }
});

export const removeOnePromotionPromoCode = () => ({
    type: SET_ONE_PROMOTION_PROMO_CODE,
    payload: false
});

export const checkHealthStatus = (i = 0) => async (dispatch, getState) => {
    let status = 'error';
    let state = getState();
    let clientCode = state.rootReducer.user === false ? "1" : state.rootReducer.user.clientCode;

    const timerOn = new Date().getTime();
    let health = await healthCheck(clientCode);
    const timerOff = new Date().getTime();
    const ping = timerOff - timerOn;
    if(health === false){
        if (i < 2) {
            await sleep(2);
            await dispatch(checkHealthStatus(i + 1));
            return;
        }
        if (state.rootReducer.connectionHealth) {
            dispatch(setConnectionHealth(false));
        }
        status = 'error';
    }else if (health.status === 200) {
        if (!state.rootReducer.connectionHealth) {
            dispatch(setConnectionHealth(true));
        }
        switch (true) {
            case ping < 250:
                status = 'Excellent';
                break;
            case ping >= 250 && ping < 400:
                status = 'Good';
                break;
            case ping >= 400 && ping < 750:
                status = 'Average';
                break;
            case ping >= 750 && ping < 1500:
                status = 'Poor';
                break;
            case ping >= 1500:
                status = 'Poorer';
                break;
            default:
                status = 'error';
                break;
        }
    }
    if (state.rootReducer.connectionStatus !== status) {
        dispatch(setConnectionStatus(status));
    }
};

export const setCustomerZipCode = (zipCode) => ({
    type: SET_CUSTOMER_ZIP_CODE,
    payload: zipCode
});

export const setScreenSaver = (images) => ({
    type: SET_SCREEN_SAVER,
    payload: {
        images,
        current: 0
    }
});

export const setScreenSaverNextImage = () => ({
    type: SET_SCREEN_SAVER_NEXT_IMAGE
});