import {addErrorAlert} from "../../../actions";
import {
    CASH_IN_OUT_SUCCESS,
    OPEN_CASH_DRAWER,
    OPEN_POS_OPEN_DAY_VIEW,
    POS_DAY_CLOSE_SUCCESS
} from "../../../actionTypes";
import {INPUT_PAYMENT_STARTED} from "../../../payment/actionTypes";

let taxID = false;

let hasGiftCard = (productsInBasket, excludedLineNumber = 0) => typeof productsInBasket.find(el => {
    return typeof el.giftCardCode !== "undefined" && el.lineNumber !== excludedLineNumber;
}) !== 'undefined';

export const PFMS = {
    port: false,
    url: 'https://localhost.erply.com',
    log: function(){
        let newArgs = Array.prototype.slice.call(arguments);
        newArgs.unshift('PFMS: ');
        console.log.apply(this, newArgs);
    },
    init: (config) => {
        PFMS.port = config.Port;
    },
    sendRequest: (request, data) => {
        return new Promise((resolve, reject) => {
            fetch(`${PFMS.url}:${PFMS.port}/${request}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((response) => {
                resolve(response.json());
            }, reject);
        });
    },
    printReceipt: (data) => {
        return new Promise((resolve, reject) => {
            PFMS.sendRequest('api/v1/printReceipt', data).then((response) => {
                if(response?.records?.[0]?.statusCode === "0"){
                    resolve(response);
                }else{
                    reject(response);
                }
            }, reject);
        });
    },
    onStateChange: (store, state, action) => {
        let lang = state?.localize?.activeLocale ?? 'pl';
        if(typeof state.rootReducer.pos !== "undefined" && state.rootReducer.pos.fiscalReceiptRequired === false){
            PFMS.log('SET_FISCAL_RECEIPT_REQUIRED');
            store.dispatch({
                type: 'SET_FISCAL_RECEIPT_REQUIRED',
                payload: true
            });
        }else if(action.type === 'HANDLE_FISCAL_REQUEST'){
            PFMS.log('HANDLE_FISCAL_REQUEST', store, state, action);
            printFiscalReceipt(store, state, action, () => {
                setTimeout(() => {
                    printCardReceipts(store, state);
                }, 1500);
            });
        }else if(action.type === 'CARD_PAYMENT_FAILED'){
            PFMS.log('CARD_PAYMENT_FAILED', store, state, action);
            printCardReceipts(store, state, action.payment);
        }else if(action.type === 'PAYMENT_VOIDED'){
            PFMS.log('CARD_PAYMENT_FAILED', store, state, action);
            let payment = {
                type: 'CARD',
                sumPaid: action.data.proccessedSum,
                transaction: {
                    terminalResponse:action.data
                }
            };
            printCardReceipts(store, state, payment);
        }else if(action.type === POS_DAY_CLOSE_SUCCESS){
            PFMS.printReport('z', store, true);
        }else if(action.type === 'POS_DAY_OPEN_SUCCESS'){
            if(action.openedSum > 0){
                PFMS.sendCashInOut(store, state, {
                    payload:{
                        type: 'cashIn',
                        amount: action.openedSum,
                        openCashDrawer: false
                    }
                });
            }
        }else if(
            action.type === OPEN_POS_OPEN_DAY_VIEW ||
            action.type === OPEN_CASH_DRAWER
        ){
            PFMS.openCashDrawer(store);
        }else if(action.type === INPUT_PAYMENT_STARTED){
            if(action.payload.type === 'CASH'){
                PFMS.openCashDrawer(store);
            }
        }else if(action.type === 'SHOW_X_REPORT'){
            PFMS.printReport('x', store, false);
        }else if(action.type === CASH_IN_OUT_SUCCESS){
            PFMS.sendCashInOut(store, state, action);
        }else if(action.type === 'CHANGE_MODE' && action.payload.mode === 'payment'){
            askForTaxID(store, state);
        }else if(action.type === 'TRANSACTION_SUCCESS'){
            taxID = false;
        }else if(action.type === 'ADD_PRODUCT'){
            let product = action.payload;

            let productInBasket = state.rootReducer.productsInBasket[state.rootReducer.productsInBasket.length - 1];
            if(typeof productInBasket === "undefined"){
                return;
            }
            product.lineNumber = productInBasket.lineNumber;

            let kioskOrderCode = '7000000007';

            let errorAlert = (type) => {
                store.dispatch({
                    type: 'ADD_ALERT',
                    payload: {
                        type: 'error',
                        message: 'Can\'t have ' + type + ' and other products on the same transaction!',
                        autoClose: true
                    }});
            };

            let hasGiftCardProduct = hasGiftCard(state.rootReducer.productsInBasket, product.lineNumber);
            let hasKioskProduct = typeof state.rootReducer.productsInBasket.find(el=> el.data.code === kioskOrderCode && el.lineNumber !== product.lineNumber) !== 'undefined';
            let hasRegularProduct = state.rootReducer.productsInBasket.length > 1 && !hasGiftCardProduct && !hasKioskProduct;

            let isGiftCard = typeof product.giftCardCode !== "undefined";
            let isKioskOrder = product.data.code === kioskOrderCode;

            let handleRemove = () => {
                let errorType = hasGiftCardProduct || isGiftCard ? 'gift card' : 'kiosk order';
                errorAlert(errorType);
                store.dispatch({
                    type: 'REMOVE_PRODUCT',
                    payload: product
                });
            };

            if(hasGiftCardProduct){
                if(!isGiftCard){
                    handleRemove();
                }
            }else if(hasKioskProduct){
                if(!isKioskOrder){
                    handleRemove();
                }
            }else{
                if((isGiftCard || isKioskOrder) && hasRegularProduct){
                    handleRemove();
                }
            }
        }else if(state.rootReducer.operationMode === 'lite' && action.type === 'ADD_PAYMENT' && action.payload.type === 'GIFTCARD'){
            let hasGiftCardProduct = typeof state.rootReducer.productsInBasket.find(el=> typeof el.giftCardCode !== "undefined") !== 'undefined';
            if(hasGiftCardProduct){
                store.dispatch({
                    type: 'REMOVE_PAYMENT',
                    payload: action.payload
                });
                store.dispatch({
                    type: 'SET_PAYMENT_MODE',
                    payload: {
                        mode: 'options'
                    }
                });
                store.dispatch({
                    type: 'ADD_ALERT',
                    payload: {
                        type: 'error',
                        message: translate('Can\'t pay for a gift card with another gift card!', lang),
                        autoClose: true
                    }
                });
            }
        }else if((action.type === 'CHANGE_MODE' && action.payload.mode === 'addGiftCard') ||
            (action.type === 'GIFT_CARD_API' && action.payload.data.request === 'giftCardAvailable'))
        {
            let hasRegularProduct = state.rootReducer.productsInBasket.length > 0 && !hasGiftCard(state.rootReducer.productsInBasket);
            if(hasRegularProduct){
                setTimeout(() => {
                    store.dispatch({type: 'CHANGE_MODE',payload: {mode: 'scan'}});
                    store.dispatch({type: 'CLOSE_RIGHT_PANEL_DIALOGUE', payload: {id: false}});
                    store.dispatch({
                        type: 'ADD_ALERT',
                        payload: {
                            type: 'error',
                            message: 'Can\'t have gift card and other products on the same transaction!',
                            autoClose: true
                        }}
                    );
                }, 1);
            }
        }
    },
    getRegisterID: (state) => state.rootReducer.pos.name.slice(-2).replace(/^[0]/, ''),
    displayErrorMessage: (store, response) => {
        let record = response?.records?.[0] || {statusCode: 1, statusMessage: "Unknown error"};
        store.dispatch(addErrorAlert("Printing failed. Code: " + record.statusCode + ", message: " + record.statusMessage));
    },
    openCashDrawer: (store) => {
        PFMS.printReceipt({
            openCashDrawer: true
        }).then(() => {

        }, (response) => {
            PFMS.displayErrorMessage(store, response);
        });
    },
    sendCashInOut: (store, state, action) => {
        let data = {
            documentType: action.payload.type === 'cashIn' ? 'CASH-IN' : 'CASH-OUT',
            total: action.payload.amount
        }
        if(action.payload?.openCashDrawer ?? true){
            data.openCashDrawer = true;
        }
        PFMS.printReceipt(data).then(() => {

        }, (response) => {
            PFMS.displayErrorMessage(store, response);
        });
    },
    printReport: (type, store, openCashDrawer) => {
        let data = {
            dayReport: type
        };
        if(openCashDrawer){
            data.openCashDrawer = true;
        }
        PFMS.printReceipt(data).then(() => {}, (response) => {
            PFMS.displayErrorMessage(store, response);
        });
    }
}

const getEmployeeByID = (employeeID, employees) => {
    return employees.find((employee) => {
        return employee.employeeID === employeeID;
    });
};

const getCurrentDateYMD = () => {
    let date = new Date();
    return [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2)
    ].join('');
};

const createBarcode = (invoiceNo, clientCode) => {
    return '77' + clientCode + getCurrentDateYMD() + invoiceNo + '0';
};

const createEmployeeID = (employee) => {
    return (employee.firstName.slice(0,1) + employee.lastName.slice(0,1) + employee.lastName.slice(-1)).toUpperCase();
};

const getNextSequenceNumber = (store, state) => {
    return new Promise((resolve) => {
        if(process.env.REACT_APP_ERPLY_MODE === "1"){
            resolve(false);
            return;
        }
        if(state.rootReducer.nextSequenceNumber !== false){
            resolve(state.rootReducer.nextSequenceNumber);
            return;
        }

        store.dispatch({
            type: "GET_NEXT_SEQUENCE_NUMBER",
            payload: {
                success: (number) => {
                    store.dispatch({
                        type: "SET_NEXT_SEQUENCE_NUMBER",
                        payload: number
                    });
                    resolve(number);
                },
                fail: () => {
                    resolve(false)
                }
            }
        });
    });
}

const printFiscalReceipt = async function (store, state, action, afterPrint) {
    if (state.payment.totalSum == 0) {
        action.payload.onSuccess();
        return;
    }

    let isReturn = state.payment.totalSum < 0;

    let isGiftCardReceipt = true;
    let isKioskOrderReceipt = true;

    let totalDiscount = 0;
    const fiscalReceiptRows = state.rootReducer.productsInBasket.map(x => {
        let isGiftCard = typeof x.giftCardCode !== "undefined";
        if (!isGiftCard) {
            isGiftCardReceipt = false
        }

        let isKioskOrder = x.data.code === '7000000007';
        if (!isKioskOrder) {
            isKioskOrderReceipt = false
        }

        let vatrate = state.rootReducer.vatRates.find(e => e.id == x.data.vatrateID);
        let usedProductVatRate = state.rootReducer.vatRates.find(e => e.name === "DKT:TechnicalExempt");

        let data = {
            description: x.name.slice(0, 49) + ' | ' + x.data.code,
            quantity: x.amount.toString(),
            measureUnit: '',
            unitPrice: (x.rowTotal / x.amount).toFixed(2),
            rowTotal: x.rowTotal.toString(),
            vatrate: x.data.vatrate,
            rowVAT: x.rowVAT.toFixed(2),
            vatrateID: vatrate.code,
            isDepositItem: isGiftCard
        };

        let RFIDSerialNumber = typeof x.data.RFIDSerialNumber !== "undefined" ?
            x.data.RFIDSerialNumber :
            typeof x.data.RFIDTag !== "undefined" && x.data.RFIDTag.SERIAL_NUMBER !== 'NULL_VALUE' ? x.data.RFIDTag.SERIAL_NUMBER : false;
        if(RFIDSerialNumber !== false){
            data.rfid = RFIDSerialNumber;
        }

        if(!isReturn && x.manualDiscountTotal > 0){
            data.unitPrice = x.originalPriceWithVAT.toFixed(2);
            data.rowTotal = (x.originalPriceWithVAT * x.amount).toFixed(2);
            data.discountAmount = (parseFloat(data.rowTotal) - x.rowTotal).toFixed(2);
            totalDiscount += parseFloat(data.discountAmount);
        }
        if(x.data?.isUsedProduct === 1){
            data.vatrate = usedProductVatRate.rate;
            data.rowVAT = 0;
            data.vatrateID = usedProductVatRate.code;
        }
        return data;
    });

    let fiscalPayments = getFiscalPayments(state.payment.payments);
    let timestamp = Math.floor(Date.now() / 1000);
    let receiptNumber = state.rootReducer.nextInvoiceNo.toString();
    let employee = getEmployeeByID(state.rootReducer.user.employeeID, state.rootReducer.employees);
    let employeeID = 'null';
    if (typeof employee !== 'undefined') {
        employeeID = createEmployeeID(employee);
    }

    let registerID = PFMS.getRegisterID(state);

    const jsonData = {
        receiptNumber: receiptNumber,
        total: state.payment.totalSum.toString(),
        timestamp,
        registerID,
        regCode: state.rootReducer.companyInfo.code,
        rows: fiscalReceiptRows,
        payments: fiscalPayments,
        header: ['Receipt number ' + receiptNumber],
        barcode: createBarcode(receiptNumber, state.rootReducer.user.clientCode),
        employeeID
    };

    if(totalDiscount !== 0){
        if (typeof jsonData.footer === "undefined") {
            jsonData.footer = [];
        }
        jsonData.footer.push("Discount " + totalDiscount.toFixed(2));
    }

    const nextSequenceNumber = await getNextSequenceNumber(store, state);
    if (nextSequenceNumber !== false) {
        if (typeof jsonData.footer === "undefined") {
            jsonData.footer = [];
        }
        jsonData.footer.push("numer transakcj: " + nextSequenceNumber);
    }

    if (isReturn) {
        let returnLines = [
            "                                      ",
            "     Protokół zwrotu / reklamacji     ",
            "         ....................         ",
            "POS",
            "......................................",
            "            podpis klienta            ",
            "                                      ",
            "- - - - - - - - - - - - - - - - - - - ",
            "            podpis kasjera            ",
            "                                      "
        ];
        jsonData.header.push(...returnLines);
        jsonData["documentType"] = "RETURN";
    } else if (isGiftCardReceipt) {
        jsonData.documentType = "GIFTCARD";
    } else if (isKioskOrderReceipt) {
        jsonData.documentType = "OTHER";
    }

    if (taxID !== false) {
        jsonData.vatNo = taxID;
    }

    PFMS.log('fiscal receipt', jsonData);
    PFMS.printReceipt(jsonData).then((response) => {
        PFMS.log('Receipt printed', {response});
        action.payload.onSuccess(response);
    }, (response) => {
        PFMS.log('Receipt printing failed', {response});

        response.errorCode = response?.records?.[0]?.statusCode ?? response.errorCode ?? "";

        if(typeof response.textResponse === "undefined"){
            response.textResponse = response?.records?.[0]?.statusMessage ?? '';
        }
        if (response.errorCode === '111' ||
            response.errorCode === '203' ||
            response.errorCode === '105'
        ) {
            if (response.errorCode === '203') {
                response.textResponse = 'Brak papieru w drukarce. Wymień rolkę.';
            }
            let errorMessage =  translate('Error code') + ': ' + response.errorCode + '. ' + response.textResponse;
            store.dispatch(showCallAssistance(false,
                'could not complete transaction',
                false, undefined, false,
                translate('Fiscal request failed. Please call assistance!') + ' ' + errorMessage,
                'Ok',
                () => {
                    store.dispatch(showDialogue(
                        'Was the fiscal printing successful?',
                        errorMessage,
                        'Yes',
                        () => {
                            action.payload.onSuccess();
                        }, () => {
                            action.payload.onFail(response);
                        }, 'No', false)
                    );
                })
            );
        } else {
            if (response.errorCode === '201') {
                response.textResponse = 'Pokrywa drukarki jest otwarta. Zamknij ją, by kontynuować.';
            } else if (response.errorCode === '112') {
                response.textResponse = 'Drukarka jest wyłączona. Włącz ją, by kontynuować.';
            }
            action.payload.onFail(response);
        }
    });

    afterPrint();
};

const printCardReceipts = function (store, state, initialPayment = false) {
    PFMS.log("printCardReceipts", {initialPayment});
    const addProperties = obj => {
        if (typeof obj !== 'undefined') {
            const paymentData = obj.filter(f => f.type === 'CARD');

            return paymentData.map(payment => {
                if (typeof payment.transaction.terminalResponse === 'undefined') {
                    payment.transaction.terminalResponse = {
                        transactionType: "",
                        status: ""
                    };
                }
                if (typeof payment.transaction.terminalResponse.receipt === 'undefined') {
                    payment.transaction.terminalResponse.receipt = {

                    };
                }
                return payment;
            });
        }
        return [];
    };

    let payments = initialPayment === false ? state.payment.payments : [initialPayment];
    let terminalData = addProperties(payments);

    PFMS.log("printCardReceipts", {terminalData});
    const formatReceiptLines = (receiptType, transactionType) => {
        let amountSaleIndex = 0;
        const paymentDataString = receiptType.map((x, i) => {
            if (x.lineNumber === 31) {
                amountSaleIndex = i;
            }
            if (x.lineNumber === 14) {
                if(x.text.includes('COMMISION')) {
                    x.text = '';
                }
            }
            return `${x.lineNumber}|${x.text}`;
        });
        paymentDataString.splice(
            amountSaleIndex - 1,
            0,
            `Transaction type|${transactionType}`
        );
        return paymentDataString;
    };

    const formatNonFiscalReceiptLines = (receiptType) => {
        return receiptType.map((line)=>{
            return `999|${line.fontSize}|${line.background}|${line.fontType}|${line.text.replaceAll("\ufffd","?")}`;
        });
    };

    const tryPrintReceipt = (jsonData, retries = 0) => {
        PFMS.log("printCardReceipts", {jsonData});
        PFMS.printReceipt(jsonData).then((response) => {
            PFMS.log('Receipt printed', {response});
        }, (response) => {
            if(response?.records?.[0]?.statusCode === "105"){
                if(retries < 5){
                    setTimeout(() => {
                        tryPrintReceipt(jsonData, retries + 1);
                    }, 1500);
                }
            }
            PFMS.log('Receipt printing failed', {response});
        });
    }

    terminalData.forEach((payment, index) => {
        let receipt = payment.transaction.terminalResponse.receipt;
        Object.keys(receipt).sort((a,b) => {
            return b.indexOf('ustomer');
        }).forEach(receiptType => {
            let paymentDataString;
            let transactionType = payment.transaction.terminalResponse.transactionType;
            let statusCode = payment.transaction.terminalResponse.statusCode;

            if(((transactionType === 'Sale' || transactionType === 'Void')
                    && ( receiptType === 'nonFiscalCustomer' || receiptType === 'customer')) ||
                (transactionType === 'Return' && statusCode === '0')
            ) {
                if (receiptType.indexOf('nonFiscal') === -1) {
                    paymentDataString = formatReceiptLines(receipt[receiptType], transactionType);
                } else {
                    paymentDataString = formatNonFiscalReceiptLines(receipt[receiptType]);
                }
            }else{
                return true;
            }
            setTimeout(() => {
                tryPrintReceipt({
                    documentType: 'FREETEXT',
                    footer: paymentDataString
                });
            }, index * 1500);
        });
    });
};

const getFiscalPayments = function (payments) {
    const availableMOP = [
        'CARD',
        'CASH',
        'GIFTCARD',
        'GIFT CARD',
        'CHECK',
        'TRANSFER'
    ];
    let otherPaymentType = false;
    return payments.map(x => {
        let paymentType = x.type.toUpperCase();
        if(paymentType === 'MANUALCARD'){
            paymentType = 'CARD'
        }else if(paymentType === 'OFFLINEBANKCARD'){
            paymentType = 'CARD'
        }else if(paymentType === 'COMMERCIALVOUCHER'){
            paymentType = 'GIFTCARD'
        }else if(paymentType === 'DEFERRED'){
            paymentType = 'OTHER';
            otherPaymentType = 'PROFORMA';
        }else if (availableMOP.indexOf(paymentType) === -1) {
            otherPaymentType = paymentType;
            paymentType = 'OTHER';
        }
        let amount = Math.abs(parseFloat(x.sumPaid));
        let data = {
            paymentType,
            amount: amount.toString()
        };
        if(otherPaymentType !== false){
            data['otherPaymentType'] = otherPaymentType;
        }
        return data;
    });
};

const translations = {
    'Do you want an invoice?': {
        'pl': 'Czy chcesz fakturę?',
        'ru': 'Хотите ли вы счет?'
    },
    'Enter your TAX ID': {
        'pl': 'Wprowadź NIP',
        'ru': 'Введите свой НДС номер'
    },
    'Yes': {
        'pl': 'Tak'
    },
    'No': {
        'pl': 'Nie'
    },
    'Maximum amount of characters allowed is 20. Please make sure you type the correct Tax ID': {
        'pl': 'Maksymalna ilość znaków to 20. Upewnij się, że wpisujesz poprawny NIP',
        'ru': 'Максимальное количество символов - 20. Убедитесь, что вы ввели правильный идентификационный номер налогоплательщика.'
    },
    'Can\'t pay for a gift card with another gift card!': {
        'pl':'Uwaga: Płatność kartą podarunkową/bonem podczas aktywacji karty jest niedozwolona'
    }
};

const translate = (string, lang) => {
    let translation = string;
    if(translations[string] !== undefined && translations[string][lang] !== undefined){
        translation = translations[string][lang];
    }
    return translation;
};

const askForTaxID = (store, state) => {
    let lang = state?.localize?.activeLocale ?? 'pl';

    let enterTaxID = (initialInputValue='') => {
        store.dispatch({
            type: 'SHOW_DIALOGUE',
            payload: {
                title: translate('Enter your TAX ID', lang),
                message: '',
                inputLabel: '',
                onConfirm: (value) => {
                    if(value.length === 0){
                        setTimeout(() => {
                            enterTaxID();
                        }, 100);
                        return false;
                    }

                    if(value.length > 20){
                        setTimeout(() => {
                            store.dispatch({
                                type: 'SHOW_DIALOGUE',
                                payload: {
                                    title: translate('Maximum amount of characters allowed is 20. Please make sure you type the correct Tax ID', lang),
                                    message: '',
                                    confirmText: translate('Ok', lang),
                                    onConfirm: () => {
                                        setTimeout(() => {
                                            enterTaxID(value);
                                        }, 100);
                                    },
                                    canClose: false
                                }
                            });
                        }, 1);
                        return false;
                    }
                    taxID = value;
                    store.dispatch({
                        type: 'SET_PLUGIN_REQUEST_PARAMETERS',
                        payload: {
                            saveSalesDocument: {
                                attributes: [
                                    {
                                        name: 'customer_tax_id_number',
                                        value: taxID
                                    }
                                ]
                            }
                        }
                    });

                },
                onCancel: ()=>{},
                canClose: true,
                inputDialogue: true,
                initialInputValue,
                keyboardLayout: 'numbersOnly',
                changeToLettersLayout: 'capitalLettersOnly',
                changeToNumbersLayout: 'numbersOnly',
            }
        });
    };

    store.dispatch({
        type: 'SHOW_DIALOGUE',
        payload: {
            title: translate('Do you want an invoice?', lang),
            message: '',
            confirmText: translate('Yes', lang),
            onConfirm: () => {
                setTimeout(() => {
                    enterTaxID(taxID !== false ? taxID : '');
                }, 1);
            },
            onCancel: () => {
                if(typeof state.rootReducer.pluginRequestParameters.saveSalesDocument !== "undefined"){
                    store.dispatch({
                        type: 'SET_PLUGIN_REQUEST_PARAMETERS',
                        payload: {}
                    });
                }
                taxID = false;
            },
            cancelText: translate('No', lang),
            canClose: false,
            smallDialogue: true
        }
    });
};

const showCallAssistance = (administrationView, titleMessage = '', canCancel = false, onCancel = ()=>{}, canClose = false, content=undefined, confirmText='Logon', onConfirm=()=>{}) => ({
    type: 'SHOW_CALL_ASSISTANCE',
    payload: {
        administrationView,
        titleMessage,
        canCancel,
        onCancel,
        canClose,
        content,
        confirmText,
        onConfirm
    }
});

const showDialogue = (title, message, confirmText, onConfirm, onCancel, cancelText, canClose=true, id, initialInputValue='', smallDialogue=false) => ({
    type: 'SHOW_DIALOGUE',
    payload: {
        id,
        title,
        message,
        confirmText,
        onConfirm,
        onCancel,
        cancelText,
        canClose,
        initialInputValue,
        smallDialogue
    }
});