import React, {Component} from 'react';
import {connect} from "react-redux";
import "./main.css"
import InputWithKeyboard from "../formElements/InputWithKeyboard";
import {getCustomers, setCustomer, setCustomerSelectList} from "../redux/actions";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../uiComponents/WrappedPerfectScrollBar';
import Translate from "localization/Translate";
import CustomerDTO from "../redux/dto/customer";
import {Loader} from "./Loading";

const mapStateToProps = state => {
    return {
        customerSelectList: state.rootReducer.customerSelectList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        findCustomers: (query, onDone) => {
            dispatch(getCustomers(query, {}, result => {
                onDone();
                return setCustomerSelectList(result)
            }, result => {
                onDone();
                return setCustomerSelectList([])
            }));
        },
        clearQuery: () => {
            dispatch(setCustomerSelectList([]));
        },
        setCustomer: (customer) => {
            let customerDTO = new CustomerDTO();
            Object.assign(customerDTO, customer);
            dispatch(setCustomer(customerDTO));
        }
    }
};

class Customer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            lastSearchTime: false,
            lastSearchQuery: false,
            searching: false
        }
    }

    componentWillUnmount() {
        this.props.clearQuery();
    }

    findCustomers(query, time) {
        setTimeout(() => {
            if(time === this.state.lastSearchTime){
                this.props.findCustomers(query, () => {
                    this.setState({
                        searching: false
                    });
                });
            }
        }, 1000);
    }

    render() {
        let content;

        if(this.state.searching){
            content = (
                <Loader position={"absolute"}/>
            );
        }else if(this.props.customerSelectList.length > 0){
            content = (
                <PerfectScrollbar style={{
                    width: "100%"
                }}>
                    <ul className={'dialogueSelectList'} style={{
                        border: 'none'
                    }}>
                        {this.props.customerSelectList.map((customer, index) => {
                            return <li key={index} onClick={e => {
                                this.props.setCustomer(customer);
                                this.props.onSelect(customer);
                            }}>{customer.fullName}</li>
                        })}
                    </ul>
                </PerfectScrollbar>
            );
        }else if(this.state.lastSearchQuery.length > 2){
            content = <Translate id={'No customer matches the query'}/>
        }

        return (
            <div>
                <InputWithKeyboard
                    onInputChange={(value) => {
                        console.log('customer input changed', value, value.length);
                        let time = Date.now();
                        this.setState({
                            lastSearchTime: time,
                            lastSearchQuery: value,
                        });
                        if(value.length > 2){
                            this.setState({
                                searching: true
                            });
                            this.findCustomers(value, time);
                        }else{
                            // this.props.clearQuery();
                        }
                    }}
                    onDone={(value) => {
                        this.props.clearQuery();
                    }}
                />
                <div style={{
                    position: 'absolute',
                    top: '120px',
                    bottom: '240px',
                    left: 0,
                    right: 0,
                    height: 'auto'
                }}>
                    { content }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
