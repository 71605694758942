import Decimal from "decimal.js";
import paymentDTO from "../../redux/payment/dto/payment";
import {getPaymentTypeByReasonCode, getProductsTotalSum} from "../selectors";

export const getFirstStartedGiftCardPayment = (payments) => {
    const startedPayments = payments.filter(function (payment) {
        return payment.type === "GIFTCARD" && payment.status === "started";
    });
    return startedPayments.length > 0 ? startedPayments[0] : false;
};

export const getCheckedGiftCardPayments = (payments) => {
    return payments.filter(function (payment) {
        return payment.type === "GIFTCARD" && payment.status === "checked";
    });
};

export const giftCardPaymentIsPresent = (payments) => {
    return typeof payments.find(payment => {
        return payment.type === "GIFTCARD";
    }) !== "undefined";
}

export const giftCardIsInTransaction = (number, payments) => {
    return payments.filter(function (payment) {
        return payment.type === "GIFTCARD" && payment.status === "checked" && payment.giftCard.cardNumber === number;
    }).length > 0;
};

export const getGiftCardsSum = payments => {
    let total = getCheckedGiftCardPayments(payments).reduce((acc, payment) => {
        acc = acc.plus(new Decimal(payment.sum));
        return acc;
    }, new Decimal(0));

    return total.toDecimalPlaces(4).toString();
};

export const getToBeCreditedSum = (state) => {
    const totalSum = state.rootReducer.invoice !== false ? state.rootReducer.invoice.total : getProductsTotalSum(state.rootReducer.productsInBasket);
    const remainingSum = new Decimal(totalSum).minus(new Decimal(state.payment.totalPaid)).toDecimalPlaces(4).toString();
    const giftCardsSum = getGiftCardsSum(state.payment.payments);
    return new Decimal(remainingSum).minus(new Decimal(giftCardsSum)).toDecimalPlaces(4).toString();
}

export const getCashPayment = (payments) => {
    let cashPayments = payments.filter(function (payment) {
        return payment.type === "CASH";
    });

    return cashPayments.length > 0 ? cashPayments[0] : false;
};

export const getCardPayments = (payments) => {
    return payments.filter(function (payment) {
        return payment.type === "CARD";
    });
};

export const getTotalPaid = (payments) => {
    return payments.reduce(function (acc, payment) {
        acc += payment.sumPaid - payment.sumChange;
        return acc;
    }, 0);
};

export const getCashChange = (payments) => {
    return payments.reduce(function (acc, payment) {
        acc = acc.plus(new Decimal(payment.cashChange));
        return acc;
    }, new Decimal(0)).toDecimalPlaces(4).toString();
};

export const getPayment = (payment) => {
    return Object.assign(new paymentDTO(), structuredClone(payment));
}

export const getManualCardPayment = (paymentSum, state) => {
    let paymentType = getManualCardPaymentType();
    if(paymentType === false){
        return false;
    }
    let erplyPaymentType = getPaymentTypeByReasonCode(state.payment.paymentTypes, paymentType.reasonCode);
    if(typeof erplyPaymentType === "undefined"){
        return false;
    }

    const paymentsLength = state.payment.payments.length;
    const nextPaymentIndex = paymentsLength === 0 ? 0 : state.payment.payments[paymentsLength - 1].index + 1;
    const payment = new paymentDTO(nextPaymentIndex, paymentSum, "success", paymentType.type);
    payment.title = paymentType.name;
    payment.typeID = erplyPaymentType.id;
    payment.sumPaid = paymentSum;
    return payment;
}

export const getManualCardPaymentType = () => {
    let paymentType = window.AppConf.paymentTypes.manualInput.find(el => el.reasonCode === "98");
    return typeof paymentType === "undefined" ? false : paymentType;
}