export const MCMS = {
    port: false,
    url: 'https://localhost.erply.com',
    available: false,
    log: function(){
        let newArgs = Array.prototype.slice.call(arguments);
        newArgs.unshift('MCMS: ');
        console.log.apply(this, newArgs);
    },
    init: (config) => {
        MCMS.port = config.Port;
        MCMS.processQueue();
    },
    queue: [],
    processQueue: () => {
        if(MCMS.queue.length === 0 || MCMS.port === false){
            return;
        }
        let {request, options, resolve, reject} = MCMS.queue.shift();
        MCMS.sendRequest(request, options).then(resolve, reject);
    },
    sendRequest: (request, options) => {
        return new Promise((resolve, reject) => {
            if(MCMS.port === false){
                MCMS.queue.push({request, options, resolve, reject});
                return;
            }
            let url = `${MCMS.url}:${MCMS.port}/`;
            fetch(url + request, options).then((response) => {
                resolve(response.json());
            }, reject);
        });
    },
    setMonitoringParameters: (clientCode, posID, sessionKey) => {
        MCMS.log("setMonitoringParameters", {clientCode, posID, sessionKey});
        return MCMS.sendRequest('api/v1/setMonitoringParameters', {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Clientcode: clientCode,
                Sessionkey: sessionKey
            },
            body: JSON.stringify({
                sendingDataPeriodMinutes:1,
                posId:posID.toString(),
                posVersion: "SCO - v" + (window.AppConf?.version ?? "2")
            })
        });
    }
};
