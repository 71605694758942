import React, {useState} from "react";
import Translate from "localization/Translate";
import {validateEmail, validatePhone} from "../../redux/dto/customer";
import Customer from "../../redux/dto/customer";
import Button from "../../buttons/Button";
import {useDispatch, useSelector} from "react-redux";
import {closeRightPanelDialogue} from "../../redux/actions";
import decathlonActiveImg from "../images/decathlonActive.png";
import CountryDialCode from "../../uiComponents/CountryDialCode";
import {getCountryDialCode} from "../../redux/selectors";
import MultipleInputContent from "../../modal/MultipleInputContent";

const determineValueType = (value, customerCodeStartsWith) => {
    if(value.startsWith(customerCodeStartsWith)){
        return "card";
    }
    if(value.indexOf("@") !== -1){
        return "email";
    }
    return false;
}

export default function (props) {
    let customer = useSelector(state => state.rootReducer.customer);
    let country = useSelector(state => state.rootReducer.erplyConf.country);
    let connectionHealth = useSelector(state => state.rootReducer.connectionHealth);

    if(customer !== false){
        const dispatch = useDispatch();
        dispatch(closeRightPanelDialogue());

    }

    const [inputValues, setInputValues] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const [selectedDialCode, setSelectedDialCode] = useState(props.countryCode);
    const onInputDone = (values) => {
        let phone = values?.phone ?? "";
        let emailCard = values?.emailCard ?? "";

        let customer = new Customer();
        if(phone !== ""){
            let phoneNumber = getCountryDialCode(selectedDialCode) + phone.replace(/\D/g,'');
            if(validatePhone(phoneNumber)){
                customer.phone = phoneNumber;
                props.onDone(customer);
            }
        }else{
            let valueType = determineValueType(emailCard, props.customerCodeStartsWith);

            if(valueType === "card"){
                customer.card_number = emailCard;
                props.onDone(customer);
            }else if(valueType === "email" && validateEmail(emailCard)){
                customer.email = emailCard;
                props.onDone(customer);
            }else {
                setValidationError(<Translate id={"Not a proper value"}/>)
            }
        }
    }

    let validationErrorDisplay;
    if(validationError !== false){
        validationErrorDisplay = (
            <div className={'errorMessage'} style={{
                fontSize: "14px",
                textAlign: "left"
            }}>{validationError}</div>
        );
    }

    let decathlonActive;
    if(country === 'PL'){
        decathlonActive =
            <div style={{
                position: "absolute",
                left: "60px",
                top: "54px"
            }}>
                <img src={decathlonActiveImg} alt={"Decathlon active"} style={{
                    width: "220px"
                }}/>
            </div>;
    }


    let inputFields;
    if(connectionHealth){
        inputFields = [
            {
                id: 'phone',
                placeholder: '123 123 123',
                active: true,
                labelContent: (
                    <div style={{float: "left", marginTop: "10px", width: "150px"}} onClick={e => e.preventDefault()}>
                        <CountryDialCode
                            defaultCountryCode={props.countryCode}
                            selectedDialCode={selectedDialCode}
                            setSelectedDialCode={(code) => {
                                setSelectedDialCode(code);
                                document.getElementsByName("phone")[0].click();
                            }}
                        />
                    </div>
                ),
                style: {
                    width: "calc(100% - 150px)"
                }
            },
            { id: 'emailCard', placeholder: "Email or card number"},
        ];
    }else{
        inputFields = [
            { id: 'card', placeholder: "209xxxxx", active: true}
        ];
    }


    return (
        <MultipleInputContent
            inputFields={inputFields}
            beforeInputFields={
                <>
                    {decathlonActive}
                    <div style={{ fontSize: "20px", marginBottom: "-20px"}}>{<Translate id={connectionHealth ? "Enter phone number, email or card number" : "Enter loyalty card number"}/>}</div>
                </>
            }
            inputClassName={"shadowBoxInput"}
            inputStyle={{
                width: "100%",
                marginTop: "10px"
            }}
            inputContainerStyle={{
                width: "450px",
                margin: "160px auto 0"
            }}
            initialKeyboardLayout={"numbers"}
            onChange={(newState) => {
                setInputValues(Object.assign({}, inputValues, newState));
            }}
            onDone={onInputDone}
            afterInputFields={
                <>
                    {validationErrorDisplay}
                    <div style={{
                        display: "flex",
                        width: '100%'
                    }}>
                        <div onClick={props.onBack} style={{
                            margin: "10px 30px 10px 0"
                        }}>
                            <Button type="button" className={"wideButton grayButton"} name={<Translate id="cancel"/>}/>
                        </div>
                        <div onClick={() => {
                            onInputDone(inputValues);
                        }} style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            flexGrow: 1
                        }}>
                            <Button type="button" className={"wideButton darkerBlueButton"} name={<Translate id="confirm"/>} style={{
                                width: "100%"
                            }}/>
                        </div>
                    </div>
                </>
            }
        />
    );
}
