import React, { Component } from 'react';
import '../statusBar.css'
import '../../App.css'
import {connect} from "react-redux";
import Translate from "localization/Translate";
import scanImg from "./images/Scan.png";
import paymentImg from "./images/Cash.png";
import doneImg from "./images/Checkmark.png";

const mapStateToProps = state => {
    return {
        view: state.rootReducer.view,
        mode: state.rootReducer.mode,
        uiCustomization: state.rootReducer.uiCustomization
    }
};

function StepSeparator() {
    return (
        <div style={{
            float: "left",
            width: "20px",
            margin: "10px"
        }}/>
    );
}

function Triangle(props) {
    let style = {
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "32.5px 0 32.5px 10px",
        borderColor: "transparent transparent transparent " + props.color,
        position: "absolute",
        top: 0
    }
    if(props.position === 'left'){
        style.left = 0;
        style.transition = "border-color 0.5s ease-out";
    }else if(props.position === 'right'){
        style.right = -10;
        style.transition = "border-color 0.5s ease-in";
    }
    return (
        <div style={style}/>
    );
}

function Step(props) {
    const opacity = props.active ? 0.2 : 0;
    const backgroundColor = `rgb(255, 255, 255, ${opacity})`;
    return (
        <div style={{
            transition: "background-color 0.5s ease-in",
            float: "left",
            padding: "20px",
            fontSize: "16px",
            backgroundColor,
            position: "relative",
            height: "100%",
            boxSizing: "border-box"
        }}>
            <Triangle color={props.active ? "#3d94f6" : "transparent"} position={"left"}/>
            <img src={props.img} alt={"step"} style={{
                float: "left"
            }}/>
            <span style={{
                marginLeft: "10px",
                marginTop: "3px",
                textTransform: "capitalize",
                float: "left"
            }}>{props.name}</span>
            <Triangle color={props.active ? backgroundColor : "transparent"} position={"right"}/>
        </div>
    );
}

class Steps extends Component{

    render() {
        if(this.props.view !== 'app' || this.props.mode === 'screenSaver' || this.props.uiCustomization.statusBarSteps.display !== 1){
            return null;
        }
        return (
            <div style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                width: 'fit-content',
                margin: 'auto'
            }}>
                <Step img={scanImg} name={<Translate id={'scan'}/>} active={this.props.mode === 'scan'}/>
                <StepSeparator/>
                <Step img={paymentImg} name={<Translate id={'payment'}/>} active={this.props.mode === 'payment'}/>
                <StepSeparator/>
                <Step img={doneImg} name={<Translate id={'done'}/>} active={this.props.mode === 'done'}/>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Steps);
