import {
    ADD_ALERT,
    ADD_DOCUMENT_DISCOUNT,
    ADD_MANUAL_PROMOTION_ID,
    ADD_PLUGIN_REQUEST_SALE_DATETIME,
    ADD_PRODUCT,
    API_ERROR,
    APPLY_PROMOTIONS,
    APPLY_RECEIPT_PLUGIN,
    CANCEL_TRANSACTION,
    CASH_IN_OUT_SUCCESS,
    CHANGE_MODE,
    CHANGE_TRANSACTION_MODE,
    CLOSE_MODAL,
    CLOSE_QCO,
    CREATE_RECEIPT,
    CREATE_RECEIPT_NUMBER,
    GET_NEXT_SEQUENCE_NUMBER,
    INIT_POS,
    INIT_TRANSACTION,
    OPEN_MODAL,
    POS_DAY_CLOSE_SUCCESS,
    POS_DAY_OPEN_SUCCESS,
    PRODUCT_NOT_FOUND,
    RECEIPT_DELIVERED,
    REMOVE_CUSTOMER,
    REMOVE_MANUAL_PROMOTION_ID,
    REMOVE_PRODUCT,
    REVERT_NEXT_SEQUENCE_NUMBER,
    SCANNER_DETECTED,
    SECURITY_ITEM_ADDED,
    SET_CONNECTION_HEALTH,
    SET_CUSTOMER,
    SET_CUSTOMER_AGE_VERIFIED,
    SET_ERPLY_SERVICE_ENDPOINTS,
    SET_ONE_PROMOTION_PROMO_CODE,
    SET_PENDING_DOCUMENT,
    SET_POS_DATA,
    SET_POS_DATA_FAILED,
    SET_POS_DAY_OPENED,
    SET_USER,
    SHOW_DIALOGUE,
    TRANSACTION_SUCCESS,
    TRANSACTION_TIMEOUT_REACHED,
    UPDATE_PRODUCT,
    UPDATE_PRODUCTS
} from "../actionTypes";
import {
    addErrorAlert,
    addInfoAlert,
    addProduct,
    addProductCodeInfo,
    addProductReturnReasonID,
    addProductRFIDSerialNumber,
    addReceiptExtraLine,
    applyPromotions,
    cancelAddUnknownItem,
    cancelTransaction as cancelTransactionAction,
    changeMode,
    changeOperationMode,
    changeTransactionMode,
    closeAdministration,
    closeAllDialogues,
    closeAllModals,
    closeDialogue,
    closeModal,
    closeQCO,
    closeRightPanelDialogue,
    findCustomerByEmail,
    findCustomerByPhone,
    findProduct,
    findProducts,
    getHasSupervisorUserRights,
    getUserRights,
    initPOS,
    initTransaction,
    openLogonModal,
    openVerifyPinModal,
    removeAlert,
    removeDocumentDiscount,
    removeProduct,
    securityItemAdded,
    setCustomer,
    setCustomerCanRemove,
    setLastScannedGiftCard,
    setLoading,
    setNextSequenceNumber,
    setPaybackInputDone,
    setScreenSaverNextImage,
    setTransactionUser,
    setUsedProductCustomer,
    showCallAssistance,
    showDialogue,
    showInputDialogue,
    showRightPanelDialogue,
    showSelectDialogue,
    transactionTimeoutReached,
    updateProduct
} from "../actions";
import POSLogService from "../poslog/POSLogService";
import React from "react";
import Traceability from "../../main/Traceability";
import EnterCustomerInfo from "../../main/customerSearch/EnterCustomerInfo";
import {
    getReasonCodesByPurpose,
    getProductFromBasketByID,
    getCommercialGestureReturnReason,
    getDefectiveItemReturnReason,
    getReasonCodeByID,
    getSecurityItems,
    getReturnToStockReason,
    displayNetPrices,
    getProductByCode,
    getTransactionMOPLimit,
    getTransactionProductLineLimit,
    getTillNumber,
    getProductsTotalSum,
    getNumberOfDecathlonGiftCardsInBasket,
    getUnknownItemCode,
    usedProductInBasket,
    paybackEnabled,
    getProductsInBasket,
    cloneProduct,
    getPaymentTypeByReasonCode
} from "../selectors";
import {HWSV, HRV, PAS} from "../../util/voucher";
import {ADD_PAYMENT, CARD_PAYMENT_FAILED, INIT_PAYMENT} from "../payment/actionTypes";
import {
    addPayment,
    logout as paymentLogout,
    processIntegratedPayment,
    setTotalSum,
    setZipCodeSurveyCounter,
    voidAllPayments
} from "../payment/actions";
import Translate from "localization/Translate";
import {getLanguages, getTranslate, localeSelected} from "localization/localizeSlice";
import {timers} from "../../util/timers";
import {
    APP_MODE_DONE,
    APP_MODE_PAYMENT,
    APP_MODE_SCREEN_SAVER,
    APP_STATUS_ASKED_FOR_ASSISTANCE,
    APP_STATUS_CLOSED,
    APP_STATUS_FAILED_PAYMENT,
    APP_STATUS_FREE,
    APP_STATUS_OPERATION_IN_PROGRESS,
    APP_STATUS_SECURITY_ITEM
} from "../appConstants";
import RGFD from "../payment/integrations/giftCard/main";
import GiftCardActivation from "../../main/payment/GiftCardActivation";
import {EI} from "../erplyInstaller/main";
import {ED} from "../erplyInstaller/integrations/externalDevices/main";
import Decimal from "decimal.js";
import * as uuid from "uuid";
import {getProductPackageWeight} from "../../util/helperFunctions";
import Product from "../dto/product";
import CTR from "../../util/receiptTemplate";
import {sendStatusUpdate} from "../../communication/ManagerAppProvider";
import moment from "moment-timezone";
import {
    onPaymentMode as paybackOnPaymentMode,
    onReturnProductAdd as paybackOnReturnProductAdd,
    onTransactionModeChange as paybackOnTransactionModeChange
} from "../../integrations/payback/util";
import Customer from "../dto/customer";
import AED from "../../campaigns/employeeDiscount/employeeDiscount";
import {getToBeCreditedSum, giftCardPaymentIsPresent} from "../payment/selectors";
import {setDefaultAppConf} from "../../login/configurationLoader";
import {LMS} from "../erplyInstaller/integrations/loggerms/main";
import {MCMS} from "../erplyInstaller/integrations/metricsCollector/main";
import yocuda from "../integrations/yocuda/main";
import OP from "../../campaigns/onePromotion/onePromotion";
import paymentDTO from "../payment/dto/payment";

let alertTimeout = false;
let applyPromotionsQueue = [];

const actionsMiddleware = store => next => action => {
    next(action);

    const state = store.getState();
    const translate = getTranslate(state);

    if(!state.rootReducer.cashierMode && state.rootReducer.view === 'app' && state.rootReducer.mode !== APP_MODE_SCREEN_SAVER && action.type !== TRANSACTION_TIMEOUT_REACHED){
        timers.setTimeout('transactionTimeout', () => {
            let currentState = store.getState();
            if(currentState.rootReducer.cashierMode){
                return;
            }
            console.log('timers timeout reached!', currentState, currentState.rootReducer.mode);
            store.dispatch(transactionTimeoutReached());
        }, window.AppConf.transactionTimeout, true);
    }

    if(action.type === TRANSACTION_TIMEOUT_REACHED && state.rootReducer.mode !== APP_MODE_SCREEN_SAVER){
        let cancelTransaction = () => {
            store.dispatch(cancelTransactionAction());
        };

        if(state.payment.payments.find(el => el.status === "started")){
            return;
        }

        if(state.rootReducer.mode === APP_MODE_DONE){
            store.dispatch(initTransaction());
            store.dispatch(changeMode(APP_MODE_SCREEN_SAVER));
            return;
        }
        let timeoutReachedDialogue = state.rootReducer.dialogues.find(el => el.title === 'timeout reached');
        if(typeof timeoutReachedDialogue !== "undefined"){
            cancelTransaction();
            store.dispatch(closeAllDialogues());
        }else{
            if(state.rootReducer.dialogues.length === 0 && state.rootReducer.modals.length === 0 && state.rootReducer.administration === false && state.rootReducer.callAssistance.length === 0){
                store.dispatch(showDialogue('timeout reached', translate('Do you want to continue with the transaction?'), 'yes', () => {}, () => {
                    cancelTransaction();
                }, 'no', false, undefined, undefined, true));
            }
        }
    }

    if(action.type === CANCEL_TRANSACTION){
        store.dispatch(voidAllPayments());
        store.dispatch(initTransaction());
        store.dispatch(changeMode(APP_MODE_SCREEN_SAVER));
        store.dispatch({type: INIT_PAYMENT});
        store.dispatch(closeRightPanelDialogue());
        if(state.rootReducer.transactionMode !== 'SALE'){
            store.dispatch(changeTransactionMode('SALE'));
        }
        if(state.rootReducer.administration !== false){
            store.dispatch(closeAdministration());
        }
        store.dispatch(closeAllModals());
    }

    const initPOSLogService = function () {
        window.posLogService = POSLogService;
        POSLogService.init(state.rootReducer.posID, state.rootReducer.warehouse.warehouseID, state.rootReducer.user.clientCode, state.rootReducer.user.employeeID);
    };

    switch (action.type) {
        case INIT_TRANSACTION: {
            if(!state.rootReducer.cashierMode){
                store.dispatch(localeSelected(getLanguages(state)[0]));
            }
            CTR.generatedReceiptNumber = {
                fromNumber: false,
                generated: false
            };
            if(window.AppConf?.enableOnePromotion ?? false){
                OP.onDocumentInit();
            }
            if(window.AppConf?.screenSaverImageSwap?.transaction){
                store.dispatch(setScreenSaverNextImage());
            }
            return true;
        }
        case SET_POS_DATA: {
            initPOSLogService();
            EI.init();
            const pos = action.payload;
            POSLogService.pointOfSaleID = pos.pointOfSaleID;
            POSLogService.warehouseID = pos.warehouseID;
            LMS.registerDevice(state.rootReducer.user.clientCode, pos.warehouseID, pos.pointOfSaleID, state.rootReducer.user.sessionKey).then((response) => {
                LMS.log("registerDevice", response);
            }, (response) => {
                LMS.log("registerDevice failed", response);
            });
            MCMS.setMonitoringParameters(state.rootReducer.user.clientCode, pos.pointOfSaleID, state.rootReducer.user.sessionKey).then((response) => {
                MCMS.log("setMonitoringParameters", response);
            }, (response) => {
                MCMS.log("setMonitoringParameters failed", response);
            });
            return true;
        }
        case SET_POS_DATA_FAILED: {
            store.dispatch(closeQCO());
            store.dispatch(addErrorAlert('Could not log in to this POS'));
            return false;
        }
        case SET_USER: {
            const user = action.payload;
            POSLogService.clientCode = user.clientCode;
            POSLogService.employeeID = user.employeeID;
            return true;
        }
        case RECEIPT_DELIVERED: {
            if(window.AppConf.enableYocuda){
                yocuda.sendReceipt(state, action.payload.deliveryType).then((response) => {
                    console.log("Yocuda receipt sent", response);
                }, (error) => {
                    console.log("Yocuda receipt not sent", error);
                    store.dispatch(addErrorAlert(error));
                });
                return;
            }
            return true;
        }
        case TRANSACTION_SUCCESS: {
            console.log('TRANSACTION_SUCCESS, send poslog!', action.payload);
            // Don't try to send while offline
            if(action.payload.invoiceID === 0){
                POSLogService.delayed = true;
            }else{
                POSLogService.sendRetailTransaction(action.payload.invoiceID, state.rootReducer.nextSequenceNumber);
                if(typeof state.payment.zipCodeSurveyCounter !== "undefined"){
                    if(state.rootReducer.erplyConf.DKTZipSurveyNonCustomer === '1' ? state.rootReducer.customer.default : true){
                        let nextZipCodeSurveyCount = state.payment.zipCodeSurveyCounter + 1;
                        if(nextZipCodeSurveyCount > state.rootReducer.erplyConf.DKTZipSurveyFrequency){
                            nextZipCodeSurveyCount = 1;
                        }
                        store.dispatch(setZipCodeSurveyCounter(nextZipCodeSurveyCount));
                    }
                }
            }
            return true;
        }
        case GET_NEXT_SEQUENCE_NUMBER: {
            POSLogService.getNextSequenceNumber().then((number) => {
                action.payload.success(number);
            }, action.payload.fail);
            return true;
        }
        case REVERT_NEXT_SEQUENCE_NUMBER: {
            if(state.rootReducer.nextSequenceNumber !== false){
                POSLogService.revertSequenceNumber().then(() => {
                    store.dispatch(setNextSequenceNumber(false));
                }, () => {

                });
            }
            return true;
        }
        case CREATE_RECEIPT: {
            if(window.AppConf.printDecathlonReceipt){
                CTR.createReceiptTime = moment().format("HH:mm:ss");
                CTR.onCreateReceipt(state, store.dispatch).then(action.payload.onSuccess);
            }else{
                action.payload.onSuccess(false);
            }
            return true;
        }
        case CREATE_RECEIPT_NUMBER: {
            if(window.AppConf.printDecathlonReceipt){
                let dateTime = moment();
                dateTime.tz(state.rootReducer.erplyConf.timezone);
                let date = dateTime.format("YYYY-MM-DD");
                let time = dateTime.format("HH:mm:ss");

                store.dispatch({
                    type: ADD_PLUGIN_REQUEST_SALE_DATETIME,
                    payload: {
                        date,
                        time
                    }
                });
                action.payload.onSuccess(CTR.createUniqueReceiptNumber(state.rootReducer.warehouse.code, getTillNumber(state.rootReducer.pos), date, time, state.rootReducer.nextInvoiceNo), dateTime);
            }else{
                action.payload.onSuccess(state.rootReducer.nextInvoiceNo);
            }
            return true;
        }
        case APPLY_RECEIPT_PLUGIN: {
            for (const property in action.properties){
                CTR[property] = action.properties[property];
            }
            return true
        }
        case SET_ERPLY_SERVICE_ENDPOINTS: {
            CTR.init(state);
            store.dispatch({
                type: 'CTR_INIT',
                ctr: CTR
            });
            return true;
        }
        case "localize/localeAdded": {
            CTR.appTranslate = getTranslate(state);
            return true;
        }
        case CASH_IN_OUT_SUCCESS: {
            console.log('CASH_IN_OUT_SUCCESS, send poslog!', action.payload);
            POSLogService.sendCashInOut(action.payload.transactionID, action.payload.addedUnixTime);
            return true;
        }
        case PRODUCT_NOT_FOUND: {
            if(action.payload.searchParam !== getUnknownItemCode()){
                if(action.payload.searchParam.substring(0,1) === "{"){
                    let PASVoucher = PAS.parseCode(action.payload.searchParam);
                    if(PASVoucher !== false){
                        PAS.addProduct(PASVoucher, state, store);
                        return;
                    }
                }

                if(HWSV.isWorkshopVoucher(action.payload.searchParam)) {
                    let voucher = new HWSV.voucher(action.payload.searchParam);
                    HWSV.log('voucherCode: ', action.payload.searchParam, 'voucher: ', voucher);
                    HWSV.addWorkshopProducts(
                        voucher,
                        state.rootReducer.erplyConf,
                        state.rootReducer.warehouse.code,
                        store.dispatch,
                        state.rootReducer.warehouse.warehouseID,
                        state.rootReducer.posID,
                        state.rootReducer.reasonCodes
                    );
                }else if(HRV.isReturnVoucher(action.payload.searchParam)){
                    let voucher = new HRV.voucher(action.payload.searchParam);
                    console.log('HRV voucherCode: ', action.payload.searchParam, 'voucher: ', voucher);
                    let vatPrice = false;
                    let netPrice = false;
                    if(displayNetPrices(state.rootReducer.erplyConf)){
                        netPrice = voucher.amount;
                    }else{
                        vatPrice = voucher.amount;
                    }

                    const productNotFoundError = translate("Could not find product with code") + " " + voucher.articleCode;
                    const addVoucherProduct = (product) => {
                        if(product.data.active === 0 && !product.isUsedProduct()){
                            return addErrorAlert(productNotFoundError);
                        }
                        product.amount = voucher.articleQuantity * -1;

                        if(voucher.paymentTypeCode === HRV.commercialGestureVoucherCode){
                            let returnReason = getCommercialGestureReturnReason(state.rootReducer.reasonCodes);
                            if(returnReason !== undefined){
                                product.returnReasonID = returnReason.reasonID;
                                product.returnReason = returnReason;
                                store.dispatch(addInfoAlert('Commercial Gesture Return'));
                            }else{
                                store.dispatch(addErrorAlert('Please add commercial gesture reason code to Erply!'));
                            }
                        }else if(voucher.paymentTypeCode === HRV.defectiveArticleVoucherCode){
                            let returnReason = getDefectiveItemReturnReason(state.rootReducer.reasonCodes);
                            if(returnReason !== undefined){
                                product.returnReasonID = returnReason.reasonID;
                                product.returnReason = returnReason;
                                store.dispatch(addInfoAlert('Defective Item Return'));
                            }else{
                                store.dispatch(addErrorAlert('Please add defective item return reason code to Erply!'));
                            }
                        }

                        product.data.voucherRFIDTag = {
                            'GTIN': voucher.articleCode,
                            'SGTIN': voucher.code,
                            'SERIAL_NUMBER': voucher.getSerialNumber(),
                            'EPC': 'NULL_VALUE',
                            'ITEM_TRANSACTION_MODE':'RETURN'
                        };
                        if(netPrice !== false) {
                            product.setNetPrice(netPrice);
                        }
                        return addProduct(product);
                    }

                    store.dispatch(setLoading(true));
                    store.dispatch(findProduct(voucher.articleCode, state.rootReducer.warehouse.warehouseID, state.rootReducer.posID, vatPrice, function (product) {
                        store.dispatch(setLoading(false));
                        return addVoucherProduct(product);
                    }, () => {
                        return findProduct(voucher.articleCode, state.rootReducer.warehouse.warehouseID, state.rootReducer.posID, vatPrice, function (product) {
                            store.dispatch(setLoading(false));
                            return addVoucherProduct(product);
                        }, () => {
                            store.dispatch(setLoading(false));
                            return addErrorAlert(productNotFoundError);
                        }, undefined, undefined, undefined, false);
                    }));
                }else if (RGFD.isGiftCard(action.payload.searchParam)){
                    let giftCardsInBasket = getNumberOfDecathlonGiftCardsInBasket(state.rootReducer.productsInBasket, state.rootReducer.productCategories);
                    let allowedGiftCardsInBasket = window.AppConf?.giftCardActivationLimit ?? 10;
                    let hasGiftCardMOP = giftCardPaymentIsPresent(state.payment.payments);
                    if(giftCardsInBasket >= allowedGiftCardsInBasket || hasGiftCardMOP){
                        store.dispatch(addErrorAlert("No more gift cards can be added to the basket"));
                    }else if(state.rootReducer.rightPanelDialogue !== false){
                        store.dispatch(addErrorAlert("Complete the current action before adding a gift card!"));
                    }else{
                        let giftCard = RGFD.decodeBarcode(action.payload.searchParam);
                        if(state.rootReducer.transactionMode === 'SALE'){
                            store.dispatch(showRightPanelDialogue(
                                <GiftCardActivation
                                    giftCard={giftCard}
                                />
                            ));
                        }else{
                            store.dispatch(showDialogue("Can't process gift card in return mode!", '',
                                'Ok', ()=>{}, undefined, undefined, false,
                                undefined,undefined, true));
                        }
                    }
                }else{
                    const addUnknownItemDialogue = () => {
                        store.dispatch(showCallAssistance('unknownItem', '', true, function () {
                            setTimeout(
                                () => {
                                    store.dispatch(cancelAddUnknownItem(action.payload.searchParam));
                                }, 100
                            );
                        }, true, (
                            <div className={'boldUppercaseText'} style={{fontSize:'20px', textAlign: 'center'}}>
                                <Translate id={'Unknown item'}/>
                            </div>
                        ), undefined, undefined, {
                            searchParam: action.payload.searchParam
                        }));
                    }
                    if(state.rootReducer.transactionMode === 'RETURN'){
                        store.dispatch(setLoading(true));
                        store.dispatch(findProducts({active: 0, searchNameIncrementally: action.payload.searchParam, isUsedProduct: 1}, state.rootReducer.warehouse.warehouseID, state.rootReducer.pos.pointOfSaleID,
                            (result) => {
                                store.dispatch(setLoading(false));
                                let productDTO = new Product(result[0].name, result[0].priceListPrice, result[0].priceListPriceWithVat, 1, result[0]);
                                return addProduct(productDTO);
                            }, () => {
                                addUnknownItemDialogue();
                                return setLoading(false);
                            }
                        ));
                    }else{
                        addUnknownItemDialogue();
                    }
                }
            }
            return true;
        }
        case SET_ONE_PROMOTION_PROMO_CODE: {
            if(window.AppConf?.enableOnePromotion ?? false){
                OP.onPromotionCodeSet(state, store);
            }
            return true;
        }
        case UPDATE_PRODUCT: {
            if((window.AppConf?.enableOnePromotion ?? false) &&
                !action.preventApplyPromotions
            ){
                OP.onProductAdded(state, store);
            }
            break;
        }
        case ADD_PRODUCT: {
            if(window.AppConf?.enableOnePromotion ?? false){
                OP.onProductAdded(state, store);
            }

            if(Object.keys(state.rootReducer.discount).length !== 0){
                store.dispatch(removeDocumentDiscount());
            }
            let shouldCloseAdminView = true;
            let closeAdminView = function(){
                let waitingForLogin = () => {
                    if(state.rootReducer.administration === false){
                        return false;
                    }
                    return state.rootReducer.administration.user === false
                };
                if( shouldCloseAdminView &&
                    state.rootReducer.administration !== false &&
                    !waitingForLogin() &&
                    !(state.rootReducer.administration.view === "unknownItem" && state.rootReducer.unknownRFIDProducts.length > 0) &&
                    !state.rootReducer.administration.view === "addItem"
                ){
                    store.dispatch(closeAdministration());
                }
            };
            let product = cloneProduct(state.rootReducer.productsInBasket[state.rootReducer.productsInBasket.length - 1]);
            console.log('MIDDLEWARE ACTION: ADD_PRODUCT', product, product.data.RFIDTag);

            if(state.rootReducer.invoice !== false){
                store.dispatch(addErrorAlert('Can\'t add products to this document!'));
                store.dispatch(removeProduct(product));
                return;
            }
            if(action.pendingDocument !== false){
                return;
            }
            if(state.rootReducer.returnBaseDocument !== false){
                store.dispatch(addErrorAlert('Can\'t add products to this document!'));
                store.dispatch(removeProduct(product));
                return;
            }

            if(product.isUsedProduct()){
                let usedProductInBasket = state.rootReducer.productsInBasket.filter(el => el.data.productID === product.data.productID);
                if(usedProductInBasket.length > 1){
                    store.dispatch(addErrorAlert('The product is already in the basket!'));
                    store.dispatch(removeProduct(product));
                    return;
                }
            }

            if(state.rootReducer.transactionMode === 'RETURN' && typeof state.rootReducer.payback.referenceReceipt !== "undefined"){
                let paybackRemoveProduct = paybackOnReturnProductAdd(product, store.dispatch, state);
                if(paybackRemoveProduct){
                    store.dispatch(removeProduct(product));
                    return;
                }
            }

            if(ED.available){
                scalePrompt(product, store);
            }

            let isReturnedProduct = product.returnReason !== undefined && product.returnReason.purpose === 'RETURN';
            if(state.rootReducer.transactionMode === 'RETURN' && !isReturnedProduct){
                if(product.isReturnable()){
                    if(process.env.REACT_APP_ERPLY_MODE !== "1" && !window.AppConf.customReturnReasonCodes){
                        let returnReason = getReturnToStockReason(state.rootReducer.reasonCodes);
                        if(returnReason !== undefined){
                            store.dispatch(addProductReturnReasonID(product.lineNumber, returnReason));
                        }else{
                            store.dispatch(addErrorAlert('Please add return to stock reason code to Erply!'));
                        }
                    }else{
                        let reasonCodeOptions = getReasonCodesByPurpose(state.rootReducer.reasonCodes, 'RETURN').reduce((acc, reasonCode) => {
                            acc.push({
                                name: reasonCode.name,
                                value: reasonCode.reasonID
                            });
                            return acc;
                        }, []);

                        store.dispatch(showSelectDialogue('Return reason', reasonCodeOptions, (reasonID, reason) => {
                            let reasonCode = getReasonCodeByID(state.rootReducer.reasonCodes, reasonID);
                            if(reasonCode === undefined){
                                reasonCode = {reasonID, name: reason};
                            }
                            store.dispatch(addProductReturnReasonID(product.lineNumber, reasonCode));
                        }, e => {}, false, undefined, undefined, product.data.code + ' ' + product.data.name));
                    }
                }else{
                    store.dispatch(showDialogue('Item is not returnable!', '', 'Ok', function () {
                        store.dispatch(removeProduct(product));
                    }, undefined, undefined, false, undefined, undefined, true));
                    closeAdminView();
                    return true;
                }
            }

            if(product.isBlocked()){
                if(product.amount >= 0){
                    store.dispatch(showDialogue('Item is not authorized for sale!', product.data.code + ' ' + product.name, 'Ok', function () {
                        store.dispatch(removeProduct(product));
                    }, undefined, undefined, false));
                    closeAdminView();
                    return true;
                }else{
                    if(product.returnReasonID !== getDefectiveItemReturnReason(state.rootReducer.reasonCodes).reasonID){
                        store.dispatch(showDialogue('Item is not authorized for return!', product.data.code + ' ' + product.name, 'Ok', function () {
                            store.dispatch(removeProduct(product));
                        }, undefined, undefined, false));
                        closeAdminView();
                        return true;
                    }
                }
            }

            if(state.rootReducer.transactionMode === 'SALE' && product.isSecurityItem()){
                store.dispatch(securityItemAdded());
                if(state.rootReducer.customerAgeVerified === -1){
                    store.dispatch(showCallAssistance('securityItem', 'Security item', true, () => {
                        store.dispatch(removeProduct(product));
                    }, true));
                }else if(state.rootReducer.customerAgeVerified === 0){
                    store.dispatch(showDialogue('Item is not authorized for sale!', '', 'Ok', function () {
                        let securityItems = getSecurityItems(getProductsInBasket(state));
                        for(let product of securityItems){
                            store.dispatch(removeProduct(product));
                        }
                    }, undefined, undefined, false));
                    return true;
                }
            }

            if(state.rootReducer.transactionMode === 'SALE' && product.hasSecurityUKPlug()){
                const showSecurityUKPlugErrorMessage = () => {
                    store.dispatch(addErrorAlert("User has no right to perform this action!"));
                };
                const showSecurityUKPlugMessage = () => {
                    store.dispatch(showDialogue( 'Attention, UK plug detected!', "", "Proceed", () => {
                        store.dispatch(openVerifyPinModal(store.dispatch, state.rootReducer.user.clientCode, (user) => {
                            if (!window.AppConf.selfValidateSupervisor && user.userID === state.rootReducer.user.userID) {
                                showSecurityUKPlugMessage();
                                showSecurityUKPlugErrorMessage();
                            } else {
                                store.dispatch(getHasSupervisorUserRights(user.userID, state.rootReducer.posID, (hasSupervisorRights) => {
                                    if(!hasSupervisorRights){
                                        showSecurityUKPlugErrorMessage();
                                    }
                                    return {
                                        type: 'USER_RIGHTS_SUCCESS'
                                    }
                                }, () => {
                                    return addErrorAlert('Could not get user right records, please try again!');
                                }));
                            }
                        }, () => {}, () => {
                            showSecurityUKPlugMessage();
                        }));
                    }, () => {
                        store.dispatch(removeProduct(product));
                    }, "Cancel", false, undefined, undefined, true));
                }
                showSecurityUKPlugMessage();
            }

            if(product.data.code === getUnknownItemCode() || product.data.code2 === getUnknownItemCode()){
                shouldCloseAdminView = false;
            }

            if(state.rootReducer.transactionMode === 'SALE' && product.isRiskyItem()){
                if(state.rootReducer.customer === false){
                    let dialogue = (
                        <Traceability
                            enterDetails={() => {
                                store.dispatch(showRightPanelDialogue((
                                    <EnterCustomerInfo
                                        onDone={(customerInfo) => {
                                            if(typeof customerInfo.email !== "undefined"){
                                                store.dispatch(setLoading(true));
                                                store.dispatch(findCustomerByEmail(customerInfo.email, () => {
                                                    store.dispatch(setLoading(false));
                                                }, () => {
                                                    store.dispatch(setLoading(false));
                                                }, false));
                                            }else if(typeof customerInfo.phone !== "undefined"){
                                                store.dispatch(setLoading(true));
                                                store.dispatch(findCustomerByPhone(customerInfo.phone, state.rootReducer.erplyConf.DKTCountryCode, () => {
                                                    store.dispatch(setLoading(false));
                                                }, () => {
                                                    store.dispatch(setLoading(false));
                                                }, false));
                                            }
                                            customerInfo.canRemove = false;
                                            store.dispatch(setCustomer(customerInfo));
                                            store.dispatch(closeRightPanelDialogue());
                                        }}
                                        onBack={e => {
                                            store.dispatch(showRightPanelDialogue(dialogue));
                                        }}
                                    />
                                )));
                            }}
                            askForHelp={(supervisor=false) => {
                                let showRiskyItemMessage = (user = false) => {
                                    store.dispatch(showDialogue(
                                        "riskyItemMessageTitle",
                                        "riskyItemMessage",
                                        "accept",
                                        e => {
                                            store.dispatch(closeRightPanelDialogue());
                                            if(user !== false){
                                                store.dispatch(setTransactionUser(user));
                                            }
                                        },
                                        e => {},
                                        "back",
                                        false
                                    ));
                                }
                                if(!store.getState().rootReducer.connectionHealth){
                                    showRiskyItemMessage();
                                    return;
                                }
                                //store.dispatch(closeRightPanelDialogue());
                                //store.dispatch(openAdministrationView('riskyItem', false));
                                store.dispatch(openLogonModal('', (data) => {
                                    let user = data[0];
                                    store.dispatch(getUserRights(user.userID, state.rootReducer.pos.pointOfSaleID, (records) => {
                                        if( records[0].rightMakeDiscountInPOS === 1 &&
                                            (window.AppConf.selfValidateSupervisor || user.userID !== state.rootReducer.user.userID)
                                        ){
                                            showRiskyItemMessage(user);
                                        }else{
                                            store.dispatch(addErrorAlert('User has no right to perform this action!'));
                                        }
                                        return {
                                            type: 'USER_RIGHTS_SUCCESS'
                                        }
                                    }, () => {
                                        return addErrorAlert('Could not get user right records, please try again!');
                                    }));
                                }, true, supervisor));
                            }}
                        />
                    );
                    store.dispatch(showRightPanelDialogue(dialogue));
                }else{
                    store.dispatch(setCustomerCanRemove(false));
                }
            }

            if(product.hasContainer){
                store.dispatch(
                    findProducts(
                        {
                            productID: product.data.containerID
                        },
                        state.rootReducer.warehouse.warehouseID,
                        state.rootReducer.pos.pointOfSaleID,
                        result => {
                            let productDTO = new Product(result[0].name, result[0].priceListPrice, result[0].priceListPriceWithVat, 1, result[0]);
                            productDTO.containerForProductLine = product.lineNumber;
                            productDTO._isContainer = true;
                            return addProduct(productDTO);
                        }, result => {
                            return addErrorAlert('Could not add container product!');
                        }
                    )
                );
            }

            if(state.rootReducer.productsInBasket.length > getTransactionProductLineLimit()){
                store.dispatch(showDialogue('Can\'t add more products to the basket!', '', 'Ok', function () {
                    store.dispatch(removeProduct(product));
                }, undefined, undefined, false, undefined, undefined, true));
            }

            if( window.AppConf.usedProductConfirm === true &&
                process.env.REACT_APP_ERPLY_MODE !== "1" &&
                state.rootReducer.transactionMode === 'SALE' &&
                (product.data.seriesName === 'DKT:SecondHand' || product.data.seriesName === 'DKT:BuyBack')
            ){
                // TODO: wait for Decathlon to decide on the size of this message (QTC-8467)
                store.dispatch(showDialogue('You are adding a used product into your basket',
                    `<p style="text-align: left">${product.data.name}</p><p>${translate("I declare that I have read the description of the used goods and I accept its condition")}</p>`,
                    'I accept', function(){}, function () {
                        store.dispatch(removeProduct(product));
                    }, "decline", false, undefined, undefined, false));
            }

            if( typeof window.AppConf.importedItemInfo !== "undefined" &&
                window.AppConf.importedItemInfo.receiptText !== "" &&
                process.env.REACT_APP_ERPLY_MODE !== "1" &&
                (product.data.seriesName === 'DKT:PassionBrand' || product.data.seriesName === 'DKT:WorkshopVoucher')
            ){
                store.dispatch(addProductCodeInfo(product.lineNumber, window.AppConf.importedItemInfo.productText));
                if(typeof state.rootReducer.receiptExtraLines.find(line => {
                    if(typeof line !== "string"){
                        return false;
                    }
                    return line.indexOf(window.AppConf.importedItemInfo.receiptText) !== -1;
                }) === "undefined"){
                    store.dispatch(addReceiptExtraLine(window.AppConf.importedItemInfo.receiptText));
                }
            }

            if((typeof product.data.RFIDTag === "undefined" || product.data.RFIDTag.SERIAL_NUMBER === "NULL_VALUE") &&
                typeof product.data.RFIDSerialNumber === "undefined" &&
                typeof product.data.voucherRFIDTag === "undefined" &&
                Math.abs(product.amount) === 1 &&
                product.needsSerialNumber()
            ){
                let insertSerialNumberID = "insertSerialNumber_" + product.lineNumber;
                store.dispatch(showInputDialogue('Insert serial number!', '', '', function (serialNumber) {
                    store.dispatch(addProductRFIDSerialNumber(product.lineNumber, serialNumber));
                }, undefined, false, true, undefined, insertSerialNumberID, undefined, undefined));
            }

            closeAdminView();
            break;
        }
        case REMOVE_PRODUCT: {
            if(window.AppConf?.enableOnePromotion ?? false){
                OP.onProductAdded(state, store);
            }
            let product = action.payload;
            if(product.isRiskyItem()){
                if(store.getState().rootReducer.rightPanelDialogue !== false){
                    let currentRiskyProducts = getProductFromBasketByID(store.getState().rootReducer.productsInBasket, product.data.productID);
                    if(currentRiskyProducts.length === 0){
                        store.dispatch(closeRightPanelDialogue());
                    }
                }
            }
            /*if(product._isContainer){
                if(typeof product.containerForProduct !== "undefined"){
                    store.dispatch(removeProduct(product.containerForProduct));
                }
            }else if(product.hasContainer){
                let containerProduct = state.rootReducer.productsInBasket.find(el => el.containerForProduct === product);
                if(typeof containerProduct !== "undefined"){
                    store.dispatch(removeProduct(containerProduct));
                }
            }*/
            break;
        }
        case API_ERROR: {
            if(action.displayError !== false){
                store.dispatch(addErrorAlert(action.payload));
            }
            return true;
        }
        case ADD_ALERT: {
            if(action.payload.autoClose){
                if(alertTimeout !== false){
                    window.clearTimeout(alertTimeout);
                    alertTimeout = false;
                }
                alertTimeout = window.setTimeout(function () {
                    store.dispatch(removeAlert());
                }, 3000);
            }
            return true;
        }
        case SET_POS_DAY_OPENED: {
            sendStatusUpdate(APP_STATUS_FREE);
            return true;
        }
        case CHANGE_TRANSACTION_MODE: {
            if(paybackEnabled()){
                paybackOnTransactionModeChange(store.dispatch, state);
            }
            return true;
        }
        case CHANGE_MODE: {
            if(state.rootReducer.mode === 'screenSaver'){
                sendStatusUpdate(APP_STATUS_FREE);
            }else if(state.rootReducer.mode === 'scan'){
                sendStatusUpdate(APP_STATUS_OPERATION_IN_PROGRESS);
                if(paybackEnabled()){
                    if(state.rootReducer.transactionMode === "SALE"){
                        store.dispatch(setPaybackInputDone(false));
                    }
                }
            }else if(action.payload.mode === 'payment'){
                addBagPrompt(store, state);
                usedProductPrompt(store, state);
                if(paybackEnabled()){
                    CTR.generatedReceiptNumber = {
                        fromNumber: false,
                        generated: false
                    };
                    paybackOnPaymentMode(store.dispatch, state);

                    if(state.rootReducer.payback.customer !== false){
                        let customer = state.rootReducer.customer === false ? new Customer() : Object.assign({}, state.rootReducer.customer);
                        customer.localRequirements = {
                            name: "PaybackAccount",
                            value: state.rootReducer.payback.customer
                        }
                        store.dispatch(setCustomer(customer));
                    }
                }
            }

            return true;
        }
        case OPEN_MODAL: {
            if(action.payload.type === 'logon'){
                sendStatusUpdate(APP_STATUS_ASKED_FOR_ASSISTANCE);
            }
            return true;
        }
        case CLOSE_MODAL: {
            if(state.rootReducer.mode === 'scan'){
                sendStatusUpdate(APP_STATUS_OPERATION_IN_PROGRESS);
            }
            return true;
        }
        case SECURITY_ITEM_ADDED: {
            sendStatusUpdate(APP_STATUS_SECURITY_ITEM);
            return true;
        }
        case SET_CUSTOMER_AGE_VERIFIED: {
            sendStatusUpdate(APP_STATUS_OPERATION_IN_PROGRESS);
            return true;
        }
        case CARD_PAYMENT_FAILED: {
            sendStatusUpdate(APP_STATUS_FAILED_PAYMENT);
            return true;
        }
        /*case CHANGE_MODE: {
            if(state.rootReducer.mode === 'payment' && state.rootReducer.transactionMode === 'RETURN'){
                store.dispatch(changeTransactionMode('SALE'));
            }
            return true;
        }*/
        case POS_DAY_OPEN_SUCCESS: {
            let employeeID = false;
            if(state.rootReducer.transactionUser !== false){
                employeeID = state.rootReducer.transactionUser.employeeID;
            }
            POSLogService.POSEvents.afterOpenDay(action.dayID, action.openedSum, action.openedUnixTime, employeeID);
            return true;
        }
        case POS_DAY_CLOSE_SUCCESS: {
            let employeeID = false;
            if(state.rootReducer.transactionUser !== false){
                employeeID = state.rootReducer.transactionUser.employeeID;
            }
            POSLogService.POSEvents.afterCloseDay(state.rootReducer.posDayID, employeeID);
            store.dispatch(paymentLogout());
            return true;
        }
        case ADD_PAYMENT: {
            if(state.payment.payments.length + 1 === getTransactionMOPLimit()){
                store.dispatch(showDialogue('You can only add one more payment method!', '', 'Ok', function () {

                }, undefined, undefined, false, undefined, undefined, true));
            }
            return true;
        }
        case SCANNER_DETECTED: {
            if(state.rootReducer.mode === 'addGiftCard'){
                store.dispatch(setLastScannedGiftCard(action.payload));
            }
            return true;
        }
        case CLOSE_QCO: {
            timers.removeTimeout('userSessionUpdate');
            for(let interval of state.rootReducer.intervals){
                clearInterval(interval);
            }
            if(!window.AppConf?.decathlonSSO){
                setTimeout(() => {
                    store.dispatch({
                        type: INIT_POS
                    });
                },100);
            }
            sendStatusUpdate(APP_STATUS_CLOSED);
            setDefaultAppConf();
            return true;
        }
        default: {}
    }

    if (
        (
            action.type === APPLY_PROMOTIONS ||
            action.type === ADD_PRODUCT ||
            action.type === REMOVE_PRODUCT ||
            (action.type === UPDATE_PRODUCT && !action.preventApplyPromotions) ||
            action.type === ADD_DOCUMENT_DISCOUNT ||
            action.type === SET_CUSTOMER ||
            action.type === ADD_MANUAL_PROMOTION_ID ||
            action.type === REMOVE_MANUAL_PROMOTION_ID ||
            action.type === SET_PENDING_DOCUMENT ||
            (action.type === CHANGE_MODE && action.payload.mode === 'payment')
        )
        && state.rootReducer.productsInBasket.length > 0
    ) {
        applyPromotionsQueue.push(1);
        setTimeout(() => {
            applyPromotionsQueue.pop();
            if(applyPromotionsQueue.length === 0){
                store.dispatch(setLoading(true));
                handleBeforeApplyPromotionsEvents().finally(() => {
                    let currentState = store.getState();
                    store.dispatch(applyPromotions(getProductsInBasket(currentState), currentState.rootReducer.pos, currentState.rootReducer.customer, currentState.rootReducer.erplyConf.default_client_idDat, () => {
                        store.dispatch(setLoading(false));
                    }, currentState.rootReducer.manualPromotionIDs));
                });
            }
        }, 200);
    }

    const handleBeforeApplyPromotionsEvents = (events = false) => {
        return new Promise((resolve, reject) => {
            let currentState = store.getState();
            if(events === false){
                events = Object.values(state.rootReducer.beforeApplyPromotionsEvents);
            }
            if(events.length === 0){
                resolve();
                return;
            }
            let event = events.pop();
            event(currentState).finally(() => {
                handleBeforeApplyPromotionsEvents(events).then(resolve, reject);
            })
        });
    }

    if(action.type === UPDATE_PRODUCTS){
        if(state.rootReducer.mode === 'payment'){
            const totalSum = getProductsTotalSum(state.rootReducer.productsInBasket);
            store.dispatch(setTotalSum(totalSum));
        }
    }

    if(action.type === SET_CUSTOMER){
        console.log("customer set action", action.payload);
        if(process.env.REACT_APP_ERPLY_MODE !== "1"){
            AED.onEmployeeSet(action.payload, state, store);
        }
    }

    if(action.type === REMOVE_CUSTOMER){
        if(window.AppConf.erplyMode !== true){
            AED.onEmployeeRemove(state, store);
        }
    }

    if(action.type === SET_CONNECTION_HEALTH && state.rootReducer.view === 'app'){
        if(action.payload === false){
            const showNoConnectionDialogue = () => {
                store.dispatch({
                    type: SHOW_DIALOGUE,
                    payload: {
                        id: "noConnection",
                        title: 'Alert!!',
                        message: `
                      <p style="font-size: 26px; margin-top: 140px;">${translate('Connection failed. Please launch degraded mode to continue using the POS.')}</p>
                      <p style="font-size: 20px;">${translate('Note: Some functionalities might be limited or not available in this mode.')}</p>
                    `,
                        confirmText: 'Continue',
                        onConfirm: () => {
                            store.dispatch(openLogonModal('Logon to confirm offline mode!', (data) => {
                                if(state.rootReducer.operationMode === 'lite'){
                                    store.dispatch(changeOperationMode('cashier'));
                                }
                            }, true, undefined, () => {
                                if(!store.getState().rootReducer.connectionHealth){
                                    showNoConnectionDialogue();
                                }
                            }));
                        },
                        onCancel: () => {
                            store.dispatch(closeQCO());
                        },
                        cancelText: 'Close till',
                        canClose: false,
                        messageClass:''
                    }
                });
            }
            showNoConnectionDialogue();
        }else{
            if(typeof state.rootReducer.dialogues.find(el => el.id === 'noConnection') !== "undefined"){
                store.dispatch(closeDialogue('noConnection'));
            }else{
                let openedOfflineLogonModal = state.rootReducer.modals.find(el => el.type === 'logon');
                if(typeof openedOfflineLogonModal !== "undefined"){
                    store.dispatch(closeModal(openedOfflineLogonModal.id));
                }
                store.dispatch({
                    type: SHOW_DIALOGUE,
                    payload: {
                        id: uuid.v4(),
                        title: 'Alert!!',
                        message: `<p style="font-size: 26px; margin-top: 140px;">${translate('POS Connection restored.')}</p>`,
                        confirmText: 'Continue',
                        onConfirm: () => {
                            if(state.rootReducer.offlineLogin){
                                store.dispatch(initPOS());
                            }
                        },
                        onCancel: () => {
                            store.dispatch(closeQCO());
                        },
                        cancelText: 'Close till',
                        canClose: false,
                        messageClass:''
                    }
                });
            }
        }
    }

    let readyForPayment = (state) => {
        return state.rootReducer.mode === APP_MODE_PAYMENT &&
            state.rootReducer.dialogues.length === 0 &&
            state.rootReducer.modals.length === 0 &&
            state.rootReducer.rightPanelDialogue === false &&
            state.rootReducer.paymentMode === "options" &&
            state.rootReducer.loading === false &&
            state.rootReducer.callAssistance.length === 0 &&
            state.rootReducer.administration === false
    }
    if( readyForPayment(state)){
        setTimeout(() => {
            let updatedState = store.getState();
            if(readyForPayment(updatedState)){
                console.log("Ready for payment");
                if(paybackEnabled()){
                    let paymentType = window?.AppConf?.paymentTypes?.["integration"]?.find(el => el.type === "paybackPay");
                    if( typeof paymentType !== "undefined" &&
                        typeof updatedState.rootReducer.payback.payQRCode !== "undefined" &&
                        typeof updatedState.payment.payments.find(el => el.type === "paybackPay") === "undefined"
                    ){
                        startIntegratedPayment(store, updatedState, paymentType, getToBeCreditedSum(updatedState));
                    }
                }
            }
        }, 1000);
    }
};


const addBagPrompt = (store, state) => {
    if(window.AppConf.addBagPrompt && window.AppConf.bagItemCode){
        const bagInBasket = getProductByCode(state.rootReducer.productsInBasket, window.AppConf.bagItemCode);
        console.log({bagInBasket});
        if(typeof bagInBasket !== "undefined") return true;

        store.dispatch(showDialogue('Do you need a bag?','', 'Yes', () => {
            const askBagAmount = () => {
                store.dispatch(showInputDialogue('How many bags do you need?', '', '', (bagAmount) => {
                    let parsedAmount = parseInt(bagAmount);
                    if(isNaN(parsedAmount)){
                        store.dispatch(addErrorAlert('Please enter number'));
                        askBagAmount();
                        return;
                    }
                    if(parsedAmount < 1){
                        return;
                    }
                    store.dispatch(setLoading(true));
                    store.dispatch(findProduct(window.AppConf.bagItemCode, state.rootReducer.pos.warehouseID, state.rootReducer.pos.pointOfSaleID, false, (product) => {
                        product.amount = parseInt(bagAmount);
                        return addProduct(product);
                    }, undefined, undefined, () => {
                        store.dispatch(setLoading(false));
                    }));
                }, () => {}, true, true));
            }
            setTimeout(askBagAmount,1);
        }, () => {

        },'No', false, undefined, undefined, true));
    }
};

const usedProductPrompt = (store, state) => {
    if(state.rootReducer.erplyConf.country !== "BE" && usedProductInBasket(getProductsInBasket(state))){
        store.dispatch({
            type: 'SHOW_DIALOGUE',
            payload: {
                title: 'Enter customer info',
                inputFields: [
                    {id: "customerName", name: "Name"},
                    {id: "customerAddress", name: "Address"},
                    {id: "customerCity", name: "City"}
                ],
                onDone: (inputValues, close, setValidationErrors) => {
                    console.log('Used product customer info', inputValues);
                    store.dispatch(setUsedProductCustomer(inputValues));
                    close();
                },
                onClose: () => {
                },
                onChange: () => {
                },
                canClose: true,
                closeOnDone: false,
                multipleInputDialogue: true,
                keyboardLayout: 'letters'
            }
        });
    }
}

const scalePrompt = (product, store) => {
    console.log('ED: ADD_PRODUCT', {product});
    if(product.data.unitName === 'kg' && product.barcodeWeight !== true && product.barcodePrice !== true){
        let putProductOnScaleDialogueID = uuid.v4();
        let weighingDialogueID = uuid.v4();
        let cancelLoop = false;

        let scaleLoop = (lastWeight=false) => {
            if(cancelLoop){
                return true;
            }
            ED.scaleWeight((response) => {
                ED.log('ScaleWeight response', response);
                if(response.statusMessage === 'success'){
                    let weight = parseFloat(response.data.weight.trim());
                    if(weight !== false && weight !== 0 && weight === lastWeight){
                        let packageWeight = getProductPackageWeight(product.data);
                        if(typeof packageWeight !== "undefined"){
                            weight = (new Decimal(weight)).minus(new Decimal(packageWeight)).toDecimalPlaces(4).toNumber();
                        }
                        if(weight <= 0){
                            store.dispatch(showDialogue(
                                'Please use a proper container!',
                                '',
                                'Ok',
                                () => {
                                    scaleLoop();
                                },
                                () => {
                                    cancelLoop = true;
                                    store.dispatch(removeProduct(product));
                                },
                                undefined,
                                true,
                                weighingDialogueID,
                                undefined,
                                true
                            ));
                        }else{
                            let currentProduct = cloneProduct(store.getState().rootReducer.productsInBasket.find(basketProduct => basketProduct.lineNumber === product.lineNumber));
                            if(typeof currentProduct !== "undefined"){
                                currentProduct.setAmount(weight);
                                store.dispatch(updateProduct(currentProduct));
                            }
                            store.dispatch(closeDialogue(weighingDialogueID));
                        }
                    }else{
                        if(weight !== 0){
                            store.dispatch(closeDialogue(putProductOnScaleDialogueID));
                            store.dispatch(showDialogue(
                                'Weighing',
                                '',
                                undefined,
                                undefined,
                                () => {
                                    cancelLoop = true;
                                    store.dispatch(removeProduct(product));
                                },
                                undefined,
                                true,
                                weighingDialogueID,
                                undefined,
                                true
                            ));
                        }
                        setTimeout(() => {
                            scaleLoop(weight);
                        }, 300);
                    }
                }else{
                    store.dispatch(addErrorAlert(response.statusMessage));
                    store.dispatch(removeProduct(product));
                    store.dispatch(closeDialogue(weighingDialogueID));
                }
            });
        };


        store.dispatch(showDialogue(
            'Put product on the scale',
            '',
            undefined,
            undefined,
            () => {
                cancelLoop = true;
                store.dispatch(removeProduct(product));
            },
            undefined,
            true,
            putProductOnScaleDialogueID,
            undefined,
            true
        ));
        scaleLoop();
    }
}

const startIntegratedPayment = (store, state, paymentType, paymentSum) => {
    const paymentsLength = state.payment.payments.length;
    const nextPaymentIndex = paymentsLength === 0 ? 0 : state.payment.payments[paymentsLength - 1].index + 1;
    let erplyPaymentType = getPaymentTypeByReasonCode(state.payment.paymentTypes, paymentType.reasonCode);
    if(typeof erplyPaymentType === "undefined"){
        store.dispatch(addErrorAlert('Payment type with reason code ' + paymentType.reasonCode + ' is missing. Please add to Erply BO!'));
        return;
    }
    const payment = new paymentDTO(nextPaymentIndex, paymentSum, "started", paymentType.type);
    payment.typeID = erplyPaymentType.id;
    payment.isIntegrated = true;
    store.dispatch(addPayment(payment));
    store.dispatch(processIntegratedPayment(payment, paymentType));
}

export default actionsMiddleware;