import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import CheckCircle from "../../../buttons/CheckCircle";
import {addManualPromotionID, removeManualPromotionID} from "../../../redux/actions";

export default function(props) {
    let manualPromotionIDs = useSelector(state => state.rootReducer.manualPromotionIDs);
    let dispatch = useDispatch();

    let isApplied = manualPromotionIDs.indexOf(props.promotion.campaignID) !== -1;
    let applied;
    if(isApplied){
        applied = <CheckCircle/>;
    }
    return (
        <tr style={{
            cursor: "pointer"
        }}
            className={`productLine ${props.className || ''}`} onClick={e => {
            if(isApplied){
                dispatch(removeManualPromotionID(props.promotion.campaignID));
            }else{
                dispatch(addManualPromotionID(props.promotion.campaignID));
            }
        }}>
            <td>
                <span>{props.promotion.name}</span>
            </td>
            <td>
                { applied }
            </td>
        </tr>
    );
}