import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkHealthStatus} from "../redux/actions";

export const useTrackConnectionStatus = () => {
    const dispatch = useDispatch();
    const connectionHealth = useSelector((state) => state.rootReducer.connectionHealth);
    useEffect(() => {
        dispatch(checkHealthStatus(connectionHealth ? 0 : 5));
        let healthInterval = 28913;
        const checkHealth = () => {
            dispatch(checkHealthStatus(connectionHealth ? 0 : 5));
            return true;
        };
        const healthStatus = window.setInterval(checkHealth, healthInterval);
        return () => {
            window.clearInterval(healthStatus);
        };
    }, [checkHealthStatus, dispatch]);
};
