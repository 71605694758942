import React, {useState} from "react";
import Translate from "localization/Translate";
import {validatePhone} from "../../redux/dto/customer";
import Customer from "../../redux/dto/customer";
import Button from "../../buttons/Button";
import InputWithKeyboard from "../../formElements/InputWithKeyboard";
import {useDispatch, useSelector} from "react-redux";
import {closeRightPanelDialogue} from "../../redux/actions";
import {getCountryDialCode} from "../../redux/selectors";
import CountryDialCode from "../../uiComponents/CountryDialCode";

export default function (props) {
    let customer = useSelector(state => state.rootReducer.customer);
    if(customer !== false){
        const dispatch = useDispatch();
        dispatch(closeRightPanelDialogue());
    }

    const [validationError, setValidationError] = useState(false);
    let validationErrorDisplay;
    if(validationError !== false){
        validationErrorDisplay = (
            <span style={{
                fontSize: "16px"
            }} className={'errorMessage'}>{validationError}</span>
        );
    }

    let inputTitle = "Enter phone number";
    let keyboardLayout = "numbers";
    let validationErrorTitle = "Input must be a phone number!";
    let backButton;
    if(props.onBack !== undefined){
        backButton = (
            <div onClick={props.onBack} style={{marginTop: '10px', marginBottom: '10px', width: 'max-content'}}>
                <Button type="button" className={"largeButton grayButton"} name={<Translate id="back"/>}
                    arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
            </div>
        );
    }

    const [selectedDialCode, setSelectedDialCode] = useState(props.countryCode);

    return (
        <div style={{
            padding: "10px",
            paddingTop: "180px"
        }}>
            <div className={"fullWidth centerText"}>
                <span className={"boldUppercaseText centerText"}>
                    { props.title }
                </span>
            </div>
            <br/>
            <InputWithKeyboard
                initialInputValue={""}
                inputLabel={inputTitle}
                inputClass={"shadowBoxInput"}
                inputStyle={{
                    width: "calc(100% - 150px)"
                }}
                layout={keyboardLayout}
                beforeInput={
                    <>
                        <div
                            style={{
                                width: "150px",
                                float: "left",
                                marginTop: "6px"
                            }}
                        >
                            <CountryDialCode
                                defaultCountryCode={props.countryCode}
                                setSelectedDialCode={setSelectedDialCode}
                                selectedDialCode={selectedDialCode}
                            />
                        </div>
                    </>
                }
                onInputChange={(value) => {}}
                onDone={(value) => {
                    let customer = new Customer();
                    let phoneNumber = getCountryDialCode(selectedDialCode) + value;
                    if(validatePhone(phoneNumber)){
                        customer.phone = phoneNumber;
                        props.onDone(customer);
                    } else {
                        setValidationError(<Translate id={validationErrorTitle}/>)
                    }
                }}
            />

            { validationErrorDisplay }
            { backButton }
        </div>
    );
}
