import React, {Component} from 'react';
import "./main.css"
import Translate from "localization/Translate";
import deleteIconErply from "./images/Erply/trash.png";
import Trash from "../icons/Trash";
import Image from "../theme/Image";

class MembershipLine extends Component{
    render() {
        if(process.env.REACT_APP_ERPLY_MODE === "1"){
            return (
                <tr className={"productLine"}>
                    <td className={"productLineNameColumn"}>
                        { this.props.customer.firstName } { this.props.customer.lastName }
                    </td>
                    <td className={"productLinePriceColumn"}/>
                    <td>
                        <Image image={"membership"} alt={'member'} style={{height:"60px"}}/>
                    </td>
                    {
                        this.props.customer.canRemove ?
                            <td onClick={e => this.props.remove()} style={{"width": "20px"}}>
                                <img src={deleteIconErply} alt={'trash'} style={{
                                    maxHeight: '22px'
                                }}/>
                            </td> : <td/>
                    }
                </tr>
            );
        }else{
            let displayField;
            let email = this.props.customer.email;
            let phone = this.props.customer.phone;
            if(this.props.customer.card_number !== ''){
                displayField = <span className={"unitPrice"}><Translate id={"Number"}/> : {this.props.customer.card_number}</span>;
            }else if( email !== undefined && email !== ""){
                displayField = <span className={"unitPrice"}><Translate id={"Email"}/> : {this.props.customer.email}</span>
            }else if( phone !== undefined && phone !== ""){
                displayField = <span className={"unitPrice"}><Translate id={"Phone"}/> : {this.props.customer.phone}</span>
            }else if(this.props.customer.fullName !== undefined){
                displayField = <span className={"unitPrice"}>{this.props.customer.fullName}</span>
            }
            return (
                <tr className={"productLine"}>
                    <td className={"productLineNameColumn"}>
                        <span style={{textTransform: "capitalize"}}><Translate id={"membership"}/></span><br/>
                        { displayField }
                    </td>
                    <td className={"productLinePriceColumn"}/>
                    <td>
                        <Image image={"membership"} alt={'giftCardImg'} style={{height:"100px"}}/>
                    </td>
                    {
                        this.props.customer.canRemove ?
                            <td onClick={e => this.props.remove()} style={{"width": "20px"}}>
                                <Trash/>
                            </td> : <td/>
                    }
                </tr>
            );
        }
    }
}

export default MembershipLine