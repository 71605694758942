import React, {Component} from 'react';
import "../main.css"
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import {connect} from "react-redux";
import Keyboard from "../../keyboard/Keyboard";
import Button from "../../buttons/Button";
import {
    addErrorAlert,
    addInfoAlert,
    closeAdministration, getPointOfSaleDayTotals,
    getUserRights,
    openVerifyPinModal,
    POSCashInOut,
    showSelectDialogue,
} from "../../redux/actions";
import {getReasonCodesByPurpose} from "../../redux/selectors";
import {CASH_IN_OUT_SUCCESS} from "../../redux/actionTypes";
import Header from "./components/Header";


const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        reasonCodes: state.rootReducer.reasonCodes,
        pointOfSaleID: state.rootReducer.posID,
        employeeID: state.rootReducer.transactionUser !== false ? state.rootReducer.transactionUser.employeeID : state.rootReducer.user.employeeID,
        erplyConf: state.rootReducer.erplyConf,
        clientCode: state.rootReducer.user.clientCode,
        theme: state.rootReducer.theme,
        translate: getTranslate(state),
        connectionHealth: state.rootReducer.connectionHealth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        handleCashInOut: (type, amount, reasonCodes, posID, employeeID, erplyConf, clientCode, adminUser, translate, connectionHealth) => {
            if (type === "cashOut" && !connectionHealth) {
                dispatch(addErrorAlert('Not available offline'));
                return false;
            }

            if (amount === '') {
                dispatch(addErrorAlert('Please enter amount'));
                return false;
            }

            if (amount.indexOf('-') !== -1) {
                dispatch(addErrorAlert('Negative amount is not allowed!'));
                return false;
            }

            let cashInLimit = window.AppConf.cashInLimit;
            if(cashInLimit !== false && type === "cashIn" && parseFloat(amount) > cashInLimit){
                dispatch(addErrorAlert(translate('Maximum cash in sum is', {sum: cashInLimit})));
                return false;
            }

            function checkUserRightsByUserID(userID, callback) {
                dispatch(getUserRights(userID, posID, (records) => {
                    callback(records[0].rightMakeDiscountInPOS == 1);
                    return {
                        type: 'USER_RIGHTS_SUCCESS'
                    }
                }, () => {
                    return addErrorAlert('Could not get user right records, please try again!');
                }));
            }

            function checkUserRights(callback) {
                if (type === 'cashIn' || parseFloat(erplyConf.DKTCashOutSupervisorLimit) >= parseFloat(amount)) {
                    callback(true);
                    return true;
                }
                dispatch(openVerifyPinModal(dispatch, clientCode, (user) => {
                    if (!window.AppConf.selfValidateSupervisor && user.userID === adminUser.userID) {
                        callback(false);
                    } else {
                        checkUserRightsByUserID(user.userID, callback);
                    }
                }, () => {
                    callback(false);
                }));
            }

            function getExpectedAmount() {
                return new Promise((resolve, reject) => {
                    if(type === 'cashIn'){
                        resolve(0);
                    }else{
                        dispatch(getPointOfSaleDayTotals(posID, (dayTotals) => {
                            let dayTotalsCash = dayTotals.filter(el => {return el.paymentType === "CASH"});
                            let expectedAmount = dayTotalsCash.length > 0 ? dayTotalsCash[0].expectedAmount : 0;
                            resolve(expectedAmount);
                            return {
                                type: "GET_EXPECTED_CASH_SUCCESS"
                            }
                        }, () => {
                            reject();
                            return {
                                type: "GET_EXPECTED_CASH_FAILED"
                            }
                        }));
                    }
                });
            }

            getExpectedAmount().then((expectedAmount) => {
                let cashOutLimit = window.AppConf.cashOutLimit;
                if(cashOutLimit === false || cashOutLimit > expectedAmount){
                    cashOutLimit = expectedAmount;
                }
                if(type === "cashOut" && parseFloat(amount) > cashOutLimit){
                    dispatch(addErrorAlert(translate('Maximum cash out sum is', {sum: cashOutLimit})));
                    return false;
                }
                checkUserRights(function (userHasRight) {
                    if (!userHasRight) {
                        dispatch(addErrorAlert('User has no right to perform this action!'));
                        return false;
                    }

                    let purpose = type === 'cashIn' ? 'CASH_IN' : 'CASH_OUT';

                    let reasonCodeOptions = getReasonCodesByPurpose(reasonCodes, purpose).reduce((acc, reasonCode) => {
                        acc.push({
                            name: reasonCode.name,
                            value: reasonCode.reasonID
                        });
                        return acc;
                    }, []);

                    let dialogueTitle = type === 'cashIn' ? 'Cash in reason' : 'Cash out reason';
                    dispatch(showSelectDialogue(dialogueTitle, reasonCodeOptions, (reasonID, reason) => {
                        let requestName = type === 'cashIn' ? 'POSCashIN' : 'POSCashOUT';
                        dispatch(POSCashInOut(requestName, posID, employeeID, amount, reason, reasonID, data => {
                            dispatch(addInfoAlert('Transaction completed'));
                            dispatch(closeAdministration());
                            return {
                                type: CASH_IN_OUT_SUCCESS,
                                payload: {
                                    transactionID: data[0].transactionID,
                                    addedUnixTime: data[0].addedUnixTime,
                                    type,
                                    amount
                                }
                            }
                        }, (error) => {
                            return addErrorAlert('Could not save cash in');
                        }, erplyConf))
                    }, e => {
                    }));
                });
            }, () => {
                dispatch(addErrorAlert('Could not get the maximum cash out amount info!'));
            });
        }
    }
};

class AddItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyboardLayout: "numbers",
            selectedUser: false,
            errorMessage: false
        };

        this.clickButton = this.clickButton.bind(this);
        this.getInputAmount = this.getInputAmount.bind(this);
    }

    getInputAmount(){
        return document.getElementsByName("cashInOutAmount")[0].value;
    }

    clickButton(onClick, name) {
        return (
            <div onClick={e => {
                if(typeof onClick !== "undefined" && onClick !== null){
                    onClick();
                }
            }} style={{
                float: 'left',
                marginRight: '10px'
            }}>
                {
                    this.props.theme === 'Erply' ?
                        <Button type={"button"}
                                className={"largeButton darkYellowButton roundButton uppercaseText"}
                                arrow={"right"}
                                name={name}
                        />
                        :
                        <Button type={"button"}
                                className={"largeButton yellowButton"}
                                flencheClass={"flencheSmallRightBottom yellowFlenche"}
                                arrow={"right"}
                                name={name}
                        />
                }

            </div>
        )
    }

    render() {
        if(this.props.theme === 'Erply'){
            return (
                <div>
                    <Header title={"Cash in/out"}/>
                    <div style={{
                        marginTop: '100px'
                    }}>
                        <label style={{
                            color: '#808080',
                            marginLeft: '15px'
                        }}>
                            <Translate id={"Amount"}/>
                        </label>
                            <input
                                type="text"
                                name={'cashInOutAmount'}
                                className={'underlineInput'}
                                style={{
                                    marginBottom: '20px'
                                }}
                                autoComplete={'off'}
                            />
                    </div>

                    {this.clickButton(e => {
                        this.props.handleCashInOut(
                            'cashIn',
                            this.getInputAmount(),
                            this.props.reasonCodes,
                            this.props.pointOfSaleID,
                            this.props.employeeID,
                            this.props.erplyConf,
                            this.props.clientCode,
                            undefined,
                            this.props.translate,
                            this.props.connectionHealth
                        );
                    }, <Translate id={"Cash in"}/>)}

                    {this.clickButton(e => {
                        this.props.handleCashInOut(
                            'cashOut',
                            this.getInputAmount(),
                            this.props.reasonCodes,
                            this.props.pointOfSaleID,
                            this.props.employeeID,
                            this.props.erplyConf,
                            this.props.clientCode,
                            this.props.administration.user,
                            this.props.translate,
                            this.props.connectionHealth
                        );
                    }, <Translate id={"Cash out"}/>)}

                    <div style={{
                        "position": "absolute",
                        "bottom": "0",
                        "left": "0",
                        "right": "0"
                    }}>
                        <Keyboard options={{
                            layout: this.state.keyboardLayout,
                            inputFieldName: "cashInOutAmount",
                            onChangeToLetters: () => {}
                        }}/>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <h1 className={"bigUpperCaseHeading"}><Translate id={"Cash in/out"}/></h1>
                <input type="text" name={'cashInOutAmount'} className={'underlineInput'} style={{
                    marginBottom: '20px'
                }} placeholder={this.props.translate('Amount')} autoComplete={'off'}/>

                {this.clickButton(e => {
                    this.props.handleCashInOut(
                        'cashIn',
                        this.getInputAmount(),
                        this.props.reasonCodes,
                        this.props.pointOfSaleID,
                        this.props.employeeID,
                        this.props.erplyConf,
                        this.props.clientCode,
                        undefined,
                        this.props.translate,
                        this.props.connectionHealth
                    );
                }, <Translate id={"Cash in"}/>)}

                {this.clickButton(e => {
                    this.props.handleCashInOut(
                        'cashOut',
                        this.getInputAmount(),
                        this.props.reasonCodes,
                        this.props.pointOfSaleID,
                        this.props.employeeID,
                        this.props.erplyConf,
                        this.props.clientCode,
                        this.props.administration.user,
                        this.props.translate,
                        this.props.connectionHealth
                    );
                }, <Translate id={"Cash out"}/>)}

                <div style={{
                    "position": "absolute",
                    "bottom": "0",
                    "left": "0",
                    "right": "0"
                }}>
                    <Keyboard options={{
                        layout: this.state.keyboardLayout,
                        inputFieldName: "cashInOutAmount",
                        onChangeToLetters: () => {}
                    }}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddItem);
