import React, {Component} from "react"
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import removeProductImg from "../images/removeProducts.png";
import thanksImg from "../images/thanks.png";
import greenCheckMarkErplyImg from "../images/Erply/greenCheckMark.png";
import {connect} from "react-redux";
import {changeMode, initTransaction, printReceipt, sendEmailReceipt} from "../../redux/actions";
import {APP_MODE_SCREEN_SAVER} from "../../redux/appConstants";
import Button from "../../buttons/Button";
import Keyboard from "../../keyboard/Keyboard";
import {getCashChange} from "../../redux/payment/selectors";
import {EMAIL_NOT_SENT, EMAIL_SENT} from "../../redux/actionTypes";
import CheckBoxErply from "../../formElements/CheckBoxErply";

const mapStateToProps = state => {
    let change = getCashChange(state.payment.payments);
    let showPrintingDialog = window.AppConf.showPrintingDialog ?? true;
    let startingView = showPrintingDialog ? 'sendEmail' : 'takeProducts';

    return {
        lastInvoice: state.rootReducer.lastInvoice,
        currency: state.rootReducer.currency,
        change,
        startingView,
        showPrintingDialog,
        translate: getTranslate(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        initDocument: () => {
            dispatch(initTransaction());
            dispatch(changeMode(APP_MODE_SCREEN_SAVER));
        },
        sendEmail: (email, invoiceID) => {
            dispatch(sendEmailReceipt(invoiceID, email, function () {
                return {
                    type: EMAIL_SENT
                }
            }, function () {
                return {
                    type: EMAIL_NOT_SENT
                }
            }));
        },
        printReceipt: (lastInvoice) => {
            dispatch(printReceipt(lastInvoice.receiptLink));
        }
    }
};

class DoneErply extends Component {
    constructor(props) {
        super(props);

        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.processReceipt = this.processReceipt.bind(this);

        this.state = {
            view: props.startingView,
            keyboardLayout: 'letters',
            printReceipt: true
        };
    }

    componentDidMount() {
        console.log('DONE: ', this.props.showPrintingDialog);
        if(!this.props.showPrintingDialog){
            this.initTransaction();
        }
    }

    componentWillUnmount() {
        const component = this;
        component.setState({
            view: this.props.startingView
        });
    }

    initTransaction() {
        const component = this;
        setTimeout(function () {
            component.setState({
                view: 'thankVisit'
            });
            setTimeout(function () {
                component.props.initDocument();
            }, 200000);
        }, 400000);
    }

    processReceipt() {
        const email = document.getElementsByName("emailInput")[0].value;
        if(email !== ""){
            this.props.sendEmail(email, this.props.lastInvoice.id);
        }
        if(this.state.printReceipt){
            this.props.printReceipt(this.props.lastInvoice);
        }
        this.setState({
            view: 'takeProducts'
        });
        this.initTransaction();
    }

    render() {
        const sendEmail = (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <img
                    src={greenCheckMarkErplyImg}
                    alt={'Check mark'}
                    width={110}
                />
                <span
                    className={"boldText"}
                    style={{
                        margin: '20px'
                    }}
                >
                    <Translate id={"paymentMade"}/>
                </span>
                <span
                    style={{
                        color: '#808080',
                        fontSize: '24px',
                        fontWeight: '600'
                    }}
                >
                    <Translate id={"changeMoneySum"}/>: {this.props.currency}{this.props.change}
                </span>
                <div style={{
                    display: "inline-block",
                    width: "600px",
                    fontSize: "16px"
                }}>
                    <label style={{
                        float: "left",
                        marginTop: "20px",
                        marginBottom: "20px",
                        fontSize: "20px"
                    }}>
                        <Translate id={"Send receipt to email"}/>
                    </label>
                    <input
                        style={{
                            float: "left",
                            width: "calc(100% - 30px)",
                            backgroundColor: '#F9F9F9',
                            border: 'none',
                            borderBottom: '4px solid #3D94F6',
                            marginBottom: '20px'
                        }}
                        type={"text"}
                        name={"emailInput"}
                        //readOnly={"readonly"}
                        placeholder={this.props.translate("E-MAIL")}
                        autoComplete={'off'}
                    />
                    <div style={{
                        float: 'left',
                        marginRight: '20px'
                    }}>
                        <CheckBoxErply
                            checked={this.state.printReceipt}
                            name={'printReceiptCheck'}
                            onChange={(checked) => {
                                this.setState({
                                    printReceipt: checked
                                });
                            }}
                        />
                    </div>
                    <label
                        style={{
                            float: "left",
                            lineHeight: 1.5,
                            fontSize: '20px'
                        }}
                    >
                        <Translate id={"Print receipt"}/>
                    </label>
                    <div style={{clear: 'both', height: '35px'}}/>
                    <span style={{
                        float: "left",
                        fontSize: "20px",
                    }}>
                        <Translate id={"Invoice nr"}/> {this.props.lastInvoice.number}
                    </span>
                    <div onClick={this.processReceipt} style={{
                        float: "right"
                    }}>
                        <Button
                            type="button"
                            name={<Translate id="Ok"/>}
                            className={"largerButton lightBlueButton roundButton uppercaseText"}
                            arrow="rightClose"
                        />
                    </div>
                </div>
                <div style={{
                    "position": "absolute",
                    "bottom": "0",
                    "left": "0",
                    "right": "0"
                }}>
                    <Keyboard options={{
                        layout: this.state.keyboardLayout,
                        inputFieldName: 'emailInput',
                        onChangeToLetters: () => {this.setState({keyboardLayout: "letters"})},
                        onChangeToNumbers: () => {this.setState({keyboardLayout: "numbers"})}
                    }}/>
                </div>
            </div>
        );

        const takeProducts = (
            <div>
                <span className={"blueCircleBackground"}>3</span>
                <br/>
                <div style={{
                    "marginBottom": "34px"
                }}/>
                <span className={"boldUppercaseText largeText"}>
                    <Translate id={"takeProductsFromBasket"}/>
                </span>
                <br/>
                <div style={{
                    "marginBottom": "30px"
                }}/>
                <img src={removeProductImg} alt="removeProduct"/>
            </div>
        );
        const thankVisit = (
            <div>
                <div style={{
                    "marginBottom": "220px"
                }}/>
                <span className={"boldUppercaseText largeText"}>
                    <Translate id={"thankVisit"}/>!
                </span>
                <br/>
                <div style={{
                    "marginBottom": "54px"
                }}/>
                <img src={thanksImg} alt="thanks"/>
            </div>
        );

        let display;
        if(this.state.view === 'sendEmail'){
            display = sendEmail;
        }else if(this.state.view === 'takeProducts'){
            display = takeProducts;
        }else{
            display = thankVisit;
        }
        return (
            <div>
                <div style={{
                    "textAlign":"center",
                    "marginTop": "95px"
                }}>
                    { display }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoneErply)
