import moment from "moment-timezone";
import * as uuid from "uuid";
import {getDocumentNotes, getPaymentsTotalRounding, getProductsInBasket, getProductsTotalSum} from "../../selectors";
import CTR from "../../../util/receiptTemplate";

export function createCompleteTransactionRequests(state, receipt=false) {
    const invoiceNo = state.rootReducer.nextInvoiceNo;
    const productsInBasket = getProductsInBasket(state);
    const payment = state.payment;
    const customer = state.rootReducer.customer;
    const pos = state.rootReducer.pos;
    const erplyConf = state.rootReducer.erplyConf;
    const user = state.rootReducer.transactionUser !== false ? state.rootReducer.transactionUser : state.rootReducer.user;
    const totalSum = getProductsTotalSum(state.rootReducer.productsInBasket);
    const discount = state.rootReducer.discount;
    const pluginRequestParameters = state.rootReducer.pluginRequestParameters;
    const creditToDocumentID = state.rootReducer.returnBaseDocument !== false ? state.rootReducer.returnBaseDocument.documentID : false;
    const cashierMode = state.rootReducer.cashierMode;
    const pendingDocument = state.rootReducer.pendingDocument;

    console.log('completing transaction', invoiceNo, productsInBasket, payment, customer, pos, erplyConf, user, totalSum, discount, pluginRequestParameters);
    const requests = [];
    let type = totalSum < 0 ? "CREDITINVOICE" : "CASHINVOICE";

    let saveTime = moment();
    saveTime.tz(erplyConf.timezone);

    let salesDocumentRequest = state.rootReducer.invoice !== false ? {
        requestName: "saveSalesDocument",
        id: state.rootReducer.invoice.id,
        pointOfSaleID: pos.pointOfSaleID,
        requestID: 0,
        paymentType: "CARD",
        invoiceState: "READY"
    } : {
        requestName: "saveSalesDocument",
        requestID: 0,
        date: saveTime.format("YYYY-MM-DD"),
        time: saveTime.format("HH:mm:ss"),
        added: saveTime.unix(),
        currencyCode: erplyConf.default_currency,
        warehouseID: pos.warehouseID,
        pointOfSaleID: pos.pointOfSaleID,
        confirmInvoice: 1,
        type,
        paymentType: "CARD",
        employeeID: user.employeeID,
        invoiceNo,
        temporaryUUID: uuid.v4()
    };

    if(pendingDocument !== false){
        salesDocumentRequest.id = pendingDocument.id;
        salesDocumentRequest.invoiceState = "READY";
    }

    if (creditToDocumentID !== false) {
        salesDocumentRequest.creditToDocumentID = creditToDocumentID
    }

    const rounding = getPaymentsTotalRounding(payment.payments);
    if(rounding !== 0){
        salesDocumentRequest.rounding = rounding;
    }
    salesDocumentRequest.notes = getDocumentNotes(pluginRequestParameters, payment.payments);

    if (process.env.REACT_APP_ERPLY_MODE === "1") {
        if (typeof customer.customerID !== "undefined") {
            salesDocumentRequest.customerID = customer.customerID
        }
    }

    if (type === 'CREDITINVOICE') {
        salesDocumentRequest['creditInvoiceType'] = 'RETURN';
        salesDocumentRequest['isCashInvoice'] = 1;
    }

    let attributeCounter = 0;

    salesDocumentRequest['attributeName' + attributeCounter] = 'source';
    salesDocumentRequest['attributeType' + attributeCounter] = 'text';
    salesDocumentRequest['attributeValue' + attributeCounter] = (cashierMode ? 'EPOS' : 'SCO') + '_' + window.AppConf?.version ?? "";
    attributeCounter++;

    let longAttributeCounter = 0;

    if (receipt !== false) {
        salesDocumentRequest['longAttributeName' + longAttributeCounter] = 'receiptHtml';
        salesDocumentRequest['longAttributeValue' + longAttributeCounter] = receipt;
        longAttributeCounter++;

        salesDocumentRequest['attributeName' + attributeCounter] = 'receiptNumber';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = CTR.lastReceiptNumber;
        attributeCounter++;
    }

    if (process.env.REACT_APP_ERPLY_MODE !== "1") {
        salesDocumentRequest['attributeName' + attributeCounter] = 'POSLogSent';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = 0;
        attributeCounter++;
    }

    if (process.env.REACT_APP_ERPLY_MODE !== "1" &&
        customer !== false &&
        (
            customer.default !== true ||
            typeof customer.zipCode !== "undefined" ||
            typeof customer.taxRegistrationNumber !== "undefined" ||
            typeof customer.localRequirements !== "undefined"
        )
    ) {
        let customerAttribute = JSON.stringify(customer.getCustomerAttribute());
        if(customerAttribute.length > 255){
            salesDocumentRequest['longAttributeName' + longAttributeCounter] = 'customer';
            salesDocumentRequest['longAttributeValue' + longAttributeCounter] = customerAttribute;
            longAttributeCounter++;
        }else{
            salesDocumentRequest['attributeName' + attributeCounter] = 'customer';
            salesDocumentRequest['attributeType' + attributeCounter] = 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = customerAttribute;
            attributeCounter++;
        }
        if (typeof customer.card_number !== "undefined" && customer.card_number !== '') {
            salesDocumentRequest.notes = "Customer card: " + customer.card_number + " \n\r" + salesDocumentRequest.notes;
        }
    }

    if(state.rootReducer.returnBasePOSLog !== false){
        salesDocumentRequest['attributeName' + attributeCounter] = 'originalTransaction';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = state.rootReducer.returnBasePOSLog.transaction.transactionID;
        attributeCounter++;
    }

    let c = 0;
    //let tagLineNo = 1;
    let priceDerivationRules = {};
    for (let product of productsInBasket) {
        salesDocumentRequest["itemName" + c] = product.name;
        salesDocumentRequest["productID" + c] = product.data.productID;
        salesDocumentRequest["amount" + c] = product.amount;
        salesDocumentRequest["price" + c] = product.netPrice;
        salesDocumentRequest["vatrateID" + c] = product.data.vatrateID;
        salesDocumentRequest["employeeID" + c] = user.employeeID;

        if (product.hasManualDiscount()) {
            /*salesDocumentRequest["promotionRule" + c + "amount1"] = product.amount;
            salesDocumentRequest["promotionRule" + c + "finalPrice1"] = product.manualDiscountFinalPrice;
            salesDocumentRequest["promotionRule" + c + "totalDiscount1"] = product.manualDiscountTotal;
            salesDocumentRequest["promotionRule" + c + "manualDiscountPercentage1"] = product.manualDiscountPercentage;*/
            salesDocumentRequest["price" + c] = product.originalPrice;
            salesDocumentRequest["discount" + c] = product.manualDiscountPercentage;

            if (product.manualDiscountReasonCodeID !== 0) {
                salesDocumentRequest["promotionRule" + c + "amount1"] = product.amount;
                salesDocumentRequest["promotionRule" + c + "finalPrice1"] = product.manualDiscountFinalPrice * -1;
                salesDocumentRequest["promotionRule" + c + "totalDiscount1"] = product.manualDiscountTotal * -1;
                salesDocumentRequest["promotionRule" + c + "manualDiscountPercentage1"] = product.manualDiscountPercentage;
                salesDocumentRequest["promotionRule" + c + "manualDiscountReasonID1"] = product.manualDiscountReasonCodeID;
            }

            if (product.discountApproval !== undefined) {
                salesDocumentRequest['attributeName' + attributeCounter] = 'discountApproval' + c;
                salesDocumentRequest['attributeType' + attributeCounter] = 'text';
                salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(product.discountApproval);
                attributeCounter++;
            }
        } else if (product.discount !== 0) {
            salesDocumentRequest["price" + c] = product.originalPrice;
            salesDocumentRequest["discount" + c] = product.discount;
        }

        if (typeof product.promotionRules !== "undefined") {
            for (let promotionRule of product.promotionRules) {
                salesDocumentRequest[promotionRule.name] = promotionRule.value;
            }
        }

        if (product.returnReasonID !== undefined) {
            salesDocumentRequest["returnReasonID" + c] = product.returnReasonID;
        }

        if (typeof product.priceDerivationRuleID !== "undefined"){
            if(typeof priceDerivationRules[product.priceDerivationRuleID] === "undefined"){
                priceDerivationRules[product.priceDerivationRuleID] = [];
            }
            priceDerivationRules[product.priceDerivationRuleID].push(c);
        }
        salesDocumentRequest["employeeID" + c] = user.employeeID;

        let RFIDTag = typeof product.data.RFIDTag !== 'undefined' ? {...product.data.RFIDTag} : product.data.voucherRFIDTag;
        if(process.env.REACT_APP_ERPLY_MODE === "0" && window.AppConf?.enableRFID !== true){
            if(typeof product.data.RFIDSerialNumber !== "undefined"){
                if(typeof RFIDTag === "undefined"){
                    RFIDTag = {
                        'GTIN': 'NULL_VALUE',
                        'SGTIN': 'NULL_VALUE',
                        'SERIAL_NUMBER': 'NULL_VALUE',
                        'EPC': 'NULL_VALUE'
                    };
                }
                RFIDTag.SERIAL_NUMBER = product.data.RFIDSerialNumber;
            }
            if(typeof product.data.voucherRFIDTag !== "undefined"){
                RFIDTag = product.data.voucherRFIDTag;
            }
        }

        if(typeof product.data.saveRFIDTag !== "undefined"){
            RFIDTag = product.data.saveRFIDTag;
        }

        if (typeof RFIDTag !== 'undefined') {
            if (RFIDTag.SERIAL_NUMBER !== 'NULL_VALUE' &&
                typeof product.giftCardCode === "undefined" &&
                RFIDTag.EPC !== "WorkshopVoucherDetail" &&
                RFIDTag.SERIAL_NUMBER !== "CCOrder"
            ) {
                salesDocumentRequest["itemName" + c] = product.name + ' RFID: ' + RFIDTag.SERIAL_NUMBER;
            }
            salesDocumentRequest['attributeName' + attributeCounter] = 'RFIDTag' + c;
            salesDocumentRequest['attributeType' + attributeCounter] = 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(RFIDTag);
            attributeCounter++;
        }

        if (product.isUnknownItem === true) {
            salesDocumentRequest['attributeName' + attributeCounter] = 'unknownItemCode' + c;
            salesDocumentRequest['attributeType' + attributeCounter] = 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = product.data.code;
            attributeCounter++;
        }

        if(product.hasPriceModification === true){
            salesDocumentRequest['attributeName' + attributeCounter] = 'priceModified' + c;
            salesDocumentRequest['attributeType' + attributeCounter] = 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = 1;
            attributeCounter++;
        }

        if(typeof product.extraSerialNumbers !== "undefined"){
            salesDocumentRequest['longAttributeName' + longAttributeCounter] = 'extraSerialNumbers' + c;
            salesDocumentRequest['longAttributeType' + longAttributeCounter] = 'text';
            salesDocumentRequest['longAttributeValue' + longAttributeCounter] = JSON.stringify(product.extraSerialNumbers);
            longAttributeCounter++;
        }

        c++;
    }

    let priceDerivationRuleKeys = Object.keys(priceDerivationRules);
    if (priceDerivationRuleKeys.length > 0) {
        salesDocumentRequest['attributeName' + attributeCounter] = 'priceDerivationRules';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(priceDerivationRules);
        attributeCounter++;
    }

    if (discount.documentDiscountPercentage !== undefined) {
        salesDocumentRequest['attributeName' + attributeCounter] = 'documentDiscountPercentage';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = discount.documentDiscountPercentage;
        attributeCounter++;
    }
    if (discount.documentDiscountApproval !== undefined) {
        salesDocumentRequest['attributeName' + attributeCounter] = 'documentDiscountApproval';
        salesDocumentRequest['attributeType' + attributeCounter] = 'text';
        salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(discount.documentDiscountApproval);
        attributeCounter++;
    }

    if (typeof pluginRequestParameters.saveSalesDocument !== 'undefined') {
        let pluginAttributes = pluginRequestParameters.saveSalesDocument.attributes ?? [];
        for (let attribute of pluginAttributes) {
            salesDocumentRequest['attributeName' + attributeCounter] = attribute.name;
            salesDocumentRequest['attributeType' + attributeCounter] = attribute.type ?? 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = attribute.value;
            attributeCounter++;
        }

        let pluginSaveSalesDocument = Object.assign({}, pluginRequestParameters.saveSalesDocument);
        delete pluginSaveSalesDocument.attributes;
        delete pluginSaveSalesDocument.notes;

        salesDocumentRequest = Object.assign(salesDocumentRequest, pluginSaveSalesDocument);
    }
    requests.push(salesDocumentRequest);

    let paymentCounter = 1;
    for (let paymentData of payment.payments) {
        if (paymentData.status !== 'success') {
            continue;
        }
        let paymentRequest = {
            "requestName": "savePayment",
            "requestID": paymentCounter,
            "documentID": "CURRENT_INVOICE_ID",
            "type": paymentData.type,
            "sum": parseFloat(paymentData.sumPaid)
        };
        if(paymentData.currencyCode !== null){
            paymentRequest.currencyCode = paymentData.currencyCode;
        }
        if (paymentData.type === 'CARD') {
            paymentRequest["attributeName1"] = "authCode";
            paymentRequest["attributeType1"] = "text";
            paymentRequest["attributeValue1"] = paymentData.transaction.authCode;
            paymentRequest["attributeName2"] = "refNo";
            paymentRequest["attributeType2"] = "text";
            paymentRequest["attributeValue2"] = paymentData.transaction.refNo;
            paymentRequest["attributeName3"] = "CreditOrDebit";
            paymentRequest["attributeType3"] = "text";
            paymentRequest["attributeValue3"] = paymentData.transaction.cardType;

            if(typeof paymentData.transaction.dateTime !== "undefined"){
                paymentRequest["attributeName4"] = "dateTime";
                paymentRequest["attributeType4"] = "text";
                paymentRequest["attributeValue4"] = paymentData.transaction.dateTime;
            }

            paymentRequest["cardNumber"] = paymentData.transaction.cardNumber;
            paymentRequest["cardType"] = paymentData.transaction.cardType;
            if (paymentData.transaction.signature !== null && paymentData.transaction !== '') {
                paymentRequest["signature"] = paymentData.transaction.signature;
            }
        } else if (paymentData.type === 'GIFTCARD') {
            if (process.env.REACT_APP_ERPLY_MODE !== "1") {
                paymentRequest["attributeName1"] = "giftCardID";
                paymentRequest["attributeType1"] = "text";
                paymentRequest["attributeValue1"] = paymentData.giftCard.cardNumber;
                paymentRequest["attributeName2"] = "POSLogGiftCard";
                paymentRequest["attributeType2"] = "text";
                paymentRequest["attributeValue2"] = JSON.stringify({
                    authorizationCode: paymentData.giftCard.authorizationCode,
                    expirationDate: paymentData.giftCard.expirationDate,
                    merchantId: paymentData.giftCard.merchantId,
                    no: paymentData.giftCard.cardNumber,
                    referenceNumber: paymentData.giftCard.referenceNumber
                });
            } else {
                paymentRequest['giftCardVatRateID'] = paymentData.giftCard.vatrateID;
                paymentRequest["attributeName1"] = "giftCardID";
                paymentRequest["attributeType1"] = "text";
                paymentRequest["attributeValue1"] = paymentData.giftCard.giftCardID;
            }
        } else if (paymentData.type === 'CASH') {
            requests[0]['paymentType'] = 'CASH';

            if(paymentData.currencyCode === null){
                paymentRequest['cashPaid'] = paymentData.cashPaid;
                paymentRequest['cashChange'] = paymentData.cashChange;
            }else{
                paymentRequest['sum'] = paymentData.cashPaid;
                if(parseFloat(paymentData.cashChange) !== 0){
                    paymentCounter++;
                    requests.push({
                        requestName: "savePayment",
                        requestID: paymentCounter,
                        documentID: "CURRENT_INVOICE_ID",
                        type: paymentData.type,
                        sum: parseFloat(paymentData.cashChange) * -1,
                        cashChange: paymentData.cashChange
                    });
                }
            }

        }

        if (typeof paymentData.typeID !== "undefined") {
            delete paymentRequest['type'];
            paymentRequest['typeID'] = paymentData.typeID;
        }

        if (paymentData.serialNumber !== '') {
            paymentRequest['attributeName3'] = 'voucherSerial';
            paymentRequest['attributeValue3'] = paymentData.serialNumber;
        }

        let ac = 10;
        paymentData.attributes.forEach(attribute => {
            paymentRequest["attributeName" + ac] = attribute.name;
            paymentRequest["attributeType" + ac] = attribute.type;
            paymentRequest["attributeValue" + ac] = attribute.value;
            ac++;
        });

        paymentData.documentAttributes.forEach(attribute => {
            salesDocumentRequest["attributeName" + attributeCounter] = attribute.name;
            salesDocumentRequest["attributeType" + attributeCounter] = attribute.type;
            salesDocumentRequest["attributeValue" + attributeCounter] = attribute.value;
            attributeCounter++;
        });

        requests.push(paymentRequest);
        paymentCounter++;
    }
    return requests;
}

export function saveSale(state){
    const productsInBasket = getProductsInBasket(state);
    const customer = state.rootReducer.customer;
    const pos = state.rootReducer.pos;
    const erplyConf = state.rootReducer.erplyConf;
    const user = state.rootReducer.user;
    const pendingDocument = state.rootReducer.pendingDocument;

    console.log('saving sale', {productsInBasket, customer, pos, erplyConf, user, pendingDocument});
    const requests = [];
    const salesDocumentRequest = {
        "requestName": "saveSalesDocument",
        "requestID": 0,
        "currencyCode": erplyConf.default_currency,
        "warehouseID": pos.warehouseID,
        "pointOfSaleID": pos.pointOfSaleID,
        "confirmInvoice": 0,
        "type": "CASHINVOICE",
        "paymentType": "CARD",
        "employeeID": user.employeeID
    };

    if(pendingDocument !== false){
        salesDocumentRequest.id = pendingDocument.id
    }

    let attributeCounter = 0;

    if(process.env.REACT_APP_ERPLY_MODE !== "1" && customer !== false && customer.default !== true) {
        let customerAttribute = JSON.stringify(customer.getCustomerAttribute());
        if(customerAttribute.length > 255){
            salesDocumentRequest['longAttributeName0'] = 'customer';
            salesDocumentRequest['longAttributeValue0'] = customerAttribute;
        }else{
            salesDocumentRequest['attributeName' + attributeCounter] = 'customer';
            salesDocumentRequest['attributeType' + attributeCounter] = 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = customerAttribute;
            attributeCounter++;
        }
    }

    let c = 0;
    let tagLineNo = 1;
    for(let product of productsInBasket) {
        salesDocumentRequest["itemName" + c] = product.name;
        salesDocumentRequest["productID" + c] = product.data.productID;
        salesDocumentRequest["amount" + c] = product.amount;
        salesDocumentRequest["price" + c] = product.netPrice;
        salesDocumentRequest["vatrateID" + c] = product.data.vatrateID;
        salesDocumentRequest["employeeID" + c] = user.employeeID;

        if (product.hasManualDiscount()) {
            salesDocumentRequest["price" + c] = product.originalPrice;
            salesDocumentRequest["discount" + c] = product.manualDiscountPercentage;

            if (product.manualDiscountReasonCodeID !== 0) {
                salesDocumentRequest["promotionRule" + c + "amount1"] = product.amount;
                salesDocumentRequest["promotionRule" + c + "finalPrice1"] = product.manualDiscountFinalPrice * -1;
                salesDocumentRequest["promotionRule" + c + "totalDiscount1"] = product.manualDiscountTotal * -1;
                salesDocumentRequest["promotionRule" + c + "manualDiscountPercentage1"] = product.manualDiscountPercentage;
                salesDocumentRequest["promotionRule" + c + "manualDiscountReasonID1"] = product.manualDiscountReasonCodeID;
            }

            if (product.discountApproval !== undefined) {
                salesDocumentRequest['attributeName' + attributeCounter] = 'discountApproval' + c;
                salesDocumentRequest['attributeType' + attributeCounter] = 'text';
                salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(product.discountApproval);
                attributeCounter++;
            }
        } else if (product.discount !== 0) {
            salesDocumentRequest["price" + c] = product.originalPrice;
            salesDocumentRequest["discount" + c] = product.discount;
        }

        if (product.returnReasonID !== undefined) {
            salesDocumentRequest["returnReasonID" + c] = product.returnReasonID;
        }

        if(typeof product.data.RFIDTag !== 'undefined'){
            product.data.RFIDTag.NUM_LINE = tagLineNo;
            salesDocumentRequest['attributeName' + attributeCounter] = 'RFIDTag' + c;
            salesDocumentRequest['attributeType' + attributeCounter] = 'text';
            salesDocumentRequest['attributeValue' + attributeCounter] = JSON.stringify(product.data.RFIDTag);
            attributeCounter++;
            tagLineNo++;
        }
        c++;
    }
    requests.push(salesDocumentRequest);

    return requests;
}
