import React, { Component } from 'react';
import '../statusBar.css'
import '../../App.css'
import {connect} from "react-redux";
import Translate from "localization/Translate";

const mapStateToProps = state => {
    return {
        view: state.rootReducer.view,
        mode: state.rootReducer.mode,
        uiCustomization: state.rootReducer.uiCustomization
    }
};

function StepSeparator() {
    return (
        <div style={{
            float: "left",
            borderBottom: "1px solid #CCC",
            width: "100px",
            margin: "10px 10px 10px 10px"
        }}/>
    );
}

function Step(props) {
    const opacity = props.active ? 1 : 0.5;
    return (
        <div style={{
            opacity: opacity,
            float: "left",
            transition: "opacity 0.2s ease-in"
        }}>
            <span className={"blueCircleBackgroundSmall"}>{props.number}</span>
            <span style={{
                marginLeft: "10px",
                textTransform: "uppercase"
            }}>{props.name}</span>
        </div>
    );
}

class Steps extends Component{

    render() {
        if(this.props.view !== 'app' || this.props.mode === 'screenSaver' || this.props.uiCustomization.statusBarSteps.display !== 1){
            return null;
        }
        return (
            <div style={{
                position: 'absolute',
                left: 0,
                right: 0,
                width: 'fit-content',
                margin: 'auto'
            }}>
                <Step number="1" name={<Translate id={'scanning'}/>} active={this.props.mode === 'scan'}/>
                <StepSeparator/>
                <Step number="2" name={<Translate id={'payment'}/>} active={this.props.mode === 'payment'}/>
                <StepSeparator/>
                <Step number="3" name={<Translate id={'done'}/>} active={this.props.mode === 'done'}/>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Steps);
