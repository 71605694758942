import React from 'react';
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../../uiComponents/WrappedPerfectScrollBar';
import PromotionListItem from "./Item";
import "../../main.css";
import {useSelector} from "react-redux";
import {numberIsOdd} from "../../../util/helperFunctions";

export default function(props) {
    const theme = useSelector(state => state.rootReducer.theme);

    return (
        <div
            id={props.id}
            style={{
                height: "100%",
                width: "100%",
                overflow: "hidden"
        }}>
            <div>
                <PerfectScrollbar style={{
                    position: "relative",
                    left: 0,
                    right:0,
                    top: 0,
                    bottom: 0,
                    padding: theme === 'Erply' ? '0 20px' : 0
                }}>
                    <table
                        className={`${theme === 'Decathlon' ? 'bold responsiveTable borderBottomTd documentTable' : 'responsiveTableErply'}`}
                        style={{
                            tableLayout: "fixed"
                        }}
                    >
                        <tbody
                            style={theme === 'Erply' ? {} : {
                                borderTop: '2px solid #ebebeb'
                            }}
                        >
                        {
                            props.promotions.map( (promotion, index) => {
                                return <PromotionListItem
                                    key={index}
                                    promotion={promotion}
                                    className={theme === 'Erply' ? (numberIsOdd(index + 1) ? ' productLineOdd' : '') : ''}
                                />
                            })
                        }
                        </tbody>
                    </table>
                </PerfectScrollbar>
            </div>
        </div>
    );
}