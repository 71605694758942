import $ from "jquery"

/**
 * Send POS Log
 */
const SPL = {
    isInit: false
};

SPL.init = function (pointOfSaleID, warehouseID, clientCode, employeeID){
    if(SPL.isInit) return true;

    SPL.pointOfSaleID = pointOfSaleID;
    SPL.warehouseID = warehouseID;
    SPL.clientCode = clientCode;
    SPL.employeeID = employeeID;

    SPL.isInit = true;
};

SPL.url = () => typeof window.AppConf?.POSLog?.url !== "undefined" ? window.AppConf.POSLog.url : false;
SPL.loggingOut = false;
SPL.dayClosed = false;

SPL.clientCode = false;
SPL.pointOfSaleID = false;
SPL.warehouseID = false;
SPL.employeeID = false;
SPL.delayed = true;

SPL.employeeLoggedIn = false;
SPL.loggedInEmployeeID = 0;
SPL.lockedTimestamp = 0;
SPL.firstLoad = true;
SPL.lastReasonCode = false;
SPL.manualOpen = false;

SPL.log = function(){
    let args = Array.from(arguments);
    args.unshift('POSLog:');
    console.log.apply(null, args);
};

SPL.makeBasicAuth = function(user, password){
    return "Basic " + btoa(user + ':' + password);
};

SPL.apiRequest = function (parameters, failMessage, callback) {
    if(SPL.url() === false){
        SPL.log('Sending POSLog failed! No endpoint in conf.js!');
        return false;
    }
    SPL.sendApiRequest(
        'addLog',
        'POST',
        parameters,
        function(data){
            let result = JSON.parse(data);
            callback(result);
            if(result.status.responseStatus === 'ok'){
                SPL.log('POSLog sent', parameters);
                SPL.delayed = false;
            }else{
                SPL.log('Sending POSLog failed!', parameters);
                SPL.delayed = true;
            }
        }, function(jqXHR, textStatus, errorThrown) {
            SPL.log('Sending POSLog failed',parameters, failMessage, textStatus, errorThrown);
            SPL.delayed = true;
        }
    );
};

SPL.sendApiRequest = (endpoint, type, parameters, success, error) => {
    $.ajax({
        url: SPL.url() + '/' + endpoint,
        type,
        crossDomain: true,
        data: parameters,
        timeout: 180000,
        beforeSend: function (xhr) {
            xhr.setRequestHeader ("Authorization", SPL.makeBasicAuth('65798846579','aPX2S9QcOuf5AGNUNyqZ'));
        },
        success,
        error
    });
}

SPL.getNextSequenceNumber = () => {
    return new Promise((resolve, reject) => {
        let parameters = SPL.getBaseParameters();
        SPL.sendApiRequest('getSequenceNumber', 'POST', parameters, (data) => {
            let result = JSON.parse(data);
            if(result.status.responseStatus === 'ok'){
                SPL.delayed = false;
                resolve(result.sequenceNumber);
            }else{
                reject(result?.status?.responseMessage || 'No response');
            }
        }, (qXHR, textStatus, errorThrown) => {
            console.log('-------------Get POSLog sequence number failed--------------------', textStatus, errorThrown);
            reject(textStatus);
        });
    });
}

SPL.revertSequenceNumber = () => {
    return new Promise((resolve, reject) => {
        let parameters = SPL.getBaseParameters();
        SPL.sendApiRequest('revertSequenceNumber', 'POST', parameters, (data) => {
            let result = JSON.parse(data);
            if(result.status.responseStatus === 'ok'){
                resolve(result.sequenceNumber);
            }else{
                reject(result?.status?.errorMessage || 'No response');
            }
        }, (qXHR, textStatus, errorThrown) => {
            console.log('-------------Revert POSLog sequence number failed--------------------', textStatus, errorThrown);
            reject(textStatus);
        });
    });
}

SPL.getBaseParameters = function () {
    return {
        accountNumber: SPL.clientCode,
        pointOfSaleID: SPL.pointOfSaleID,
        warehouseID: SPL.warehouseID,
        delayed: SPL.delayed
    };
};

SPL.sendRetailTransaction = function (documentID, nextSequenceNumber=false) {
    let parameters = SPL.getBaseParameters();
    parameters['type'] = 'retailTransaction';
    parameters['documentID'] = documentID;
    if(nextSequenceNumber !== false){
        parameters['sequenceNumber'] = nextSequenceNumber;
    }
    SPL.apiRequest(parameters, 'Could not send POSLog!', function (data) {

    });
};

SPL.controlTransaction = function (warehouseID, pointOfSaleID, timestamp, employeeID, transaction) {
    this.warehouseID = warehouseID;
    this.pointOfSaleID = pointOfSaleID;
    this.timestamp = timestamp;
    this.employeeID = employeeID;
    this.transaction = transaction;
    this.startTimestamp = timestamp;
};

SPL.sendControlTransaction = function (transaction, params) {
    let parameters = SPL.getBaseParameters();
    parameters['type'] = 'controlTransaction';
    let timestamp = Math.floor(Date.now() / 1000);
    let controlTransaction = new SPL.controlTransaction(SPL.warehouseID, SPL.pointOfSaleID,
        timestamp, SPL.employeeID, transaction);
    if(typeof params.startTimestamp !== 'undefined'){
        controlTransaction.startTimestamp = params.startTimestamp;
    }
    if(typeof params.employeeID !== 'undefined'){
        controlTransaction.employeeID = params.employeeID;
    }
    if(typeof params.timestamp !== 'undefined'){
        controlTransaction.timestamp = params.timestamp;
    }
    if(typeof params.dayID !== 'undefined'){
        controlTransaction.dayID = params.dayID;
    }
    if(typeof params.cashInSum !== 'undefined'){
        controlTransaction.cashInSum = params.cashInSum;
    }
    parameters['controlTransaction'] = JSON.stringify(controlTransaction);
    SPL.apiRequest(parameters, 'Could not send POSLog!', function (data) {

    });
};

SPL.sendOperatorSignOn = function (dayID, cashInSum, openedUnixTime, employeeID=false) {
    SPL.log('sendOperatorSignOn');
    let params = {
        dayID,
        cashInSum,
        timestamp: openedUnixTime
    };
    if(employeeID !== false){
        params.employeeID = employeeID;
    }
    SPL.sendControlTransaction('OperatorSignOn', params);
};

SPL.sendOperatorSignOff = function (dayID, employeeID=false) {
    SPL.log('sendOperatorSignOff');
    localStorage.setItem('SPLLoggedIn' + SPL.clientCode, 0);
    let params = {
        dayID
    };
    if(employeeID !== false){
        params.employeeID = employeeID;
    }
    SPL.sendControlTransaction('OperatorSignOff', params);
};

SPL.sendOperatorLockUnlock = function () {
    if(SPL.lockedTimestamp === 0){
        return true;
    }
    SPL.log('sendOperatorLockUnlock');
    SPL.sendControlTransaction('OperatorLockUnlock', {startTimestamp: SPL.lockedTimestamp});
    SPL.lockedTimestamp = 0;
};

SPL.sendOperatorLock = function () {
    console.log('SPL: sendOperatorLock');
    SPL.sendControlTransaction('OperatorLock', {});
    SPL.setOperatorLocked(true);
};

SPL.setOperatorLocked = function(locked){
    SPL.locked = locked;
    const lockedValue = locked ? '1' : '0';
    localStorage.setItem('SPLLocked' + SPL.clientCode, lockedValue);
    SPL.lockedTimestamp = locked ? Math.floor(Date.now() / 1000) : 0;
};

SPL.sendOperatorUnlock = function () {
    console.log('SPL: sendOperatorUnlock');
    SPL.sendControlTransaction('OperatorUnlock', {});
    SPL.setOperatorLocked(false);
};

SPL.sendCashInOut = function (transactionID, addedUnixTime) {
    console.log('SPL: sendCashInOut');
    if(SPL.cashPaymentStarted){
        console.log('SPL: cash payment started send cash in out');
        SPL.cashPaymentStarted = false;
        return true;
    }
    // Prevent sending POSLog that will get stuck
    if(transactionID === 0){
        return false;
    }
    console.log('-----------------------------send cash in out-------------------------');
    let parameters = SPL.getBaseParameters();
    parameters['type'] = 'tenderControlTransaction';
    parameters['transactionID'] = transactionID;
    parameters['addedUnixTime'] = addedUnixTime;
    SPL.apiRequest(parameters, 'Could not send POSLog!', function (data) {

    });
};


SPL.POSEvents = {
    afterDocumentSave: function (data) {
        SPL.log('afterDocumentSave', data);
        if(data.invoiceID === ''){
            SPL.delayed = true;
        }
    },
    afterOpenDay: function (dayID, cashInSum, openedUnixTime, employeeID) {
        SPL.log('afterOpenDay');
        SPL.dayClosed = false;
        SPL.employeeLoggedIn = true;
        SPL.sendOperatorSignOn(dayID, cashInSum, openedUnixTime, employeeID);
    },
    afterCloseDay: function (dayID, employeeID) {
        SPL.log('afterCloseDay');
        SPL.dayClosed = true;
        SPL.sendOperatorSignOff(dayID, employeeID);
    }
};

export default SPL;