import React, {useState} from "react";
import Translate from "localization/Translate";
import Button from "../../buttons/Button";
import InputWithKeyboard from "../../formElements/InputWithKeyboard";
import {useActionListener} from "redux-action-listener-hook";

export default function (props) {
    const [inputValue, setInputValue] = useState('');

    useActionListener('SCANNER_DETECTED', (dispatch, action) => {
        props.onInputDone(action.payload);
        setInputValue('');
    });

    let backButton;
    if(props.onBack !== undefined){
        backButton = (
            <div onClick={props.onBack} style={{marginTop: '10px', marginBottom: '10px', float: 'left'}}>
                <Button type="button" className={"largeButton grayButton"} name={<Translate id="cancel"/>}
                        arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
            </div>
        );
    }

    return (
        <div>
            <div style={{
                padding: "80px 10px 10px",
                width: "80%",
                margin: "0 auto",
                textAlign: "center"
            }}>
                <span className={"boldUppercaseText"} style={{
                    fontSize: 24
                }}>
                    { <Translate id={"Please type your promo code"}/> }
                </span>
                <br/>
                <InputWithKeyboard
                    inputClass={""}
                    inputStyle={{
                        boxSizing: "border-box",
                        width: "100%"
                    }}
                    onInputChange={setInputValue}
                    initialInputValue={inputValue}
                    onDone={props.onInputDone}
                    controlled={true}
                    placeholder={"Scan or type code"}
                />
                <div style={{clear: "both"}}> </div>
                { backButton }
                <div onClick={() => {
                    props.onInputDone(inputValue);
                }} style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    float: 'right'
                }}>
                    <Button
                        type="button"
                        className={"largeButton fullWidth blueButton"}
                        name={<Translate id="Apply"/>}
                        arrow={'right'}
                        style={{
                            minWidth: '150px'
                        }}
                    />
                </div>
                <div style={{
                    clear: "both",
                    fontSize: "16px",
                    textAlign: "left",
                    fontWeight: "bold",
                    paddingTop: "10px"
                }}>
                    <p>
                        <Translate id={"Key Information:"}/>
                    </p>
                    <p>
                        <Translate id={"Promotions are not accumulative, the system will apply per item the most beneficial discount for the customer."}/>
                    </p>
                    <p>
                        <Translate id={"If an item already had a promotion applied it will only change by the promo code if it's better than the already existing one"}/>
                    </p>
                </div>
            </div>
        </div>
    );
}