import {API, AUTH_API, CAFA_API, POSLOG_API} from "../actionTypes";
import {apiStart, closeQCO, addErrorAlert, setUserRights} from "../actions";
import $ from "jquery"
import {displayNetPrices, getProductCategoryByID, getProductsTotalSum, getVatRateByID} from "../selectors";
import {getTranslate} from "localization/localizeSlice";
import CTR from "../../util/receiptTemplate";
import {APP_URL, DKT_SSO_URL, ERPLY_API_PARTNER_KEY} from "../appConstants";
import {calculateNetPriceFromTotal, roundDown, roundedPrice} from "../../util/helperFunctions";
import Decimal from "decimal.js";
import {createCompleteTransactionRequests, saveSale} from "./api/completeTransaction";
import SPL from "../poslog/POSLogService";
import yocuda from "../integrations/yocuda/main";

const numberOfRequestsInBulk = 100;

const apiMiddleware = (store) => next => action => {
    next(action);

    let dispatch = store.dispatch;
    let state = store.getState();
    let translate = getTranslate(state);
    let decathlonSSO = window.AppConf?.decathlonSSO || false

    if (action.type === CAFA_API){
        let endpoint = action.payload?.endpoint ?? 'configuration';
        cafaRequest(action.payload.method, endpoint, action.payload.parameters, action.payload.onSuccess, action.payload.onFailure, store);
        return;
    }
    if (action.type === AUTH_API){
        authApiRequest(action.payload.method, action.payload.headers, action.payload.parameters, action.payload.onSuccess, action.payload.onFailure, store);
    }
    if(action.type === POSLOG_API){
        poslogApiRequest(action.payload);
    }
    if (action.type === "CLOSE_QCO"){
        if(decathlonSSO){
            oAuthLogout(store, state);
        }
    }
    if (action.type !== API) return;


    let {
        data,
        onSuccess,
        onFailure,
        label,
        successActions
    } = action.payload;

    if (data.request === 'sendByEmail'){
        if(window.AppConf.enableYocuda){
            yocudaRequest(dispatch, state, action, translate);
            return;
        }
        if(window.AppConf.printDecathlonReceipt){
            CTR.handleSendEmail(data, action.payload.document, state).then((response) => {
                dispatch(onSuccess(response));
            }, (errorMessage) => {
                dispatch(onFailure(errorMessage));
            });
            return;
        }
    }else if(data.request === 'closeApp'){
        closeAppRequest(store);
        return;
    }else if(data.request === 'oAuthLogin'){
        oAuthLogin(store, data, onSuccess, onFailure);
        return;
    }else if(data.request === 'switchUser'){
        if(decathlonSSO){
            oAuthSwitchUser(store, data, onSuccess, onFailure);
            return;
        }
    }else if(data.request === 'completeTransaction'){
        data = createCompleteTransactionRequests(state, data.receipt);
    }else if(data.request === 'saveSale'){
        data = saveSale(state);
    }else if(data.request === 'getUserRights'){
        if(state.rootReducer.userRights !== false){
            dispatch(onSuccess(state.rootReducer.userRights));
            return;
        }
        data = Object.assign({}, data);
        data.getAllPages = true;
        delete data.userID;
        let originalOnSuccess = onSuccess;
        onSuccess = (records) => {
            dispatch(setUserRights(records));
            return originalOnSuccess(records);
        }
    }else if(data.request === 'applyPromotions' && !state.rootReducer.connectionHealth) {
        offlineApplyPromotions(state, (records) => {
            dispatch(onSuccess(records));
        });
        return;
    }

    if (label) {
        dispatch(apiStart(label));
        /*if(label === 'completeTransaction'){
            if(retryCompleteTransaction < 3){
                retryCompleteTransaction++;
                dispatch(onFailure('testing retry complete transaction!'));
                return;
            }
        }*/
    }

    apiRequest(data, function (records) {
        dispatch(onSuccess(records));
        if(successActions){
            $(successActions).each(function (index, successAction) {
                dispatch(successAction(records));
            });
        }
    }, function (errorMessage, errorCode = 1000) {
        if(errorCode === 1054){
            dispatch(closeQCO());
            dispatch(addErrorAlert('Session has expired, please log in again!'));
        }else if(errorCode === 1065 && data.request !== 'switchUser'){
            setTimeout(() => {
                dispatch(closeQCO());
            }, 3000);
            dispatch(onFailure(errorMessage));
        }else if(onFailure !== undefined){
            dispatch(onFailure(errorMessage));
        }
    }, state, translate);

};

const apiRequest = function (parameters, onSuccess, onFail, state, translate) {
    const clientCode = parameters?.clientCode ?? state.rootReducer.user.clientCode;
    if (parameters.request === 'getProducts' && state.rootReducer.transactionMode === "RETURN"){
        delete parameters.active;
    }
    if (parameters.request === 'getProducts' &&
        (
            typeof parameters.searchNameIncrementally !== "undefined" ||
            typeof parameters.code !== "undefined"
        )
    ){
        if(parameters.skipOfflineSearch !== true){
            console.log('ODS: getting from ODS');
            getProductFromOfflineDB(parameters, onSuccess, onFail, state, translate);
            return true;
        }
    }
    if(parameters.getAllPages){
        delete parameters.getAllPages;
        apiRequestAllPages(parameters, onSuccess, onFail, state, translate);
        return true;
    }

    if(window.AppConf?.decathlonSSO || window.AppConf?.decathlonQRCodeLogin){
        if(state.rootReducer.user !== false){
            parameters.sessionKey = state.rootReducer.user.sessionKey;
        }
    }

    let finalParams = {};
    let url = "";
    if(typeof window.AppConf?.ErplyAPI?.url !== "undefined" && window.AppConf.ErplyAPI.url.indexOf('localhost:') !== -1){
        finalParams = {
            erplyAPI: JSON.stringify({
                ...parameters,
                partnerKey: ERPLY_API_PARTNER_KEY
            })
        };
        url = APP_URL + 'erplyAPI';
    }else{
        if(Array.isArray(parameters)){
            if(parameters.length > numberOfRequestsInBulk){
                bulkApiRequestNext(parameters, onSuccess, onFail, state, translate);
                return;
            }
            finalParams = {
                requests: JSON.stringify(parameters)
            };
        }else{
            finalParams = {
                ...parameters
            };
        }
        finalParams.partnerKey = ERPLY_API_PARTNER_KEY;
        url = "https://" + clientCode + ".erply.com/api/";
    }

    if(state.rootReducer.user !== false){
        if(typeof finalParams.sessionKey === "undefined"){
            finalParams.sessionKey = state.rootReducer.user.sessionKey;
        }
        finalParams.clientCode = state.rootReducer.user.clientCode;
    }

    $.ajax({
        url,
        type: "POST",
        data: finalParams,
        timeout: 60000,
        success: function(response){
            let result;
            if(typeof response === 'object'){
                result = response;
            }else{
                try {
                    result = JSON.parse(response);
                }catch (e) {
                    console.log('Could not parse: ', response);
                    result = {
                        status: {
                            responseStatus: "error",
                            errorCode: 102
                        }
                    }
                }
            }
            if(result.status.responseStatus === 'ok'){
                let records = typeof result.records !== 'undefined' ? result.records : result.requests;
                onSuccess(records);
            }else{
                if(parameters.request === 'applyPromotions') {
                    offlineApplyPromotions(state, onSuccess);
                }else{
                    let errorMessage = errorCodes[result.status.errorCode] || 'No response';
                    onFail(translate(errorMessage), result.status.errorCode);
                }
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            console.log('API request failed!', textStatus, errorThrown);
            if(parameters.request === 'applyPromotions'){
                offlineApplyPromotions(state, onSuccess);
            }else{
                onFail('No response from Erply API!', 1000);
            }
        }
    });
};

const apiRequestAllPages = function (params, onSuccess, onFail, state, translate) {
    params['recordsOnPage'] = 100;
    if(params.request === "getProducts")
    {
        if(typeof params['getStockInfo'] == 'undefined') params['recordsOnPage'] = 1000;
    }
    params['pageNo'] = 1;
    const res = [];
    apiRequestAllPagesNext(params, onSuccess, onFail, state, translate, res);
};

const apiRequestAllPagesNext = function (params, onSuccess, onFail, state, translate, res) {
    apiRequest(params, function (records) {
        $(records).each(function (index, record) {
            res.push(record);
        });
        if (records.length < params['recordsOnPage']) {
            onSuccess(res);
        }else{
            params['pageNo'] += 1;
            apiRequestAllPagesNext(params, onSuccess, onFail, state, translate, res);
        }
    }, onFail, state, translate);
};

const bulkApiRequestNext = function (requests, onSuccess, onFail, state, translate, res=[]) {
    let nextRequests = requests.splice(0, numberOfRequestsInBulk);

    apiRequest(nextRequests, function (records) {
        $(records).each(function (index, record) {
            res.push(record);
        });
        if (requests.length === 0) {
            onSuccess(res);
        }else{
            bulkApiRequestNext(requests, onSuccess, onFail, state, translate, res);
        }
    }, onFail, state, translate);
};

const getProductFromOfflineDB = function (parameters, onSuccess, onFail, state, translate) {
    let requests = [];
    if(typeof parameters.code !== "undefined"){
        requests.push({name: 'getByCode', parameters: {code: parameters.code}});
    }else{
        requests.push({name: 'full-text-search', parameters: {lookupPhrase: parameters.searchNameIncrementally}});
    }
    function getByCode () {
        if(requests.length === 0){
            parameters.skipOfflineSearch = true;
            apiRequest(parameters, onSuccess, onFail, state, translate);
            return true;
        }
        let request = requests.pop();
        offlineProductDatabaseRequest(request.name, request.parameters, function (data) {
            if(data.length === 0){
                getByCode();
            }else{
                onSuccess(data);
            }
        }, function () {
            parameters.skipOfflineSearch = true;
            apiRequest(parameters, onSuccess, onFail, state, translate);
        }, state);
    }

    getByCode();
};

const offlineProductDatabaseRequest = function (request, parameters, onSuccess, onFail, state) {
    if(window.AppConf.ODS === undefined || window.AppConf.ODS.url === undefined || window.AppConf.ODS.url === ""){
        onFail();
        return false;
    }
    $.ajax({
        url: window.AppConf.ODS.url + "/product/" + request,
        method: "GET",
        data: parameters,
        timeout: 500,
        success: function(data){
            console.log('ErplyDB.query Found from local db: ', data);
            if(data === null) data = [];

            data = data.filter((product) => {
                if(typeof product.status === "undefined") return true;
                return product.status !== 'ARCHIVED';
            });
            data.map(function (product) {
                if(typeof product.vatrateID === "undefined") product.vatrateID = product.vatRateID;
                let vatRate = getVatRateByID(state.rootReducer.vatRates, product.vatrateID);
                if(typeof product.price === "undefined"){
                    product.price = product.priceListPrice;
                }
                product.vatrate = vatRate.rate;

                if(typeof product.seriesName === "undefined" && typeof product.categoryID !== "undefined"){
                    const productCategory = getProductCategoryByID(state.rootReducer.productCategories, product.categoryID);
                    if(typeof productCategory !== "undefined"){
                        product.seriesName = productCategory.productCategoryName;
                    }
                }
                product.seriesID = product.categoryID;

                product.priceListPrice = product.price;
                product.priceListPriceWithVat = parseFloat((product.price * (1 + product.vatrate/100)).toFixed(4));
                product.priceWithVat = product.priceListPriceWithVat;
                return product;
            });
            onSuccess(data);
        },
        error: function(){
            onFail();
        }
    });
};

const cafaRequest = function (method, endpoint, parameters, onSuccess, onFail, store) {
    let state = store.getState();
    let customerCode = state.rootReducer.user.clientCode;
    let headers = {
        clientCode: customerCode,
        sessionKey: state.rootReducer.user.sessionKey
    };

    let application = 'self_checkout_pos';
    if(parameters.level === 'Pos'){
        parameters.level_id = state.rootReducer.posID.toString();
    }
    let url = state.rootReducer.erplyServiceEndpoints.cafa.url + endpoint;

    if(parameters.getAll){
        url += "/" + application;
    }else{
        parameters.application = application;
    }

    let options = {
        method,
        headers: headers
    };

    if(method === 'GET'){
        if(!parameters.getAll) {
            url += '?' + Object.keys(parameters).reduce((acc, key) => {
                if (acc !== '') {
                    acc += '&';
                }
                acc += key + '=' + encodeURIComponent(parameters[key]);
                return acc;
            }, '');
        }
    }else{
        options.body = JSON.stringify(parameters);
    }

    if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
        let proxyUrl = method === 'GET' ? 'proxy' : 'postProxy';
        url = APP_URL + proxyUrl + '?u=' + btoa(url);
    }

    fetch(url, options).then(response => {
        return response.json();
    }).then((response) => {
        if(parameters.getAll){
            store.dispatch(onSuccess(response));
        }else{
            if(typeof response[0] !== "undefined"){
                store.dispatch(onSuccess(response[0].value));
            }else{
                store.dispatch(onSuccess(false));
            }
        }
    }).catch((data) => {
        console.log(data);
        store.dispatch(addErrorAlert('No response from CAFA API!'));
        onFail('No response from CAFA API!')
    });
};


const authApiRequest = function (method, headers, parameters, onSuccess, onFail, store) {
    let state = store.getState();
    if(typeof headers.clientCode === "undefined"){
        headers.clientCode = state.rootReducer?.user?.clientCode || window.AppConf?.clientCode || false;
    }
    let url;
    if(typeof headers.region !== "undefined"){
        url = "https://api-auth-" + headers.region + ".erply.com/";
        delete headers.region;
    }else{
        url = state.rootReducer?.erplyServiceEndpoints?.auth?.url || false;
    }
    if(headers.clientCode === false || url === false){
        onFail("Auth API not configured!", 0);
        return;
    }

    url += 'v1/integrations/session';
    let options = {
        method,
        headers
    };

    if(method === 'GET'){
        url += '?' + Object.keys(parameters).reduce((acc, key) => {
            if(acc !== ''){
                acc += '&';
            }
            acc += key + '=' + encodeURIComponent(parameters[key]);
            return acc;
        }, '');
    }else{
        options.body = JSON.stringify(parameters);
    }

    if(process.env.REACT_APP_USE_LOCAL_PROXY === "1"){
        let proxyUrl = method === 'GET' ? 'proxy' : 'postProxy';
        url = APP_URL + proxyUrl + '?u=' + btoa(url);
    }

    fetch(url, options).then(response => {
        return response.json();
    }).then((response) => {
        if(response.status.responseStatus === 'error'){
            store.dispatch(onFail(response.status.errorDescription, response.status.errorCode));
        }else{
            store.dispatch(onSuccess(response.data));
        }
    }).catch((data) => {
        console.log(data);
        store.dispatch(addErrorAlert('No response from AUTH API!'));
        onFail('No response from AUTH API!', 0)
    });
};

const poslogApiRequest = (payload) => {
    SPL.sendApiRequest(payload.endpoint, payload.type, payload.data, payload.onSuccess, payload.onFailure);
}

const closeAppRequest = function (store) {
    fetch(APP_URL + 'exit').then((response) => {
        console.log('exit message', response);
        if(response.status !== 200){
            store.dispatch(addErrorAlert('Can\'t exit application!'));
        }
    }).catch((data) => {
        store.dispatch(addErrorAlert('Can\'t exit application!'));
    });
};

const oAuthLogin = function (store, data, onSuccess, onFailure) {
    fetch(DKT_SSO_URL + 'getToken?code=' + data.authCode + '&redirectUri=' + encodeURIComponent(window.location.origin)).then((response) => {
        response.json().then((response) => {
            console.log('oAuthLogin response', response);
            if(response.errorCode === 0){
                if(response.sessionInfo.signInFailed){
                    store.dispatch(onFailure(response.sessionInfo.message));
                    setTimeout(() => {
                        oAuthLogout(store, {
                            rootReducer: {
                                user:{
                                    oAuthToken: response.token
                                }
                            }
                        });
                    }, 3000);
                }else{
                    response.sessionInfo.oAuthToken = response.token;
                    store.dispatch(onSuccess(response.sessionInfo));
                }
            }else{
                store.dispatch(onFailure(response.errorMessage));
            }
        });
    }).catch((data) => {
        store.dispatch(onFailure('Can\'t login!'));
    });
}

const oAuthLogout = function (store, state) {
    let body = JSON.stringify(state.rootReducer.user.oAuthToken);
    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body
    };
    console.log('oAuthLogout request', options);
    fetch(DKT_SSO_URL + 'revokeToken', options).then((response) => {
        response.json().then((response) => {
            console.log('oAuthLogout response', response);
            if(response.result){
                window.location.href = window.location.origin;
            }
        });
    }).catch((data) => {});
}

const oAuthSwitchUser = (store, data, onSuccess, onFailure) => {
    let state = store.getState();
    let customerCode = state.rootReducer.user.clientCode;
    let headers = {
        clientCode: customerCode,
        sessionKey: state.rootReducer.user.sessionKey
    };

    let options = {
        method: 'POST',
        headers
    };
    options.body = JSON.stringify({
        sessionLength: data.sessionLength,
        userPin: data.cardCode
    });

    let url = APP_URL + 'postProxy?u=' + btoa(state.rootReducer.erplyServiceEndpoints.auth.url + 'v1/switch-session');

    fetch(url, options).then(response => {
        return response.json();
    }).then((response) => {
        console.log('switch-session', response);
        if(response.data !== null){
            let data = [
                {
                    userID: response.data.session.user.id,
                    employeeID: response.data.session.user.employeeId
                }
            ]
            store.dispatch(onSuccess(data));
        }else{
            store.dispatch(onFailure('Wrong PIN'));
        }
    }).catch((data) => {
        console.log(data);
        store.dispatch(onFailure('Can\'t verify user PIN)'));
    });
}

const yocudaRequest = (dispatch, state, action, translate) => {
    let {
        data,
        onSuccess,
        onFailure,
        document
    } = action.payload;

    if(document !== false){
        apiRequest({
            request: "getSalesDocuments",
            id: data.id
        }, (records) => {
            if(records.length > 0){
                apiRequest({
                    request: "getPayments",
                    documentID: data.id
                }, (payments) => {
                    let documentWithRows = records[0];
                    documentWithRows.payments = payments;
                    yocuda.sendDuplicateReceipt(documentWithRows, data.email, state).then(() => {
                        dispatch(onSuccess());
                    }, (error) => {
                        setTimeout(() => {
                            dispatch(addErrorAlert("Yocuda error: " + error));
                        }, 1500);
                        dispatch(onFailure("Yocuda API error"));
                    });
                }, (message, code) => {
                    dispatch(onFailure(message));
                }, state, translate);
            }else{
                dispatch(onFailure("Document not found"));
            }
        }, (message, code) => {
            dispatch(onFailure(message));
        }, state, translate);
    }else{
        dispatch(onSuccess({}));
    }
}

//TODO: check compliance with conf rounding decimals
const offlineApplyPromotions = (state, onSuccess) => {
    const useNetPrices = displayNetPrices(state.rootReducer.erplyConf);
    const invoiceAlgorithmVersion = state.rootReducer.erplyConf.invoice_algorithm_version;
    const record = {
        netTotal: 0,
        vatTotal: 0,
        total: 0,
        rows: []
    };
    state.rootReducer.productsInBasket.forEach((product, index) => {
        const row = {};
        row.rowNumber = index;
        row.productID = product.data.productID;
        row.amount = product.amount;
        row.vatrateID = product.data.vatrateID;
        row.vatRate = product.data.vatrate;
        row.originalPrice = parseFloat(product.data.priceListPrice) === 0 ? product.netPrice : product.data.priceListPrice;
        row.originalPriceWithVAT = parseFloat(product.data.priceListPriceWithVat) === 0 ? product.vatPrice : product.data.priceListPriceWithVat;
        row.manualDiscountPrice = 0;
        row.manualDiscountPriceWithVAT = 0;
        row.promotionPrice = row.originalPrice;
        row.promotionPriceWithVAT = row.originalPriceWithVAT;
        row.promotionDiscount = 0;
        row.originalDiscount = 0;
        row.manualDiscount = 0;
        row.discount = 0;
        row.finalPrice = row.originalPrice;
        row.finalPriceWithVAT = row.originalPriceWithVAT;
        row.rowNetTotal = calculateRowTotal(row.finalPrice, row.amount);
        row.rowTotal = calculateRowTotal(row.finalPriceWithVAT, row.amount);
        row.rowVAT = parseFloat(row.rowTotal - row.rowNetTotal);
        row.priceBasedTaxThreshold = 0;
        row.priceBasedTaxRate = 0;

        if(product.manualPrice) {
            const originalPrice = useNetPrices ? row.promotionPrice : row.promotionPriceWithVAT;
            const discount = 100 - 100 * parseFloat(useNetPrices ? product.netPrice : product.vatPrice) / parseFloat(originalPrice);
            row.promotionPrice = calculateDiscount(row.promotionPrice, discount, useNetPrices);
            row.promotionPriceWithVAT = calculateDiscount(row.promotionPriceWithVAT, discount, useNetPrices);
            row.finalPrice = row.promotionPrice;
            row.finalPriceWithVAT = row.promotionPriceWithVAT;
            row.rowNetTotal = Math.round(row.promotionPrice * row.amount * 10000) / 10000;
            row.rowVAT = parseFloat(row.rowNetTotal * row.vatRate / 100);
            row.rowTotal = (row.rowNetTotal + row.rowVAT).toFixed(2);
        }

        if(product.manualDiscountPercentage > 0) {
            row.manualDiscount = product.manualDiscountPercentage;
        }
        if(row.manualDiscount > 0) {
            row.discount = product.manualDiscountPercentage;
            row.originalDiscount = product.manualDiscountPercentage;
            row.manualDiscountPrice = calculateDiscount(product.originalPrice, product.manualDiscountPercentage, useNetPrices);
            row.manualDiscountPriceWithVAT = calculateDiscount(product.originalPriceWithVAT, product.manualDiscountPercentage, useNetPrices);
            row.promotionPrice = calculateDiscount(product.promotionPrice, product.manualDiscountPercentage, useNetPrices);
            row.promotionPriceWithVAT = calculateDiscount(product.promotionPriceWithVAT, product.manualDiscountPercentage, useNetPrices);
            row.finalPrice = row.manualDiscountPrice;
            row.finalPriceWithVAT = row.manualDiscountPriceWithVAT;
            row.rowNetTotal = Math.round(row.manualDiscountPrice * row.amount * 100) / 100;
            row.rowVAT = parseFloat(row.rowNetTotal * row.vatRate / 100);
            row.rowTotal = row.rowNetTotal + row.rowVAT;
        }

        record.netTotal += row.rowNetTotal;
        record.vatTotal += row.rowVAT;
        record.total += row.rowTotal;
        record.rows.push(row);
    });
    record.rounding = parseFloat(record.total - record.vatTotal - record.netTotal);
    record.usedCouponIdentifiers = "";
    record.appliedPromotions = [];
    record.automaticCoupons = "";
    record.printAutomaticCoupons = [];
    record.automaticCoupons = "";
    record.information = "";
    if(invoiceAlgorithmVersion === 5){
        record.rows = applyLargestRemainderRounding(record, state.rootReducer.vatRates);
    }

    console.log("Offline applyPromotions record", {record});
    onSuccess([record]);
}

const calculateDiscount = (price, discount, useNetPrices) => {
    const discountPrice = parseFloat(price) - parseFloat(price) * parseFloat(discount) / 100;
    return !useNetPrices ? discountPrice : Math.round(10000 * discountPrice) / 10000;
}

const calculateRowTotal = (price, amount) => {
    return parseFloat(price * amount);
}

export const applyLargestRemainderRounding = ({rows, total}, vatrates) => {
    const decimals = window?.AppConf?.priceDecimals ?? 2;
    //const roundingDecimals = 6;
    let rowsByVatrateID = rows.reduce((acc, row) => {
        if(typeof acc[row.vatrateID] === "undefined"){
            acc[row.vatrateID] = [];
        }
        acc[row.vatrateID].push(row);
        return acc;
    }, {});
    for(let vatrateID in rowsByVatrateID){
        let vatrate = getVatRateByID(vatrates, vatrateID);
        let rowsByTax = rowsByVatrateID[vatrateID];
        let roundedTotal = roundedPrice(getProductsTotalSum(rowsByTax));
        let nonRoundedNetTotal = calculateNetPriceFromTotal(roundedTotal, vatrate.rate);
        //let nonRoundedVatTotal = roundedTotal - nonRoundedNetTotal;
        let roundedNetTotal = nonRoundedNetTotal.toFixed(decimals);
        //let roundedVatTotal = (roundedTotal - roundedNetTotal).toFixed(decimals);
        //let rounding = (nonRoundedVatTotal - roundedVatTotal).toFixed(roundingDecimals);

        let roundedDownNetTotal = 0;
        let remainders = [];
        rowsByTax.forEach((row, index) => {
            let nonRoundedNet = calculateNetPriceFromTotal(row.rowTotal, vatrate.rate);
            //let nonRoundedVat = (row.rowTotal - nonRoundedNet).toFixed(roundingDecimals);
            let roundedDownNet = roundDown(nonRoundedNet, decimals);
            let remainder = nonRoundedNet - roundedDownNet;
            console.log({nonRoundedNet, roundedDownNet, remainder});
            remainders.push({
                index,
                remainder,
                roundedDownNet
            });
            roundedDownNetTotal += roundedDownNet;
            row.rowTotal = new Decimal(row.rowTotal).toDecimalPlaces(decimals).toNumber();
        });
        remainders.sort((a, b) => {
            let aAbs = Math.abs(a.remainder);
            let bAbs = Math.abs(b.remainder);
            if(aAbs > bAbs){
                return 1;
            }
            if(aAbs < bAbs){
                return -1;
            }
            return a.index < b.index ? 1 : -1;
        });
        let totalRemainder = (roundedNetTotal - roundedDownNetTotal).toFixed(decimals);
        console.log({totalRemainder, roundedTotal, nonRoundedNetTotal, roundedNetTotal, roundedDownNetTotal})
        let positiveTotal = totalRemainder >= 0;
        let step = 1 / Math.pow(10, decimals);
        while(remainders.length > 0){
            let remainder = remainders.pop();
            let row = rowsByTax[remainder.index];
            let positiveRow = parseFloat(row.amount) >= 0;
            if(positiveTotal){
                if(positiveRow && totalRemainder > 0){
                    row.rowNetTotal = remainder.roundedDownNet + step;
                    totalRemainder -= step;
                }else{
                    row.rowNetTotal = remainder.roundedDownNet;
                }
            }else{
                if(!positiveRow && totalRemainder < 0){
                    row.rowNetTotal = remainder.roundedDownNet - step;
                    totalRemainder += step;
                }else{
                    row.rowNetTotal = remainder.roundedDownNet;
                }
            }

            row.rowVAT = new Decimal(row.rowTotal - row.rowNetTotal).toDecimalPlaces(decimals).toNumber();
        }
    }
    return rows;
}

const errorCodes = {
    50: 'Invalid parameters format. Must be JSON string',
    101: 'API communication timed out, please try again',
    102: 'API communication error, please try again',
    103: 'API is not responding or invalid client code',
    1000: 'API is in maintenance, try again in a couple of minutes',
    1001: 'Access has not been set up for this account',
    1002: 'Number of allowed requests exceeded for this account',
    1003: 'Cannot access system',
    1004: 'API version number not specified',
    1005: 'Unknown function name',
    1006: 'Function not implemented yet',
    1007: 'Unknown format requested',
    1009: 'This API call requires authentication parameters, but none were found.',
    1010: 'Required parameters are missing',
    1011: 'Invalid classifier ID, there is no such item',
    1012: 'A parameter must have a unique value',
    1013: 'Inconsistent parameter set',
    1014: 'Incorrect data type or format',
    1015: 'Malformed request',
    1016: 'Invalid value',
    1017: 'Document has been confirmed and its contents and warehouse ID cannot be edited any more',
    1040: 'Invalid coupon identifier - such coupon has not been issued',
    1041: 'Invalid coupon identifier - this coupon has already been redeemed',
    1043: 'Employee already has an appointment on that time slot. Choose a different start and end time for appointment',
    1044: 'Default length for this service has not been defined in Erply backend - cannot suggest possible time slots',
    1050: 'Username/password missing',
    1051: 'Username or password incorrect',
    1052: 'User has been temporarily blocked because of unsuccessful login attempts',
    1053: 'Login has not been enabled for this user',
    1054: 'API session has expired. Call function verifyUser() again (with correct credentials) to receive a new session key',
    1055: 'Session not found',
    1057: 'Demo account has expired',
    1060: 'No viewing rights (in this module/for this item)',
    1061: 'No adding rights (in this module)',
    1062: 'No editing rights (in this module/for this item)',
    1063: 'No deleting rights (in this module/for this item).',
    1064: 'User does not have access to this location (store, warehouse).',
    1065: 'This user account does not have API access. (It may be limited to POS or Erply backend operations only.)',
    1066: 'This user does not have the right to manage specified user group. (Error may occur when attempting to create a new user, or modify an existing one.)',
    1082: 'E-mail sending has been incorrectly set up, review settings in ERPLY',
    1146: 'You do not have an employee record. Please ask a manager to create an employee record for you.',
    1147: 'You have already confirmed your compliance with the General Data Protection Regulation.',
    1148: 'You do not have access to customer data. Please contact your manager.',
    1149: 'Your account country is a non-EU country and the GDPR customer data processing log is not available.'
};

export default apiMiddleware;
