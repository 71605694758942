import React, {Component} from "react"
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import paymentDoneErplyImg from "../images/Erply/paymentDone.png";
import checkMarkErplyImg from "../images/Erply/checkMark.png";
import greenCheckMarkErplyImg from "../images/Erply/greenCheckMark.png";
import {connect} from "react-redux";
import {changeMode, initTransaction, printReceipt, sendEmailReceipt} from "../../redux/actions";
import {APP_MODE_SCREEN_SAVER} from "../../redux/appConstants";
import {getCashChange} from "../../redux/payment/selectors";
import {EMAIL_NOT_SENT, EMAIL_SENT, RECEIPT_DELIVERED} from "../../redux/actionTypes";
import AskReceipt from "./AskReceipt";
import EnterEmail from "./EnterEmail";
import CheckBoxErply from "../../formElements/CheckBoxErply";
import Button from "../../buttons/Button";
import Keyboard from "../../keyboard/Keyboard";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from 'uiComponents/WrappedPerfectScrollBar.js';
import CountDown from "../../uiComponents/CountDown";
import Image from "../../theme/Image";

const mapStateToProps = state => {
    let change = getCashChange(state.payment.payments);
    let showPrintingDialog = window.AppConf.showPrintingDialog ?? true;
    let alwaysPrintReceipt = window.AppConf.alwaysPrintReceipt ?? false;
    let printReceiptOnTimeout = window.AppConf.printReceiptOnTimeout ?? false;
    if(!printReceiptOnTimeout){
        let dayNumber = (new Date()).getDay();
        printReceiptOnTimeout = window.AppConf.printerTimeoutPrintDays[dayNumber] === 1;
    }
    let startingView = showPrintingDialog ? 'sendEmail' : 'takeProducts';

    return {
        lastInvoice: state.rootReducer.lastInvoice,
        invoice: state.rootReducer.invoice,
        currency: state.rootReducer.currency,
        change,
        startingView,
        showPrintingDialog,
        alwaysPrintReceipt,
        printReceiptOnTimeout,
        translate: getTranslate(state),
        customer: state.rootReducer.customer,
        delayReceipt: state.rootReducer.delayReceipt,
        theme: state.rootReducer.theme,
        decathlonTheme: state.rootReducer.decathlonTheme,
        printDefault: state.rootReducer.uiCustomizationPOS.printing.printDefault,
        cashierMode: state.rootReducer.cashierMode
    }
};

const mapDispatchToProps = dispatch => {
    return {
        initDocument: () => {
            dispatch(initTransaction());
            dispatch(changeMode(APP_MODE_SCREEN_SAVER));
        },
        sendEmail: (email, invoiceID) => {
            dispatch(sendEmailReceipt(invoiceID, email, function () {
                return {
                    type: EMAIL_SENT
                }
            }, function () {
                return {
                    type: EMAIL_NOT_SENT
                }
            }));
        },
        printReceipt: (lastInvoice) => {
            dispatch(printReceipt(lastInvoice.receiptLink));
        },
        printInvoice: (lastInvoice) => {
            dispatch(printReceipt(lastInvoice.invoiceLink));
        },
        receiptDelivered: (type) => {
            dispatch({
                type: RECEIPT_DELIVERED,
                payload: {
                    deliveryType: type
                }
            });
        }
    }
};

class Done extends Component {
    constructor(props) {
        super(props);

        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.processReceipt = this.processReceipt.bind(this);

        this.state = {
            view: props.startingView,
            keyboardLayout: 'letters',
            keyboardActive: false,
            printReceipt: props.printDefault
        };
    }

    componentDidMount() {
        console.log('DONE: ', this.props.showPrintingDialog);
        if(!this.props.showPrintingDialog){
            this.initTransaction();
        }
    }

    componentWillUnmount() {
        const component = this;
        component.setState({
            view: this.props.startingView
        });
    }

    initTransaction() {
        if(this.props.alwaysPrintReceipt){
            setTimeout(() => {
                this.props.printReceipt(this.props.lastInvoice);
            }, 1);
        }

        const component = this;

        if(this.props.cashierMode){
            setTimeout(function () {
                component.props.initDocument();
            }, 1);
            return;
        }

        setTimeout(function () {
            component.setState({
                view: 'thankVisit'
            });
            setTimeout(function () {
                component.props.initDocument();
            }, 2000);
        }, 4000);
    }

    processReceipt(receiptDelivery) {
        this.setState({
            view: 'takeProducts'
        });
        this.props.receiptDelivered(receiptDelivery);
        this.initTransaction();
    }

    render() {
        let display;
        if(this.state.view === 'sendEmail'){
            if(this.props.delayReceipt){
                return <div/>;
            }

            if(this.props.theme === 'Erply'){
                let maxHeight = this.state.keyboardActive ? window.innerHeight - 65 - 216 : window.innerHeight - 65;
                if(this.state.keyboardActive){
                    setTimeout(() => {
                        let emailEl = document.getElementsByName("emailInput");
                        if(emailEl.length > 0){
                            document.getElementsByName("emailInput")[0].scrollIntoView(false);
                        }
                    },1);
                }

                let timeoutCountDown;

                if(!this.state.keyboardActive){
                    timeoutCountDown = <CountDown
                        style={{
                            position: 'fixed',
                            right: '30px',
                            top: '90px',
                            border: '4px solid #c3c3c3',
                            color: '#c3c3c3',
                            borderRadius: '50%',
                            width: '150px',
                            height: '150px',
                            fontSize: '100px',
                            fontWeight: 'bold',
                            lineHeight: 1.5,
                            textAlign: 'center'
                        }}
                        onDone={() => {
                            if(this.state.printReceipt){
                                if(this.props.invoice !== false){
                                    this.props.printInvoice(this.props.invoice);
                                }else{
                                    this.props.printReceipt(this.props.lastInvoice);
                                }
                            }
                            this.processReceipt({type: this.state.printReceipt ? 'print' : 'none'});
                        }}
                        timeInSeconds={window.AppConf.receiptTimeout || 10}
                    />;
                }

                return (
                    <div>
                        { timeoutCountDown }
                        <PerfectScrollbar
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                height: maxHeight
                            }}
                            containerRef={(ref) => {
                                this._scrollRef = ref;
                            }}
                        >
                            <img
                                src={greenCheckMarkErplyImg}
                                alt={'Check mark'}
                                style={{
                                    height: '100px',
                                    marginTop: '44px'
                                }}
                            />
                            <span
                                className={"boldText capitalizeFirst"}
                                style={{
                                    margin: '20px'
                                }}
                            >
                                <Translate id={"paymentMade"}/>
                            </span>
                            <span
                                style={{
                                    color: '#808080',
                                    fontSize: '24px',
                                    fontWeight: '600'
                                }}
                            >
                                <Translate id={"changeMoneySum"}/>: {this.props.currency}{this.props.change}
                            </span>
                            <div style={{
                                display: "inline-block",
                                width: "600px",
                                fontSize: "16px"
                            }}>
                                <label style={{
                                    float: "left",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    fontSize: "20px"
                                }}>
                                    <Translate id={"Send receipt to email"}/>
                                </label>
                                <input
                                    style={{
                                        float: "left",
                                        width: "calc(100% - 30px)",
                                        backgroundColor: '#F9F9F9',
                                        border: 'none',
                                        borderBottom: '4px solid #3D94F6',
                                        marginBottom: '20px'
                                    }}
                                    type={"text"}
                                    name={"emailInput"}
                                    placeholder={this.props.translate("E-MAIL")}
                                    autoComplete={'off'}
                                    onClick={() => {
                                        this.setState({
                                            keyboardActive: true
                                        });
                                        console.log({scrollRef: this._scrollRef});
                                    }}
                                />
                                <div style={{
                                    float: 'left',
                                    marginRight: '20px'
                                }}>
                                    <CheckBoxErply
                                        checked={this.state.printReceipt}
                                        name={'printReceiptCheck'}
                                        onChange={(checked) => {
                                            this.setState({
                                                printReceipt: checked
                                            });
                                        }}
                                    />
                                </div>
                                <label
                                    style={{
                                        float: "left",
                                        lineHeight: 1.5,
                                        fontSize: '20px'
                                    }}
                                >
                                    <Translate id={"Print receipt"}/>
                                </label>
                                <div style={{clear: 'both', height: '35px'}}/>
                                <span style={{
                                    float: "left",
                                    fontSize: "20px",
                                }}>
                                    <Translate id={"Invoice nr"}/> {this.props.lastInvoice.number}
                                </span>
                                <div onClick={() => {
                                    let receiptDelivery = {
                                        type: 'none'
                                    };
                                    const email = document.getElementsByName("emailInput")[0].value;
                                    if(email !== ""){
                                        this.props.sendEmail(email, this.props.lastInvoice.id);
                                        receiptDelivery.type = 'email';
                                        receiptDelivery.email = email;
                                    }
                                    if(this.state.printReceipt){
                                        if(this.props.invoice !== false){
                                            this.props.printInvoice(this.props.invoice);
                                        }else{
                                            this.props.printReceipt(this.props.lastInvoice);
                                        }
                                        if(receiptDelivery.type === 'none') {
                                            receiptDelivery.type = 'print';
                                        }
                                    }
                                    this.processReceipt(receiptDelivery);
                                }} style={{
                                    float: "right"
                                }}>
                                    <Button
                                        type="button"
                                        name={<Translate id="Ok"/>}
                                        className={"largerButton lightBlueButton roundButton uppercaseText"}
                                        arrow="rightClose"
                                    />
                                </div>
                            </div>
                        </PerfectScrollbar>
                        <div style={{
                            "position": "absolute",
                            "bottom": "0",
                            "left": "0",
                            "right": "0"
                        }}>
                            <Keyboard
                                options={{
                                    layout: this.state.keyboardLayout,
                                    inputFieldName: 'emailInput',
                                    onChangeToLetters: () => {this.setState({keyboardLayout: "letters"})},
                                    onChangeToNumbers: () => {this.setState({keyboardLayout: "numbers"})},
                                }}
                                active={this.state.keyboardActive}
                                setActive={(active) => {
                                    this.setState({
                                        keyboardActive: active
                                    });
                                }}
                            />
                        </div>
                    </div>
                );
            }else{
                return (
                    <AskReceipt
                        onEmail={() => {
                            let customerEmail = this.props.customer !== false && typeof this.props.customer.email !== "undefined" ? this.props.customer.email : false;
                            if(customerEmail !== false && customerEmail !== ''){
                                this.props.sendEmail(customerEmail, this.props.lastInvoice.id);
                                this.processReceipt({type: 'email', email: customerEmail});
                            }else{
                                this.setState({
                                    view: 'enterEmail'
                                });
                            }
                        }}
                        onPrint={() => {
                            if(!this.props.alwaysPrintReceipt){
                                this.props.printReceipt(this.props.lastInvoice);
                            }
                            this.processReceipt({type: 'print'});
                        }}
                        onDecline={() => {
                            this.processReceipt({type: 'none'});
                        }}
                        onTimeout={() => {
                            if(this.props.printReceiptOnTimeout && !this.props.alwaysPrintReceipt){
                                this.props.printReceipt(this.props.lastInvoice);
                            }
                            this.processReceipt({type: this.props.printReceiptOnTimeout || this.props.alwaysPrintReceipt ? 'print' : 'none'});
                        }}
                    />
                );
            }
        }else if(this.state.view === 'enterEmail'){
            return (
                <EnterEmail
                    onAccept={(email) => {
                        this.props.sendEmail(email, this.props.lastInvoice.id);
                        this.processReceipt({type: 'email', email});
                    }}
                    onCancel={() => {
                        this.setState({
                            view: 'sendEmail'
                        });
                    }}
                />
            );
        }else if(this.state.view === 'takeProducts'){
            if(this.props.theme === 'Erply'){
                display = (
                    <div style={{
                        margin: 'auto',
                        marginTop: '30px',
                        alignSelf: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <img
                            src={checkMarkErplyImg}
                            alt={'check mark'}
                            style={{
                                margin: '30px auto'
                            }}
                        />
                        <span
                            className={"boldText"}
                        >
                            <Translate id={"paymentMade"}/>.
                        </span>
                        <span
                            className={"boldText"}
                        >
                            <Translate id={"takeProductsFromBasket"}/>
                        </span>
                        <div style={{
                            "marginBottom": "30px"
                        }}/>
                        <img src={paymentDoneErplyImg} alt="removeProduct" width={257}/>
                    </div>
                );
            }else{
                display = (
                    <div style={{
                        margin: 'auto',
                        alignSelf: 'center'
                    }}>
                        <span className={"blueCircleBackground"}>3</span>
                        <br/>
                        <div style={{
                            "marginBottom": "34px"
                        }}/>
                        <span className={"boldUppercaseText largeText"}>
                    <Translate id={"takeProductsFromBasket"}/>
                </span>
                        <br/>
                        <div style={{
                            "marginBottom": "30px"
                        }}/>
                        <Image image={"removeProducts"} alt="removeProduct" style={{maxWidth: "300px", maxHeight: "500px"}}/>
                    </div>
                );
            }
        }else{
            if(this.props.theme === 'Erply'){
                display = (
                    <div style={{
                        height: '100%',
                        width: '100%',
                        backgroundColor: '#f2f2f2',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <span
                        className={"boldText"}
                        style={{
                            marginTop: '110px',
                            fontSize: '48px'
                        }}
                    >
                        <Translate id={"All good"}/>!
                    </span>
                    <span
                        className={"boldText"}
                        style={{
                            marginBottom: '50px',
                            fontSize: '48px'
                        }}
                    >
                        <Translate id={"paymentMade"}/>.
                    </span>
                        <img src={greenCheckMarkErplyImg} alt="thanks"/>
                    </div>
                );
            }else{
                display = (
                    <div style={{
                        margin: 'auto',
                        alignSelf: 'center'
                    }}>
                    <span className={"boldUppercaseText largeText"}>
                        <Translate id={"thankVisit"}/>!
                    </span>
                        <br/>
                        <div style={{
                            "marginBottom": "54px"
                        }}/>
                        <Image image={"dialogue"} alt="thanks"/>
                    </div>
                );
            }
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
                textAlign: 'center'
            }}>
                { display }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Done)
