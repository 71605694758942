import {
    addBeforeApplyPromotionsEvent,
    addCampaignByName,
    addErrorAlert,
    getCampaignsByName,
    openGetUserRightsByPinModal,
    removeBeforeApplyPromotionEvent,
    setProductsInBasket,
    setTransactionUser
} from "../../redux/actions";
import {APPLY_PROMOTIONS} from "../../redux/actionTypes";
import {getHasRightToMakeDiscount, getProductsInBasket, getReasonCodeByID} from "../../redux/selectors";
import {createDraft} from "immer";

/**
 * Apply employee discount
 */
const AED = {
    conf: {
        teammateRole: "com.decathlon.teammate",
        priceAPIUrl: () => window.AppConf.preprod ? "https://accio-eu10.erply.com" : "https://accio-eu.erply.com",
        modes: () => window.AppConf?.employeeDiscount?.modes ?? ["cashier"],
        supervisorValidation: () => window.AppConf?.employeeDiscount?.supervisor ?? false
    },
    addPromotion: (store, state, campaign) => {
        return new Promise((resolve, reject) => {
            let productsInBasket = getProductsInBasket(state);
            let storeNumber = state.rootReducer.warehouse.code;
            let reasonCodes = state.rootReducer.reasonCodes;
            AED.getDiscountableProductCodes(productsInBasket, storeNumber).then((codes) => {
                productsInBasket.forEach(product => {
                    if(codes.indexOf(product.data.code) !== -1){
                        product.setManualDiscountPercentage(campaign.percentageOffMatchingItems);
                        let reason = getReasonCodeByID(reasonCodes, campaign.reasonID);
                        if(product.amount < 0){
                            product.manualDiscountReasonCodeID = campaign.reasonID;
                            product.manualDiscountReason = reason;
                        }else{
                            product.returnReasonID = campaign.reasonID;
                            product.returnReason = reason;
                        }
                    }
                });
                store.dispatch(setProductsInBasket(productsInBasket));
                resolve();
            }, () => {
                reject();
            });
        });
    },
    removePromotion: (productsInBasket, campaign) => {
        productsInBasket.forEach(product => {
            if(product.returnReasonID === campaign.reasonID){
                product.resetManualDiscount();
                delete product.returnReasonID;
                delete product.returnReason;
            }
        });
    },
    customerIsEmployee: (customer) => {
        if(typeof customer.roles === "undefined"){
            return false;
        }
        return typeof customer.roles.find(role => role.id === AED.conf.teammateRole) !== "undefined";
    },
    getProductPriceTypes: (productCodes, storeNumber) => {
        return new Promise((resolve, reject) => {
            fetch(AED.conf.priceAPIUrl() + "/prices?storeNumber=" + storeNumber + "&productCodes=" + productCodes.join(',')).then(response => {
                return response.json();
            }).then((response) => {
                console.log('api response', response);
                if(response.status.responseStatus === "ok"){
                    resolve(response.data);
                }else{
                    reject(response);
                }
            }).catch((response) => {
                console.log('api request failed', response);
                reject(response);
            });
        });
    },
    getDiscountableProductCodes: (productsInBasket, storeNumber) => {
        return new Promise((resolve, reject) => {
            let productCodes = productsInBasket.reduce((acc, el) => {
                console.log("AED: product", {code: el.data.code, category: el.data.seriesName});
                if(el.data.seriesName === 'DKT:PassionBrand'){
                    acc.push(el.data.code);
                }
                return acc;
            }, []);
            console.log('AED: ', {productCodes});
            if(productCodes.length === 0){
                resolve([]);
                return;
            }
            AED.getProductPriceTypes(productCodes, storeNumber).then(productPriceTypes => {
                console.log({productPriceTypes});
                let codes = productCodes.reduce((acc, code) => {
                    if(typeof productPriceTypes[code] !== "undefined"){
                        let price = productPriceTypes[code];
                        if(price.priceType === "STANDARD"){
                            acc.push(code);
                        }
                    }else{
                        acc.push(code);
                    }
                    return acc;
                }, []);
                resolve(codes);
            }, reject);
        });
    },
    getCampaign: (state, store) => {
        return new Promise((resolve, reject) => {
            const campaignName = 'Employee discount';
            let campaign = state.rootReducer.campaignsByName?.[campaignName];
            if(typeof campaign === "undefined"){
                store.dispatch(getCampaignsByName(campaignName, (data) => {
                    console.log("AED: getCampaignsByName", data);
                    if(data.length > 0){
                        campaign = data[0];
                        resolve(campaign);
                        return addCampaignByName(campaign);
                    }
                    reject();
                    return addCampaignByName({
                        name: campaignName,
                        campaignID: 0
                    });
                }, () => {
                    reject();
                    console.log('Campaigns not found');
                    return {
                        type: "CAMPAIGN_NOT_FOUND"
                    }
                }));
            }else{
                if(campaign.campaignID !== 0){
                    resolve(campaign);
                }else{
                    reject();
                }
            }
        });
    },
    onEmployeeSet: (customer, state, store) => {
        let operationMode = state.rootReducer.cashierMode ? "cashier" : state.rootReducer.operationMode;
        if(AED.conf.modes().indexOf(operationMode) === -1){
            return false;
        }

        if(AED.customerIsEmployee(customer)){
            console.log("AED: customer is employee");
            const applyDiscount = () => {
                AED.getCampaign(state, store).then((campaign) => {
                    console.log("AED: campaign", campaign);
                    store.dispatch(addBeforeApplyPromotionsEvent((state) => {
                        return new Promise((resolve, reject) => {
                            AED.addPromotion(store, state, campaign).then(resolve, reject);
                        });
                    }, campaign.campaignID));

                    store.dispatch({
                        type: APPLY_PROMOTIONS
                    });
                }, () => {});
            }

            if(AED.conf.supervisorValidation()){
                store.dispatch(openGetUserRightsByPinModal(store.dispatch, state.rootReducer.user.clientCode, state.rootReducer.posID, ((userRights, supervisor) => {
                    if(!window.AppConf.selfValidateSupervisor && state.rootReducer.user.userID === supervisor.userID){
                        store.dispatch(addErrorAlert('User has no right to perform this action!'));
                        return;
                    }

                    if(getHasRightToMakeDiscount(userRights)){
                        store.dispatch(setTransactionUser(supervisor));
                        applyDiscount();
                    }else{
                        store.dispatch(addErrorAlert('User has no right to perform this action!'));
                    }
                }), undefined, undefined, "Supervisor validation needed for employee discount!"));
            }else{
                applyDiscount();
            }
        }
    },
    onEmployeeRemove: (state, store) => {
        AED.getCampaign(state, store).then((campaign) => {
            console.log("AED: campaign", campaign);
            store.dispatch(removeBeforeApplyPromotionEvent(campaign.campaignID));
            let productsInBasket = getProductsInBasket(state);
            AED.removePromotion(productsInBasket, campaign);
            store.dispatch(setProductsInBasket(productsInBasket));
            store.dispatch({
                type: APPLY_PROMOTIONS
            });
        }, () => {});
    }
}

export default AED;
