import React, { Component } from 'react';
import languageChoose from './images/LanguageChoose.png'
import Translate from "localization/Translate";
import {getLanguages, localeSelected} from "localization/localizeSlice";
import {connect} from "react-redux";
import ClickAwayListener from "react-advanced-click-away";
import FlagImage from "../Decathlon/FlagImage";

const mapDispatchToProps = dispatch => {
    return {
        setLocale: (locale) => {
            dispatch(localeSelected(locale));
        }
    }
}

const mapStateToProps = state => {
    let locale = state.localize.activeLocale;

    return {
        locale,
        languages: getLanguages(state)
    }
};

class LanguageSelect extends Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen: false
        };
    }

    toggleSelect(){
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    changeLocale(e, locale){
        this.props.setLocale(locale);
    }

    render() {
        let languages = this.props.languages.map((languageCode, index) => {
            return(
                <li className={"languageItem"} key={languageCode} onClick={e => this.changeLocale.bind(this)(e, languageCode)}>
                    <FlagImage locale={languageCode} style={{width: "35px"}}/>
                    <span>
                        <Translate id={languageCode}/>
                    </span>
                </li>
            );
        });

        return (
            <div onClick={this.toggleSelect.bind(this)} className={"statusBarItemLeft"}>
                <img src={languageChoose} alt={"languageChoose"}/>
                {this.state.isOpen &&
                    <ClickAwayListener onClickAway={this.toggleSelect.bind(this)}>
                        <div className={"languageListContainer"}>
                            <div className={"triangle-with-shadow"}/>
                            <div className={""}>
                               <ul>{languages}</ul>
                            </div>
                        </div>
                    </ClickAwayListener>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelect);
