import React, {useState} from 'react';
import Translate from "localization/Translate";
import Button from "../../buttons/Button";
import CheckList from "../lists/productCheckList/CheckList";
import Header from "./components/Header";
import {useSelector} from "react-redux";
import {getTranslate} from "localization/localizeSlice";

export default function ({document, onDone}) {
    const theme = useSelector(state => state.rootReducer.theme);
    const translate = useSelector(state => getTranslate(state));
    const [listItems, setListItems] = useState(document.rows.reduce((acc, item) => {
        acc.push({
            checked: false,
            product: item
        });
        return acc;
    }, []));

    if(theme === 'Erply'){
        return (
            <div>
                <Header title={`${translate("Return")} ${document.number}`}/>
                <Button
                    style={{
                        position: "absolute",
                        top: "8px",
                        right: "100px"
                    }}
                    type="button"
                    className={"largeButton darkYellowButton roundButton uppercaseText"}
                    name={<Translate id="Ok"/>}
                    arrow="right"
                    onClick={() => {
                        let checkedProducts = listItems.reduce((acc, item) => {
                            if(item.checked){
                                acc.push(item.product);
                            }
                            return acc;
                        }, []);
                        onDone(checkedProducts);
                    }}/>
                <CheckList
                    listItems={listItems}
                    setListItems={setListItems}
                    marginTop={'65px'}
                />
            </div>
        );
    }

    return (
        <div>
            <h2>
                <Translate id={"Return"}/> {document.number}
            </h2>
            <Button
                style={{
                    position: "absolute",
                    top: "20px",
                    right: "100px"
                }}
                type="button"
                className={"largeButton yellowButton"}
                name={<Translate id="Ok"/>}
                arrow="right"
                flencheClass={"flencheSmallRightBottom yellowFlenche"}
                onClick={() => {
                    let checkedProducts = listItems.reduce((acc, item) => {
                        if(item.checked){
                            acc.push(item.product);
                        }
                        return acc;
                    }, []);
                    onDone(checkedProducts);
                }}/>
            <CheckList
                listItems={listItems}
                setListItems={setListItems}
            />
        </div>
    );
}