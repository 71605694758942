import React, {Component} from 'react';
import "../../main/main.css";
import Translate from "localization/Translate";
import Trash from "../../icons/Trash";

class BasketLine extends Component{
    render() {
        let removeLine = this.props.remove !== false ? (
                <td onClick={e => this.props.remove()} style={{
                    width: "20px",
                    paddingRight: "33px"
                }}>
                    <Trash/>
                </td>
        ) : <td/>;

        return (
            <tr className={"productLine"}>
                <td className={"productLineNameColumn"}>
                    <span style={{textTransform: "uppercase"}}><Translate id={this.props.title}/></span><br/>
                    <span style={{
                        fontWeight: "bold"
                    }}>{this.props.value}</span>
                </td>
                <td className={"productLinePriceColumn"}/>
                <td>
                    <img src={this.props.image} alt={'paybackImg'} style={{
                        maxHeight: 60,
                        maxWidth: 100
                    }}/>
                </td>
                {removeLine}
            </tr>
        );
    }
}

export default BasketLine
