export const SET_INTEGRATION = "SET_INTEGRATION";
export const PAYMENT_API = "PAYMENT_API";
export const LOGIN = "LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const SET_TOTAL_SUM = "SET_TOTAL_SUM";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_PAYMENTS = "UPDATE_PAYMENTS";
export const VOID_ALL_PAYMENTS = "VOID_ALL_PAYMENTS";
export const REMOVE_PAYMENT = "REMOVE_PAYMENT";
export const PAYMENT_COMPLETED = "PAYMENT_COMPLETED";
export const CARD_PAYMENT_FAILED = "CARD_PAYMENT_FAILED";
export const INIT_PAYMENT = "INIT_PAYMENT";
export const RETURN = "RETURN";
export const VOID = "VOID";
export const CANCEL = "CANCEL";
export const GIFT_CARD_API = "GIFT_CARD_API";
export const INPUT_PAYMENT_STARTED = "INPUT_PAYMENT_STARTED";
export const USE_ALL_IN_ONE_RECEIPT = "USE_ALL_IN_ONE_RECEIPT";
export const ADD_CARD_PAYMENT_PROGRESS_MESSAGE = "ADD_CARD_PAYMENT_PROGRESS_MESSAGE";
export const SET_DISPLAY_PAYMENT_TYPES = "SET_DISPLAY_PAYMENT_TYPES";
export const SET_ID_CARD_IDENTIFICATION = "SET_ID_CARD_IDENTIFICATION";
export const SET_ZIP_CODE_SURVEY_COUNTER = "SET_ZIP_CODE_SURVEY_COUNTER";
export const SET_CUSTOM_PAYMENT_VIEW = "SET_CUSTOM_PAYMENT_VIEW";