import React, {Component} from 'react';
import "./main.css"
import Translate from "localization/Translate";
import {connect} from "react-redux";
import Keyboard from "../keyboard/Keyboard";
import {addErrorAlert, closeModal, showDialogue} from "../redux/actions";
import {getTranslate} from "localization/localizeSlice";
import PerfectScrollbar from "react-perfect-scrollbar";
import {displayPrice, roundedPrice} from "../util/helperFunctions";

const mapStateToProps = state => {
    return {
        currency: state.rootReducer.currency,
        posCurrencyUnits: state.rootReducer.erplyConf?.pos_currency_units ?? false,
        translate: getTranslate(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onConfirmInput: (openedSum, openDayModalID, translate, currency, onConfirm, onCancel) => {
            let cashInLimit = window.AppConf.cashInLimit;
            if(cashInLimit !== false && parseFloat(openedSum) > cashInLimit){
                dispatch(addErrorAlert(translate('Maximum cash in sum is', {sum: cashInLimit})));
                return false;
            }
            dispatch(closeModal(openDayModalID));
            dispatch(showDialogue(
                'Validate open day',
                translate("Are you sure you want to validate") + ' '  + displayPrice(openedSum) + currency + ' ' + translate('to open day') + '?',
                'accept',
                onConfirm,
                onCancel,
                'refuse',
                false
            ));
        },
        addErrorAlert: (error) => {
            dispatch(addErrorAlert(error))
        }
    }
};

class OpenDay extends Component{
    constructor(props) {
        super(props);
        this.state = {
            currentInput: "register",
            total: ""
        };

        this.selectInput = this.selectInput.bind(this);
        this.calculateTotal = this.calculateTotal.bind(this);
    }

    selectInput(name) {
        this.setState({
            currentInput: name
        });
    }

    calculateTotal(money) {
        let total = money.reduce((acc, el) => {
            let inputValue = document.getElementsByName('money_' + el)[0].value;
            acc += inputValue * el;
            return acc;
        }, 0);
        this.setState({
            total: roundedPrice(total)
        });
    }

    render() {
        let calculateTotal = this.calculateTotal;
        let currentInput = this.state.currentInput;

        let money = [
            100,
            50,
            20,
            10,
            5,
            2,
            1,
            0.5,
            0.2,
            0.1,
            0.05,
            0.02,
            0.01
        ];

        if(this.props.posCurrencyUnits !== false){
            money = this.props.posCurrencyUnits.split(',').reduce((acc, el) => {
                let parts = el.split('=');
                acc.push(parts[1]);
                return acc;
            }, []);
        }

        let that = this;
        return (
            <div style={{
                height: process.env.REACT_APP_ERPLY_MODE === "1" ? "calc(100% - 176px)" : "calc(100% - 146px)"
            }}>
                <h2 style={{
                    float: "left"
                }}><Translate id={'Open day'}/></h2>
                <PerfectScrollbar>
                    <div className={"moneyInputs"}>
                        <ul>
                            { money.map((el, index) => {
                                let inputName = 'money_' + el;
                                let className = this.state.currentInput === inputName ? 'selectedMoney' : '';
                                return (
                                    <li key={index} onClick={e => {this.selectInput(inputName)}} className={className}>
                                        <span className={"moneyTitle"}>{ this.props.currency }{ el }</span>
                                        <input
                                            type={"text"}
                                            name={inputName}
                                            placeholder={"0"}
                                            autoComplete={'off'}
                                            onChange={e => calculateTotal(money)}
                                        />
                                    </li>
                                );
                            }) }
                            <li style={{
                                width: "calc(100% - 25px)"
                            }} onClick={e => {this.selectInput("money_register")}} className={this.state.currentInput === "money_register" ? 'selectedMoney' : ''}>
                                <span style={{
                                width: "50px"
                            }}><Translate id={"Register"}/></span>
                                <input
                                    style={{
                                        width: "calc(100% - 110px)"
                                    }}
                                    autoComplete={"off"}
                                    type={"text"}
                                    name={"money_register"}
                                    placeholder={"0"}
                                    value={this.state.total}
                                    onChange={e => {
                                        this.setState({
                                            total: e.target.value
                                        });
                                    }}
                                    onClick={() => {
                                        this.selectInput("money_register");
                                    }}
                                />
                                <span>{ this.props.currency }</span></li>
                        </ul>
                    </div>
                </PerfectScrollbar>
                <div style={{
                    "position": "absolute",
                    "bottom": "0",
                    "left": "0",
                    "right": "0"
                }}>
                    <Keyboard options={{
                        layout: 'numbers',
                        inputFieldName: this.state.currentInput,
                        onChangeToLetters: () => {},
                        onChangeToNumbers: () => {},
                        onDone: function () {
                            let inputValue = document.getElementsByName("money_register")[0].value;
                            if(inputValue === ""){
                                inputValue = "0";
                            }
                            let value = parseFloat(inputValue.replace(",","."));
                            if(!isNaN(value)){
                                that.props.onConfirmInput(value, that.props.modalID, that.props.translate, that.props.currency, () => {
                                    that.props.onConfirm(value);
                                }, that.props.onCancel);
                            }else {
                                that.props.addErrorAlert("please insert correct amount");
                            }
                        },
                        onClick: function (key) {
                            if(currentInput !== 'money_register'){
                                calculateTotal(money);
                            }
                        }
                    }}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenDay);