import React from 'react';
import './button.css';
import Translate from "../localization/Translate";
import Button from "./Button";
import {useDispatch, useSelector} from "react-redux";
import {
    closeRightPanelDialogue,
    removeOnePromotionPromoCode,
    setOnePromotionPromoCode,
    showRightPanelDialogue
} from "../redux/actions";
import EnterPromoCode from "../campaigns/onePromotion/EnterPromoCode";

export default function ({}) {
    const dispatch = useDispatch();
    const onePromotionPromoCode = useSelector((state) => state.rootReducer.onePromotionPromoCode);

    if(onePromotionPromoCode !== false){
        return (
            <Button
                removeSign
                onClick={() => {
                    dispatch(removeOnePromotionPromoCode());
                }}
                type="button"
                className={"largeRoundButton " + (
                    onePromotionPromoCode.status === 'error' ? "negativeActionButton" :
                    onePromotionPromoCode.status === 'pending' ? "warningActionButton" : "positiveActionButton")}
                name={<><Translate id="Code"/> - {onePromotionPromoCode.code} - <Translate id={onePromotionPromoCode.message}/></>}
                style={{
                    minWidth: '240px'
                }}
            />
        );
    }

    return (
        <Button
            plusSign
            onClick={() => {
                dispatch(showRightPanelDialogue(
                    <EnterPromoCode
                        onInputDone={(value) => {
                            dispatch(setOnePromotionPromoCode(value, "pending", "validating"));
                            dispatch(closeRightPanelDialogue());
                        }}
                        onBack={() => {
                            dispatch(closeRightPanelDialogue());
                        }}
                    />));
            }}
            type="button"
            className={"largeRoundButton blueButton"}
            name={<Translate id="add a promo code"/>}
            style={{
                minWidth: '240px'
            }}
        />
    );
}
