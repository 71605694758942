let db;
let dbInit = 0;
let dbClientCode = 0;
const dbVersion = 4;

const requestToTable = {
    verifyUser: {
        name: 'user',
        key: 'userID',
        rejectEmpty: true,
        clearOnUpdate: true
    },
    getPointsOfSale: {
        name: 'pos',
        key: 'pointOfSaleID',
        rejectEmpty: true,
        clearOnUpdate: true,
        getKeyFromRequest: 'POSOpenDay'
    },
    getAllowedWarehouses: {
        name: 'allowed_warehouse',
        key: 'warehouseID',
        rejectEmpty: true,
        clearOnUpdate: true
    },
    getWarehouses: {
        name: 'warehouse',
        key: 'warehouseID',
        rejectEmpty: true,
        clearOnUpdate: true
    },
    getCompanyInfo: {
        name: 'company_info',
        key: 'id',
        rejectEmpty: true
    },
    getReasonCodes: {
        name: 'reason_code',
        key: 'reasonID',
        rejectEmpty: true,
        clearOnUpdate: true
    },
    getVatRates: {
        name: 'vat_rate',
        key: 'id',
        rejectEmpty: true,
        clearOnUpdate: true
    },
    getPaymentTypes: {
        name: 'payment_type',
        key: 'id',
        rejectEmpty: true,
        clearOnUpdate: true
    },
    getEmployees: {
        name: 'employee',
        key: 'employeeID',
        rejectEmpty: true
    },
    getProductGroups: {
        name: 'product_group',
        key: 'productGroupID',
        rejectEmpty: true,
        clearOnUpdate: true
    },
    getProductCategories: {
        name: 'product_category',
        key: 'productCategoryID',
        clearOnUpdate: true
    },
    getUserRights: {
        name: 'user_rights',
        key: 'userID',
        clearOnUpdate: false,
        dbVersion: 2
    },
    getServiceEndpoints: {
        name: 'service_endpoint',
        rejectEmpty: true,
        clearOnUpdate: true
    },
    getConfParameters: {
        name: 'conf_parameter',
        rejectEmpty: true,
        clearOnUpdate: true
    },
    POSOpenDay: {
        name: 'pos_day',
        key: 'dayID',
        clearOnUpdate: true
    },
    POSCloseDay: {
        name: 'pos_day',
        key: 'dayID',
        clearOnUpdate: true,
        createStore: false
    },
    getCAFAConf: {
        name: 'cafa_conf',
        key: 'id',
        clearOnUpdate: true
    },
    getTranslations: {
        name: 'translations',
        key: 'id',
        clearOnUpdate: true,
        dbVersion: 3
    },
    saveTransaction: {
        name: 'transaction'
    },
    getCurrencies: {
        name: 'currency',
        key: 'currencyID',
        rejectEmpty: true,
        clearOnUpdate: true,
        dbVersion: 4
    },
};

export const getDB = (clientCode) => {
    return new Promise((resolve, reject) => {
        if(clientCode === null || clientCode === ""){
            reject();
            return;
        }
        if(dbInit === 2){
            if(dbClientCode === clientCode){
                resolve(db);
            }else{
                db.close();
                dbInit = 0;
                db = undefined;
                init(clientCode).then(resolve, reject);
            }
        }else if(dbInit === 1){
            initCallbacks.push({resolve, reject});
        }else{
            init(clientCode).then(resolve, reject);
        }
    });
}

const initCallbacks = [];
const init = (clientCode) => {
    return new Promise((resolve, reject) => {
        dbInit = 1;
        dbClientCode = clientCode;
        let dbRequest = indexedDB.open('sco_' + clientCode, dbVersion);
        dbRequest.onupgradeneeded = (event) => {
            console.log("onupgradeneeded", {event});
            const db = event.target.result;

            db.onerror = (event) => {
                console.log("IDB: error", event);
            };

            const createTables = (version) => {
                Object.values(requestToTable).forEach((table) => {
                    let tableVersion = typeof table.dbVersion === "undefined" ? 1 : table.dbVersion;
                    console.log({table, tableVersion, version, dbVersion});
                    if(tableVersion === version){
                        if(table.createStore !== false){
                            if(typeof table.key !== "undefined"){
                                db.createObjectStore(table.name, {
                                    keyPath: table.key,
                                });
                            }else{
                                db.createObjectStore(table.name, {
                                    autoIncrement: true
                                });
                            }
                        }
                    }
                });
            }

            for(let i=event.oldVersion; i < dbVersion; i++){
                createTables(i+1);
            }
        };
        dbRequest.onsuccess = () => {
            db = dbRequest.result;
            dbInit = 2;
            resolve();
            initCallbacks.forEach((callback) => {
                callback.resolve(db);
            });
        }
        dbRequest.onerror = (error) => {
            console.log("IDB: init error", error);
            reject(error);
            initCallbacks.forEach((callback) => {
                callback.reject();
            });
        };
    });
}

const clearStoreIfNeeded = (table) => {
    return new Promise((resolve, reject) => {
        if(table.clearOnUpdate){
            let store = table.name;
            let request = db.transaction(store,'readwrite').objectStore(store).clear();
            request.onsuccess = resolve;
            request.onerror = reject;
        }else{
            resolve();
        }
    });
}

export const addOrUpdate = (requestName, value) => {
    return new Promise((resolve, reject) => {
        let table = requestToTable?.[requestName];
        if(typeof table === "undefined"){
            reject();
            return;
        }
        clearStoreIfNeeded(table).then(() => {
            let store = table.name;
            let request = db.transaction(store,'readwrite').objectStore(store).put(value);
            request.onsuccess = resolve;
            request.onerror = reject;
        }, reject);
    });
}

const getKeyFromRequest = (request, requestKey) => {
    return new Promise((resolve, reject) => {
        getOldest(request).then(({record, key}) => {
            if(record === null){
                resolve(false);
                return;
            }
            if(typeof record[requestKey] !== "undefined"){
                resolve(record[requestKey]);
            }else{
                resolve(false);
            }
        }, () => {
            resolve(false);
        });
    });
}

export const get = (requestName, key=false) => {
    return new Promise(async (resolve, reject) => {
        let table = requestToTable?.[requestName];
        if(typeof table === "undefined"){
            reject();
            return;
        }
        let store = table.name;

        if(key === false && table.getKeyFromRequest){
            key = await getKeyFromRequest(table.getKeyFromRequest, table.key);
        }

        if(key !== false){
            let request = db.transaction(store,'readonly').objectStore(store).get(key);
            request.onsuccess = (event) => {
                resolve([event.target.result]);
            };
            request.onerror = () => {
                reject("Browser database issue");
            };
        }else{
            let request = db.transaction(store,'readonly').objectStore(store).getAll();
            request.onsuccess = (event) => {
                if(table.rejectEmpty && event.target.result.length === 0){
                    reject("No records");
                }else{
                    resolve(event.target.result);
                }
            };
            request.onerror = () => {
                reject("Browser database issue");
            };
        }
    });
}

export const getOldest = (requestName) => {
    return new Promise(function (resolve, reject) {
        let table = requestToTable?.[requestName];
        if(typeof table === "undefined"){
            reject();
            return;
        }
        let store = table.name;
        let objectStore = db.transaction(store,'readonly').objectStore(store);
        const request = objectStore.openCursor();
        request.onsuccess = (event) => {
            const cursor = event.target.result;
            const countRequest = objectStore.count();
            countRequest.onsuccess = (event) => {
                const count = event.target.result;
                if(cursor) {
                    resolve({record: cursor.value, key: cursor.key, count});
                }else{
                    resolve({record: null, count});
                }
            }
        };
        request.onerror = reject;
    });
};

export const deleteRecord = (requestName, key) => {
    return new Promise(function (resolve, reject) {
        let table = requestToTable?.[requestName];
        if(typeof table === "undefined"){
            reject();
            return;
        }
        let store = table.name;
        const request = db.transaction(store,'readwrite').objectStore(store).delete(key);
        request.onsuccess = resolve;
        request.onerror = reject;

    });
};
