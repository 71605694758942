import React, {Component} from 'react';
import "../main.css"
import Translate from "localization/Translate";
import {connect} from "react-redux";
import {apiError, closeModal, closeQCO, login, refreshUserSession, setLoading, switchUser} from "../../redux/actions";
import {getTranslate} from "../../localization/localizeSlice";
import MultipleInputContent from "../../modal/MultipleInputContent";
import Button from "../../buttons/Button";
import {SET_USER} from "../../redux/actionTypes";
import {timers} from "../../util/timers";

const sendSwitchUser = (dispatch, clientCode, pin, onSuccess) => {
    dispatch(switchUser(clientCode, pin, (data) => {
        dispatch(setLoading(false));
        let user = data[0];
        user.clientCode = clientCode;

        timers.setTimeout('userSessionUpdate', () => {
            sendSwitchUser(dispatch, clientCode, pin, () => {}, () => {
                dispatch(closeQCO());
            });
        }, 86400, true);
        onSuccess();
        return {
            type: SET_USER,
            payload: data[0]
        }
    }, (error) => {
        dispatch(setLoading(false));
        return apiError(error);
    }));
}

const mapStateToProps = state => {
    return {
        clientCode: state.rootReducer.user.clientCode,
        translate: getTranslate(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        verifyUser: (clientCode, inputValues, modalID) => {
            dispatch(setLoading(true));
            if(typeof inputValues.pin !== "undefined"){
                sendSwitchUser(dispatch, clientCode, inputValues.pin, () => {
                    dispatch(closeModal(modalID));
                });
            }else{
                let onSessionTimeout = (user) => {
                    let handleRefreshUserSession = () => {
                        dispatch(refreshUserSession(user.clientCode, inputValues.username, inputValues.password, () => {
                            return closeQCO();
                        }, () => {
                            handleRefreshUserSession();
                        }));
                    }
                    handleRefreshUserSession();
                };
                dispatch(login(clientCode, inputValues.username, inputValues.password, () => {
                    dispatch(setLoading(false));
                    dispatch(closeModal(modalID));
                }, () => {
                    dispatch(setLoading(false));
                }, onSessionTimeout));
            }
        }
    }
};

class SwitchUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "pin"
        };
        this.login = this.login.bind(this);
    }
    login(inputValues) {
        const that = this;
        this.props.verifyUser(this.props.clientCode, inputValues, this.props.modalID);
    }
    render() {
        let changeModeButton = this.state.mode === "pin" ?
            <Button
                type="button"
                className={"mediumEqualButton lightBlueButton roundButton uppercaseText"}
                name={<Translate id="Username and password"/>}
                onClick={e => this.setState({mode: "userPass"})}
            />
            :
            <Button
                type="button"
                className={"mediumEqualButton lightBlueButton roundButton uppercaseText"}
                name={<Translate id="PIN"/>}
                onClick={e => this.setState({mode: "pin"})}
            />
        ;

        let inputFields = this.state.mode === "pin" ?
            [
                { id: 'pin', name: 'PIN', isNumber: true, type: 'password'}
            ]
            :
            [
                { id: 'username', name: 'Username'},
                { id: 'password', name: 'Password', type: 'password'}
            ]
        ;

        return (
            <div style={{
                position: "absolute",
                top: 0,
                bottom: "74px",
                left: 0
            }}>
                <h1 className={"bigUpperCaseHeading"}><Translate id={"Switch user"}/></h1>
                <div style={{
                    height: "100%"
                }}>
                    <MultipleInputContent
                        containerStyle={{position: "auto",height: "100%"}}
                        inputFields={ inputFields }
                        onDone={(inputValues) => {this.login(inputValues);}}
                        afterInputFields={<div style={{marginTop: "20px"}}>{changeModeButton}</div>}
                        inputClassName={"fullWidth roundButton"}
                        inputStyle={{boxSizing: "border-box"}}
                    />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchUser);
