import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {setDefaultAppConf} from "./login/configurationLoader";

if(typeof window.AppConf === "undefined"){
    setDefaultAppConf();
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
