import {useSelector} from "react-redux";
import {getTranslate} from "./localizeSlice";

export default function ({id, values={}, options={}}) {
    const translate = useSelector(state => getTranslate(state));

    if(typeof id === "undefined" || id === "" || id === null){
        return "";
    }
    return translate(id, values, options);
}