import React, {Component} from 'react';
import "../main.css"
import Translate from "localization/Translate";
import {connect} from "react-redux";
import DialogueView from "../DialogueView";
import {closeAdministration, removeProduct, setCustomerAgeVerified} from "../../redux/actions";
import {getProductsInBasket, getSecurityItems} from "../../redux/selectors";


const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        productsInBasket: getProductsInBasket(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onConfirm: () => {
            dispatch(setCustomerAgeVerified(true));
        },
        onCancel: (productsInBasket) => {
            let securityItems = getSecurityItems(productsInBasket);
            for(let product of securityItems){
                dispatch(removeProduct(product));
            }
            dispatch(setCustomerAgeVerified(false));
        },
        close: () => dispatch(closeAdministration())
    }
};

class SecurityItem extends Component {
    render() {
        return (
            <DialogueView
                confirmText={ <Translate id={"Yes"}/> }
                cancelText={ <Translate id={"No"}/> }
                onConfirm={ this.props.onConfirm}
                onCancel={ () => {this.props.onCancel(this.props.productsInBasket);} }
                title={ "Validate security item" }
                message={ "securityItemMessage" }
                canClose={false}
                close={this.props.close}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityItem);
