import React, { Component } from 'react';
import './screenSaver.css';
import arrowImg from "./arrow_yellow_concept.png";
import tshirtImg from "./tshirt_concept.png";
import {Redirect} from "react-router-dom";
import Translate from "localization/Translate";
import {connect} from "react-redux";
import {changeMode} from "../redux/actions";
import {APP_MODE_SCAN, APP_VIEW_APP} from "../redux/appConstants";
import ScreenSaverErply from "./Erply/ScreenSaver";
import ScreenSaverDecathlonPurple from "./DecathlonPurple/ScreenSaver";
import {checkIfImageExists} from "../util/helperFunctions";

const mapStateToProps = state => {
    return {
        view: state.rootReducer.view,
        redirect: state.rootReducer.redirect,
        hasProductsInBasket: state.rootReducer.productsInBasket.length > 0,
        erplyConf: state.rootReducer.erplyConf,
        uiCustomization: state.rootReducer.uiCustomization,
        activeLanguage: state.localize.activeLocale,
        rightPanelDialogue: state.rootReducer.rightPanelDialogue,
        decathlonTheme: state.rootReducer.decathlonTheme
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeMode: mode => dispatch(changeMode(mode))
    }
};

class ScreenSaver extends Component{
    constructor(props) {
        super(props);
        this.state = {
            displayScreenSaverImage: false
        }
    }

    componentDidMount () {
        checkIfImageExists('img/screensaver.png').then(exists => {
            if(exists){
                this.setState({
                    displayScreenSaverImage: true
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.hasProductsInBasket || this.props.rightPanelDialogue !== false){
            this.props.changeMode(APP_MODE_SCAN);
        }
    }

    render() {
        if (this.props.redirect && this.props.view !== APP_VIEW_APP) {
            return <Redirect to={this.props.view}/>
        }

        let dropItems = (
            <div className={"screenSaverWrapper"}>
                <div className={"greyShadowTriangle"}/>
                <div className={"whiteShadowTriangle"}/>
                <div className={"screenSaverContainer"}>
                    <div style={{
                        margin: "60px auto 80px",
                        width: "fit-content"
                    }}>
                        <img src={tshirtImg} alt={"tshirt"} height={56}/>
                        <img src={tshirtImg} alt={"tshirt"} height={106} style={{
                            height: "106px",
                            marginBottom: "20px"
                        }}/>
                        <img src={tshirtImg} alt={"tshirt"} height={56}/>
                    </div>
                    <span style={{fontSize: "50px"}}>
                        <Translate id={"drop your items"}/>
                    </span>
                    <span style={{fontSize: "70px"}} className={"cutUnderline"}>
                        <Translate id={"one at a time"}/>
                    </span>
                    <img src={arrowImg} alt={"downArrow"} style={{
                        display: "block",
                        margin: "80px auto 0"
                    }}/>
                </div>
            </div>
        );

        let content;
        if(process.env.REACT_APP_ERPLY_MODE === "1"){
            content = <ScreenSaverErply/>;
        }else if(this.props.decathlonTheme === 'purple'){
            content = <ScreenSaverDecathlonPurple/>
        }else{
            if(this.state.displayScreenSaverImage){
                content = <img style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    top: 0,
                    zIndex: 3
                }} src={'img/screensaver.png'} alt={"screenSaver"}/>
            }else{
                content = dropItems;
            }
        }

        return (
            <div onClick={e => this.props.changeMode(APP_MODE_SCAN)}>
                { content }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSaver);
