import React, {Component} from 'react';
import "./main.css"
import Translate from "localization/Translate";
import Trash from "../icons/Trash";
import Image from "../theme/Image";

class GiftCardLine extends Component{
    render() {
        return (
            <tr className={"productLine"}>
                <td className={"productLineNameColumn"}>
                    <span style={{textTransform: "capitalize"}}><Translate id={"giftCard"}/></span><br/>
                    <span className={"unitPrice"}><Translate id={"Reference"}/> : {this.props.payment.giftCard.cardNumber}</span>
                </td>
                <td className={"productLinePriceColumn"}>{parseFloat(this.props.payment.sum).toFixed(2)} {this.props.currency}</td>
                <td>
                    <Image image={"iconGiftCardPayment"} alt={"Gift card"} style={{height:"60px"}}/>
                </td>
                <td onClick={e => this.props.removeGiftCardPayment(this.props.payment)} style={{"width": "20px"}}>
                    <Trash/>
                </td>
            </tr>
        );
    }
}

export default GiftCardLine