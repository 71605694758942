import React, {useEffect, useState} from "react";

export default function ({locale, style}){
    const [image, setImage] = useState(null);

    useEffect(() => {
        let isCancelled = false;
        import('../flags/' + locale + '.png').then((image) => {
            if(!isCancelled){
                setImage(image.default);
            }
        }).catch(e => {
            
        });
        return () => {
            isCancelled = true;
        }
    })

    return <img src={image} alt={locale} style={style}/>
}