import React, {useState} from 'react';
import './button.css';
import Translate from "../localization/Translate";

export default function (props) {
    let [open, setOpen] = useState(false);
    let messageBox;
    if(open) {
        messageBox = <div
            style={{
                position: "absolute",
                border: "1px solid black",
                padding: "10px",
                right: "48px",
                top: "40px",
                backgroundColor: "#fff",
                fontSize: "16px"
            }}>
            <Translate id={props.message}/>
        </div>;
    }
    return (
        <div>
            <div onClick={(event) => {
                event.stopPropagation();
                setOpen(!open);
            }} style={{
                float: "right",
                marginRight: "20px"
            }}>
                <div style={{
                    color: "#da9a69",
                    border: "1px solid #da9a69",
                    borderRadius: "30px",
                    width: "30px",
                    padding: "4px",
                    fontSize: "16px",
                    boxSizing: "border-box",
                    textAlign: "center"
                }}>!</div>
            </div>
            { messageBox }
        </div>
    );
}