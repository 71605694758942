import React, {Component} from 'react';
import "./keyboard.css"
import CheckCircle from "../buttons/CheckCircle";
import {connect} from "react-redux";
import CheckSquare from "../buttons/CheckSquare";
import closeImg from "../main/images/Erply/close.png";

const layouts = {
    letters: [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', '\''],
        ['shift', '?123','z', 'x', 'c', 'v', 'b', 'n', 'm', 'delete'],
        ['@', '.com', 'space', '.', ';', 'ok']
    ],
    numbers: [
        ['.', '1', '2', '3'],
        [',', '4', '5', '6'],
        ['-', '7', '8', '9', 'delete'],
        ['abc', '_', '0', '+','ok']
    ],
    numbersOnly: [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['abc', '0', 'delete', 'ok']
    ],
    capitalLettersOnly: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['?123','Z', 'X', 'C', 'V', 'B', 'N', 'M', 'delete', 'ok']
    ],
    countryLetterLayouts: {
        et: [
            ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'ü', 'õ'],
            ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä', '\''],
            ['shift', '?123','z', 'x', 'c', 'v', 'b', 'n', 'm', 'delete'],
            ['@', '.com', 'space', '.', '','ok']
        ]
    }
};

const mapStateToProps = state => {
    return {
        lang: state.localize.activeLocale,
        theme: state.rootReducer.theme
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

class Keyboard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            currentLayout: props.options?.initialLayout || 'letters',
            shiftOn: false
        }
    }

    render() {
        const options = this.props.options;

        let currentLayout;
        let changeLayout = (layout) => {
            this.setState({
                currentLayout: layout
            });
        };

        if(typeof options.layout === "undefined"){
            currentLayout = this.state.currentLayout;
        }else{
            currentLayout = options.layout;
        }

        let layout = typeof currentLayout === "string" ? layouts[currentLayout] || layouts.letters : currentLayout;
        if(currentLayout === 'letters'){
            if(typeof layouts.countryLetterLayouts[this.props.lang] !== "undefined"){
                layout = layouts.countryLetterLayouts[this.props.lang];
            }
        }

        if(this.props.active === false){
            return <div/>
        }
        let closeButton;
        if(typeof this.props.setActive !== "undefined"){
            closeButton = <div style={{
                position: 'absolute',
                right: 0,
                top: -39,
                backgroundColor: '#f2f2f2',
                padding: 10
            }} onClick={() => {
                this.props.setActive(false);
            }}>
                <img src={closeImg} alt={'Close'}/>
            </div>;
        }

        return(
            <div className={"keyboardWrapper"} style={this.props.style ?? {}}>
                {closeButton}
                {
                    <table className={this.props.theme === 'Erply' ? "keyboardTableErply" : "keyboardTable"}>
                        <tbody>
                        {
                            layout.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        {
                                            row.map((key, keyIndex) => {
                                                let displayKey = key;
                                                const isSpace = key === 'space';
                                                const isDelete = key === 'delete';
                                                const isChangeToNumbers = key === '?123';
                                                const isChangeToLetters = key === 'abc';
                                                const isShift = key === 'shift';
                                                const isOk = key === 'ok';
                                                let colspan = "1";
                                                let className = "key";
                                                if(isSpace){
                                                    colspan = "5";
                                                    className = this.props.theme === 'Erply' ? "spaceKeyErply" : "spaceKey"
                                                }else if(isDelete){
                                                    displayKey = <i className={"icoFont"}>&#xef00;</i>;
                                                    /*if(options.layout === 'letters'){
                                                        colspan = "2";
                                                    }*/
                                                }else if(isShift){
                                                    if(this.state.shiftOn){
                                                        displayKey = <i className={"icoFont"}>&#xea62;</i>;
                                                    }else{
                                                        displayKey = <i className={"icoFont"}>&#xea91;</i>;
                                                    }
                                                }else if(isOk){
                                                    if(this.props.theme === 'Erply'){
                                                        colspan = "2";
                                                    }
                                                    displayKey = <div>
                                                        {
                                                            this.props.theme === 'Erply' ?
                                                                <CheckSquare style={{
                                                                    margin: '0 auto'
                                                                }}/>
                                                                :
                                                                <CheckCircle/>
                                                        }
                                                    </div>;
                                                }/*else if(key === "0" && options.layout === 'numbers'){
                                                colspan = "2";
                                            }*/

                                                let style = {};
                                                if(this.state.shiftOn){
                                                    style.textTransform = 'uppercase';
                                                }

                                                return (
                                                    <td key={keyIndex}
                                                        style={style}
                                                        onClick={e => {
                                                            if(isChangeToNumbers){
                                                                if(typeof options.onChangeToNumbers !== "undefined"){
                                                                    options.onChangeToNumbers();
                                                                }else{
                                                                    changeLayout('numbers');
                                                                }
                                                            }else if(isChangeToLetters) {
                                                                if(typeof options.onChangeToLetters !== "undefined"){
                                                                    options.onChangeToLetters();
                                                                }else{
                                                                    changeLayout('letters');
                                                                }
                                                            }else if(isShift) {
                                                                this.setState({
                                                                    shiftOn: !this.state.shiftOn
                                                                });
                                                            }else if(isOk) {
                                                                if (typeof options.onDone !== "undefined") {
                                                                    options.onDone();
                                                                }
                                                            }else{
                                                                let keyValue = isSpace ? " " : this.state.shiftOn ? key.toUpperCase() : key;
                                                                if(typeof options.inputFieldName !== "undefined"){
                                                                    let inputField = document.getElementsByName(options.inputFieldName);
                                                                    if(typeof inputField[0] !== "undefined"){
                                                                        if(isDelete){
                                                                            inputField[0].value = inputField[0].value.substr(0,inputField[0].value.length - 1);
                                                                        }else{
                                                                            inputField[0].value += keyValue;
                                                                        }
                                                                    }
                                                                }
                                                                if(options.currentValue !== undefined && options.updateValue !== undefined){
                                                                    let newString = isDelete ? options.currentValue.toString().slice(0, -1) : options.currentValue + keyValue;
                                                                    options.updateValue(newString, key, options.currentValue);
                                                                }
                                                                if(typeof options.onClick !== "undefined"){
                                                                    options.onClick(keyValue);
                                                                }
                                                            }
                                                        }}
                                                        colSpan={colspan}
                                                        className={className}
                                                    >
                                                        {displayKey}
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Keyboard);