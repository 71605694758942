import React, {Fragment} from "react";
import Translate from "../../localization/Translate";
import decathlonLogoBlue from "../images/DecathlonPurple/decathlonLogoTextBlue.png";
import {useSelector} from "react-redux";
import presentIcon from "../images/presentIcon.png";
import {getTranslate} from "../../localization/localizeSlice";

export default ({title, onClick=()=>{}, style={}}) => {
    const decathlonTheme = useSelector(state => state.rootReducer.decathlonTheme);
    const translate = useSelector(state => getTranslate(state));

    if(decathlonTheme === 'purple') {
        return <div
            className={"addProductContainer"}
            onClick={onClick}
            style={{
                textAlign: 'center',
                flexDirection: 'column',
                ...style
            }}
        >
            <Fragment>
                <div style={{
                    fontSize: 16
                }}><Translate id={title}/></div>
                <div><img width={120} src={decathlonLogoBlue} alt={"Decathlon"}/></div>
            </Fragment>
        </div>;
    }

    let giftCardTextParts = translate('giftCard').split(' ');
    let giftCardTextFirstWord = giftCardTextParts.shift();
    let giftCardTextOtherWords = giftCardTextParts.join(' ');
    let giftCardText = (
        <div>
            <span style={{color: '#f29125', display: 'inline-block', marginRight: '5px', width: 'fit-content'}}>{giftCardTextFirstWord}</span>
            <span style={{display: 'inline-block', marginRight: '4px', width: 'fit-content'}}>{giftCardTextOtherWords}</span>
            <img style={{
                position: 'relative',
                top: '3px',
                marginBottom: '3px',
                height: '20px'
            }} src={presentIcon} alt="presentIcon"/>
        </div>
    );

    return <div
        className={"addProductContainer"}
        onClick={onClick}
        style={{
            textAlign: 'center',
            flexDirection: 'column',
            ...style
        }}
    >
        <Fragment>
            <div className={'capitalizeFirst giftCardBuy'}><Translate id={"click here to buy a"}/></div>
            <div className={"giftCardBox"}>{ giftCardText }</div>
        </Fragment>
    </div>
}