import React, {useState} from "react"
import Translate from "localization/Translate";
import Button from "../../buttons/Button";
import InputWithKeyboard from "../../formElements/InputWithKeyboard";
import {useSelector} from "react-redux";
import Image from "../../theme/Image";

export default function (props) {
    let [manualEntry, setManualEntry] = useState(false);
    const theme = useSelector(state => state.rootReducer.theme);

    if(manualEntry){
        if(theme === 'Erply'){
            return (
                <div>
                <span className={"boldUppercaseText"}>
                    <Translate id={"giftCard"}/>
                </span>
                    <div style={{
                        padding: "20px",
                        textAlign: "left"
                    }}>
                        <InputWithKeyboard
                            inputLabel={"enter gift card code"}
                            inputLabelStyle={{
                                display: "block",
                                textAlign: "left",
                                marginTop: "30px",
                                fontSize: "16px"
                            }}
                            layout={"numbers"}
                            onInputChange={(value) => {}}
                            onDone={props.onManualEntry}
                            inputClass={"underlineInput"}
                        />
                        <Button
                            style={{
                                marginTop: "20px"
                            }}
                            type="button"
                            className={"largerButton roundButton grayButton uppercaseText"}
                            name={<Translate id="back"/>}
                            arrow="left"
                            onClick={e => {
                                setManualEntry(false);
                            }}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div>
                <span className={"boldUppercaseText"}>
                    <Translate id={"giftCard"}/>
                </span>
                <div style={{
                    padding: "20px",
                    textAlign: "left"
                }}>
                    <InputWithKeyboard
                        inputLabel={"enter gift card code"}
                        layout={"numbers"}
                        onInputChange={(value) => {}}
                        onDone={props.onManualEntry}
                    />
                    <Button
                        style={{
                            marginTop: "20px"
                        }}
                        type="button"
                        className={"largeButton grayButton"}
                        name={<Translate id="back"/>}
                        arrow="left"
                        flencheClass={"flencheSmallLeftBottom grayFlenche"}
                        onClick={e => {
                            setManualEntry(false);
                        }}
                    />
                </div>
            </div>
        );
    }

    if(theme === 'Erply'){
        return (
            <div>
                <span className={"boldUppercaseText"}>
                        <Translate id={"scan your gift card"}/>
                    </span>
                <br/>
                <div style={{
                    "marginBottom": "45px"
                }}/>
                <Image
                    image={"scanGiftCard"}
                    alt="scanGiftCard"
                    style={{
                        width: "200px",
                        marginBottom: "40px"
                    }}
                />
                <Button
                    style={{
                        display: 'block'
                    }}
                    type="button"
                    className={"largerButton roundButton lightBlueButton uppercaseText"}
                    name={<Translate id="enter gift card code"/>}
                    arrow="right"
                    onClick={e => {
                        setManualEntry(true);
                    }}
                />
                <div onClick={props.backToOptions} style={{
                    position: 'absolute',
                    bottom: '128px',
                    width: '100%',
                    textAlign: 'center'
                }}>
                    <Button
                        type="button"
                        className={"largerButton roundButton grayButton uppercaseText"}
                        name={<Translate id="cancel"/>}
                        arrow="left"
                    />
                </div>
            </div>
        );
    }


    return (
        <div>
                <span className={"boldUppercaseText"}>
                        <Translate id={"scan your gift card"}/>
                    </span>
            <br/>
            <div style={{
                "marginBottom": "45px"
            }}/>
            <Image image={"scanGiftCard"} alt="scanGiftCard" style={{width: "50%"}}/>
            <Button
                style={{
                    display: 'block'
                }}
                type="button"
                className={"largeButton blueButton"}
                name={<Translate id="enter gift card code"/>}
                arrow="right"
                flencheClass={"flencheSmallRightBottom blueFlenche"}
                onClick={e => {
                    setManualEntry(true);
                }}
            />
            <div onClick={props.backToOptions} style={{
                position: 'absolute',
                bottom: '128px',
                width: '100%',
                textAlign: 'center'
            }}>
                <Button type="button" className={"largeButton grayButton"} name={<Translate id="cancel"/>}
                        arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
            </div>
        </div>
    );
}
