export const SET_DECATHLON_THEME = "SET_DECATHLON_THEME";
export const SET_PLUGIN_URLS = "SET_PLUGIN_URLS";
export const CAFA_CONF_SET = "CAFA_CONF_SET";
export const SET_PLUGIN_REQUEST_PARAMETERS = "SET_PLUGIN_REQUEST_PARAMETERS";
export const ADD_PLUGIN_REQUEST_SALE_ATTRIBUTES = "ADD_PLUGIN_REQUEST_SALE_ATTRIBUTES";
export const REMOVE_PLUGIN_REQUEST_SALE_ATTRIBUTES = "REMOVE_PLUGIN_REQUEST_SALE_ATTRIBUTES";
export const ADD_PLUGIN_REQUEST_SALE_NOTES = "ADD_PLUGIN_REQUEST_SALE_NOTES";
export const ADD_PLUGIN_REQUEST_SALE_DATETIME = "ADD_PLUGIN_REQUEST_SALE_DATETIME";
export const REMOVE_PLUGIN_REQUEST_SALE_NOTE = "REMOVE_PLUGIN_REQUEST_SALE_NOTE";

export const SET_MIXED_TRANSACTION = "SET_MIXED_TRANSACTION";
export const SET_LOADING = "SET_LOADING";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const SET_PRODUCTS_IN_BASKET = "SET_PRODUCTS_IN_BASKET";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const PRODUCT_NOT_FOUND = "PRODUCT_NOT_FOUND";
export const ADD_UNKNOWN_RFID_PRODUCT = "ADD_UNKNOWN_RFID_PRODUCT";
export const REMOVE_UNKNOWN_RFID_PRODUCT = "REMOVE_UNKNOWN_RFID_PRODUCT";
export const PRODUCT_ADD_ALLOWED = "PRODUCT_ADD_ALLOWED";
export const SECURITY_ITEM_ADDED = "SECURITY_ITEM_ADDED";
export const ADD_PRODUCT_RETURN_REASON_ID = "ADD_PRODUCT_RETURN_REASON_ID";
export const ADD_PRODUCT_CODE_INFO = "ADD_PRODUCT_CODE_INFO";
export const ADD_PRODUCT_RFID_SERIAL = "ADD_PRODUCT_RFID_SERIAL";
export const ADD_PRODUCT_RFID_TAG = "ADD_PRODUCT_RFID_TAG";
export const UPDATE_PRODUCT_NAME = "UPDATE_PRODUCT_NAME";
export const SET_PRODUCT_SELECT_LIST = "SET_PRODUCT_SELECT_LIST";

export const CAFA_API = "CAFA_API";
export const AUTH_API = "AUTH_API";
export const POSLOG_API = "POSLOG_API";

export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";

export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const EPSI = "EPSI";

export const SET_USER = "SET_USER";
export const INIT_POS = "INIT_POS";
export const SET_TRANSACTION_USER = "SET_TRANSACTION_USER";
export const SET_POS_ID = "SET_POS_ID";
export const SET_POS_DATA = "SET_POS_DATA";
export const SET_POS_DATA_FAILED = "SET_POS_DATA_FAILED";
export const UPDATE_USER_SESSION = "UPDATE_USER_SESSION";

export const SET_POS_DAY_OPENED = "SET_POS_DAY_OPENED";
export const POS_OPEN_DAY = "POS_OPEN_DAY";
export const POS_DAY_OPEN_SUCCESS = "POS_DAY_OPEN_SUCCESS";
export const POS_DAY_OPEN_FAILED = "POS_DAY_OPEN_FAILED";
export const POS_DAY_CLOSE = "POS_DAY_CLOSE";
export const POS_DAY_CLOSE_SUCCESS = "POS_DAY_CLOSE_SUCCESS";
export const POS_DAY_CLOSE_FAILED = "POS_DAY_CLOSE_FAILED";
export const OPEN_POS_OPEN_DAY_VIEW = "OPEN_POS_OPEN_DAY_VIEW";

export const SET_WAREHOUSE = "SET_WAREHOUSE";
export const SET_ERPLY_CONF = "SET_ERPLY_CONF";
export const SET_ERPLY_SERVICE_ENDPOINTS = "SET_ERPLY_SERVICE_ENDPOINTS";
export const SET_COMPANY_INFO = "SET_COMPANY_INFO";
export const SET_REASON_CODES = "SET_REASON_CODES";
export const SET_VAT_RATES = "SET_VAT_RATES";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const SET_USER_RIGHTS = "SET_USER_RIGHTS";
export const SET_PRODUCT_GROUPS = "SET_PRODUCT_GROUPS";
export const SET_PRODUCT_CATEGORIES = "SET_PRODUCT_CATEGORIES";
export const UPDATE_PRODUCT_GROUP_PRODUCTS = "UPDATE_PRODUCT_GROUP_PRODUCTS";
export const SET_QUICK_POS_PRODUCTS = "SET_QUICK_POS_PRODUCTS";
export const SET_QUICK_POS_PRODUCTS_ORDER = "SET_QUICK_POS_PRODUCTS_ORDER";
export const ADD_QUICK_POS_PRODUCT = "ADD_QUICK_POS_PRODUCT";
export const SET_EDIT_QUICK_SELECT_PRODUCTS = "SET_EDIT_QUICK_SELECT_PRODUCTS";

export const INIT_TRANSACTION = "INIT_TRANSACTION";
export const CANCEL_TRANSACTION = "CANCEL_TRANSACTION";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_FAILED = "TRANSACTION_FAILED";
export const CHANGE_TRANSACTION_SAVE_STATUS = "CHANGE_TRANSACTION_SAVE_STATUS";
export const INCREMENT_NEXT_INVOICE_NO = "INCREMENT_NEXT_INVOICE_NO";
export const CHANGE_TRANSACTION_MODE = "CHANGE_TRANSACTION_MODE";
export const CHANGE_PRICE_EDIT_MODE = "CHANGE_PRICE_EDIT_MODE";

export const GET_NEXT_SEQUENCE_NUMBER = "GET_NEXT_SEQUENCE_NUMBER";
export const SET_NEXT_SEQUENCE_NUMBER = "SET_NEXT_SEQUENCE_NUMBER";
export const REVERT_NEXT_SEQUENCE_NUMBER = "REVERT_NEXT_SEQUENCE_NUMBER";

export const CASH_IN_OUT_SUCCESS = "CASH_IN_OUT_SUCCESS";

export const CHANGE_VIEW = "CHANGE_VIEW";
export const CHANGE_MODE = "CHANGE_MODE";
export const SET_DEFAULT_CUSTOMER = "SET_DEFAULT_CUSTOMER";
export const SET_PAYMENT_MODE = "SET_PAYMENT_MODE";
export const SET_OPERATION_MODE = "SET_OPERATION_MODE";

export const CUSTOMER_API = "CUSTOMER_API";
export const FIND_CUSTOMER_BY_CODE = "FIND_CUSTOMER_BY_CODE";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const SET_CUSTOMER_CAN_REMOVE = "SET_CUSTOMER_CAN_REMOVE";
export const SET_USED_PRODUCT_CUSTOMER = "SET_USED_PRODUCT_CUSTOMER";
export const REMOVE_CUSTOMER = "REMOVE_CUSTOMER";
export const SET_CUSTOMER_SELECT_LIST = "SET_CUSTOMER_SELECT_LIST";
export const SET_CUSTOMER_AGE_VERIFIED= "SET_CUSTOMER_AGE_VERIFIED";

export const SET_ERROR = "SET_ERROR";

export const SHOW_DIALOGUE = "SHOW_DIALOGUE";
export const CLOSE_DIALOGUE = "CLOSE_DIALOGUE";
export const CLOSE_ALL_DIALOGUES = "CLOSE_ALL_DIALOGUES";

export const SHOW_RIGHT_PANEL_DIALOGUE = "SHOW_RIGHT_PANEL_DIALOGUE";
export const CLOSE_RIGHT_PANEL_DIALOGUE = "CLOSE_RIGHT_PANEL_DIALOGUE";
export const SET_RIGHT_PANEL_FULLSCREEN = "SET_RIGHT_PANEL_FULLSCREEN";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CLOSE_ALL_MODALS = "CLOSE_ALL_MODALS";


export const OPEN_ADMINISTRATION_VIEW = "OPEN_ADMINISTRATION_VIEW";
export const CLOSE_ADMINISTRATION_VIEW = "CLOSE_ADMINISTRATION_VIEW";
export const CHANGE_ADMINISTRATION_VIEW = "CHANGE_ADMINISTRATION_VIEW";

export const SHOW_CALL_ASSISTANCE = "SHOW_CALL_ASSISTANCE";
export const CLOSE_CALL_ASSISTANCE = "CLOSE_CALL_ASSISTANCE";

export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const SET_ADMIN_USER = "SET_ADMIN_USER";

export const ADD_PERIPHERAL = "ADD_PERIPHERAL";
export const UPDATE_PERIPHERAL = "UPDATE_PERIPHERAL";
export const REMOVE_PERIPHERAL = "REMOVE_PERIPHERAL";

export const ADD_INTERVAL = "ADD_INTERVAL";
export const REMOVE_INTERVAL = "REMOVE_INTERVAL";

export const SCANNER_DETECTED = "SCANNER_DETECTED";

export const CLOSE_QCO = "CLOSE_QCO";

export const CANCEL_ADD_UNKNOWN_ITEM = "CANCEL_ADD_UNKNOWN_ITEM";

export const RECEIPT_DELIVERED = "RECEIPT_DELIVERED";
export const PRINT_RECEIPT = "PRINT_RECEIPT";
export const PRINT_HTML = "PRINT_HTML";
export const EMAIL_SENT = "EMAIL_SENT";
export const EMAIL_NOT_SENT = "EMAIL_NOT_SENT";
export const EPSI_REQUEST = "EPSI_REQUEST";

export const SET_PAYMENT_TYPES = "SET_PAYMENT_TYPES";

export const ADD_DOCUMENT_DISCOUNT = "ADD_DOCUMENT_DISCOUNT";
export const REMOVE_DOCUMENT_DISCOUNT = "REMOVE_DOCUMENT_DISCOUNT";
export const ADD_PRODUCT_DISCOUNT_APPROVAL = "ADD_PRODUCT_DISCOUNT_APPROVAL";
export const ADD_DOCUMENT_DISCOUNT_APPROVAL = "ADD_DOCUMENT_DISCOUNT_APPROVAL";
export const UPDATE_PRODUCT_GIFT_CARD_NUMBER = "UPDATE_PRODUCT_GIFT_CARD_NUMBER";

export const OPEN_CASH_DRAWER = "OPEN_CASH_DRAWER";

export const TRANSACTION_TIMEOUT_REACHED = "TRANSACTION_TIMEOUT_REACHED";
export const HANDLE_FISCAL_REQUEST = "HANDLE_FISCAL_REQUEST";
export const SET_FISCAL_RECEIPT_REQUIRED = "SET_FISCAL_RECEIPT_REQUIRED";

export const CREATE_RECEIPT = "CREATE_RECEIPT";
export const CREATE_RECEIPT_NUMBER = "CREATE_RECEIPT_NUMBER";
export const APPLY_RECEIPT_PLUGIN = "APPLY_RECEIPT_PLUGIN";

export const SET_DELAY_RECEIPT = "SET_DELAY_RECEIPT";

export const SET_UI_CUSTOMIZATION = "SET_UI_CUSTOMIZATION";
export const SET_POS_UI_CUSTOMIZATION = "SET_POS_UI_CUSTOMIZATION";

export const SET_LAST_SCANNED_GIFT_CARD = "SET_LAST_SCANNED_GIFT_CARD";
export const ADD_RECEIPT_GIFT_CARD = "ADD_RECEIPT_GIFT_CARD";

export const ADD_BEFORE_COMPLETE_TRANSACTION_ACTION = "ADD_BEFORE_COMPLETE_TRANSACTION_ACTION";
export const REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTION = "REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTION";
export const REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTIONS_BY_TYPE = "REMOVE_BEFORE_COMPLETE_TRANSACTION_ACTIONS_BY_TYPE";

export const SET_PENDING_DOCUMENT = "SET_PENDING_DOCUMENT";
export const SET_INVOICE = "SET_INVOICE";
export const SET_RETURN_BASE_DOCUMENT = "SET_RETURN_BASE_DOCUMENT";
export const SET_RETURN_BASE_POSLOG = "SET_RETURN_BASE_POSLOG";
export const UPDATE_RETURN_BASE_DOCUMENT_PAYMENT = "UPDATE_RETURN_BASE_DOCUMENT_PAYMENT";
export const SET_ADD_RECEIPT_TITLE = "SET_ADD_RECEIPT_TITLE";

export const ADD_RECEIPT_FISCAL_INFO = "ADD_RECEIPT_FISCAL_INFO";
export const ADD_RECEIPT_EXTRA_LINE = "ADD_RECEIPT_EXTRA_LINE";
export const REMOVE_RECEIPT_EXTRA_LINE = "REMOVE_RECEIPT_EXTRA_LINE";

export const ADD_PLUGIN_ADMIN_BUTTON = "ADD_PLUGIN_ADMIN_BUTTON";
export const ADD_PLUGIN_CUSTOMER_SEARCH_BUTTON = "ADD_PLUGIN_CUSTOMER_SEARCH_BUTTON";

export const SET_PAYBACK_CUSTOMER = "SET_PAYBACK_CUSTOMER";
export const SET_PAYBACK_INPUT_DONE = "SET_PAYBACK_INPUT_DONE";
export const REMOVE_PAYBACK_CUSTOMER = "REMOVE_PAYBACK_CUSTOMER";
export const ADD_PAYBACK_COUPON = "ADD_PAYBACK_COUPON";
export const REMOVE_PAYBACK_COUPON = "REMOVE_PAYBACK_COUPON";
export const SET_PAYBACK_REFERENCE_RECEIPT = "SET_PAYBACK_REFERENCE_RECEIPT";
export const SET_PAYBACK_PAY_CODE = "SET_PAYBACK_PAY_CODE";
export const REMOVE_PAYBACK_PAY_CODE = "REMOVE_PAYBACK_PAY_CODE";
export const ADD_MANUAL_PROMOTION_ID = "ADD_MANUAL_PROMOTION_ID";
export const REMOVE_MANUAL_PROMOTION_ID = "REMOVE_MANUAL_PROMOTION_ID";

export const ADD_CAMPAIGN_BY_NAME = "ADD_CAMPAIGN_BY_NAME";

export const ADD_BEFORE_APPLY_PROMOTIONS_EVENT = "ADD_BEFORE_APPLY_PROMOTIONS_EVENT";
export const REMOVE_BEFORE_APPLY_PROMOTIONS_EVENT = "REMOVE_BEFORE_APPLY_PROMOTIONS_EVENT";

export const APPLY_PROMOTIONS = "APPLY_PROMOTIONS";

export const SET_CONNECTION_HEALTH = "SET_CONNECTION_HEALTH";
export const SET_CONNECTION_STATUS = "SET_CONNECTION_STATUS";
export const UPDATE_RFID_STATUS = "UPDATE_RFID_STATUS";
export const CLOSE_RFID_READER = "CLOSE_RFID_READER";
export const SET_ONE_PROMOTION_PROMO_CODE = "SET_ONE_PROMOTION_PROMO_CODE";
export const SET_CUSTOMER_ZIP_CODE = "SET_CUSTOMER_ZIP_CODE";
export const SET_SCREEN_SAVER = "SET_SCREEN_SAVER";
export const SET_SCREEN_SAVER_NEXT_IMAGE = "SET_SCREEN_SAVER_NEXT_IMAGE";
export const SET_HEADER_EXTRA_INFO = "SET_HEADER_EXTRA_INFO";
export const REMOVE_HEADER_EXTRA_INFO = "REMOVE_HEADER_EXTRA_INFO";