import $ from 'jquery';
import parseBarcode from "./BarcodeParser.min.js";
import {PDTB} from "../../util/product";
import {getAttributeValue, ltrim} from "../../util/helperFunctions";
import {APP_MODE_PAYMENT, APP_MODE_SCAN, APP_MODE_SCREEN_SAVER} from "../appConstants";
import {closeModal, openModal} from "../actions";
import Translate from "../../localization/Translate";
import hangerImg from "../../main/images/hanger.png";
import Button from "../../buttons/Button";
import React from "react";

const RFIDService = {
    parameters: () => {
        let conf = window.AppConf?.rfidParameters ?? {};
        return Object.assign({
            hangerMessageTimeout: 10,
            hangerTagCodes: "9999999999994"
        }, conf);
    },
    securityTagCodes: [],
    hangerTagCodes: [],
    handleSecurityTagsAfterScanning: false,
    GTINLength: 14,
    tagReadTimeout: 50,
    available: true,
    connected: false,
    confirmingTransaction: false,
    manuallyAddedProducts: {},
    lastScanned: [],
    serviceInit: false,
    openingDocument: false,
    openedPendingSale: false,
    documentCancelled: false,
    waitingForResponse: false,
    loggingOut: false,
    lastStatus: 'init',
    /**
     * Options: init, ready, reading, paused
     * @type {string}
     */
    status: 'init',
    mode: 'SALE',
    openRetries: 20,
    openTried: 0,
    tagCount: 0,
    currentTagCount: 0,
    currentTagsProcessed: 0,
    lineNumberCounter: 0,
    isServiceTransactionReady: false,
    dayClosed: false,
    currentCallback: false,
    statusCallback: false,

    warehouseCode: "",
    tillNumber: "",
    nextInvoiceNo: "",
    clientCode: "",
    pos: {},
    DKTUnknownItemCode: "",
    productsInBasket: [],
    scanning: false,
    temporaryScanning: false,
    addToScannerQueue: function (code) {
        RFIDService.log('No addToScannerQueue function defined!', code);
    },
    removeProduct: function (product) {
        RFIDService.log('No removeProduct function defined!');
    },
    securityTagFound: function (tag) {
        RFIDService.log('No securityTagFound function defined!');
    },
    setLoading: function () {},
    setStatus: (status) => {
        RFIDService.status = status;
    }
};

RFIDService.updateState = function (scanning, productsInBasket) {
    this.scanning = scanning;
    this.productsInBasket = productsInBasket;
};

RFIDService.retryInitService = function() {
    RFIDService.serviceInit = false;
    RFIDService.connected = false;
    RFIDService.initService(RFIDService.onConnectionSuccess, RFIDService.onConnectionLost);
};

RFIDService.onConnectionSuccess = function() {};
RFIDService.onConnectionLost = function() {};
RFIDService.initRFIDReader = function (callback, connectionLostCallback) {
    if (RFIDService.isAvailable()) {
        RFIDService.isServiceTransactionReady = false;

        RFIDService.openWebSocket().then(function () {
            RFIDService.sendStartCommand(function () {
                RFIDService.connected = true;
                RFIDService.setStatus('ready');
                if(RFIDService.scanning){
                    RFIDService.startTransaction(false, null, callback);
                }else{
                    if(typeof callback !== "undefined"){
                        callback();
                    }
                }
            });
        });
        RFIDService.onConnectionSuccess = callback;
        RFIDService.onConnectionLost = connectionLostCallback;
    }
};

RFIDService.Alert = function(message) {
    RFIDService.log('RFIDService alert: ', message);
    return {
        delayedClose: function () {

        }
    }
};

RFIDService.log = function(){
    let args = Array.from(arguments);
    args.unshift('RFIDService:');
    console.log.apply(null, args);
};

RFIDService.pad = function(a,b){
    return(1e15+a+"").slice(-b)
};

RFIDService.getStoreAndTillNumber = function () {
    RFIDService.log('getStoreAndTillNumber', RFIDService.warehouseCode, RFIDService.tillNumber);
    return RFIDService.pad(RFIDService.warehouseCode, 5) + ';' + RFIDService.pad(RFIDService.tillNumber, 2);
};

RFIDService.updateLineNumber = function (add) {
    if(add){
        RFIDService.lineNumberCounter++;
    }else{
        RFIDService.lineNumberCounter--;
    }
};

RFIDService.getLocalStorageTillName = function () {
    return RFIDService.clientCode + '-' + RFIDService.pos.warehouseID + '-' + RFIDService.pos.pointOfSaleID;
};

RFIDService.savedInvoiceNo = false;

RFIDService.getInvoiceNo = function() {
    var currentNumber = '';
    if(RFIDService.savedInvoiceNo !== false){
        currentNumber = RFIDService.trimChar(RFIDService.savedInvoiceNo.toString(), 'K');
    }else{
        currentNumber = RFIDService.nextInvoiceNo.toString();
    }
    return currentNumber.substr(currentNumber.length - 6);
};

RFIDService.getWebSocketHost = function () {
    return "ws://localhost:9080/ws";
};

RFIDService.openWebSocket = function () {
    RFIDService.log('websocket Opening socket');
    return new Promise(function (resolve, reject) {
        RFIDService.websocket = new WebSocket(RFIDService.getWebSocketHost());
        RFIDService.websocket.onopen = function () {
            RFIDService.log('websocket onopen');
            resolve();
        };
        RFIDService.websocket.onmessage = function (response) {
            RFIDService.log('websocket: Message is received:', response.data);
            const data = JSON.parse(response.data);
            if(typeof data.data !== 'undefined'){
                RFIDService.respondToStatus(data.data);
            }else if(typeof data.error !== 'undefined'){
                RFIDService.setUnavailable();
            }
        };
        RFIDService.websocket.onclose = function () {
            RFIDService.log('websocket onclose');
            RFIDService.setUnavailable();
        }
    });
};

RFIDService.connectionIsOpen = function(){
    return RFIDService.websocket && RFIDService.websocket.readyState === RFIDService.websocket.OPEN;
};

RFIDService.addCurrentLineNumberAndTransactionToLocalStorage = function (overwrite = false) {
    const invoice = RFIDService.getInvoiceNo();
    let line = RFIDService.lineNumberCounter;
    const lastTransaction = RFIDService.getLastTransactionNumberAndLineFromLocalStorage();
    if(!overwrite && lastTransaction !== false && lastTransaction.transaction == invoice){
        line += parseInt(lastTransaction.line);
    }
    localStorage.setItem('RFIDLastTransactionLine' + RFIDService.getLocalStorageTillName(), invoice + '-' + line);
};

RFIDService.getLastTransactionNumberAndLineFromLocalStorage = function () {
    const data = localStorage.getItem('RFIDLastTransactionLine' + RFIDService.getLocalStorageTillName());
    if(data === null){
        return false;
    }
    let parts = data.split('-');
    return {
        transaction: parts[0],
        line: parts[1]
    }
};

RFIDService.sendSetEnableDevice = function (value, callback) {
    RFIDService.RFIDApiRequest('<SET_ENABLE_DEVICE;' + value + '>', callback);
};

RFIDService.initTransaction = function(){
    RFIDService.tagCount = 0;
    RFIDService.lineNumberCounter = 0;
    RFIDService.RFIDProducts = {};
    RFIDService.securityTags = [];
    RFIDService.hangerTags = [];
    RFIDService.hangerTagsConfirm = [];
    RFIDService.manuallyAddedProducts = {};
};

RFIDService.startTransaction = function(continueTransaction, document, callback) {
    if(!RFIDService.isAvailable()){
        return false;
    }
    RFIDService.log('start transaction called. Status: ', RFIDService.status, {continueTransaction, document});
    if(RFIDService.status === 'starting' || RFIDService.status === 'reading'){
        return true;
    }else if(RFIDService.status !== 'paused' && RFIDService.status !== 'ready'){
        return true;
    }
    RFIDService.setStatus('starting');
    if(typeof continueTransaction === 'undefined'){
        continueTransaction = false;
    }
    if (RFIDService.connectionIsOpen()) {
        RFIDService.gettingProductsFromRFIDStopped = false;
        if(!continueTransaction) {
            RFIDService.tagCount = 0;
            RFIDService.lineNumberCounter = 0;
            RFIDService.RFIDProducts = {};
            RFIDService.manuallyAddedProducts = {};
        }
        if(typeof document !== 'undefined' && document !== null){
            RFIDService.startTransaction2(callback, document);
        }else{
            RFIDService.startTransaction2(callback);
        }
    }
};

RFIDService.startRetries = 0;
RFIDService.startTransaction2 = function (callback, document) {
    let tags = "";
    if(typeof document !== 'undefined' && document !== null){
        let RFIDTags = RFIDService.getTagsFromDocumentAttributes(document.attributes);
        let tagsArray = RFIDTags.map(tag => {
            return RFIDService.getTransactionTagString(tag, tag.NUM_LINE) + tag.ITEM_TRANSACTION_MODE;
        });
        if(tagsArray.length > 0){
            tags += ';' + tagsArray.length + ';' + tagsArray.join(';');
            RFIDService.lineNumberCounter = tagsArray.length;
        }
    }

    RFIDService.RFIDApiRequest('<START_TRANSACTION;' + RFIDService.getInvoiceNo() + ';MIXED;' +
        RFIDService.getStoreAndTillNumber() + tags + '>', function (success, response) {
        RFIDService.log('startTransaction response', success, response);
        if(success){
            if(response[1] == 'SUCCESS'){
                RFIDService.sendSetDataEventEnable(function (success) {
                    RFIDService.setStatus('reading');
                    if(success && typeof callback !== 'undefined'){
                        callback();
                    }
                });
            }else if(response[1] === "RFID_READ_ALREADY_STARTED"){
                RFIDService.setStatus('reading');
                if(typeof callback !== 'undefined'){
                    callback();
                }
            }else{
                if(RFIDService.status === 'starting'){
                    RFIDService.setStatus('paused');
                }
            }
        }
    });
};

RFIDService.sendStartCommand = function(callback) {
    RFIDService.log('sendStartCommand, callback: ', callback);

    RFIDService.currentCallback = function (success, response_data) {
        RFIDService.log('sendStartCommandResponse', success, response_data);
        if(success){
            RFIDService.connectionStatus = 'open';
            RFIDService.openTried = 0;
            setTimeout(function () {
                RFIDService.RFIDApiRequest('<CLAIM>', function (success, response_data) {
                    RFIDService.log('claim callback', success, response_data);
                    if(!success){
                        return false;
                    }
                    RFIDService.setInitialized(false);
                    setTimeout(function () {
                        RFIDService.sendSetEnableDevice('TRUE', function () {
                            setTimeout(function () {
                                RFIDService.setAutoDisableTrue(function () {
                                    callback();
                                });
                            }, 200);
                        });
                    }, 200);
                });
            },1000);

        }else{
            RFIDService.openTried++;
            if(RFIDService.openTried < RFIDService.openRetries){
                RFIDService.sendOpenCommand();
            }else{
                RFIDService.closeConnection(function () {
                    RFIDService.setUnavailable();
                });
            }
        }
    };
    RFIDService.sendOpenCommand();
};

RFIDService.sendOpenCommand = function () {
    RFIDService.RFIDApiRequest('<OPEN;YES;' + RFIDService.getStoreAndTillNumber() + '>', RFIDService.currentCallback);
};

RFIDService.setInitialized = function (lastStatus) {
    RFIDService.log('setInitialized', lastStatus, RFIDService.lastStatus);
    RFIDService.isServiceTransactionReady = true;
    RFIDService.setStatus(lastStatus ? RFIDService.lastStatus : 'ready');
};

RFIDService.respondToStatus = function(response_data) {
    RFIDService.log('Respond to status: ', response_data);
    const response = (RFIDService.trimBeaks(response_data)).split(';');
    if(response[0] == 'DATA'){
        if(response[1] == 'SUCCESS' || response[1] == 'FAIL'){
            if(response[1] == 'FAIL'){
                //RFIDService.lineNumberCounter = 0;
            }
            RFIDService.startRFIDReadLoop();
            return true;
        }
    }
    if(response_data.indexOf('RFID_READER_NOT_AVAILABLE') !== -1){
        RFIDService.log('reader is not available!', response);
        RFIDService.currentCallback(false, response);
    }else if (response_data.indexOf('STATUS') === -1 && response_data.indexOf('ERROR') === -1) {
        RFIDService.currentCallback(true, response);
    } else if (response_data.indexOf('ERROR') !== -1) {
        RFIDService.log('ERROR-----------------', response_data);
        if(response[1] === 'TIMEOUT_REACHED'){
            if(RFIDService.status !== 'pausing'){
                RFIDService.setStatus('paused');

                RFIDService.statusCallback = function () {
                    RFIDService.statusCallback = false;
                };

                if(typeof RFIDService.onDeviceTimeout === "function"){
                    RFIDService.onDeviceTimeout();
                }
            }
            return true;
        }
        RFIDService.currentCallback(false, response);
    } else if(response[0] === 'STATUS_UPDATE'){
        if(response[1] === 'CONNECTED_IDLE'){
            if(RFIDService.statusCallback !== false){
                RFIDService.statusCallback(response[1]);
            }
        }
    }
    RFIDService.waitingForResponse = false;
    RFIDService.handlePendingApiRequests();
};

RFIDService.endTransaction = function(callback) {
    if (RFIDService.connectionIsOpen()) {
        RFIDService.gettingProductsFromRFIDStopped = true;
        RFIDService.log('sending confirmTransaction');
        RFIDService.sendConfirmTransaction(function () {
            RFIDService.log('confirmTransaction callback');
            RFIDService.savedInvoiceNo = false;
            RFIDService.confirmingTransaction = false;
            callback();
        });
    }
};

RFIDService.afterPauseTransaction = function (callback) {
    RFIDService.setStatus('paused');
    callback();
};

RFIDService.pauseTransaction = function (callback) {
    if(RFIDService.status === 'paused' || RFIDService.status === 'pausing'){
        callback();
        return true;
    }
    RFIDService.setStatus('pausing');
    RFIDService.statusCallback = function () {
        RFIDService.afterPauseTransaction(callback);
        RFIDService.statusCallback = false;
    };
    RFIDService.gettingProductsFromRFIDStopped = true;
    RFIDService.sendSetDataEventEnable(function () {
        RFIDService.RFIDApiRequest('<PAUSE_TRANSACTION;' + RFIDService.getTransactionPOSInfoString() + '>', function (success, response) {
            if(response[1] === 'RFID_READER_NOT_OPENED') {
                RFIDService.setStatus('init');
                RFIDService.statusCallback = false;
                RFIDService.initRFIDReader();
                return false;
            }
            // Wait for status update!
            setTimeout(function () {
                if(RFIDService.status === 'pausing'){
                    RFIDService.afterPauseTransaction(callback);
                    RFIDService.statusCallback = false;
                }
            }, 5000);
        });
    }, 'FALSE');
};

RFIDService.closeRFIDReader = function (callback) {
    RFIDService.log('closeRFIDReader');
    if (RFIDService.isServiceTransactionReady) {
        RFIDService.isServiceTransactionReady = false;
        RFIDService.sendReleaseCommand(function () {
            RFIDService.addCurrentLineNumberAndTransactionToLocalStorage();
            setTimeout(function(){
                RFIDService.sendCloseCommand(callback);
            }, RFIDService.tagReadTimeout);
        });
    }else{
        RFIDService.sendCloseCommand(callback);
    }
};

RFIDService.sendSetDataEventEnable = function(callback, value, force = false) {
    if(typeof value === 'undefined'){
        value = 'TRUE';
    }
    if(RFIDService.gettingProductsFromRFID && !force) {
        callback(false);
        return false;
    }

    RFIDService.RFIDApiRequest('<SET_DATA_EVENT_ENABLE;' + value + '>', function (success, response) {
        callback(true);
    });
};

RFIDService.sendGetTagCount = function(callback) {
    RFIDService.RFIDApiRequest('<TAG_COUNT>', function (success, response) {
        if(success){
            RFIDService.tagCount += parseInt(response[2]);
            var thisTagCount = response[2];
            RFIDService.log('tag count: ', thisTagCount);
            if(thisTagCount != 0) {
                RFIDService.currentTagCount = thisTagCount;
                RFIDService.log('tagCount is not 0');
                setTimeout(function(){
                    callback(RFIDService.currentTagCount);
                }, RFIDService.tagReadTimeout);
            }else{
                callback(false);
            }
        }
    });
};

RFIDService.sendFirstTag = function(callback) {
    RFIDService.RFIDApiRequest('<FIRST_TAG>', function (success, response) {
        setTimeout(function(){
            callback();
        }, RFIDService.tagReadTimeout);
    });
};

RFIDService.getTagCurrentID = function(process, callback) {
    RFIDService.RFIDApiRequest('<CURRENT_TAG_ID>', function (success, response) {
        if(success){
            RFIDService.log('getTagCurrentID success', response[2]);
            var tag = {
                GTIN: response[2],
                SGTIN: response[3],
                SERIAL_NUMBER: response[4],
                EPC: response[5],
                NUM_LINE: response[6],
                ITEM_TRANSACTION_MODE: RFIDService.mode //response[7]
            };

            RFIDService.currentTagsProcessed++;
            RFIDService.updateLineNumber(true);
            tag.NUM_LINE = RFIDService.lineNumberCounter;

            if(response[2] !== 'NULL_VALUE'){
                process(tag);
            }
            setTimeout(function(){
                callback(tag);
            }, RFIDService.tagReadTimeout);
        }
    }, true);
};

RFIDService.getNextTag = function(process, callback) {
    RFIDService.RFIDApiRequest('<NEXT_TAG>', function (success, response) {
        setTimeout(function(){
            RFIDService.getTagCurrentID(process, callback);
        }, RFIDService.tagReadTimeout);
    });
};

RFIDService.addItem = function (tag, callback, product) {
    function add(tag, callback) {
        if(RFIDService.gettingProductsFromRFID){
            RFIDService.log('waiting to add item');
            setTimeout(() => {
                add(tag, callback);
            }, 500);
        }else{
            add2(tag, callback);
        }
    }

    function add2(tag, callback) {
        RFIDService.log('Adding item');
        RFIDService.updateLineNumber(true);
        tag.NUM_LINE = RFIDService.lineNumberCounter;

        RFIDService.RFIDApiRequest('<ADD_ITEM;' + RFIDService.getTransactionPOSInfoString() + ';' + tag['GTIN'] + ';' + tag['SGTIN'] +
            ';' + tag['SERIAL_NUMBER'] + ';' + tag['EPC'] + ';' + tag['NUM_LINE'] + ';' + tag['ITEM_TRANSACTION_MODE'] + '>', function (success, response) {
            if(success && response[1] == 'SUCCESS'){
                setTimeout(function () {
                    RFIDService.sendAckAddItemCommand(tag['NUM_LINE'], callback);
                }, RFIDService.tagReadTimeout);
            }else{
                RFIDService.log('Add item response: ', response);
                RFIDService.pendingAck = false;
                if(response[1] == 'TAG_ALREADY_IN_TRANSACTION'){
                    RFIDService.log('Add item TAG_ALREADY_IN_TRANSACTION');
                    RFIDService.Alert('Tag already in transaction').delayedClose(2.5);
                    RFIDService.lastCancelledTag = tag;
                    callback(false);
                }
            }
        }, true);
    }

    add(tag, callback);
};

RFIDService.sendAckAddItemCommand = function(lineNumber, callback) {
    RFIDService.log('sending Ack add item command!');
    RFIDService.RFIDApiRequest('<ACK;ADD_ITEM;' + lineNumber + '>');
    setTimeout(function(){
        callback(true);
    }, RFIDService.tagReadTimeout);
};

RFIDService.sendAckCommand = function(process, callback, tag) {
    if(Object.keys(RFIDService.manuallyAddedProducts).length > 0){
        if(typeof RFIDService.manuallyAddedProducts[tag.GTIN] !== 'undefined'){
            if(RFIDService.manuallyAddedProducts[tag.GTIN].data.RFIDTag.acked === true){
                RFIDService.manuallyAddedProducts = {};
            }else{
                RFIDService.manuallyAddedProducts[tag.GTIN].data.RFIDTag.acked = true;
            }
        }
    }
    RFIDService.log('sending Ack command!');
    RFIDService.RFIDApiRequest('<ACK;CURRENT_TAG_ID;' + RFIDService.lineNumberCounter + '>');

    function processOtherTags(){
        setTimeout(function(){
            if(RFIDService.currentTagCount > RFIDService.currentTagsProcessed) {
                RFIDService.getNextTag(process, function (tag) {
                    RFIDService.sendAckCommand(process, callback, tag);
                });
            } else {
                callback();
            }
        }, RFIDService.tagReadTimeout);
    }

    setTimeout(function () {
        if(RFIDService.mode === 'RETURN'){
            RFIDService.updateArticle('RETURN', RFIDService.lineNumberCounter, function (articleUpdated) {
                processOtherTags();
            });
        }else{
            processOtherTags();
        }

    }, RFIDService.tagReadTimeout);
};

RFIDService.sendAckCancelCommand = function(lineNumber, callback) {
    RFIDService.log('sending Ack cancel command!');
    RFIDService.RFIDApiRequest('<ACK;CANCEL_ITEM;' + lineNumber + '>');

    setTimeout(function(){
        callback();
    }, RFIDService.tagReadTimeout);
};

RFIDService.getTransactionPOSInfoString = function() {
    return RFIDService.getInvoiceNo() + ';' + RFIDService.getStoreAndTillNumber();
};

RFIDService.getTransactionTagString = function(tag, rowNumber) {
    return tag.GTIN + ';' + tag.SGTIN + ';' + tag.SERIAL_NUMBER + ';' + tag.EPC + ';' + rowNumber + ';';
};

RFIDService.generateConfirmTransactionCommand = function() {
    var tags = [];
    $(RFIDService.productsInBasket).each(function (index, product) {
        if(typeof product.RFIDTag !== "undefined"){
            let tag = product.RFIDTag;
            tags.push( RFIDService.getTransactionTagString(tag, tag.NUM_LINE) + tag.ITEM_TRANSACTION_MODE);
        }
    });

    $(RFIDService.securityTags).each(function (index, tag) {
        tags.push( RFIDService.getTransactionTagString(tag, tag.NUM_LINE) + tag.ITEM_TRANSACTION_MODE);
    });

    $(RFIDService.hangerTagsConfirm).each(function (index, tag) {
        tags.push( RFIDService.getTransactionTagString(tag, tag.NUM_LINE) + tag.ITEM_TRANSACTION_MODE);
    });

    console.log({tags});
    return '<CONFIRM_TRANSACTION;' + RFIDService.getTransactionPOSInfoString() + ';' + RFIDService.productsInBasket.length + ';' + tags.join(';') + '>';
};

RFIDService.sendConfirmTransaction = function(callback) {
    RFIDService.RFIDApiRequest(RFIDService.generateConfirmTransactionCommand(), function (success, response) {
        setTimeout(function(){
            callback();
        }, RFIDService.tagReadTimeout);
    });
};

RFIDService.sendReleaseCommand = function(callback) {
    RFIDService.RFIDApiRequest('<RELEASE>', function (success, response) {
        callback();
    });
};

RFIDService.sendCloseCommand = function(callback) {
    RFIDService.RFIDApiRequest('<CLOSE>', function (success, response) {
        RFIDService.isServiceTransactionReady = false;
        RFIDService.log('close: ', response);
        RFIDService.closeConnection(function () {
            RFIDService.updateRFIDServiceStatusInLocalStorage();
            RFIDService.setStatus('closed');
            callback();
        });
    });
};

RFIDService.connectionStatus = 'closed';
RFIDService.closeConnection = function (callback) {
    if(RFIDService.websocket){
        RFIDService.websocket.close();
    }

    setTimeout(function () {
        RFIDService.log('connection closed!');
        RFIDService.connectionStatus = 'closed';
        callback(true);
    }, 500);

};

RFIDService.trimChar = function(s, c) {
    if (c === "]") c = "\\]";
    if (c === "\\") c = "\\\\";
    return s.replace(new RegExp(
        "^[" + c + "]+|[" + c + "]+$", "g"
    ), "");
};

RFIDService.trimBeaks = function(string) {
    if(typeof string === 'undefined'){
        return 'NO MESSAGE';
    }
    return RFIDService.trimChar(RFIDService.trimChar(string, '<'), '>');
};

RFIDService.gettingProductsFromRFID = false;
RFIDService.gettingProductsFromRFIDStopped = true;

RFIDService.startRFIDReadLoop = function() {
    RFIDService.log('Starting RFID read loop');
    RFIDService.gettingProductsFromRFIDStopped = false;
    (function () {
        function codeBlock(){
            if(RFIDService.isServiceTransactionReady && !RFIDService.gettingProductsFromRFIDStopped){
                if(!RFIDService.gettingProductsFromRFID){
                    RFIDService.gettingProductsFromRFID = true;
                    RFIDService.getProductsFromRFID();
                }else{
                    setTimeout(codeBlock, 200);
                }
            }
        }
        codeBlock();
    })();
};

RFIDService.getProductsFromRFID = function() {
    RFIDService.setLoading(true);
    RFIDService.sendGetTagCount(function (currentTagCount) {
        if(currentTagCount === false){
            RFIDService.gettingProductsFromRFID = false;
            RFIDService.gettingProductsFromRFIDStopped = true;
        }else{
            RFIDService.sendFirstTag(function () {
                RFIDService.getTagCurrentID(RFIDService.addProductFromRFID, function (tag) {
                    RFIDService.sendAckCommand(RFIDService.addProductFromRFID, function () {
                        RFIDService.sendSetDataEventEnable(function () {
                            RFIDService.currentTagCount = 0;
                            RFIDService.currentTagsProcessed = 0;
                            RFIDService.gettingProductsFromRFID = false;
                            RFIDService.setLoading(false);
                            RFIDService.gettingProductsFromRFIDStopped = true;
                        }, 'TRUE', true);
                    }, tag);
                });
            });
        }

    });
};

RFIDService.RFIDProducts = {};
RFIDService.securityTags = [];
RFIDService.hangerTags = [];
RFIDService.hangerTagsConfirm = [];

RFIDService.addProductFromRFID = function(tag) {
    RFIDService.log('Adding products from RFID', tag);
    if(RFIDService.securityTagCodes.indexOf(tag.GTIN) !== -1){
        RFIDService.securityTags.push(tag);
        RFIDService.securityTagFound(tag);
        return true;
    }
    if(RFIDService.hangerTagCodes.indexOf(tag.GTIN) !== -1){
        RFIDService.hangerTags.push(tag);
        return true;
    }
    if(RFIDService.scanning || RFIDService.temporaryScanning){
        let code = PDTB.tryAllpatterns(tag.GTIN);
        if(code !== tag.GTIN){
            tag.ITEM_ID = code;
        }
        RFIDService.log('scannerQueueCode: ' + code);
        RFIDService.addToScannerQueue(code);
        if (typeof RFIDService.RFIDProducts[code] === 'undefined'){
            RFIDService.RFIDProducts[code] = [];
        }
        RFIDService.RFIDProducts[code].push(tag);
    }
};

RFIDService.cancelLineRetries = 0;
RFIDService.lastCancelledTag = false;

RFIDService.cancelRFIDLine = function(RFIDTag, callback) {
    RFIDService.log('cancelRFIDLine tag', RFIDTag);
    if(RFIDService.lastCancelledTag !== false){
        RFIDService.log('has last cancelled tag', RFIDService.lastCancelledTag.SERIAL_NUMBER, RFIDTag.SERIAL_NUMBER);
        if(RFIDService.lastCancelledTag.SERIAL_NUMBER === RFIDTag.SERIAL_NUMBER && RFIDTag.SERIAL_NUMBER !== "NULL_VALUE"){
            RFIDService.lastCancelledTag = false;
            callback();
            return false;
        }
    }
    RFIDService.lastCancelledTag = Object.assign({}, RFIDTag);

    RFIDService.gettingProductsFromRFID = true;
    RFIDService.RFIDApiRequest('<CANCEL_ITEM;' + RFIDService.getTransactionPOSInfoString() + ';' +
        RFIDService.getTransactionTagString(RFIDTag, RFIDTag.NUM_LINE) + RFIDTag.ITEM_TRANSACTION_MODE + '>',
        function (response, response_data) {
            if(response_data[1] == 'WRONG_LINE_NUMBER_FOR_ACK'){
                RFIDService.lastCancelledTag = false;
                if(RFIDService.cancelLineRetries < 100){
                    if(RFIDService.cancelLineRetries == 0){
                        const lastTransaction = RFIDService.getLastTransactionNumberAndLineFromLocalStorage();
                        if(lastTransaction.transaction == RFIDService.getInvoiceNo()){
                            RFIDTag.NUM_LINE += parseInt(lastTransaction.line);
                        }
                    }else{
                        RFIDTag.NUM_LINE = RFIDService.cancelLineRetries;
                    }
                    RFIDService.cancelLineRetries++;
                    RFIDService.cancelRFIDLine(RFIDTag, callback);
                }else{
                    RFIDService.pendingAck = false;
                    RFIDService.cancelLineRetries = 0;
                    callback();
                }
            }else{
                RFIDService.cancelLineRetries = 0;
                RFIDService.sendAckCancelCommand(RFIDTag.NUM_LINE, function () {
                    RFIDService.gettingProductsFromRFID = false;
                    RFIDService.tagCount -= 1;
                    callback();
                });
            }
        }, true);
};

RFIDService.productRemoveTags = [];
RFIDService.cancelAllLines = function (callback, productsInBasket = false) {
    if(productsInBasket === false){
        productsInBasket = RFIDService.productsInBasket;
    }
    RFIDService.log('cancel all lines');
    RFIDService.manuallyAddedProducts = {};
    RFIDService.productRemoveTags = [];
    productsInBasket.forEach((product) => {
        if(typeof product.RFIDTag !== "undefined"){
            const tag = Object.assign({}, product.RFIDTag);
            RFIDService.productRemoveTags.push(tag);
        }
    });
    RFIDService.hangerTags.forEach((hangerTag) => {
        RFIDService.productRemoveTags.push(hangerTag);
    });
    RFIDService.hangerTags = [];
    RFIDService.hangerTagsConfirm = [];
    RFIDService.securityTags.forEach((securityTag) => {
        RFIDService.productRemoveTags.push(securityTag);
    });
    RFIDService.securityTags = [];
    if(RFIDService.productRemoveTags.length === 0){
        callback();
        return true;
    }
    RFIDService.sendSetDataEventEnable(function () {
        setTimeout(function () {
            RFIDService.cancelAllLines2(callback);
        }, 400);
    }, 'FALSE');
};
RFIDService.cancelAllLines2 = function (callback) {
    if(RFIDService.productRemoveTags.length === 0){
        callback();
        return true;
    }
    var tag = RFIDService.productRemoveTags.pop();
    RFIDService.cancelRFIDLine(tag, function () {
        RFIDService.cancelAllLines2(callback);
    });
};

RFIDService.cancelAllSecurityTagLines = () => {
    if(RFIDService.securityTags.length === 0){
        return true;
    }

    const tag = RFIDService.securityTags.pop();
    RFIDService.cancelRFIDLine(tag, () => {
        console.log('RFID: security tag line cancelled');
        RFIDService.lastCancelledTag = false;
        RFIDService.cancelAllSecurityTagLines();
    });
}
RFIDService.cancelAllHangerTagLines = (sendToConfirmList, callback) => {
    if(RFIDService.hangerTags.length === 0){
        callback();
        return true;
    }

    const tag = RFIDService.hangerTags.pop();
    if(sendToConfirmList){
        if(typeof RFIDService.hangerTagsConfirm.find(el => el.EPC === tag.EPC) === "undefined"){
            RFIDService.hangerTagsConfirm.push(tag);
        }
    }
    RFIDService.cancelRFIDLine(tag, () => {
        RFIDService.lastCancelledTag = false;
        RFIDService.cancelAllHangerTagLines(sendToConfirmList, callback);
    });
}

RFIDService.setAutoDisableTrue = function (callback) {
    RFIDService.RFIDApiRequest('<SET_AUTO_DISABLE;TRUE>', callback);
};

RFIDService.isAvailable = function () {
    return RFIDService.available;
};

RFIDService.isConnected = function() {
    return RFIDService.connected;
};

RFIDService.setUnavailable = function () {
    RFIDService.log('setting unavailable!');
    RFIDService.available = false;
    RFIDService.Alert('RFID not available!').delayedClose(3);
    RFIDService.setStatus('not available');
    if(typeof RFIDService.onConnectionLost === "function"){
        RFIDService.onConnectionLost();
    }
    if(!RFIDService.loggingOut){
        setTimeout(function () {
            if(!RFIDService.isAvailable()){
                RFIDService.retryInitService();
            }
        }, 10000);
    }
};


RFIDService.updateArticle = function (type, lineNumber, callback) {
    RFIDService.RFIDApiRequest('<UPDATE_ARTICLE;' + type + ';' + lineNumber + '>', function (success, response) {
        RFIDService.log('update article', success, response);
        if(response[1] === 'SUCCESS'){
            RFIDService.RFIDApiRequest('<ACK;UPDATE_ARTICLE;' + lineNumber + '>');
            callback(true);
        }else {
            RFIDService.pendingAck = false;
            callback(false);
        }
    }, true);
};


RFIDService.pendingApiRequests = [];
RFIDService.pendingAck = false;
RFIDService.handlePendingApiRequests = function() {
    setTimeout(() => {
        if(!RFIDService.pendingAck && RFIDService.pendingApiRequests.length > 0){
            let request = RFIDService.pendingApiRequests.shift();
            RFIDService.log('handlePendingApiRequests', request.command);
            RFIDService.RFIDApiRequest(request.command, request.callback, request.waitForAck);
        }
    }, RFIDService.tagReadTimeout);
};

RFIDService.RFIDApiRequest = function(command, callback = ()=>{}, waitForAck = false) {
    RFIDService.log('api request: ', {command, isAvailable: RFIDService.isAvailable()});
    RFIDService.log('pending ack: ', RFIDService.pendingAck, 'waiting for response: ', RFIDService.waitingForResponse);
    if(!RFIDService.isAvailable() || RFIDService.websocket === undefined){
        return false;
    }

    let isAckCommand = command.indexOf('<ACK;') !== -1;

    if((RFIDService.waitingForResponse || RFIDService.pendingAck) && !isAckCommand){
        RFIDService.pendingApiRequests.push({
            command,
            callback,
            waitForAck
        });
    }else{
        RFIDService.waitingForResponse = true;
        RFIDService.currentCallback = callback;
        RFIDService.websocket.send(command);
        RFIDService.pendingAck = waitForAck;
    }

    if(isAckCommand){
        RFIDService.waitingForResponse = false;
        RFIDService.handlePendingApiRequests();
    }
};

RFIDService.changeStatus = function () {
    switch(RFIDService.status){
        case 'init':
            return false;
        case 'not available':
            return false;
        case 'ready':
            RFIDService.startTransaction();
            break;
        case 'reading':
            if(!RFIDService.gettingProductsFromRFID){
                RFIDService.pauseTransaction(function () {});
            }
            break;
        case 'paused':
            RFIDService.startTransaction(true);
            break;
        case 'closed':
            RFIDService.initRFIDReader();
    }
};

RFIDService.getTagsFromDocumentAttributes = function (attributes) {
    let tags = [];
    $(attributes).each(function (index, attribute) {
        if(attribute.attributeName.indexOf('RFIDTag') == 0){
            const tag = JSON.parse(attribute.attributeValue);
            tags.push(tag);
        }
    });
    return tags;
};

RFIDService.initSequenceStarted = false;
RFIDService.initService = function (callback, connectionLostCallback) {
    if(RFIDService.serviceInit){
        return true;
    }
    if(typeof this.warehouseCode !== "string" || this.warehouseCode === "" || this.tillNumber === ""){
        RFIDService.log('initService: can\'t init yet!');
        return true;
    }
    RFIDService.log('initService, status: ', RFIDService.status, RFIDService.initSequenceStarted);
    if(RFIDService.initSequenceStarted){
        return true;
    }
    RFIDService.available = true;

    RFIDService.initRFIDReader(callback, connectionLostCallback);

    window.addEventListener("beforeunload", function (event) {
        alert("beforeunload rfid status: " + RFIDService.status);
        if(RFIDService.status !== 'closed'){
            RFIDService.updateRFIDServiceStatusInLocalStorage();
            RFIDService.addCurrentLineNumberAndTransactionToLocalStorage();
        }
    });

    RFIDService.serviceInit = true;
    RFIDService.initSequenceStarted = false;
};

RFIDService.updatePOSInformation = function (warehouseCode, tillNumber, nextInvoiceNo, clientCode, pos, DKTUnknownItemCode){
    RFIDService.warehouseCode = warehouseCode;
    RFIDService.tillNumber =  tillNumber;
    RFIDService.nextInvoiceNo = nextInvoiceNo;
    RFIDService.clientCode = clientCode;
    RFIDService.pos = pos;
    RFIDService.DKTUnknownItemCode = DKTUnknownItemCode;
};

RFIDService.updateRFIDServiceStatusInLocalStorage = function () {
    localStorage.setItem('RFIDServiceStatus' + RFIDService.getLocalStorageTillName(), RFIDService.status);
};

RFIDService.getServiceStateFromLocalStorage = function (callback) {
    const status = localStorage.getItem('RFIDServiceStatus' + RFIDService.getLocalStorageTillName());
    RFIDService.log('getting state from local storage', status);
    if(status !== null){
        RFIDService.lastStatus = status;
        RFIDService.setStatus(status);
    }
    if(RFIDService.status === 'reading'){
        callback();
    }else{
        RFIDService.setStatus('init');
        callback();
    }
};

RFIDService.POSEvents = {
    posMode: false,
    setPosMode: function(mode){
        if(RFIDService.POSEvents.posMode === APP_MODE_PAYMENT && mode === APP_MODE_SCAN){
            RFIDService.confirmingTransaction = false;
        }
        RFIDService.POSEvents.posMode = mode;
    },
    afterDocumentSave: function (invoiceNo) {
        if(!RFIDService.isAvailable()) return false;

        RFIDService.log('Event: afterDocumentSave, invoiceNo: ' + invoiceNo);
        RFIDService.savedInvoiceNo = invoiceNo;
        RFIDService.endTransaction(function () {
            setTimeout(function () {
                RFIDService.initTransaction();
                if(RFIDService.POSEvents.posMode === APP_MODE_SCAN || RFIDService.POSEvents.posMode === APP_MODE_SCREEN_SAVER){
                    RFIDService.startTransaction();
                }
            }, RFIDService.tagReadTimeout);
        });
    },
    afterDocumentCancelled: function (productsInBasket = false) {
        if(!RFIDService.isAvailable()) return false;

        RFIDService.log('cancelled document');
        RFIDService.documentCancelled = true;
        RFIDService.pauseTransaction(function () {
            RFIDService.gettingProductsFromRFIDStopped = true;
            RFIDService.cancelAllLines(function () {
                setTimeout(function () {
                    if (RFIDService.status === 'reading') {
                        RFIDService.sendSetDataEventEnable(function () {}, 'TRUE');
                    }else if(RFIDService.status === 'paused'){
                        if(RFIDService.scanning && !RFIDService.openingDocument){
                            RFIDService.startTransaction(true);
                        }
                    }
                    RFIDService.documentCancelled = false;
                }, 400);
            }, productsInBasket);
        });
    },
    afterProductSearchNotFound: function (searchParam) {
        if(!RFIDService.isAvailable()) return false;

        if(searchParam !== RFIDService.DKTUnknownItemCode && typeof searchParam !== 'undefined'){
            const tags = RFIDService.RFIDProducts[searchParam];
            if(typeof tags !== 'undefined' && tags.length > 0){
                /*if(typeof RFIDService.RFIDProducts['unknown'] === 'undefined'){
                    RFIDService.RFIDProducts['unknown'] = [];
                }
                RFIDService.RFIDProducts['unknown'].push(searchParam);*/
                return tags[tags.length - 1];
            }
        }
        return false;
    },
    productAddAllowed: function (product, addRFIDTag, updateProductName) {
        if(!RFIDService.isAvailable()) return true;

        RFIDService.log('product_add_allowed', product);
        RFIDService.log('typeof manually added', typeof RFIDService.manuallyAddedProducts[product.code2]);
        if(typeof RFIDService.manuallyAddedProducts[product.code2] !== 'undefined'){
            let oldProductLine = RFIDService.manuallyAddedProducts[product.code2];
            let tags = RFIDService.RFIDProducts[product.code2];
            if(typeof tags === 'undefined'){
                return true;
            }
            let tag = tags.pop();
            if(typeof tag === 'undefined'){
                return true;
            }
            RFIDService.log('tag', tag);
            tag.ITEM_TRANSACTION_MODE = RFIDService.mode;
            RFIDService.log('ADD_ITEM changed tag', tag);
            if(tag.acked === true){
                RFIDService.manuallyAddedProducts = {};
            }else{
                tag.acked = true;
            }
            updateProductName(oldProductLine.lineNumber, oldProductLine.data.name + " RFID: " + tag.SERIAL_NUMBER);
            addRFIDTag(oldProductLine.lineNumber, tag);
            return false;
        }else{
            RFIDService.manuallyAddedProducts = {};
        }
        return true;
    },
    addProductAfter: function (productData, pendingDocument, addProductRFIDTag, updateProductName) {
        const addExternalTagInfo = (product) => {
            if(typeof product.RFIDSerialNumber !== "undefined"){
                product.RFIDTag.SERIAL_NUMBER = product.RFIDSerialNumber;
            }

            if(typeof product.voucherRFIDTag !== "undefined"){
                product.RFIDTag = product.voucherRFIDTag;
            }
        }

        const product = productData.data;
        let delayedUpdate = false;

        if(!RFIDService.isAvailable()) {
            RFIDService.log('product added while RFIDService not available', product);
            addExternalTagInfo(product);
            if(typeof product.RFIDTag !== "undefined"){
                addProductRFIDTag(product.RFIDTag);
            }
            return true;
        }

        if(typeof product.RFIDTag !== 'undefined'){
            RFIDService.log('product already has RFIDTag!');
            return true;
        }
        RFIDService.log('product added!', product);

        if(pendingDocument !== false){
            let RFIDAttributeName = "RFIDTag" + (productData.lineNumber - 1);
            let RFIDTag = getAttributeValue(pendingDocument, RFIDAttributeName);
            RFIDService.log("Adding RFIDTag from pending document", {RFIDTag, RFIDAttributeName});
            if(typeof RFIDTag !== "undefined"){
                addProductRFIDTag(productData.lineNumber, JSON.parse(RFIDTag));
                return true;
            }
        }

        RFIDService.lastCancelledTag = false;

        const isUnknownProduct = product.code == RFIDService.DKTUnknownItemCode || product.code2 == RFIDService.DKTUnknownItemCode;

        let tags = [];
        if(isUnknownProduct){
            /*if(typeof RFIDService.RFIDProducts['unknown'] !== 'undefined'){
                const tagCode = RFIDService.RFIDProducts['unknown'].pop();
                if(typeof tagCode !== 'undefined'){
                    tags = RFIDService.RFIDProducts[tagCode];
                }
            }*/
            tags = RFIDService.RFIDProducts[productData.originalCode] || [];
            RFIDService.log('tags: ', tags);
        }else{
            let compareCodes = [product.code2, product.code, product.code5, product.code6, product.code7, product.code8];

            for(let compareCode of compareCodes){
                tags = RFIDService.RFIDProducts[compareCode];
                if(typeof tags !== "undefined"){
                    break;
                }
            }
        }

        if((typeof tags !== 'undefined' && tags.length > 0)){
            product.RFIDTag = tags.pop();
            product.RFIDTag.ITEM_TRANSACTION_MODE = RFIDService.mode;
            RFIDService.lastScanned = [];
            if(product.RFIDTag.SERIAL_NUMBER !== 'NULL_VALUE'){
                updateProductName(product.name + " RFID: " + product.RFIDTag.SERIAL_NUMBER);
            }
        }else if(RFIDService.lastScanned.length > 0){
            RFIDService.log('Adding tag from scanner!');
            let lastScanned = RFIDService.lastScanned.pop();
            RFIDService.log('lastScanned: ', lastScanned);
            let manuallyAdded = false;
            try{
                let barcode = parseBarcode(lastScanned);
                product.RFIDTag = {
                    'SGTIN': lastScanned,
                    'EPC': 'NULL_VALUE'
                };
                RFIDService.log('barcode: ', barcode);

                let gtinItem = barcode.parsedCodeItems.find(el => el.dataTitle === 'GTIN');
                let serialItem = barcode.parsedCodeItems.find(el => el.dataTitle === 'SERIAL');

                if(typeof gtinItem === "undefined" || typeof serialItem === "undefined"){
                    throw "This is not a RFID tag";
                }

                product.RFIDTag['GTIN'] = gtinItem.data;
                product.RFIDTag['SERIAL_NUMBER'] = ltrim(serialItem.data, '0');
            }catch (e){
                RFIDService.log('barcode parse failed', lastScanned);
                manuallyAdded = true;
                product.RFIDTag = {
                    'GTIN': RFIDService.pad(product.code2, RFIDService.GTINLength),
                    'SGTIN': 'NULL_VALUE',
                    'SERIAL_NUMBER': 'NULL_VALUE',
                    'EPC': 'NULL_VALUE'
                };
            }

            product.RFIDTag.ITEM_TRANSACTION_MODE = RFIDService.mode;
            addExternalTagInfo(product);

            delayedUpdate = true;
            RFIDService.addItem(product.RFIDTag, function (message) {
                if(message === false){
                    RFIDService.removeProduct(productData);
                    RFIDService.updateLineNumber(false);
                }else{
                    if(manuallyAdded){
                        if(product.RFIDTag.SERIAL_NUMBER === 'NULL_VALUE'){
                            RFIDService.manuallyAddedProducts[product.code2] = productData;
                        }
                    }
                    if(product.RFIDTag.SERIAL_NUMBER !== 'NULL_VALUE'){
                        updateProductName(product.name + " RFID: " + product.RFIDTag.SERIAL_NUMBER);
                    }
                    addProductRFIDTag(product.RFIDTag);
                }
            }, product);
        }else{
            RFIDService.log('Adding tag from keyed product!');
            product.RFIDTag = {
                'GTIN': RFIDService.pad(product.code2, RFIDService.GTINLength),
                'SGTIN': 'NULL_VALUE',
                'SERIAL_NUMBER': 'NULL_VALUE',
                'EPC': 'NULL_VALUE'
            };

            product.RFIDTag.ITEM_TRANSACTION_MODE = RFIDService.mode;
            addExternalTagInfo(product);

            delayedUpdate = true;
            RFIDService.addItem(product.RFIDTag, function (message) {
                if(message === false){
                    RFIDService.removeProduct(productData);
                    RFIDService.updateLineNumber(false);
                }else{
                    if(product.RFIDTag.SERIAL_NUMBER === 'NULL_VALUE'){
                        RFIDService.manuallyAddedProducts[product.code2] = productData;
                    }else{
                        updateProductName(product.name + " RFID: " + product.RFIDTag.SERIAL_NUMBER);
                    }
                    addProductRFIDTag(product.RFIDTag);
                }
            }, product);
        }
        if(!delayedUpdate){
            addProductRFIDTag(product.RFIDTag);
        }
    },
    beforeProductRemove: function (product) {
        if(!RFIDService.isAvailable()) return true;

        RFIDService.log('product removed!', product);
        if(typeof product.RFIDTag === 'undefined'){
            return true;
        }
        if(product.RFIDTag.SERIAL_NUMBER === 'NULL_VALUE'){
            RFIDService.manuallyAddedProducts = {};
        }
        RFIDService.cancelRFIDLine(product.RFIDTag, function () {

        });
    },
    startPayment: function () {
        if(!RFIDService.isAvailable()) return false;

        RFIDService.log('Payment process started!');
        RFIDService.pauseTransaction(function () {
            RFIDService.confirmingTransaction = true;
        });
    },
    beforeClosePOS: function (callback) {
        if(!RFIDService.isAvailable() || !RFIDService.connected) {
            callback();
            return false;
        }

        RFIDService.loggingOut = true;
        RFIDService.log('before close POS');
        RFIDService.cancelAllLines(function () {
            RFIDService.closeRFIDReader(function () {
                RFIDService.loggingOut = false;
                RFIDService.serviceInit = false;
                RFIDService.initTransaction();
                callback();
            });
        });
    },
    afterCloseDay: function () {
        if(!RFIDService.isAvailable()) return false;

        RFIDService.log('after_close_day');
        RFIDService.dayClosed = true;
    },
    afterOpenDay: function () {
        if(!RFIDService.isAvailable()) return false;

        RFIDService.log('after_open_day');
        RFIDService.dayClosed = false;
    },
    beforeLogout: function () {
        if(!RFIDService.isAvailable()) return false;

        RFIDService.log('before logout');
        RFIDService.loggingOut = true;
    },
    afterCancelAddUnknownItem: function (gtin) {
        console.log('RFIDService: afterCancelAddUnknownItem', gtin);
        if(typeof RFIDService.RFIDProducts[gtin] !== 'undefined'){
            let tags = RFIDService.RFIDProducts[gtin];
            if(typeof tags !== 'undefined' && tags.length > 0) {
                let tag = tags.pop();
                tag.ITEM_TRANSACTION_MODE = RFIDService.mode;
                RFIDService.lastCancelledTag = false;
                RFIDService.cancelRFIDLine(tag, function () {

                });
            }
        }
    },
    changeTransactionMode: function (mode) {
        console.log('RFIDService: changeMode called!', mode);
        RFIDService.mode = mode;
    },
    scanForHanger: (onHangersRemoved, store) => {
        RFIDService.cancelAllHangerTagLines(true, () => {
            RFIDService.sendSetDataEventEnable(() => {
                setTimeout(() => {
                    console.log(RFIDService.hangerTags);
                    if(RFIDService.hangerTags.length > 0) {
                        let state = store.getState();
                        if(typeof state.rootReducer.modals.find(el => el.id === "hangerMessage") === "undefined"){
                            onHangersRemoved();
                        }else{
                            RFIDService.POSEvents.scanForHanger(onHangersRemoved, store);
                        }
                    }else{
                        onHangersRemoved();
                    }
                }, 3000);
            });
        });
    },
    hangerMessage: (store) => {
        let modalID = "hangerMessage";
        let waitForModalDisplayed = () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    RFIDService.log("waiting for hanger modal");
                    let state = store.getState();
                    if(state.rootReducer.dialogues.length > 0){
                        waitForModalDisplayed().then(resolve);
                    }else{
                        resolve();
                    }
                }, 1000);
            });
        }
        let rfidParameters = RFIDService.parameters();

        let stopScanning = () => {
            RFIDService.pauseTransaction(function () {
                RFIDService.temporaryScanning = false;
                store.dispatch(closeModal(modalID));
            });
        }

        store.dispatch(openModal({
            id: modalID,
            displayIfNoDialogues: true,
            content: <div style={{
                textAlign: "center",
                fontSize: "20px"
            }}>
                <img
                    style={{
                        marginTop: "50px"
                    }}
                    alt="Hanger"
                    src={hangerImg}
                    width={150}
                />
                <h2><Translate id={"Time to remove your hangers"}/></h2>
                <p><Translate id={"Decathlon reuses"}/> <span style={{fontWeight: "bold"}} className={"coloredText"}><Translate id={"100%"}/></span> <Translate id={"of its hangers."}/></p>
                <p><Translate id={"Please leave them next to this checkout."}/></p>
                <Button type={"button"}
                        className={"largeButton blueButton"}
                        flencheClass={"flencheSmallRightBottom blueFlenche"}
                        arrow={"right"}
                        name={<Translate id="Got it!"/>}
                        onClick={e => {
                            stopScanning();
                        }}
                        style={{
                            marginTop: "50px"
                        }}
                />
            </div>,
            className: "Administration",
            canClose: false
        }));
        waitForModalDisplayed().then(() => {
            RFIDService.temporaryScanning = true;
            RFIDService.startTransaction(true);
            RFIDService.POSEvents.scanForHanger(stopScanning, store);
            setTimeout(() => {
                store.dispatch(closeModal(modalID));
            }, rfidParameters.hangerMessageTimeout * 1000);
        });
    }
};

window.RFIDService = RFIDService;

export default RFIDService;
