import React, {Component} from 'react';
import "./main.css"
import Translate from "localization/Translate";
import Modal from "../modal/Modal";
import {connect} from "react-redux";
import {getNotConnectedPeripherals} from "../redux/selectors";
import {closeQCO} from "../redux/actions";
import Button from "../buttons/Button";


const mapStateToProps = state => {
    return {
        peripherals: state.rootReducer.peripherals,
        pos: state.rootReducer.pos,
        connectionHealth: state.rootReducer.connectionHealth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeQCO: () => {
            dispatch(closeQCO());
        }
    }
};

class Peripherals extends Component{
    render() {
        if(this.props.peripherals.length === 0 || this.props.pos === false || !this.props.connectionHealth) return null;

        const notConnected = getNotConnectedPeripherals(this.props.peripherals);
        if(notConnected.length === 0) return null;
        let list = notConnected.map(function (peripheral, index) {
            let retryButton;
            if(peripheral.hasNoConnection()){
                retryButton =
                    <div onClick={peripheral.onRetry} style={{
                        margin: '10px',
                        display: 'inline'
                    }}>
                        <Button
                            type="button"
                            className={"mediumButton grayButton"}
                            name={<Translate id="retry"/>}
                            arrow="left"
                            flencheClass={"flencheSmallLeftBottom grayFlenche"}
                        />
                    </div>;
            }
            return (
                <li key={index}>
                    {peripheral.name}: <Translate id={peripheral.status}/>
                    { retryButton }
                </li>
            );
        });

        let content = (
            <ul>{list}</ul>
        );

        return (
            <Modal className={"Administration"} content={content} onClose={e => {
                this.props.closeQCO();
            }}/>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Peripherals);
