import React from 'react';
import './button.css';
import {useSelector} from "react-redux";
import successImg from "./images/success.png";

export default function (props) {
    let decathlonTheme = useSelector(state => state.rootReducer.decathlonTheme);
    let image = decathlonTheme === "purple" ? <img height={25} src={successImg} alt={"Ok"}/> : <i className={"icoFont checkCircle"}>&#xeed6;</i>;
    return (
        <div onClick={props.onClick}>
            {image}
        </div>
    );
}