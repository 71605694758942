import React from 'react';
import {useActionListener} from "redux-action-listener-hook";
import Translate from "../../../../localization/Translate";
import Image from "../../../../theme/Image";
import {useSelector} from "react-redux";
import {addErrorAlert, setPaybackPayCode} from "../../../actions";
import PBP from "./main";
import {isPaybackPayCode} from "../../../../integrations/payback/util";

export default function (props){
    let payback = useSelector(state => state.rootReducer.payback);
    useActionListener('SCANNER_DETECTED', (dispatch, action) => {
        if(typeof payback.payQRCode !== "undefined"){
            dispatch(addErrorAlert("Processing the payment already!"));
            return;
        }
        if(isPaybackPayCode(action.payload)){
            let qrCode = new PBP.QRCode(action.payload);
            dispatch(setPaybackPayCode(qrCode));
            props.onScan(qrCode);
        }else{
            dispatch(addErrorAlert("Not a payback PAY code!"))
        }
    });

    let title = typeof payback.payQRCode !== "undefined" ? "processing transaction" : "Scan the payment QR code";

    return (
        <div>
            <span className={"boldUppercaseText"}>
                { <Translate id={title}/>}
            </span>
            <br/>
            <div style={{
                "marginBottom": "45px"
            }}/>
            <Image image={"paymentTerminal"} alt="paymentTerminal"/>
        </div>
    );
}