import React from "react";
import Button from "../../buttons/Button";
import Translate from "localization/Translate";
import {APP_MODE_SCAN} from "../../redux/appConstants";

export default function cancelButton({theme, onClick, appMode, title}) {
    if(theme === 'Erply'){
        return (
            <div onClick={onClick} style={{
                marginTop: '10px',
                marginRight: '10px',
                float: 'right'
            }}>
                <Button type="button"
                        className={"mediumEqualButton transparentButton uppercaseText"}
                        name={<Translate id={title}/>}
                        arrow="left"
                />
            </div>
        );
    }else {
        let colorClass;
        let flencheColorClass;
        if(appMode === APP_MODE_SCAN){
            colorClass = 'grayButton';
            flencheColorClass = 'grayFlenche';
        }else{
            colorClass = 'blueButton';
            flencheColorClass = 'blueFlenche'
        }
        return (
            <div onClick={onClick} style={{
                marginTop: '10px',
                marginRight: '10px',
                float: 'right'
            }}>
                <Button type="button"
                        className={`mediumButton ${colorClass}`}
                        name={<Translate id={title}/>}
                        arrow="left"
                        flencheClass={`flencheSmallLeftBottom ${flencheColorClass}`}
                />
            </div>
        );
    }
}
