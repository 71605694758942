import CTR from "../../../../util/receiptTemplate";

export const PMS = {
    port: false,
    url: 'wss://localhost.erply.com',
    urlHttp: 'https://localhost.erply.com',
    available: false,
    pixelPerLine: 576,
    log: function(){
        let newArgs = Array.prototype.slice.call(arguments);
        newArgs.unshift('PMS: ');
        console.log.apply(this, newArgs);
    },
    init: (config) => {
        PMS.log("init", {config});
        PMS.port = config.Port;
        PMS.openWebSocket().then(() => {

        });
        PMS.getPrinterList().then((response) => {
            let printer = response.find(el => el.Name === config.PrinterName);
            if(typeof printer !== "undefined"){
                let paperSize = config?.PrinterPaperSize ?? 3;
                let pixelPerLineKey = Object.keys(printer).find(el => el.toLowerCase().indexOf("pixelperline" + paperSize) !== -1);
                if(typeof pixelPerLineKey !== "undefined"){
                    let pixelPerLine = parseInt(printer[pixelPerLineKey]);
                    if(!isNaN(pixelPerLine) && pixelPerLine !== 0){
                        PMS.pixelPerLine = pixelPerLine;
                    }
                }
            }
        });
    },
    openWebSocket: function () {
        return new Promise(function (resolve, reject) {
            PMS.websocket = new WebSocket(PMS.url + ':' + PMS.port);
            PMS.websocket.onopen = function () {
                PMS.log("websocket onopen");
                PMS.available = true;
                resolve();
            };
            PMS.websocket.onmessage = function (response) {
                PMS.log("websocket: Message is received:", response.data);
                //const data = JSON.parse(response.data);

            };
            PMS.websocket.onclose = function () {
                PMS.log("websocket onclose");
                delete PMS.websocket;
                PMS.available = false;
            }
        });
    },
    print: async (receipt) => {
        let receiptPng = await CTR.createPng(receipt, PMS.pixelPerLine);
        PMS.websocket.send(JSON.stringify(
            [
                {
                    "type": "image",
                    "align" : "center",
                    "blob": receiptPng
                }
            ]
        ));
    },
    printLink: async (receiptLink) => {
        // Link printing is not working well. Convert link to image and print.
        let receiptHtml = await (await fetch(receiptLink)).text();
        PMS.print(receiptHtml);
        //PMS.websocket.send(JSON.stringify({receiptLink}));
    },
    sendRequest: (request) => {
        return new Promise((resolve, reject) => {
            let url = `${PMS.urlHttp}:${PMS.port}/`;
            fetch(url + request).then((response) => {
                resolve(response.json());
            }, reject);
        });
    },
    openCashDrawer: () => {
        return PMS.sendRequest('opencashdrawer');
    },
    getPrinterList: () => {
        return PMS.sendRequest('getprinterlist?full=true');
    }
};

window.PMS = PMS;