import React, {Component} from 'react';
import {APP_MODE_BEFORE_PAYMENT} from "../redux/appConstants";
import {changeMode} from "../redux/actions";
import {connect} from "react-redux";
import Translate from "localization/Translate";
import Button from "../buttons/Button";
import {getProductsTotalSum} from "../redux/selectors";

const mapStateToProps = state => {
    return {
        mode: state.rootReducer.mode,
        hasItems: state.rootReducer.productsInBasket.length > 0 || state.rootReducer.invoice !== false,
        rightPanelDialogue: state.rootReducer.rightPanelDialogue,
        rightPanelFullScreen: state.rootReducer.rightPanelFullScreen,
        editQuickPosProducts: state.rootReducer.editQuickPosProducts,
        quickPosProducts: state.rootReducer.quickPosProducts,
        theme: process.env.REACT_APP_ERPLY_MODE === "1" ? 'Erply' : 'Decathlon',
        leftPanelFullScreen: state.rootReducer.leftPanelFullScreen,
        totalSum: Number(getProductsTotalSum(state.rootReducer.productsInBasket))
    }
};

const mapDispatchToProps = dispatch => {
    return {
        startPayment: () => {
            dispatch(changeMode(APP_MODE_BEFORE_PAYMENT));
        }
    }
};

class PaymentButton extends Component {
    render() {
        if(this.props.hasItems){
            if(this.props.theme === 'Erply'){
                return (
                    <div onClick={e => this.props.startPayment()} style={this.props?.style ?? {} }>
                        <Button type="button"
                                name={<Translate id="payment"/>}
                                className={"ultraLargeButton greenButton roundButton uppercaseText"}
                                arrow="rightClose"
                        />
                    </div>
                );
            }else{
                if(this.props.leftPanelFullScreen){
                    let paymentText = this.props.totalSum < 0 ? "return" : this.props.totalSum === 0 ? "process" : "payment";
                    console.log({totalSum: this.props.totalSum});
                    return (
                        <div onClick={e => this.props.startPayment()}>
                            <Button type="button" id={"paymentButton"} className={"extralargeButton yellowButton"} name={<Translate id={paymentText}/>}
                                    arrow="right" flencheClass={"flencheSmallRightBottom yellowFlenche"}/>
                        </div>
                    );
                }

                if(window?.AppConf?.fullscreenCustomerSearch){
                    return (
                        <div onClick={e => this.props.startPayment()} style={{
                            position: 'absolute',
                            bottom: '78px',
                            width: '100%',
                            zIndex: 4,
                            padding: "0 40px",
                            boxSizing: "border-box"
                        }}>
                            <Button
                                type="button"
                                className={"darkerBlueButton wideButton italic"}
                                name={<Translate id="payment"/>}
                                style={{
                                    width: '100%'
                                }}
                            />
                        </div>
                    );
                }else{
                    return (
                        <div onClick={e => this.props.startPayment()} style={{
                            margin: "30px"
                        }}>
                            <Button type="button" id={"paymentButton"} className={"extralargeButton yellowButton"} name={<Translate id="payment"/>}
                                    arrow="right" flencheClass={"flencheSmallRightBottom yellowFlenche"}/>
                        </div>
                    );
                }
            }
        }
        return <div style={{height: "122px"}}/>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentButton);
