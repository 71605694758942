import React, {useState} from 'react';
import ImageBox from "../buttons/ImageBox";
import Translate from "localization/Translate";
import Button from "../buttons/Button";
import {useSelector} from "react-redux";

export default function ({onLiteMode, onFullMode, onCashierMode}) {
    const [view, setView] = useState('mode');

    const connectionHealth = useSelector(state => state.rootReducer.connectionHealth);
    const currentMode = useSelector(state => state.rootReducer.cashierMode ? "cashier" : state.rootReducer.operationMode);

    if(!connectionHealth){
        return (
            <div style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div style={{
                    margin: '50px auto',
                }}>
                    <ImageBox
                        image={"iconOfflineMode"}
                        title={<Translate id={'offline mode'}/>}
                        width={'200px'}
                        height={'400px'}
                        boxStyle={{
                            boxSizing: 'border-box',
                            paddingTop: '50px'
                        }}
                        onClick={onCashierMode}
                    />
                </div>
                <span style={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    marginBottom: '20px'
                }}><Translate id={"Connection failed. Only degraded mode available"}/></span>
                <span style={{
                    fontWeight: 'bold'
                }}><Translate id={"NOTE: Some functionalities might be limited or not available in this mode."}/></span>
            </div>
        );
    }

    const unifiedVersion = window?.AppConf?.unifiedVersion ?? false;

    if(unifiedVersion && view === 'mode' && currentMode !== "cashier"){
        return (<div style={{
            textAlign: 'center'
        }}>
            <div style={{
                marginTop: '50px',
                display: 'inline-block'
            }}>
                <ImageBox
                    image={"iconCashierMode"}
                    title={<Translate id={'cashier mode'}/>}
                    width={'200px'}
                    height={'400px'}
                    boxStyle={{
                        boxSizing: 'border-box',
                        paddingTop: '50px'
                    }}
                    onClick={onCashierMode}
                />
                <ImageBox
                    image={"iconFullMode"}
                    title={<Translate id={'sco mode'}/>}
                    width={'200px'}
                    height={'400px'}
                    containerStyle={{
                        marginLeft: '100px'
                    }}
                    boxStyle={{
                        boxSizing: 'border-box',
                        paddingTop: '75px'
                    }}
                    onClick={() => {
                        setView('sco');
                    }}
                />
            </div>
        </div>);
    }

    let backButton;
    if(unifiedVersion && currentMode !== "cashier"){
        backButton = <div onClick={() => {
            setView('mode');
        }} style={{marginTop: '10px', marginBottom: '10px'}}>
            <Button type="button" className={"largeButton grayButton"} name={<Translate id="back"/>}
                    arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
        </div>;
    }

    let liteMode;
    if(currentMode !== "lite"){
        liteMode = <ImageBox
            image={"iconLiteMode"}
            title={<Translate id={'lite mode'}/>}
            width={'200px'}
            height={'400px'}
            boxStyle={{
                boxSizing: 'border-box',
                paddingTop: '50px'
            }}
            onClick={onLiteMode}
        />;
    }

    let fullMode;
    if(currentMode !== "full"){
        fullMode = <ImageBox
            image={"iconFullMode"}
            title={<Translate id={'full mode'}/>}
            width={'200px'}
            height={'400px'}
            containerStyle={{
                marginLeft: '100px'
            }}
            boxStyle={{
                boxSizing: 'border-box',
                paddingTop: '75px'
            }}
            onClick={onFullMode}
        />;
    }

    return (
        <div style={{
            textAlign: 'center'
        }}>
            <div style={{
                marginTop: '50px',
                display: 'inline-block'
            }}>
                { liteMode }
                { fullMode }
            </div>
            {backButton}
        </div>
    );
}