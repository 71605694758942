import React, {Component} from 'react';
import {getTranslate} from "localization/localizeSlice";
import {connect} from "react-redux";
import {
    addPaybackCoupon,
    closeRightPanelDialogue,
    setPaybackCustomer,
    setPaybackInputDone,
    setPaybackPayCode,
    showRightPanelDialogue
} from "../../redux/actions";
import EnterCardNumber from "./EnterCardNumber";
import EnterCouponCode from "./EnterCouponCode";
import ScanCustomerCard from "./ScanCustomerCard";
import ScanCoupon from "./ScanCoupon";
import {getProductsInBasket} from "../../redux/selectors";

const mapStateToProps = state => {
    return {
        payback: state.rootReducer.payback,
        clientCode: state.rootReducer.user.clientCode,
        sessionKey: state.rootReducer.user.sessionKey,
        warehouseCode: state.rootReducer.warehouse.code,
        productsInBasket: getProductsInBasket(state),
        translate: getTranslate(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        paybackInputDone: () => {
            dispatch(setPaybackInputDone(true));
        },
        enterCardCode: () => {
            dispatch(showRightPanelDialogue((
                <EnterCardNumber
                    onDone={cardNumber => {
                        dispatch(setPaybackCustomer(cardNumber));
                        dispatch(closeRightPanelDialogue());
                    }}
                    setPaybackPayCode={code => {
                        dispatch(setPaybackPayCode(code));
                        dispatch(closeRightPanelDialogue());
                    }}
                    onBack={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                />
            )));
        },
        setPaybackCustomer: (cardNumber) => {
            dispatch(setPaybackCustomer(cardNumber));
        },
        setPaybackPayCode: (code) => {
            dispatch(setPaybackPayCode(code));
        },
        enterCouponCode: (coupons) => {
            dispatch(showRightPanelDialogue((
                <EnterCouponCode
                    coupons={coupons}
                    onDone={() => {
                        dispatch(closeRightPanelDialogue());
                        dispatch(setPaybackInputDone(true));
                    }}
                    onEnterAnother={couponCode => {
                        dispatch(addPaybackCoupon(couponCode));
                    }}
                    onBack={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                />
            )));
        },
        addPaybackCoupon: (couponCode) => {
            dispatch(addPaybackCoupon(couponCode));
        }
    }
}

class Payment extends Component{
    render() {
        if(this.props.payback.customer === false){
            return <ScanCustomerCard
                paybackInputDone={this.props.paybackInputDone}
                enterCardCode={this.props.enterCardCode}
                setPaybackCustomer={this.props.setPaybackCustomer}
                setPaybackPayCode={this.props.setPaybackPayCode}
            />;
        }else{
            return <ScanCoupon
                coupons={this.props.payback.coupons}
                enterCouponCode={this.props.enterCouponCode}
                paybackInputDone={this.props.paybackInputDone}
                addPaybackCoupon={this.props.addPaybackCoupon}
            />;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);