import * as React from 'react';
import {useSelector} from "react-redux";

export default function DynamicStyles(props){
    let decathlonTheme = useSelector(state => state.rootReducer.decathlonTheme);
    let styleSheet = process.env.REACT_APP_ERPLY_MODE === "1" ?
        './css/AppErply.css' :
        decathlonTheme === "purple" ?
            './css/AppDecathlonPurple.css' :'./css/AppDecathlon.css'
    return (
        <div style={{
            height: "100%"
        }}>
            <link rel="stylesheet" type="text/css" href={styleSheet} />
            {props.children}
        </div>
    )
};