import React, {Component} from 'react';
import "../main.css"
import {connect} from "react-redux";
import {
    addErrorAlert,
    addInfoAlert,
    addProduct,
    cancelAddUnknownItem,
    closeAdministration,
    findProduct,
    setLoading,
    showInputDialogue
} from "../../redux/actions";
import {displayNetPrices, getFirstUnknownRFIDProduct, getUnknownItemCode} from "../../redux/selectors";
import MultipleInputContent from "../../modal/MultipleInputContent";


const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        warehouseID: state.rootReducer.warehouse.warehouseID,
        pointOfSaleID: state.rootReducer.posID,
        unknownItemCode: getUnknownItemCode(),
        unknownRFIDProduct: getFirstUnknownRFIDProduct(state.rootReducer.unknownRFIDProducts),
        erplyConf: state.rootReducer.erplyConf
    }
};

export const findUnknownProduct = (dispatch, code, warehouseID, pointOfSaleID, vatPrice, netPrice, itemCode, originalCode, RFIDTag=false, editProduct=(product)=>product) => {
    dispatch(findProduct(itemCode, warehouseID, pointOfSaleID, false, (product) => {
        dispatch(setLoading(false));
        dispatch(closeAdministration());
        dispatch(addInfoAlert("Product found in the database!"))
        if(product.needsSerialNumber()){
            return showInputDialogue('Insert serial number!', '', '', function (serialNumber) {
                product.data.RFIDSerialNumber = serialNumber;
                dispatch(addProduct(product));
            }, undefined, false, true);
        }else{
            return addProduct(product);
        }
    }, () => {
        return findProduct(code, warehouseID, pointOfSaleID, vatPrice, function (product) {
            dispatch(setLoading(false));
            dispatch(closeAdministration());
            product.data.code = itemCode;
            product.isUnknownItem = true;
            product.name += ' (code: ' + itemCode + ')';
            if(netPrice !== false){
                product.setNetPrice(netPrice);
            }
            if(RFIDTag !== false){
                product.data.RFIDTag = RFIDTag;
            }
            return addProduct(editProduct(product));
        }, (code, error) => {
            dispatch(setLoading(false));
            return addErrorAlert("Could not find unknown item, please check the configuration!");
        }, originalCode);
    }, undefined, undefined, 'code'));
}

const mapDispatchToProps = dispatch => {
    return {
        findProduct: (code, warehouseID, pointOfSaleID, vatPrice, netPrice, itemCode, originalCode) => {
            findUnknownProduct(dispatch, code, warehouseID, pointOfSaleID, vatPrice, netPrice, itemCode, originalCode);
        },
        cancelAddUnknownItem: (gtin) => {
            dispatch(addErrorAlert('Could not add unknown item!'));
            dispatch(cancelAddUnknownItem(gtin));
            dispatch(closeAdministration());
        },
        setLoading: (loading) => {
            dispatch(setLoading(loading));
        }
    }
};

class UnknownItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyboardLayout: "numbers",
            keyboardInputField: 'itemCode',
            selectedUser: false,
            errorMessage: false,
            codeInputValue: '',
            priceInputValue: ''
        };

        this.findItem = this.findItem.bind(this);
    }

    componentDidMount() {

    }

    findItem(inputValues) {
        this.props.setLoading(true);
        if( typeof inputValues.itemPrice === "undefined" ||
            isNaN(inputValues.itemPrice) ||
            inputValues.itemPrice === 0 ||
            inputValues.itemPrice === '' ||
            typeof inputValues.itemCode === "undefined" ||
            inputValues.itemCode === ''
        ){
            this.props.cancelAddUnknownItem(this.props.unknownRFIDProduct.GTIN);
            this.props.setLoading(false);
        }else{
            let originalCode = this.props.unknownRFIDProduct !== false ? this.props.unknownRFIDProduct.ITEM_ID || this.props.unknownRFIDProduct.GTIN : this.props.administration?.data?.searchParam ?? '';
            let vatPrice = false;
            let netPrice = false;

            if(displayNetPrices(this.props.erplyConf)){
                netPrice = inputValues.itemPrice;
            }else{
                vatPrice = inputValues.itemPrice;
            }

            this.props.findProduct(this.props.unknownItemCode, this.props.warehouseID, this.props.pointOfSaleID, vatPrice, netPrice, inputValues.itemCode, originalCode);
            this.setState({
                codeInputValue: '',
                priceInputValue: ''
            });
        }
    }

    render() {
        console.log('RFIDText unknownRFIDProduct', this.props.unknownRFIDProduct);
        const rfidText = this.props.unknownRFIDProduct !== false ? 'GTIN: ' + this.props.unknownRFIDProduct.GTIN : this.props.administration?.data?.searchParam ?? '';

        let initialValues = {};
        let inputFields = [
            { id: 'itemCode', name: 'Item code', isNumber: true, positiveInt: true},
            { id: 'itemPrice', name: 'Item price', isNumber: true}
        ];

        return (
            <MultipleInputContent
                title={'Unknown item'}
                inputFields={inputFields}
                onDone={(inputValues) => {
                    console.log('inputValues', inputValues);
                    this.findItem(inputValues);
                }}
                initialValues={initialValues}
                beforeInputFields={
                    <p>{rfidText}</p>
                }
                initialKeyboardLayout={'numbers'}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnknownItem);
