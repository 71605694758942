import React from "react";
import Button from "../buttons/Button";
import {useSelector} from "react-redux";
import Image from "../theme/Image";

export default function ({title, confirmText, onConfirm, cancelText, onCancel}) {
    const theme = useSelector(state => state.rootReducer.theme);

    let cancelButton;
    if(typeof cancelText !== "undefined"){
        if(theme === 'Decathlon'){
            cancelButton = (
                <div className={"dialogueConfirmButton"} onClick={onCancel}>
                    <Button type="button" className={"largeButton grayButton"} name={cancelText}
                            arrow="right" flencheClass={"flencheSmallRightBottom grayFlenche"}/>
                </div>
            )
        }else{
            cancelButton = (
                <div className={"dialogueConfirmButton"} onClick={onCancel}>
                    <Button
                        type="button"
                        className={"button largerButton grayButton roundButton uppercaseText"}
                        name={cancelText}
                        arrow="left"
                    />
                </div>
            )
        }
    }

    let confirmButton;
    if(theme === 'Decathlon'){
        confirmButton = (
            <div className={"dialogueConfirmButton"} onClick={onConfirm}>
                <Button type="button" className={"largeButton blueButton negativeActionButton"} name={confirmText}
                        arrow="right" flencheClass={"flencheSmallRightBottom blueFlenche"}/>
            </div>
        );
    }else{
        confirmButton = (
            <div className={"dialogueConfirmButton"} onClick={onConfirm}>
                <Button type="button"
                        className={"button largerButton lightBlueButton roundButton uppercaseText"}
                        name={confirmText}
                        arrow="right"
                />
            </div>
        );
    }

    return (
        <div style={{
            textAlign:"center",
            position: "absolute",
            top: 0,
            bottom: "120px",
            left: 0,
            right: 0
        }}>
            <div style={{
                display: "table",
                height: "100%",
                width: "100%"
            }}>
                <div style={{
                    display: "table-cell",
                    verticalAlign: "middle"
                }}>
                <span className={"boldUppercaseText"}>
                    {title}
                </span>
                    <br/>
                    <div style={{
                        "marginBottom": "20px"
                    }}/>
                        <Image image={"removeProducts"} alt={"removeProducts"} style={{width: theme === 'Decathlon' ? 300 : 200}}/>
                    <br/>
                    <div style={{
                        "marginBottom": "50px"
                    }}/>
                </div>
            </div>
            <div className={"dialogueConfirmButtons"}>
                {cancelButton}
                {confirmButton}
            </div>
        </div>
    );
}