import React, {Component} from 'react';
import Translate from "localization/Translate";
import MultipleInputContent from "../../modal/MultipleInputContent";
import Button from "../../buttons/Button";
import {connect} from "react-redux";
import {
    closeRightPanelDialogue,
} from "../../redux/actions";

const mapStateToProps = state => {
    return {
        warehouseID: state.rootReducer.warehouse.warehouseID,
        posID: state.rootReducer.pos.pointOfSaleID
    }
};

const closeView = (dispatch) => {
    dispatch(closeRightPanelDialogue());
};

const mapDispatchToProps = dispatch => {
    return {
        closeView: () => {
            closeView(dispatch);
        }
    }
};

class ZipCodeSurvey extends Component{
    constructor(props) {
        super(props);
        this.state = {
            inputValues: {}
        };
    }

    render(){
        const labelStyle = {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '20px',
            marginLeft: '20px'
        };

        const NumberCircle = ({number}) => {
            return (
                <span
                    style={{
                        width: '24px',
                        height: '24px',
                        fontSize: '20px'
                    }}
                    className={"blueCircleBackgroundSmall"}
                >{number}</span>);
        };

        const Label = () => {
            return (
                <div className={"coloredText"}>
                    <NumberCircle number={2}/>
                    <span style={labelStyle}>
                        <Translate id={'Please type your post code / zip code'}/>
                    </span>
                </div>
            );
        };

        let inputFields = [
            { id: 'zipCode', labelContent: <Label/>, active: true}
        ];

        let beforeInputFields = (
            <div
                className={"coloredText"}
                style={{
                    marginTop: '40px',
                    marginBottom: '60px'
                }}
            >
                <NumberCircle number={1}/>
                <span style={{
                    ...labelStyle,
                    width: '280px',
                    marginTop: '-34px',
                    marginLeft: '54px',
                    display: 'block'
                }}>
                    <Translate id={"Help us make sport accessible closer to you !!"}/>
                </span>
            </div>
        );

        let validate = () => {
            if(this.state.inputValues.zipCode){
                console.log({zipCode: this.state.inputValues.zipCode});
                this.props.onDone({
                    zipCode: this.state.inputValues.zipCode
                });
            }
        };

        let decline = () => {
            this.props.onDone({
                zipCode: ""
            });
        };

        let afterInputFields = (
            <div className={"coloredText"}>
                <span>
                    <Translate id={'NOTE: If code is divided in 2 parts, at least type the 1st part'}/>
                </span>
                <Button
                    onClick={validate}
                    type="button"
                    className={"largeButton yellowButton"}
                    name={<Translate id="validate"/>}
                    arrow="right"
                    flencheClass={"flencheSmallRightBottom yellowFlenche"}
                    style={{
                        display: 'block',
                        textAlign: 'center',
                        marginTop: '30px'
                    }}
                />
                <Button
                    onClick={decline}
                    type="button"
                    className={"mediumButton blueButton"}
                    name={<Translate id="I do not wish to provide this information"/>}
                    arrow="right"
                    flencheClass={"flencheSmallRightBottom blueFlenche"}
                    style={{
                        display: 'block',
                        textAlign: 'center',
                        marginTop: '30px'
                    }}
                />
            </div>
        );

        let content = (
            <MultipleInputContent
                inputFields={inputFields}
                onChange={(newState) => {
                    this.setState({
                        inputValues: Object.assign({}, this.state.inputValues, newState)
                    });
                }}
                onDone={validate}
                initialValues={this.state.inputValues}
                beforeInputFields={ beforeInputFields }
                afterInputFields={ afterInputFields }
                initialKeyboardLayout={'numbers'}
                labelStyle={labelStyle}
                inputClassName={'borderInput fullWidth coloredText'}
                inputStyle={{
                    marginTop: '20px',
                    marginBottom: '30px'
                }}
            />
        );

        return (
            <div style={{
                fontSize: '20px',
                textAlign: 'left'
            }}>
                <div
                    className={"coloredText"}
                    style={{
                        position: 'absolute',
                        right: '30px',
                        top: '12px',
                        zIndex: 1000,
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        fontSize: '22px'
                    }}
                >
                    <Translate id={'Survey'}/>
                </div>
                { content }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeSurvey);