import React, {useState} from 'react';
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import Keyboard from "../../keyboard/Keyboard";
import {useDispatch, useSelector} from "react-redux";
import {addErrorAlert, setLoading} from "../../redux/actions";
import {checkGiftCardBalance as checkGiftCardBalanceAction} from "../../redux/payment/actions";
import {dateFormat, stringToDate} from "../../util/calendar";
import {useActionListener} from "redux-action-listener-hook";
import RGFD from "../../redux/payment/integrations/giftCard/main";

const checkGiftCardBalance = (dispatch, number, setBalance, setExpirationDate, erplyConf) => {
    dispatch(setLoading(true));
    dispatch(checkGiftCardBalanceAction(number, (params) => {
        setBalance(params.balance);
        setExpirationDate(dateFormat(stringToDate(params.expirationDate, 'Y-m-d'), erplyConf.dateformat));
        dispatch(setLoading(false));
    }, (error) => {
        setBalance(error);
        setExpirationDate("-");
        dispatch(setLoading(false));
    }));
}
export default function () {
    let [number, changeNumber] = useState("");
    let [balance, setBalance] = useState("-");
    let [expirationDate, setExpirationDate] = useState("-");
    let translate = getTranslate(useSelector(state => state));
    let erplyConf = useSelector(state => state.rootReducer.erplyConf);
    let dispatch = useDispatch();

    useActionListener('GIFT_CARD_SCANNED', (dispatch, action) => {
        let giftCardNumber = action.payload;
        if(giftCardNumber.length !== 16){
            if(RGFD.isGiftCard(action.payload)){
                let giftCard = RGFD.decodeBarcode(action.payload);
                giftCardNumber = giftCard.number;
            }else{
                changeNumber("");
                dispatch(addErrorAlert("Please scan a gift card"));
                return;
            }
        }
        changeNumber(giftCardNumber);
        checkGiftCardBalance(dispatch, giftCardNumber, setBalance, setExpirationDate, erplyConf);
    });

    return (
        <div style={{
            position: "absolute",
            left: 0,
            right:0,
            top: 0,
            bottom: 0
        }}>
            <h1 style={{marginLeft: '20px'}}><Translate id={'Check gift card balance'}/></h1>
            <div style={{
                width: "100%",
                borderTop: "1px solid gray",
                marginBottom: "10px"
            }}/>
            <input
                type={"text"}
                name={"number"}
                className={"underlineInput"}
                autoComplete={'off'}
                placeholder={translate("Gift card serial number")}
                value={number}
                onChange={e => {
                    changeNumber(e.target.value);
                }}
            />
            <div className={"boldText"}>
                <Translate id={'Balance'}/>: {balance}
            </div>
            <div className={"boldText"}>
                <Translate id={'Expiration date'} />: {expirationDate}
            </div>
            <div style={{
                "position": "absolute",
                "bottom": "0",
                "left": "0",
                "right": "0"
            }}>
                <Keyboard options={{
                    initialLayout: 'numbers',
                    onClick: (key) => {
                        changeNumber(key === 'delete' ? number.slice(0, -1) : number + key);
                    },
                    onDone: () => {
                        checkGiftCardBalance(dispatch, number, setBalance, setExpirationDate, erplyConf);
                    }
                }}/>
            </div>
        </div>
    );
}