import React, {Component} from 'react';
import {connect} from "react-redux";
import "./main.css"
import InputWithKeyboard from "../formElements/InputWithKeyboard";
import {addProduct, addQuickPosProduct, findProducts, setProductSelectList} from "../redux/actions";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../uiComponents/WrappedPerfectScrollBar';
import Translate from "localization/Translate";
import Product from "../redux/dto/product";
import closeImg from "./images/Erply/close.png";
import {displayPrice, numberIsOdd} from "../util/helperFunctions";
import {displayNetPrices} from "../redux/selectors";
import {Loader} from "./Loading";

const mapStateToProps = state => {
    return {
        productSelectList: state.rootReducer.productSelectList,
        warehouseID: state.rootReducer.warehouse.warehouseID,
        pointOfSaleID: state.rootReducer.pos.pointOfSaleID,
        editQuickPosProducts: state.rootReducer.editQuickPosProducts,
        currency: state.rootReducer.currency,
        displayNetPrices: displayNetPrices(state.rootReducer.erplyConf),
    }
};

const mapDispatchToProps = dispatch => {
    return {
        findProducts: (query, warehouseID, pointOfSaleID, onDone) => {
            dispatch(findProducts({searchNameIncrementally: query}, warehouseID, pointOfSaleID, result => {
                onDone();
                return setProductSelectList(result);
            }, result => {
                onDone();
                return setProductSelectList([]);
            }));
        },
        clearQuery: () => {
            dispatch(setProductSelectList([]));
        },
        setProduct: (product) => {
            let productDTO = new Product(product.name, product.priceListPrice, product.priceListPriceWithVat, 1, product);
            dispatch(addProduct(productDTO));
        },
        addQuickPosProduct: (product) => {
            dispatch(addQuickPosProduct(product));
        }
    }
};

class ProductSearch extends Component{
    constructor(props) {
        super(props);
        this.state = {
            lastSearchTime: false,
            lastSearchQuery: false,
            searching: false
        }
    }

    componentWillUnmount() {
        this.props.clearQuery();
    }

    findProducts(query, time) {
        setTimeout(() => {
            if(time === this.state.lastSearchTime){
                this.props.findProducts(query, this.props.warehouseID, this.props.pointOfSaleID, () => {
                    this.setState({
                        searching: false
                    });
                });
            }
        }, 1000);
    }

    render() {
        let content;

        if(this.state.searching){
            content = (
                <Loader position={"absolute"}/>
            );
        }else if(this.props.productSelectList.length > 0){
            content = (
                <PerfectScrollbar style={{
                    width: "100%"
                }}>
                    <table className={'productSearchTable'}>
                        <tbody>
                            {this.props.productSelectList.map((product, index) => {
                                let liClass = (numberIsOdd(index + 1) ? ' productLineOdd' : '');
                                return (
                                    <tr
                                        key={index}
                                        onClick={e => {
                                            if(this.props.editQuickPosProducts){
                                                this.props.addQuickPosProduct(product);
                                            }else{
                                                this.props.setProduct(product);
                                            }
                                            this.props.onSelect(product);
                                        }}
                                        className={liClass}
                                    >
                                        <td className={'boldText'}
                                            style={{
                                            textAlign: 'left'
                                        }}>
                                            { product.name }
                                        </td>
                                        <td>
                                            { product.code }
                                        </td>
                                        <td className={'boldText'}>
                                            { this.props.displayNetPrices ? displayPrice(product.price) : displayPrice(product.priceWithVat) } {this.props.currency}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </PerfectScrollbar>
            );
        }else if(this.state.lastSearchQuery.length > 2){
            content = <Translate id={'No products match the query'}/>
        }

        return (
            <div style={{
                marginTop: '10px',
                padding: '0 2px',
                fontSize: '14px',
                display: 'flex'
            }}>
                <span style={{
                    display: 'block',
                    float: 'left',
                    padding: '10px 20px',
                    fontWeight: 'bold',
                    fontSize: '16px'
                }}>
                    <Translate id={"Product search"}/>
                </span>
                <InputWithKeyboard
                    onInputChange={(value) => {
                        let time = Date.now();
                        this.setState({
                            lastSearchTime: time,
                            lastSearchQuery: value,
                        });
                        if(value.length > 2){
                            this.setState({
                                searching: true
                            });
                            this.findProducts(value, time);
                        }else{
                            // this.props.clearQuery();
                        }
                    }}
                    onDone={(value) => {
                        this.props.clearQuery();
                    }}
                    inputStyle={{
                        boxSizing: 'border-box',
                        height: '44px',
                        color: '#808080'
                    }}
                    inputClass={'fullWidth roundButton'}
                    style={{
                        flex: 'auto'
                    }}
                />
                <span
                    style={{
                        padding: '14px 25px'
                    }}
                    onClick={this.props.onClose}
                >
                    <img src={closeImg} alt={'close'}/>
                </span>
                <div style={{
                    position: 'absolute',
                    top: '60px',
                    bottom: '240px',
                    left: 0,
                    right: 0,
                    height: 'auto'
                }}>
                    { content }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
