import React from 'react';
import "../../main/main.css";
import Translate from "localization/Translate";
import Button from "../../buttons/Button";
import paybackLogoImg from "./img/paybackLogo.png";
import paybackCardImg from "./img/paybackCard.png";
import { useActionListener } from 'redux-action-listener-hook';
import {isCardCode, isPaybackPayCode} from "./util";
import {addErrorAlert} from "../../redux/actions";
import PBP from "../../redux/payment/integrations/paybackPay/main";

const ScanCustomerCard = (props) => {
    useActionListener('SCANNER_DETECTED', (dispatch, action) => {
        console.log({useActionListener: action});
        const scannedCode = action.payload;
        if(isCardCode(scannedCode)){
            props.setPaybackCustomer(scannedCode);
        }else if(isPaybackPayCode(scannedCode)){
            props.setPaybackPayCode(new PBP.QRCode(scannedCode));
        }else{
            dispatch(addErrorAlert('Not a proper Payback card number!'));
        }
    });

    let paymentButton = (
        <Button
            onClick={e => {
                props.paybackInputDone();
            }}
            type="button"
            className={"largeButton yellowButton"}
            name={<Translate id="I don't have a Payback card"/>}
            arrow="right"
            flencheClass={"flencheSmallRightBottom yellowFlenche"}
            style={{
                marginTop: '20px'
            }}
        />
    );
    let enterCardCodeButton = (
        <div style={{marginBottom: "20px"}} onClick={e => {
            props.enterCardCode();
        }}>
            <Button type="button" className={"largeButton blueButton"} name={<Translate id="Enter Payback customer number"/>}
                    arrow="right" flencheClass={"flencheSmallRightBottom blueFlenche"}/>
        </div>
    );

    let orLine = (
        <div style={{
            width: "300px",
            margin: "auto"
        }}>
            <div className={"diagonalLine"} style={{
                float: "left",
                marginTop: "40px"
            }}/>
            <span style={{
                textTransform: "uppercase",
                fontStyle: "italic",
                color: "#0082C3",
                fontSize: "20px"
            }}>
                            <Translate id="or"/>
                        </span>
            <div className={"diagonalLine"} style={{
                float: "right",
                marginTop: "10px"
            }}/>
        </div>
    );
    return (
        <div>
            <span className={"boldUppercaseText"}>
                <Translate id={"Scan your Payback card"}/>
            </span>
            <br/>
            <span style={{
                color: "#0283c3",
                textTransform: "uppercase",
                fontSize: 24,
                fontStyle: "italic"
            }}>
                <Translate id={"Also available in the Payback app"}/>
            </span>
            <br/>
            <div style={{
                "marginBottom": "20px"
            }}/>
            <img src={paybackLogoImg} style={{
                width: 100,
                position: "absolute",
                right: "calc(50% - 160px)"
            }} alt="paybackLogoImg"/>
            <img src={paybackCardImg} style={{
                maxWidth: "90%",
                maxHeight: "280px",
                display: "block",
                margin: "0 auto"
            }} alt="scanPaybackCard"/>
            <div style={{}}>
                {orLine}
                <div style={{
                    position: "absolute",
                    width: "100%",
                    bottom: "18px"
                }}>
                    {enterCardCodeButton}
                    {paymentButton}
                </div>
            </div>
        </div>
    );
}

export default ScanCustomerCard;