import React, {Component} from 'react';
import "../main.css"
import Translate from "localization/Translate";
import {connect} from "react-redux";
import {closeCallAssistance, openAdministrationView, openLogonModal, setTransactionUser} from "../../redux/actions";
import userImgErply from "../../icons/Erply/teammate.png";
import Button from "../../buttons/Button";
import Modal from "../../modal/Modal";
import Image from "../../theme/Image";


const mapStateToProps = state => {
    return {
        callAssistance: state.rootReducer.callAssistance.length > 0 ? state.rootReducer.callAssistance[state.rootReducer.callAssistance.length - 1] : false,
        administration: state.rootReducer.administration,
        theme: state.rootReducer.theme,
        cashierMode: state.rootReducer.cashierMode
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logon: (callAssistance) => {
            console.log('logon: ', callAssistance, callAssistance.onCancel);
            dispatch(closeCallAssistance(callAssistance.id));
            if(callAssistance.administrationView !== false){
                dispatch(openAdministrationView(callAssistance.administrationView, callAssistance.canClose, callAssistance.onCancel, callAssistance.data));
            }else{
                dispatch(openLogonModal(callAssistance.titleMessage, (data) => {
                    callAssistance.onConfirm(data);
                    dispatch(setTransactionUser(data[0]));
                }, callAssistance.canClose));
            }
        },
        close: (callAssistance) => {
            dispatch(closeCallAssistance(callAssistance.id));
        }
    }
};

class CallAssistance extends Component {
    render() {
        if(this.props.callAssistance === false){
            return null;
        }
        if(this.props.administration !== false || this.props.cashierMode){
            setTimeout(() => {
                this.props.logon(this.props.callAssistance);
            }, 1);
            return null;
        }

        let confirmButton;
        let onConfirm = e => this.props.logon(this.props.callAssistance);
        let cancelButton;

        if(this.props.callAssistance.canCancel){
            let onCancel = e => {
                this.props.callAssistance.onCancel();
                this.props.close(this.props.callAssistance);
            }
            if(this.props.theme === 'Erply'){
                cancelButton = (
                    <div className={"dialogueConfirmButton"} onClick={onCancel}>
                        <Button type="button"
                                className={"largeButton grayButton roundButton uppercaseText"}
                                name={<Translate id="cancel"/>}
                                arrow="left"
                        />
                    </div>
                );
            }else{
                cancelButton = (
                    <div className={"dialogueConfirmButton"} onClick={onCancel}>
                        <Button type="button" className={"largeButton grayButton"} name={<Translate id="cancel"/>}
                                arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
                    </div>
                );
            }
        }

        let content;

        if(this.props.theme === 'Erply'){
            confirmButton = (
                <div className={"dialogueConfirmButton"} onClick={onConfirm}>
                    <Button type={"button"}
                            className={"largerButton lightBlueButton roundButton uppercaseText"}
                            arrow={"rightClose"}
                            name={<Translate id={this.props.callAssistance.confirmText}/>}
                    />
                </div>
            );

            content = (
                <div>
                    <div style={{
                        float: "right"
                    }}>
                        <Translate id={this.props.callAssistance.titleMessage}/>
                    </div>
                    <br/>
                    <img src={userImgErply} alt={'User image'} style={{
                        display: "block",
                        paddingTop: "60px",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}/>
                    <span style={{
                        textAlign: 'center',
                        display: 'block',
                        marginTop: '40px',
                        fontSize: '24px',
                        fontWeight: 'bold'
                    }}>
                        <Translate id={"Please call a teammate"}/>
                    </span>
                    {this.props.callAssistance.content}
                    <div className={"dialogueConfirmButtons"}>
                        { cancelButton }
                        { confirmButton }
                    </div>
                </div>
            );
        }else{
            confirmButton = (
                <div className={"dialogueConfirmButton"} onClick={onConfirm}>
                    <Button type={"button"}
                            className={"largeButton blueButton"}
                            flencheClass={"flencheSmallRightBottom blueFlenche"}
                            arrow={"right"}
                            name={<Translate id={this.props.callAssistance.confirmText}/>}
                    />
                </div>
            );
            content = (
                <div>
                    <div style={{
                        float: "right"
                    }}>
                        <Translate id={this.props.callAssistance.titleMessage}/>
                    </div>
                    <br/>
                    <Image image={"user"} alt={"User image"} style={{
                        display: "block",
                        marginTop: "40px",
                        height: "120px",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}/>
                    <h1 className={"bigUpperCaseHeading"}><Translate id={"Please call a teammate"}/></h1>
                    {this.props.callAssistance.content}
                    <div className={"dialogueConfirmButtons"}>
                        { cancelButton }
                        { confirmButton }
                    </div>
                </div>
            );
        }
        return (
            <Modal className={"callAssistance"} content={content} canClose={false}/>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallAssistance);
