import React, { Component } from "react";
import {connect} from "react-redux";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../uiComponents/WrappedPerfectScrollBar';
import QuickSelectProducts from "./QuickSelectProducts";
import {addProduct, findProducts, updateProductGroupProducts} from "../redux/actions";
import Product from "../redux/dto/product";
import ProductSelectList from "./lists/ProductSelectList";
import {getISO6392BFromISO6391, getProductGroupByID} from "../redux/selectors";
import ImageBoxErply from "../buttons/ImageBoxErply";
import arrowBack from "./images/Erply/arrowBack.png";
import arrowForward from "./images/Erply/arrowForward.png";
import {Loader} from "./Loading";

const mapStateToProps = state => {
    return {
        productGroups: state.rootReducer.productGroups,
        user: state.rootReducer.user,
        warehouseID: state.rootReducer.warehouse.warehouseID,
        pointOfSaleID: state.rootReducer.pos.pointOfSaleID,
        productGroupsOnLine: window.innerWidth < 1400 ? 4 : 5,
        productLines: window.innerHeight < 880 ? 2 : 3,
        productsOnLine: window.innerWidth < 1400 ? 4 : 6,
        language: state.localize.activeLocale
    }
};

const mapDispatchToProps = dispatch => {
    return {
        findProducts: (productGroupID, pageNo, recordsOnPage, warehouseID, pointOfSaleID, onDone) => {
            dispatch(findProducts({groupID: productGroupID, pageNo, recordsOnPage}, warehouseID, pointOfSaleID, result => {
                setTimeout(onDone, 100);
                return updateProductGroupProducts(productGroupID, result, pageNo);
            }, result => {
                setTimeout(onDone, 100);
                return updateProductGroupProducts(productGroupID,undefined, 1);
            }));
        },
        clearProductGroupProducts: (productGroupID) => {
            dispatch(updateProductGroupProducts(productGroupID,undefined, 1));
        },
        setProduct: (product) => {
            let productDTO = new Product(product.name, product.priceListPrice, product.priceListPriceWithVat, 1, product);
            dispatch(addProduct(productDTO));
        }
    }
};

const getPictureUrl = (user, pictureID) => {
    return user.loginUrl + 'file.php?pic=prodgroup_pict&size=web&id=' + pictureID + '&authKey=' + user.sessionKey;
};

class ProductGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayAllGroups: false,
            activeGroupID: false,
            loadingProducts: false,
            productPageNo: 1
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('productGroups updated', prevState, this.state);
        if(!this.state.loadingProducts && this.state.activeGroupID !== false){
            let activeGroup = getProductGroupByID(this.props.productGroups, this.state.activeGroupID);
            if( activeGroup !== false &&
                (
                    typeof activeGroup.products === "undefined" ||
                    (
                        prevState.activeGroupID !== this.state.activeGroupID && activeGroup.productsPageNo !== 1
                    )
                )
            ) {
                this.setState({
                    loadingProducts: true
                });
                this.props.findProducts(this.state.activeGroupID, this.state.productPageNo, this.getNumberOfProductsOnPage(), this.props.warehouseID, this.props.pointOfSaleID, () => {
                    this.setState({
                        loadingProducts: false
                    });
                });
            }
        }
    }

    getNumberOfProductsOnPage(){
        return this.props.productLines * this.props.productsOnLine - 2;
    }

    getGroupName(group) {
        let lang = getISO6392BFromISO6391(this.props.language);
        if(lang === false){
            return group.name;
        }
        lang = lang.toUpperCase();
        console.log('getGroupName', lang, group['name' + lang]);
        return group['name' + lang];
    }

    render() {
        let displayGroups;
        let openAllButton;
        let backButton;
        let activeGroup = this.state.activeGroupID !== false ? getProductGroupByID(this.props.productGroups, this.state.activeGroupID) : false;

        console.log('activeGroup', this.state.activeGroupID, activeGroup);

        let NavButton = ({title = '', onClick, elementsOnLine = this.props.productGroupsOnLine}) => {
            return (
                <ImageBoxErply
                    boxStyle={{
                        backgroundColor: '#5ABB7C',
                        color: '#fff'
                    }}
                    textStyle={{
                        position: 'initial',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        fontSize: 48
                    }}
                    title={title}
                    width={(100 / elementsOnLine) + '%'}
                    height={150}
                    padding={'5px'}
                    onClick={onClick}
                />
            );
        };

        let OpenAllButton = () => {
            return <ImageBoxErply
                boxStyle={{
                    backgroundColor: '#5ABB7C',
                    color: '#fff'
                }}
                textStyle={{
                    position: 'initial',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}
                title={'...'}
                width={(100 / this.props.productGroupsOnLine) + '%'}
                height={150}
                onClick={e => {
                    this.setState({
                        displayAllGroups: true
                    });
                }}
            />;
        };

        if(activeGroup !== false){
            if(this.state.displayAllGroups){
                displayGroups = activeGroup.subGroups;
                backButton = <NavButton
                    title={<span><i className={"icoFont"}>&#xea5c;</i><span style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        overflow: 'hidden',
                        fontSize: 18
                    }}>{this.getGroupName(activeGroup)}</span></span>}
                    onClick={e => {
                        this.setState({
                            displayAllGroups: false
                        });
                    }}
                />
            }else{
                displayGroups = activeGroup.subGroups.slice(0, this.props.productGroupsOnLine - 2);
                if(activeGroup.subGroups.length > displayGroups.length){
                    openAllButton = <OpenAllButton/>;
                }
                let parentGroupID = activeGroup.parentGroupID === '0' ? false : parseInt(activeGroup.parentGroupID);
                backButton = <NavButton
                    title={<span><img src={arrowBack} alt={'Back'} width={30}/><span style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        overflow: 'hidden',
                        fontSize: 18
                    }}>{this.getGroupName(activeGroup)}</span></span>}
                    onClick={e => {
                        this.setState({
                            activeGroupID: parentGroupID,
                            productPageNo: 1
                        });
                    }}
                />
            }
        }else if(this.state.displayAllGroups) {
            displayGroups = this.props.productGroups;
            backButton = <NavButton
                title={<span><img src={arrowBack} alt={'Back'} width={30}/></span>}
                onClick={e => {
                    this.setState({
                        displayAllGroups: false
                    });
                }}
            />
        }else{
            if(Array.isArray(this.props.productGroups)){
                displayGroups = this.props.productGroups.slice(0, (this.props.productGroupsOnLine - 1));
            }
            openAllButton = <OpenAllButton/>
        }

        let displayProducts;

        if(this.state.displayAllGroups === false){
            if(this.state.loadingProducts){
                displayProducts = <Loader position={"absolute"}/>
            }else if(activeGroup === false){
                displayProducts = <QuickSelectProducts/>;
            }else{
                if(typeof activeGroup.products !== "undefined"){
                    let productPageButtons;
                    if(activeGroup.products.length === this.getNumberOfProductsOnPage()){
                        productPageButtons = (
                            <div>
                                <NavButton
                                    title={<span><img src={arrowBack} alt={'Back'} width={30}/></span>}
                                    onClick={e => {
                                        if(this.state.productPageNo > 1){
                                            this.props.clearProductGroupProducts(this.state.activeGroupID);
                                            this.setState({
                                                productPageNo: this.state.productPageNo - 1
                                            });
                                        }
                                    }}
                                    elementsOnLine={this.props.productsOnLine}
                                />
                                <NavButton
                                    title={<span><img src={arrowForward} alt={'Forward'} width={30}/></span>}
                                    onClick={e => {
                                        this.props.clearProductGroupProducts(this.state.activeGroupID);
                                        this.setState({
                                            productPageNo: this.state.productPageNo + 1
                                        });
                                    }}
                                    elementsOnLine={this.props.productsOnLine}
                                />
                            </div>
                        );
                    }

                    displayProducts = (
                        <div>
                            <ProductSelectList
                                products={activeGroup.products}
                                setProduct={this.props.setProduct}
                                productsOnLine={this.props.productsOnLine}
                                containerStyle={{
                                    clear: 'both'
                                }}
                                language={this.props.language}
                            />
                            {productPageButtons}
                        </div>
                    );
                }
            }
        }

        return (
            <PerfectScrollbar style={{
                position: "absolute",
                left: 0,
                right:0,
                top: 60,
                bottom: 0,
                height: 'calc(100% - 220px)',
                paddingRight: '12px',
                zIndex: this.state.displayAllGroups ? 1000 : 1,
                backgroundColor: '#fff',
            }}>
                { backButton }
                { window.AppConf.showProductGroups !== false ?
                    displayGroups.map((productGroup, index) => {
                        let picture = typeof productGroup.images !== "undefined" ? getPictureUrl(this.props.user, productGroup.images[0].pictureID) : false;
                        let boxStyle;
                        let textStyle;

                        if(picture !== false){
                            boxStyle = {
                                backgroundImage: 'url(' + picture + ')',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                            };
                            textStyle = {
                                bottom: 0,
                                backgroundColor: 'rgb(255,255,255,0.8)'
                            }
                        }else{
                            boxStyle = {
                                backgroundColor: '#3d94f6',
                                color: '#fff'
                            };
                            textStyle = {
                                position: 'initial',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                            }
                        }

                        return <ImageBoxErply
                            key={index}
                            boxStyle={boxStyle}
                            textStyle={textStyle}
                            title={this.getGroupName(productGroup)}
                            width={(100 / this.props.productGroupsOnLine) + '%'}
                            height={150}
                            onClick={e => {
                                this.setState({
                                    activeGroupID: productGroup.productGroupID,
                                    productPageNo: 1,
                                    displayAllGroups: false
                                });
                            }}
                        />
                    }) : null
                }
                { window.AppConf.showProductGroups !== false ? openAllButton : null}
                { displayProducts }
            </PerfectScrollbar>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroups);
