import React, {Component} from 'react';
import "./main.css"
import Button from "../buttons/Button";
import Modal from "../modal/Modal";
import Translate from "localization/Translate";
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {
        theme: state.rootReducer.theme
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDoneAction: (action) => {
            dispatch(action);
        }
    }
}

class DialogueView extends Component{
    render() {
        let confirmButton;
        let closeWindow = () => {
            if(typeof this.props.close !== "undefined" && this.props.close !== null){
                this.props.close();
            }
        };

        let onConfirm = e => {
            if(typeof this.props.onConfirm !== "undefined" && this.props.onConfirm !== null){
                this.props.onConfirm(this.props.onDoneAction);
            }
            closeWindow();
        };

        if(typeof this.props.confirmText !== "undefined" && this.props.confirmText !== null){
            if(this.props.theme === 'Erply'){
                confirmButton = (
                    <div className={"dialogueConfirmButton"} onClick={onConfirm}>
                        <Button type={"button"}
                                className={"largerButton lightBlueButton roundButton uppercaseText"}
                                arrow={"rightClose"}
                                name={this.props.confirmText}
                        />
                    </div>
                );
            }else{
                confirmButton = (
                    <div className={"dialogueConfirmButton"} onClick={onConfirm}>
                        <Button type={"button"}
                                className={this.props?.confirmButtonClass ?? "largeButton blueButton"}
                                flencheClass={"flencheSmallRightBottom blueFlenche"}
                                arrow={"right"}
                                name={this.props.confirmText}
                        />
                    </div>
                );
            }
        }

        let cancelButton;
        let onCancel = e => {
            if(typeof this.props.onCancel !== "undefined" && this.props.onCancel !== null){
                this.props.onCancel(this.props.onDoneAction);
            }
            closeWindow();
        };

        if(typeof this.props.cancelText !== "undefined" && this.props.cancelText !== null){
            if(this.props.theme === 'Erply'){
                cancelButton = (
                    <div className={"dialogueConfirmButton"} onClick={onCancel}>
                        <Button type={"button"}
                                className={"largerButton grayButton roundButton uppercaseText"}
                                arrow={"left"}
                                name={this.props.cancelText}
                        />
                    </div>

                );
            }else{
                cancelButton = (
                    <div className={"dialogueConfirmButton"} onClick={onCancel}>
                        <Button type={"button"}
                                className={this.props?.cancelButtonClass ?? "largeButton grayButton negativeActionButton"}
                                flencheClass={"flencheSmallRightBottom grayFlenche"}
                                arrow={"left"}
                                name={this.props.cancelText}
                        />
                    </div>

                );
            }
        }

        const messageIsHtml = /<\/?[a-z][\s\S]*>/i.test(this.props.message);
        const messageClass = typeof this.props.messageClass !== "undefined" ? this.props.messageClass : this.props.smallDialogue ? "smallDialogueMessage" : "dialogueMessage";
        const message = messageIsHtml ?
            <div className={messageClass} dangerouslySetInnerHTML={{__html: this.props.message}}/> :
            <div className={messageClass}><Translate id={this.props.message}/></div>;
        const content = (
            <div>
                <div className={"dialogueTitle"}><Translate id={this.props.title}/></div>
                {message}
                <div className={"dialogueConfirmButtons"}>
                    {cancelButton}
                    {confirmButton}
                </div>
            </div>
        );
        return (
            <Modal
                className={this.props.smallDialogue ? "smallDialogue" : "dialogue"}
                content={content}
                onClose={e =>
                    {
                        closeWindow();
                        if(typeof this.props.onCancel !== "undefined" && this.props.onCancel !== null){
                            this.props.onCancel();
                        }
                    }
                }
                canClose={this.props.canClose}/>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogueView);
