import React, {Component} from 'react';
import "../main.css"
import Translate from "localization/Translate";
import {connect} from "react-redux";
import Keyboard from "../../keyboard/Keyboard";
import {addErrorAlert} from "../../redux/actions";
import {
    displayPrice,
    getPaymentSumInDefaultCurrency,
    round005,
    round05,
    roundedPrice
} from "../../util/helperFunctions";
import Button from "../../buttons/Button";
import {getCurrencyByCode} from "../../redux/selectors";

const mapStateToProps = state => {
    let currencyCodes = [];
    let currenciesConf = state.rootReducer.erplyConf?.touchpos_sale_additional_currencies ?? "";
    if(currenciesConf !== ""){
        currencyCodes = currenciesConf.split(",");
    }
    currencyCodes.unshift(state.rootReducer.erplyConf.default_currency);
    return {
        currency: state.rootReducer.currency,
        defaultCurrencyCode: state.rootReducer.erplyConf.default_currency,
        currencyCodes,
        currencies: state.rootReducer.currencies
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addErrorAlert: (message) => {
            dispatch(addErrorAlert(message));
        }
    }
};

class PaymentInput extends Component{
    constructor(props) {
        super(props);
        let currency = getCurrencyByCode(this.props.currencies, this.props.defaultCurrencyCode);
        if(typeof currency === "undefined"){
            currency = {
                code: this.props.defaultCurrencyCode,
                rate: 1
            }
        }
        this.state = {
            change: 0,
            paymentSum: '',
            currency
        };

        this.calculateChange = this.calculateChange.bind(this);
        this.updateValue = this.updateValue.bind(this);
    }

    updateValue (value, key, oldValue) {
        let paymentSum = value;
        let lastChar = value.substring(value.length - 1);
        if(isNaN(key) && key !== ',' && key !== '.' && key !== 'delete'){
            paymentSum = paymentSum.slice(0, -1);
        }
        paymentSum = paymentSum.replace(',', '.');
        if(key === ',' || key === '.'){
            if(paymentSum.slice(0,-1).indexOf('.') !== -1){
                paymentSum = paymentSum.slice(0, -1);
            }
        }
        if(paymentSum[0] === '.'){
            paymentSum = '0' + paymentSum;
        }
        if(this.props.totalSum < 0 && parseFloat(paymentSum) > 0){
            paymentSum *= -1;
        }

        if(lastChar !== '.' && paymentSum !== ''){
            let parts = paymentSum.toString().split('.');
            let decimals = parts?.[1] || '';
            if(parts[0].length > 12){
                paymentSum = parts[0].slice(0,-1) + (decimals !== ''? '.' + decimals : '');
            }
            if(decimals.length > 2){
                paymentSum = paymentSum.slice(0, -1 * (decimals.length - 2));
            }
            if(this.state.currency.default === "1") {
                if(this.props.roundToNearestFiveCents){
                    paymentSum = round005(paymentSum, decimals.length);
                }else if(this.props.roundToNearestFiftyCents){
                    paymentSum = round05(paymentSum, decimals.length);
                }
            }
            this.calculateChange(this.props.totalSum, paymentSum, this.props.canGiveChange);
        }else{
            this.setState({
                paymentSum
            });
        }
    }
    calculateChange(total, paymentSum, canGiveChange) {
        let paymentSumInDefaultCurrency = paymentSum;
        if(this.state.currency.rate !== 1){
            paymentSumInDefaultCurrency = getPaymentSumInDefaultCurrency(paymentSum, this.state.currency.rate);
        }
        let change = parseFloat((paymentSumInDefaultCurrency - total));
        if(isNaN(change)) change = 0;

        let setChange = (paymentSum, change) => {
            if(parseInt(change).toFixed(2) !== change){
                let oldChange = parseFloat(change);
                if(this.props.roundToNearestFiveCents){
                    if(change >= (this.state.currency.default === "1" ? 0 : -0.05)){
                        change = Math.round(change * 10) / 10;
                        this.props.payment.cashRounding = (change - oldChange).toFixed(2);
                    }
                }else if(this.props.roundToNearestFiftyCents){
                    if(change >= (this.state.currency.default === "1" ? 0 : -0.5)){
                        change = Math.round(change);
                        this.props.payment.cashRounding = (change - oldChange).toFixed(2);
                    }
                }
            }
            this.setState({
                paymentSum,
                change
            });
        };

        if(total >= 0){
            if(canGiveChange){
                setChange(paymentSum, parseFloat(roundedPrice(change)));
            }else{
                let newPaymentSum = paymentSum;
                if(change > 0){
                    newPaymentSum = total;
                }
                setChange(newPaymentSum, 0);
            }
        }else{
            if(canGiveChange){
                setChange(paymentSum, parseFloat(roundedPrice(change)));
            }else{
                let newPaymentSum = paymentSum;
                if(change <= 0){
                    newPaymentSum = total;
                }
                setChange(newPaymentSum, 0);
            }
        }
    }

    render() {
        let onConfirm = this.props.onConfirm;
        let totalSum = this.props.totalSum;
        let sumChange = this.state.change;
        let currency = this.state.currency;
        let canGiveChange = this.props.canGiveChange;
        let addErrorAlert = this.props.addErrorAlert;
        let roundToNearestFiveCents = this.props.roundToNearestFiveCents || false;
        let roundToNearestFiftyCents = this.props.roundToNearestFiftyCents || false;

        let fullAmountButton = this.props.fullAmountButton || false;
        let currencyButtons = (this.props.currencyButtons || false) && this.props.currencyCodes.length > 1;

        return (
            <div>
                <div className={"dialogueTitle"}><Translate id={this.props.title}/></div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row"
                    }}>
                        <table className={"paymentInputTable"}>
                            <tbody>
                            <tr>
                                <td><h2><Translate id={"Total"}/></h2></td>
                                <td className={"textRight bold"}>{ displayPrice(totalSum) }</td>
                            </tr>
                            <tr className={"coloredPaymentRow"}>
                                <td><Translate id={this.props.paymentType}/> {currencyButtons !== false ? this.state.currency.code : ""}</td>
                                <td>
                                    <input
                                        name={"paymentInput"}
                                        className={"textRight"}
                                        type={"text"}
                                        value={this.state.paymentSum}
                                        autoFocus={true}
                                        onChange={(e) => {
                                            let newValue = e.target.value;
                                            let previousValue = this.state.paymentSum;
                                            let key = newValue.length > previousValue.length ? newValue.substring(newValue.length - 1) : "delete";
                                            this.updateValue(newValue, key, previousValue);
                                        }}
                                    />
                                </td>
                            </tr>
                            {
                                this.state.currency.code !== this.props.defaultCurrencyCode ?
                                    <tr>
                                        <td><Translate id={"currency rate"}/>: 1 {this.state.currency.code} -> {this.state.currency.rate} {this.props.defaultCurrencyCode}</td>
                                        <td className={"textRight bold"}>{getPaymentSumInDefaultCurrency(this.state.paymentSum, this.state.currency.rate)}</td>
                                    </tr>
                                    :
                                    null
                            }
                            <tr>
                                <td><h2><Translate id={"changeMoneySum"}/></h2></td>
                                <td className={"textRight bold"}>{ this.state.change }</td>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{
                            marginLeft: "20px"
                        }}>
                            {
                                currencyButtons ? this.props.currencyCodes.map((type, index) => {
                                    if(type === this.state.currency.code){
                                        return null;
                                    }
                                    return (
                                        <div
                                            key={index}
                                            onClick={e => {
                                                let currency = getCurrencyByCode(this.props.currencies, type);
                                                if(typeof currency !== "undefined") {
                                                    this.setState({
                                                        currency
                                                    });
                                                }
                                                setTimeout(() => {
                                                    this.calculateChange(totalSum, this.state.paymentSum, canGiveChange);
                                                },1);
                                            }}
                                            style={{
                                                margin: '20px'
                                            }}
                                        >
                                            {
                                                process.env.REACT_APP_ERPLY_MODE === "1" ?
                                                    <Button type="button" className={"mediumEqualButton lightBlueButton roundButton"} name={<Translate id={type}/>}/> :
                                                    <Button type="button" className={"largeButton blueButton"} name={<Translate id={type}/>}/>
                                            }
                                        </div>
                                    );
                                }) : null
                            }
                        </div>
                    </div>
                    {
                        fullAmountButton && this.state.currency.code === this.props.defaultCurrencyCode ? (
                            <div onClick={e => {
                                onConfirm(totalSum, 0, this.state.currency);
                            }} style={{marginBottom: '10px', display: 'inline-block'}}>
                                {
                                    process.env.REACT_APP_ERPLY_MODE === "1" ?
                                        <Button type="button" className={"mediumEqualButton lightBlueButton roundButton"} name={<Translate id="100%"/>}/> :
                                        <Button type="button" className={"largeButton blueButton"} name={<Translate id="100%"/>}/>
                                }
                            </div>
                        ) : null
                    }
                <div style={{
                    "position": "absolute",
                    "bottom": "0",
                    "left": "0",
                    "right": "0"
                }}>
                    <Keyboard options={{
                        layout: 'numbers',
                        inputFieldName: 'paymentInput',
                        onChangeToLetters: () => {},
                        onChangeToNumbers: () => {},
                        onDone: function () {
                            let inputValue = document.getElementsByName("paymentInput")[0].value;
                            let lastChar = inputValue.substring(inputValue.length - 1);
                            inputValue = parseFloat(inputValue);
                            if(isNaN(inputValue) || inputValue === 0 || isNaN(lastChar)){
                                addErrorAlert('please insert correct amount');
                            }else{
                                if(typeof onConfirm !== "undefined"){
                                    onConfirm(inputValue, sumChange, currency);
                                }
                            }
                        },
                        currentValue: this.state.paymentSum,
                        updateValue: this.updateValue
                    }}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInput);
