import React, {Component} from 'react';
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import {connect} from "react-redux";
import idCard from "../images/IDKaart.PNG";
import Button from "../../buttons/Button";
import ZipCodeSurvey from "../inputForms/ZipCodeSurvey";
import {
    addErrorAlert,
    changeMode,
    closeRightPanelDialogue,
    findCustomerByCardNumber,
    findCustomerByEmail,
    findCustomerByPhone,
    findCustomerByRegistryCode,
    setCustomerZipCode,
    setDefaultCustomer,
    setLoading,
    showRightPanelDialogue
} from "../../redux/actions";
import {readIdCard} from "../../redux/payment/actions";
import PaybackPayment from "../../integrations/payback/Payment";
import {APP_MODE_PAYMENT} from "../../redux/appConstants";
import EnterCustomerInfo from "../customerSearch/EnterCustomerInfo";
import {getCustomerSearchButtons, getProductsTotalSum, paybackEnabled} from "../../redux/selectors";
import EnterCustomerPhone from "../customerSearch/EnterCustomerPhone";
import FullscreenCustomerSearch from "./FullscreenCustomerSearch";
import ScanCustomerCountDown from "./ScanCustomerCountDown";
import EnterCustomer from "../customerSearch/EnterCustomer";
import {Loader} from "../Loading";
import Image from "../../theme/Image";

const mapStateToProps = state => {
    const customer = state.rootReducer.customer;
    const totalSum = getProductsTotalSum(state.rootReducer.productsInBasket);

    return {
        customer,
        eftIdCardIdentification: state.payment.idCardIdentification,
        showZipCodeSurvey: state.payment.zipCodeSurveyCounter === 1 && state.rootReducer.customerZipCode === false &&
            (state.rootReducer.erplyConf.DKTZipSurveyNonCustomer === "1" ? customer.default : true),
        showPayback: paybackEnabled() && parseFloat(totalSum) !== 0 ? !state.rootReducer.payback.inputDone : false,
        translate: getTranslate(state),
        theme: state.rootReducer.theme,
        decathlonTheme: state.rootReducer.decathlonTheme,
        countryCode: state.rootReducer.erplyConf.DKTCountryCode,
        pluginCustomerSearchButtons: state.rootReducer.pluginCustomerSearchButtons,
        mode: state.rootReducer.mode
    }
};

const mapDispatchToProps = dispatch => {
    return {
        startPayment: () => {
            dispatch(changeMode(APP_MODE_PAYMENT));
        },
        enterPlugin: (inputFields, onDone, onInputChange) => {
            dispatch(showRightPanelDialogue((
                <EnterCustomer
                    title={<Translate id={"findCustomer"}/>}
                    inputFields={inputFields}
                    onDone={onDone}
                    onBack={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                    onInputChange={onInputChange}
                />
            )));
        },
        enterEmail: () => {
            dispatch(showRightPanelDialogue((
                <EnterCustomerInfo
                    emailOnly={true}
                    title={<Translate id={"findCustomer"}/>}
                    onDone={(customerInfo) => {
                        dispatch(setLoading(true));
                        console.log('customerInfo: ', customerInfo);
                        dispatch(findCustomerByEmail(customerInfo.email, (customer)=>{
                            dispatch(setLoading(false));
                        }, (result) => {
                            dispatch(setLoading(false));
                        }));
                        dispatch(closeRightPanelDialogue());
                    }}
                    onBack={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                />
            )));
        },
        enterPhone: (countryCode) => {
            dispatch(showRightPanelDialogue((
                <EnterCustomerPhone
                    title={<Translate id={"findCustomer"}/>}
                    countryCode={countryCode}
                    onDone={(customerInfo) => {
                        dispatch(setLoading(true));
                        console.log('customerInfo: ', customerInfo);
                        dispatch(findCustomerByPhone(customerInfo.phone, countryCode, (customer)=>{
                            dispatch(setLoading(false));
                        }, (result) => {
                            dispatch(setLoading(false));
                        }));
                        dispatch(closeRightPanelDialogue());
                    }}
                    onBack={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                />
            )));
        },
        enterCardCode: () => {
            dispatch(showRightPanelDialogue((
                <EnterCustomerInfo
                    cardCode={true}
                    title={<Translate id={"findCustomer"}/>}
                    onDone={customer => {
                        dispatch(setLoading(true));
                        dispatch(findCustomerByCardNumber(customer.card_number, () => {
                            dispatch(setLoading(false));
                        }, () => {
                            dispatch(setLoading(false));
                        }));
                        dispatch(closeRightPanelDialogue());
                    }}
                    onBack={e => {
                        dispatch(closeRightPanelDialogue());
                    }}
                />
            )));
        },
        setDefaultCustomer: () => {
            dispatch(setDefaultCustomer());
        },
        startIdCardRead: (onDone) => {
            dispatch(readIdCard((response) => {
                onDone();
                let idCode = response?.records?.[0]?.additionalData?.iDCode;
                if(typeof idCode === "undefined" || idCode === ''){
                    return addErrorAlert('Could not read ID card');
                }
                return findCustomerByRegistryCode(response.records[0].additionalData.iDCode);
            }, () => {
                onDone();
                return addErrorAlert('Could not read ID card');
            }));
        },
        addZipCodeToCustomer: (zipCode) => {
            dispatch(setCustomerZipCode(zipCode));
        }
    }
};


class BeforePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readIdCard: false
        };

        this.customerSearch = this.customerSearch.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.getCurrentView() === null && this.props.mode !== APP_MODE_PAYMENT){
            this.props.startPayment();
        }
    }

    componentDidMount() {
        if(this.getCurrentView() === null){
            this.props.startPayment();
        }
    }

    customerSearch(){
        if(process.env.REACT_APP_ERPLY_MODE === "1"){
            if(this.props.eftIdCardIdentification){
                let startIdCardRead = () => {
                    if(this.state.readIdCard) return;

                    this.setState({
                        readIdCard: true
                    });
                    this.props.startIdCardRead(() => {
                        this.setState({
                            readIdCard: false
                        });
                    });
                };
                return (
                    <div>
                        <span className={"boldUppercaseText"} onClick={startIdCardRead}>
                            <Translate id={"click to scan id card"}/>
                        </span>
                        <br/>
                        <div style={{
                            "marginBottom": "30px"
                        }}/>
                        <img src={idCard} alt="Read ID Card" style={{
                            width: '50%'
                        }} onClick={startIdCardRead}/>
                        <br/>
                        <div style={{

                        }}>
                            <div style={{
                                position: "absolute",
                                width: "100%",
                                bottom: "18px"
                            }}>
                                <Button
                                    onClick={e => {
                                        this.props.setDefaultCustomer();
                                    }}
                                    type="button"
                                    className={"largerButton roundButton darkYellowButton uppercaseText"}
                                    name={<Translate id="I don't have ID card"/>}
                                    arrow="right"
                                    style={{
                                        marginTop: '20px'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );
            }else{
                this.props.setDefaultCustomer();
            }
        }
        if(window?.AppConf?.fullscreenCustomerSearch){
            return <FullscreenCustomerSearch
                onSkip={() => {
                    this.props.setDefaultCustomer();
                }}
            />
        }

        let paymentButton = (
            <Button
                onClick={e => {
                    this.props.setDefaultCustomer();
                }}
                type="button"
                className={`largeButton ${this.props.decathlonTheme === 'purple' ? 'grayButton' : 'yellowButton'}`}
                name={<Translate id="noDecathlonCard"/>}
                arrow="right"
                flencheClass={"flencheSmallRightBottom yellowFlenche"}
                style={{
                    marginTop: '10px'
                }}
                buttonStyle={{
                    minWidth: "300px"
                }}
            />
        );

        let customerSearchButton = (name, onClick, key) => (
            <div key={key} style={{marginBottom: "5px"}} onClick={onClick}>
                <Button type="button"
                        className={"mediumButton blueButton"}
                        name={<Translate id={name}/>}
                        arrow="right"
                        flencheClass={"flencheSmallRightBottom blueFlenche"}
                        buttonStyle={{
                            minWidth: "300px"
                        }}
                />
            </div>
        );

        let pluginCustomerSearchButtons = this.props.pluginCustomerSearchButtons.map((el, key) => {
            return customerSearchButton(el.name, () => {
                this.props.enterPlugin(el.inputFields, el.onDone, el.onInputChange);
            }, key);
        });

        let customerSearchButtons = getCustomerSearchButtons();
        let enterEmailButton;
        if(customerSearchButtons?.email ?? true){
            enterEmailButton = customerSearchButton("enterEmail", this.props.enterEmail);
        }
        console.log(this.props.pluginCustomerSearchButtons, {pluginCustomerSearchButtons, enterEmailButton});
        let enterCardCodeButton;
        if(customerSearchButtons?.card ?? true){
            enterCardCodeButton = customerSearchButton("enterCardCode", this.props.enterCardCode);
        }
        let enterPhoneButton;
        if(customerSearchButtons?.phone ?? true){
            enterPhoneButton = customerSearchButton("Enter phone number", e => {
                this.props.enterPhone(this.props.countryCode);
            });
        }

        let orLine = (
            <div style={{
                width: "300px",
                margin: "auto",
                position: "absolute",
                top: "50%",
                left: "calc(50% - 150px)"
            }}>
                <div className={"diagonalLine"} style={{
                    float: "left",
                    marginTop: "40px"
                }}/>
                <span className={"coloredText"} style={{
                    textTransform: "uppercase",
                    fontStyle: "italic",
                    fontSize: "20px"
                }}>
                            <Translate id="or"/>
                        </span>
                <div className={"diagonalLine"} style={{
                    float: "right",
                    marginTop: "10px"
                }}/>
            </div>
        );

        return (
            <div>
                <span className={"boldUppercaseText"}>
                        <Translate id={"scanDecathlonCard"}/>
                    </span>
                <br/>
                <div style={{
                    "marginBottom": "10px"
                }}/>
                <Image
                    image={"scanLoyaltyCard"}
                    alt="scanDecathlonCard"
                    style={{
                        maxWidth: "330px",
                        maxHeight: "130px"
                    }}
                />
                <br/>
                <ScanCustomerCountDown setDefaultCustomer={this.props.setDefaultCustomer}/>
                <br/>
                <div style={{

                }}>
                    { orLine }
                    <div style={{
                        position: "absolute",
                        width: "100%",
                        bottom: "18px"
                    }}>
                        { pluginCustomerSearchButtons }
                        { enterCardCodeButton }
                        { enterEmailButton }
                        { enterPhoneButton }
                        { paymentButton }
                    </div>
                </div>
            </div>
        );
    }
    zipCodeSurvey(){
        return (
            <ZipCodeSurvey
                onDone={({zipCode}) => {
                    this.props.addZipCodeToCustomer(zipCode);
                }}
            />
        );
    }
    getCurrentView(){
        if (this.props.showPayback) {
            return <PaybackPayment/>;
        } else if (this.props.customer === false) {
            return this.customerSearch();
        } else if (this.props.showZipCodeSurvey) {
            return this.zipCodeSurvey();
        } else {
            return null;
        }
    }

    render() {
        const view = this.getCurrentView();
        return view !== null ? view : (
            <Loader position={"absolute"}/>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeforePayment);