import { createSlice } from '@reduxjs/toolkit'
import {sFormat} from "../util/helperFunctions";

const initialState = {
    activeLocale: "en",
    languages: ["en"],
    locales: {}
}

const localizeSlice = createSlice({
    name: 'localize',
    initialState,
    reducers: {
        localeAdded(state, action) {
            const { name, messages } = action.payload;
            state.locales[name] = messages;
        },
        localeSelected(state, action) {
            state.activeLocale = action.payload;
        },
        languagesSet(state, action) {
            state.languages = action.payload;
        }
    }
})

export const formatLocale = (locale) => {
    if(locale.indexOf('_') !== -1){
        let parts = locale.split('_');
        return parts[0] + "-" + parts[1].toUpperCase();
    }
    return locale;
}

export const getTranslate = (state) => {
    return (id, variables = {}, options = {}) => {
        if(variables === null){
            variables = {};
        }
        let locale = options?.language ?? state.localize.activeLocale;
        let translation = state.localize.locales?.[locale]?.[id] ?? id;
        if(translation === "") return id;
        return sFormat(translation, variables);
    }
}
export const { localeAdded, localeSelected, languagesSet } = localizeSlice.actions;
export const getLanguages = (state) => {
    return state.localize.languages;
}
export default localizeSlice.reducer