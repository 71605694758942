import React from 'react';
import ReactFlagsSelect from "react-flags-select";
import {getCountryDialCode} from "../redux/selectors";

export default function ({selectedDialCode, setSelectedDialCode, defaultCountryCode}) {
    let dialCodeCountries = (window.AppConf?.dialCodeCountries ?? defaultCountryCode).toUpperCase().split(",");
    let dialCodeLabels = dialCodeCountries.reduce((acc, el) => {
        let countryDialCode = getCountryDialCode(el);
        if(countryDialCode !== false){
            acc[el] = "+" + countryDialCode;
        }
        return acc;
    }, {});

    return (
        <ReactFlagsSelect
            selectedSize={23}
            optionsSize={23}
            countries={dialCodeCountries}
            customLabels={dialCodeLabels}
            placeholder="+"
            selected={selectedDialCode}
            onSelect={(code) => {
                setSelectedDialCode(code);
            }}
        />
    );
}