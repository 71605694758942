import Decimal from "decimal.js";
import isoLanguageCodes from "iso-639-2";
import {getAttributeValue} from "../util/helperFunctions";
import {COUNTRY_CODE_TO_DIAL_CODE, ERPLY_LANGUAGE_TO_LANGUAGE} from "./appConstants";
import Product from "./dto/product";

export const getSecurityItems = productsInBasket => {
    return productsInBasket.filter(function (product) {
        return product.isSecurityItem();
    });
};

export const getProductFromBasketByID = (productsInBasket, productID) => {
    return productsInBasket.filter(function (product) {
        return product.data.productID === productID;
    });
};

export const getProductsTotalSum = products => {
    let total = products.reduce((acc, product) => {
        let rowTotal = new Decimal(product.rowTotal);
        acc = acc.plus(rowTotal);
        return acc;
    }, new Decimal(0));
    return total.toDecimalPlaces(4).toString();
};

export const getProductsTotalTax = products => {
    let total = products.reduce((acc, product) => {
        if(!(product.isUsedProduct())){
            let rowVAT = new Decimal(product.rowVAT);
            acc = acc.plus(rowVAT);
        }
        return acc;
    }, new Decimal(0));
    return total.toDecimalPlaces(4).toString();
};

export const getProductsTotalSumBeforeManualDiscount = (products, useNetPrices=false) => {
    let total = products.reduce((acc, product) => {
        let rowTotal = new Decimal(useNetPrices ? product.rowNetTotal : product.rowTotal);
        if(product.manualDiscountPercentage !== 0){
            if(product.manualDiscountPercentage === 100){
                rowTotal = useNetPrices ? product.originalPrice : product.originalPriceWithVAT;
            }else{
                rowTotal *= 1 / (1 - product.manualDiscountPercentage / 100);
            }
        }
        acc = acc.plus(rowTotal);
        return acc;
    }, new Decimal(0));
    return total.toDecimalPlaces(4).toString();
};

export const getPaymentsTotalSumPaid = payments => {
    let total = payments.reduce((acc, payment) => {
        let paid = new Decimal(payment.sumPaid);
        acc = acc.plus(paid);
        if(typeof payment.cashRounding !== "undefined"){
            acc = acc.plus(new Decimal(payment.cashRounding));
        }
        return acc;
    }, new Decimal(0));
    return total.toDecimalPlaces(4).toString();
};

export const getPaymentsTotalRounding = payments => {
    let total = payments.reduce((acc, payment) => {
        if(typeof payment.cashRounding !== "undefined"){
            acc = acc.plus(new Decimal(payment.cashRounding).mul(-1));
        }
        return acc;
    }, new Decimal(0));
    return total.toDecimalPlaces(4);
};

export const getProductsNetTotalSum = products => {
    return products.reduce((acc, product) => {
        acc += product.rowNetTotal;
        return parseFloat(acc.toFixed(4));
    }, 0);
};

export const getProductsVatTotalSum = products => {
    return products.reduce((acc, product) => {
        acc = acc.plus(new Decimal(product.rowVAT));
        return acc;
    }, new Decimal(0)).toFixed(4);
};

export const getFirstUnknownRFIDProduct = (products) => {
    return products[0] || false;
};

export const getProductByCode = (productsInBasket, code) => {
    return productsInBasket.find(product => product.data.code === code);
}

export const getCurrencySign = currency => {
    switch (currency) {
        case"USD":
        case"CAD":
        case"ARS":
        case"TTD":
        case"XCD":
        case"AUD":
            return "$";
        case"EUR":
            return "€";
        case"GBP":
            return "£";
        case"CZK":
            return "Kč";
        default:
            return currency
    }
};

export const getReasonCodesByPurpose = (reasonCodes, purpose, manualOnly = false) => {
    return reasonCodes.filter(reasonCode => {
        let display = true;
        if(manualOnly){
            display = (reasonCode.code.split("|::|")?.[2] ?? "1") === "1";
        }
        return reasonCode.purpose === purpose && display;
    });
};

export const getReasonCodeByID = (reasonCodes, id) => {
    return reasonCodes.find(reasonCode => {
        return reasonCode.reasonID === parseInt(id);
    });
};

export const getReasonCodeByNameAndPurpose = (reasonCodes, nameInput, purpose) => {
    return reasonCodes.find(reasonCode => {
        const name = reasonCode.name.toLowerCase();
        return reasonCode.purpose === purpose && (name.indexOf(nameInput.toLowerCase()) >= 0);
    });
};

export const getCommercialGestureReturnReason = (reasonCodes) => {
    return getReasonCodeByNameAndPurpose(reasonCodes, 'commercial gesture', 'RETURN') ||
            getReasonCodeByNameAndPurpose(reasonCodes, 'commercialgesture', 'RETURN');
};

export const getCommercialGestureDiscountReason = (reasonCodes) => {
    return getReasonCodeByNameAndPurpose(reasonCodes, 'commercial gesture', 'DISCOUNT') ||
            getReasonCodeByNameAndPurpose(reasonCodes, 'commercialgesture', 'DISCOUNT');
};

export const getDefectiveItemReturnReason = (reasonCodes) => {
    return getReasonCodeByNameAndPurpose(reasonCodes, 'defective', 'RETURN');
};

export const getReturnToStockReason = (reasonCodes) => {
    return getReasonCodeByNameAndPurpose(reasonCodes, 'returntostock', 'RETURN');
};


export const getVatRateByID = (vatRates, id) => {
    return vatRates.find(vatRate => {
        return vatRate.id === id.toString();
    });
};

export const getProductCategoryByID = (categories, id) => {
    return categories.find(category => {
        return category.productCategoryID.toString() === id.toString();
    });
};

export const basketHasDecathlonGiftCard = (productsInBasket, categories) => {
    return typeof productsInBasket.find(el => {
        if(typeof el.data.seriesID === "undefined"){
            return typeof el.giftCardCode !== "undefined";
        }
        let category = getProductCategoryByID(categories, el.data.seriesID);
        return typeof category !== "undefined" ? category.productCategoryName === "DKT:Giftcard" : false;
    }) !== "undefined";
}

export const getNumberOfDecathlonGiftCardsInBasket = (productsInBasket, categories) => {
    return productsInBasket.filter(el => {
        if(typeof el.data.seriesID === "undefined"){
            return typeof el.giftCardCode !== "undefined";
        }
        let category = getProductCategoryByID(categories, el.data.seriesID);
        return typeof category !== "undefined" ? category.productCategoryName === "DKT:Giftcard" : false;
    }).length;
}

export const getPeripheral = (peripherals, name) => {
    const filtered = peripherals.filter(function (peripheral) {
        return peripheral.name === name;
    });
    return filtered.length > 0 ? Object.assign({}, filtered[0]) : false;
};

export const getDocumentCustomer = (document) => {
    if(document.attributes !== undefined){
        let customerAttribute = document.attributes.find(el => {return el.attributeName === 'customer'});
        if(customerAttribute !== undefined){
            try {
                let attributeValue = JSON.parse(customerAttribute.attributeValue);
                return attributeValue['card_number'] || attributeValue['email'] || attributeValue['phone'] || document.clientName;
            }catch (e) {}

        }
    }
    return  document.clientName;
};

export const getEmployeeByID = (employeeID, employees) => {
    return employees.find((employee) => {
        return employee.employeeID.toString() === employeeID.toString();
    });
};

export const getPaymentTypeByReasonCode = (paymentTypes, reasonCode) => paymentTypes.find(el => el.quickBooksDebitAccount === reasonCode);
export const getPaymentTypeByType = (paymentTypes, type) => paymentTypes.find(el => el.type === type);

export const getProductGroupByID = (productGroups, id) => {
    for(let group of productGroups){
        if(group.productGroupID === id){
            return group;
        }
        let findGroup = getProductGroupByID(group.subGroups, id);
        if(findGroup !== false){
            return findGroup;
        }
    }
    return false;
};

export const getISO6392BFromISO6391 = (code) => {
    let language = isoLanguageCodes.find(el => {
        return el.iso6391 === code;
    });
    if(typeof language !== "undefined"){
        return language.iso6392B;
    }
    return false;
};

export const getProductName = (product, language) => {
    let lang = getISO6392BFromISO6391(language);
    if(lang === false){
        return product.name;
    }
    lang = lang.toUpperCase();
    return product['name' + lang];
};

export const getProductShortDescription = (product, language) => {
    let lang = getISO6392BFromISO6391(language);
    if(lang === false){
        return '';
    }
    lang = lang.toUpperCase();
    if(window.AppConf.productDescriptionFromAttributes){
        return getAttributeValue(product, 'shortDescriptionSCO_' + lang) || '';
    }else{
        return product['description' + lang] || product.description
    }
};

export const getNotConnectedPeripherals = (peripherals) => {
    return peripherals.filter(function (peripheral) {
        return peripheral.status !== 'connected';
    });
};

export const nonDiscountableProductsInList = (products) => {
    return typeof products.find((product) => !product.isDiscountable()) !== "undefined";
};

export const getNumberOfArticles = (products) => {
    return products.reduce((acc, product) => {
        if(!product._isContainer){
            acc = acc.plus(new Decimal(product.amount));
        }
        return acc;
    }, new Decimal(0)).toDecimalPlaces(4).toNumber();
};

export const getDocumentNotes = (pluginRequestParameters, payments) => {
    let notes = '';
    let addNote = (note) => {
        if(notes === ''){
            notes = note;
        }else{
            notes += " \n" + note;
        }
    };
    let pluginNotes = pluginRequestParameters?.saveSalesDocument?.notes ?? [];
    for(let note of pluginNotes){
        addNote(note);
    }
    for(let paymentData of payments){
        if(paymentData.documentNotes !== ''){
            addNote(paymentData.documentNotes);
        }
    }
    return notes;
};

export const displayNetPrices = (confParameters) => {
    if(typeof window.AppConf.displayNetPrices !== "undefined"){
        return window.AppConf.displayNetPrices;
    }
    return confParameters.locale_uses_price_with_tax === 0;
}

export const getTillNumber = (pos) => {
    return pos.name.slice(-2).replace(/^[0]/,'');
}

export const getTransactionMOPLimit = () => {
    return window?.AppConf?.transactionMOPLimit || 10;
}
export const getTransactionProductLineLimit = () => {
    return window?.AppConf?.transactionProductLineLimit || 50;
}

export const getCountryDialCode = (countryCode) => {
    const country = COUNTRY_CODE_TO_DIAL_CODE.find(el => el.code === countryCode);
    if(typeof country !== "undefined"){
        return country.dial_code;
    }
    return false;
}

export const getReceiptLanguage = (state) => {
    let receiptLanguage = state.rootReducer.erplyConf.default_language;
    if(receiptLanguage === 'est' && state.rootReducer.erplyConf.country === 'CZ'){
        receiptLanguage = 'cze';
    }
    if(receiptLanguage === 'gre' && state.rootReducer.erplyConf.country === 'PL'){
        return 'pl';
    }
    return ERPLY_LANGUAGE_TO_LANGUAGE[receiptLanguage] || 'en';
}

export const getCampaignByName = (state, name) => {
    return state.rootReducer.campaignsByName?.[name] ?? false;
}

export const mixedTransactions = () => {
    return window?.AppConf?.mixedTransaction ?? true;
}

export const getBagItemCode = () => {
    return window?.AppConf?.bagItemCode || false;
}

export const getUnknownItemCode = () => {
    return window?.AppConf?.unknownItemCode || "100000000011001";
}

export const usedProductInBasket = products => {
    return typeof products.find(product => product.isUsedProduct()) !== "undefined";
};

export const paybackEnabled = () => window?.AppConf?.payback || false;

export const cloneProduct = (product) => {
    return Object.assign(new Product(), structuredClone(product));
};
export const getProductsInBasket = (state) => {
    return state.rootReducer.productsInBasket.map((el) => {
        return cloneProduct(el);
    });
}

export const getReturnBaseDocument = (state) => {
    return structuredClone(state.rootReducer.returnBaseDocument);
}

export const getHasRightToMakeDiscount = (userRights) => {
    if(typeof userRights === "undefined"){
        return false;
    }
    return userRights.rightMakeDiscountInPOS == 1;
};

export const getCustomerSearchButtons = () => {
    return window?.AppConf?.customerSearchButtons ?? {
        "card": true,
        "email": true,
        "phone": true
    };
}

export const getCurrencyByCode = (currencies, code) => {
    let currency = currencies.find(currency => {
        return currency.code === code;
    });
    if(typeof currency === "undefined") return currency;

    let clone = structuredClone(currency);
    clone.rate = parseFloat(clone.rate);
    return clone;
};

export const isVoucherDetailProduct = (product) => {
    return product.data?.RFIDTag?.EPC === "WorkshopVoucherDetail";
}