import React from "react";

export default function (){
    return <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1093_4784)">
            <rect width="24" height="24" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.75 6.2998C7.75 3.98559 9.68579 2.0498 12 2.0498C14.3142 2.0498 16.25 3.98559 16.25 6.2998C16.25 8.61402 14.3142 10.5498 12 10.5498C9.68579 10.5498 7.75 8.61402 7.75 6.2998ZM12 3.5498C10.5142 3.5498 9.25 4.81402 9.25 6.2998C9.25 7.78559 10.5142 9.0498 12 9.0498C13.4858 9.0498 14.75 7.78559 14.75 6.2998C14.75 4.81402 13.4858 3.5498 12 3.5498ZM16.3604 15.247C15.2203 13.7119 13.6889 12.9067 12.0192 12.9496C8.69924 13.0347 5.75 16.3074 5.75 21.9998H4.25C4.25 15.8922 7.50076 11.5649 11.9808 11.4501C14.2111 11.3929 16.1797 12.4877 17.5646 14.3526C18.9428 16.2084 19.75 18.8252 19.75 21.8998H18.25C18.25 19.0744 17.5072 16.7912 16.3604 15.247Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_1093_4784">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}