import React from 'react';
import "../main.css"
import Translate from "localization/Translate";
import {displayPrice} from "../../util/helperFunctions";
import Trash from "../../icons/Trash";
import Image from "../../theme/Image";
import {getCurrencyByCode} from "../../redux/selectors";
import {useSelector} from "react-redux";

export default (props) => {
    let currencies = useSelector(state => state.rootReducer.currencies);
    let defaultCurrency = useSelector(state => state.rootReducer.erplyConf.default_currency);
    let serialNumber;
    if(props.payment.serialNumber !== ''){
        serialNumber = <span className={"unitPrice"}>N°: {props.payment.serialNumber}</span>
    }
    let currencyInfo;
    let currencyPrice;
    if(props.payment.currencyCode !== null){
        let currency = getCurrencyByCode(currencies, props.payment.currencyCode);
        currencyInfo = <span className={"unitPrice"}><Translate id={"currency rate"}/>: 1 {currency.code} -> {currency.rate} {defaultCurrency}</span>;
        currencyPrice = <span className={"unitPrice"}>{props.payment.cashPaid} {currency.code}</span>;
    }

    return (
        <tr className={"productLine"}>
            <td className={"productLineNameColumn"}>
                <span style={{textTransform: "capitalize"}}><Translate id={props.payment.title}/></span>
                <br/>
                { serialNumber }
                <br/>
                { currencyInfo }
            </td>
            <td className={"productLinePriceColumn"}>{displayPrice(props.payment.sumPaid)} {props.currency}
                <br/>
                { currencyPrice }
            </td>
            <td>
                <Image image={props.image} alt={'paymentImg'} style={{maxHeight: "60px"}}/>
            </td>
            {
                props?.displayDelete ?
                    <td onClick={e => props.removePayment(props.payment)} style={{"width": "20px"}}>
                        <Trash/>
                    </td> :
                    <td/>
            }
        </tr>
    );
}