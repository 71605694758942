import React from 'react';
import '../statusBar.css';
import {connect} from "react-redux";
import userImg from "./images/Teammate.png";
import * as uuid from "uuid";
import {openModal} from "../../redux/actions";
import SwitchUserView from "../../main/administration/SwitchUser";

export const SwitchUser = connect(state => {
    return {
        view: state.rootReducer.view,
        mode: state.rootReducer.mode
    }
}, function (dispatch) {
    return {
        switchUser: () => {
            console.log("Switch user");
            let logonModalID = uuid.v4();
            dispatch(openModal({
                content: <SwitchUserView modalID={logonModalID}/>,
                type: 'logon',
                id: logonModalID,
                className: "Administration",
                onClose: function(){},
                canClose: true
            }));
        }
    }
})(function (props) {
    if(props.view === 'app' && props.mode !== 'screenSaver'){
        return (
            <div className={"statusBarItemRight"} onClick={e => {props.switchUser();}}>
                <img src={userImg} alt={"Switch user"}/>
            </div>
        );
    }
    return <div/>;
});