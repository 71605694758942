import React, {useState} from "react";
import Translate from "localization/Translate";
import Keyboard from "../keyboard/Keyboard";

export default function ({inputLabel, inputLabelStyle, inputStyle, inputClass, style, onInputChange, placeholder, layout, onDone, beforeInput, initialInputValue, controlled}) {
    const [inputValue, setInputValue] = useState(initialInputValue || '');
    let inputLabelEl;
    if(typeof inputLabel !== "undefined"){
        inputLabelEl = (
            <label className={"capitalizeFirst"} style={inputLabelStyle || {
                display: "block",
                textAlign: "left",
                marginTop: "30px",
                fontSize: "14px"
            }}>
                <Translate id={inputLabel}/>
            </label>
        );
    }

    return (
        <div style={style ?? {}}>
            { inputLabelEl }
            { beforeInput }
            <input
                className={inputClass ?? "fullWidth shadowBoxInput"}
                style={inputStyle ?? {}}
                type={"text"}
                autoComplete={'off'}
                value={controlled ? initialInputValue : inputValue}
                autoFocus={true}
                onChange={(event) => {
                    setInputValue(event.target.value);
                    onInputChange(event.target.value);
                }}
                placeholder={placeholder || ''}
            />
            <div style={{
                "position": "absolute",
                "bottom": "0",
                "left": "0",
                "right": "0"
            }}>
                <Keyboard options={{
                    initialLayout: layout ?? 'letters',
                    currentValue: controlled ? initialInputValue : inputValue,
                    updateValue: (value) => {
                        setInputValue(value);
                        onInputChange(value);
                    },
                    onDone: () => {
                        onDone(controlled ? initialInputValue : inputValue);
                    }
                }}/>
            </div>
        </div>
    );
}
