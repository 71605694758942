import $ from "jquery"
import React from 'react';
import {dateFormat, stringToDate} from "../../../../util/calendar";
import {capitalizeFirstLetter} from "../../../../util/helperFunctions";

const RGFD = {
    isInit: false,
    barcodeLength: 39,
    meanOfPaymentCodes: [
        '118',
        '137',
        '154',
        '149',
        '146',
        '170'
    ],
    useCents: true,
    POS: {}
};

RGFD.init = function(warehouse, POS, erplyConfig, customerCode, translate){
    if(RGFD.isInit) return true;
    console.log('RGFD: init');
    RGFD.API.url = typeof window.AppConf.FirstData !== "undefined" ? window.AppConf.FirstData.url + '/sendRequest' : false;
    RGFD.API.preprod = window.AppConf.preprod ?? false;
    RGFD.warehouse = Object.assign({}, warehouse);
    RGFD.POS = Object.assign({}, POS);
    RGFD.POS.pointOfSaleID = RGFD.parsePointOfSaleID(RGFD.POS.pointOfSaleID);
    RGFD.currency = erplyConfig.default_currency;
    RGFD.API.auth = erplyConfig.DKT_PROXY_AUTH;
    RGFD.API.client_name = erplyConfig.DKT_ONEPAY_CLIENT_NAME;
    RGFD.API.merchantId = erplyConfig.DKT_ONEPAY_MERCHANT_ID || false;
    RGFD.customerCode = customerCode;

    if(erplyConfig.country === 'CL'){
        RGFD.useCents = false;
    }

    RGFD.isInit = true;

    RGFD.translate = translate;
};

RGFD.translate = (text) => {
    return text;
};

RGFD.API = {
    url: false,
    auth: '',
    client_name: '',
    merchantId: false,
    preprod: false
};

RGFD.isGiftCard = function(code){
    console.log('RGFD: is giftcard ' + code + ' ?');
    if(code.length !== RGFD.barcodeLength){
        console.log('RGFD: is not giftcard, wrong length');
        return false;
    }

    const meanOfPayment = RGFD.getMeanOfPaymentCode(code);
    if(RGFD.meanOfPaymentCodes.indexOf(meanOfPayment) === -1){
        console.log('RGFD: is not giftcard, wrong mean of payment code');
        return false;
    }

    console.log('RGFD: is giftcard');
    return true;
};

RGFD.cardCode = function(number, type, meanOfPayment, amount, currency, articleCode) {
    this.number = number;
    this.type = type;
    this.meanOfPayment = meanOfPayment;
    this.amount = amount;
    this.currency = currency;
    this.articleCode = articleCode;
};

RGFD.getMeanOfPaymentCode = function(code) {
    return code.substr(18,3);
};

RGFD.getArticleCode = function(code) {
    return code.substr(32,7);
};

RGFD.decodeBarcode = function (code) {
    const number = code.substr(0,16);
    const type = code.substr(16,2);
    const meanOfPayment = RGFD.getMeanOfPaymentCode(code);
    const amount = parseFloat(code.substr(21,8)) / 100;
    const currency = code.substr(29,3);
    const articleCode = RGFD.getArticleCode(code);
    return new RGFD.cardCode(number, type, meanOfPayment, amount, currency, articleCode);
};

RGFD.addGiftCardProduct = function(code){
    console.log('RGFD: adding giftcard product');
    RGFD.lastGiftCard = RGFD.decodeBarcode(code);
};


RGFD.giftCardInRows = function(productsInBasket) {
    return typeof productsInBasket.find((product) => {return typeof product.giftCardCode !== "undefined"}) !== "undefined";
};

RGFD.getGiftCardAttributes = function(){
    let attributes = [];
    let c = 1;
    $(RGFD.creditResults.data).each(function (index, result) {
        let data = {
            line: result.line,
            no: result.cardNumber,
            expirationDate: result.expirationDate,
            merchantId: RGFD.API.merchantId,
            authorizationCode: result.authorizationCode,
            referenceNumber: result.referenceNumber
        };
        attributes.push({
            name: 'POSLogGiftCard_' + c,
            type: 'text',
            value: JSON.stringify(data)
        });
        c++;
    });

    return attributes;
};

RGFD.getGiftCardPrintInfo = function(){
    let info = [];
    $(RGFD.creditResults.data).each(function (index, result) {
        info.push({
            amountCharged: result.chargedAmount,
            currencyCode: result.currency,
            expirationDate: dateFormat(stringToDate(result.expirationDate, 'Y-m-d'), 'd/m/Y'),
            transactionID: result.transactionID,
            cardType: result.meanOfPayment,
            no: result.cardNumber,
            authorizationCode: result.authorizationCode
        });
    });

    return info;
};

RGFD.getGiftCardCapturePrintInfo = function(giftCard){
    return {
        capturedAmount: giftCard.capturedAmount,
        currentBalance: giftCard.currentBalance,
        expirationDate: dateFormat(stringToDate(giftCard.expirationDate, 'Y-m-d'), 'd/m/Y'),
        previousBalance: giftCard.previousBalance,
        currencyCode: giftCard.currency,
        cardType: giftCard.meanOfPayment,
        authorizationCode: giftCard.authorizationCode,
        transactionID: giftCard.transactionID,
        no: giftCard.cardNumber
    };
};

RGFD.getGiftCardPrintInfoText = function(giftCards, translate){
    let text = "\n" + translate('Your gift card info') + "\n";
    text += "-------------------------------------------------\n";

    giftCards.map(giftCard => {
        text += capitalizeFirstLetter(translate('card number')) + ': ' + giftCard.no.substring(0, giftCard.no.length - 4) + '----' + "\n";

        if(typeof giftCard.amountCharged !== "undefined"){
            text += translate('Amount charged') + ': ' + giftCard.amountCharged + "\n";
        }else{
            text += translate('Captured amount') + ': ' + giftCard.capturedAmount + "\n";
            text += translate('Current balance of the card') + ': ' + giftCard.currentBalance + "\n";
            text += translate('Previous balance of the card') + ': ' + giftCard.previousBalance + "\n";
        }
        text += translate('Expiration date of the giftcard') + ': ' + giftCard.expirationDate + "\n";
        text += translate('Currency') + ': ' + giftCard.currencyCode + "\n";
        text += translate('Type of the card') + ': ' + giftCard.cardType + "\n";
        text += translate('Authorization code') + ': ' + giftCard.authorizationCode + "\n";
        text += translate('OnePay transaction id') + ': ' + giftCard.transactionID + "\n";
        text += "-------------------------------------------------";
    });

    return text;
};

RGFD.parsePointOfSaleID = function(pointOfSaleID){
    return parseInt(pointOfSaleID.toString().slice(-4));
};

RGFD.parseEmployeeID = function(employeeID){
    return parseInt(employeeID.toString().slice(-8));
};

RGFD.API.sendRequest = function(method, request, body){
    return new Promise(function (resolve, reject) {
        $.ajax({
            url: RGFD.API.url,
            type: "POST",
            crossDomain: true,
            timeout: 30000,
            data: {
                method: method,
                request: request,
                body: body,
                preprod: RGFD.API.preprod ? 1 : 0
            },
            beforeSend: function (xhr){
                xhr.setRequestHeader('Authorization', RGFD.API.auth);
            },
            success: function(data){
                resolve(data);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                reject(jqXHR, textStatus, errorThrown);
            }
        });
    });
};

RGFD.doCreditResendParameters = {

};
RGFD.API.doCredit = function(cardNumber, externalActionId, creditAmount, employeeID, onSuccess, onFailure){
    RGFD.doCreditResendParameters = {
        employeeID,
        onSuccess,
        onFailure
    };
    let clerkId = RGFD.parseEmployeeID(employeeID);
    let params = RGFD.API.getBaseParameters();
    params = RGFD.API.addCommonParameters(params, cardNumber);
    params = RGFD.API.addContractAttribute(params, 'externalActionId', externalActionId);
    params = RGFD.API.addContractAttribute(params, 'creditAmount', creditAmount);
    params = RGFD.API.addContractAttribute(params, 'clerkId', clerkId);

    RGFD.API.sendRequest('POST', 'do_credit', params).then(function (result) {
        RGFD.creditResults.handleSuccess(result, cardNumber, onSuccess, onFailure);
    }, function (result) {
        RGFD.creditResults.handleFail(result, cardNumber);
        onFailure();
    });
};

RGFD.API.doCheck = function(cardNumber){
    let params = RGFD.API.getBaseParameters();
    params = RGFD.API.addCommonParameters(params, cardNumber);
    params['transaction_id'] = 0;
    return RGFD.API.sendRequest('POST', 'do_check', params);
};

RGFD.API.doCapture = function(cardNumber, externalActionId, paymentAmount, employeeID){
    let clerkId = RGFD.parseEmployeeID(employeeID);
    let params = RGFD.API.getBaseParameters();
    let amount = RGFD.useCents ? parseFloat(paymentAmount).toFixed(2) : parseInt(paymentAmount);
    params['transaction_id'] = 0;
    params = RGFD.API.addCommonParameters(params, cardNumber);
    params = RGFD.API.addContractAttribute(params, 'externalActionId', externalActionId);
    params = RGFD.API.addContractAttribute(params, 'paymentAmount', amount);
    params = RGFD.API.addContractAttribute(params, 'clerkId', clerkId);

    return RGFD.API.sendRequest('POST', 'do_capture', params);
};

RGFD.API.doVoid = function(transaction_id){
    let params = RGFD.API.getBaseParameters();
    params['transaction_id'] = transaction_id;

    return RGFD.API.sendRequest('POST', 'do_void', params);
};

RGFD.API.addCommonParameters = function(params, cardNumber){
    params['contract_attributes'] = [];

    const contractAttributes = {
        cardNumber: cardNumber,
        orderId: RGFD.warehouse.code + RGFD.POS.name,
        currency: RGFD.currency,
        merchantId: RGFD.API.merchantId,
        storeNumber: RGFD.warehouse.code,
        posId: RGFD.POS.pointOfSaleID
    };

    $.each(contractAttributes, function (key, value) {
        params = RGFD.API.addContractAttribute(params, key, value);
    });

    return params;
};

RGFD.API.addContractAttribute = function(params, key, value){
    params['contract_attributes'].push({
        "conatt_key": key,
        "conatt_value": value
    });
    return params;
};

RGFD.API.getBaseParameters = function() {
    return {
        'client_name': RGFD.API.client_name,
        'channel': 'TILL'
    };
};

RGFD.API.getExternalActionID = function(invoiceID, invoiceNo, cardNumber){
    return 'EAID-' + RGFD.customerCode + '-' + invoiceID + '-' + invoiceNo + '-' + cardNumber;
};

RGFD.API.lastGenericExternalActionTime = false;
RGFD.API.getGenericExternalActionID = function(time = false){
    if(time === false){
        time = Date.now();
    }
    let nextID = 'EAID-' + RGFD.customerCode + '-' + RGFD.warehouse.warehouseID + '-' + RGFD.POS.pointOfSaleID + time;
    if(RGFD.API.lastGenericExternalActionTime >= time){
        return RGFD.API.getGenericExternalActionID(RGFD.API.lastGenericExternalActionTime + 1);
    }
    RGFD.API.lastGenericExternalActionTime = time;
    return nextID;
};

RGFD.API.resendCredit = function(cardNumber, amount, externalActionID){
    RGFD.creditResults.updateStatus(cardNumber, 'Pending');
    RGFD.API.doCredit(cardNumber, externalActionID, amount, RGFD.doCreditResendParameters.employeeID, RGFD.doCreditResendParameters.onSuccess, RGFD.doCreditResendParameters.onFailure);
};

RGFD.giftCard = function(line, cardNumber, externalActionID, chargedAmount, status, productLineNumber){
    this.line = line;
    this.cardNumber = cardNumber;
    this.externalActionID = externalActionID;
    this.chargedAmount = chargedAmount;
    this.status = status;
    this.done = 0;
    this.retry = 0;
    this.expirationDate = '';
    this.authorizationCode = '';
    this.referenceNumber = '';
    this.merchantId = RGFD.API.merchantId;
    this.productLineNumber = productLineNumber;
};

RGFD.getGiftCard = function (cards, cardNumber) {
    let giftCard = false;
    $(cards).each(function (index, card) {
        if(card.cardNumber === cardNumber){
            giftCard = card;
            return false;
        }
    });
    return giftCard;
};

RGFD.creditResults = {
    data: []
};

RGFD.creditResults.init = function(){
    console.log('RGFD: results init called');
    RGFD.creditResults.data = [];
};

RGFD.creditResults.update = function(){
    RGFD.creditResults.data = [...RGFD.creditResults.data];
    return true;
};

RGFD.creditResults.getRow = function(cardNumber){
    return RGFD.getGiftCard(RGFD.creditResults.data, cardNumber);
};

RGFD.creditResults.handleSuccess = function(result, cardNumber, onSuccess, onFailure){
    console.log('RGFD: credit call success');
    const response = JSON.parse(result);
    console.log('RGFD: response:', response);

    if(response.status.responseStatus === 'ok'){
        if(response.data.action_status === "CREDIT_SUCCESS"){
            RGFD.creditResults.updateStatus(cardNumber, 'Success', true, response.data.generic_parameter, response.data.transaction_id);
            onSuccess(response.data);
            return true;
        }else if(response.data.action_status === "CREDIT_ERROR"){
            RGFD.creditResults.updateStatus(cardNumber, response.data.generic_parameter.message);
            RGFD.creditResults.showRetryButton(cardNumber);
            onFailure(response.data);
            return true;
        }
    }

    RGFD.creditResults.updateStatus(cardNumber, 'Failed');
    RGFD.creditResults.showRetryButton(cardNumber);
    onFailure();
    console.log('RGFD: failed doCredit, card number: ' + cardNumber, response);

};

RGFD.creditResults.handleFail = function(result, cardNumber){
    console.log('RGFD: credit call failed');
    RGFD.creditResults.updateStatus(cardNumber, 'Failed');
    RGFD.creditResults.showRetryButton(cardNumber);
};

RGFD.creditResults.showRetryButton = function(cardNumber) {
    console.log('RGFD: showRetryButton', RGFD.creditResults.data, cardNumber);
    let row = RGFD.creditResults.getRow(cardNumber);
    row.retry = 1;
    RGFD.creditResults.update();
};

RGFD.creditResults.updateStatus = function(cardNumber, status, done, genericParameters, transaction_id){
    const row = RGFD.creditResults.getRow(cardNumber);
    row.status = status;
    if(typeof done !== 'undefined'){
        row.done = done;
    }
    if(typeof genericParameters !== 'undefined'){
        if(typeof genericParameters.expiryDate !== 'undefined'){
            row.expirationDate = genericParameters.expiryDate.split('T')[0];
        }
        if(typeof genericParameters.authorizationCode !== 'undefined'){
            row.authorizationCode = genericParameters.authorizationCode;
        }
        if(typeof genericParameters.referenceNumber !== 'undefined'){
            row.referenceNumber = genericParameters.referenceNumber;
        }
        row.currency = genericParameters.currency;
        row.meanOfPayment = genericParameters.meanOfPayment;
        row.transactionID = transaction_id;
    }
    RGFD.creditResults.update();
};

RGFD.creditResults.allSuccessful = function(){
    let allSuccessful = true;
    for(let creditResult of RGFD.creditResults.data){
        if(creditResult.status !== 'Success'){
            allSuccessful = false;
        }
    }
    return allSuccessful;
};

RGFD.creditResults.allVoided = function(data=RGFD.creditResults.data){
    let allVoided = true;
    for(let creditResult of data){
        if(creditResult.status === 'Success'){
            allVoided = false;
        }
    }
    return allVoided;
};

RGFD.payment = {};

RGFD.payment.searchGiftCard = function(number, resolve, reject) {
    const apiError = 'Could not perform the API call to check the number, try again!';

    RGFD.API.doCheck(number).then(function (result) {
        console.log('RGFD: doCheck payment', result);

        let availableAmount = 0;
        let response;
        try {
            response = JSON.parse(result);
        }catch (e) {
            reject(apiError);
            return false;
        }

        if(response.status.responseStatus === 'ok'){
            if(response.data.action_status === 'CHECK_SUCCESS'){
                availableAmount = response.data.generic_parameter.balance;
                const expired = RGFD.payment.isExpired(response.data.generic_parameter.expiryDate);
                if(expired){
                    reject('expired');
                }
                // TODO: check currency!!!
                let giftCard = new RGFD.giftCard(0, number, '', availableAmount, 'Pending');
                giftCard.expirationDate = response.data.generic_parameter.expiryDate.split('T')[0];
                resolve(giftCard);
            }else{
                reject(response.data?.generic_parameter?.message || response.data.action_status);
            }
        }else{
            reject(apiError);
        }
    }, function () {
        reject(apiError);
    });
};

RGFD.payment.isExpired = function(expirationDate){
    const now = (new Date()).getTime();
    if ("" !== expirationDate){
        const expiration = new Date(expirationDate);
        expiration.setHours(23,59,59,999);
        return now > expiration.getTime();
    }
    return false;
};

export default RGFD;
