import React from 'react';
import "../../main.css"
import {dateFormat, stringToDate} from "../../../util/calendar";
import {getDocumentCustomer} from "../../../redux/selectors";
import printImg from "../../images/Erply/print.png";
import giftImg from "../../images/Erply/gift.png";
import mailImg from "../../images/Erply/mail.png";
import exchangeImg from "../../images/Erply/returnMode.png";
import deleteImg from "../../images/Erply/trash.png";
import printPurpleImg from "../../images/DecathlonPurple/printer.png";
import giftPurpleImg from "../../images/DecathlonPurple/gift.png";
import mailPurpleImg from "../../images/DecathlonPurple/mail.png";
import exchangePurpleImg from "../../images/DecathlonPurple/exchange_return.png";
import {displayPrice} from "../../../util/helperFunctions";
import {useSelector} from "react-redux";
import Trash from "../../../icons/Trash";

export default function(props) {
    const theme = useSelector(state => state.rootReducer.theme);
    const decathlonTheme = useSelector(state => state.rootReducer.decathlonTheme);
    let customer = getDocumentCustomer(props.document);

    let printReceipt = props.printReceipt !== false ? (
        <td onClick={e => props.printReceipt(props.document)}
            style={{width: "20px", cursor: 'pointer'}}
            className={theme === 'Erply' ? 'leftGrayBorder' : ''}
        >
            {
                theme === 'Erply' ?
                    <img src={printImg} alt={"print"} width={24}/>
                    :
                    decathlonTheme === "purple" ?
                        <img src={printPurpleImg} alt={"print"} width={24}/>
                        :
                        <i className={"icoFont"} style={{color: '#0082C3', fontSize: '30px'}}>&#xedfd;</i>
            }
        </td>) : <td/>;

    let printGiftReceipt = props.printReceipt !== false ? (
        <td onClick={e => props.printReceipt(props.document, true)}
            style={{width: "20px", cursor: 'pointer'}}
            className={theme === 'Erply' ? 'leftGrayBorder' : ''}
        >
            {
                theme === 'Erply' ?
                    <img src={giftImg} alt={"print gift receipt"} width={24}/>
                    :
                    decathlonTheme === "purple" ?
                        <img src={giftPurpleImg} alt={"print gift receipt"} width={24}/>
                        :
                        <i className={"icoFont"} style={{color: '#0082C3', fontSize: '30px'}}>&#xef3c;</i>
            }
        </td>) : <td/>;

    let sendEmailReceipt = props.sendEmailReceipt !== false ? (
        <td onClick={e => props.sendEmailReceipt(props.document, true)}
            style={{width: "20px", cursor: 'pointer'}}
            className={theme === 'Erply' ? 'leftGrayBorder' : ''}
        >
            {
                theme === 'Erply' ?
                    <img src={mailImg} alt={"email receipt"} width={24}/>
                    :
                    decathlonTheme === "purple" ?
                        <img src={mailPurpleImg} alt={"email receipt"} width={24}/>
                        :
                        <i className={"icoFont"} style={{color: '#0082C3', fontSize: '30px', marginRight: '10px'}}>&#xef12;</i>
            }
        </td>) : <td/>;
    let refundReceipt = props.refundReceipt !== false ? (
        <td onClick={e => props.refundReceipt(props.document, true)}
            style={{width: "20px", cursor: 'pointer'}}
            className={theme === 'Erply' ? 'leftGrayBorder' : ''}
        >
            {
                theme === 'Erply' ?
                    <img src={exchangeImg} alt={"refund"} width={24}/>
                    :
                    decathlonTheme === 'purple' ?
                        <img src={exchangePurpleImg} alt={"refund"} width={24}/>
                        :
                        <i className={"icoFont"} style={{color: '#0082C3', fontSize: '30px', marginRight: '10px'}}>&#xef18;</i>
            }
        </td>) : <td/>;
    let deleteReceipt = typeof props.deleteReceipt !== "undefined" ? (
        <td onClick={e => {
            e.stopPropagation();
            props.deleteReceipt(props.document);
        }}
            style={{}}
            className={theme === 'Erply' ? 'leftGrayBorder' : ''}
        >
            {
                theme === 'Erply' ?
                    <img src={deleteImg} alt={"delete"} width={24}/>
                    :
                    <Trash/>
            }
        </td>
    ) : <td/>
    return (
        <tr className={`productLine ${props.className || ''}`} onClick={e => {
            if(typeof props.onClick !== "undefined"){
                props.onClick(props.document);
            }
        }}>
            <td>
                <span>{props.document.number}</span>
            </td>
            <td>
                <span>{dateFormat(stringToDate(props.document.date, 'Y-m-d'), props.dateFormat)}</span>
            </td>
            <td>
                <span>{customer}</span>
            </td>
            <td style={{
                textAlign: 'right'
            }}>
                <span>{displayPrice(props.document.total)}</span>
            </td>
            {printReceipt}
            {printGiftReceipt}
            {sendEmailReceipt}
            {refundReceipt}
            {deleteReceipt}
        </tr>
    );
}