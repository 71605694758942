import React, {Component} from 'react';
import "./main.css"
import Translate from "localization/Translate";
import {connect} from "react-redux";
import addProductErplyImg from "./images/Erply/addProducts.png";
import scanErplyImg from "./images/Erply/scan.png";
import Button from "../buttons/Button";
import {changeMode, setEditQuickProducts} from "../redux/actions";
import Payment from "./payment/Payment";
import {APP_MODE_PAYMENT, APP_MODE_BEFORE_PAYMENT} from "../redux/appConstants";
import ProductSearch from "./ProductSearch";
import ProductGroups from "./ProductGroups";
import BasketHeader from "./BasketHeader";
import BeforePayment from "./payment/BeforePayment";
import PaymentButton from "./PaymentButton";
import Image from "../theme/Image";

const mapStateToProps = state => {
    return {
        mode: state.rootReducer.mode,
        hasItems: state.rootReducer.productsInBasket.length > 0,
        rightPanelDialogue: state.rootReducer.rightPanelDialogue,
        rightPanelFullScreen: state.rootReducer.rightPanelFullScreen,
        editQuickPosProducts: state.rootReducer.editQuickPosProducts,
        quickPosProducts: state.rootReducer.quickPosProducts,
        theme: state.rootReducer.theme,
        decathlonTheme: state.rootReducer.decathlonTheme,
        showQuickSelectProducts: window.AppConf.showQuickSelectProducts
    }
};

const mapDispatchToProps = dispatch => {
    return {
        startPayment: () => {
            dispatch(changeMode(APP_MODE_BEFORE_PAYMENT));
        },
        setEditQuickProducts: (edit) => {
            dispatch(setEditQuickProducts(edit));
        }
    }
};

class RightPanel extends Component{
    constructor(props) {
        super(props);
        this.state = {
            productSearchOpen: false
        }
    }

    placeItems() {
        let content;
        if(process.env.REACT_APP_ERPLY_MODE === "1"){
            let Header = ({children, buttons}) => (
                <BasketHeader>
                        <span className={"boldUppercaseText"} style={{
                            paddingLeft: '30px',
                            verticalAlign: 'middle',
                            lineHeight: '3',
                            fontSize: '20px'
                        }}>
                            {children}
                            {buttons.map((button, index) => {
                                return (
                                    <div key={index} style={{
                                        marginTop: '10px',
                                        marginRight: '10px',
                                        float: 'right',
                                        lineHeight: 0
                                    }}>
                                        {button}
                                    </div>
                                );
                            })}
                        </span>
                </BasketHeader>
            );
            if(this.state.productSearchOpen){
                content = (
                    <ProductSearch
                        onSelect={product => this.setState({productSearchOpen: false})}
                        onClose={e => this.setState({productSearchOpen: false})}
                    />
                );
            }else{
                let closeEditQuickSelectButton;
                let cancelEditQuickSelectButton;
                if(this.props.editQuickPosProducts){
                    closeEditQuickSelectButton = (
                        <Button
                            type="button"
                            className={"mediumButton lightBlueButton roundButton uppercaseText"}
                            name={<Translate id="Edit done"/>}
                            onClick={e => {
                                this.props.setEditQuickProducts(false);
                            }}
                        />
                    );
                    cancelEditQuickSelectButton = (
                        <Button
                            type="button"
                            className={"mediumButton grayButton roundButton uppercaseText"}
                            name={<Translate id="cancel"/>}
                            onClick={e => {
                                this.props.setEditQuickProducts('cancel');
                            }}
                        />
                    );
                }

                let ScanProducts = () => (
                    <div className={"boldText"} style={{
                        marginTop: '40px'
                    }}>
                        <div style={{
                            marginBottom: '27px'
                        }}>
                            <img src={scanErplyImg} alt={'Scan'}/>
                        </div>
                        <span style={{fontSize: "24px"}}>
                            <Translate id={"Bring the item to the scanner"}/>
                        </span>
                        <div style={{
                            position: 'absolute',
                            top: '250px',
                            bottom: '150px',
                            left: 0,
                            right:0,
                            textAlign: 'center',
                            maxWidth: '450px',
                            margin: 'auto'
                        }}>
                            <img src={addProductErplyImg} alt="Scan product" style={{
                                maxWidth: '100%',
                                maxHeight: '100%'
                            }}/>
                        </div>
                    </div>
                );
                let headerButtons = [];
                if(window.AppConf.showProductSearch !== false){
                    headerButtons.push(
                        (
                            <Button
                                type="button"
                                className={"mediumEqualButton lightBlueButton roundButton uppercaseText"}
                                name={<Translate id="Add an item"/>}
                                onClick={e => this.setState({productSearchOpen: true})}
                            />
                        ), cancelEditQuickSelectButton, closeEditQuickSelectButton
                    );
                }
                content = (
                    <div>
                        <Header buttons={headerButtons}/>
                        { this.props.showQuickSelectProducts ? <ProductGroups/> : <ScanProducts/>}
                        <PaymentButton style={{
                            position: 'absolute',
                            bottom: '78px',
                            width: '100%',
                            zIndex: 4
                        }}/>
                    </div>
                );
            }
        }else{
            content = (
                <div className={"boldUppercaseText"} style={{
                    marginTop: "54px",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <span style={{fontSize: "35px"}}>
                        <Translate id={"drop your items"}/>
                    </span>
                    <span style={{fontSize: "50px"}} className={"cutUnderline"}>
                        <Translate id={"one at a time"}/>
                    </span>
                    <div style={{
                        flexGrow: 1
                    }}>
                        <Image
                            image={"addProductImg"}
                            alt="addProduct"
                           asBackground={true}
                        />
                        {
                            this.props.decathlonTheme === "blue" && window.AppConf.showHangerGif ?
                                (<span style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: '10px',
                                    fontSize: '16px',
                                    color: '#f75706',
                                    width: '120px'
                                }}>
                                    <Translate id={"Please leave the hanger"}/>
                                </span>) : ""
                        }
                    </div>
                    <div style={{ height: "200px" }}></div>
                    <PaymentButton/>
                </div>
            );
        }

        return content;
    }

    render() {
        if(this.props.rightPanelDialogue !== false){
            return (
                <div id={this.props.rightPanelFullScreen ? "rightPanelFullscreen" : "rightPanel"}>
                    {this.props.rightPanelDialogue.dialogue}
                </div>
            );
        }

        let display;
        if(this.props.mode === 'scan'){
            display = this.placeItems();
        } else if (this.props.mode === APP_MODE_BEFORE_PAYMENT) {
            display = (
                <div style={{
                    marginTop: "54px"
                }}>
                    <BeforePayment/>
                </div>
            );
        }else if (this.props.mode === APP_MODE_PAYMENT) {
            display = (
                <div style={{
                    marginTop: "54px"
                }}>
                    <Payment/>
                </div>
            );
        }

        return (
            <div id={this.props.rightPanelFullScreen ? "rightPanelFullscreen" : "rightPanel"} style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
            }}>
                { display }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
