import Decimal from "decimal.js";
import {unregister as unregisterSW} from "../serviceWorker";

export const ltrim = function(string, char) {
    let index = 0;
    while (string[index] === char){
        index++;
    }
    return string.substr(index);
};

export const isEmptyString = (string) => {
    return ltrim(string, " ") === "";
}

export function interpolate(template, variables, fallback) {
    return template.replace(/\${[^{]+}/g, (match) => {
        const path = match.slice(2, -1).trim();
        return getObjPath(path, variables, fallback);
    });
}

function getObjPath(path, obj, fallback = '') {
    return path.split('.').reduce((res, key) => res[key] || fallback, obj);
}

export const getAttributeValue = (object, attributeName) => {
    if(typeof object.attributes === "undefined") {
        return undefined;
    }
    let attribute = object.attributes.find(el => el.attributeName === attributeName || el.name === attributeName);
    return typeof attribute !== "undefined" ? attribute?.attributeValue || attribute.value : undefined;
};

export const getProductPackageWeight = (product) => {
    let attributeValue = getAttributeValue(product, 'packageWeight');
    if(typeof attributeValue === "undefined") {
        return undefined;
    }
    return attributeValue.replace(',', '.');
};

export const capitalizeFirstLetter = (s) => {
    return s.substr(0,1).toUpperCase() + s.slice(1);
};

export const round005 = (x, decimals = false) => {
    let isPositive = x >= 0;
    let value = Math.abs(x) * 100;
    let rounded = ((value % 5) >= 2.5 ? parseInt(value / 5) * 5 + 5 : parseInt(value / 5) * 5) / 100;
    if(!isPositive){
        rounded *= -1;
    }
    if(decimals !== false){
        return rounded.toFixed(decimals);
    }
    return parseFloat(rounded.toFixed(2));
};

export const round05 = (num,  decimals=2) => {
    let rounded = Math.round(num*2)/2;
    return parseFloat(rounded).toFixed(decimals);
};

export const numberIsOdd = (number) => {
    return number % 2 === 1;
}

export const displayPrice = (price) => {
    const thousandsSeparator = window?.AppConf?.thousandsSeparator ?? "";
    const number = roundedPrice(price);
    if(thousandsSeparator !== ""){
        return numberWithThousandsSeparators(number, thousandsSeparator);
    }
    return number;
}

export const roundedPrice = (price) => {
    const decimals = window?.AppConf?.priceDecimals ?? 2;
    return new Decimal(price).toFixed(decimals);
}

export const calculateNetPriceFromTotal = (price, vatrate) => {
    return (new Decimal(price)).div((new Decimal(vatrate)).div( 100).add(1)).toDecimalPlaces(6).toNumber();
}

export const calculateVatPrice = (netPrice, vatrate) => {
    return (new Decimal(netPrice)).mul((new Decimal(1)).plus((new Decimal(vatrate)).dividedBy(new Decimal(100)))).toDecimalPlaces(4).toNumber();
}

export const roundDown = (number, decimals) => {
    return new Decimal(number).toDecimalPlaces(decimals, Decimal.ROUND_DOWN).toNumber();
}

export const checkIfImageExists = (url) => {
    return new Promise(((resolve) => {
        const img = new Image();
        img.src = url;

        if (img.complete) {
            resolve(true);
        } else {
            img.onload = () => {
                resolve(true);
            };

            img.onerror = () => {
                resolve(false);
            };
        }
    }));
}

export const fetchWithTimeout = async (resource, options = {}) => {
    const { timeout = 30000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
        ...options,
        signal: controller.signal
    });
    clearTimeout(id);

    return response;
}

export const imageFile = (str) => {
    let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|ico|JPG|JPEG|PNG|GIF|ICO)$/);
    if (str == null) {
        return false;
    }
    return regex.test(str);
}

export const sleep = seconds => new Promise(resolve => setTimeout(resolve, seconds * 1000));

const urlWithRndQueryParam = (url, paramName) => {
    const ulrArr = url.split('#');
    const urlQry = ulrArr[0].split('?');
    const usp = new URLSearchParams(urlQry[1] || '');
    usp.set(paramName || '_z', `${Date.now()}`);
    urlQry[1] = usp.toString();
    ulrArr[0] = urlQry.join('?');
    return ulrArr.join('#');
}

export const handleHardReload = async(url, unregisterServiceWorker=false) => {
    const newUrl = urlWithRndQueryParam(url);
    await fetch(newUrl, {
        headers: {
            Pragma: 'no-cache',
            Expires: '-1',
            'Cache-Control': 'no-cache',
        },
    }).catch(() => {
        window.location.href = url;
    });
    if(unregisterServiceWorker){
        unregisterSW(() => {
            window.location.href = url;
        });
        //If service-worker does not become active
        setTimeout(() => {
            window.location.href = url;
        }, 5000);
    }else{
        window.location.href = url;
    }
}

export const hasMainVersionPreCache = () => {
    return new Promise((resolve) => {
        if (caches) {
            caches.keys().then(function(names) {
                let mainPrecache = names.find(name => {
                    if(name.indexOf("workbox-precache") !== -1){
                        let versionNumber = name.split("/").find(el => {
                            return isSemanticVersionNumber(el);
                        });
                        return  typeof versionNumber === "undefined"
                    }
                    return false;
                });
                resolve(typeof mainPrecache !== "undefined");
            }, () => {
                resolve(false);
            });
        }else{
            resolve(false);
        }
    });
}

export const unregisterMainSW = () => {
    navigator.serviceWorker.getRegistrations().then(registrationsArray => {
        for (let registration of registrationsArray){
            if(typeof registration.scope.split("/").find(el => {
                return !isSemanticVersionNumber(el);
            }) !== "undefined"){
                registration.unregister();
            }
        }
    });
}

export const checkAppMainPreCache = () => {
    hasMainVersionPreCache().then((hasCache) => {
        if(!hasCache){
            unregisterMainSW();
        }
    });
}
export const clearAppPreCache = (keepVersion) => {
    if (caches) {
        caches.keys().then(function(names) {
            for (let name of names){
                if(name.indexOf("workbox-precache") !== -1){
                    let versionNumber = name.split("/").find(el => {
                        return isSemanticVersionNumber(el);
                    });

                    if(typeof versionNumber !== "undefined"){
                        if(versionNumber !== keepVersion){
                            caches.delete(name);
                        }
                    }else{
                        caches.open(name).then((cache) => {
                            cache.matchAll("/manifest.json").then((responses) => {
                                if(responses.length === 0){
                                    navigator.serviceWorker.getRegistrations().then(registrationsArray => {
                                        for (let registration of registrationsArray){
                                            if(typeof registration.scope.split("/").find(el => {
                                                if(isSemanticVersionNumber(el)){
                                                    return  el !== keepVersion;
                                                }
                                                return false;
                                            }) === "undefined"){
                                                caches.delete(name).then(() => {
                                                    registration.unregister();
                                                });
                                            }
                                        }
                                    });
                                }
                            });
                        });
                    }
                }
            }
        });
    }
}

export function sFormat(template, data){
    return Object.keys(data).reduce((acc, key)=>acc.replaceAll(`\$\{${key}\}`, data[key]), template)
}

export function base64Encode(string){
    const bytes = new TextEncoder().encode(string);
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
}

export function getObjectValues(obj){
    return Object.keys(obj).reduce((acc,el) => {
        if(typeof obj[el] !== "function"){
            acc[el] = obj[el];
        }
        return acc;
    }, {});
}

export const isSemanticVersionNumber = (text) => {
    return text.match(/^([0-9]+)\.([0-9]+)\.([0-9]+)?(\.([0-9]))?$/g) !== null;
}

export const isCashierModeForced = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("cashierMode") === "true";
}
export const getAutologinMode = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get("autologinMode");
    if(mode === "full" || mode === "lite"){
        return mode;
    }
    return false;
}

export const getPaymentSumInDefaultCurrency = (sum, rate) => {
    if(sum === "") return 0;
    return new Decimal(sum).mul(new Decimal(rate)).toDecimalPlaces(window?.AppConf?.priceDecimals ?? 2).toNumber();
}

export const numberWithThousandsSeparators = (number, separator) => {
    let parts = number.toString().split(".");
    let decimalPart = parts?.[1] ?? "";
    if(decimalPart !== ""){
        decimalPart = "." + decimalPart;
    }
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator) + decimalPart;
}