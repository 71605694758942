import React, {Component} from 'react';
import "../main.css"
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import {connect} from "react-redux";
import {
    addErrorAlert,
    addProduct,
    findProduct,
    productNotFound, setLoading,
    showDialogue,
    showInputDialogue
} from "../../redux/actions";
import Keyboard from "../../keyboard/Keyboard";
import Header from "./components/Header";


const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        warehouseID: state.rootReducer.warehouse.warehouseID,
        pointOfSaleID: state.rootReducer.posID,
        translate: getTranslate(state),
        theme: state.rootReducer.theme
    }
};

const mapDispatchToProps = dispatch => {
    return {
        findProduct: (code, amount, warehouseID, pointOfSaleID, translate, close, clearInputs) => {
            if(code.trim() === ''){
                dispatch(addErrorAlert('Enter the item code'));
                return;
            }
            let searchCodeType = process.env.REACT_APP_ERPLY_MODE === "1" ? 'searchNameIncrementally' : 'code';
            dispatch(setLoading(true));
            dispatch(findProduct(code, warehouseID, pointOfSaleID, false, function (product) {
                product.amount = amount;
                if(Math.abs(product.amount) === 1 && product.needsSerialNumber()){
                    return showInputDialogue('Insert serial number!', '', '', function (serialNumber) {
                        product.data.RFIDSerialNumber = serialNumber;
                        dispatch(addProduct(product));
                        clearInputs();
                    }, undefined, false, true);
                }else{
                    clearInputs();
                    return addProduct(product);
                }
            }, function (code, error) {
                return showDialogue(translate('Could not find product with code') + ' ' + code, translate('Do you want to add unknown item') + '?', 'Yes', function () {
                    dispatch(productNotFound(code, error));
                    setTimeout(close, 1);
                }, function () {},'No');
            }, undefined, () => {
                dispatch(setLoading(false));
            }, searchCodeType));
        }
    }
};

class AddItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyboardLayout: "numbers",
            selectedUser: false,
            errorMessage: false,
            activeInput: "itemCode",
            inputValues: {
                itemCode: "",
                itemAmount: 1
            }
        };

        this.findItem = this.findItem.bind(this);
    }

    componentDidMount() {

    }

    findItem() {
        console.log('findItem inputValues', this.state.inputValues);
        this.props.findProduct(
            this.state.inputValues.itemCode,
            this.state.inputValues.itemAmount,
            this.props.warehouseID,
            this.props.pointOfSaleID,
            this.props.translate,
            this.props.close,
            () => {
                this.setState({
                    inputValues: {
                        itemCode: "",
                        itemAmount: 1
                    }
                });
            }
        );
    }

    render() {
        let content;
        if(this.props.theme === 'Erply'){
            content = <div>
                <Header title={"Adding an item"}/>
                <div style={{
                    margin: '15% auto 0px',
                    width: '400px'
                }}>
                    <label style={{
                        fontSize: '16px',
                        color: '#808080',
                        marginLeft: '15px'
                    }}>
                        <Translate id={"Enter the item code"}/>
                    </label>
                    <input
                        type="text"
                        name={'itemCode'}
                        className={'underlineInput'}
                        autoComplete={'off'}
                        value={this.state.inputValues.itemCode}
                        onClick={() => {
                            this.setState({
                                activeInput: "itemCode"
                            })
                        }}
                        onChange={(e) => {
                            console.log(this.state, e);
                            this.setState({
                                inputValues: {
                                    ...this.state.inputValues,
                                    itemCode: e.target.value
                                }
                            });
                        }}
                    />
                </div>
            </div>;
        }else{
            content = <div>
                <h1 className={"bigUpperCaseHeading"}><Translate id={"Adding an item"}/></h1>
                <label>
                    <Translate id={"Item code"}/>
                </label>
                <br/>
                <input
                    type="text"
                    name={'itemCode'}
                    className={'underlineInput'}
                    autoComplete={'off'}
                    value={this.state.inputValues.itemCode}
                    onClick={() => {
                        this.setState({
                            activeInput: "itemCode"
                        })
                    }}
                    onChange={(e) => {
                        console.log(this.state, e);
                        this.setState({
                            inputValues: {
                                ...this.state.inputValues,
                                itemCode: e.target.value
                            }
                        });
                    }}
                />
                <label>
                    <Translate id={"Quantity"}/>
                </label>
                <input
                    type="text"
                    name={'itemAmount'}
                    className={'underlineInput'}
                    autoComplete={'off'}
                    value={this.state.inputValues.itemAmount}
                    onClick={() => {
                       this.setState({
                           activeInput: "itemAmount"
                       })
                    }}
                    onChange={(e) => {
                        this.setState({
                            inputValues: {
                                ...this.state.inputValues,
                                itemAmount: e.target.value
                            }
                        });
                    }}
                />
            </div>;
        }
        return (
            <div>
                {content}
                <div style={{
                    "position": "absolute",
                    "bottom": "0",
                    "left": "0",
                    "right": "0"
                }}>
                    <Keyboard options={{
                        layout: this.state.keyboardLayout,
                        currentValue: this.state.inputValues[this.state.activeInput],
                        updateValue: (value, key, currentValue) => {
                            console.log("Add item, updateValue", {value, key, currentValue}, this.state.inputValues);
                            this.setState({
                                inputValues: {
                                    ...this.state.inputValues,
                                    [this.state.activeInput]: value
                                }
                            });
                        },
                        onChangeToLetters: () => {this.setState({keyboardLayout: "letters"})},
                        onChangeToNumbers: () => {this.setState({keyboardLayout: "numbers"})},
                        onDone: this.findItem
                    }}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddItem);
