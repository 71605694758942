import React from "react";
import {useSelector} from "react-redux";
import addProductImg from "../main/images/pictureAddArticleRFID.png";
import addProductPurpleImg from "../main/images/DecathlonPurple/basket.png";
import addProductAnimationImg from "../main/images/pictureAddArticleRFIDAnimation.gif";
import cashErplyWhiteImg from "../main/images/Erply/cashWhite.png";
import cashWhiteImgPurple from "../main/images/DecathlonPurple/cashWhite.png";
import cashImg from "../main/images/pictureCashWhite.png";
import bankCardErplyImg from "../main/images/Erply/bankCard.png";
import otherImg from "../main/images/Erply/number.png";
import voucherImg from "../main/images/voucherPayment.png";
import otherPaymentImgPurple from "../main/images/DecathlonPurple/otherPayment.png";
import giftCardImg from "../icons/giftCardWhite.png";
import giftCardWhiteImgPurple from "../main/images/DecathlonPurple/giftCardWhite.png";
import giftCardErplyImg from "../main/images/Erply/giftCard.png";
import decathlonCardScanAnimation from "../main/images/decathlonCardScanAnimation.gif";
import scanDecathlonCardImg from "../main/images/scanDecathlonCardSmall.png";
import scanDecathlonCardImgPurple from "../main/images/DecathlonPurple/scanLoyaltyCard.png";
import paymentTerminalImg from "../main/images/insertCard.png";
import paymentTerminalImgPurple from "../main/images/DecathlonPurple/paymentTerminal.png";
import paymentTerminalImgErply from "../main/images/Erply/cardPayment.png";
import dialogueImg from "../main/images/thanks.png";
import dialogueImgPurple from "../main/images/DecathlonPurple/thankYou.png";
import mailImg from "../main/images/mail.png";
import mailImgPurple from "../main/images/DecathlonPurple/mail.png";
import printImg from "../main/images/print.png";
import printImgPurple from "../main/images/DecathlonPurple/printer.png";
import printConfImgPurple from "../main/images/DecathlonPurple/printerConf.png";
import iconArticleErply from "../main/images/Erply/bagWhite.png";
import iconArticle from "../main/images/iconArticle.png";
import iconArticlePurple from "../main/images/DecathlonPurple/search.png";
import iconUnknownArticle from "../main/images/unknownArticle.png";
import iconUnknownArticlePurple from "../main/images/DecathlonPurple/question.png";
import iconNotes from "../main/images/edit.png";
import iconNotesPurple from "../main/images/DecathlonPurple/edit.png";
import iconNotesErply from "../main/images/Erply/addNotes.png";
import iconSaveSale from "../main/images/iconSaveSale.png";
import iconSaveSalePurple from "../main/images/DecathlonPurple/save.png";
import iconSaveSaleErply from "../main/images/Erply/save.png";
import iconCashInOut from "../main/images/iconCash.png";
import iconCashInOutErply from "../main/images/Erply/cashWhiteSmaller.png";
import iconReturn from "../main/images/return.png";
import iconReturnPurple from "../main/images/DecathlonPurple/exchange_return.png";
import iconReturnErply from "../main/images/Erply/returnMode.png";
import iconRecentSales from "../main/images/iconRecentSales.png";
import iconRecentSalesPurple from "../main/images/DecathlonPurple/list-check.png";
import iconPromotionsPurple from "../main/images/DecathlonPurple/promotions.png";
import iconPromotions from "../main/images/iconEditPrices.png";
import iconRecentSalesErply from "../main/images/Erply/clock.png";
import iconPendingSales from "../main/images/iconPendingSales.png";
import iconPendingSalesPurple from "../main/images/DecathlonPurple/timer.png";
import iconVoucher from "../main/images/voucherPaymentBlue.png";
import iconVoucherPurple from "../main/images/DecathlonPurple/voucher.png";
import iconCardErply from "../main/images/Erply/bankCard.png";
import iconGiftCard from "../main/images/giftCardPresent.png";
import iconGiftCardPurple from "../main/images/DecathlonPurple/giftCard.png";
import iconGiftCardPayment from "../main/images/giftCard.png";
import iconGiftCardErply from "../main/images/Erply/giftCardBlack.png";
import iconCashDrawer from "../main/images/cashDrawer.png";
import iconCashDrawerPurple from "../main/images/DecathlonPurple/alert.png";
import iconCashDrawerErply from "../main/images/Erply/cashDrawer.png";
import iconCloseQCO from "../main/images/signOff.png";
import iconCloseQCOPurple from "../main/images/DecathlonPurple/lock.png";
import iconCloseQCOErply from "../main/images/Erply/signOutBlack.png";
import iconReport from "../main/images/iconReport.png";
import iconReportPurple from "../main/images/DecathlonPurple/bill.png";
import iconReportErply from "../main/images/Erply/document.png";
import iconCashierModePurple from "../main/images/DecathlonPurple/account.png";
import iconCloseDay from "../main/images/close.png";
import iconCloseDayPurple from "../main/images/DecathlonPurple/shut-down.png";
import iconCloseDayErply from "../main/images/Erply/signOutWhite.png";
import removeProductImg from "../main/images/removeProducts.png";
import removeProductImgPurple from "../main/images/DecathlonPurple/basketRemove.png";
import removeProductImgErply from "../main/images/Erply/removeProducts.png";
import membershipImg from "../main/images/membership.png";
import membershipImgPurple from "../main/images/DecathlonPurple/loyalty.png";
import membershipImgErply from "../icons/Erply/teammate.png";
import scanGiftCardImg from "../main/images/scanGiftCard.png";
import scanGiftCardImgPurple from "../main/images/DecathlonPurple/scanGiftCard.png";
import scanGiftCardImgErply from "../main/images/Erply/giftCardBlack.png";
import iconCash from "../main/images/pictureCash.png";
import iconCashPurple from "../main/images/DecathlonPurple/cash.png";
import iconCashErply from "../main/images/Erply/cash.png";
import iconCard from "../main/images/iconBankCardBlue.png";
import iconCardPurple from "../main/images/DecathlonPurple/bankCardPurple.png";
import iconCardWhite from "../main/images/iconBankCard.png";
import iconCardWhitePurple from "../main/images/DecathlonPurple/bankCardWhite.png";
import iconDefaultPayment from "../main/images/voucherPaymentBlue.png";
import iconDefaultPaymentPurple from "../main/images/DecathlonPurple/otherPaymentPurple.png";
import iconDefaultPaymentErply from "../main/images/Erply/number.png";
import offlineModeImg from "../login/images/offlineMode.png";
import cashierModeImg from '../login/images/cashierMode.png';
import liteModeImg from '../login/images/liteMode.png';
import fullModeImg from '../login/images/fullMode.png';
import fullModeImgPurple from '../main/images/DecathlonPurple/fullMode.png';
import userImg from "../icons/user.png";


const imageMap = () => {
    return {
        scanGiftCard: {
            erply: scanGiftCardImgErply,
            decathlon_blue: scanGiftCardImg,
            decathlon_purple: scanGiftCardImgPurple
        },
        membership: {
            erply: membershipImgErply,
            decathlon_blue: membershipImg,
            decathlon_purple: membershipImgPurple
        },
        removeProducts: {
            erply: removeProductImgErply,
            decathlon_blue: removeProductImg,
            decathlon_purple: removeProductImgPurple
        },
        iconCloseDay: {
            erply: iconCloseDayErply,
            decathlon_blue: iconCloseDay,
            decathlon_purple: iconCloseDayPurple
        },
        iconOfflineMode: {
            erply: offlineModeImg,
            decathlon_blue: offlineModeImg,
            decathlon_purple: offlineModeImg
        },
        iconCashierMode: {
            erply: cashierModeImg,
            decathlon_blue: cashierModeImg,
            decathlon_purple: iconCashierModePurple
        },
        iconFullMode: {
            erply: fullModeImg,
            decathlon_blue: fullModeImg,
            decathlon_purple: fullModeImgPurple
        },
        iconLiteMode: {
            erply: liteModeImg,
            decathlon_blue: liteModeImg,
            decathlon_purple: paymentTerminalImgPurple
        },
        iconReport: {
            erply: iconReportErply,
            decathlon_blue: iconReport,
            decathlon_purple: iconReportPurple
        },
        iconCloseQCO: {
            erply: iconCloseQCOErply,
            decathlon_blue: iconCloseQCO,
            decathlon_purple: iconCloseQCOPurple
        },
        iconCashDrawer: {
            erply: iconCashDrawerErply,
            decathlon_blue: iconCashDrawer,
            decathlon_purple: iconCashDrawerPurple
        },
        iconGiftCard: {
            erply: iconGiftCardErply,
            decathlon_blue: iconGiftCard,
            decathlon_purple: iconGiftCardPurple
        },
        iconGiftCardPayment: {
            erply: iconGiftCardErply,
            decathlon_blue: iconGiftCardPayment,
            decathlon_purple: iconGiftCardPurple
        },
        iconVoucher: {
            erply: iconCardErply,
            decathlon_blue: iconVoucher,
            decathlon_purple: iconVoucherPurple
        },
        iconCash: {
            erply: iconCashErply,
            decathlon_blue: iconCash,
            decathlon_purple: iconCashPurple
        },
        iconDefaultPayment: {
            erply: iconDefaultPaymentErply,
            decathlon_blue: iconDefaultPayment,
            decathlon_purple: iconDefaultPaymentPurple
        },
        iconCard: {
            erply: iconCardErply,
            decathlon_blue: iconCard,
            decathlon_purple: iconCardPurple
        },
        iconCardWhite: {
            erply: iconCardErply,
            decathlon_blue: iconCardWhite,
            decathlon_purple: iconCardWhitePurple
        },
        iconPendingSales: {
            erply: iconRecentSalesErply,
            decathlon_blue: iconPendingSales,
            decathlon_purple: iconPendingSalesPurple
        },
        iconRecentSales: {
            erply: iconRecentSalesErply,
            decathlon_blue: iconRecentSales,
            decathlon_purple: iconRecentSalesPurple
        },
        iconPromotions: {
            erply: iconCashErply,
            decathlon_blue: iconPromotions,
            decathlon_purple: iconPromotionsPurple
        },
        iconReturn: {
            erply: iconReturnErply,
            decathlon_blue: iconReturn,
            decathlon_purple: iconReturnPurple
        },
        iconCashInOut: {
            erply: iconCashInOutErply,
            decathlon_blue: iconCashInOut,
            decathlon_purple: iconCashPurple
        },
        iconSaveSale: {
            erply: iconSaveSaleErply,
            decathlon_blue: iconSaveSale,
            decathlon_purple: iconSaveSalePurple
        },
        iconNotes: {
            erply: iconNotesErply,
            decathlon_blue: iconNotes,
            decathlon_purple: iconNotesPurple
        },
        iconUnknownArticle: {
            erply: iconArticleErply,
            decathlon_blue: iconUnknownArticle,
            decathlon_purple: iconUnknownArticlePurple
        },
        iconArticle: {
            erply: iconArticleErply,
            decathlon_blue: iconArticle,
            decathlon_purple: iconArticlePurple
        },
        addProductImg: {
            erply: addProductImg,
            decathlon_blue: window.AppConf.showHangerGif ? addProductAnimationImg : addProductImg,
            decathlon_purple: addProductPurpleImg
        },
        cashPaymentImage: {
            erply: cashErplyWhiteImg,
            decathlon_blue: cashImg,
            decathlon_purple: cashWhiteImgPurple
        },
        bankCardImage: {
            erply: bankCardErplyImg,
            decathlon_blue: iconCardWhite,
            decathlon_purple: iconCardWhitePurple
        },
        otherPaymentImage: {
            erply: otherImg,
            decathlon_blue: voucherImg,
            decathlon_purple: otherPaymentImgPurple
        },
        voucherPaymentImg: {
            erply: otherImg,
            decathlon_blue: voucherImg,
            decathlon_purple: otherPaymentImgPurple
        },
        giftCardWhiteImage: {
            erply: giftCardErplyImg,
            decathlon_blue: giftCardImg,
            decathlon_purple: giftCardWhiteImgPurple
        },
        scanLoyaltyCard: {
            decathlon_blue: window.AppConf.scanDecathlonCardGif ? decathlonCardScanAnimation : scanDecathlonCardImg,
            decathlon_purple: scanDecathlonCardImgPurple
        },
        paymentTerminal: {
            erply: paymentTerminalImgErply,
            decathlon_blue: paymentTerminalImg,
            decathlon_purple: paymentTerminalImgPurple
        },
        dialogue: {
            decathlon_blue: dialogueImg,
            decathlon_purple: dialogueImgPurple
        },
        print: {
            decathlon_blue: printImg,
            decathlon_purple: printImgPurple
        },
        printConf: {
            decathlon_blue: printImg,
            decathlon_purple: printConfImgPurple
        },
        email: {
            decathlon_blue: mailImg,
            decathlon_purple: mailImgPurple
        },
        user: {
            erply: membershipImgErply,
            decathlon_blue: userImg,
            decathlon_purple: iconCashierModePurple
        }
    }
}

export default function ({image, style, alt, asBackground=false}) {
    const theme = useSelector(state => state.rootReducer.theme);
    const decathlonTheme = useSelector(state => state.rootReducer.decathlonTheme);
    const key = theme === "Erply" ? "erply" : "decathlon_" + decathlonTheme;
    const source = imageMap()?.[image]?.[key] ?? image;
    if(asBackground){
        return <div style={{
            ...style,
            backgroundImage: `url(${source})`,
            width: "100%",
            height: "100%",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
        }}/>
    }
    return <img src={source} style={style} alt={alt}/>
}