import React, {Component, Fragment} from 'react';
import './screenSaver.css';
import backgroundImg from "./BG.png";
import Translate from "localization/Translate";
import {connect} from "react-redux";
import {setScreenSaverNextImage} from "../../redux/actions";
import arrowImg from "../../main/images/DecathlonPurple/arrow.png";
import Image from "../../theme/Image";
import GiftCardButton from "../../main/components/GiftCardButton";

const mapStateToProps = state => {
    let screenSaverImageSwap = window.AppConf?.screenSaverImageSwap ?? {
        time: "0",
        transaction: false
    }

    return {
        erplyConf: state.rootReducer.erplyConf,
        uiCustomization: state.rootReducer.uiCustomization,
        activeLanguage: state.localize.activeLocale,
        cafaConfSet: state.rootReducer.cafaConfSet,
        operationMode: state.rootReducer.operationMode,
        screenSaver: state.rootReducer.screenSaver,
        currentImage: state.rootReducer.screenSaver === false ? false : state.rootReducer.screenSaver.images[state.rootReducer.screenSaver.current],
        screenSaverImageSwap
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setScreenSaverNextImage: () => dispatch(setScreenSaverNextImage())
    }
};

class ScreenSaver extends Component{
    constructor(props) {
        super(props);
        this.state = {
            screenSaverSwapInterval: false
        }
        this.startImageSwap.bind(this);
    }
    startImageSwap() {
        if(this.state.screenSaverSwapInterval === false){
            if(this.props.screenSaver !== false){
                if(this.props.screenSaverImageSwap.time !== "0"){
                    let screenSaverSwapInterval = setInterval(() => {
                        this.props.setScreenSaverNextImage();
                    }, parseInt(this.props.screenSaverImageSwap.time) * 1000);
                    this.setState({
                        screenSaverSwapInterval
                    });
                }
            }
        }
    }
    componentDidMount() {
        this.startImageSwap();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.startImageSwap();
    }

    componentWillUnmount() {
        if(this.state.screenSaverSwapInterval !== false){
            console.log("clear screenSaverSwapInterval");
            clearInterval(this.state.screenSaverSwapInterval);
        }
    }

    render() {
        const paymentImages = [
            "iconCard",
            "giftCardText"
        ]

        if(this.props.operationMode === 'full'){
            paymentImages.push("iconCash");
        }

        const Separator = () => <div style={{
            display: 'inline-block',
            height: 50,
            borderRight: "1px solid #d4d4d4"
        }}/>

        return (
            <div style={{
                backgroundColor: "#fff",
                display: 'flex',
                flexDirection: 'column'
            }} className={"touchToStartWrapper"}>
                <div style={{
                    top: 0,
                    width: '100%',
                    height: '50%',
                    backgroundImage: `url(${this.props.currentImage !== false ? this.props.currentImage.url : backgroundImg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}>
                    <div style={{
                        position: 'absolute',
                        right: 40,
                        top: 40,
                        fontSize: 45,
                        color: this.props.currentImage !== false ? this.props.currentImage.textColor : '#fff',
                        fontFamily: 'RobotoCondensed-Italic',
                        textShadow: '0px 5px 1px rgba(0,0,0,0.1)'
                    }}>
                        <Translate id={"Welcome"}/>
                    </div>
                </div>
                <div style={{
                    fontFamily: 'RobotoCondensed-BoldItalic',
                    marginTop: 40,
                    textTransform: 'uppercase',
                    color: '#15195A',
                    fontSize: 50,
                    textAlign: 'center',
                    flexGrow: 1
                }}>
                    <span style={{
                        width: '100%',
                        display: 'block'
                    }}>
                        <Translate id={"drop off your items"}/>
                    </span>
                    <span>
                        <Translate id={"in the basket"}/>
                    </span>
                    <img src={arrowImg} alt={"downArrow"} style={{
                        display: "block",
                        margin: "15px auto 0"
                    }}/>
                </div>
                <div style={{
                    borderTop: '1px solid #d4d4d4',
                    height: '75px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <div style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        textAlign: 'right',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <div style={{
                            display: 'inline-block',
                            flexGrow: 1
                        }}/>
                        <span><Translate id={"Payment methods"}/>:</span>
                        {
                            paymentImages.map((el, index) => {
                                if(el === "giftCardText"){
                                    return <Fragment key={index}><GiftCardButton title={"giftCardScreensaver"} style={{scale: '0.8'}}/><Separator/></Fragment>;
                                }
                                return (
                                    <Fragment key={index}>
                                        <Image image={el} alt={el} style={{
                                            height: 30,
                                            padding: "0 40px"
                                        }}/>
                                        <Separator/>
                                    </Fragment>
                                )
                            })
                        }
                        <div style={{
                            display: 'inline-block',
                            width: 100
                        }}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSaver);