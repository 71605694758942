import React, {Component, Fragment} from 'react';
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import MultipleInputContent from "../../modal/MultipleInputContent";
import giftCardPresentImg from "../../main/images/giftCardPresent.png";
import Button from "../../buttons/Button";
import {connect} from "react-redux";
import {checkGiftCardAvailable} from "../../redux/payment/actions";
import {
    addErrorAlert,
    addProduct,
    changeMode,
    closeRightPanelDialogue,
    findProduct,
    openGetUserRightsByPinModal,
    openLogonModal,
    setLastScannedGiftCard,
    setLoading,
    setTransactionUser
} from "../../redux/actions";
import {APP_MODE_SCAN} from "../../redux/appConstants";
import RGFD from "../../redux/payment/integrations/giftCard/main";
import Image from "../../theme/Image";

const mapStateToProps = state => {
    let giftCardActivationSupervisorLimit = state.rootReducer.erplyConf?.DKTGiftCardActivationSupervisorLimit ?? '';
    if(giftCardActivationSupervisorLimit !== ''){
        giftCardActivationSupervisorLimit = Number(giftCardActivationSupervisorLimit);
        if(isNaN(giftCardActivationSupervisorLimit)){
            giftCardActivationSupervisorLimit = "";
        }
    }

    return {
        warehouseID: state.rootReducer.warehouse.warehouseID,
        posID: state.rootReducer.pos.pointOfSaleID,
        currency: state.rootReducer.currency,
        lastScannedGiftCard: state.rootReducer.lastScannedGiftCard,
        translate: getTranslate(state),
        giftCardActivationSupervisorLimit,
        clientCode: state.rootReducer.user.clientCode,
        decathlonTheme: state.rootReducer.decathlonTheme
    }
};

const closeView = (dispatch) => {
    dispatch(changeMode(APP_MODE_SCAN));
    dispatch(setLastScannedGiftCard(false));
    dispatch(closeRightPanelDialogue());
};

const mapDispatchToProps = dispatch => {
    return {
        closeView: () => {
            closeView(dispatch);
        },
        checkGiftCard: (cardNumber, onSuccess) => {
            if(typeof cardNumber === "undefined" || cardNumber === ''){
                dispatch(addErrorAlert('Please enter number'));
                return false;
            }

            if(cardNumber.length > 16){
                dispatch(setLastScannedGiftCard(cardNumber));
                return;
            }

            dispatch(setLoading(true));
            dispatch(checkGiftCardAvailable(cardNumber,(canUse, message) => {
                if(canUse){
                    onSuccess(message);
                    dispatch(setLoading(false));
                }else{
                    dispatch(setLoading(false));
                    dispatch(addErrorAlert(message));
                }
            }, (errorMessage) => {
                dispatch(setLoading(false));
                dispatch(addErrorAlert(errorMessage));
            }));
        },
        addGiftCardProduct: (cardNumber, amount, minimumAmount, maximumAmount, warehouseID, posID, articleCode, giftCardActivationSupervisorLimit, clientCode) => {
            if(isNaN(parseFloat(amount)) || parseFloat(amount) < parseFloat(minimumAmount) || parseFloat(amount) > parseFloat(maximumAmount)){
                dispatch(addErrorAlert('please insert correct amount'));
                return false;
            }

            let addGiftCardProduct = () => {
                let searchCode = articleCode !== false ? articleCode : window.AppConf.giftCardItemCode;
                dispatch(setLoading(true));
                dispatch(findProduct(searchCode, warehouseID, posID, amount, (giftCardProduct) => {
                    closeView(dispatch);

                    giftCardProduct.giftCardCode = cardNumber;
                    giftCardProduct.data.RFIDSerialNumber = cardNumber;
                    giftCardProduct.name = giftCardProduct.name + ' ' + cardNumber.substring(0, cardNumber.length -4) + '----';
                    return addProduct(giftCardProduct);
                }, undefined, undefined, () => {
                    dispatch(setLoading(false));
                }));
            }

            if(giftCardActivationSupervisorLimit !== '' && giftCardActivationSupervisorLimit < Number(amount)){
                dispatch(openGetUserRightsByPinModal(dispatch, clientCode, posID, ((userRights, supervisor) => {
                    if(userRights.rightMakeDiscountInPOS === 1){
                        addGiftCardProduct();
                    }else{
                        dispatch(addErrorAlert('User has no right to perform this action!'));
                    }
                })))
            }else{
                addGiftCardProduct();
            }

        },
        logonForManualEntry: (onDone) => {
            dispatch(openLogonModal('enable manual gift card input', (data) => {
                onDone(data);
                dispatch(setTransactionUser(data[0]));
            }));
        }
    }
};

class GiftCardActivation extends Component{
    constructor(props) {
        super(props);
        if(typeof props.giftCard !== "undefined"){
            this.state = {
                inputValues: {
                    cardNumber: props.giftCard.number,
                    amount: props.giftCard.amount
                },
                manualEntryAllowed: false,
                giftCard: props.giftCard
            };
            props.checkGiftCard(this.state.inputValues.cardNumber, (result) => {
                if(this._ismounted){
                    this.setState({
                        minimumAmount: result.minimumAmount,
                        maximumAmount: result.maximumAmount
                    });
                }
            });
        }else{
            this.state = {
                inputValues: {},
                manualEntryAllowed: false,
                giftCard: false
            };
        }
    }

    componentDidMount() {
        this._ismounted = true;
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.lastScannedGiftCard !== false && prevProps.lastScannedGiftCard !== this.props.lastScannedGiftCard){
            let number = this.props.lastScannedGiftCard;
            let amount = '';
            if (RGFD.isGiftCard(number)) {
                let giftCard = RGFD.decodeBarcode(number);
                number = giftCard.number;
                amount = giftCard.amount;
                this.setState({
                    giftCard
                });
            }else{
                this.setState({
                    giftCard: false
                });
            }

            this.setState({
                inputValues: {},
                manualEntryAllowed: false
            });

            this.props.checkGiftCard(number, (result) => {
                this.setState({
                    inputValues: {
                        amount: amount,
                        cardNumber: number
                    },
                    minimumAmount: result.minimumAmount,
                    maximumAmount: result.maximumAmount
                });
            });
        }
    }

    render(){
        const labelStyle = {
            textTransform: 'uppercase',
            color: 'var(--coloredText)',
            fontWeight: 'bold',
            fontSize: '20px',
            marginLeft: '20px'
        };

        const NumberCircle = ({number}) => {
            return (
                <span
                    style={{
                        width: '24px',
                        height: '24px',
                        fontSize: '20px'
                    }}
                    className={"blueCircleBackgroundSmall"}
                >{number}</span>);
        };

        const AmountLabel = () => {
            return (
                <div>
                    <NumberCircle number={3}/>
                    <span style={labelStyle}>
                    <Translate id={'type the amount'}/>
                </span>
                    <br/>
                    <span style={{
                        color: 'var(--coloredText)',
                        marginLeft: '64px',
                        fontSize: '18px',
                        fontWeight: 'bold'
                    }}>
                    <span style={{textTransform: 'capitalize'}}>
                        <Translate id={'from'}/>
                    </span>&nbsp;
                        {this.state.minimumAmount}{this.props.currency}&nbsp;
                        <Translate id={'to'}/>&nbsp;
                        {this.state.maximumAmount}{this.props.currency}
                </span>
                </div>
            );
        };


        let inputFields;
        if(typeof this.state.minimumAmount === "undefined"){
            if(this.state.manualEntryAllowed){
                inputFields = [
                    { id: 'cardNumber', name: 'scan or type the card number', readOnly: false, labelContent: <NumberCircle number={2}/>, active: true}
                ];
            }else{
                inputFields = [];
            }
        }else{
            inputFields = [
                { id: 'cardNumber', name: 'card number', readOnly: true, labelContent: <NumberCircle number={2}/>},
                { id: 'amount', labelContent: <AmountLabel/>, active: true, isNumber: true}
            ];
        }

        let beforeInputFields = (
            <div style={{
                marginTop: '-40px',
                marginBottom: '60px'
            }}>
                <NumberCircle number={1}/>
                <span style={labelStyle}>
                    <Translate id={"choose a"}/>
                </span>
                <div style={{
                    position:'relative',
                    display:'initial'
                }}>
                    {
                        this.props.decathlonTheme === 'purple' ?
                            <Fragment>
                                <Image image={"iconGiftCard"} alt={"Gift card"} style={{
                                    position: 'relative',
                                    top: '50px',
                                    left: '20px',
                                    height: '80px'
                                }}/>
                            </Fragment>
                            :
                            <Fragment>
                                <img
                                    style={{
                                        position: 'relative',
                                        top: '50px',
                                        left: '30px',
                                        height: '100px'
                                    }}
                                    src={giftCardPresentImg}
                                    alt={'gift card'}
                                />
                                <span style={{
                                    position: 'absolute',
                                    left: '-10px',
                                    bottom: '-37px',
                                    backgroundColor: '#f26a39',
                                    padding: '2px',
                                    transform: 'rotate(-10deg)',
                                    color: 'white',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    width: '180px',
                                    textAlign: 'center'
                                }}>
                                    <Translate id={"giftCard"}/>
                                </span>
                            </Fragment>
                    }
                </div>
                <br/>
            </div>
        );

        let validateGiftCard = () => {
            let articleCode = this.state.giftCard !== false ? this.state.giftCard.articleCode : false;
            if(typeof this.state.minimumAmount === "undefined"){
                this.props.checkGiftCard(this.state.inputValues.cardNumber, (result) => {
                    this.setState({
                        minimumAmount: result.minimumAmount,
                        maximumAmount: result.maximumAmount
                    });
                });
            }else{
                this.props.addGiftCardProduct(
                    this.state.inputValues.cardNumber,
                    this.state.inputValues.amount,
                    this.state.minimumAmount,
                    this.state.maximumAmount,
                    this.props.warehouseID,
                    this.props.posID,
                    articleCode,
                    this.props.giftCardActivationSupervisorLimit,
                    this.props.clientCode
                )
            }
        };
        let afterInputFields = (
            <Button
                onClick={validateGiftCard}
                type="button"
                className={"largeButton yellowButton"}
                name={<Translate id="validate"/>}
                arrow="right"
                flencheClass={"flencheSmallRightBottom yellowFlenche"}
                style={{
                    display: 'block',
                    textAlign: 'center'
                }}
            />
        );

        let content;
        if(inputFields.length > 0){
            content = (
                <MultipleInputContent
                    inputFields={inputFields}
                    onChange={(newState) => {
                        this.setState({
                            inputValues: Object.assign({}, this.state.inputValues, newState)
                        });
                    }}
                    onDone={validateGiftCard}
                    initialValues={this.state.inputValues}
                    beforeInputFields={ beforeInputFields }
                    afterInputFields={ afterInputFields }
                    initialKeyboardLayout={'numbers'}
                    labelStyle={labelStyle}
                    inputClassName={'borderInput fullWidth'}
                    inputStyle={{
                        marginTop: '20px',
                        marginBottom: '30px'
                    }}
                />
            );
        }else{
            content = (
                <div className={'voucherInputs'}>
                    { beforeInputFields }
                    <NumberCircle number={2}/>
                    <span style={{
                        ...labelStyle,
                        fontSize: '36px',
                        textAlign: 'center',
                        right: 0,
                        left: 0,
                        position: 'absolute'
                    }}>
                        { this.props.translate('scan')}
                    </span>
                    <br/>
                    <span style={{
                        ...labelStyle,
                        textAlign: 'center',
                        right: 0,
                        left: 0,
                        position: 'absolute',
                        marginTop: '10px'
                    }}>
                        { this.props.translate('or')}
                    </span>
                    <br/>
                    <Button
                        type="button"
                        className={"largeButton blueButton"}
                        style={{
                            display: 'block',
                            marginTop: '20px',
                            textAlign: 'center'
                        }}
                        name={<Translate id="click here for manual input"/>}
                        flencheClass={"flencheSmallRightBottom blueFlenche"}
                        onClick={e => {
                            this.props.logonForManualEntry((data) => {
                                console.log('RGFD: logon data', data);
                                this.setState({
                                    manualEntryAllowed: true
                                })
                            });
                        }}
                        arrow={'right'}
                    />
                    <span style={{
                        display: 'block',
                        marginTop: '20px',
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }}>
                        { this.props.lastScannedGiftCard }
                    </span>
                </div>
            );
        }

        return (
            <div style={{
                fontSize: '20px'
            }}>
                <div
                    onClick={ e => {
                        this.props.closeView();
                    }}
                    className={"blueCircleBackground"}
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '12px',
                        zIndex: 1000
                    }}
                >
                    <i className="icoFont">&#xeee1;</i>
                </div>
                { content }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardActivation);
