import React, {Component, Fragment} from 'react';
import "./main.css"
import Translate from "localization/Translate";
import deleteIconErply from "./images/Erply/trash.png";
import Decimal from "decimal.js";
import {displayPrice, getAttributeValue, getProductPackageWeight, numberIsOdd} from "../util/helperFunctions";
import Trash from "../icons/Trash";
import {useSelector} from "react-redux";
import OP from "../campaigns/onePromotion/onePromotion";
import WarningCircle from "../buttons/WarningCircle";

export default function (props){
    let onePromotionPromoCode = useSelector(state => state.rootReducer.onePromotionPromoCode);

    let removeProduct = props.removeProduct !== false && !props.product._isContainer ? (
        props.theme === 'Erply' ?
            <td onClick={e => props.removeProduct(props.product)} style={{
                width: "64px",
                boxSizing: "border-box",
                borderLeft: '1px solid #E6E6E6'
            }}>
                <img src={deleteIconErply} alt={'trash'}/>
            </td> :
            <td onClick={e => props.removeProduct(props.product)} style={{
                width: "20px",
                paddingRight: "33px"
            }}>
                <Trash/>
            </td>
    ) : <td/>;

    let onePromotionExcluded;

    if(onePromotionPromoCode !== false){
        if(OP.productIsInExcludedCategory(props.product)){
            onePromotionExcluded = <WarningCircle message={"Promo code is not applicable for this item"}/>;
        }
    }

    let RFIDTag = props.product.data?.RFIDTag ?? props.product.data?.voucherRFIDTag;
    let RFIDSerialNumber = typeof props.product.data.RFIDSerialNumber !== "undefined" ?
        props.product.data.RFIDSerialNumber :
        typeof RFIDTag !== "undefined" && RFIDTag.SERIAL_NUMBER !== 'NULL_VALUE' ? RFIDTag.SERIAL_NUMBER : false;
    let rfidInfo = RFIDSerialNumber !== false && typeof props.product.giftCardCode === "undefined"?
        <span className={"unitPrice"}><br/><Translate id={"RFID"}/> : {RFIDSerialNumber}</span> : null;
    let packageWeight;
    let packageWeightValue = getProductPackageWeight(props.product.data);
    if(typeof packageWeightValue !== "undefined"){
        packageWeight = <span className={"unitPrice"}><br/><Translate id={'package weight'}/>: {packageWeightValue}{props.product.data.unitName}</span>;
    }
    let returnReason = '';
    if(props.product.returnReason !== undefined){
        returnReason = <span className={"unitPrice"}><br/>{ props.product.returnReason.name }</span>;
    }
    let discountInfo = '';
    if(props.product.hasManualDiscount()){
        discountInfo = (
            <span className={"unitPrice"}>
                <br/>
                <span className={"discountInfo"}>
                    <Translate id={'Disc'}/>: {parseFloat(props.product.manualDiscountPercentage).toFixed(2)}% {displayPrice(props.displayNetPrices ? props.product.getNetPrice(props.product.manualDiscountTotal, props.product.data.vatrate) : props.product.manualDiscountTotal)}{props.currency}
                </span>
            </span>);
    }
    let priceListPromotionLines = props.product.getAppliedPriceListRules();
    let campaignPromotionLines = props.product.getAppliedCampaignRules();

    let plusAmount;
    let minusAmount;

    if(process.env.REACT_APP_ERPLY_MODE === "1"){
        plusAmount = (
            <i
                onClick={e => props.editProductAmount(props.product, (new Decimal(props.product.amount)).plus(1).toDecimalPlaces(4).toNumber())}
                className={"icoFont"}
                style={{
                    fontSize: '16px',
                    marginLeft: '5px'
                }}>
                &#xefc2;
            </i>
        );
        minusAmount = (
            <i
                onClick={e => props.editProductAmount(props.product, (new Decimal(props.product.amount)).minus(1).toDecimalPlaces(4).toNumber())}
                className={"icoFont"}
                style={{
                    fontSize: '16px',
                    marginRight: '5px'
                }}>
                &#xef9a;
            </i>
        );
    }

    let productInfoButton;
    let productShortDescription;
    if(window.AppConf.productDescriptionFromAttributes){
        productShortDescription = getAttributeValue(props.product.data, 'shortDescriptionSCO');
    }else{
        productShortDescription = props.product.data.description;
    }
    if(typeof productShortDescription !== "undefined" && productShortDescription !== ""){
        productInfoButton = (
            <span
                style={{
                    fontSize: '28px',
                    cursor: 'pointer',
                    float: 'right',
                    lineHeight: 2
                }}
                onClick={ e => {
                    props.onInfoButtonClick(props.product);
                }}
            >
                <i className="icoFont">&#xef4e;</i>
            </span>
        );
    }

    let displayAmount;
    if(!props.product._isContainer){
        displayAmount = (
            <div>
                { minusAmount }
                <div
                    onClick={e => props.editProductAmount(props.product)}
                    style={{
                        display: 'inline'
                    }}
                    className={"grayBackground roundBackground"}>
                    {props.product.amount}
                </div>
                { plusAmount }
            </div>
        );
    }

    let unitPrice;
    if(process.env.REACT_APP_ERPLY_MODE === "1"){
        unitPrice = (
            <span className={"unitPriceErply"}>
                <Translate id={"unitPrice"}/> : {props.product.getDisplayUnitPrice(props.displayNetPrices)} {props.currency}
            </span>
        );
    }else{
        if(!props.product.isUsedProduct()){
            unitPrice = (
                <span className={"unitPrice"}>
                    <Translate id={"unitPrice"}/> : {props.product.getDisplayUnitPrice(props.displayNetPrices)} {props.currency}
                </span>
            );
        }
    }

    return (
        <tr className={`productLine ${props.product._isContainer ? 'productLineSmall' : ''} ${props.theme === 'Erply' && numberIsOdd(props.product.lineNumber) ? 'productLineOdd' : ''}`}>
            <td className={"productLineNameColumn"}>
                { productInfoButton }
                <span>{props.product.name}</span>
                { typeof props.product.nameExtraInfo !== "undefined" ?
                    <div dangerouslySetInnerHTML={{__html: props.product.nameExtraInfo}}/> : <br/>
                }
                <span className={"productCode"}>{props.product.data.code}</span>
                <br/>
                { unitPrice }
                { rfidInfo }
                { returnReason }
                { packageWeight }
            </td>
            <td>
                { displayAmount }
            </td>
            <td className={"productLinePriceColumn"} onClick={e => props.editProductPrice(props.product, props.currency)}>
                { onePromotionExcluded }
                {props.product.getDisplayTotalPrice(props.displayNetPrices)} {props.currency}
                { props.displayTax ? (
                    <Fragment>
                        <br/>
                        <span className={"unitPrice"}>
                            &nbsp;<Translate id={"Taxes"}/>: {displayPrice(props.product.rowVAT)} {props.currency}
                        </span>
                    </Fragment>
                ) : null}
                <br/>
                { discountInfo }
                { priceListPromotionLines.map((line, index) => {
                    return (
                        <span className={"unitPrice"} key={index}>
                            <br/>
                            { line.name } { line.totalDiscount }{props.currency}
                        </span>
                    );
                })}
                { campaignPromotionLines.map((line, index) => {
                    return (
                        <span className={"unitPrice"} key={index}>
                            <br/>
                            { line.name } { line.totalDiscount }{props.currency}
                        </span>
                    );
                })}
            </td>
            {removeProduct}
        </tr>
    );
}