import RFIDService from "./RFIDService";
import {
    ADD_PRODUCT,
    CANCEL_ADD_UNKNOWN_ITEM,
    CANCEL_TRANSACTION,
    CHANGE_MODE,
    CHANGE_TRANSACTION_MODE,
    CLOSE_QCO,
    CLOSE_RFID_READER,
    INCREMENT_NEXT_INVOICE_NO,
    INIT_POS,
    INIT_TRANSACTION,
    PRODUCT_NOT_FOUND,
    REMOVE_PRODUCT,
    SCANNER_DETECTED,
    SET_PENDING_DOCUMENT,
    SHOW_CALL_ASSISTANCE
} from "../actionTypes";
import {
    addProductRFIDTag,
    addUnknownRFIDProduct,
    removeProduct,
    removeUnknownRFIDProduct,
    showDialogue,
    updateProductName
} from "../actions";
import {
    APP_MODE_PAYMENT,
    APP_MODE_SCAN, APP_MODE_SCREEN_SAVER
} from "../appConstants";
import {cloneProduct, getFirstUnknownRFIDProduct, getUnknownItemCode} from "../selectors";
import {getTranslate} from "localization/localizeSlice";


const middleware = store => next => action => {
    next(action);

    if(process.env.REACT_APP_ERPLY_MODE === "1" || window.AppConf?.enableRFID !== true){
        if(action.type === CLOSE_RFID_READER){
            action.payload.callback();
        }
        return true;
    }

    const state = store.getState();
    const dispatch = store.dispatch;
    const translate = getTranslate(state);

    if (action.type === INCREMENT_NEXT_INVOICE_NO){
        if(state.rootReducer.mode === 'done'){
            RFIDService.log('INCREMENT_NEXT_INVOICE_NO ', action.payload.lastInvoiceNo);
            RFIDService.POSEvents.afterDocumentSave(action.payload.lastInvoiceNo);
        }
    } else if(action.type === CANCEL_TRANSACTION){
        const productsInBasket = state.rootReducer.productsInBasket.reduce(function (acc, product) {
            acc.push(product.data);
            return acc;
        }, []);
        RFIDService.POSEvents.afterDocumentCancelled(productsInBasket);
    } else if(action.type === PRODUCT_NOT_FOUND){
        let unknownProduct = RFIDService.POSEvents.afterProductSearchNotFound(action.payload.searchParam);
        if(unknownProduct !== false){
            dispatch(addUnknownRFIDProduct(unknownProduct));
        }
    } else if(action.type === SET_PENDING_DOCUMENT){
        let startRFIDTransaction = () => {
            if(RFIDService.documentCancelled){
                RFIDService.log("Waiting for cancel..");
                setTimeout(startRFIDTransaction, 1000);
                return;
            }
            RFIDService.startTransaction(false, action.payload, () => {
                RFIDService.openingDocument = false;
            });
        }
        RFIDService.openingDocument = true;

        if(RFIDService.status !== 'paused'){
            RFIDService.pauseTransaction(() => {
                startRFIDTransaction();
            });
        }else{
            startRFIDTransaction();
        }
    } else if(action.type === ADD_PRODUCT){
        const product = cloneProduct(state.rootReducer.productsInBasket[state.rootReducer.productsInBasket.length - 1]);

        if(RFIDService.POSEvents.productAddAllowed(product.data, (lineNumber, RFIDTag) => {
            dispatch(addProductRFIDTag(lineNumber, RFIDTag));
        }, (lineNumber, name) => {
            dispatch(updateProductName(lineNumber, name));
        })){
            RFIDService.POSEvents.addProductAfter(product, action.pendingDocument, (RFIDTag) => {
                dispatch(addProductRFIDTag(product.lineNumber, RFIDTag));
            }, (name) => {
                dispatch(updateProductName(product.lineNumber, name));
            });
        }else{
            dispatch(removeProduct(product));
        }
        if(product.data.code === getUnknownItemCode() || product.data.code2 === getUnknownItemCode()){
            let unknownRFIDProduct = getFirstUnknownRFIDProduct(state.rootReducer.unknownRFIDProducts);
            if(unknownRFIDProduct !== false){
                dispatch(removeUnknownRFIDProduct(unknownRFIDProduct));
            }
        }
    } else if(action.type === REMOVE_PRODUCT){
        const product = action.payload.data;
        RFIDService.POSEvents.beforeProductRemove(product);
    } else if(action.type === CHANGE_MODE){
        RFIDService.POSEvents.setPosMode(action.payload.mode);
        if( action.payload.mode === APP_MODE_PAYMENT){
            RFIDService.POSEvents.startPayment();
            if(RFIDService.hangerTags.length > 0){
                RFIDService.POSEvents.hangerMessage(store);
            }
            if(RFIDService.handleSecurityTagsAfterScanning){
                if(RFIDService.securityTags.length > 0){
                    dispatch(showDialogue(
                        translate('Hardtags detected', {number: RFIDService.securityTags.length}),
                        'Your items contain a security tag. After payment please go to the customer service desk to get it removed',
                        'Ok',
                        () => {
                            //RFIDService.cancelAllSecurityTagLines();
                        },
                        undefined,
                        undefined,
                        false,
                        undefined,
                        undefined,
                        true
                    ));
                }
            }
        }else if( action.payload.mode === APP_MODE_SCAN || action.payload.mode === APP_MODE_SCREEN_SAVER){
            if(!RFIDService.documentCancelled && !RFIDService.confirmingTransaction){
                RFIDService.startTransaction(true);
            }
        }
    } else if(action.type === CLOSE_QCO || action.type === INIT_POS || action.type === CLOSE_RFID_READER){
        RFIDService.POSEvents.beforeClosePOS(() => {
            if(action.type === CLOSE_RFID_READER){
                RFIDService.setUnavailable();
                RFIDService.serviceInit = true;
                action.payload.callback();
            }
        });
    } else if(action.type === CANCEL_ADD_UNKNOWN_ITEM){
        let unknownRFIDProduct = getFirstUnknownRFIDProduct(state.rootReducer.unknownRFIDProducts);
        if(unknownRFIDProduct !== false){
            dispatch(removeUnknownRFIDProduct(unknownRFIDProduct));
        }
        RFIDService.POSEvents.afterCancelAddUnknownItem(action.productCode);
    } else if(action.type === SCANNER_DETECTED){
        RFIDService.lastScanned.push(action.payload);
        console.log("RFIDService: lastScanned", RFIDService.lastScanned);
    } else if(action.type === CHANGE_TRANSACTION_MODE){
        RFIDService.POSEvents.changeTransactionMode(action.payload);
    } else if(action.type === INIT_TRANSACTION){
        RFIDService.POSEvents.changeTransactionMode('SALE');
    } else if(action.type === SHOW_CALL_ASSISTANCE){
        if(state.rootReducer.mode === APP_MODE_SCAN || state.rootReducer.mode === APP_MODE_SCREEN_SAVER){
            RFIDService.pauseTransaction(() => {

            });
        }
    } else if(
        (state.rootReducer.mode === APP_MODE_SCAN || state.rootReducer.mode === APP_MODE_SCREEN_SAVER) &&
        state.rootReducer.administration === false &&
        state.rootReducer.callAssistance.length === 0 &&
        RFIDService.status === 'paused' &&
        !RFIDService.confirmingTransaction &&
        !RFIDService.documentCancelled &&
        !RFIDService.openingDocument
    ){
        RFIDService.startTransaction(true);
    }
    /*else if(action.type === ""){
        RFIDService.POSEvents.afterCloseDay();
    }
    else if(action.type === ""){
        RFIDService.POSEvents.afterOpenDay();
    }
    else if(action.type === ""){
        RFIDService.POSEvents.beforeLogout();
    }*/
};

export default middleware;
