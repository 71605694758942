import React from "react";
import Button from "../buttons/Button";
import Translate from "localization/Translate";
import {useDispatch, useSelector} from "react-redux";
import {closeRightPanelDialogue} from "../redux/actions";

export default function (props) {
    let customer = useSelector(state => state.rootReducer.customer);
    let cashierMode = useSelector(state => state.rootReducer.cashierMode);
    if(customer !== false){
        const dispatch = useDispatch();
        dispatch(closeRightPanelDialogue());
    }
    let enterDetailsButton = (
        <div onClick={e => {
            props.enterDetails();
        }}>
            <Button type="button" className={"largeButton blueButton"} name={<Translate id="Enter details"/>}
                    arrow="right" flencheClass={"flencheSmallRightBottom blueFlenche"}/>
        </div>
    );
    let askForHelpButton = (
        <div onClick={e => {
            props.askForHelp();
        }}>
            <Button type="button" className={"largeButton grayButton"} name={<Translate id="Ask for assistance"/>}
                    arrow="right" flencheClass={"flencheSmallRightBottom grayFlenche"}/>
        </div>
    );
    let cancelButton = (
        <div onClick={e => {
            props.askForHelp(true);
        }}>
            <Button type="button" className={"largeButton grayButton negativeActionButton"} name={<Translate id="Cancel"/>}
                    arrow="right" flencheClass={"flencheSmallRightBottom grayFlenche"}/>
        </div>
    );

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
        }}>
            <div style={{flexGrow:1}}/>
            <div style={{
                textAlign: "center",
                padding: "30px",
                paddingTop: "50px"
            }}>
                <span className={"boldText"}>
                    <Translate id={cashierMode ? "traceabilityMessageCashier" : "traceabilityMessage"}/>
                </span>
                <div style={{
                    marginTop: "30px"
                }}>
                    { enterDetailsButton }
                    <div style={{
                        width: "280px",
                        margin: "50px auto"
                    }}>
                        <div className={"diagonalLine"} style={{
                            float: "left",
                            marginTop: "38px"
                        }}/>
                        <span style={{
                            textTransform: "uppercase",
                            fontStyle: "italic",
                            color: "var(--coloredText)",
                            fontSize: "20px"
                        }}>
                                <Translate id="or"/>
                            </span>
                        <div className={"diagonalLine"} style={{
                            float: "right",
                            marginTop: "10px"
                        }}/>
                    </div>
                    { cashierMode ? cancelButton : askForHelpButton }
                </div>
            </div>
        </div>
    );
}
