import React, {Component} from 'react';
import "../main.css";
import Translate from "localization/Translate";
import {getLanguages, getTranslate} from "localization/localizeSlice";
import {connect} from "react-redux";
import '@programmerraj/rc-color-picker/assets/index.css';
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../uiComponents/WrappedPerfectScrollBar';
import {savePrinterTimeoutPrintConfiguration} from "../../redux/actions";
import CheckBox from "../../formElements/CheckBox";
import {getDayName} from "../../util/calendar";

const mapStateToProps = state => {
    return {
        administration: state.rootReducer.administration,
        translate: getTranslate(state),
        languages: getLanguages(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveConf: (data) => {
            dispatch(savePrinterTimeoutPrintConfiguration(data));
            window.AppConf.printerTimeoutPrintDays = data;
        }
    }
};

class PrinterConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printDays: window.AppConf.printerTimeoutPrintDays
        };
    }

    render() {
        console.log({printDays: this.state.printDays});
        let allDaysChecked = typeof this.state.printDays.find(el => el === 0) === "undefined";
        let rows = this.state.printDays.map((print,dayNumber) => {
            let day = getDayName(dayNumber);
            return (
                <tr key={dayNumber}>
                    <td>
                        <CheckBox checked={print === 1} name={"printDay" + dayNumber} onChange={(checked) => {
                            let printDays = this.state.printDays.map((el, index) => {
                                if(index === dayNumber){
                                    return checked ? 1 : 0;
                                }else{
                                    return el;
                                }
                            });
                            this.setState({printDays});
                            this.props.saveConf(printDays);
                        }}/>
                    </td>
                    <td><Translate id={day}/></td>
                </tr>
            );
        });
        rows.push(rows.shift());

        return (
            <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            }}>
                <h1 className={"bigUpperCaseHeading"}><Translate id={"Receipt print configuration"}/></h1>
                <PerfectScrollbar style={{
                    position: "absolute",
                    left: 0,
                    right:0,
                    top: "66px",
                    height: "calc(100% - 66px)"
                }}>
                    <div style={{
                        padding: "20px",
                        fontWeight: "bold"
                    }}>
                        <table
                            className={"configurationTable"}
                            style={{
                                borderSpacing: "15px",
                                float: "left"
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <CheckBox checked={allDaysChecked} name={"printDayAll"} onChange={(checked) => {
                                            let printDays = this.state.printDays.map(() => checked ? 1 : 0);
                                            this.setState({printDays});
                                            this.props.saveConf(printDays);
                                        }}/>
                                    </td>
                                    <td><Translate id={"All days"}/></td>
                                </tr>
                                {rows}
                            </tbody>
                        </table>
                        <div
                            className={"coloredText"}
                            style={{
                                width: "220px",
                                position: "absolute",
                                right: "30px",
                                bottom: "30px"
                            }}
                        >
                            <h2><Translate id={"Note"}/></h2>
                            <CheckBox checked={true} onChange={() => {}}/>
                            <span style={{
                                float: "right",
                                width: "170px",
                                paddingTop: "30px"
                            }}>
                                <Translate id={"If selected, the receipt will be forced to print if screen times out without action from the customer"}/>
                            </span>
                        </div>
                    </div>
                </PerfectScrollbar>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrinterConfiguration);