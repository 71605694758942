import React, {Component} from 'react';
import "./main.css"
import Translate from "localization/Translate";
import {connect} from "react-redux";
import {closeDialogue} from "../redux/actions";
import DialogueView from "./DialogueView";
import Modal from "../modal/Modal";
import Keyboard from "../keyboard/Keyboard";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../uiComponents/WrappedPerfectScrollBar';
import MultipleInputContent from "../modal/MultipleInputContent";

const mapStateToProps = state => {
    return {
        dialogue: state.rootReducer.dialogues.length > 0 ? state.rootReducer.dialogues[state.rootReducer.dialogues.length - 1] : false,
        theme: state.rootReducer.theme
    }
};

const mapDispatchToProps = dispatch => {
    return {
        close: (id) => dispatch(closeDialogue(id))
    }
};

class Dialogue extends Component{
    constructor(props) {
        super(props);
        this.state = {
            keyboardLayout: "letters",
            keyboardLayoutSet: false,
            inputValue: ''
        };
    }

    componentDidUpdate() {
        console.log('DIALOGUE: did update', this.state, this.props.dialogue);
        if(this.props.dialogue === false){
            if(this.state.keyboardLayoutSet === true){
                this.setState({
                    keyboardLayout: "letters",
                    keyboardLayoutSet: false,
                    inputValue: ''
                });
            }else{
                if(this.state.inputValue !== ''){
                    this.setState({
                        inputValue: ''
                    });
                }
            }
            return null;
        }else if(this.props.dialogue.inputNumber === true && !this.state.keyboardLayoutSet && this.state.keyboardLayout !== 'numbers'){
            this.setState({
                keyboardLayout: "numbers",
                keyboardLayoutSet: true,
                inputValue: this.props.dialogue.initialInputValue
            });
        }else if(this.props.dialogue.keyboardLayout && !this.state.keyboardLayoutSet){
            this.setState({
                keyboardLayout: this.props.dialogue.keyboardLayout,
                keyboardLayoutSet: true,
                inputValue: this.props.dialogue.initialInputValue
            });
        }
    }

    render() {
        if(this.props.dialogue === false){
            return null;
        }
        let close = () => {
            this.props.close(this.props.dialogue.id);
        };

        if(this.props.dialogue.inputDialogue === true){
            let onConfirm = this.props.dialogue.onConfirm;
            let changeToLettersLayout = typeof this.props.dialogue.changeToLettersLayout !== "undefined" ? this.props.dialogue.changeToLettersLayout : 'letters';
            let changeToNumbersLayout = typeof this.props.dialogue.changeToNumbersLayout !== "undefined" ? this.props.dialogue.changeToNumbersLayout : 'numbers';
            let that = this;
            let content = (
                <div>
                    <div className={"dialogueTitle"}><Translate id={this.props.dialogue.title}/></div>
                    <div className={"dialogueMessage"}>
                        <Translate id={this.props.dialogue.message}/>
                    </div>
                    <label><Translate id={ this.props.dialogue.inputLabel }/></label><br/>
                    <input
                        type={this.props.dialogue?.inputType ?? "text"}
                        value={this.state.inputValue}
                        name={'dialogueInput'}
                        className={'underlineInput'}
                        autoComplete={'off'}
                        autoFocus={true}
                        onChange={(event) => {
                            let value = event.target.value;
                            if(this.props.dialogue.inputNumber){
                                value = value.replace(',', '.');
                                let lastChar = value.slice(-1);
                                if(lastChar === '.'){
                                    if(value.split('.').length > 2){
                                        value = value.slice(0,-1);
                                    }
                                }else if(isNaN(lastChar)){
                                    value = value.slice(0,-1);
                                }
                            }
                            that.setState({
                                inputValue: value
                            });
                        }}
                    />
                    <div style={{
                        "position": "absolute",
                        "bottom": "0",
                        "left": "0",
                        "right": "0"
                    }}>
                        <Keyboard options={{
                            layout: this.state.keyboardLayout,
                            currentValue: this.state.inputValue,
                            updateValue: (value, key) => {
                                if(this.props.dialogue.inputNumber){
                                    if(key !== 'delete' && key.length > 1){
                                        return false;
                                    }
                                    value = value.replace(',', '.');
                                    let lastChar = value.slice(-1);
                                    if(lastChar === '.'){
                                        if(value.split('.').length > 2){
                                            value = value.slice(0,-1);
                                        }
                                    }else if(isNaN(lastChar)){
                                        value = value.slice(0,-1);
                                    }
                                }
                                this.setState({
                                    inputValue: value
                                });
                            },
                            onChangeToLetters: () => {this.setState({keyboardLayout: changeToLettersLayout})},
                            onChangeToNumbers: () => {this.setState({keyboardLayout: changeToNumbersLayout})},
                            onDone: function () {
                                if(typeof onConfirm !== "undefined"){
                                    onConfirm(that.state.inputValue, close);
                                }
                                if(that.props.dialogue.closeOnDone !== false){
                                    close();
                                }
                            }
                        }}/>
                    </div>
                </div>
            );
            let onCancel = this.props.dialogue.onCancel;
            return (
                <Modal
                    className={"Administration " + (this.props.dialogue.className || '')}
                    content={content}
                    onClose={ e => {
                        close();
                        if(typeof onCancel !== "undefined"){
                            onCancel();
                        }
                    }}
                    canClose={this.props.dialogue.canClose}
                />
            )
        }
        if(this.props.dialogue.multipleInputDialogue === true){
            console.log('SHOW_DIALOGUE multipleInputDialogue');
            let content = (
                <MultipleInputContent
                    title={ this.props.dialogue.title }
                    inputFields={ this.props.dialogue.inputFields }
                    onDone={ (inputValues, activeInputID, setValidationErrors) => {
                        this.props.dialogue.onDone(inputValues, close, setValidationErrors);
                        if(this.props.dialogue.closeOnDone !== false){
                            close();
                        }
                    }}
                    onChange={ this.props.dialogue.onChange }
                    initialValues={ this.props.dialogue.initialValues }
                    initialValidationErrors={ this.props.dialogue.initialValidationErrors }
                    afterInputFields={ this.props.dialogue.afterInputFields }
                    initialKeyboardLayout={ this.props.dialogue.initialKeyboardLayout }
                />
            );

            return (
                <Modal
                    className={"Administration" + (this.props.dialogue.className || '')}
                    content={content}
                    onClose={ e => {
                        close();
                        this.props.dialogue.onClose();
                    }}
                    canClose={this.props.dialogue.canClose}
                />
            )
        }
        if(this.props.dialogue.selectDialogue === true){
            let onSelect = this.props.dialogue.onSelect;
            let onCancel = this.props.dialogue.onCancel;
            let content;

            if(this.props.theme === 'Erply'){
                content = (
                    <div>
                        <div style={{
                            backgroundColor: '#CCCCCC',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            height: '65px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <span style={{
                                fontSize: '20px',
                                marginLeft: '20px'
                            }}>
                                <Translate id={this.props.dialogue.title}/>
                            </span>
                        </div>
                        <div style={{
                            marginTop: '65px',
                            marginBottom: '20px'
                        }}>
                            {this.props.dialogue.message}
                        </div>
                        <PerfectScrollbar style={{
                            position: "absolute",
                            left: "40px",
                            right: "40px",
                            top: "105px",
                            bottom: "40px",
                            height: "auto"
                        }}>
                            <ul className={'dialogueSelectListErply'}>
                                {this.props.dialogue.options.map((option, index) => {
                                    return <li key={index} onClick={e => {
                                        onSelect(option.value, option.name);
                                        close();
                                    }}><Translate id={option.name}/></li>
                                })}
                            </ul>
                        </PerfectScrollbar>
                    </div>
                );
            }else{
                content = (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%"
                    }}>
                        <div className={"dialogueTitle"}><Translate id={this.props.dialogue.title}/></div>
                        <div style={{
                            padding: "20px 0"
                        }}>
                            {this.props.dialogue.message}
                        </div>
                        <PerfectScrollbar style={{
                            flexGrow: 1
                        }}>
                            <ul className={'dialogueSelectList'}>
                                {this.props.dialogue.options.map((option, index) => {
                                    return <li key={index} onClick={e => {
                                        onSelect(option.value, option.name);
                                        close();
                                    }}><Translate id={option.name}/></li>
                                })}
                            </ul>
                        </PerfectScrollbar>
                    </div>
                );
            }
            return (
                <Modal
                    className={"Administration " + (this.props.dialogue.className || '')}
                    content={content}
                    onClose={ e => {
                        close();
                        if(typeof onCancel !== "undefined"){
                            onCancel();
                        }
                    }}
                    canClose={this.props.dialogue.canClose}
                />
            )
        }
        return (
            <DialogueView
                confirmText={ typeof this.props.dialogue.confirmText !== "undefined" ? <Translate id={this.props.dialogue.confirmText}/> : null }
                cancelText={ typeof this.props.dialogue.cancelText !== "undefined" ? <Translate id={this.props.dialogue.cancelText}/> : null }
                onConfirm={ typeof this.props.dialogue.onConfirm !== "undefined" ? this.props.dialogue.onConfirm : null }
                onCancel={ typeof this.props.dialogue.onCancel !== "undefined" ? this.props.dialogue.onCancel : null }
                title={ this.props.dialogue.title }
                message={ this.props.dialogue.message }
                close={close}
                canClose={this.props.dialogue.canClose}
                smallDialogue={this.props.dialogue.smallDialogue}
                messageClass={this.props.dialogue.messageClass}
                confirmButtonClass={this.props.dialogue.confirmButtonClass}
                cancelButtonClass={this.props.dialogue.cancelButtonClass}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dialogue);
