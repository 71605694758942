import React, {Component} from "react";
import Button from "../../buttons/Button";
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import downArrowErplyImg from "../images/Erply/arrowDown.png";
import erplyLogo from "../images/Erply/logo.png";
import {connect} from "react-redux";
import {
    addErrorAlert,
    changeMode,
    closeModal,
    completeTransaction,
    createReceipt,
    deletePayment,
    handleFiscalRequest,
    openCashDrawer,
    openGetUserRightsByPinModal,
    openLogonModal,
    openModal,
    removeBeforeCompleteTransactionAction,
    removeSalesDocument,
    revertNextSequenceNumber,
    setLoading,
    setTransactionUser,
    showDialogue,
    cancelTransaction as appCancelTransaction
} from "../../redux/actions";
import { changePaymentMode } from "../../redux/actions";
import {
    addPayment,
    captureFirstGiftCard,
    checkGiftCard,
    processCard,
    processIntegratedPayment,
    removePayment,
    setTotalSum,
    voidGiftCardActivations
} from "../../redux/payment/actions";
import paymentDTO from "../../redux/payment/dto/payment";
import {
    basketHasDecathlonGiftCard,
    getPaymentsTotalSumPaid,
    getPaymentTypeByReasonCode,
    getPaymentTypeByType,
    getProductsTotalSum,
    getTransactionMOPLimit
} from "../../redux/selectors";
import {CHANGE_TRANSACTION_SAVE_STATUS, INCREMENT_NEXT_INVOICE_NO} from "../../redux/actionTypes";
import {
    APP_DOCUMENT_SAVE_STATUS_BEFORE_COMPLETE,
    APP_DOCUMENT_SAVE_STATUS_FISCAL_DONE,
    APP_DOCUMENT_SAVE_STATUS_PENDING,
    APP_DOCUMENT_SAVE_STATUS_START,
    APP_MODE_DONE,
    PAYMENT_MODE_OPTIONS
} from "../../redux/appConstants";
import {getCashPayment, getToBeCreditedSum} from "../../redux/payment/selectors";
import {
    INIT_PAYMENT,
    INPUT_PAYMENT_STARTED
} from "../../redux/payment/actionTypes";
import Logon from "../administration/Logon";
import * as uuid from "uuid";
import PaymentInput from "./PaymentInput";
import LeftImageButton from "../../buttons/LeftImageButton";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../uiComponents/WrappedPerfectScrollBar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import GiftCardPayment from "./GiftCardPayment";
import Decimal from "decimal.js";
import {createModalID} from "../../modal/Modal";
import MultipleInputContent from "../../modal/MultipleInputContent";
import {
    getPaymentSumInDefaultCurrency,
    interpolate,
    isEmptyString
} from "../../util/helperFunctions";
import LeftImageButtonErply from "../../buttons/LeftImageButtonErply";
import {Loader} from "../Loading";
import Image from "../../theme/Image";
import PromoCodeButton from "../../buttons/PromoCodeButton";
import OP from "../../campaigns/onePromotion/onePromotion";

const paymentLogon = (dispatch, afterLogon, paymentMethod, supervisorRequired=false) => {
    let logonModalID = uuid.v4();
    dispatch(openModal({
        content: <Logon
            message={<span><Translate id={'Logon to use this payment method'}/>: <Translate id={paymentMethod}/></span>}
            afterLogonAction={function (data) {
                console.log('paymentLogon', data);
                dispatch(closeModal(logonModalID));
                afterLogon(data[0]);
                return {
                    type: 'LOGGED_ON'
                };
            }}
            supervisor={supervisorRequired}
        />,
        type: 'logon',
        afterLogonAction: (data) => {
            afterLogon(data[0]);
        },
        id: logonModalID,
        className: "Administration",
        onClose: function(){},
        canClose: true
    }));
};

const startPaymentWithInput = (dispatch, payment, title, canGiveChange = false, paymentType, user=false, translate) => {
    const afterLogon = (user) => {
        let inputPaymentModalID = uuid.v4();
        let roundToNearestFiveCents = paymentType.roundToNearestFiveCents || false;
        let roundToNearestFiftyCents = paymentType.roundToNearestFiftyCents || false;

        dispatch(openModal({
            content:
                <PaymentInput
                    title={title}
                    paymentType={paymentType.name}
                    payment={payment}
                    currencyButtons={paymentType.name === "Cash"}
                    totalSum={payment.sum}
                    canGiveChange={canGiveChange}
                    onConfirm={function (paid, change, currency) {
                        let paymentSum = parseFloat(payment.sum);
                        let notDefaultCurrency = currency.default !== "1";
                        let paidInCurrency = parseFloat(paid);
                        if(notDefaultCurrency){
                            payment.currencyCode = currency.code;
                            payment.currencyRate = currency.rate;
                            paid = getPaymentSumInDefaultCurrency(paid, currency.rate);
                        }else{
                            paid = parseFloat(paid);
                        }
                        change = parseFloat(change);

                        if (canGiveChange) {
                            let changeSum = paymentSum >= 0 ? (change > 0 ? change : 0) : (change < 0 ? change : 0);
                            payment.sumPaid = new Decimal(paid).minus(new Decimal(changeSum)).toDecimalPlaces(4).toString();
                            payment.cashPaid = paidInCurrency;
                            payment.cashChange = changeSum;
                        } else {
                            payment.sumPaid = paymentSum >= 0 ? (paymentSum < paid ? paymentSum : paid) : (paymentSum >= paid ? paymentSum : paid);
                        }

                        if (typeof paymentType.limit !== "undefined") {
                            if (paymentSum >= 0 ?
                                parseFloat(payment.sumPaid) > parseFloat(paymentType.limit) :
                                parseFloat(payment.sumPaid) < (parseFloat(paymentType.limit) * -1)
                            ) {
                                dispatch(addErrorAlert(translate('This MOP has a limit of ${limit}', {limit: paymentType.limit})));
                                return;
                            }
                        }

                        payment.approvedUserID = user.userID;
                        dispatch(setTransactionUser(user));
                        // For cash payment, the drawer is opened before completing the input
                        if (paymentType.openCashDrawer || (canGiveChange && payment.cashChange > 0 && payment.type !== "CASH")) {
                            dispatch(openCashDrawer());
                        }
                        dispatch(addPayment(payment));
                        dispatch(closeModal(inputPaymentModalID));
                    }}
                    roundToNearestFiveCents={roundToNearestFiveCents}
                    roundToNearestFiftyCents={roundToNearestFiftyCents}
                    fullAmountButton={true}
                />,
            id: inputPaymentModalID,
            className: "Administration",
            onClose: function () {},
            canClose: true
        }));
        dispatch({
            type: INPUT_PAYMENT_STARTED,
            payload: payment
        });
    };

    if(user === false){
        paymentLogon(dispatch, afterLogon, paymentType.name);
    }else{
        afterLogon(user);
    }
};

const askPaymentInput = (dispatch, payment, title, onConfirm, onClose=()=>{}, logonRequired=true, canGiveChange=false) => {
    let openPaymentInputModal = () => {
        let inputPaymentModalID = uuid.v4();
        dispatch(openModal({
            content:
                <PaymentInput
                    title={title}
                    paymentType={payment.type}
                    payment={payment}
                    totalSum={ payment.sum }
                    canGiveChange={canGiveChange}
                    onConfirm={function (paid, change) {
                        onConfirm(paid, change);
                        dispatch(closeModal(inputPaymentModalID));
                    }}
                />,
            id: inputPaymentModalID,
            className: "Administration",
            onClose,
            canClose: true
        }));
        dispatch({
            type: INPUT_PAYMENT_STARTED,
            payload: payment
        });
    };

    if(logonRequired){
        paymentLogon(dispatch, (user) => {
            openPaymentInputModal();
        }, title);
    }else{
        openPaymentInputModal();
    }
};

export const returnPaymentNeedsSupervisorConfirm = (erplyConf, paymentType, paymentSum) => {
    let limits = {
        'CASH': 'DKTReturnCashSupervisorLimit',
        'CARD': 'DKTReturnCardSupervisorLimit',
        'manualCard': 'DKTReturnManualCardSupervisorLimit'
    };

    let limit = limits[paymentType] !== undefined ? erplyConf[limits[paymentType]] : undefined;
    return limit !== undefined ? (paymentSum * -1) > limit : false;
};

const handleBeforeCompleteActions = (dispatch, actions, onFail) => {
    if(actions.length > 0){
        let action = actions.pop();
        action.run(() => {
            dispatch(removeBeforeCompleteTransactionAction(action.id));
            handleBeforeCompleteActions(dispatch, actions, onFail);
        }, onFail);
    }
};

const mapStateToProps = state => {
    const totalSum = state.rootReducer.invoice !== false ? state.rootReducer.invoice.total : getProductsTotalSum(state.rootReducer.productsInBasket);
    const remainingSum = new Decimal(totalSum).minus(new Decimal(state.payment.totalPaid)).toDecimalPlaces(4).toString();
    const toBeCreditedSum = getToBeCreditedSum(state);
    const paymentsLength = state.payment.payments.length;
    const nextPaymentIndex = paymentsLength === 0 ? 0 : state.payment.payments[paymentsLength - 1].index + 1;
    const customer = state.rootReducer.customer;
    return {
        customer,
        showPayback: window.AppConf.payback || false,
        paymentMode: state.rootReducer.paymentMode,
        totalSum,
        remainingSum,
        toBeCreditedSum,
        referenceNumber: state.rootReducer.nextInvoiceNo,
        nextPaymentIndex: nextPaymentIndex,
        payment: state.payment,
        productsInBasket: state.rootReducer.productsInBasket,
        productCategories: state.rootReducer.productCategories,
        pos: state.rootReducer.pos,
        erplyConf: state.rootReducer.erplyConf,
        user: state.rootReducer.user,
        documentSaveStatus: state.rootReducer.documentSaveStatus,
        progressMessage: state.payment.currentCardPaymentProgressMessage,
        operationMode: state.rootReducer.operationMode,
        cashierMode: state.rootReducer.cashierMode,
        uiCustomizationPOS: state.rootReducer.uiCustomizationPOS,
        beforeCompleteTransactionActions: state.rootReducer.beforeCompleteTransactionActions,
        translate: getTranslate(state),
        //only gift card activations, payments not needed.
        receiptGiftCards: state.rootReducer.receiptGiftCards.filter(el => typeof el.amountCharged !== "undefined"),
        theme: state.rootReducer.theme,
        decathlonTheme: state.rootReducer.decathlonTheme,
        allowGiftCardPaymentWithGiftCard: window.AppConf.allowGiftCardPaymentWithGiftCard || false,
        giftCardPaymentEnabled: totalSum < 0 ? false :
            state.rootReducer.connectionHealth ? window.AppConf.paymentTypes?.main?.giftCard?.enabled ?? true : false,
        connectionHealth: state.rootReducer.connectionHealth,
        customPaymentView: state.payment.customPaymentView
    }
};

const mapDispatchToProps = dispatch => {
    function cancelTransaction(receiptGiftCards) {
        dispatch(setLoading(true));
        dispatch(appCancelTransaction());

        dispatch(voidGiftCardActivations(() => {
            dispatch(setLoading(false));
        }, (giftCards) => {
            dispatch(setLoading(false));
            for(let giftCard of giftCards){
                dispatch(showDialogue('Failed to void gift card activation', 'no: ' + (giftCard?.cardNumber ?? giftCard?.no), 'Ok',
                    () => {}, undefined, undefined, false, undefined, undefined, true));
            }
        }, receiptGiftCards));
        dispatch(revertNextSequenceNumber());
    }

    const doHandleFiscalRequest = (translate, receiptGiftCards) => {
        dispatch({
            type: CHANGE_TRANSACTION_SAVE_STATUS,
            payload: APP_DOCUMENT_SAVE_STATUS_PENDING
        });
        dispatch(handleFiscalRequest(() => {
            dispatch({
                type: CHANGE_TRANSACTION_SAVE_STATUS,
                payload: APP_DOCUMENT_SAVE_STATUS_FISCAL_DONE
            });
        }, (response) => {
            dispatch(showDialogue('could not complete transaction', translate('fiscalRequestFailed') + ' ' + translate('Error code') + ': ' + (response?.errorCode || '') + '. ' + (response?.textResponse || ''), 'retry', () => {
                doHandleFiscalRequest(translate, receiptGiftCards);
            }, ()=>{
                cancelTransaction(receiptGiftCards);
            },'Cancel', false));
        }));
    }
    return {
        startPayment: (totalSum) => {
            dispatch(setTotalSum(totalSum));
        },
        startCardPayment: (nextPaymentIndex, paymentSum, referenceNumber, erplyConf, user, posID, withInput=false, paymentType={}, erplyPaymentTypes=[]) => {
            let startPayment = () => {
                const payment = new paymentDTO(nextPaymentIndex, paymentSum, "started", "CARD");
                if(typeof paymentType?.extraInfo?.receiptTitle !== "undefined"){
                    payment.title = paymentType.extraInfo.receiptTitle;
                }
                let onPaymentDone = () => {
                    if (paymentType.openCashDrawer || ((payment?.cashChange ?? 0) > 0)) {
                        dispatch(openCashDrawer());
                    }
                    return changePaymentMode('options');
                };

                if(typeof paymentType.reasonCode !== "undefined"){
                    let erplyPaymentType = getPaymentTypeByReasonCode(erplyPaymentTypes, paymentType.reasonCode);
                    if(typeof erplyPaymentType !== "undefined"){
                        payment.typeID = erplyPaymentType.id;
                    }
                }

                if(typeof paymentType.extraInfo !== "undefined"){
                    payment.extraInfo = paymentType.extraInfo;
                }
                if(withInput){
                    let canGiveChange = typeof paymentType.canGiveChange !== "undefined" ? paymentType.canGiveChange : false;
                    askPaymentInput(dispatch, payment, paymentType?.name || 'CARD', (paidSum, change) => {
                        let paymentSum = parseFloat(payment.sum);
                        paidSum = parseFloat(paidSum);
                        change = parseFloat(change);

                        if(canGiveChange){
                            payment.cashChange = paymentSum >= 0 ? (change > 0 ? change : 0) : (change < 0 ? change : 0);
                        }

                        payment.sum = paidSum;
                        dispatch(addPayment(payment));
                        dispatch(changePaymentMode('card'));
                        dispatch(processCard(payment, referenceNumber, [onPaymentDone]));
                    }, undefined, typeof paymentType.logonRequired !== "undefined" ? paymentType.logonRequired : true, canGiveChange);
                }else{
                    dispatch(addPayment(payment));
                    dispatch(changePaymentMode('card'));
                    dispatch(processCard(payment, referenceNumber, [onPaymentDone]));
                }
            };

            if(paymentSum < 0 && returnPaymentNeedsSupervisorConfirm(erplyConf, 'CARD', paymentSum)){
                dispatch(openGetUserRightsByPinModal(dispatch, user.clientCode, posID, (userRights, adminUser) => {
                    if(userRights.rightMakeDiscountInPOS != 1 || (!window.AppConf.selfValidateSupervisor && user.userID === adminUser.userID)){
                        dispatch(addErrorAlert('User has no right to perform this action!'));
                    }else{
                        startPayment();
                    }
                }));
            }else{
                startPayment();
            }
        },
        startGiftCardPayment: (nextPaymentIndex, paymentSum) => {
            dispatch(changePaymentMode('giftCard'));
            const payment = new paymentDTO(nextPaymentIndex, paymentSum, "started", "GIFTCARD");
            dispatch(addPayment(payment));
        },
        startCashPayment: (nextPaymentIndex, paymentSum, payments, erplyConf, user, posID, cashTypeConf, translate) => {
            const roundToNearestFiftyCents = parseInt(erplyConf.pos_round_cash_to_nearest_05) === 1;
            const roundToNearestFiveCents = parseInt(erplyConf.pos_round_cash_to_nearest_005) === 1;
            let multipleCurrencies = (erplyConf?.touchpos_sale_additional_currencies ?? "") !== "";
            if(!multipleCurrencies){
                let oldPayment = getCashPayment(payments);
                if(oldPayment !== false){
                    paymentSum = new Decimal(paymentSum).plus(new Decimal(oldPayment.sumPaid)).toDecimalPlaces(4).toString();
                    dispatch(removePayment(oldPayment));
                }
            }
            const payment = new paymentDTO(nextPaymentIndex, paymentSum, "success", "CASH");

            let limit = cashTypeConf?.limit ?? erplyConf?.DKTCashPaymentLimit ?? undefined;

            if(paymentSum < 0 && returnPaymentNeedsSupervisorConfirm(erplyConf, 'CASH', paymentSum)){
                dispatch(openGetUserRightsByPinModal(dispatch, user.clientCode, posID, (userRights, adminUser) => {
                    if(userRights.rightMakeDiscountInPOS != 1 || (!window.AppConf.selfValidateSupervisor && user.userID === adminUser.userID)){
                        dispatch(addErrorAlert('User has no right to perform this action!'));
                    }else{
                        startPaymentWithInput(dispatch, payment, 'Pay with cash', true, {name: 'Cash', roundToNearestFiveCents, roundToNearestFiftyCents, limit}, user, translate);
                    }
                }));
            }else{
                startPaymentWithInput(dispatch, payment, 'Pay with cash', true, {name: 'Cash', roundToNearestFiveCents, roundToNearestFiftyCents, limit}, undefined, translate);
            }
        },
        startCustomPayment: (
            withInput,
            erplyPaymentTypes,
            nextPaymentIndex,
            paymentSum,
            paymentType,
            erplyConf,
            user,
            posID,
            canGiveChange = false,
            sumPaid = false,
            connectionHealth = true,
            translate
        ) => {
            const payment = new paymentDTO(nextPaymentIndex, paymentSum, "success", paymentType.type);
            let erplyPaymentType;
            if(typeof paymentType.reasonCode !== "undefined"){
                erplyPaymentType = getPaymentTypeByReasonCode(erplyPaymentTypes, paymentType.reasonCode);
                payment.title = paymentType.name;
            }else if(typeof paymentType.type !== "undefined"){
                erplyPaymentType = getPaymentTypeByType(erplyPaymentTypes, paymentType.type);
                payment.title = erplyPaymentType.name;
            }
            if(typeof erplyPaymentType !== "undefined"){
                payment.typeID = erplyPaymentType.id;
            }else{
                dispatch(addErrorAlert('Payment type ' + (typeof paymentType.reasonCode !== "undefined" ? 'with reason code ' + paymentType.reasonCode : paymentType.type) + ' is missing. Please add to Erply BO!'));
                return;
            }
            if(typeof paymentType.extraInfo !== "undefined"){
                payment.extraInfo = paymentType.extraInfo;
            }
            if(withInput){
                let supervisorRequired = paymentType?.supervisor === true;
                if(supervisorRequired && paymentType.type === "manualCard" && !connectionHealth){
                    supervisorRequired = false;
                }
                if((paymentSum < 0 && returnPaymentNeedsSupervisorConfirm(erplyConf, paymentType.type, paymentSum)) || supervisorRequired){
                    dispatch(openGetUserRightsByPinModal(dispatch, user.clientCode, posID, (userRights, adminUser) => {
                        if(userRights.rightMakeDiscountInPOS != 1 || (!window.AppConf.selfValidateSupervisor && user.userID === adminUser.userID)){
                            dispatch(addErrorAlert('User has no right to perform this action!'));
                        }else{
                            startPaymentWithInput(dispatch, payment, paymentType.name, true, paymentType, user, translate);
                        }
                    }));
                }else{
                    startPaymentWithInput(dispatch, payment, paymentType.name, true, paymentType, undefined, translate);
                }
            }else{
                payment.sumPaid = sumPaid !== false ? sumPaid : paymentSum;
                if(paymentType.openCashDrawer){
                    dispatch(openCashDrawer());
                }
                dispatch(addPayment(payment));
            }
        },
        startVoucherPayment: (erplyPaymentTypes, nextPaymentIndex, paymentSum, paymentType, user) => {
            let erplyPaymentType = getPaymentTypeByReasonCode(erplyPaymentTypes, paymentType.reasonCode);
            if(typeof erplyPaymentType === "undefined"){
                dispatch(addErrorAlert('Payment type with reason code ' + paymentType.reasonCode + ' is missing. Please add to Erply BO!'));
                return;
            }

            let supervisorRequired = paymentType?.supervisor === true;

            paymentLogon(dispatch, (adminUser) => {
                if(supervisorRequired && !window.AppConf.selfValidateSupervisor && user.userID === adminUser.userID){
                    dispatch(addErrorAlert('User has no right to perform this action!'));
                    return;
                }

                let editModalID = createModalID();
                dispatch(openModal({
                    content:
                        <MultipleInputContent
                            title={ paymentType.name }
                            inputFields={ paymentType.inputs }
                            onDone={(inputValues) => {
                                let useAmount;
                                if(paymentType.allowSplit === false || typeof inputValues['value'] === "undefined"){
                                    useAmount = paymentSum;
                                }else{
                                    useAmount = parseFloat(inputValues['value'].replace(',', '.')) || 0;
                                    if(useAmount === 0){
                                        dispatch(addErrorAlert('please insert correct amount'));
                                        return;
                                    }
                                    if(paymentSum > 0){
                                        if(useAmount > paymentSum){
                                            useAmount = paymentSum;
                                        }
                                    }else{
                                        useAmount *= -1;
                                        if(useAmount < paymentSum){
                                            useAmount = paymentSum;
                                        }
                                    }
                                }

                                for(let input of paymentType.inputs){
                                    if(typeof input.validate !== "undefined"){
                                        let value = inputValues[input.id];
                                        if(input.validate?.notEmpty){
                                            if(isEmptyString(value)){
                                                dispatch(addErrorAlert('Enter details'));
                                                return;
                                            }
                                        }
                                    }
                                }

                                const payment = new paymentDTO(nextPaymentIndex, useAmount, "success", paymentType.type);
                                payment.title = paymentType.name;

                                for(let output of paymentType.outputs){
                                    let outputValue = interpolate(output.value, inputValues);
                                    if(output.type === 'documentAttributes'){
                                        payment.addDocumentAttribute(output.name, outputValue);
                                    }else{
                                        payment[output.type] = outputValue;
                                    }
                                }

                                payment.typeID = erplyPaymentType.id;
                                payment.sumPaid = payment.sum;
                                dispatch(addPayment(payment));
                                if(paymentType.openCashDrawer){
                                    dispatch(openCashDrawer());
                                }

                                dispatch(closeModal(editModalID));
                            }}
                            initialKeyboardLayout={'numbers'}
                        />,
                    id: editModalID,
                    className: "Administration",
                    onClose: function () {

                    },
                    canClose: true
                }));
            }, paymentType.name, supervisorRequired);
        },
        startIntegratedPayment: (erplyPaymentTypes, nextPaymentIndex, paymentSum, paymentType) => {
            let erplyPaymentType = getPaymentTypeByReasonCode(erplyPaymentTypes, paymentType.reasonCode);
            if(typeof erplyPaymentType === "undefined"){
                dispatch(addErrorAlert('Payment type with reason code ' + paymentType.reasonCode + ' is missing. Please add to Erply BO!'));
                return;
            }
            const payment = new paymentDTO(nextPaymentIndex, paymentSum, "started", paymentType.type);
            payment.typeID = erplyPaymentType.id;
            payment.isIntegrated = true;
            dispatch(addPayment(payment));
            dispatch(processIntegratedPayment(payment, paymentType));
        },
        checkGiftCard: (number) => {
            dispatch(setLoading(true));
            dispatch(checkGiftCard(number, () => {
                dispatch(setLoading(false));
            }));
        },
        captureFirstGiftCard: () => {
            dispatch(changePaymentMode('giftCardCapture'));
            let onDone = () => {
                dispatch(changePaymentMode('options'));
            };
            dispatch(captureFirstGiftCard(onDone, () => {}));
        },
        changeTransactionSaveStatus: (status) => {
            dispatch({
                type: CHANGE_TRANSACTION_SAVE_STATUS,
                payload: status
            });
        },
        handleBeforeCompleteActions: (actions, onFail) => {
            handleBeforeCompleteActions(dispatch, actions, onFail);
        },
        handleFiscalRequest(translate, receiptGiftCards){
            doHandleFiscalRequest(translate, receiptGiftCards);
        },
        completeTransaction: (
            payment,
            totalSum
        ) => {
            dispatch({
                type: CHANGE_TRANSACTION_SAVE_STATUS,
                payload: APP_DOCUMENT_SAVE_STATUS_PENDING
            });

            if(parseFloat(totalSum) !== parseFloat(getPaymentsTotalSumPaid(payment.payments))){
                dispatch(showDialogue('ALERT ERROR ON TRANSACTION', 'transactionDiscrepancyError', 'Ok', function () {
                    cancelTransaction();
                }, undefined, undefined, false, undefined, undefined, true));
                return false;
            }

            function doComplete(retry, receipt){
                if(retry > 4) {
                    dispatch(openLogonModal('Logon to handle failure to complete transaction', (data) => {
                        dispatch(showDialogue('could not complete transaction', 'There is a connection or API issue, please reboot the SCO. If the issue persists, please contact your technical support.', 'Ok', function () {
                            cancelTransaction();
                        }, undefined, undefined, false));
                    }, false));
                    return false;
                }
                dispatch(setLoading(true));
                let next = retry + 1;
                dispatch(
                    completeTransaction(
                        receipt,
                        (invoiceID, invoiceNo, receiptLink) => {
                            dispatch(setLoading(false));
                            dispatch(changeMode(APP_MODE_DONE));
                            dispatch({type: INCREMENT_NEXT_INVOICE_NO, payload: {lastInvoiceNo: invoiceNo}})
                            dispatch({type: INIT_PAYMENT});
                        }, (requests) => {
                            dispatch(setLoading(false));
                            dispatch(addErrorAlert('Communication error, trying again...'));
                            for(let request of requests){
                                if(request.status.responseStatus === 'ok'){
                                    if(request.status.requestName === 'saveSalesDocument'){
                                        dispatch(removeSalesDocument(request.records[0].invoiceID));
                                    }else if(request.status.requestName === 'savePayment'){
                                        dispatch(deletePayment(request.records[0].paymentID));
                                    }
                                }
                            }
                            window.setTimeout(function () {
                                doComplete(next, receipt);
                            }, 5000);
                        }
                    ));
            }

            function doCreateReceipt() {
                dispatch(createReceipt((receipt) => {
                    doComplete(0, receipt);
                }));
            }

            doCreateReceipt();
        },
        backToOptions: (payments) => {
            let currentPayment = payments.find(payment => payment.status === 'started' && payment.type === 'GIFTCARD');
            if(typeof currentPayment !== "undefined"){
                dispatch(removePayment(currentPayment));
            }
            dispatch(changePaymentMode(PAYMENT_MODE_OPTIONS));
        },
        addErrorAlert: (message) => {
            dispatch(addErrorAlert(message));
        },
    }
};

class Payment extends Component{

    constructor(props) {
        super(props);
        this.state = {
            paymentOptions: 'main'
        };
    }

    componentDidMount() {
        this.props.startPayment(this.props.totalSum);
        this.completeTransaction();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        setTimeout(() => {
            this.completeTransaction();
        }, 100);
    }

    paymentsCompleted() {
        return this.props.totalSum >= 0 ? this.props.remainingSum <= 0 : this.props.remainingSum >= 0;
    }

    paymentsCanBeCompleted() {
        if(this.props.totalSum < 0){
            return false;
        }
        return this.props.toBeCreditedSum <= 0;
    }

    completeTransaction() {
        console.log('Try to complete transaction', this.paymentsCompleted());
        if(this.props.documentSaveStatus !== APP_DOCUMENT_SAVE_STATUS_PENDING && this.props.customer !== false){
            if(this.paymentsCompleted()){
                if(this.props.documentSaveStatus === APP_DOCUMENT_SAVE_STATUS_START){
                    this.props.changeTransactionSaveStatus(APP_DOCUMENT_SAVE_STATUS_BEFORE_COMPLETE);
                    let actions = Array.from(this.props.beforeCompleteTransactionActions);
                    this.props.handleBeforeCompleteActions(actions, () => {

                    });
                    return true;
                }

                if(this.props.beforeCompleteTransactionActions.length > 0 || this.props.documentSaveStatus === APP_DOCUMENT_SAVE_STATUS_START){
                    return false;
                }


                if(this.props.pos.fiscalReceiptRequired && this.props.documentSaveStatus !== APP_DOCUMENT_SAVE_STATUS_FISCAL_DONE){
                    this.props.handleFiscalRequest(this.props.translate, this.props.receiptGiftCards);
                    return true;
                }

                console.log('Completing transaction');
                this.props.completeTransaction(
                    this.props.payment,
                    this.props.totalSum
                );
            }else if(this.paymentsCanBeCompleted()){
                if(this.props.paymentMode === 'options'){
                    this.props.captureFirstGiftCard();
                }
            }
        }
    }

    paymentOptions() {
        const PaymentButton = (props) => {
            if(this.props.theme === 'Erply'){
                return <LeftImageButtonErply
                    className={props.className}
                    image={props.image}
                    title={props.title}
                />
            }else{
                return <LeftImageButton
                    className={props.className}
                    image={props.image}
                    title={props.title}
                />
            }
        }
        if(this.props.payment.payments.length >= getTransactionMOPLimit()){
            return <div><h2><Translate id={"noMorePaymentMethods"}/></h2></div>
        }
        let mainPaymentTypes = this.props.payment.displayPaymentTypes.main.filter(el => this.props.uiCustomizationPOS.paymentMethods[el.name] === 1).map((payment, index) => {
            let erplyPaymentType = getPaymentTypeByType(this.props.payment.paymentTypes, payment.type);
            return (
                <div style={{order: 1000}} key={index} onClick={
                    e => {
                        console.log('mainPaymentType clicked', payment, erplyPaymentType);
                        if(payment.input === 'manual'){
                            this.props.startCustomPayment(
                                true,
                                this.props.payment.paymentTypes,
                                this.props.nextPaymentIndex,
                                this.props.toBeCreditedSum,
                                payment, this.props.erplyConf,
                                this.props.user,
                                this.props.pos.pointOfSaleID,
                                undefined,
                                undefined,
                                this.props.connectionHealth,
                                this.props.translate
                            );
                        }else if(typeof payment.input === "function"){
                            let sumPaid = payment.input(this.props.toBeCreditedSum);
                            if(sumPaid === 0){
                                this.props.addErrorAlert("Can't use this payment method")
                            }else{
                                this.props.startCustomPayment(
                                    false,
                                    this.props.payment.paymentTypes,
                                    this.props.nextPaymentIndex,
                                    this.props.toBeCreditedSum,
                                    payment,
                                    this.props.erplyConf,
                                    this.props.user,
                                    this.props.pos.pointOfSaleID,
                                    false,
                                    sumPaid,
                                    this.props.connectionHealth,
                                    this.props.translate
                                );
                            }
                        }
                    }
                }>
                    <PaymentButton
                        className={'paymentButton otherPayment'}
                        image={"otherPaymentImage"}
                        title={<Translate id={erplyPaymentType.name}/>}
                    />
                </div>
            )
        });

        const getPaymentTypes = (category, paymentType, defaultType = false) => {
            let operationMode = this.props.cashierMode ? "cashier" : this.props.operationMode;
            let returnMode = this.props.totalSum < 0;

            if(typeof window?.AppConf?.paymentTypes?.[category] === "undefined"){
                return [];
            }
            return window.AppConf.paymentTypes[category].filter(el => {
                if(returnMode && el?.returnMode === false){
                    return false;
                }

                if(!this.props.connectionHealth && el?.offline === false){
                    return false;
                }
                if(this.props.operationMode !== 'full'){
                    if(el.logonRequired !== false){
                        return false;
                    }
                }
                if(defaultType && (typeof el.display === "undefined" || el?.display?.[operationMode] === "")){
                    return true;
                }
                if(typeof el.display === "object"){
                    return el.display?.[operationMode] === paymentType;
                }else {
                    return el.display === paymentType;
                }
            });
        }
        const manualInputOtherPaymentTypes = getPaymentTypes("manualInput", "other", true);
        const manualInputMainPaymentTypes = getPaymentTypes("manualInput", "main");
        const otherVoucherPaymentTypes = getPaymentTypes("voucher", "other", true);
        const mainVoucherPaymentTypes = getPaymentTypes("voucher", "main");
        const otherIntegratedPaymentTypes = this.props.connectionHealth ?
            getPaymentTypes("integration", "other", true) : [];
        const mainIntegratedPaymentTypes = this.props.connectionHealth ?
            getPaymentTypes("integration", "main") : [];

        let hasOtherPayments = (manualInputOtherPaymentTypes.length + otherVoucherPaymentTypes.length + otherIntegratedPaymentTypes.length) > 0;

        const mapCustomPayments = (customPaymentTypes) => customPaymentTypes.map((payment, index) => {
            let paymentTransactionMode = payment.transactionMode ?? 'both';
            if(this.props.totalSum >= 0){
                if(paymentTransactionMode === 'return' ){
                    return;
                }
            }else{
                if(paymentTransactionMode === 'sale' ){
                    return;
                }
            }
            let withInput = payment?.extraInfo?.withInput ?? true;

            if(payment.allowSplit === false){
                if(this.props.payment.payments.length > 0){
                    return;
                }
                withInput = false;
            }

            return (
                <div style={{order: 1000}} key={index} onClick={
                    e => {
                        if(payment.type === 'card'){
                            this.props.startCardPayment(
                                this.props.nextPaymentIndex,
                                this.props.toBeCreditedSum,
                                this.props.referenceNumber,
                                this.props.erplyConf,
                                this.props.user,
                                this.props.pos.pointOfSaleID,
                                withInput,
                                payment,
                                this.props.payment.paymentTypes
                            );
                        }else{
                            this.props.startCustomPayment(
                                withInput,
                                this.props.payment.paymentTypes,
                                this.props.nextPaymentIndex,
                                this.props.toBeCreditedSum,
                                payment,
                                this.props.erplyConf,
                                this.props.user,
                                this.props.pos.pointOfSaleID,
                                undefined,
                                undefined,
                                this.props.connectionHealth,
                                this.props.translate
                            );
                        }
                    }
                }>
                    <PaymentButton
                        className={payment.type === 'card' ? 'cardPaymentButtonSmaller' : 'paymentButton otherPaymentButton'}
                        image={payment.type === 'card' ? "bankCardImage" : "otherPaymentImage"}
                        title=<Translate id={payment.name}/>
                    />
                </div>
            )
        });
        const customOtherPayments = mapCustomPayments(manualInputOtherPaymentTypes);
        const customMainPayments = mapCustomPayments(manualInputMainPaymentTypes);

        let mapVoucherPayments = (voucherPaymentTypes) => {
            return voucherPaymentTypes.map((payment, index) => {
                if (payment.allowSplit === false) {
                    if (this.props.payment.payments.length > 0) {
                        return;
                    }
                }
                return (
                    <div style={{order: 1000}} key={index} onClick={
                        e => this.props.startVoucherPayment(this.props.payment.paymentTypes, this.props.nextPaymentIndex, this.props.toBeCreditedSum, payment, this.props.user)
                    }>
                        <LeftImageButton
                            className={'paymentButton'}
                            image={"voucherPaymentImg"}
                            title=<Translate id={payment.name}/>
                        />
                    </div>
                )
            })
        }
        const otherVoucherPayments = mapVoucherPayments(otherVoucherPaymentTypes);
        const mainVoucherPayments = mapVoucherPayments(mainVoucherPaymentTypes);


        let mapIntegratedPayments = (paymentTypes) => {
            return paymentTypes.map((payment, index) => {
                if (payment.allowSplit === false) {
                    if (this.props.payment.payments.length > 0) {
                        return;
                    }
                }
                return (
                    <div style={{order: 1000}} key={index} onClick={
                        e => {
                            console.log({MOP: payment, connection: this.props.connectionHealth});
                            if(!this.props.connectionHealth){
                                this.props.addErrorAlert("This mean of payment is not available in offline mode!");
                                return false;
                            }
                            this.props.startIntegratedPayment(this.props.payment.paymentTypes, this.props.nextPaymentIndex, this.props.toBeCreditedSum, payment);
                        }
                    }>
                        <LeftImageButton
                            className={'paymentButton'}
                            image={"voucherPaymentImg"}
                            title=<Translate id={payment.name}/>
                        />
                    </div>
                )
            })
        }
        let otherIntegratedPayments = mapIntegratedPayments(otherIntegratedPaymentTypes);
        let mainIntegratedPayments = mapIntegratedPayments(mainIntegratedPaymentTypes);

        let displayPayments;

        if(this.state.paymentOptions === 'main'){
            let otherPaymentsButton;
            let cashPaymentButton;

            if(hasOtherPayments){
                let otherType = window.AppConf.paymentTypes?.main?.other ?? {enabled: true, order: 0};
                if(otherType.enabled){
                    let otherPaymentButtonStyle = {};
                    if (otherType.order !== "" && otherType.order !== 0) {
                        otherPaymentButtonStyle.order = otherType.order;
                    }
                    otherPaymentsButton = (
                        <div style={otherPaymentButtonStyle} onClick={e => this.setState({paymentOptions: 'other'})}>
                            <PaymentButton
                                className={'paymentButton otherPaymentButton'}
                                image={"otherPaymentImage"}
                                title=<Translate id={"Other payments"}/>
                            />
                        </div>
                    );
                }
            }

            if(this.props.operationMode === 'full'){
                let cashType = window.AppConf.paymentTypes?.main?.cash ?? {enabled: true, offline: true, order: 0};
                if(cashType.enabled && (this.props.connectionHealth || cashType.offline)){
                    let cashPaymentButtonStyle = {};
                    if (cashType.order !== "" && cashType.order !== 0) {
                        cashPaymentButtonStyle.order = cashType.order;
                    }
                    if(this.props.uiCustomizationPOS.paymentMethods.cash === 1) {
                        cashPaymentButton = (
                            <div style={cashPaymentButtonStyle} onClick={e => this.props.startCashPayment(
                                this.props.nextPaymentIndex,
                                this.props.toBeCreditedSum,
                                this.props.payment.payments,
                                this.props.erplyConf,
                                this.props.user,
                                this.props.pos.pointOfSaleID,
                                cashType,
                                this.props.translate
                            )}>
                                <PaymentButton
                                    className={'paymentButton cashPaymentButton'}
                                    image={"cashPaymentImage"}
                                    title=<Translate id={"Cash"}/>
                                />
                            </div>
                        );
                    }
                }
            }

            let cardPaymentButton;
            let giftCardPaymentButton;

            if(this.props.uiCustomizationPOS.paymentMethods.card === 1){
                let CardPaymentButton = () => {
                    return <PaymentButton
                        title={<Translate id={'bankCard'}/>}
                        className={'cardPaymentButton'}
                        image={"bankCardImage"}
                    />;
                }
                if(typeof window.AppConf.paymentTypes?.main?.card !== "undefined"){
                    let cardType = window.AppConf.paymentTypes.main.card;
                    if(cardType.enabled && (this.props.connectionHealth || cardType.offline)){
                        let cardPaymentButtonStyle = {};
                        if(cardType.order !== "" && cardType.order !== 0){
                            cardPaymentButtonStyle.order = cardType.order;
                        }
                        cardPaymentButton = (
                            <div style={cardPaymentButtonStyle} onClick={e => {
                                this.props.startCardPayment(
                                    this.props.nextPaymentIndex,
                                    this.props.toBeCreditedSum,
                                    this.props.referenceNumber,
                                    this.props.erplyConf,
                                    this.props.user,
                                    this.props.pos.pointOfSaleID,
                                    false,
                                    window.AppConf.paymentTypes.main.card,
                                    this.props.payment.paymentTypes
                                );
                            }}>
                                <CardPaymentButton/>
                            </div>
                        );
                    }
                }else{
                    cardPaymentButton = (
                        <div onClick={e => {
                            this.props.startCardPayment(
                                this.props.nextPaymentIndex,
                                this.props.toBeCreditedSum,
                                this.props.referenceNumber,
                                this.props.erplyConf,
                                this.props.user,
                                this.props.pos.pointOfSaleID
                            );
                        }}>
                            <CardPaymentButton/>
                        </div>
                    );
                }
            }

            if(this.props.uiCustomizationPOS.paymentMethods.giftCard === 1){
                if(this.props.giftCardPaymentEnabled){
                    let giftCardPaymentButtonStyle = {};
                    let giftCardType = window.AppConf.paymentTypes?.main?.giftCard ?? false;
                    if(giftCardType !== false && giftCardType.order !== "" && giftCardType.order !== 0){
                        giftCardPaymentButtonStyle.order = giftCardType.order;
                    }
                    let giftCardText = this.props.theme === 'Erply' ? "giftCard" : "gift card or loyalty voucher";
                    if(
                        this.props.theme === 'Erply' ||
                        !(!this.props.allowGiftCardPaymentWithGiftCard && basketHasDecathlonGiftCard(this.props.productsInBasket, this.props.productCategories))
                    ){
                        giftCardPaymentButton = (
                            <div style={giftCardPaymentButtonStyle} onClick={() =>
                                this.props.startGiftCardPayment(
                                    this.props.nextPaymentIndex,
                                    this.props.toBeCreditedSum,
                                    this.props.referenceNumber
                                )}>
                                <PaymentButton
                                    className={'paymentButton giftCardPaymentButton'}
                                    image={"giftCardWhiteImage"}
                                    title=<Translate id={giftCardText}/>
                                />
                            </div>
                        );
                    }
                }
            }

            displayPayments = (
                <div id={"paymentOptions"}>
                    { cardPaymentButton }
                    { giftCardPaymentButton }
                    { mainPaymentTypes }
                    { customMainPayments }
                    { mainVoucherPayments }
                    { mainIntegratedPayments }
                    { otherPaymentsButton }
                    { cashPaymentButton }
                </div>
            );

        }else{
            displayPayments = (
                <div id={"paymentOptions"}>
                    <div onClick={e => this.setState({paymentOptions: 'main'})} style={{marginBottom: '10px'}}>
                        {this.props.theme === 'Erply' ?
                            <Button type="button" className={"largeButton grayButton roundButton uppercaseText"} name={<Translate id="back"/>}
                                    arrow="left"/>
                            :
                            <Button type="button" className={"largeButton blueButton"} name={<Translate id="back"/>}
                                    arrow="left" flencheClass={"flencheSmallLeftBottom blueFlenche"}/>
                        }
                    </div>
                    { customOtherPayments }
                    { otherVoucherPayments }
                    { otherIntegratedPayments }
                </div>
            );
        }

        let title;
        if(this.props.theme === 'Erply'){
            title =
                <div style={{
                    display: 'inline-grid'
                }}>
                    <Image
                        image={"iconCash"}
                        alt={'MOP'}
                        style={{
                            margin: '16px auto'
                        }}
                    />
                    <span
                        className={"boldText"}
                        style={{
                            margin: '16px auto',
                            fontSize: '24px'
                        }}
                    >
                        <Translate id={'Choose your payment method'}/>
                    </span>
                    <img
                        src={downArrowErplyImg}
                        alt={'MOP'}
                        style={{
                            margin: '16px auto'
                        }}
                    />
                </div>;
        }else{
            title =
                <span className={"boldUppercaseText"}>
                    <Translate id={'choosePaymentMethod'}/>
                </span>;
        }

        let addPromoCodeButton;
        if(window.AppConf?.enableOnePromotion ?? false){
            if(OP.conf.couponEnabled){
                addPromoCodeButton = <div>
                    <PromoCodeButton/>
                </div>;
            }
        }

        return (
            <div>
                {title}
                <br/>
                { addPromoCodeButton }
                <PerfectScrollbar style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: this.props.theme === 'Erply' ? '230px' : '180px',
                    height: 'calc(100% - 190px)'
                }}>
                    { displayPayments }
                </PerfectScrollbar>
            </div>
        );
    }
    cardPayment() {
        if(this.props.theme === 'Erply'){
            return (
                <div style={{
                    display: 'inline-grid'
                }}>
                    <Image
                        image={"bankCardImage"}
                        alt={'Bank card'}
                        style={{
                            margin: '30px auto',
                            width: '31px'
                        }}
                    />
                    <span
                        className={"boldText"}
                        style={{
                            fontSize: '24px'
                        }}
                    >
                        { <Translate id={this.props.progressMessage || "Apply your card"}/>}
                    </span>
                    <Image
                        image={"paymentTerminal"}
                        alt="paymentTerminal"
                        style={{
                            margin: '40px auto',
                            maxWidth: '80%',
                            maxHeight: '300px',
                            paddingLeft: '133px'
                        }}
                    />
                    <div style={{
                        margin: "20px",
                        position: "absolute",
                        bottom: 0,
                        right: 0
                    }}>
                        <img src={erplyLogo} alt="ERPLY" style={{
                            width: "150px"
                        }}/>
                    </div>
                </div>
            );
        }else{
            return (
                <div>
                <span className={"boldUppercaseText"}>
                        { <Translate id={this.props.progressMessage || "Follow payment terminal instructions"}/>}
                    </span>
                    <br/>
                    <div style={{
                        "marginBottom": "45px"
                    }}/>
                    <Image image={"paymentTerminal"} alt="paymentTerminal"/>
                    <div style={{
                        margin: "20px",
                        position: "absolute",
                        bottom: 0,
                        right: 0
                    }}>
                        <img src={erplyLogo} alt="ERPLY" style={{
                            width: "150px"
                        }}/>
                    </div>
                </div>
            );
        }
    }
    giftCardPayment() {
        return (
            <GiftCardPayment
                backToOptions={() => {
                    this.props.backToOptions(this.props.payment.payments);
                }}
                onManualEntry={(number) => {
                    this.props.checkGiftCard(number);
                }}
            />
        );
    }
    render() {
        if (this.paymentsCompleted()){
            return null;
        }else{
            switch (this.props.paymentMode) {
                case 'options':
                    return this.paymentOptions();
                case 'card':
                    return this.cardPayment();
                case 'giftCard':
                    return this.giftCardPayment();
                case 'custom':
                    return this.props.customPaymentView;
                default:
                    return (
                        <Loader position={"absolute"}/>
                    );
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
