import React from "react";
import Translate from "localization/Translate";
import Button from "../../buttons/Button";
import {useDispatch, useSelector} from "react-redux";
import {closeRightPanelDialogue} from "../../redux/actions";
import MultipleInputContent from "../../modal/MultipleInputContent";

export default function ({title, inputFields, onBack, onDone, onInputChange}) {
    let customer = useSelector(state => state.rootReducer.customer);
    const dispatch = useDispatch();

    if(customer !== false){
        setTimeout(() => {
            dispatch(closeRightPanelDialogue());
        }, 1)
        return null;
    }

    let backButton;
    if(onBack !== undefined){
        backButton = (
            <div onClick={onBack} style={{marginTop: '10px', marginBottom: '10px'}}>
                <Button type="button" className={"largeButton grayButton"} name={<Translate id="back"/>}
                    arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
            </div>
        );
    }

    return (
        <div style={{
            padding: "10px",
            paddingTop: "100px",
            fontSize: "16px",
            height: "100%"
        }}>
            <MultipleInputContent
                containerStyle={{
                    position: "auto",
                    height: "calc(100% - 100px)"
                }}
                beforeInputFields={ <div className="fullWidth centerText" style={{marginBottom: "50px"}}><span className="boldUppercaseText centerText">{ title }</span></div> }
                inputFields={ inputFields }
                onDone={onDone}
                onChange={onInputChange}
                afterInputFields={backButton}
                inputClassName={"fullWidth shadowBox"}
                inputStyle={{
                    boxSizing: "border-box"
                }}
            />
        </div>
    );
}
