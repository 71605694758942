import React, {Component} from 'react';
import "../main.css";
import Translate from "localization/Translate";
import {getLanguages, getTranslate} from "localization/localizeSlice";
import {connect} from "react-redux";
import '@programmerraj/rc-color-picker/assets/index.css';
import {ColorPicker} from "@programmerraj/rc-color-picker";
import TinyColor from "tinycolor2";
//import PerfectScrollbar from "react-perfect-scrollbar/lib/index";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../uiComponents/WrappedPerfectScrollBar';
import {saveUICustomization} from "../../redux/actions";
import Button from "../../buttons/Button";
import {SET_POS_UI_CUSTOMIZATION, SET_UI_CUSTOMIZATION} from "../../redux/actionTypes";
import Card from "../../uiComponents/Card";
import CheckBox from "../../formElements/CheckBox";
import Header from "./components/Header";

const mapStateToProps = state => {
    return {
        uiCustomization: state.rootReducer.uiCustomization,
        uiCustomizationPOS: state.rootReducer.uiCustomizationPOS,
        administration: state.rootReducer.administration,
        translate: getTranslate(state),
        languages: getLanguages(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveUICustomization: (uiCustomization) => {
            dispatch(saveUICustomization(uiCustomization, 'UICustomization', 'Company'));
            dispatch({
                type: SET_UI_CUSTOMIZATION,
                payload: uiCustomization
            });
        },
        savePOSUICustomization: (uiCustomization) => {
            dispatch(saveUICustomization(uiCustomization, 'UICustomizationPOS', 'Pos'));
            dispatch({
                type: SET_POS_UI_CUSTOMIZATION,
                payload: uiCustomization
            });
        }
    }
};

class EditUI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uiCustomization: props.uiCustomization,
            uiCustomizationPOS: props.uiCustomizationPOS
        };
    }

    render() {
        return (
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            }}>
                <Header title={"Edit UI"}/>
                <div style={{
                    width: "100%",
                    borderTop: "1px solid #ededed"
                }}/>
                <PerfectScrollbar style={{
                    position: "absolute",
                    left: 0,
                    right:0,
                    top: '66px',
                    height: 'calc(100% - 66px)'
                }}>
                    <div style={{
                        padding: '20px'
                    }}>
                        <h1><Translate id={"Company level"}/></h1>
                        <Button
                            style={{
                                display: 'block'
                            }}
                            type="button"
                            className={"mediumButton lightBlueButton roundButton uppercaseText"}
                            name={<Translate id="Save"/>}
                            onClick={e => {
                                this.props.saveUICustomization(this.state.uiCustomization);
                            }}
                        />
                        <Card>
                            <h2><Translate id={"Colors"}/></h2>
                            <table className={'configurationTable'}>
                                <tbody>
                                <tr>
                                    <td>
                                        <ColorPicker
                                            defaultValue={{
                                                color: new TinyColor(this.state.uiCustomization.basketHeader.color)
                                            }}
                                            animated={true}
                                            onChange={(picker) => {
                                                this.setState({
                                                    uiCustomization: {
                                                        ...this.state.uiCustomization,
                                                        basketHeader: {
                                                            ...this.state.uiCustomization.basketHeader,
                                                            color: picker.color.toHexString()
                                                        }
                                                    }
                                                });
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Translate id={'Header'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ColorPicker
                                            defaultValue={{
                                                color: new TinyColor(this.state.uiCustomization.colors.mainBackground)
                                            }}
                                            animated={true}
                                            onChange={(picker) => {
                                                this.setState({
                                                    uiCustomization: {
                                                        ...this.state.uiCustomization,
                                                        colors: {
                                                            ...this.state.uiCustomization.colors,
                                                            mainBackground: picker.color.toHexString()
                                                        }
                                                    }
                                                });
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Translate id={'Background'}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Card>
                        <Card>
                            <h2><Translate id={"screen saver"}/></h2>
                            <table className={'configurationTable'}>
                            <tbody>
                                <tr>
                                    <td className={'capitalizeFirst'}><Translate id={"image"}/></td>
                                    <td>
                                        <select
                                            className={'fullWidth selectInput'}
                                            value={this.state.uiCustomization.screenSaver.image}
                                            onChange={(event) => {
                                                this.setState({
                                                    uiCustomization: {
                                                        ...this.state.uiCustomization,
                                                        screenSaver: {
                                                            ...this.state.uiCustomization.screenSaver,
                                                            image: event.target.value
                                                        }
                                                    }
                                                })
                                            }}
                                        >
                                            <option value={'default'}>{this.props.translate("default")}</option>
                                            <option value={'invoiceLogo'}>{this.props.translate("company logo")}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'capitalizeFirst'}><Translate id={"text"}/></td>
                                </tr>
                                {
                                    this.props.languages.map((el, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{el}</td>
                                                <td>
                                                    <input type="text" value={this.state.uiCustomization.screenSaver.text[el] || ''} onChange={(event) => {
                                                        this.setState({
                                                            uiCustomization: {
                                                                ...this.state.uiCustomization,
                                                                screenSaver: {
                                                                    ...this.state.uiCustomization.screenSaver,
                                                                    text: {
                                                                        ...this.state.uiCustomization.screenSaver.text,
                                                                        [el]: event.target.value
                                                                    }
                                                                }
                                                            }
                                                        });
                                                    }} />
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                        </Card>
                        <Card>
                            <h2><Translate id={"Fonts"}/></h2>
                            <table className={'configurationTable'}>
                            <tbody>
                                <tr>
                                    <td colSpan={2} className={'capitalizeFirst'}><Translate id={"quick select products"}/></td>
                                </tr>
                                <tr>
                                    <td className={'capitalizeFirst'}><Translate id={"size"}/></td>
                                    <td>
                                        <input type="text" value={this.state.uiCustomization.fonts.quickPosProducts.size} onChange={(event) => {
                                            this.setState({
                                                uiCustomization: {
                                                    ...this.state.uiCustomization,
                                                    fonts: {
                                                        ...this.state.uiCustomization.fonts,
                                                        quickPosProducts: {
                                                            size: event.target.value
                                                        }
                                                    }
                                                }
                                            });
                                        }}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </Card>
                        <Card>
                            <h2 className={'capitalizeFirst'}><Translate id={"status bar steps"}/></h2>
                            <table className={'configurationTable'}>
                                <tbody>
                                <tr>
                                    <td className={'capitalizeFirst'}><Translate id={"display"}/></td>
                                    <td>
                                        <CheckBox checked={this.state.uiCustomization.statusBarSteps.display === 1} name={'displayStatusBarStepsCheck'} onChange={(checked) => {
                                            this.setState({
                                                uiCustomization:{
                                                    ...this.state.uiCustomization,
                                                    statusBarSteps: {
                                                        ...this.state.uiCustomization.statusBarSteps,
                                                        display: checked ? 1 : 0
                                                    }
                                                }
                                            });
                                        }}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Card>
                        <div style={{
                            clear:"both",
                            borderBottom: "1px solid rgb(237, 237, 237)",
                            margin: "0 -20px 40px -20px"
                        }}/>
                        <h1><Translate id={"Point of sale level"}/></h1>
                        <Button
                            style={{
                                display: 'block'
                            }}
                            type="button"
                            className={"mediumButton lightBlueButton roundButton uppercaseText"}
                            name={<Translate id="Save"/>}
                            onClick={e => {
                                this.props.savePOSUICustomization(this.state.uiCustomizationPOS);
                            }}
                        />
                        <Card>
                            <h2 className={'capitalizeFirst'}><Translate id={"payment methods"}/></h2>
                            <table className={'configurationTable'}>
                                <tbody>
                                    {Object.keys(this.state.uiCustomizationPOS.paymentMethods).map((mop,index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <CheckBox checked={this.state.uiCustomizationPOS.paymentMethods[mop] === 1} name={'paymentMethodCheck' + mop} onChange={(checked) => {
                                                        let newState = {
                                                            uiCustomizationPOS:{
                                                                ...this.state.uiCustomizationPOS,
                                                                paymentMethods: {
                                                                    ...this.state.uiCustomizationPOS.paymentMethods
                                                                }
                                                            }
                                                        };
                                                        newState.uiCustomizationPOS.paymentMethods[mop] = checked ? 1 : 0;
                                                        this.setState(newState);
                                                    }}/>
                                                </td>
                                                <td className={'capitalizeFirst'}><Translate id={mop}/></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </Card>
                        <Card>
                            <h2 className={'capitalizeFirst'}><Translate id={"shopping bag"}/></h2>
                            <table className={'configurationTable'}>
                                <tbody>
                                <tr>
                                    <td className={'capitalizeFirst'}><Translate id={"display"}/></td>
                                    <td>
                                        <CheckBox checked={this.state.uiCustomizationPOS.shoppingBag.display === 1} name={'displayShoppingBagCheck'} onChange={(checked) => {
                                            this.setState({
                                                uiCustomizationPOS:{
                                                    ...this.state.uiCustomizationPOS,
                                                    shoppingBag: {
                                                        ...this.state.uiCustomizationPOS.shoppingBag,
                                                        display: checked ? 1 : 0
                                                    }
                                                }
                                            });
                                        }}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={'capitalizeFirst'}><Translate id={"Item code"}/></td>
                                    <td>
                                        <input type="text" value={this.state.uiCustomizationPOS.shoppingBag.code} onChange={(event) => {
                                            this.setState({
                                                uiCustomizationPOS: {
                                                    ...this.state.uiCustomizationPOS,
                                                    shoppingBag: {
                                                        ...this.state.uiCustomizationPOS.shoppingBag,
                                                        code: event.target.value
                                                    }
                                                }
                                            });
                                        }}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Card>
                        <Card>
                            <h2 className={'capitalizeFirst'}><Translate id={"Printing"}/></h2>
                            <table className={'configurationTable'}>
                                <tbody>
                                <tr>
                                    <td className={'capitalizeFirst'}><Translate id={"Print option turned on"}/></td>
                                    <td>
                                        <CheckBox checked={this.state.uiCustomizationPOS.printing.printDefault === 1} name={'printDefault'} onChange={(checked) => {
                                            this.setState({
                                                uiCustomizationPOS:{
                                                    ...this.state.uiCustomizationPOS,
                                                    printing: {
                                                        ...this.state.uiCustomizationPOS.printing,
                                                        printDefault: checked ? 1 : 0
                                                    }
                                                }
                                            });
                                        }}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Card>
                    </div>
                </PerfectScrollbar>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUI);