import React, {useState} from "react";
import Translate from "localization/Translate";
import Button from "../../buttons/Button";
import InputWithKeyboard from "../../formElements/InputWithKeyboard";
import {getPaybackConf, isCouponCode} from "./util";
import paybackCouponImg from "./img/coupon.png";
import {useActionListener} from "redux-action-listener-hook";
import {addErrorAlert} from "../../redux/actions";

const validateCouponCode = (coupon) => {
    return coupon.length === 13;
}

export default function (props) {
    const [inputValue, setInputValue] = useState('');
    const [addedCoupons, setAddedCoupons] = useState([]);

    const addCouponToTransaction = (scannedCode, clearInput=true) => {
        if(props.coupons.indexOf(scannedCode) !== -1 || addedCoupons.indexOf(scannedCode) !== -1){
            setValidationError(<Translate id={'Coupon is already in the transaction!'}/>);
        }else{
            setAddedCoupons([...addedCoupons, scannedCode]);
            setValidationError(false);
            props.onEnterAnother(scannedCode);
            if(clearInput){
                setInputValue('');
            }
        }
    }

    useActionListener('SCANNER_DETECTED', (dispatch, action) => {
        console.log({useActionListener: action});
        const scannedCode = action.payload;
        if(isCouponCode(scannedCode)){
            addCouponToTransaction(scannedCode, false);
        }else{
            dispatch(addErrorAlert('Not a proper Payback coupon number!'));
        }
        setInputValue('');
    });

    const [validationError, setValidationError] = useState(false);
    let validationErrorDisplay;
    if(validationError !== false){
        validationErrorDisplay = (
            <span style={{
                fontSize: 14
            }} className={'errorMessage'}>{validationError}</span>
        );
    }

    let keyboardLayout = "numbers";
    let validationErrorTitle = "Not a proper Payback coupon number!";
    let backButton;
    if(props.onBack !== undefined){
        backButton = (
            <div onClick={props.onBack} style={{marginTop: '10px', marginBottom: '10px', float: 'left'}}>
                <Button type="button" className={"largeButton grayButton"} name={<Translate id="back"/>}
                        arrow="left" flencheClass={"flencheSmallLeftBottom grayFlenche"}/>
            </div>
        );
    }

    const paybackConf = getPaybackConf();

    const onInputDone = (value) => {
        if(validateCouponCode(paybackConf.couponPrefix + value)){
            addCouponToTransaction(paybackConf.couponPrefix + value);
        }else {
            if(isCouponCode(value)){
                addCouponToTransaction(value);
            }else{
                setValidationError(<Translate id={validationErrorTitle}/>);
            }
        }
    };

    return (
        <div>
            <img src={paybackCouponImg} style={{
                width: 100,
                position: "absolute",
                top: 20,
                right: 50
            }} alt="paybackCouponImg"/>
            <div style={{
                padding: "10px",
                paddingTop: "180px"
            }}>
                <span className={"boldUppercaseText"} style={{
                    fontSize: 24
                }}>
                    { <Translate id={"Add the Payback print coupon code"}/> }
                </span>
                <br/>
                <InputWithKeyboard
                    beforeInput={<div style={{
                        float: "left",
                        marginTop: "5px",
                        padding: "12px 10px 11px 10px",
                        boxSizing: "border-box",
                        fontSize: "20px",
                        width: "100px",
                        backgroundColor: "#d9d9d9",
                        borderRadius: "10px 0 0 10px"
                    }}>{paybackConf.couponPrefix}</div>}
                    inputClass={""}
                    inputStyle={{
                        boxSizing: "border-box",
                        width: "calc(100% - 100px)"
                    }}
                    inputLabel={"Enter the Payback print coupon code"}
                    layout={keyboardLayout}
                    onInputChange={setInputValue}
                    initialInputValue={inputValue}
                    onDone={onInputDone}
                    controlled={true}
                />

                { validationErrorDisplay }
                <div style={{clear: "both"}}> </div>
                { backButton }
                <div onClick={() => {
                    onInputDone(inputValue);
                }} style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    float: 'right'
                }}>
                    <Button
                        type="button"
                        className={"largeButton fullWidth blueButton"}
                        name={<Translate id="add coupon"/>}
                        arrow={'right'}
                        style={{
                            minWidth: '240px'
                        }}
                    />
                </div>
                <div style={{clear: "both"}}> </div>
                <div onClick={() => {
                    props.onDone();
                }} style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    float: 'right',
                    minWidth: '240px'
                }}>
                    <Button
                        type="button"
                        className={"largeButton fullWidth yellowButton"}
                        name={<Translate id="payment"/>}
                        arrow={'right'}
                        style={{
                            minWidth: '240px'
                        }}
                    />
                </div>
            </div>
        </div>
    );
}