import React from "react";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../../uiComponents/WrappedPerfectScrollBar';
import ProductLine from "./ProductLine";
import Translate from "localization/Translate";

export default function ({listItems, setListItems, marginTop}) {
    return (
        <div id={"productList"} style={{
            display: "contents"
        }}>
            <PerfectScrollbar style={{
                position: "absolute",
                left: 0,
                right:0,
                top: marginTop || "60px",
                bottom: 0,
                height: "auto"
            }}>
                <table className={"responsiveTable bold borderBottomTd documentTable"}>
                    <thead style={{
                        fontSize: "20px",
                        borderBottom: "2px solid rgb(235, 235, 235)"
                    }}>
                    <tr>
                        <th><input type={"checkbox"} onChange={ (e) => {
                            setListItems(listItems.map(item => {
                                item.checked = e.target.checked;
                                return item;
                            }));
                        }}/></th>
                        <th><Translate id={"Product"}/></th>
                        <th><Translate id={"Item code"}/></th>
                        <th><Translate id={"Quantity"}/></th>
                        <th><Translate id={"Total"}/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        listItems.map((item, index) => {
                            return <ProductLine
                                key={index}
                                item={item}
                                onCheckToggle={(checked) => {
                                    setListItems(listItems.map((listItem, listItemIndex) => {
                                        if(listItemIndex === index){
                                            listItem.checked = checked;
                                        }
                                        return listItem;
                                    }));
                                }}
                            />
                        })
                    }
                    </tbody>
                </table>
            </PerfectScrollbar>
        </div>
    );
}