import React, { Component } from 'react';
import './App.css';
import './fonts/roboto.css';
import {applyMiddleware} from 'redux';
import {Provider} from "react-redux";
import pluginMiddleware from "./redux/plugin/middleware";
import rootReducer from "./redux/reducers/rootReducer";
import paymentReducer from "./redux/payment/reducer";
import apiMiddleware from "./redux/middleware/api";
import epsiMiddleware from "./redux/middleware/epsi";
import {SET_INTEGRATION} from "./redux/payment/actionTypes";
import paymentMiddleware from "./redux/payment/middleware";
import rfidMiddleware from "./redux/rfid/middleware";
import actionsMiddleware from "./redux/middleware/actions";
import customerAPIMiddleware from "./redux/middleware/customerAPI";
import MainLocalized from "./Main"
import ManagerAppProvider from "./communication/ManagerAppProvider";
import DynamicStyles from "./DynamicStyles";
import { createMiddleware } from 'redux-action-listener-hook';
import thunk from "redux-thunk";
import {middleware as erplyInstallerMiddleware} from "./redux/erplyInstaller/main";
import {addTranslations} from "./login/configurationLoader";
import CacheBuster from "./util/CacheBuster";
import localizeSlice from "localization/localizeSlice";
import {Loader} from "./main/Loading";
import {configureStore} from "@reduxjs/toolkit";
import {TailSpin} from "react-loader-spinner";

export const createStore = (preloadedState=false) => {
    const options = {
        reducer: {
            localize: localizeSlice,
            rootReducer: rootReducer,
            payment: paymentReducer
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false
        }),
        enhancers: [
            applyMiddleware(
                pluginMiddleware,
                apiMiddleware,
                actionsMiddleware,
                epsiMiddleware,
                paymentMiddleware,
                rfidMiddleware,
                customerAPIMiddleware,
                createMiddleware(),
                thunk,
                erplyInstallerMiddleware
            )
        ]
    }
    if(preloadedState !== false){
        options.preloadedState = preloadedState;
    }
    return  configureStore(options);
}
export const initApp = () => {
    const store = createStore();

    let initialLanguages = window?.AppConf?.languages ?? ["en"];

    addTranslations(store.dispatch, initialLanguages);

    const paymentIntegration = window.AppConf.EFT;
    store.dispatch({
        type: SET_INTEGRATION,
        payload: paymentIntegration
    });
    return store;
}

class App extends Component {
    constructor(props) {
        super(props);
        const store = initApp();
        this.state = {store};
    }

    render() {
        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        setTimeout(refreshCacheAndReload, 5000);
                        return <TailSpin
                            color="#0082C3"
                            height={100}
                            width={100}
                            wrapperStyle={{
                                position: "fixed",
                                left: "50%",
                                top: "50%",
                                margin: "-50px 0 0 -50px"
                            }}
                        />
                    }

                    return (
                        <Provider store={this.state.store}>
                            <ManagerAppProvider>
                                <DynamicStyles>
                                    <MainLocalized/>
                                </DynamicStyles>
                            </ManagerAppProvider>
                        </Provider>
                    );
                }}
            </CacheBuster>
        );
  }
}

export default App;
