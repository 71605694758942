import React from "react";
import StatusBar from "./statusBar/StatusBar";
import Alert from "./alerts/Alert";
import Loading from "./main/Loading";
import AppRouter from "./AppRouter";
import {useTrackConnectionStatus} from "./hooks/useTrackConnectionStatus";

const Main = () => {
    useTrackConnectionStatus();
    let className = process.env.REACT_APP_ERPLY_MODE === "1" ? 'AppErply' : 'App';
    return (
        <div className={className}>
            <StatusBar/>
            <Alert/>
            <Loading/>
            <AppRouter />
        </div>
    );
}

export default Main;
