import React from 'react';
import Translate from "localization/Translate";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../../../uiComponents/WrappedPerfectScrollBar';
import Button from "../../../../buttons/Button";
import RGFD from "./main";

export default function ({onAskAssistance, data, onEdit}) {
    let someFailed = data.filter(el => el.status !== 'Pending' && el.status !== 'Success').length > 0;
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: '30px',
            left: '20px',
            overflow: 'hidden'
        }}>
            <h1 className={"capitalizeFirst"} style={{
                textAlign: 'center',
                width: '100%'
            }}>
                <Translate id={'activating giftcards'}/>
            </h1>
            <div style={{
                margin: '1px 0 0',
                padding: '0',
                position: 'relative',
                overflowY: 'scroll',
                scrollBehavior: 'smooth',
                width: 'calc(100% + 17px)',
                boxSizing: 'content-box',
                flexGrow: 1
            }}>
                <PerfectScrollbar style={{
                    position: "absolute",
                    left: 0,
                    right:0,
                    top: 0,
                    bottom: 0
                }}>
                    <table
                        className="responsiveTable borderBottomTd"
                        id="RGFD-credit-rows"
                    >
                        <thead>
                        <tr style={{
                            borderBottom: '1px solid #ebebeb'
                        }}>
                            <th className={'capitalizeFirst'}><Translate id={'card number'}/></th>
                            <th className={'capitalizeFirst'}><Translate id={'credit amount'}/></th>
                            <th className={'capitalizeFirst'}><Translate id={'status'}/></th>
                            <th> </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((creditResult, index) => {
                                let style = creditResult.retry == 0 ? {display: 'none'} : {};
                                return (
                                    <tr key={index} id={"creditRow_" + creditResult.cardNumber}>
                                        <td>
                                            {creditResult.cardNumber}
                                            <button
                                                style={{
                                                    ...style,
                                                    marginLeft: '20px'
                                                }}
                                                className="button mediumButton blueButton"
                                                onClick={() => {
                                                    onEdit(creditResult);
                                                }}
                                            >
                                                {RGFD.translate('edit')}
                                            </button>
                                        </td>
                                        <td>{creditResult.chargedAmount}</td>
                                        <td>
                                            <input type="hidden" name="done" value={creditResult.done}/>
                                            {creditResult.status}
                                        </td>
                                        <td style={style}>
                                            <button
                                                className="button mediumButton blueButton"
                                                onClick={() => {
                                                    RGFD.API.resendCredit(creditResult.cardNumber, creditResult.chargedAmount, creditResult.externalActionID);
                                                }}
                                            >
                                                {RGFD.translate('retry')}
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </PerfectScrollbar>
            </div>
            <div style={someFailed ? {} : {display: 'none'}}>
                <h3 className={'capitalizeFirst'}><Translate id={'please call assistance if activation fails'}/></h3>
                <Button
                    type="button"
                    style={{
                        marginTop: '20px',
                        marginBottom: '20px',
                        alignSelf: 'center'
                    }}
                    className={"largeButton blueButton"}
                    name={<Translate id={'Ask for assistance'}/>}
                    flencheClass={"flencheRight transparentFlenche"}
                    arrow="right"
                    onClick={e => {
                        onAskAssistance();
                    }}
                />
            </div>
        </div>
    );
}