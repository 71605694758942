import React, {Component, useState} from 'react';
import "../main.css"
import Translate from "localization/Translate";
import {getTranslate} from "localization/localizeSlice";
import {connect} from "react-redux";
import ImageBox from "../../buttons/ImageBox";
import iconEditUIErply from "../images/Erply/editUI.png";
import iconEditProduct from "../images/edit.png";
import iconEditProductErply from "../images/Erply/addNotes.png";
import {
    closeQCO,
    changeAdministrationView,
    showDialogue,
    saveSale,
    changeTransactionMode,
    closeAdministration,
    POSCloseDay,
    getPointOfSaleDayTotals,
    getCloseDayHtmlReport,
    openModal,
    getRecentSales,
    getPendingSales,
    printReceipt,
    addInfoAlert,
    showInputDialogue,
    sendEmailReceipt,
    addErrorAlert,
    removeAlert,
    closeModal,
    findProduct,
    getUserRights,
    openCashDrawer,
    setEditQuickProducts,
    setLoading,
    getSalesDocument,
    findProducts,
    addProduct,
    setReturnBaseDocument,
    getPayments,
    changeOperationMode,
    initTransaction,
    openAdministrationView,
    setPendingDocument,
    getAppliedPromotionRecords,
    initPOS,
    cancelTransaction,
    setCustomer,
    deleteSalesDocument,
    getActiveManualCampaigns,
    getInvoices,
    setInvoice,
    changeMode,
    setDefaultCustomer,
    deleteSalesDocuments
} from "../../redux/actions";
import * as uuid from "uuid";
import Button from "../../buttons/Button";
import {
    EMAIL_NOT_SENT,
    EMAIL_SENT,
    POS_DAY_CLOSE_FAILED,
    POS_DAY_CLOSE_SUCCESS,
    SET_USER
} from "../../redux/actionTypes";
import DocumentList from "../lists/document/DocumentList";
import PromotionList from "../lists/promotion/List";
import Keyboard from "../../keyboard/Keyboard";
import VoucherInput from "../VoucherInput";
import { AVMB } from "../../util/voucher";
import { PerfectScrollbarWithHotfix as PerfectScrollbar } from '../../uiComponents/WrappedPerfectScrollBar';
import ReturnDocument from "./ReturnDocument";
import Product from "../../redux/dto/product";
import ImageBoxErply from "../../buttons/ImageBoxErply";
import Header from "./components/Header";
import {calculateVatPrice, displayPrice, isCashierModeForced} from "../../util/helperFunctions";
import ChooseOperationMode from "../../login/ChooseOperationMode";
import {getReasonCodeByID, getReturnBaseDocument, mixedTransactions} from "../../redux/selectors";
import CheckGiftCard from "./CheckGiftCard";
import RFIDService from "../../redux/rfid/RFIDService";
import CustomerDTO from "../../redux/dto/customer";

const showXReport = function (posID, warehouseID, onClose, theme) {
    return getCloseDayHtmlReport(posID, warehouseID, function (reportData) {
        let reportModalID = uuid.v4();

        return openModal({
            content:
                <div style={{
                    position: "absolute",
                    left: 0,
                    right:0,
                    top: 0,
                    bottom: 0
                }}>
                    {
                        theme === 'Erply' ?
                            <div>
                                <Header title={'X-report'}>
                                    <div onClick={e => window.frames["x-report"].print()} style={{
                                        marginTop: '4px',
                                        marginLeft: '40px'
                                    }}>
                                        <Button
                                            type="button"
                                            className={"mediumButton lightBlueButton roundButton uppercaseText"}
                                            name={<Translate id="Print"/>}
                                        />
                                    </div>
                                </Header>
                                <div style={{
                                    width: "100%",
                                    marginTop: '75px'
                                }}/>
                            </div>
                            :
                            <div>
                                <h1 style={{marginLeft: '20px', float: 'left'}}><Translate id={'X-report'}/></h1>
                                <div onClick={e => window.frames["x-report"].print()} style={{
                                    float: 'left',
                                    marginTop: '16px',
                                    marginLeft: '40px'
                                }}>
                                    <Button type="button" className={"mediumButton blueButton"} name={<Translate id="Print"/>}
                                            arrow="left" flencheClass={"flencheSmallLeftBottom blueFlenche"}/>
                                </div>
                                <div style={{
                                    width: "100%",
                                    borderTop: "1px solid gray",
                                    marginTop: '75px'
                                }}/>
                            </div>
                    }
                    <iframe title={'x-report'} name={'x-report'} srcDoc={reportData.htm} style={{
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        border: "none"
                    }}/>
                </div>,
            id: reportModalID,
            className: "Administration",
            onClose: onClose,
            canClose: true
        });

    }, function () {
        return {
            type: 'GET_X_REPORT_FAILED'
        }
    });
};

const addReturnProducts = function (salesDocument, payments, selectedRows, dispatch, warehouseID, pointOfSaleID) {
    dispatch(setLoading(true));
    let productIDs = selectedRows.reduce((acc, row) => {
        acc.push(row.productID);
        return acc;
    }, []);
    dispatch(changeTransactionMode('RETURN', true));
    dispatch(findProducts({productIDs: productIDs.join(','), recordsOnPage: 1000}, warehouseID, pointOfSaleID, result => {
        console.log({result});
        let productByID = result.reduce((acc, product) => {
            acc[product.productID] = product;
            return acc;
        }, {});

        selectedRows.map(row => {
            let product = productByID[row.productID];
            if(typeof product !== "undefined"){
                let productDTO = new Product(row.itemName, row.finalNetPrice, row.finalPriceWithVAT, row.amount, product);
                productDTO.baseDocumentRow = row;
                productDTO.manualPrice = true;
                dispatch(addProduct(productDTO));
            }
        });
        dispatch(setLoading(false));
        return setReturnBaseDocument(salesDocument, payments);
    }, result => {
        return addErrorAlert('Could not get product info from API');
    }));
};

const addPendingProducts = function (salesDocument, promotionRecords, dispatch, warehouseID, pointOfSaleID, reasonCodes) {
    let productIDs = salesDocument.rows.reduce((acc, row) => {
        acc.push(row.productID);
        return acc;
    }, []);

    if(productIDs.length === 0){
        return;
    }
    dispatch(setLoading(true));
    dispatch(findProducts({productIDs: productIDs.join(','), recordsOnPage: 1000}, warehouseID, pointOfSaleID, result => {
        console.log({result});
        let productByID = result.reduce((acc, product) => {
            acc[product.productID] = product;
            return acc;
        }, {});

        let RFIDTags = RFIDService.isAvailable() ? RFIDService.getTagsFromDocumentAttributes(salesDocument.attributes) : [];
        salesDocument.rows.map((row, index) => {
            let product = productByID[row.productID];
            if(typeof product !== "undefined"){
                let rowPriceWithVAT = calculateVatPrice(row.price, product.vatrate);
                let productDTO = new Product(row.itemName, row.price, rowPriceWithVAT, row.amount, structuredClone(product));
                if(RFIDService.isAvailable() && RFIDTags.length > 0){
                    productDTO.data.RFIDTag = RFIDTags.find(el => el.NUM_LINE === (index + 1));
                }
                if(parseFloat(row.discount) !== 0){
                    productDTO.setManualDiscountPercentage(row.discount);
                }
                let promotionRecord = promotionRecords.find(el => parseInt(el.stableRowID) === parseInt(row.stableRowID));
                if(typeof promotionRecord !== "undefined"){
                    if(promotionRecord.manualDiscountReasonID !== "0"){
                        productDTO.manualDiscountReasonCodeID = promotionRecord.manualDiscountReasonID;
                    }
                }
                if(typeof row.returnReasonID !== "undefined" && row.returnReasonID !== "0"){
                    productDTO.returnReasonID = row.returnReasonID;
                    productDTO.returnReason = getReasonCodeByID(reasonCodes, row.returnReasonID);
                }
                dispatch(addProduct(productDTO, salesDocument));
            }
        });
        dispatch(setLoading(false));
        return setPendingDocument(salesDocument);
    }, result => {
        dispatch(setLoading(false));
        return addErrorAlert('Could not get product info from API');
    }));
};
const setPendingDocumentCustomer = (salesDocument, dispatch) => {
    if(typeof salesDocument.attributes !== "undefined"){
        let customerAttribute = salesDocument.attributes.find(el => el.attributeName === "customer");
        if(typeof customerAttribute === "undefined"){
            customerAttribute = salesDocument.longAttributes.find(el => el.attributeName === "customer");
        }
        if(typeof customerAttribute !== "undefined"){
            const customer = JSON.parse(customerAttribute.attributeValue);
            let customerDTO = new CustomerDTO();
            Object.assign(customerDTO, customer);
            dispatch(setCustomer(customerDTO));
        }
    }
}

const RecentSales = ({
    documents,
    theme,
    dateFormat,
    returnBaseDocument,
    dispatch,
    recentSalesModalID,
    posID,
    warehouseID,
    onClose,
    initialInputValue
}) => {
    const [inputValue, setInputValue] = useState(initialInputValue);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            dispatch(showRecentSales(posID, warehouseID, onClose, dateFormat, dispatch, recentSalesModalID, returnBaseDocument, theme, inputValue));
        }
    }

    return <div style={{
        position: "absolute",
        left: 0,
        right:0,
        top: 0,
        bottom: 0
    }}>
        {
            theme === 'Erply' ?
                <div>
                    <Header title={'Recent sales'}/>
                    <div style={{
                        marginTop: '65px',
                        padding: '20px'
                    }}>
                        <label style={{
                            marginLeft: '15px',
                            color: '#808080',
                            fontSize: '12px'
                        }}>
                            <Translate id={'Search'}/>
                        </label>
                        <input
                            type={"text"}
                            name={'recentSaleSearch'}
                            className={"searchInputErply underlineInput"}
                            value={inputValue}
                            autoComplete={'off'}
                            onChange={(e) => {
                                setInputValue(e.target.value);
                            }}
                            onKeyDown={handleKeyDown}
                            autoFocus={true}
                        />
                    </div>
                </div>
                :
                <div>
                    <h1 style={{marginLeft: '20px', float: 'left'}}><Translate id={'Recent sales'}/></h1>
                    <input type={"text"} name={'recentSaleSearch'}
                           className={"searchInput underlineInput"}
                           value={inputValue}
                           autoComplete={'off'}
                           onChange={(e) => {
                               setInputValue(e.target.value);
                           }}
                           onKeyDown={handleKeyDown}
                           autoFocus={true}
                    />
                    <div style={{
                        width: "100%",
                        borderTop: "1px solid gray"
                    }}/>
                </div>
        }
        <DocumentList
            id="recentSaleList"
            documents={documents}
            dateFormat={dateFormat}
            printReceipt={(document, giftReceipt = false) => {
                dispatch(addInfoAlert('Printing receipt', false));
                dispatch(printReceipt(document.receiptLink, giftReceipt, true, document, () => {
                    dispatch(removeAlert());
                }));
            }}
            sendEmailReceipt={(document) => {
                dispatch(showInputDialogue('Type email', '', 'Send receipt to email', (email) => {
                    dispatch(addInfoAlert('Sending email', false));
                    dispatch(sendEmailReceipt(document.id, email, function () {
                        dispatch(addInfoAlert('Email sent!'));
                        return {
                            type: EMAIL_SENT
                        }
                    }, function () {
                        dispatch(addErrorAlert('Sending email failed!'));
                        return {
                            type: EMAIL_NOT_SENT
                        }
                    }, document));
                }, undefined, undefined, undefined, 'zIndex6'))
            }}
            refundReceipt={(document) => {
                if(returnBaseDocument !== false){
                    dispatch(addErrorAlert('Return already in progress'));
                    return;
                }
                dispatch(setLoading(true));
                dispatch(getSalesDocument(document.id, (salesDocument) => {
                    return getPayments(document.id, (payments) => {
                        dispatch(setLoading(false));
                        console.log({salesDocument, payments});
                        let refundModal = uuid.v4();
                        return openModal({
                            content:
                                <ReturnDocument
                                    document={salesDocument}
                                    onDone={(selectedProducts) => {
                                        console.log({selectedProducts});
                                        if(selectedProducts.length === 0){
                                            dispatch(addErrorAlert('Please select at least one item'))
                                        }else{
                                            addReturnProducts(salesDocument, payments, selectedProducts, dispatch, warehouseID, posID);
                                            dispatch(closeModal(refundModal));
                                            dispatch(closeModal(recentSalesModalID));
                                            dispatch(closeAdministration());
                                        }
                                    }}
                                />,
                            id: refundModal,
                            className: "Administration",
                            onClose: () => {},
                            canClose: true
                        });
                    }, () => {
                        dispatch(setLoading(false));
                    });
                }, () => {
                    dispatch(setLoading(false));
                }, {nonReturnedItemsOnly: 1}));
            }}
        />
        <div style={{
            "position": "absolute",
            "bottom": "0",
            "left": "0",
            "right": "0"
        }}>
            <Keyboard options={{
                layout: 'numbers',
                currentValue: inputValue,
                updateValue: (value) => {
                    setInputValue(value);
                },
                onDone: function () {
                    dispatch(showRecentSales(posID, warehouseID, onClose, dateFormat, dispatch, recentSalesModalID, returnBaseDocument, theme, inputValue));
                }
            }}/>
        </div>
    </div>
}

const showRecentSales = function (posID, warehouseID, onClose, dateFormat, dispatch, lastRecentSalesModalID, returnBaseDocument, theme, initialInputValue='') {
    dispatch(setLoading(true));
    return getRecentSales(posID, warehouseID, function (documents) {
        dispatch(setLoading(false));
        if(lastRecentSalesModalID !== undefined){
            dispatch(closeModal(lastRecentSalesModalID));
        }

        let recentSalesModalID = uuid.v4();

        return openModal({
            content: <RecentSales
                documents={documents}
                theme={theme}
                dateFormat={dateFormat}
                returnBaseDocument={returnBaseDocument}
                dispatch={dispatch}
                recentSalesModalID={recentSalesModalID}
                posID={posID}
                warehouseID={warehouseID}
                onClose={onClose}
                initialInputValue={initialInputValue}
            />,
            id: recentSalesModalID,
            className: "Administration",
            onClose: onClose,
            canClose: true
        });

    }, function () {
        dispatch(setLoading(false));
        return {
            type: 'GET_RECENT_SALES_FAILED'
        }
    },initialInputValue);
};

const showPendingSales = function (posID, warehouseID, onClose, dateFormat, dispatch, pendingDocument, reasonCodes) {
    if(pendingDocument !== false){
        return addErrorAlert("Pending sale already open!");
    }
    dispatch(setLoading(true));
    return getPendingSales(posID, warehouseID, function (documents) {
        dispatch(setLoading(false));
        let pendingSalesModalID = uuid.v4();

        return openModal({
            content:
                <div style={{
                    position: "absolute",
                    left: 0,
                    right:0,
                    top: 0,
                    bottom: 0,
                    height: "100%"
                }}>
                    <div>
                        <h1 style={{marginLeft: '20px', float: 'left'}}><Translate id={'Pending sales'}/></h1>
                        <div style={{
                            width: "100%",
                            borderTop: "1px solid gray"
                        }}/>
                    </div>
                    <DocumentList
                        id="pendingSaleList"
                        documents={documents}
                        dateFormat={dateFormat}
                        printReceipt={false}
                        sendEmailReceipt={false}
                        refundReceipt={false}
                        deleteReceipt={(document) => {
                            dispatch(setLoading(true));
                            dispatch(closeModal(pendingSalesModalID));
                            dispatch(deleteSalesDocument(document.id, (response) => {
                                dispatch(setLoading(false));
                                return showPendingSales(posID, warehouseID, onClose, dateFormat, dispatch, pendingDocument, reasonCodes);
                            }, (errorMessage) => {
                                dispatch(addErrorAlert(errorMessage));
                                return setLoading(false);
                            }));
                        }}
                        onClick={(document) => {
                            dispatch(setLoading(true));
                            dispatch(getSalesDocument(document.id, (salesDocument) => {
                                return getAppliedPromotionRecords(document.id, (promotionRecords) => {
                                    dispatch(setLoading(false));
                                    console.log({salesDocument, promotionRecords});
                                    dispatch(cancelTransaction());
                                    setTimeout(() => {
                                        addPendingProducts(salesDocument, promotionRecords, dispatch, warehouseID, posID, reasonCodes);
                                        setPendingDocumentCustomer(salesDocument, dispatch);
                                        dispatch(closeModal(pendingSalesModalID));
                                    },1);
                                    return closeAdministration();
                                }, () => {
                                    dispatch(setLoading(false));
                                });
                            }, () => {
                                dispatch(setLoading(false));
                            }, {}));
                        }}
                    />
                </div>,
            id: pendingSalesModalID,
            className: "Administration",
            onClose: onClose,
            canClose: true
        });

    }, function () {
        dispatch(setLoading(false));
        return {
            type: 'GET_PENDING_SALES_FAILED'
        }
    });
};

const showInvoices = function (dispatch, invoice, posID, dateFormat) {
    if(invoice !== false){
        return addErrorAlert("Invoice already open!");
    }
    dispatch(setLoading(true));
    return getInvoices(posID, (documents) => {
        dispatch(setLoading(false));
        let invoicesModalID = uuid.v4();

        return openModal({
            content:
                <div style={{
                    position: "absolute",
                    left: 0,
                    right:0,
                    top: 0,
                    bottom: 0,
                    height: "100%"
                }}>
                    <div>
                        <h1 style={{marginLeft: '20px', float: 'left'}}><Translate id={'Invoices'}/></h1>
                        <div style={{
                            width: "100%",
                            borderTop: "1px solid gray"
                        }}/>
                    </div>
                    <DocumentList
                        id="invoiceList"
                        documents={documents}
                        dateFormat={dateFormat}
                        printReceipt={false}
                        sendEmailReceipt={false}
                        refundReceipt={false}
                        onClick={(document) => {
                            dispatch(setInvoice(document));
                            dispatch(closeModal(invoicesModalID));
                            dispatch(closeAdministration());
                            dispatch(setDefaultCustomer());
                            dispatch(changeMode("payment"));
                        }}
                    />
                </div>,
            id: invoicesModalID,
            className: "Administration",
            onClose: () => {},
            canClose: true
        });

    }, function () {
        dispatch(setLoading(false));
        return {
            type: 'GET_INVOICES_FAILED'
        }
    });
};

const showPromotions = function (dispatch, warehouseID) {
    dispatch(setLoading(true));
    return getActiveManualCampaigns(warehouseID, (campaigns) => {
        console.log({campaigns});
        dispatch(setLoading(false));
        let promotionsModalID = uuid.v4();

        return openModal({
            content:
                <div style={{
                    position: "absolute",
                    left: 0,
                    right:0,
                    top: 0,
                    bottom: 0,
                    height: "100%"
                }}>
                    <div>
                        <h1 style={{marginLeft: '20px', float: 'left'}}><Translate id={'Promotions'}/></h1>
                        <div style={{
                            width: "100%",
                            borderTop: "1px solid gray"
                        }}/>
                    </div>
                    <PromotionList
                        id="promotionList"
                        promotions={campaigns}
                    />
                </div>,
            id: promotionsModalID,
            className: "Administration",
            canClose: true,
            onClose: () => {}
        });

    }, function () {
        dispatch(setLoading(false));
        return {
            type: 'GET_CAMPAIGNS_FAILED'
        }
    });
};

const voucherInput = (dispatch, warehouseID, posID) => {
    let voucherModalID = uuid.v4();

    let voucherTypes = AVMB.vouchers.filter(voucherType => {
        return window.AppConf.manualVoucherInput.indexOf(voucherType.id) !== -1;
    });
    return openModal({
        content:
            <VoucherInput
                title={'Scan or enter voucher code'}
                voucherTypes={voucherTypes}
                onDone={(voucherType, parameters) => {
                    console.log('Voucher input: ', voucherType, parameters);
                    voucherType.useAction(parameters, (code) => {
                        console.log('useAction code:', code);
                        dispatch(setLoading(true));
                        dispatch(findProduct(code, warehouseID, posID, undefined, undefined, undefined, undefined, () => {
                            dispatch(setLoading(false));
                        }));
                        dispatch(closeModal(voucherModalID));
                    }, () => {
                        dispatch(addErrorAlert('This is not a correct voucher!'));
                    });
                }}
            />,
        id: voucherModalID,
        className: "Administration",
        onClose: () => {},
        canClose: true
    });
};

export const switchToReturnMode = (dispatch, userID, posID, hasProductsInBasket, mixedTransaction) => {
    if(hasProductsInBasket && !mixedTransaction){
        dispatch(addErrorAlert('Mixed transactions are not allowed'));
        return;
    }
    dispatch(setLoading(true));
    dispatch(getUserRights(userID, posID, (records) => {
        dispatch(setLoading(false));
        let userRights = records[0];
        if(typeof userRights === "undefined"){
            return addErrorAlert('Could not get user rights. Please try again or restart POS!');
        }

        if( userRights.rightMakePOSReturnsWithoutReceipt == 1 && userRights.rightMakePOSRefunds == 1){
            dispatch(changeTransactionMode('RETURN'));
            return closeAdministration();
        }else{
            return addErrorAlert('User has no right to perform this action!');
        }
    }, () => {
        dispatch(setLoading(false));
        return addErrorAlert('Could not get user rights!');
    }));
}

const mapStateToProps = state => {
    let administrationUser = structuredClone(state.rootReducer.administration.user);
    administrationUser.clientCode = state.rootReducer.user.clientCode;
    return {
        administrationUser,
        administration: state.rootReducer.administration,
        rootReducer: state.rootReducer,
        dateFormat: state.rootReducer.erplyConf.dateformat,
        translate: getTranslate(state),
        operationMode: state.rootReducer.operationMode,
        mode: state.rootReducer.mode,
        returnBaseDocument: getReturnBaseDocument(state),
        pendingDocument: state.rootReducer.pendingDocument,
        invoice: state.rootReducer.invoice,
        theme: state.rootReducer.theme,
        decathlonTheme: state.rootReducer.decathlonTheme,
        pluginAdminButtons: state.rootReducer.pluginAdminButtons,
        cashierMode: state.rootReducer.cashierMode,
        mixedTransaction: mixedTransactions(),
        enableManualPromotions: window.AppConf?.enableManualPromotions?.[state.rootReducer.cashierMode ? "cashier" : state.rootReducer.operationMode] ?? true
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeQCO: () => {dispatch(showDialogue('Close the application', 'closeSCOMessage', 'I confirm', function () {
            dispatch(closeQCO());
        }, function () {

        }, 'Cancel'));},
        openAddItemView: (mode) => {
            if(mode !== 'scan'){
                dispatch(addErrorAlert('Only available in scan mode!'));
                return;
            }
            dispatch(changeAdministrationView('addItem'));
        },
        openUnknownItemView: () => {
            dispatch(openAdministrationView('unknownItem', true, () => {}));
        },
        openAddNotesView: (mode) => {
            if(mode !== 'scan'){
                dispatch(addErrorAlert('Only available in scan mode!'));
                return;
            }
            dispatch(changeAdministrationView('addNotes'));
        },
        openCashInOutView: () => {dispatch(changeAdministrationView('cashInOut'));},
        saveSale: () => {
            dispatch(setLoading(true));
            dispatch(saveSale(() => {
                dispatch(setLoading(false));
                dispatch(showDialogue('Sale saved', '', 'Ok', () => {
                    dispatch(closeAdministration());
                    dispatch(initTransaction());
                }, undefined, undefined, false, undefined, undefined, true));
            },() => {
                dispatch(setLoading(false));
            }));
        },
        closeDay: (posID, warehouseID, dayID, translate, theme, employeeID) => {
            let doCloseDay = () => {
                dispatch(getPointOfSaleDayTotals(posID, function (dayTotals) {
                    let dayTotalsCash = dayTotals.filter(el => {return el.paymentType === "CASH"});
                    let expectedAmount = dayTotalsCash.length > 0 ? dayTotalsCash[0].expectedAmount : 0;

                    dispatch(showDialogue('Close day', translate('Cash expected') + ': ' + displayPrice(expectedAmount), 'End the day', function () {
                        dispatch(POSCloseDay(posID, dayID, expectedAmount, function () {
                            dispatch({
                                type: POS_DAY_CLOSE_SUCCESS
                            });
                            return showXReport(posID, warehouseID, function () {
                                dispatch(closeQCO());
                            }, theme);
                        }, function () {
                            dispatch(addErrorAlert("Can't close day!"));
                            return {
                                type: POS_DAY_CLOSE_FAILED
                            }
                        }, employeeID));
                    }, function () {}, 'Cancel'));

                    return {
                        type: 'GET_POS_DAY_TOTALS'
                    }
                }, function () {
                    dispatch(addErrorAlert("Can't close day!"));
                    return {
                        type: POS_DAY_CLOSE_FAILED
                    }
                }));
            }
            if(window?.AppConf?.removePendingSalesBeforeCloseDay ?? false){
                dispatch(getPendingSales(posID, warehouseID, (documents) => {
                    if(documents.length > 0){
                        return showDialogue("", "There are some pending sales. These will be deleted if you choose to continue with closing the day.", "Ok", () => {
                            let ids = documents.reduce((acc, el) => {
                                acc.push(el.id);
                                return acc;
                            }, []);
                            dispatch(deleteSalesDocuments(ids, () => {
                                return {
                                    type: 'DELETE_PENDING_SALES_SUCCESS'
                                }
                            }, () => {
                                return {
                                    type: 'DELETE_PENDING_SALES_FAILED'
                                }
                            }));
                            doCloseDay();
                        }, () => {
                            doCloseDay();
                        }, "Cancel", false, undefined, undefined, true);
                    }else{
                        doCloseDay();
                        return {
                            type: 'GET_PENDING_SALES_SUCCESS'
                        }
                    }
                }, () => {
                    doCloseDay();
                    return {
                        type: 'GET_PENDING_SALES_FAILED'
                    }
                }));
            }else{
                doCloseDay();
            }
        },
        switchToReturnMode: (userID, posID, hasProductsInBasket, mixedTransaction) => {
            switchToReturnMode(dispatch, userID, posID, hasProductsInBasket, mixedTransaction);
        },
        showRecentSales: (posID, warehouseID, onClose, dateFormat, returnBaseDocument, theme) => {
            dispatch(showRecentSales(posID, warehouseID, onClose, dateFormat, dispatch, undefined, returnBaseDocument, theme));
        },
        showPendingSales: (posID, warehouseID, onClose, dateFormat, pendingDocument, reasonCodes) => {
            dispatch(showPendingSales(posID, warehouseID, onClose, dateFormat, dispatch, pendingDocument, reasonCodes));
        },
        openInvoicePaymentView: (invoice, posID, dateFormat) => {
            dispatch(showInvoices(dispatch, invoice, posID, dateFormat));
        },
        showPromotions: (warehouseID) => {
            dispatch(showPromotions(dispatch, warehouseID));
        },
        showXReport: (posID, warehouseID, onClose, theme) => {
            dispatch(showXReport(posID, warehouseID, onClose, theme));
            dispatch({
                type: 'SHOW_X_REPORT'
            });
        },
        voucherInput: (warehouseID, posID) => {
            dispatch(voucherInput(dispatch, warehouseID, posID));
        },
        openCashDrawer: () => {
            dispatch(openCashDrawer());
        },
        editQuickProducts: () => {
            dispatch(setEditQuickProducts(true));
            dispatch(closeAdministration());
        },
        openEditUIView: () => {
            dispatch(changeAdministrationView('editUI'));
        },
        openPrinterConfiguration: () => {
            dispatch(changeAdministrationView('printerConfiguration'));
        },
        changeMode: (cashierMode, user) => {
            dispatch(closeAdministration());
            let operationModeModalID = uuid.v4();

            dispatch(openModal({
                content:
                    <ChooseOperationMode
                        onFullMode={() => {
                            if(cashierMode){
                                dispatch(initPOS());
                            }else {
                                dispatch(initTransaction());
                                dispatch(closeModal(operationModeModalID));
                            }
                            dispatch(changeOperationMode('full'));
                        }}
                        onLiteMode={() => {
                            if(cashierMode){
                                dispatch(initPOS());
                            }else{
                                dispatch(initTransaction());
                                dispatch(closeModal(operationModeModalID));
                            }
                            dispatch(changeOperationMode('lite'));
                        }}
                        onCashierMode={() => {
                            if(!cashierMode){
                                dispatch({type: SET_USER, payload: user});
                                dispatch(changeOperationMode('cashier'));
                            }
                            dispatch(initTransaction());
                            dispatch(closeModal(operationModeModalID));
                        }}
                    />,
                id: operationModeModalID,
                className: "Administration",
                onClose: function () {
                    //dispatch(closeQCO());
                },
                canClose: true
            }));
        },
        checkGiftCardBalance: () => {
            dispatch(openModal({
                content: <CheckGiftCard/>,
                className: "Administration",
                onClose: () => {},
                canClose: true,
                onScannerDetect: (code) => {
                    dispatch({
                        type: "GIFT_CARD_SCANNED",
                        payload: code
                    });
                }
            }));
        },
        addErrorAlert: (message, autoClose=true) => {
            dispatch(addErrorAlert(message, autoClose));
        }
    }
};

class Main extends Component {
    componentDidMount() {

    }

    render() {
        const that = this;

        let ThemeImageBox = ({theme, boxClassName, image, title, onClick}) => {
            if(theme === 'Erply'){
                let width = window.innerWidth < 1200 ? '33.33%' : '25%';
                return <ImageBoxErply
                    image={image}
                    title={title}
                    boxClassName={boxClassName}
                    width={width}
                    height={'160px'}
                    padding={'12px'}
                    onClick={onClick}
                />
            }else{
                let width = window.innerWidth < 1200 ? '20%' : '16.66%';
                return <ImageBox
                    image={image}
                    title={title}
                    width={width}
                    height={'170px'}
                    onClick={onClick}
                />
            }
        }

        let voucherCodesButton;
        let editUIButton;
        if(this.props.theme === 'Decathlon'){
            voucherCodesButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={"iconVoucher"}
                    title={<Translate id={'Enter voucher codes'}/>}
                    onClick={e => {
                        that.props.voucherInput(
                            this.props.rootReducer.warehouseID,
                            this.props.rootReducer.posID);
                    }}
                />
            );
        }
        if(this.props.theme === 'Erply'){
            editUIButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={iconEditUIErply}
                    title={<Translate id={'Edit UI'}/>}
                    onClick={e => {
                        that.props.openEditUIView();
                    }}
                />
            );
        }

        let cashInOutButton;
        let cashDrawerButton;
        if(this.props.operationMode === 'full'){
            cashInOutButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={"iconCashInOut"}
                    boxClassName={this.props.theme === 'Erply' ? 'greenButton' : undefined}
                    title={<Translate id={'Cash in/out'}/>}
                    onClick={e => {
                        that.props.openCashInOutView();
                    }}
                />
            );
            cashDrawerButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={"iconCashDrawer"}
                    boxClassName={this.props.theme === 'Erply' ? 'redButton' : undefined}
                    title={<Translate id={'Open cash drawer'}/>}
                    onClick={e => {
                        that.props.openCashDrawer();
                    }}
                />
            );
        }

        let printingConfButton;
        if(this.props.theme === 'Decathlon'){
            printingConfButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={"printConf"}
                    title={<Translate id={'Receipt print configuration'}/>}
                    onClick={e => {
                        that.props.openPrinterConfiguration();
                    }}
                />
            );
        }


        let editQuickProductsButton;
        if(window.AppConf.posSpecificQuickSelectProducts === true){
            editQuickProductsButton = (
                <ThemeImageBox
                    theme={this.props.theme}
                    image={this.props.theme === 'Erply' ? iconEditProductErply : iconEditProduct}
                    title={<Translate id={'Edit quick select products'}/>}
                    onClick={e => {
                        that.props.editQuickProducts();
                    }}
                />
            );
        }

        console.log("process.env", process.env);
        let header;
        let scrollBarStyle;
        if(this.props.theme === 'Erply'){
            scrollBarStyle = {
                position: "absolute",
                left: 50,
                right: 50,
                top: 100,
                height: 'calc(100% - 100px)'
            };
            header =
                <div style={{
                    backgroundColor: '#CCCCCC',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '65px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <span style={{
                        fontSize: '20px',
                        marginLeft: '20px'
                    }}>
                        <Translate id={"Administrator menu"}/>
                    </span>
                </div>;
        }else{
            scrollBarStyle = {
                position: "absolute",
                left: 0,
                right:0,
                top: 65,
                height: 'calc(100% - 65px)'
            };
            header =
                <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '65px',
                    backgroundColor: '#FFFFFF'
                }}>
                    <span style={{
                        position: "absolute",
                        left: 10,
                        top: 10,
                        fontWeight: "bold"
                    }}>v{window.AppConf?.version ?? ""}</span>
                    <h1 className={"bigUpperCaseHeading"}><Translate id={"Administration menu"}/></h1>
                </div>;
        }

        return (
            <div>
                {header}
                <PerfectScrollbar style={scrollBarStyle}>
                    <div>
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={"iconArticle"}
                            boxClassName={this.props.theme === 'Erply' ? 'greenButton' : undefined}
                            title={<Translate
                                id={'Add an item'}/>}
                            onClick={e => {
                                that.props.openAddItemView(this.props.mode);
                            }}
                        />
                        {
                            this.props.theme === 'Decathlon' ?
                                <ThemeImageBox
                                    theme={this.props.theme}
                                    image={"iconUnknownArticle"}
                                    title={<Translate
                                        id={'Add unknown item'}/>}
                                    onClick={e => {
                                        this.props.openUnknownItemView();
                                    }}
                                />
                                : null
                        }
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={"iconNotes"}
                            title={<Translate
                                id={'Add sale notes'}/>}
                            onClick={e => {
                                that.props.openAddNotesView(this.props.mode);
                            }}
                        />
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={"iconSaveSale"}
                            boxClassName={this.props.theme === 'Erply' ? 'lightBlueButton' : undefined}
                            title={<Translate id={'Save sale'}/>}
                            onClick={e => {
                                that.props.saveSale();
                            }
                            }/>
                        { cashInOutButton }
                        {
                            this.props.theme === 'Erply' ?
                                <ThemeImageBox
                                    theme={this.props.theme}
                                    image={"iconCashInOut"}
                                    boxClassName={'greenButton'}
                                    title={<Translate id={'Pay an invoice'}/>}
                                    onClick={e => {
                                        if(that.props.rootReducer.productsInBasket.length > 0) {
                                            this.props.addErrorAlert("Products already added to the basket. Please complete or cancel the current transaction!");
                                            return;
                                        }
                                        that.props.openInvoicePaymentView(
                                            this.props.invoice,
                                            this.props.rootReducer.posID,
                                            this.props.dateFormat
                                        );
                                    }}
                                />
                                :
                                undefined
                        }
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={"iconReturn"}
                            boxClassName={this.props.theme === 'Erply' ? 'darkYellowButton' : undefined}
                            title={<Translate id={'Return mode'}/>}
                            onClick={e => {
                                that.props.switchToReturnMode(
                                    that.props.administration.user.userID,
                                    that.props.rootReducer.posID,
                                    this.props.rootReducer.productsInBasket.length > 0,
                                    this.props.mixedTransaction
                                );
                            }}
                        />
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={"iconRecentSales"}
                            title={<Translate id={'Recent sales'}/>}
                            onClick={e => {
                                that.props.showRecentSales(
                                    this.props.rootReducer.posID,
                                    this.props.rootReducer.warehouseID,
                                    function () {},
                                    this.props.dateFormat,
                                    this.props.returnBaseDocument,
                                    this.props.theme
                                );
                            }}
                        />
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={"iconPendingSales"}
                            title={<Translate id={'Pending sales'}/>}
                            onClick={e => {
                                that.props.showPendingSales(
                                    this.props.rootReducer.posID,
                                    this.props.rootReducer.warehouseID,
                                    function () {},
                                    this.props.dateFormat,
                                    this.props.pendingDocument,
                                    this.props.rootReducer.reasonCodes
                                );
                            }}
                        />
                        {
                            this.props.enableManualPromotions ?
                                <ThemeImageBox
                                    theme={this.props.theme}
                                    image={"iconPromotions"}
                                    title={<Translate id={'Promotions'}/>}
                                    onClick={e => {
                                        that.props.showPromotions(
                                            this.props.rootReducer.warehouseID
                                        );
                                    }}
                                />
                                :
                                null
                        }
                        { voucherCodesButton }
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={"iconGiftCard"}
                            title={<Translate id={'Check gift card balance'}/>}
                            onClick={e => {
                                that.props.checkGiftCardBalance();
                            }}
                        />
                        { cashDrawerButton }
                        { printingConfButton }
                        { editQuickProductsButton }
                        { editUIButton }
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={"iconCloseQCO"}
                            title={<Translate id={'sign off'}/>}
                            onClick={e => {
                                that.props.closeQCO();
                            }}
                        />
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={"iconReport"}
                            title={<Translate id={'X-report'}/>}
                            onClick={e => {
                                that.props.showXReport(
                                    this.props.rootReducer.posID,
                                    this.props.rootReducer.warehouseID,
                                    function () {},
                                    this.props.theme
                                );
                            }}
                        />
                        {
                            this.props.theme === 'Erply' || isCashierModeForced() ? undefined :
                                <ThemeImageBox
                                    theme={this.props.theme}
                                    image={"iconCashierMode"}
                                    title={<Translate id={'Change mode'}/>}
                                    onClick={() => {
                                        that.props.changeMode(this.props.cashierMode, this.props.administrationUser);
                                    }}
                                />
                        }
                        {this.props.pluginAdminButtons.map((button, index) => {
                            return <ThemeImageBox
                                key={index}
                                theme={this.props.theme}
                                image={button.image}
                                title={<Translate id={button.title}/>}
                                onClick={button.onClick}
                            />
                        })}
                        <ThemeImageBox
                            theme={this.props.theme}
                            image={"iconCloseDay"}
                            boxClassName={this.props.theme === 'Erply' ? 'redButton' : undefined}
                            title={<Translate id={this.props.theme === 'Erply' ? 'close day': 'close SCO'}/>}
                            onClick={e => {
                                that.props.closeDay(
                                    this.props.rootReducer.posID,
                                    this.props.rootReducer.warehouseID,
                                    this.props.rootReducer.posDayID,
                                    this.props.translate,
                                    this.props.theme,
                                    this.props.rootReducer.transactionUser !== false ?
                                        this.props.rootReducer.transactionUser.employeeID : false
                                );
                            }}
                        />
                    </div>
                </PerfectScrollbar>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
