import React from "react";
import uuid from "uuid";
import {getPaybackConf} from "../../../../integrations/payback/util";
import {fetchWithTimeout} from "../../../../util/helperFunctions";
import {changePaymentMode, setCustomPaymentView} from "../../../actions";
import PaymentView from "./PaymentView";

const PBP = {
    conf: false,
    initialized: false,
    init: (state) => {
        PBP.conf = getPaybackConf();
        if(PBP.conf === false){
            return false;
        }

        PBP.initialized = true;
        PBP.conf.clientCode = state.rootReducer.user.clientCode;
        PBP.conf.sessionKey = state.rootReducer.user.sessionKey;
        PBP.conf.currency = state.rootReducer.erplyConf.default_currency;
        PBP.conf.branchID = state.rootReducer.warehouse.code.padStart(8,"0");
    },
    api: {
        sendRequest: (request, data) => {
            return new Promise((resolve, reject) => {
                let paybackConf = getPaybackConf();
                fetchWithTimeout(paybackConf.url + request, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        clientCode: PBP.conf.clientCode,
                        sessionKey: PBP.conf.sessionKey
                    },
                    body: JSON.stringify(data),
                    timeout: 15000
                }).then((response) => {
                    resolve(response.json());
                }, (error) => {
                    if(error.name === 'AbortError'){
                        reject("Timeout");
                    }else{
                        reject(error);
                    }
                });
            });
        },
        processTransaction: (paymentToken, type, amount, basketTotalAmount, receiptID, products) => {
            return new Promise((resolve, reject) => {
                let body = {
                    paymentToken,
                    data: {
                        TransactionType: type, // 10.1.5.3 Transaction Types 1=Debit, 2=Credit 3=reversal
                        GrossAmount: parseFloat(amount), // Float, transaction amount to be authorized. In case of sMR: EUR value of points to be redeemed.
                        TotalPurchaseValue: parseFloat(basketTotalAmount), // Float, total purchase value before deduction of vouchers, coupons, etc..
                        Currency: PBP.conf.currency,
                        PaybackPoints: Math.round(basketTotalAmount * PBP.conf.eurToPoint),
                        ReceiptID: receiptID.toString(),
                        MerchantTraceID: uuid.v4(),
                        PSPMerchantID: PBP.conf.PSPMerchantID, // Receive from Decathlon
                        BranchID: PBP.conf.branchID // Store number
                    }
                };

                let vatItems = products.reduce((acc, product) => {
                    if(typeof acc[product.data.vatrateID] === "undefined"){
                        acc[product.data.vatrateID] = {
                            Rate: product.data.vatrate,
                            GrossAmount: 0
                        }
                    }
                    acc[product.data.vatrateID].GrossAmount += product.rowVAT;
                    return acc;
                }, {});

                body.data.VATItems = Object.values(vatItems);

                console.log("pay/transaction request", {body});
                resolve({
                    "TransactionID": "a6e4e90d-ce5b-44d6-b872-74424ca5f37e",
                    "Created": "2021-03-08T13:18:25.020",
                    "AutorizationID": "a27fa3",
                    "TransactionType": 1,
                    "GrossAmount": amount,
                    "TotalPurchaseValue": basketTotalAmount,
                    "PAYAmount": 10.00, // Always 0 for sMR
                    "Currency": "EUR",
                    "PaybackPoints": 26,
                    "PointsRedeemed": 2000,
                    "ReceiptID": receiptID,
                    "MerchantTraceID": "dfa63919-a7ce-47ea-a7f1-75a12133cb06",
                    "PSPMerchantID": PBP.conf.PSPMerchantID,
                    "BranchID": PBP.conf.branchID,
                    "VATItems":
                    [
                        {
                            "Rate": 16.00,
                            "GrossAmount": 2.76
                        }
                    ]
                }
            )
                return;

                PBP.api.sendRequest("pay/transaction", body).then((response) => {
                    if(response.status.responseStatus === "ok"){
                        resolve(response.data);
                    }else{
                        reject(PBP.formatErrorResponse(response.data));
                    }
                }, (error) => {
                    if(error === "Timeout"){
                        PBP.api.reversal(amount * -1, receiptID, receiptID).then(() => {
                            reject(error);
                        }, () => {
                            reject(error);
                        });
                    }else{
                        reject(PBP.formatErrorResponse(error));
                    }
                });
            });
        },
        reversal: (amount, receiptID, referenceReceiptID, retry = 0) => {
            return new Promise((resolve, reject) => {
                if(retry > 2){
                    reject("Timeout");
                    return;
                }
                let body = {
                    paymentToken: false,
                    data: {
                        TransactionType: 3,
                        GrossAmount: parseFloat(amount),
                        Currency: PBP.conf.currency,
                        ReceiptID: receiptID.toString(),
                        MerchantTraceID: uuid.v4(),
                        PSPMerchantID: PBP.conf.PSPMerchantID,
                        BranchID: PBP.conf.branchID,
                        ReferenceTransaction: referenceReceiptID
                    }
                };

                console.log("pay/transaction reversal request", {body});
                return;

                PBP.api.sendRequest("pay/transaction", body).then((response) => {
                    if(response.status.responseStatus === "ok"){
                        resolve(response.data);
                    }else{
                        reject(PBP.formatErrorResponse(response.data));
                    }
                }, (error) => {
                    if(error === "Timeout"){
                        PBP.api.reversal(amount, receiptID, receiptID, retry + 1).then(resolve, reject);
                    }else{
                        reject(PBP.formatErrorResponse(error));
                    }
                });
            });
        }
    },
    QRCode: function (code){
        this.prefix = code.substring(0,4);
        this.cardNumber = code.substring(4,17);
        this.deviceID = code.substring(17,56);
        this.timestamp = code.substring(56,66);
        this.additionalData = code.substring(66,118);
        this.payData = PBP.parseAdditionalData(this.additionalData);
        this.tokenSignature = code.substring(118,273);
        this.paymentToken = this.deviceID + this.timestamp + this.additionalData + this.tokenSignature;
    },
    parseAdditionalData: (data) => {
        let processing = data.substring(2);
        let tag = processing.substring(0,2);
        if(tag === "01" || tag === "02"){
            return {
                pointsToRedeem: parseInt(processing.substring(2,7)),
                pay: tag === "01"
            }
        }
        return {
            pointsToRedeem: 0,
            pay: true
        };
    },
    startPayment: (dispatch, amount, basketTotalAmount, receiptNo, products, qrCode) => {
        return new Promise((resolve, reject) => {
            let isCredit = amount < 0;
            let finalAmount = qrCode.payData.pay ? amount : PBP.conf.pointToEur * qrCode.payData.pointsToRedeem;
            console.log("PBP: startPayment", {qrCode, isCredit, finalAmount});
            PBP.api.processTransaction(qrCode.paymentToken, isCredit ? 2 : 1, finalAmount, basketTotalAmount, receiptNo, products).then(resolve, reject);
        });
    },
    formatErrorResponse(error) {
        if(typeof error === "string") return error;
        if(typeof error.Name !== "undefined"){
            return `${error.Error} ${error.Name} ${error.Description}`;
        }
        return error?.name ?? "unknown API error";
    }
}

export default PBP;